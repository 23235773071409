import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { Route, Routes, Navigate } from "react-router-dom";
import "../../../tellurideExtAssets/styles/Styles.style.scss";
import Error from "../../../tellurideExtComponents/errorsComponent/Error";
import Header from "../../headerComponent/Header";
import NavigationBar from "../../navigationBarComponent/internalNavigationBarComponent/NavigationBar";
import CreateOfferComponent from "../../offerSetupComponent/createOfferComponent/CreateOfferComponent";
import "./OfferRedirection.style.scss";
import { useLocation } from "react-router-dom";
import { AnimatePresence } from "framer-motion/dist/framer-motion";
import { useSelector } from "react-redux";
import {
	OFFER_TITLE,
	DUAL_NAVLINKS,
} from "../../constantComponent/Constants";
import { useAuth0 } from "@auth0/auth0-react";
import AuthLoading from "../../LoaderComponents/AuthLoading";
import SessionExpiredConfirmation from "../../modelComponent/sessionExpiredConfirmationComponent/SessionExpiredConfirmation";
import { logoutAuth0 } from "../../../tellurideExtRedux/reduxUtil";
import UnauthorizedAccess,{
	PageNotFound,
} from "../../unauthorizeComponent/UnauthorizeWrapper";
import LeavingSetupPageConfirmationComponent from "../../modelComponent/leavingSetupPageConfirmationComponent/LeavingSetupPageConfirmationComponent.jsx";
import {
	checkRightsIsEditable,
	getRightsKey,
} from "../../utilsComponent/CommonUtil.js";
const OfferRedirection = () => {
	const securityData = useSelector((state) => state.securityData);
	const [showOfferPopup, setShowOfferPopup] = useState(false);
	const [selectOffer, setSelectOffer] = useState(false);
	const location = useLocation();
	const scrollTargetDiv = useRef();
	const { logout } = useAuth0();
	const [loginRedirectLoading, setLoginRedirectLoading] = useState(false);
	const OfferAnalyticsBlankSessionIdError = useSelector(
		(state) => state.OfferAnalyticsData.blankSessionIdError
	);
	const offerDetailsDataBlankSessionIdError = useSelector(
		(state) => state.offerDetailsData.blankSessionIdError
	);
	const metaDataBlankSessionIdError = useSelector(
		(state) => state.metaData.blankSessionIdError
	);
	const OfferAnalyticsSessionExpire = useSelector(
		(state) => state.OfferAnalyticsData.showExpiredSessionPopup
	);
	const offerDetailsDataSessionExpire = useSelector(
		(state) => state.offerDetailsData.showExpiredSessionPopup
	);
	const metaDataSessionExpire = useSelector(
		(state) => state.metaData.showExpiredSessionPopup
	);
	const offerDetailsPageData = useSelector((state) => state.offerDetailsData);

	const orgName = offerDetailsPageData?.authOrganizationName;

	//theme
	const toasterData = useSelector((state) => state.toasterData);
	const [theme, setTheme] = useState(toasterData.isDarkTheme);
	useEffect(() => {
		if (!securityData.authLoading) {
			const lastVisitedURL = localStorage.getItem("lastVisitedUrl");
			if (lastVisitedURL) {
				localStorage.removeItem("lastVisitedUrl");
				window.location.href = lastVisitedURL;
			} else {
				setLoginRedirectLoading(false);
			}
		}
	}, [securityData.authLoading]);
	useEffect(() => {
		setTheme(toasterData.isDarkTheme);
	}, [toasterData]);
	//theme

	useLayoutEffect(() => {
		scrollTargetDiv?.current?.scrollTo({
			top: 0,
			behavior: "smooth",
		});
	}, [location]);

	useEffect(() => {
		if (
			[
				OfferAnalyticsBlankSessionIdError,
				offerDetailsDataBlankSessionIdError,
				metaDataBlankSessionIdError,
			].includes(true)
		) {
			logoutAuth0(logout, orgName);
		}
	}, [
		OfferAnalyticsBlankSessionIdError,
		offerDetailsDataBlankSessionIdError,
		metaDataBlankSessionIdError,
		logout,
	]);

	return (
		<div data-theme={theme}>
			<AuthLoading loginRedirectLoading={loginRedirectLoading}>
				<div className="redirection-page-wrapper">
					<Header title="Offers Management" />
					<section className="display-flex-wrapper" ref={scrollTargetDiv}>
						<NavigationBar
							showOfferPopup={showOfferPopup}
							setShowOfferPopup={setShowOfferPopup}
							src={OFFER_TITLE}
						/>
						<AnimatePresence>
							{showOfferPopup && (
								<CreateOfferComponent
									setSelectOffer={setSelectOffer}
									setShowOfferPopup={setShowOfferPopup}
								/>
							)}
						</AnimatePresence>
						<div className="inner-section-wrapper">
							<ErrorBoundary FallbackComponent={Error} onError={(error) => {}}>
								<Routes>
									{DUAL_NAVLINKS.find(
										(dualNavLink) => dualNavLink.label === "Loyalty"
									).subLinks.map((SubLink, index) => {
										if (SubLink.isRoute) {
											return (
												<React.Fragment key={index}>
													<Route
														path={SubLink.path}
														element={
															checkRightsIsEditable(
																getRightsKey(SubLink.rights)
															) ? (
																<SubLink.Component.Name
																	setShowOfferPopup={setShowOfferPopup}
																/>
															) : (
																<UnauthorizedAccess />
															)
														}
													/>
													{SubLink.isActive && (
														<Route
															path="/"
															element={
																<Navigate
																	to={
																		"/" +
																		window.location.pathname.split("/")[1] +
																		SubLink.url
																	}
																	replace
																	key={index}
																/>
															}
														/>
													)}
												</React.Fragment>
											);
										}
									})}
									<Route
										path="*"
										Component={() => {
											return <PageNotFound pageName={"Page not found !"} />;
										}}
									/>
								</Routes>
							</ErrorBoundary>
						</div>
					</section>
				</div>
				<LeavingSetupPageConfirmationComponent />
				{offerDetailsDataSessionExpire === true ||
				OfferAnalyticsSessionExpire === true ||
				metaDataSessionExpire === true ? (
					<SessionExpiredConfirmation />
				) : null}
			</AuthLoading>
		</div>
	);
};

export default OfferRedirection;
