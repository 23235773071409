import {enableSendButton,disableSendButton} from "../ChatBotUtil";
const Validator = (input) => {
  if (input.length > 0) {
    //enableSendButton();
    return true;
  }else{
    //disableSendButton();    
    return false;
  }  
}

export default Validator;