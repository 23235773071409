export const COLUMNS = [
  {
    title: "Offer ID",
    key: "id",
    type: "string",
    visible: true,
    filter: true,
    sort: true,
    minWidth: 150,
    initialWidth: 150,
    defaultWidth: 150,
    resizable: true,
    reqBodyKey: { attrName: "id", isAllowed: true },
  },
  {
    title: "Offer Name",
    key: "name",
    type: "string",
    visible: true,
    filter: true,
    sort: true,
    minWidth: 140,
    initialWidth: 140,
    defaultWidth: 140,
    resizable: true,
    reqBodyKey: { attrName: "name", isAllowed: true },
  },
  {
    title: "Description",
    key: "description",
    type: "string",
    visible: true,
    filter: true,
    sort: true,
    advanceFilter: true,
    minWidth: 180,
    initialWidth: 180,
    defaultWidth: 180,
    resizable: true,
    reqBodyKey: { attrName: "description", isAllowed: true },
  },
  {
    title: "Type",
    key: "type",
    type: "select",
    visible: true,
    filter: true,
    sort: true,
    advanceFilter: true,
    multi: true,
    options: [
      "Points Earn",
      "Price Discount",
      "Loyalty Cash",
      "Base Earn",
      "Base Redeem",
      "Custom Offer",
      "Mission",
      "NonPurchase PointsEarn",
      "Event Offer",
    ],
    minWidth: 85,
    initialWidth: 85,
    defaultWidth: 85,
    resizable: true,
    reqBodyKey: { attrName: "type", isAllowed: true },
    rights: [
      {
        key: "Points Earn",
        rights: ["filter","loyalty","viewoffer","offertype","pointsearn"],
      },
      {
        key: "Price Discount",
        rights: ["filter","loyalty","viewoffer","offertype","pricediscount"],
      },
      {
        key: "Loyalty Cash",
        rights: ["filter","loyalty","viewoffer","offertype","loyaltycash"],
      },
      {
        key: "Base Earn",
        rights: ["filter","loyalty","viewoffer","offertype","baseearn"],
      },
      {
        key: "Base Redeem",
        rights: ["filter","loyalty","viewoffer","offertype","baseredeem"],
      },
      {
        key: "Custom Offer",
        rights: ["filter","loyalty","viewoffer","offertype","customoffer"],
      },
      {
        key: "Mission",
        rights: ["filter","loyalty","viewoffer","offertype","mission"],
      },
      {
        key: "NonPurchase PointsEarn",
        rights: ["filter","loyalty","viewoffer","offertype","nonpurchasepointsearn"],
      },
      {
        key:"Event Offer",
        rights: ["filter","loyalty","viewoffer","offertype","eventoffer"]
      }
    ],
  },
  {
    title: "Status",
    key: "status",
    type: "select",
    visible: true,
    filter: true,
    sort: true,
    advanceFilter: true,
    multi: true,
    options: ["ACTIVE", "INACTIVE", "DRAFT", "EXPIRED"],
    minWidth: 100,
    initialWidth: 100,
    defaultWidth: 100,
    resizable: false,
    reqBodyKey: { attrName: "status", isAllowed: true },
  },
  {
    title: "Start Date",
    key: "startDate",
    type: "date",
    visible: true,
    filter: true,
    sort: true,
    advanceFilter: true,
    minWidth: 100,
    initialWidth: 100,
    defaultWidth: 100,
    resizable: true,
    reqBodyKey: { attrName: "offerStartDate", isAllowed: true },
  },
  {
    title: "End Date",
    key: "endDate",
    type: "date",
    visible: true,
    filter: true,
    sort: true,
    advanceFilter: true,
    minWidth: 100,
    initialWidth: 100,
    defaultWidth: 100,
    resizable: true,
    reqBodyKey: { attrName: "offerEndDate", isAllowed: true },
  },
  {
    title: "Last Modified",
    key: "lastEditedDate",
    type: "date",
    visible: true,
    filter: true,
    sort: true,
    advanceFilter: true,
    minWidth: 130,
    initialWidth: 130,
    defaultWidth: 130,
    resizable: true,
    reqBodyKey: { attrName: "lastEditedDate", isAllowed: true },
  },
  {
    title: "Created By",
    key: "createdBy",
    type: "string",
    visible: true,
    filter: true,
    sort: true,
    advanceFilter: true,
    minWidth: 150,
    initialWidth: 150,
    defaultWidth: 150,
    resizable: true,
    reqBodyKey: { attrName: "createdBy", isAllowed: true },
  },
];
export const VIEW_OFFER = "VIEW_OFFER";
export const VIEW_OFFER_PLACEHOLDER = "Search by Offer ID or Offer Name";
export const DATE_TYPE_OPTIONS = [
  { code: "LiveDate", name: "Live Date" },
  { code: "OfferStartDate", name: "Start Date" },
  { code: "OfferEndDate", name: " End Date" },
];
export const OFFER_TABLE_REQ_BODY_KEY = "offerSearch";
export const OFFER_TABLE_RES_BODY_KEY = "offerList";
