import { useEffect, useState } from "react";
import "./CampaignHeader.style.scss";
import { images } from "../../imagesComponent/Images";
import { TfiStatsUp } from "react-icons/tfi";

import {
  OFFER_DETAILS_STATUS_ACTIVATED,
  OFFER_DETAILS_STATUS_DEACTIVATED,
  OFFER_DETAILS_STATUS_DRAFT,
  headers,
  SAVE,
  ACTIVATE,
  EDIT,
  DEACTIVATE,
  OFFER_DETAILS_STATUS_DEACTIVATED_EXPIRED_MESSAGE,
  offer_status,
  ABTEST,
  OFFER_DETAILS_STATUS_AB_TEST,
  CANCEL,
} from "../../constantComponent/Constants";

import moment from "moment";
import {
  miniloading,
  miniloadingWithoutMessage,
} from "../../LoaderComponents/loadingDesignConstant";
import {
  changeDateDashFormat,
  checkRightsIsEditable,
  checkRightsIsVisible,
  getAllRights,
  getRightsFromSession,
  getRightsKey,
  skey,
  trimExtraSpaces,
} from "../../utilsComponent/CommonUtil";
import { AnimatePresence, motion } from "framer-motion/dist/framer-motion";
import { StatsPopover } from "./CampaignDesignConstant";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { useAuth0 } from "@auth0/auth0-react";
import { convertUTCDateToLocalDate } from "../../offerSetupComponent/offerDetailsComponent/OfferDetailsUtil";
import { getDisplayName } from "../../utilsComponent/GenericFlow/GenericFlowUtil";
import {
  EMAIL,
  displayNameAlias,
  updatejourneystatusRequestBody,
} from "../campaignBuilderComponent/CampaignBuilderConstants";
import {
  saveJourney,
  updateJourneyStatus,
} from "../../../tellurideExtRedux/journey/JourneyAction";
import {
  CAMPAIGN,
  OFFER,
  VIEW,
  VIEW_OFFER,
} from "../../constantComponent/PathConstants";
import ConfirmationPopUpComponent from "../../modelComponent/ConfirmationPopUpComponent/ConfirmationPopUpComponent";
import {
  setErrorToaster,
  setSourceCancelBtn,
} from "../../../tellurideExtRedux";
import { showSetupCreationConfirmationPopup } from "../../navigationBarComponent/internalNavigationBarComponent/NavigationBarUtil";

const CampaignHeader = ({
  campaignDetailsData,
  campaignBuilderData,
  isDisabled,
}) => {
  const journeyDetailsData = useSelector((state) => state.journeyDetailsData);
  const securityData = useSelector((state) => state.securityData);

  const journeyData = journeyDetailsData?.journeyDetailsResponse?.journeyData;

  const { user } = useAuth0();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const offerDetailsPageData = useSelector((state) => state.offerDetailsData);
  const [campaignNameEditable, setCampaignNameEditable] = useState(false);
  const [clickedBtn, setClickedBtn] = useState("");
  const [updatedCampaignName, setUpdatedCampaignName] = useState(
    journeyData?.journeyName
  );
  const [statsPopoverVisible, setStatsPopoverVisible] = useState(false);
  // show error toaster message if start date input is empty on Activate button click.
  const [showConfirmationPopup, setShowConfirmationPopup] = useState({
    key: "",
    visibility: false,
  });
  const handleEditClickCampaignName = () => {
    setCampaignNameEditable(true);
  };
  const handleCampaignNameChange = (e) => {
    setUpdatedCampaignName(e.target.value);
  };
  const saveCampaignName = () => {
    setCampaignNameEditable(!campaignNameEditable);
  };

  const [disableABtestBtn, setDisableABtestBtn] = useState(false);
  const [disableActivateBtn, setDisableActivateBtn] = useState(false);
  const journeyType =
    journeyDetailsData?.journeyDetailsResponse?.journeyAttributes?.journeyAttribute?.some(
      (attribute) => attribute.name === "DISPLAY_OFFER_TYPE"
    )
      ? journeyDetailsData?.journeyDetailsResponse.journeyAttributes.journeyAttribute.find(
          (attribute) => attribute.name === "DISPLAY_OFFER_TYPE"
        ).values.value[0]
      : journeyDetailsData?.journeyDetailsResponse?.journeyType;

  useEffect(() => {
    if (campaignBuilderData?.journeyFlow?.nodes.length > 0) {
      const hasEmailNode = campaignBuilderData?.journeyFlow?.nodes.some(
        (node) => node?.data?.type?.toLowerCase() === EMAIL
      );
      const hasABtest = campaignBuilderData?.journeyFlow?.nodes.some(
        (node) =>
          node?.data?.type?.toLowerCase() === EMAIL &&
          node?.data?.value?.action?.abTestEnabled === "Y"
      );
      setDisableABtestBtn(!hasABtest);
      setDisableActivateBtn(!hasEmailNode);
    }
  }, [campaignBuilderData]);
  useEffect(() => {
    if (
      journeyDetailsData?.updateJourneyStatusError ||
      journeyDetailsData?.saveJourneyError
    ) {
      setClickedBtn("");
    }
  }, [journeyDetailsData]);
  function showErrorToaster(obj) {
    const inputStartDate = document.getElementById("input-start-date");
    if (!inputStartDate.value) {
      const backdrop = document.querySelector(".backdrop");
      backdrop.classList.add("show");
      const startDate = document.getElementById("start-date");
      startDate.classList.add("error-start-date");
      setTimeout(() => {
        hideErrorToaster(); // after 5 seconds hide error toaster message
      }, 5000);
    }
    return;
  }

  // hide error toaster message
  function hideErrorToaster(obj) {
    const backdrop = document.querySelector(".backdrop");
    backdrop.classList.remove("show");
    return;
  }

  const handleClickOnSave = async (activateStatus) => {
    setClickedBtn(SAVE);
    headers.sessionId = skey();
    headers.requestAccess = getRightsFromSession();
    headers.organization = offerDetailsPageData?.authOrganizationName;
    const requestHeaders = {
      headers,
    };
    const requestBody = {
      requestorID: "OFRI",
      messageVersion: process.env.REACT_APP_MESSAGE_VERSION_05,
      journeyData: {
        ...journeyData,

        journeyDescription: campaignDetailsData?.description,
        journeyEndDate: changeDateDashFormat(campaignDetailsData?.endDate),
        journeyStartDate: changeDateDashFormat(campaignDetailsData?.startDate),
        journeyName: trimExtraSpaces(updatedCampaignName),
        journeySubType: journeyData?.journeyType,
        journeyNumber: journeyData?.journeyNumber,
        // offerType: "EVENTPROC",
        // modifiedBy: user?.email,
      },
      journeyFlow: {
        metaData: {
          ...(journeyData?.journeyType === "CMP-EVENT" && {
            version: "1",
          }),
          ...(journeyData?.journeyType === "CMP-BATCH" && {
            scheduleCronExpression:
              campaignDetailsData?.cronExpression || "0 0 * * *",
            frequencyType: campaignDetailsData?.frequencyType,
            timezone: "CST",
          }),
          // triggeringEventName: journeyData?.campaignType === "CMP-EVENT" ? triggerEventName : null
        },
        nodes: campaignBuilderData?.journeyFlow?.nodes.map((node) => {
          return {
            id: node.id,
            data: {
              // displayData: node.data.displayData,
              type: node.data.type,
              value: node.data.value,
              name: node?.data?.name
                ? node?.data?.name
                : getDisplayName(node.data.type, displayNameAlias),
            },
          };
        }),
        edges: campaignBuilderData?.journeyFlow?.edges.map((edge) => {
          return {
            id: edge.id,
            source: edge.source,
            target: edge.target,
            // type: edge.type, if required in future
          };
        }),
      },
    };

    await dispatch(
      saveJourney(requestHeaders, requestBody, true, false, navigate, true)
    );
    if (activateStatus) {
      updateStatusHandler(activateStatus);
    }
  };

  const saveAndUpdateStatusHandler = (status) => {
    handleClickOnSave(status);
  };

  const updateStatusHandler = (status) => {
    headers.sessionId = skey();
    headers.requestAccess = getRightsFromSession();
    headers.organization = offerDetailsPageData?.authOrganizationName;
    const requestHeaders = {
      headers,
    };

    const requestBody = updatejourneystatusRequestBody(
      user,
      journeyData,
      status
    );
    dispatch(
      updateJourneyStatus(requestHeaders, requestBody, journeyData, {
        navigate: navigate,
        successRedirect: "",
        errorRedirectionPath: CAMPAIGN + VIEW_OFFER,
      })
    );
  };

  return (
    <>
      <div className="campaign-navigation-top-div">
        <div className="empty-space-div"></div>
        <div className="navigation-text">
          {campaignNameEditable && isDisabled !== true ? (
            <>
              <input
                className="input-campaign-name"
                placeholder="Enter new campaign name"
                maxLength="50"
                onChange={handleCampaignNameChange}
                defaultValue={updatedCampaignName}
                type="text"
                autoFocus
                onBlur={() => {
                  if (updatedCampaignName.trim() === "")
                    setUpdatedCampaignName(
                      trimExtraSpaces(journeyData?.journeyName)
                    );
                  saveCampaignName();
                }}
                autocomplete="off"
                autocorrect="off"
                autocapitalize="off"
                spellcheck="false"
              />
            </>
          ) : (
            <p className="campaign-name setup-name-header">
              {updatedCampaignName}
            </p>
          )}
          {!campaignNameEditable && isDisabled !== true && (
            <>
              {checkRightsIsVisible(
                getRightsKey(["button", "campaign", "detail", "save"])
              ) ? (
                <img
                  onClick={
                    !checkRightsIsEditable(
                      getRightsKey(["button", "campaign", "detail", "save"])
                    )
                      ? handleEditClickCampaignName
                      : ""
                  }
                  src={images.penIcon}
                  className="pen-icon"
                />
              ) : null}
            </>
          )}
          {!campaignNameEditable && (
            <div
              className={`status-container ${
                journeyData?.journeyStatus === OFFER_DETAILS_STATUS_ACTIVATED
                  ? moment(journeyData?.journeyEndDate).diff(
                      convertUTCDateToLocalDate(new Date()),
                      "days"
                    ) <= -1
                    ? "EXPIRED"
                    : journeyData?.journeyStatus
                  : journeyData?.journeyStatus
              }`}
            >
              {" "}
              <span className="campaign-status-value">
                {journeyData?.journeyStatus === OFFER_DETAILS_STATUS_ACTIVATED
                  ? moment(journeyData?.journeyEndDate).diff(
                      convertUTCDateToLocalDate(new Date()),
                      "days"
                    ) <= -1
                    ? OFFER_DETAILS_STATUS_DEACTIVATED_EXPIRED_MESSAGE
                    : offer_status[journeyData?.journeyStatus]
                  : offer_status[journeyData?.journeyStatus]}
                {journeyData?.journeyStatus === OFFER_DETAILS_STATUS_AB_TEST
                  ? journeyData?.journeyStatus
                  : ""}
              </span>
            </div>
          )}
        </div>
        {/* {offerDetailsPageData?.offerData?.offerType === "Points Earn" && (
          <div
            className={`stats-button-container ${
              statsPopoverVisible === true ? "active" : null
            }`}
            onClick={() => setStatsPopoverVisible(true)}
          >
            <TfiStatsUp />
          </div>
        )} */}
        {statsPopoverVisible === true && (
          <StatsPopover setStatsPopover={setStatsPopoverVisible} />
        )}
        <div className="navigation-buttons">
          {journeyData?.journeyStatus === OFFER_DETAILS_STATUS_DRAFT && (
            <>
              {checkRightsIsVisible(
                getRightsKey(["button", "campaign", "detail", "save"])
              ) ? (
                <button
                  className="save-button btn btn-primary"
                  onClick={(e) => {
                    handleClickOnSave();
                  }}
                  disabled={
                    clickedBtn ||
                    journeyDetailsData?.updateJourneyStatusLoading ||
                    journeyDetailsData?.saveJourneyLoading ||
                    !checkRightsIsEditable(
                      getRightsKey(["button", "campaign", "detail", "save"])
                    )
                  }
                >
                  {journeyDetailsData?.saveJourneyLoading && clickedBtn === SAVE
                    ? miniloadingWithoutMessage()
                    : SAVE}
                </button>
              ) : null}
            </>
          )}
          {journeyData?.journeyStatus !== OFFER_DETAILS_STATUS_DRAFT &&
            journeyData?.journeyStatus !== OFFER_DETAILS_STATUS_ACTIVATED && (
              <>
                {checkRightsIsVisible(
                  getRightsKey(["button", "campaign", "detail", "edit"])
                ) ? (
                  <button
                    className="save-button btn btn-primary"
                    onClick={(e) => {
                      setClickedBtn(EDIT);
                      updateStatusHandler(OFFER_DETAILS_STATUS_DRAFT);
                    }}
                    disabled={
                      clickedBtn ||
                      journeyDetailsData?.updateJourneyStatusLoading ||
                      !checkRightsIsEditable(
                        getRightsKey(["button", "campaign", "detail", "edit"])
                      )
                    }
                  >
                    {journeyDetailsData?.updateJourneyStatusLoading &&
                    clickedBtn === EDIT
                      ? miniloadingWithoutMessage()
                      : EDIT}
                  </button>
                ) : null}
              </>
            )}
          {/* ----------------------------AB testing button------------------------- */}
          {journeyData?.journeyType === "CMP-BATCH" &&
            (journeyData?.journeyStatus === OFFER_DETAILS_STATUS_DRAFT ||
              journeyData?.journeyStatus ===
                OFFER_DETAILS_STATUS_DEACTIVATED) && (
              <>
                {checkRightsIsVisible(
                  getRightsKey(["button", "campaign", "detail", "startabtest"])
                ) ? (
                  <button
                    className="activate-button btn btn-primary w-155"
                    disabled={
                      clickedBtn ||
                      journeyDetailsData?.updateJourneyStatusLoading
                        ? true
                        : false ||
                          !checkRightsIsEditable(
                            getRightsKey([
                              "button",
                              "campaign",
                              "detail",
                              "startabtest",
                            ])
                          )
                    }
                    onClick={() => {
                      if (disableABtestBtn) {
                        dispatch(
                          setErrorToaster(
                            "We haven't found the A/B test to start."
                          )
                        );
                      } else {
                        setClickedBtn(ABTEST);
                        setShowConfirmationPopup({
                          key: "startAbTest",
                          visibility: true,
                        });
                      }
                    }}
                  >
                    {journeyDetailsData?.updateJourneyStatusLoading &&
                    clickedBtn === ABTEST
                      ? miniloadingWithoutMessage()
                      : ABTEST}
                  </button>
                ) : null}
              </>
            )}
          {/* ----------------------------ACTIVATE button--------------------------- */}
          {(journeyData?.journeyStatus === OFFER_DETAILS_STATUS_DRAFT ||
            journeyData?.journeyStatus === OFFER_DETAILS_STATUS_DEACTIVATED ||
            journeyData?.journeyStatus === OFFER_DETAILS_STATUS_AB_TEST) && (
            <>
              {checkRightsIsVisible(
                getRightsKey(["button", "campaign", "detail", "active"])
              ) ? (
                <button
                  className="activate-button btn btn-primary"
                  onClick={(e) => {
                    if (disableActivateBtn) {
                      dispatch(
                        setErrorToaster(
                          "One email task is necessary to activate the campaign."
                        )
                      );
                    } else {
                      setClickedBtn(ACTIVATE);
                      setShowConfirmationPopup({
                        key: "activate",
                        visibility: true,
                      });
                    }
                  }}
                  disabled={
                    clickedBtn ||
                    journeyDetailsData?.updateJourneyStatusLoading ||
                    !checkRightsIsEditable(
                      getRightsKey(["button", "campaign", "detail", "active"])
                    )
                  }
                >
                  {journeyDetailsData?.updateJourneyStatusLoading &&
                  clickedBtn === ACTIVATE
                    ? miniloadingWithoutMessage()
                    : ACTIVATE}
                </button>
              ) : null}
            </>
          )}
          {/* ---------------------------------DEACTIVATE BUTTON---------------------------- */}
          {journeyData?.journeyStatus === OFFER_DETAILS_STATUS_ACTIVATED && (
            <>
              {checkRightsIsVisible(
                getRightsKey(["button", "campaign", "detail", "deactive"])
              ) ? (
                <button
                  className="activate-button btn btn-primary"
                  onClick={(e) => {
                    setClickedBtn(DEACTIVATE);
                    setShowConfirmationPopup({
                      key: "deactivate",
                      visibility: true,
                    });
                  }}
                  disabled={
                    clickedBtn === DEACTIVATE ||
                    journeyDetailsData?.updateJourneyStatusLoading ||
                    !checkRightsIsEditable(
                      getRightsKey(["button", "campaign", "detail", "deactive"])
                    )
                  }
                >
                  {journeyDetailsData?.updateJourneyStatusLoading &&
                  clickedBtn === DEACTIVATE
                    ? miniloadingWithoutMessage()
                    : DEACTIVATE}
                </button>
              ) : null}
            </>
          )}
          <button
            className="activate-button btn btn-secondary"
            disabled={
              clickedBtn ||
              journeyDetailsData?.updateJourneyStatusLoading ||
              journeyDetailsData?.saveJourneyLoading
            }
            onClick={(e) => {
              if (
                journeyDetailsData.isOfferChanged &&
                journeyData?.journeyStatus === OFFER_DETAILS_STATUS_DRAFT &&
                checkRightsIsEditable(
                  getRightsKey([
                    "campaign",
                    "detail",
                    "setup",
                    journeyType?.split(" ").join(""),
                  ])
                )
              ) {
                dispatch(
                  setSourceCancelBtn({
                    state: true,
                    path: CAMPAIGN + VIEW,
                  })
                );
                showSetupCreationConfirmationPopup();
              } else {
                navigate(
                  "/" + window.location.pathname.split("/")[1] + CAMPAIGN + VIEW
                );
              }
            }}
          >
            {CANCEL}
          </button>
        </div>
      </div>
      {/* --------------------------------Confirmation Popup--------------------------- */}
      <AnimatePresence>
        {showConfirmationPopup.visibility && (
          <ConfirmationPopUpComponent
            setClickedBtn={setClickedBtn}
            updateStatusHandler={updateStatusHandler}
            saveAndUpdateStatusHandler={saveAndUpdateStatusHandler}
            secondaryBtnText={
              journeyData?.journeyStatus === OFFER_DETAILS_STATUS_DRAFT &&
              journeyDetailsData?.isOfferChanged
                ? "No"
                : "Cancel"
            }
            saveActivate={
              journeyData?.journeyStatus === OFFER_DETAILS_STATUS_DRAFT &&
              journeyDetailsData?.isOfferChanged
                ? true
                : false
            }
            isOfferChanged={
              journeyData?.journeyStatus === OFFER_DETAILS_STATUS_DRAFT
                ? journeyDetailsData?.isOfferChanged
                : false
            }
            statusKey={showConfirmationPopup.key}
            setShowConfirmationPopup={setShowConfirmationPopup}
          />
        )}
      </AnimatePresence>
    </>
  );
};

export default CampaignHeader;
