export const CONTENT_DETAILS_TITLE = "Content details";
export const CONTENT_ID_LABEL = "Content ID";
export const CONTENT_ID_INFO = "System-generated number for the content.";
export const CONTENT_TYPE_LABEL = "Content Type";
export const START_DATE_LABEL = "Start Date";
export const START_DATE_INFO = "Date, Content will be active from.";
export const END_DATE_LABEL = "End Date";
export const END_DATE_INFO = "Date, Content will expire.";
export const DESCRIPTION_LABEL = "Description";
export const CONTENT_START_DATE = "contentStartDate";
export const CONTENT_END_DATE = "contentEndDate";
export const CTA_URL_DATA =
  "Clicking on email, member will be redirected to this URL.";
export const CONTENT_CATEGORY_OPTIONS = [
  { label: "Stackable", value: "Stackable" },
  { label: "Non Stackable", value: "Non Stackable" },
  { label: "Always Earn", value: "Always Earn" },
];
export const CONTENT_PREVIEW_AND_TEST = "Preview and Test";