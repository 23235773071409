import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { Route, Routes, Navigate } from "react-router-dom";
import "../../../tellurideExtAssets/styles/Styles.style.scss";
import Error from "../../errorsComponent/Error";
import Header from "../../headerComponent/Header";
import NavigationBar from "../../navigationBarComponent/internalNavigationBarComponent/NavigationBar";
import "./SegmentRedirection.style.scss";
import CreateSegmentComponent from "../../segmentSetupComponent/createSegmentComponent/CreateSegmentComponent";
import LeavingSetupPageConfirmationComponent from "../../modelComponent/leavingSetupPageConfirmationComponent/LeavingSetupPageConfirmationComponent";
import { useLocation } from "react-router-dom";
import { AnimatePresence } from "framer-motion/dist/framer-motion";
import { useSelector } from "react-redux";
import {
  SEGMENT,
  DUAL_NAVLINKS,
} from "../../constantComponent/Constants";
import { useAuth0 } from "@auth0/auth0-react";
import AuthLoading from "../../LoaderComponents/AuthLoading";
import SessionExpiredConfirmation from "../../modelComponent/sessionExpiredConfirmationComponent/SessionExpiredConfirmation";
import { logoutAuth0 } from "../../../tellurideExtRedux/reduxUtil";
import UnauthorizedAccess, {
  PageNotFound,
} from "../../unauthorizeComponent/UnauthorizeWrapper";
import {
  checkRightsIsEditable,
  getRightsKey,
} from "../../utilsComponent/CommonUtil";
const SegmentRedirection = () => {
  let [showOfferPopup, setShowOfferPopup] = useState(false);
  const [selectOffer, setSelectOffer] = useState(false);
  const location = useLocation();
  const scrollTargetDiv = useRef();
  const { logout } = useAuth0();
  const queryString = require("query-string");
  const groupId = queryString.parse(window.location.search).groupId;

  const OfferAnalyticsBlankSessionIdError = useSelector(
    (state) => state.OfferAnalyticsData.blankSessionIdError
  );
  const offerDetailsDataBlankSessionIdError = useSelector(
    (state) => state.offerDetailsData.blankSessionIdError
  );
  const metaDataBlankSessionIdError = useSelector(
    (state) => state.metaData.blankSessionIdError
  );
  const OfferAnalyticsSessionExpire = useSelector(
    (state) => state.OfferAnalyticsData.showExpiredSessionPopup
  );
  const offerDetailsDataSessionExpire = useSelector(
    (state) => state.offerDetailsData.showExpiredSessionPopup
  );
  const metaDataSessionExpire = useSelector(
    (state) => state.metaData.showExpiredSessionPopup
  );
  const offerDetailsPageData = useSelector((state) => state.offerDetailsData);
  const orgName = offerDetailsPageData?.authOrganizationName;

  //theme
  const toasterData = useSelector((state) => state.toasterData);
  const [theme, setTheme] = useState(toasterData.isDarkTheme);
  useEffect(() => {
    setTheme(toasterData.isDarkTheme);
  }, [toasterData]);
  //theme

  if (window.location.pathname.includes === "/segments/details" && groupId) {
    setShowOfferPopup(true);
  }
  // if(isNew!=="" && isNew!==undefined){
  //   showOfferPopup=false;
  //   setShowOfferPopup=false;
  // }

  useLayoutEffect(() => {
    scrollTargetDiv?.current?.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [location]);

  useEffect(() => {
    if (
      [
        OfferAnalyticsBlankSessionIdError,
        offerDetailsDataBlankSessionIdError,
        metaDataBlankSessionIdError,
      ].includes(true)
    ) {
      // logout({
      //   logoutParams: { returnTo: window.location.origin },
      // });
      logoutAuth0(logout, orgName);
    }
  }, [
    OfferAnalyticsBlankSessionIdError,
    offerDetailsDataBlankSessionIdError,
    metaDataBlankSessionIdError,
    logout,
  ]);

  return (
    <div data-theme={theme}>
      <AuthLoading>
        <div className="redirection-page-wrapper">
          <Header title="Segmentation" />
          <section className="display-flex-wrapper" ref={scrollTargetDiv}>
            <NavigationBar
              showOfferPopup={showOfferPopup}
              setShowOfferPopup={setShowOfferPopup}
              // selectOffer={selectOffer}
              // setSelectOffer={setSelectOffer}
              src={SEGMENT}
            />
            <AnimatePresence>
              {showOfferPopup && (
                <CreateSegmentComponent
                  // selectOffer={selectOffer}
                  setSelectOffer={setSelectOffer}
                  // showOfferPopup={showOfferPopup}
                  setShowOfferPopup={setShowOfferPopup}
                />
              )}
            </AnimatePresence>
            <div className="inner-section-wrapper">
              <ErrorBoundary FallbackComponent={Error} onError={(error) => {}}>
                <Routes>
                  {DUAL_NAVLINKS.find(
                    (dualNavLink) => dualNavLink.label === "Segmentation"
                  ).subLinks.map((SubLink, index) => {
                    if (SubLink.isRoute) {
                      return (
                        <>
                          <Route
                            key={index}
                            path={SubLink.path}
                            element={
                              checkRightsIsEditable(getRightsKey(SubLink?.rights)) ? (
                              <SubLink.Component.Name
                                setShowOfferPopup={setShowOfferPopup}
                              />
                              ) : (
                                <UnauthorizedAccess />
                              )
                            }
                          />
                          {SubLink.isActive && (
                            <Route
                              path="/"
                              element={
                                <Navigate
                                  to={
                                    "/" +
                                    window.location.pathname.split("/")[1] +
                                    SubLink.url
                                  }
                                  replace
                                />
                              }
                            />
                          )}
                        </>
                      );
                    }
                  })}
                  <Route
                    path="*"
                    Component={() => {
                      return <PageNotFound pageName={"Page not found !"} />;
                    }}
                  />
                </Routes>
              </ErrorBoundary>
            </div>
          </section>
        </div>
        <LeavingSetupPageConfirmationComponent />
        {offerDetailsDataSessionExpire === true ||
        OfferAnalyticsSessionExpire === true ||
        metaDataSessionExpire === true ? (
          <SessionExpiredConfirmation />
        ) : null}
      </AuthLoading>
    </div>
  );
};

export default SegmentRedirection;
