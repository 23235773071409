import {
  GET_CHART_DELIVERY_STATUS_FAILURE,
  GET_CHART_DELIVERY_STATUS_REQUEST,
  GET_CHART_DELIVERY_STATUS_SUCCESS,
  GET_CHART_DELIVERY_STATUS_SUCCESS_FAILURE,
  GET_JOURNEY_DETAILS_FAILURE,
  GET_JOURNEY_DETAILS_REQUEST,
  GET_JOURNEY_DETAILS_SUCCESS,
  GET_JOURNEY_DETAILS_SUCCESS_FAILURE,
  GET_JOURNEY_METADATA_FAILURE,
  GET_JOURNEY_METADATA_REQUEST,
  GET_JOURNEY_METADATA_SUCCESS,
  GET_JOURNEY_METADATA_SUCCESS_FAILURE,
  SAVE_JOURNEY_FAILURE,
  SAVE_JOURNEY_REQUEST,
  SAVE_JOURNEY_SUCCESS,
  SAVE_JOURNEY_SUCCESS_FAILURE,
  SET_CAMPAIGN_OFFER_CHANGE_STATUS,
  SET_CUSTOM_OFFER_CHANGE_STATUS,
  SET_CUSTOM_OFFER_NAME,
  UPDATE_JOURNEY_STATUS_FAILURE,
  UPDATE_JOURNEY_STATUS_REQUEST,
  UPDATE_JOURNEY_STATUS_SUCCESS,
  UPDATE_JOURNEY_STATUS_SUCCESS_FAILURE,
  GET_VARIATION_STATS_REQUEST,
  GET_VARIATION_STATS_SUCCESS,
  GET_VARIATION_STATS_SUCCESS_FAILURE,
  GET_VARIATION_STATS_FAILURE,
  SAVE_WINNER_REQUEST,
  SAVE_WINNER_SUCCESS,
  SAVE_WINNER_SUCCESS_FAILURE,
  SAVE_WINNER_FAILURE,
} from "./JourneyType";

const initialState = {
  journeyDetailsLoading: false,
  journeyDetailsResponse: "",
  journeyDetailsPageError: "",
  saveJourneyLoading: false,
  saveJourneyResponse: "",
  saveJourneyError: "",
  saveJourneyResponseStatus: "",
  updateJourneyStatusLoading: false,
  updateJourneyStatusResponse: "",
  updateJourneyStatus: "",
  journeyNumber: "",
  isOfferChanged: false,
  customOfferName: "",
  responseLoaded: 1,
  isChartLoading: false,
  deliveryStatusChartDetails: "",
  deliveryStatusChartDetailsError: false,
  apiError: "",
  apiSuccessError: "",
  journeyMetaDataLoading: false,
  journeyMetaDataResponse: "",
  journeyMetaDataTriggerEvents: "",
  journeyMetaDataErrorResponse: "",
  variationStatsLoading: false,
  variationStatsResponse: "",
  variationStatsError: "",
};

export const JourneyReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_JOURNEY_DETAILS_REQUEST:
      return {
        ...state,
        journeyDetailsLoading: true,
        journeyDetailsResponse: "",
        saveJourneyResponseStatus: "",
        responseLoaded: 2,
      };
    case GET_JOURNEY_DETAILS_SUCCESS:
      return {
        ...state,
        journeyDetailsLoading: false,
        journeyDetailsResponse: action.payload,
        updateJourneyStatus: action?.payload?.journeyData?.journeyStatus,
        saveJourneyResponseStatus: "",
        saveJourneyResponse: "",
        customOfferName: action?.payload?.journeyData?.journeyName,
        responseLoaded: 3,
      };
    case GET_JOURNEY_DETAILS_SUCCESS_FAILURE:
      return {
        ...state,
        journeyDetailsLoading: false,
        journeyDetailsPageError: action.payload,
        saveJourneyResponseStatus: "",
        saveJourneyResponse: "",
      };
    case GET_JOURNEY_DETAILS_FAILURE:
      return {
        ...state,
        journeyDetailsLoading: false,
        journeyDetailsPageError: action.payload,
        saveJourneyResponseStatus: "",
        saveJourneyResponse: "",
      };
    case SAVE_JOURNEY_REQUEST:
      return {
        ...state,
        saveJourneyLoading: true,
        saveJourneyResponseStatus: "",
      };
    case SAVE_JOURNEY_SUCCESS:
      return {
        ...state,
        saveJourneyLoading: false,
        saveJourneyResponse: action.payload,
        saveJourneyResponseStatus: action.payload.status,
        journeyNumber: action.payload.journeyNumber,
      };
    case SAVE_JOURNEY_SUCCESS_FAILURE:
      return {
        ...state,
        saveJourneyLoading: false,
        saveJourneyError: action.payload,
      };
    case SAVE_JOURNEY_FAILURE:
      return {
        ...state,
        saveJourneyLoading: false,
        saveJourneyError: action.payload,
      };
    case UPDATE_JOURNEY_STATUS_REQUEST:
      return {
        ...state,
        updateJourneyStatusLoading: true,
        updateJourneyStatusResponse: "",
        updateJourneyStatus: "",
      };
    case UPDATE_JOURNEY_STATUS_SUCCESS:
      return {
        ...state,
        updateJourneyStatusLoading: false,
        updateJourneyStatusResponse: action.payload,
        updateJourneyStatus: action.payload.journeyStatus,
      };
    case UPDATE_JOURNEY_STATUS_SUCCESS_FAILURE:
      return {
        ...state,
        updateJourneyStatusLoading: false,
        updateJourneyStatusError: action.payload,
      };
    case UPDATE_JOURNEY_STATUS_FAILURE:
      return {
        ...state,
        updateJourneyStatusLoading: false,
        updateJourneyStatusError: action.payload,
      };
    case SET_CUSTOM_OFFER_CHANGE_STATUS:
      return {
        ...state,
        isOfferChanged: action.payload,
      };
    case SET_CUSTOM_OFFER_NAME:
      return {
        ...state,
        customOfferName: action.payload,
      };
    case SET_CAMPAIGN_OFFER_CHANGE_STATUS: {
      return {
        ...state,
        isOfferChanged: action.payload,
      };
    }
    case GET_CHART_DELIVERY_STATUS_REQUEST:
      return {
        ...state,
        isChartLoading: true,
        deliveryStatusChartDetails: "",
        deliveryStatusChartDetailsError: false,
      };
    case GET_CHART_DELIVERY_STATUS_SUCCESS:
      return {
        ...state,
        isChartLoading: false,
        deliveryStatusChartDetails: action.payload,
        deliveryStatusChartDetailsError: false,
      };
    case GET_CHART_DELIVERY_STATUS_FAILURE:
      return {
        ...state,
        isChartLoading: false,
        apiError: action.payload,
        apiSuccessError: "",
        deliveryStatusChartDetails: "",
        deliveryStatusChartDetailsError: true,
      };
    case GET_CHART_DELIVERY_STATUS_SUCCESS_FAILURE:
      return {
        ...state,
        isChartLoading: false,
        apiError: "",
        apiSuccessError: action.payload,
        deliveryStatusChartDetails: "",
        deliveryStatusChartDetailsError: true,
      };
    case GET_JOURNEY_METADATA_REQUEST: {
      return {
        ...state,
        journeyMetaDataLoading: true,
      };
    }
    case GET_JOURNEY_METADATA_SUCCESS: {
      return {
        ...state,
        journeyMetaDataLoading: false,
        journeyMetaDataResponse: action.payload,
        journeyMetaDataTriggerEvents: action.payload.triggerEvents,
      };
    }
    case GET_JOURNEY_METADATA_SUCCESS_FAILURE: {
      return {
        ...state,
        journeyMetaDataLoading: false,
        journeyMetaDataErrorResponse: action.payload,
        journeyMetaDataResponse: "",
        journeyMetaDataTriggerEvents: "",
      };
    }
    case GET_JOURNEY_METADATA_FAILURE: {
      return {
        ...state,
        journeyMetaDataResponse: "",
        journeyMetaDataTriggerEvents: "",
      };
    }
    case SAVE_WINNER_REQUEST: {
      return {
        ...state,
        saveWinnerLoading: true,
      };
    }
    case SAVE_WINNER_SUCCESS: {
      return {
        ...state,
        saveWinnerLoading: false,
        saveWinnerResponse: action.payload,
      };
    }
    case SAVE_WINNER_SUCCESS_FAILURE: {
      return {
        ...state,
        saveWinnerLoading: false,
        saveWinnerError: action.payload,
      };
    }
    case SAVE_WINNER_FAILURE: {
      return {
        ...state,
        saveWinnerLoading: false,
        saveWinnerError: false,
        apiError: action.payload,
      };
    }
    case GET_VARIATION_STATS_REQUEST: {
      return {
        ...state,
        variationStatsLoading: true,
      };
    }
    case GET_VARIATION_STATS_SUCCESS: {
      return {
        ...state,
        variationStatsLoading: false,
        variationStatsResponse: action.payload,
      };
    }
    case GET_VARIATION_STATS_SUCCESS_FAILURE: {
      return {
        ...state,
        variationStatsLoading: false,
        variationStatsError: action.payload,
      };
    }
    case GET_VARIATION_STATS_FAILURE: {
      return {
        ...state,
        variationStatsLoading: false,
        apiError: action.payload,
      };
    }
    default:
      return state;
  }
};
