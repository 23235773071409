import React, { useEffect, useState } from "react";
import "./Discount.style.scss";
import {
	GenerateNewData,
	generateInitialJSON,
	generateRewardJSON,
	getCustomStyles,
} from "./DiscountUtil";
import { useDispatch, useSelector } from "react-redux";
import {
	TIER,
	MIN,
	ITEM,
	DISCOUNT_TITLE,
	DISCOUNT_TYPE_TITLE,
	APPLY_ON_TITLE,
	THRESHOLD_TYPE_TITLE,
	MAX,
	discountTypeOptions,
	applyOnOptions,
	applyOnAlias,
	thresholdOptions,
	thresholdAlias,
	discountTypeAlias,
	THRESHOLD,
	DOLLAR_OFF,
	PERCENT_OFF,
	TIER_UPPERCASE,
	DISCOUNT_TYPE,
	APPLY_ON,
	SPEND,
	QUANTITY,
} from "./DiscountConstant";
import {
	setErrorrsForValidations,
	updateRewardData,
} from "../../../tellurideExtRedux/offerDetails/OfferDetailsAction";
import Select from "react-select";
import { OFFER_DETAILS_STATUS_DRAFT } from "../../constantComponent/Constants";
import { images } from "../../imagesComponent/Images";
import PropertiesForm from "./PropertiesForm";
import {
	onChangeAction,
	onKeyPressAction,
} from "../../utilsComponent/CommonUtil";
import {
	OFFER_SETUP_DISCOUNT_ALL_ITEMS,
	OFFER_SETUP_DISCOUNT_APPLY_ON,
	OFFER_SETUP_DISCOUNT_SPEND_EXCRED,
} from "../offerSetupConstant";
const DiscountSection = ({ theme: theme, disabled }) => {
	const dispatch = useDispatch();
	const offerData = useSelector((state) => state.offerDetailsData);
	const initialRewardData = useSelector(
		(state) => state.offerDetailsData.rewardData
	);
	const [awardDetailData, setAwardDetaildata] = useState(
		initialRewardData === undefined
			? JSON.parse(JSON.stringify(generateInitialJSON()))
			: JSON.parse(JSON.stringify(generateRewardJSON(initialRewardData)))
	);
	const [actProperty, setActProperty] = useState([]);
	const [isSetDisable, setDisable] = useState(disabled);
	const [limit, setLimit] = useState("");
	const [id, setId] = useState("");
	const [discountType, setDiscountType] = useState("");
	const [applyOn, setApplyOn] = useState("");
	const [thresholdType, setThresholdType] = useState("");
	const [isUpdateVal, setIsUpdateVal] = useState(1);
	const [thresholdDropDownValue, setThresholdDropDownValue] = useState("");
	const [isDiscountOfferValidationError, setIdDiscountOfferValidationError] =
		useState(false);

	// useEffect(() => {
	// 	setDisable(
	// 		offerData?.offerStatus !== OFFER_DETAILS_STATUS_DRAFT ? true : false || disabled
	// 	);
	// }, [offerData?.offerStatus,disabled]);

	useEffect(() => {
		let ind = 0;
		if (
			awardDetailData &&
			awardDetailData?.action[0]?.actionProperties?.actionProperty?.length === 0
		) {
			addProperty();
		}

		awardDetailData &&
			awardDetailData?.action[0]?.actionProperties?.actionProperty?.map(
				(element) => {
					if (!element?.propertyType) {
						element?.property?.map((ele) => {
							if (ele.name === THRESHOLD) {
								if (ele.values.value[0] === OFFER_SETUP_DISCOUNT_SPEND_EXCRED) {
									setThresholdDropDownValue(SPEND);
								} else {
									setThresholdDropDownValue(QUANTITY);
								}
								ele.values.value[0]
									? ele.values.value[0] === ITEM
										? setThresholdType(ITEM)
										: setThresholdType(ele.values.value[0])
									: setThresholdType(OFFER_SETUP_DISCOUNT_SPEND_EXCRED);
							} else if (ele.name === OFFER_SETUP_DISCOUNT_APPLY_ON) {
								ele.values.value[0]
									? setApplyOn(ele.values.value[0])
									: setApplyOn(OFFER_SETUP_DISCOUNT_ALL_ITEMS);
							}
						});
					} else if (element?.propertyType === TIER.toUpperCase()) {
						element?.property?.map((ele) => {
							if (ele.name === DOLLAR_OFF) {
								setDiscountType(DOLLAR_OFF);
							} else if (ele.name === PERCENT_OFF) {
								setDiscountType(PERCENT_OFF);
							}
						});
						element.id = ++ind;
						element.isMaxInValid = false;
						element.isDollarPercentInValid = false;
						setActProperty((prevArr) => [...prevArr, element]);
					}
				}
			);
		setId(ind);
	}, []);

	useEffect(() => {
		dispatch(updateRewardData(awardDetailData));
	}, [awardDetailData, dispatch]);

	useEffect(() => {
		if (isUpdateVal !== 1) {
			updateMaxValues(thresholdDropDownValue);
		}
	}, [isUpdateVal]);

	useEffect(() => {
		let nonTierProps =
			awardDetailData.action[0].actionProperties.actionProperty.filter(
				(element) =>
					element.propertyType === undefined ||
					element.propertyType !== TIER_UPPERCASE
			);
		let tier = actProperty.filter(
			(element) => element.propertyType === TIER_UPPERCASE
		);

		let propertyDataWithValues = [];
		tier.map((tierData) => {
			let isNotEmpty = false;
			tierData.property.map((tierValue) => {
				if (tierValue.values.value[0]) {
					isNotEmpty = true;
				}
			});
			if (isNotEmpty) {
				let tierProperty = [];
				tierData.property.map((tierValue) => {
					if (tierValue.values.value[0]) {
						tierProperty.push(tierValue);
					}
				});
				if (tierProperty) {
					let newTierValue = JSON.parse(JSON.stringify(tierData));
					newTierValue.property = tierProperty;
					propertyDataWithValues.push(newTierValue);
				}
			}
		});
		for (var nt = 0; nt < nonTierProps.length; nt++) {
			propertyDataWithValues.push(nonTierProps[nt]);
		}
		let newTier = JSON.parse(JSON.stringify(propertyDataWithValues));
		let isNotValid = false;
		newTier.map((val, index) => {
			if (!isNotValid && (val.isMaxInValid || val.isDollarPercentInValid)) {
				isNotValid = true;
			} else if (isNotValid) {
				isNotValid = true;
			}
			offerData.offerValidationErrors.createOffer.discount = isNotValid;
			dispatch(setErrorrsForValidations(offerData.offerValidationErrors));
		});
		newTier.map((val) => {
			if (val.id) {
				delete val.id;
			}
			if (
				val.isMaxInValid ||
				!val.isMaxInValid ||
				val.isDollarPercentInValid ||
				!val.isDollarPercentInValid
			) {
				delete val.isMaxInValid;
				delete val.isDollarPercentInValid;
			}
		});
		setAwardDetaildata((prevAwardDetails) => {
			return {
				action: [
					{
						actionID: prevAwardDetails?.action[0]?.actionID,
						actionName: prevAwardDetails?.action[0]?.actionName,
						actionProperties: {
							actionProperty: newTier,
						},
					},
				],
			};
		});
	}, [actProperty, thresholdType, applyOn]);

	//add new properties form row
	const addProperty = () => {
		let maxVal = 0;
		if (actProperty.length > 0) {
			let temp = actProperty[actProperty.length - 1];
			if (temp.length !== 0) {
				temp.property.map((prop) => {
					if (prop.name === MAX) {
						maxVal = parseFloat(prop.values.value[0]);
					}
				});
			}
		}

		let newData = GenerateNewData(
			discountType,
			maxVal ? maxVal : "",
			thresholdDropDownValue
		);
		newData.id = id + 1;
		newData.isMaxInValid = false;
		newData.isDollarPercentInValid = false;
		setId(id + 1);
		setActProperty((prev) => [...prev, newData]);
	};

	//delete properties form row
	const removeProperty = (index) => {
		let temp = actProperty.filter((elm) => index !== elm.id);
		temp.length !== 0 && setActProperty([...temp]);
		setIsUpdateVal(isUpdateVal + 1);
	};

	//adds updated values from input fields to the DOM
	//add value from DOM to JSON
	const addValue = (index, value, type, limit, actIndex) => {
		let valtemp = value.replaceAll(",", "");
		let temp = actProperty.map((ele) => {
			if (ele.id === index) {
				let temp = ele.property.map((elm) => {
					if (elm.name === type) {
						if (elm.values.value.length === 0) {
							elm.values.value.push(valtemp);
						} else {
							elm.values.value = elm.values.value.map((val) => {
								return valtemp;
							});
						}
						return elm;
					} else {
						return elm;
					}
				});
				return {
					...ele,
					property: temp,
					propertyType: ele.propertyType,
				};
			} else {
				return ele;
			}
		});
		setActProperty([...temp]);
		setIsUpdateVal(isUpdateVal + 1);
	};

	const disableAddBtn = (property) => {
		let isDisableAddBtn = false;
		property.map((prop) => {
			if (prop.name === MAX) {
				if (!prop.values.value[0]) {
					isDisableAddBtn = true;
				}
			} else if (prop.name === DOLLAR_OFF || prop.name === PERCENT_OFF) {
				if (!prop.values.value[0]) {
					isDisableAddBtn = true;
				}
			} else {
				isDisableAddBtn = false;
			}
		});
		return isDisableAddBtn;
	};

	function updateMaxValues(thresholdType) {
		let addVal = thresholdType === QUANTITY ? 1 : 0.01;
		let maxValue = 0;
		let newMaxVal = 0;
		let temp = actProperty.map((ele, elementIndex) => {
			let temp = ele.property.map((elm) => {
				if (elm.name === MAX) {
					newMaxVal = elm.values.value[0];
				}
				if (elm.name === MIN && elementIndex !== 0) {
					elm.values.value = elm.values.value.map((val) => {
						if (maxValue) {
							return parseFloat(maxValue) + addVal;
						} else {
							return val;
						}
					});
				}
				return elm;
			});
			maxValue = newMaxVal;
			return {
				...ele,
				property: temp,
				propertyType: ele.propertyType,
			};
		});
		setActProperty([...temp]);
	}

	function handleChangeSelect({ value }, { name }) {
		let e = { target: { name: name, value: value } };
		if (e.target.name === DISCOUNT_TYPE) {
			setDiscountType(e.target.value);
			let newData = GenerateNewData(
				e.target.value,
				undefined,
				thresholdDropDownValue
			);
			newData.id = id + 1;
			newData.isMaxInValid = false;
			newData.isDollarPercentInValid = false;
			setId(id + 1);
			setActProperty([newData]);
		}
		if (e.target.name === THRESHOLD) {
			setThresholdType(e.target.value);
			let newData = GenerateNewData(discountType, undefined, value);
			newData.id = id + 1;
			newData.isMaxInValid = false;
			newData.isDollarPercentInValid = false;
			setId(id + 1);
			setActProperty([newData]);
			if (e.target.value === OFFER_SETUP_DISCOUNT_SPEND_EXCRED) {
				setThresholdDropDownValue(SPEND);
			} else {
				setThresholdDropDownValue(QUANTITY);
			}
		}
		awardDetailData &&
			awardDetailData?.action[0]?.actionProperties?.actionProperty?.map(
				(element) => {
					if (!element?.propertyType) {
						element?.property?.map((ele) => {
							if (ele.name === e.target.name) {
								ele.values.value[0] = e.target.value;
								if (ele.name === APPLY_ON) {
									setApplyOn(e.target.value);
								} else if (ele.name === THRESHOLD) {
									setThresholdType(e.target.value);
								}
							}
						});
					}
				}
			);
	}

	return (
		<>
			<div className="discount-reward-details-title setup-page-section-title">
				{DISCOUNT_TITLE}
			</div>
			<div className="discount-spend-section">
				<div className="discount-spend-section-inner">
					<div className="discount-spend-section-fieldset">
						<fieldset
							className="selector-fieldset reward-fieldset dropdown"
							disabled={disabled}
						>
							<legend>{DISCOUNT_TYPE_TITLE}</legend>
							<Select
								defaultInputValue={discountType}
								value={
									discountType && {
										label: discountTypeAlias[discountType],
										value: discountType,
									}
								}
								name={DISCOUNT_TYPE}
								options={discountTypeOptions}
								styles={getCustomStyles(theme)}
								onChange={handleChangeSelect}
								isDisabled={disabled}
							/>
						</fieldset>
						<>
							<fieldset
								className="selector-fieldset reward-fieldset dropdown ml-84"
								disabled={disabled}
							>
								<legend>{APPLY_ON_TITLE}</legend>
								<Select
									defaultInputValue={applyOn}
									value={
										applyOn && {
											label: applyOnAlias[applyOn],
											value: applyOn,
										}
									}
									name={APPLY_ON}
									options={applyOnOptions}
									styles={getCustomStyles(theme)}
									onChange={handleChangeSelect}
									isDisabled={disabled}
								/>
							</fieldset>
						</>
					</div>
					<div className="mt-23 discount-spend-section-fieldset">
						<fieldset
							className="selector-fieldset reward-fieldset dropdown"
							disabled={disabled}
						>
							<legend>{THRESHOLD_TYPE_TITLE}</legend>
							<Select
								defaultInputValue={thresholdType}
								value={
									thresholdType && {
										label: thresholdAlias[thresholdType],
										value: thresholdType,
									}
								}
								name={THRESHOLD}
								options={thresholdOptions}
								styles={getCustomStyles(theme)}
								onChange={handleChangeSelect}
								isDisabled={disabled}
							/>
						</fieldset>
						<fieldset className="empty-div"></fieldset>
					</div>
					<div className="minMxOffDiscSection">
						{actProperty.map((ele, currentIndex) => {
							let index = ele.id;
							let place = actProperty.indexOf(ele);
							let minVal = 0;
							let maxVal = 0;
							let isLastIndex = false;
							let dollerPercentOffVal = 0;
							currentIndex + 1 === actProperty.length
								? (isLastIndex = true)
								: (isLastIndex = false);
							return (
								<div className="row-2">
									{/* {JSON.stringify(ele)} */}

									{ele.property.map((prop) => {
										if (prop.name === MIN) {
											minVal = prop.values.value[0];
										}
										if (prop.name === MAX) {
											maxVal = prop.values.value[0];
										}
										if (prop.name === DOLLAR_OFF || prop.name === PERCENT_OFF) {
											dollerPercentOffVal = prop.values.value[0];
										}
										return (
											<PropertiesForm
												prop={prop}
												onChangeAction={onChangeAction}
												onKeyPressAction={onKeyPressAction}
												limit={limit}
												setLimit={setLimit}
												addValue={addValue}
												index={index}
												discountType={discountType}
												minVal={minVal}
												maxVal={maxVal}
												currentIndex={currentIndex}
												isLastIndex={isLastIndex}
												ele={ele}
												dollerPercentOffVal={dollerPercentOffVal}
												thresholdDropDownValue={thresholdDropDownValue}
												isSetDisable={disabled}
											/>
										);
									})}
									{place === actProperty.length - 1 ? (
										<>
											{actProperty.length > 1 && (
												<div className="col-3 mb-15">
													<img
														onClick={() => {
															if (!disabled) {
																removeProperty(index);
															}
														}}
														src={images.trashImage}
														alt="delete-icon"
													></img>
												</div>
											)}
											<div className="col-3 ml-13 mb-15">
												<img
													onClick={() => {
														if (!disabled) {
															if (!disableAddBtn(ele.property)) {
																addProperty();
															}
														}
													}}
													src={images.addImage}
													alt="add-icon"
													className={
														disableAddBtn(ele.property) ? "disabled" : ""
													}
												></img>
											</div>
										</>
									) : (
										<>
											<div className="col-3 mb-15">
												<img
													onClick={() => {
														if (!disabled) {
															removeProperty(index);
														}
													}}
													src={images.trashImage}
													alt="delete-icon"
												></img>
											</div>
											<div className="col-3 mb-5 ml-32"></div>
										</>
									)}
								</div>
							);
						})}
					</div>
				</div>
			</div>
		</>
	);
};

export default DiscountSection;
