import { React, useState } from "react";
import "./CustomComponent.scss";
import { useDispatch,useSelector } from "react-redux";
import { message } from "antd";

export const CustomHeader = ({ toggleChatbot, setMinimizeChatbot }) => {
  // console.log("Chat window props " + toggleChatbot);
  // console.log("minimize window props" + setMinimizeChatbot);
  const metaDataSessionExpire = useSelector(
    (state) => state.metaData.showExpiredSessionPopup
  )
  console.log( "metaDataSessionExpire" + metaDataSessionExpire);
  const dispatch = useDispatch();
  return (
    <div className="react-chatbot-kit-chat-header">
      <img
        src="/static/media/telluride-logo-white.e04d4092.png"
        alt="Header Icon"
        className="header-icon"
      />
      <div className="header-title">Offer Assist</div>
      {
        !metaDataSessionExpire ? (
          <div className="header-buttons">
        <button
          className="chatbot-minimize-button"
          onClick={() => {
            toggleChatbot(false);
            setMinimizeChatbot(true);
          }}
        >
          &#8211;
        </button>
        <button
          className="chatbot-close-button"
          onClick={() => {
            toggleChatbot(false);
            setMinimizeChatbot(false);
            localStorage.removeItem("chat_messages");
          }}
        >
          &times;
        </button>
      </div>
        ) : (
          <div className="header-buttons-disable">
        <button
          className="chatbot-minimize-button"
        >
          &#8211;
        </button>
        <button
          className="chatbot-close-button" >
          &times;
        </button>
      </div>
        )
      }
    </div>
  );
};

export const CustomInput = () => {
  console.log(message);
  const [userInput, setUserInput] = useState('');
  const handleInputChange = (message) => {
   const inputSize = message.length
   userInput(inputSize)
   console.log(inputSize)
  };

  return (
    <div class="react-chatbot-kit-chat-input-container">
      <form class="react-chatbot-kit-chat-input-form">
        <input
          class="react-chatbot-kit-chat-input"
          placeholder="Ask your question here"
          value={userInput}
          onChange={handleInputChange}
        />
          {
            userInput < 5 ?(
              <button className="react-chatbot-kit-chat-btn-send-disabled"> 
                <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
            class="react-chatbot-kit-chat-btn-send-icon"
          >
            <path d="M476 3.2L12.5 270.6c-18.1 10.4-15.8 35.6 2.2 43.2L121 358.4l287.3-253.2c5.5-4.9 13.3 2.6 8.6 8.3L176 407v80.5c0 23.6 28.5 32.9 42.5 15.8L282 426l124.6 52.2c14.2 6 30.4-2.9 33-18.2l72-432C515 7.8 493.3-6.8 476 3.2z"></path>
          </svg>
        </button>
            ) : (
              <button className="react-chatbot-kit-chat-btn-send"> 
                <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
            class="react-chatbot-kit-chat-btn-send-icon"
          >
            <path d="M476 3.2L12.5 270.6c-18.1 10.4-15.8 35.6 2.2 43.2L121 358.4l287.3-253.2c5.5-4.9 13.3 2.6 8.6 8.3L176 407v80.5c0 23.6 28.5 32.9 42.5 15.8L282 426l124.6 52.2c14.2 6 30.4-2.9 33-18.2l72-432C515 7.8 493.3-6.8 476 3.2z"></path>
          </svg>
        </button>
            )
          }
         
      </form>
    </div>
  );
};
