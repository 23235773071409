import React, { useEffect, useState } from "react";
import "./TestProgressTableList.style.scss";

import { MEMBER_GROUP_HEADER } from "../../../utilsComponent/GenericRules/GenericRulesConstants";
import { motion } from "framer-motion/dist/framer-motion";
import { emptyData } from "../../../constantComponent/DesignConstant";
import {
  OFFER_DETAILS_STATUS_ACTIVATED,
  SAVE_WINNER_CAMPAIGN_STATUS,
} from "../../../constantComponent/Constants";
import { useSelector } from "react-redux";
import { isWinnerSelectionAllowed } from "../../campaignBuilderComponent/campaignPopups/EmailContentSelectionPopup/EmailContentSelectionUtil";

const TestProgressTableList = ({
  isDisabled,
  variationsData,
  winnerVariation,
  setWinnerVariation,
  winnerVariationId,
}) => {
  const [isDataEmpty, setIsDataEmpty] = useState(variationsData?.length === 0);
  const journeyData = useSelector((state) => state.journeyDetailsData);
  const journeyStatus =
    journeyData?.journeyDetailsResponse?.journeyData?.journeyStatus;
  const journeyType =
    journeyData?.journeyDetailsResponse?.journeyData?.journeyType;
  const [selectedWinner, setSelectedWinner] = useState(winnerVariationId);
  return (
    <>
      {!isDataEmpty ? (
        <div className={`modal-container-progress-list "multi"`}>
          <div className="progress-list-group">
            <div className="attribute-list-container-member-group">
              <div className="table-body-header-wrapper">
                <div className="attribute-list-header-member-group membergroup">
                  <>
                    <div className="attribute-col-member-group generic-table-col-name ml-10">
                      {"Variant"}
                    </div>
                    <div className="description-col generic-table-col-name">
                      {"Subject"}
                    </div>
                    <div className="description-col generic-table-col-name">
                      {"Delivered"}
                    </div>
                    <div className="description-col generic-table-col-name">
                      {"Opened"}
                    </div>
                    <div className="description-col generic-table-col-name">
                      {"Confidence level"}
                    </div>
                    <div className="description-col generic-table-col-name">
                      {"Statistically Significant"}
                    </div>
                  </>
                </div>
                <div className="attribute-list-wrapper">
                  <div className="attribute-list-member-group membergroup">
                    {variationsData?.map((item, index) => {
                      return (
                        <div
                          onClick={() => {
                            if (
                              isWinnerSelectionAllowed(
                                journeyType,
                                journeyStatus
                              )
                            ) {
                              setWinnerVariation(item);
                              setSelectedWinner(item.variationId);
                            }
                          }}
                          className={`attribute-container-member-group ${
                            winnerVariation.variationId === item.variationId ||
                            selectedWinner === item.variationId
                              ? "selected"
                              : null
                          }`}
                        >
                          <div className="attribute-col-member-group ml-10">
                            <div className="group-name  generic-table-col-data">
                              {`variant${index + 1}`}
                            </div>
                          </div>
                          <div className="description-member-group generic-table-col-data">
                            {item?.subject}
                          </div>
                          <div className="description-member-group generic-table-col-data">
                            {item?.delivered}
                          </div>
                          <div className="description-member-group generic-table-col-data">
                            {item?.impression}
                          </div>
                          <div className="description-member-group generic-table-col-data">
                            {item?.confidence_level}
                          </div>
                          <div className="description-member-group generic-table-col-data">
                            {item?.statistically_signifcant}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="empty-table-container">{emptyData()}</div>
      )}
    </>
  );
};

export default TestProgressTableList;
