export const DASHBOARD_COLUMNS = [
  {
    title: "Offer ID",
    key: "offerID",
    type: "string",
    visible: true,
    filter: true,
    sort: true,
    minWidth: 150,
    initialWidth: 150,
    defaultWidth: 150,
    resizable: false,
    reqBodyKey: { attrName: "offerID", isAllowed: true },
  },
  {
    title: "Offer Name",
    key: "offerName",
    type: "string",
    visible: true,
    filter: true,
    sort: true,
    minWidth: 150,
    initialWidth: 150,
    defaultWidth: 150,
    resizable: false,
    reqBodyKey: { attrName: "offerName", isAllowed: true },
  },
  {
    title: "Offer Type",
    key: "offerType",
    type: "select",
    visible: true,
    filter: true,
    sort: true,
    options: ["Points Earn", "Price Discount", "Loyalty Cash"],
    rights: [
      {
        key: "Points Earn",
        rights: ["filter","loyalty","dashboard","offertype","pointsearn"],
      },
      {
        key: "Price Discount",
        rights: ["filter","loyalty","dashboard","offertype","pricediscount"],
      },
      {
        key: "Loyalty Cash",
        rights: ["filter","loyalty","dashboard","offertype","loyaltycash"],
      },
      {
        key: "Base Earn",
        rights: ["filter","loyalty","dashboard","offertype","baseearn"],
      },
      {
        key: "Base Redeem",
        rights: ["filter","loyalty","dashboard","offertype","baseredeem"],
      },
      {
        key: "Mission Offer",
        rights: ["filter","loyalty","dashboard","offertype","missionoffer"],
      },
      {
        key: "Custom Offer",
        rights: ["filter","loyalty","dashboard","offertype","customoffer"],
      },
      {
        key: "Non-purchase Points Earn",
        rights: ["filter","loyalty","dashboard","offertype","nonpurchasepointsearn"],
      },
    ],
    multi: false,
    minWidth: 100,
    initialWidth: 100,
    defaultWidth: 100,
    resizable: false,
    reqBodyKey: { attrName: "offerType", isAllowed: true },
  },
  {
    title: "Offer Status",
    key: "offerStatus",
    type: "select",
    visible: true,
    filter: true,
    sort: true,
    multi: false,
    options: ["ACTIVE", "INACTIVE", "DRAFT", "EXPIRED"],
    minWidth: 100,
    initialWidth: 100,
    defaultWidth: 100,
    resizable: false,
    reqBodyKey: { attrName: "offerStatus", isAllowed: true },
  },
  {
    title: "Point Amount",
    key: "pointsAmount",
    type: "string",
    visible: true,
    filter: false,
    sort: true,
    minWidth: 100,
    initialWidth: 100,
    defaultWidth: 100,
    resizable: false,
    reqBodyKey: { attrName: "", isAllowed: false },
  },
  {
    title: "Members Benefited",
    key: "membersBenefited",
    type: "string",
    visible: true,
    filter: false,
    sort: true,
    minWidth: 100,
    initialWidth: 100,
    defaultWidth: 100,
    resizable: false,
    reqBodyKey: { attrName: "", isAllowed: false },
  },
  {
    title: "Sales Impacted",
    key: "salesImpacted",
    type: "string",
    visible: true,
    filter: false,
    sort: true,
    minWidth: 100,
    initialWidth: 100,
    defaultWidth: 100,
    resizable: false,
    reqBodyKey: { attrName: "", isAllowed: false },
  },
];
export const DASHBOARD_HEADER = "Let's Get Started";
export const DASHBOARD_HEADER_DESCRIPTION =
  "Create exciting offers to build long lasting relationships with your members.";
export const DASHBOARD = "DASHBOARD";
export const OFFER_DASHBOARD_REQ_BODY_KEY = "filter";
export const OFFER_DASHBOARD_RES_BODY_KEY = "offerPerformance";
export const OFFER_DASHBOARD_HEADER_TEXT = "Offer Performance";
export const dashboardRolePermissionsNames = ["Create Offer", "View Offer"];
