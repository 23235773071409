export const values_in_graph = [
  {
    label: "Sent",
    color: "#0275FC",
  },
  {
    label: "Delivered",
    color: "#38B435",
  },
  {
    label: "Opened",
    color: "#6979a1",
  },
  {
    label: "Clicked",
    color: "#f6ab19",
  },
];

export const getDataSetJson = (label, color) => {
  return {
    barPercentage: 0.4,
    label: label,
    data: [],
    backgroundColor: color,
    font: {
      family: "Poppins",
    },
  };
};

export const testProgressLabelAlias = {
  Sent: "sent",
  Delivered: "delivered",
  Opened: "impression",
  Clicked: "click",
};
