import React, { useEffect, useState } from "react";
import "./Redeem.style.scss";
import {
  generateInitialJSON,
  getCustomStyles,
  getInputOfferFor,
  getInputOfferRange,
} from "./RedeemUtil";
import { useDispatch, useSelector } from "react-redux";
import {
  OFFER_FOR,
  OFFER_RANGE,
  TIER,
  offerRangeOptions,
  offerForOptions,
  VALUE,
  MIN,
  infoOfferFor,
  SPEND,
  TRIP,
  ITEM,
  FLAT,
  QUANTITY,
  REDEEM_TITLE,
  OFFER_FOR_TITLE,
  OFFER_RANGE_TITLE,
  offerRangeAlias,
  offerForAlias,
  MULTIPLIER,
  BASE_REDEEM_TITLE,
} from "./RedeemConstant";
import {
  setErrorrsForValidations,
  updateRewardData,
} from "../../../tellurideExtRedux/offerDetails/OfferDetailsAction";
import Select from "react-select";
import {
  BASE_REDEEM,
  OFFER_DETAILS_STATUS_DRAFT,
} from "../../constantComponent/Constants";
import { CAP } from "../offerSetupConstant";
const RedeemSection = ({ theme,disabled }) => {
  const dispatch = useDispatch();
  const offerData = useSelector((state) => state.offerDetailsData);
  const offerType = offerData?.offerData?.offerType;
  const initialRewardData = useSelector(
    (state) => state.offerDetailsData.rewardData
  );
  const [awardDetailData, setAwardDetaildata] = useState(
    initialRewardData === undefined
      ? JSON.parse(JSON.stringify(generateInitialJSON(offerType)))
      : JSON.parse(JSON.stringify(initialRewardData))
  );
  const [offerFor, setOfferFor] = useState("");
  const [offerRange, setOfferRange] = useState("");
  const [offerForValue, setOfferForValue] = useState("");
  const [offerRangeValue, setOfferRangeValue] = useState("");
  const [actProperty, setActProperty] = useState([]);
  const [isSetDisable, setDisable] = useState(disabled);
  const [showInfo, setShowInfo] = useState(false);
  // useEffect(() => {
  //   setDisable(
  //     offerData?.offerStatus !== OFFER_DETAILS_STATUS_DRAFT ? true : false || disabled
  //   );
  // }, [offerData?.offerStatus,disabled]);
  useEffect(() => {
    offerData.offerValidationErrors.createOffer.discount = false;
    dispatch(setErrorrsForValidations(offerData.offerValidationErrors));

    awardDetailData &&
      awardDetailData?.action[0]?.actionProperties?.actionProperty?.map(
        (element) => {
          if (!element?.propertyType) {
            element?.property?.map((ele) => {
              if (ele.name === OFFER_FOR) {
                ele.values.value[0]
                  ? ele.values.value[0] === ITEM
                    ? setOfferFor(QUANTITY)
                    : setOfferFor(ele.values.value[0])
                  : setOfferFor(SPEND);
              } else if (ele.name === OFFER_RANGE) {
                ele.values.value[0]
                  ? setOfferRange(ele.values.value[0])
                  : setOfferRange(FLAT);
              }
            });
          } else if (element?.propertyType === TIER.toUpperCase()) {
            element?.property?.map((ele) => {
              if (ele.name === VALUE) {
                setOfferRangeValue(
                  ele.values.value[0]
                    ? ele.values.value[0].toLocaleString("en-US")
                    : ele.values.value[0]
                );
              } else if (ele.name === MIN) {
                setOfferForValue(ele.values.value[0]);
              }
            });
            setActProperty((prevArr) => [...prevArr, element]);
          }
        }
      );
  }, []);

  //update offerFor and offerRange if user chose from the dropdown
  useEffect(() => {
    if (awardDetailData) {
      setAwardDetaildata((prevAwardDetails) => {
        return {
          action: [
            {
              actionID: prevAwardDetails?.action[0]?.actionID,
              actionName: prevAwardDetails?.action[0]?.actionName,
              actionProperties: {
                actionProperty:
                  prevAwardDetails?.action[0]?.actionProperties?.actionProperty?.map(
                    (element) => {
                      if (!element?.propertyType) {
                        let prop = element?.property?.map((ele) => {
                          if (ele.name === OFFER_FOR) {
                            offerFor === QUANTITY
                              ? (ele.values.value[0] = ITEM)
                              : (ele.values.value[0] = offerFor);
                            return ele;
                          } else if (ele.name === OFFER_RANGE) {
                            ele.values.value[0] = offerRange;
                            return ele;
                          }
                          return ele;
                        });
                        return {
                          ...element,
                          property: prop,
                        };
                      } else if (element?.propertyType === TIER.toUpperCase()) {
                        let prop = element?.property?.map((ele) => {
                          if (ele.name === VALUE) {
                            ele.values.value[0] = offerRangeValue
                              ? offerRangeValue.replace(/,/g, "")
                              : "0";

                            ele.values.value[0] =
                              ele.values.value[0].includes(".") === false
                                ? ele.values.value[0] + ".00"
                                : ele.values.value[0];
                            return ele;
                          } else if (ele.name === MIN) {
                            let value = "";
                            if (offerFor === SPEND && offerForValue === "") {
                              value = 0.01;
                            } else if (
                              (offerFor === TRIP || offerFor === QUANTITY) &&
                              offerForValue === ""
                            ) {
                              value = "1";
                            } else {
                              if (
                                offerFor === SPEND &&
                                offerForValue &&
                                offerForValue.includes(".") === false
                              ) {
                                value = offerForValue + ".00";
                              } else {
                                value = offerForValue;
                              }
                            }
                            ele.values.value[0] = value
                              ? value.toString().replace(/,/g, "")
                              : "";
                            return ele;
                          } else if (ele.name === CAP) {
                            ele.values.value[0] = offerRangeValue
                              ? offerRangeValue.replace(/,/g, "")
                              : "0";

                            ele.values.value[0] =
                              ele.values.value[0].includes(".") === false
                                ? ele.values.value[0] + ".00"
                                : ele.values.value[0];
                            return ele;
                          }
                          return ele;
                        });
                        return {
                          ...element,
                          property: prop,
                        };
                      }
                      return element;
                    }
                  ),
              },
            },
          ],
        };
      });
    }
  }, [offerRange, offerFor, offerRangeValue, offerForValue]);

  useEffect(() => {
    dispatch(updateRewardData(awardDetailData));
  }, [awardDetailData, dispatch]);

  function handleChangeSelect({ value }, { name }) {
    let e = { target: { name: name, value: value } };
    name === OFFER_RANGE ? setOfferRange(value) : setOfferFor(value);
    name === OFFER_RANGE ? setOfferRangeValue("") : setOfferForValue("");
  }
  return (
    <>
      <div className="redeem-details-title setup-page-section-title">
        {offerType === BASE_REDEEM ? BASE_REDEEM_TITLE : REDEEM_TITLE}
      </div>
      <div className="redeem-spend-section">
        <div className="redeem-spend-section-inner">
          {offerType !== BASE_REDEEM ? (
            <div className="spend-section-fieldset">
              <fieldset
                className="selector-fieldset reward-fieldset dropdown"
                disabled={disabled}
              >
                <legend>{OFFER_FOR_TITLE}</legend>
                <Select
                  defaultInputValue={offerFor}
                  value={
                    offerFor && {
                      label: offerForAlias[offerFor],
                      value: offerFor,
                    }
                  }
                  name="OFFER_FOR"
                  options={offerForOptions}
                  styles={getCustomStyles(theme)}
                  onChange={handleChangeSelect}
                  isDisabled={disabled}
                />
              </fieldset>
              <div className="threshold">
                {offerFor &&
                  getInputOfferFor(
                    offerFor,
                    offerForValue,
                    setOfferForValue,
                    disabled
                  )}
                <p>{infoOfferFor[offerFor]}</p>
              </div>
            </div>
          ) : null}
          <div className="row-2">
            {offerRange &&
              getInputOfferRange(
                offerRange,
                offerRangeValue,
                setOfferRangeValue,
                disabled,
                offerType !== BASE_REDEEM
                  ? "Dollar Value of redeem points"
                  : "Dollar Value per 1000 points"
              )}
          </div>
        </div>
      </div>
    </>
  );
};

export default RedeemSection;
