import React from "react";
import { ZERO } from "../GenericRules/GenericRulesConstants";
import { emptyData } from "../../constantComponent/DesignConstant";
import { tableLoader } from "../../LoaderComponents/loadingDesignConstant";
import { OFFER_STATUS, STATUS, TOOLTIP_ACTIVE } from "./GenericTableConstants";
export function TableBody({
	list,
	isDataLoading,
	columns,
	SHOW_TOOLTIP,
	ROW_CLICKABLE,
	ROW_CLICK_HANDLER,
	layoutLoading,
}) {
	return (
		<div className="table-body">
			{isDataLoading === true
				? tableLoader(columns.filter((col) => col.visible === true),layoutLoading)
				: list && list.length > ZERO
				? list.map((row, index) => {
						return (
							<React.Fragment key={index}>
								<div
									className={`row ${ROW_CLICKABLE === true ? "clickable" : ""}`}
									key={row.key}
									onClick={() => {
										if (ROW_CLICKABLE === true) {
											ROW_CLICK_HANDLER && ROW_CLICK_HANDLER(row);
										}
									}}
								>
									<>
										{columns.map((col, colIndex) => {
											if (row.hasOwnProperty(col.key) && col.visible === true) {
												return (
													<div
														className={`data ${SHOW_TOOLTIP && TOOLTIP_ACTIVE}`}
														style={
															layoutLoading
																? {
																		width: "100%",
																  }
																: {
																		maxWidth: col.minWidth,
																		minWidth: col.minWidth,
																  }
														}
														key={col.key}
														data-tooltip={row[col.key]}
													>
														{
															<div
																className={`row-data ${
																	[
																		columns.length - 1,
																		columns.length - 2,
																		columns.length - 3,
																	].includes(colIndex) && "left"
																} ${
																	[OFFER_STATUS, STATUS].includes(col.key) ===
																	true
																		? `status ${row[col.key].toUpperCase()}`
																		: ""
																}
                                `}
																data-tooltip={row[col.key]}
															>{`${row[col.key]}`}</div>
														}
													</div>
												);
											} else if (col.visible === true) {
												return (
													<div
														className="data"
														key={col.key}
														style={
															layoutLoading
																? {
																		width: "100%",
																  }
																: {
																		maxWidth: col.minWidth,
																		minWidth: col.minWidth,
																  }
														}
													>
														{<div className="row-data">{""}</div>}
													</div>
												);
											}
										})}
									</>
								</div>
							</React.Fragment>
						);
				  })
				: emptyData()}
		</div>
	);
}
