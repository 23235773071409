import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
	resetAttributeDetailsResponse,
	resetCreateAttributeResponse,
} from "../../../tellurideExtRedux";
import {
	CONFIGURE_ATTRIBUTE_DETAILS,
	DETAILS,
} from "../../constantComponent/PathConstants";
import GenericTable from "../../utilsComponent/TableUtil/GenericTable";
import {
	ATTRIBUTE_REQ_BODY_KEY,
	ATTRIBUTE_RES_BODY_KEY,
	COLUMNS,
	VIEW_ATTRIBUTE,
	VIEW_ATTRIBUTE_PLACEHOLDER,
} from "./AttributeListConstants";
import { REDUX } from "../../constantComponent/Constants";
import { getRightsKey } from "../../utilsComponent/CommonUtil";

function AttributeListComponent() {
	const navigate = useNavigate();
	function btnClickHandler() {
		navigate(
			"/" + window.location.pathname.split("/")[1] + CONFIGURE_ATTRIBUTE_DETAILS
		);
	}
	const advanceSearchHeader = {
		searchBar: true,
		globalFilter: true,
		dateType: false,
		dateRange: false,
		tableHeaderBtn: {
			label: "Create Attribute",
			clickHandler: btnClickHandler,
			rights: getRightsKey([
				"button",
				"configure",
				"attribute",
				"createattribute",
			]),
		},
	};
	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(resetAttributeDetailsResponse());
		dispatch(resetCreateAttributeResponse());
	}, []);

	function handleRowClick(row) {
		navigate({
			pathname:
				"/" +
				window.location.pathname.split("/")[1] +
				"/configure/attribute" +
				DETAILS,
			search: `?code=${row.code}`,
		});
	}

	return (
		<GenericTable
			COLUMNS={COLUMNS}
			FILTER_SHOW_LIMIT={1}
			SHOW_HEADER={true}
			ADVANCE_SEARCH_PLACEHOLDER={VIEW_ATTRIBUTE_PLACEHOLDER}
			API_URL={process.env.REACT_APP_GET_ATTRIBUTES_LIST}
			TABLE_SRC={VIEW_ATTRIBUTE}
			SHOW_HEADER_DASHBOARD={false}
			SHOW_TOOLTIP={false}
			ROW_CLICKABLE={true}
			ROW_CLICK_HANDLER={handleRowClick}
			ADVANCE_SEARCH_HEADER={advanceSearchHeader}
			REQUEST_BODY_KEY={ATTRIBUTE_REQ_BODY_KEY}
			RESPONSE_BODY_KEY={ATTRIBUTE_RES_BODY_KEY}
			IS_SEARCH_ALLOWED={true}
			SAVE_SEARCHED_FILTER={{
				isSaved: true, //  true || false => default : true
				storage: REDUX, // redux  || local || session  => default : "redux"
			}}
		/>
	);
}
export default AttributeListComponent;
