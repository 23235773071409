import React, { useEffect, useState } from "react";
import CodeMirror from "@uiw/react-codemirror";
import { json } from "@codemirror/lang-json";
import { linter } from "@codemirror/lint";
import Ajv from "ajv";
import { githubLight, githubDark } from "@uiw/codemirror-theme-github";
import { DARK, LIGHT } from "../../constantComponent/Constants";
import "./JsonEditor.style.scss";
import { is } from "date-fns/locale";

const ajv = new Ajv();
const schema = {};

const validate = ajv.compile(schema);

const JsonEditor = ({
	value,
	height,
	onChange,
	theme = LIGHT,
	setValidationErrors,
	jsonKey,
	isDisabled,
}) => {
	const [error, setError] = useState(false);
	useEffect(() => {
		setValidationErrors((prev) => {
			return {
				...prev,
				[jsonKey]: {
					status: error,
					msg: error ? "Invalid JSON" : "",
				},
			};
		});
	}, [error]);
	const jsonLinter = () => {
		return linter((view) => {
			const value = view.state.doc.toString();
			const diagnostics = [];
			try {
				JSON.parse(value);
				setError(false);
			} catch (e) {
				diagnostics.push({
					from: 0,
					to: value.length,
					severity: "error",
					message: "Invalid JSON: " + e.message,
				});
				setError(true);
				return diagnostics;
			} finally {
				const valid = validate(json);
				if (!valid) {
					validate.errors.forEach((error) => {
						const { message, instancePath } = error;
						const path = instancePath.split("/").slice(1).join(".");
						const position = value.indexOf(path);
						diagnostics.push({
							from: position !== -1 ? position : 0,
							to: position !== -1 ? position + path.length : value.length,
							severity: "error",
							message: message,
						});
					});
				} else {
				}
				return diagnostics;
			}
		});
	};
	return (
		<div className="code-mirror-container">
			<CodeMirror
				value={value}
				onChange={onChange}
				extensions={[json(), jsonLinter()]}
				width="100%"
				height={height}
				theme={theme === LIGHT ? githubLight : githubDark}
				options={{
					lineNumbers: true,
				}}
				readOnly={isDisabled}
				style={{
					width: "100%",
				}}
				key={jsonKey}
				onBlur={() => {
					if (!value) {
						onChange("{}");
					}
				}}
				autoFocus={false}
				editable={!isDisabled}
			/>
		</div>
	);
};

export default JsonEditor;
