import "./OfferDetailsHeader.style.scss";
import { images } from "../../imagesComponent/Images";
import { TfiStatsUp } from "react-icons/tfi";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { useState } from "react";
import {
	OFFER_DETAILS_STATUS_ACTIVATED,
	OFFER_DETAILS_STATUS_DEACTIVATED,
	OFFER_DETAILS_STATUS_DRAFT,
	headers,
	SAVE,
	ACTIVATE,
	EDIT,
	DEACTIVATE,
	OFFER_DETAILS_STATUS_DEACTIVATED_EXPIRED_MESSAGE,
	OFRI,
	offer_status,
	POINTS_EARN,
	CANCEL,
	BASE_EARN,
	BASE_REDEEM,
	CUSTOM_OFFER,
	NON_PURCHASE,
} from "../../constantComponent/Constants";
import {
	updateOfferStatus,
	updateOfferData,
	createUpdateOffer,
	createUpdateContentOffer,
	contentGenerationDetails,
	setSourceCancelBtn,
	setErrorToaster,
} from "../../../tellurideExtRedux";
import moment from "moment";
import { convertUTCDateToLocalDate } from "../offerDetailsComponent/OfferDetailsUtil";
import { miniloadingWithoutMessage } from "../../LoaderComponents/loadingDesignConstant";
import { useAuth0 } from "@auth0/auth0-react";
import {
	checkRightsIsEditable,
	checkRightsIsVisible,
	getRightsFromSession,
	getRightsKey,
	skey,
	trimExtraSpaces,
	GetRequestBodyForCreateUpdateOffer,
} from "../../utilsComponent/CommonUtil";
import { AnimatePresence } from "framer-motion/dist/framer-motion";
import { StatsPopover } from "./OfferDetailsDesignConstant";
import { useNavigate } from "react-router";
import {
	CONTENT_NAVIGATION_PATH,
	contentGenerationRequestBody,
} from "../offerSetupConstant";
import ConfirmationPopUpComponent from "../../modelComponent/ConfirmationPopUpComponent/ConfirmationPopUpComponent";
import { showSetupCreationConfirmationPopup } from "../../navigationBarComponent/internalNavigationBarComponent/NavigationBarUtil";
import { OFFER, VIEW } from "../../constantComponent/PathConstants";
import {
	APPLY_ON,
	DOLLAR_OFF,
	LOYALTY_CASH,
	MIN,
	OFFER_FOR,
	OFFER_RANGE,
	PERCENT_OFF,
	promptJson,
	THRESHOLD,
	VALUE,
} from "../rewardComponent/RewardConstant";

const OfferDetailsHeader = (props) => {
	const dispatch = useDispatch();
	const offerDetailsPageData = useSelector((state) => state.offerDetailsData);
	const securityData = useSelector((state) => state.securityData);
	const eventProccessingOfferError =
		offerDetailsPageData.eventProccessingOfferError;
	const [offerNameEditable, setOfferNameEditable] = useState(false);
	const [clickedBtn, setClickedBtn] = useState("");
	const [offerDetailValues, setValues] = useState(
		offerDetailsPageData?.offerData
	);
	const [updatedOfferName, setUpdatedOfferName] = useState(
		offerDetailsPageData?.offerName
	);
	const [showDeactivateConfirmationPopup, setShowDeactivateConfirmationPopup] =
		useState(false);
	const [showActivateConfirmationPopup, setShowActivateConfirmationPopup] =
		useState(false);
	const { user } = useAuth0();
	const [statsPopoverVisible, setStatsPopoverVisible] = useState(false);
	const navigate = useNavigate();
	const promptData = useSelector((state) => state.promptData);
	const [showConfirmationPopup, setShowConfirmationPopup] = useState({
		key: "",
		visibility: false,
	});
	// show error toaster message if start date input is empty on Activate button click.
	const updateOfferStatusHandller = (statusValue) => {
		headers.sessionId = skey();
		headers.requestAccess = getRightsFromSession();
		headers.organization = offerDetailsPageData?.authOrganizationName;
		const requestHeaders = {
			headers,
		};
		const updateOfferStatusRequestBody = {
			requestorID: OFRI,
			messageVersion: process.env.REACT_APP_MESSAGE_VERSION_01,
			source: "TELL",
			modifiedBy: user?.email,
			modifiedTS: "2023-06-27 20:44:29",
			offersInfo: {
				offerInfo: [
					{
						offerNumber:
							offerDetailsPageData.offerDetailsResponse.offerData.offerNumber,
						offerStatus: statusValue,
					},
				],
			},
			additionalAttributes: {
				additionalAttribute: [
					{
						name: "RealTimeFlag",
						values: {
							value: ["Y"],
						},
					},
				],
			},
		};

		dispatch(
			updateOfferStatus(
				requestHeaders,
				updateOfferStatusRequestBody,
				navigate,
				offerDetailsPageData.offerData
			)
		);
	};
	const handleClickOnSave = (isActivateClick) => {

		headers.sessionId = skey();
		headers.requestAccess = getRightsFromSession();
		headers.organization = offerDetailsPageData?.authOrganizationName;
		const requestHeaders = {
			headers,
		};
		const requestBody = GetRequestBodyForCreateUpdateOffer(
			user,
			offerDetailsPageData,
			updatedOfferName
		);

		dispatch(
			createUpdateOffer(
				requestHeaders,
				requestBody,
				true,
				isActivateClick,
				navigate,
				true
			)
		);
	};
	const setSaveDisabled = () => {
		if (
			offerDetailsPageData?.offerValidationErrors?.createOffer?.discount ||
			offerDetailsPageData?.offerValidationErrors?.createOffer?.pointValidity
		) {
			return true;
		} else {
			return false;
		}
	};
	useEffect(() => {
		dispatch(updateOfferData(offerDetailValues));
	}, [offerDetailValues]);
	const handleEditClickOfferName = () => {
		setOfferNameEditable(true);
	};
	const handleOfferNameChange = (e) => {
		setUpdatedOfferName(e.target.value);
	};
	const saveOfferName = () => {
		setOfferNameEditable(!offerNameEditable);
	};
	function showErrorToaster(obj) {
		const inputStartDate = document.getElementById("input-start-date");
		if (!inputStartDate.value) {
			const backdrop = document.querySelector(".backdrop");
			backdrop.classList.add("show");
			const startDate = document.getElementById("start-date");
			startDate.classList.add("error-start-date");
			setTimeout(() => {
				hideErrorToaster(); // after 5 seconds hide error toaster message
			}, 5000);
		}
		return;
	}
	// hide error toaster message
	function hideErrorToaster(obj) {
		const backdrop = document.querySelector(".backdrop");
		backdrop.classList.remove("show");
		return;
	}

	function getContentGenerationPromptString(rewardData) {
		if (rewardData) {
			let propertyValues = {
				MIN: 0,
				VALUE: 0,
				DOLLAR_OFF: 0,
				PERCENT_OFF: 0,
				OFFER_FOR: "",
				OFFER_RANGE: "",
				APPLY_ON: "",
				THRESHOLD: "",
				OFFER_THRESHOLD: "",
			};

			let promptText = "";

			let offerType = offerDetailsPageData?.offerData?.offerType?.replaceAll(
				" ",
				""
			);

			rewardData?.action[0]?.actionProperties?.actionProperty?.map(
				(element) => {
					element.property.map((property) => {
						if (
							propertyValues[property.name] === "" ||
							propertyValues[property.name] === 0
						) {
							propertyValues[property.name] = property?.values?.value[0];
						}
					});
				}
			);

			if (propertyValues.OFFER_FOR && propertyValues.OFFER_RANGE) {
				if (
					"Spend" + "Flat" + "Points Earn" ===
						propertyValues.OFFER_FOR + propertyValues.OFFER_RANGE + offerType &&
					propertyValues.MIN === "0.01"
				) {
					promptText =
						promptJson[
							propertyValues.OFFER_FOR +
								propertyValues.OFFER_RANGE +
								offerType +
								"WithoutMin"
						];
				} else {
					promptText =
						promptJson[
							propertyValues.OFFER_FOR + propertyValues.OFFER_RANGE + offerType
						];
				}

				let parsedValue = parseFloat(propertyValues.VALUE) / 1000;
				if (promptText) {
					promptText = promptText.replace(
						"~~values",
						offerType !== LOYALTY_CASH ? parsedValue : propertyValues.VALUE
					);
					promptText = promptText.replace(
						"~~tripThreshold",
						propertyValues.OFFER_THRESHOLD
					);
					promptText = promptText.replace("~~min", propertyValues.MIN);
				}
				return promptText;
			}

			if (propertyValues.APPLY_ON && propertyValues.THRESHOLD) {
				promptText =
					promptJson[
						propertyValues.APPLY_ON + propertyValues.THRESHOLD + offerType
					];

				promptText = promptText.replace(
					"~~values",
					propertyValues.DOLLAR_OFF
						? "$" + propertyValues.DOLLAR_OFF
						: propertyValues.PERCENT_OFF + "%"
				);
				promptText = promptText.replace("~~min", propertyValues.MIN);
				return promptText;
			}
		}
	}
	// const handleDeactivateButtonClick = (deactivateStatus) => {
	//   if (deactivateStatus) {
	//     updateOfferStatusHandller(OFFER_DETAILS_STATUS_DEACTIVATED);
	//     setShowDeactivateConfirmationPopup(!deactivateStatus);
	//   } else {
	//     setShowDeactivateConfirmationPopup(deactivateStatus);
	//   }
	// };
	// const handleActivateButtonClick = (activateStatus) => {
	//   if (activateStatus) {
	//     updateOfferStatusHandller(OFFER_DETAILS_STATUS_ACTIVATED);
	//     setShowActivateConfirmationPopup(!activateStatus);
	//   } else {
	//     setShowActivateConfirmationPopup(activateStatus);
	//   }
	// };
	// const handleSaveActivateButtonClick = (activateStatus) => {
	//   if (activateStatus) {
	//     // updateOfferStatusHandller(OFFER_DETAILS_STATUS_ACTIVATED);
	//     handleClickOnSave(true);
	//     setShowActivateConfirmationPopup(!activateStatus);
	//   } else {
	//     setShowActivateConfirmationPopup(activateStatus);
	//   }
	// };
	const generateContentDetails = () => {
		const promptString = getContentGenerationPromptString(
			offerDetailsPageData.rewardData
		);
		if (
			offerDetailsPageData?.rewardData?.action[0]?.actionProperties
				?.actionProperty
		) {
			headers.sessionId = skey();
			headers.requestAccess = getRightsFromSession();
			headers.organization = offerDetailsPageData?.authOrganizationName;
			const requestHeaders = {
				headers,
			};
			// get headlineBodyline request body
			contentGenerationRequestBody.prompt = promptString;
			//   contentGenerationRequestBody.varg.company =
			//     offerDetailsPageData?.authOrganizationName;

			dispatch(
				contentGenerationDetails(requestHeaders, contentGenerationRequestBody)
			);
		} else {
			createContent();
		}
	};
	const createContent = () => {
		headers.sessionId = skey();
		headers.requestAccess = getRightsFromSession();
		headers.organization = offerDetailsPageData?.authOrganizationName;
		const requestHeaders = {
			headers,
		};
		let headline1 = "";
		let bodyline1 = "";

		if (
			promptData &&
			promptData?.contentGenerationResponse &&
			promptData?.contentGenerationResponse?.resp &&
			promptData?.contentGenerationResponse?.resp[0]
		) {
			headline1 = promptData?.contentGenerationResponse?.resp[0]?.headline;
			bodyline1 = promptData?.contentGenerationResponse?.resp[0]?.bodyline;
		}
		const content_offer_request = {
			requestorID: "OFRI",
			messageVersion: process.env.REACT_APP_MESSAGE_VERSION_05,
			offerData: {
				offerCategory: "stackable",
				offerSubCategory: "",
				offerType: "Message only",
				offerPointsDollarName: offerDetailsPageData?.offerName,
				offerDescription: offerDetailsPageData?.offerDetailsResponse?.offerData
					?.offerDescription
					? offerDetailsPageData?.offerDetailsResponse?.offerData
							?.offerDescription
					: "",
				linkedOfferStatus: "N",
				isContentOffer: "Y",

				deleteImages: {
					imageIds: [],
				},
			},
			actions: {
				action: [
					{
						actionName: "My Action",
						actionType: "MESSAGE",
						actionJson: {
							headline1: headline1, // api call  for headline
							bodyline1: bodyline1, // api call  for  bodyline
							imageId: "",
							imageName: "",
							disclaimerLegalCopy: "",
							callToActionURL: "",
							category: "MARKETING",
							subCategory: "DEFAULT",
						},
					},
				],
			},
		};

		// createUpdateContentOffer request body
		// content_offer_request.offerData.offerPointsDollarName = offerDetailsPageData?.offerName;

		// content_offer_request.offerData.offerDescription =
		//   offerDetailsPageData.offerDetailsResponse.offerData.offerDescription;

		// content_offer_request.actions.action[0].actionJson.headline1 =
		//   promptData?.contentGenerationResponse?.resp[0]?.Headline;
		// content_offer_request.actions.action[0].actionJson.bodyline1 =
		//   promptData?.contentGenerationResponse?.resp[0]?.Bodyline;
		dispatch(
			createUpdateContentOffer(
				requestHeaders,
				content_offer_request,
				true,
				false,
				navigate,
				true,
				CONTENT_NAVIGATION_PATH
			)
		);
	};
	useEffect(() => {
		if (promptData.contentGenerationResponse) {
			createContent();
		}
	}, [promptData.contentGenerationResponse]);
	// useEffect(() => {
	//   if (contentData.createUpdateContentOfferResponse) {
	//     navigate({
	//       pathname:
	//         "/" +
	//         window.location.pathname.split("/")[1] +
	//         "/campaign/content" +
	//         "/create",
	//       search: `?offerid=${offerDetailsPageData.offerDetailsResponse.offerData.offerNumber}`,
	//     });
	//   }
	// }, [contentData.createUpdateContentOfferResponse]);
	const saveAndUpdateStatusHandler = (status) => {
		handleClickOnSave(true);
	};

	const updateStatusHandler = (status) => {
		headers.sessionId = skey();
		headers.requestAccess = getRightsFromSession();
		headers.organization = offerDetailsPageData?.authOrganizationName;
		const requestHeaders = {
			headers,
		};
		const updateOfferStatusRequestBody = {
			requestorID: OFRI,
			messageVersion: process.env.REACT_APP_MESSAGE_VERSION_01,
			source: "TELL",
			modifiedBy: user?.email,
			modifiedTS: "2023-06-27 20:44:29",
			offersInfo: {
				offerInfo: [
					{
						offerNumber:
							offerDetailsPageData.offerDetailsResponse.offerData.offerNumber,
						offerStatus: status,
					},
				],
			},
			additionalAttributes: {
				additionalAttribute: [
					{
						name: "RealTimeFlag",
						values: {
							value: ["Y"],
						},
					},
				],
			},
		};

		dispatch(
			updateOfferStatus(
				requestHeaders,
				updateOfferStatusRequestBody,
				navigate,
				offerDetailsPageData.offerData
			)
		);
	};
	return (
		<>
			<div className="navigation-top-div">
				<div className="empty-space-div"></div>
				<div className="navigation-text">
					{offerNameEditable ? (
						<>
							<input
								className="input-offer-name"
								placeholder="Enter new offer name"
								maxLength="50"
								onChange={handleOfferNameChange}
								defaultValue={updatedOfferName}
								type="text"
								autoFocus
								onBlur={() => {
									if (updatedOfferName.trim() === "")
										setUpdatedOfferName(
											trimExtraSpaces(offerDetailsPageData?.offerName)
										);
									saveOfferName();
								}}
								autocomplete="off"
								autocorrect="off"
								autocapitalize="off"
								spellcheck="false"
							/>
						</>
					) : (
						<p className="offer-name setup-name-header">{updatedOfferName}</p>
					)}
					{!offerNameEditable && (
						<>
							{offerDetailsPageData?.offerStatus ===
								OFFER_DETAILS_STATUS_DRAFT && (
								<>
									{checkRightsIsVisible(
										getRightsKey(["button", "loyalty", "detail", "save"])
									) ? (
										// eslint-disable-next-line jsx-a11y/alt-text
										<img
											onClick={() => {
												if (
													checkRightsIsEditable(
														getRightsKey([
															"button",
															"loyalty",
															"detail",
															"save",
														])
													)
												) {
													handleEditClickOfferName();
												}
											}}
											src={images.penIcon}
											className="pen-icon"
										/>
									) : null}
								</>
							)}
						</>
					)}
					{!offerNameEditable && (
						<div
							className={`status-container ${
								offerDetailsPageData?.offerStatus ===
								OFFER_DETAILS_STATUS_ACTIVATED
									? moment(offerDetailsPageData?.offerData?.offerEndDate).diff(
											convertUTCDateToLocalDate(new Date()),
											"days"
									  ) <= -1
										? "EXPIRED"
										: offerDetailsPageData?.offerStatus
									: offerDetailsPageData?.offerStatus
							}`}
						>
							{" "}
							<span className="offer-status-value">
								{offerDetailsPageData?.offerStatus ===
								OFFER_DETAILS_STATUS_ACTIVATED
									? moment(offerDetailsPageData?.offerData?.offerEndDate).diff(
											convertUTCDateToLocalDate(new Date()),
											"days"
									  ) <= -1
										? OFFER_DETAILS_STATUS_DEACTIVATED_EXPIRED_MESSAGE
										: offer_status[offerDetailsPageData?.offerStatus]
									: offer_status[offerDetailsPageData?.offerStatus]}
							</span>
						</div>
					)}
				</div>
				{offerDetailsPageData?.offerData?.offerType === POINTS_EARN && (
					<div
						className={`stats-button-container ${
							statsPopoverVisible === true ? "active" : null
						}`}
						onClick={() => setStatsPopoverVisible(true)}
					>
						<TfiStatsUp />
					</div>
				)}
				{statsPopoverVisible === true && (
					<StatsPopover setStatsPopover={setStatsPopoverVisible} />
				)}
				<div className="navigation-buttons">
					{offerDetailsPageData?.offerStatus === OFFER_DETAILS_STATUS_DRAFT && (
						<>
							{checkRightsIsVisible(
								getRightsKey(["button", "loyalty", "detail", "save"])
							) ? (
								<button
									className="save-button btn btn-primary"
									onClick={handleClickOnSave}
									disabled={
										setSaveDisabled() ||
										eventProccessingOfferError ||
										!checkRightsIsEditable(
											getRightsKey(["button", "loyalty", "detail", "save"])
										)
									}
								>
									{" "}
									{SAVE}{" "}
								</button>
							) : null}
						</>
					)}
					{offerDetailsPageData?.offerStatus !== OFFER_DETAILS_STATUS_DRAFT &&
						offerDetailsPageData?.offerStatus !==
							OFFER_DETAILS_STATUS_ACTIVATED && (
							<>
								{checkRightsIsVisible(
									getRightsKey(["button", "loyalty", "detail", "edit"])
								) ? (
									<button
										className="save-button btn btn-primary"
										onClick={(e) => {
											setClickedBtn(EDIT);
											updateOfferStatusHandller(OFFER_DETAILS_STATUS_DRAFT);
										}}
										disabled={
											clickedBtn === EDIT ||
											!checkRightsIsEditable(
												getRightsKey(["button", "loyalty", "detail", "edit"])
											)
										}
									>
										{/* uploadOfferStatusLoading */}
										{offerDetailsPageData.updateOfferStatusLoading &&
										clickedBtn === EDIT
											? miniloadingWithoutMessage()
											: EDIT}
									</button>
								) : null}
							</>
						)}
					{(offerDetailsPageData?.offerStatus === OFFER_DETAILS_STATUS_DRAFT ||
						offerDetailsPageData?.offerStatus ===
							OFFER_DETAILS_STATUS_DEACTIVATED) && (
						<>
							{checkRightsIsVisible(
								getRightsKey(["button", "loyalty", "detail", "active"])
							) ? (
								<button
									className="activate-button btn btn-primary"
									onClick={(e) => {
										setClickedBtn(ACTIVATE);
										setShowConfirmationPopup({
											key: "activate",
											visibility: true,
										});
									}}
									disabled={
										offerDetailsPageData.updateOfferStatusLoading ||
										eventProccessingOfferError ||
										!checkRightsIsEditable(
											getRightsKey(["button", "loyalty", "detail", "active"])
										)
									}
								>
									{offerDetailsPageData.updateOfferStatusLoading &&
									clickedBtn === ACTIVATE
										? miniloadingWithoutMessage()
										: ACTIVATE}
								</button>
							) : null}
						</>
					)}
					{offerDetailsPageData?.offerStatus ===
						OFFER_DETAILS_STATUS_ACTIVATED && (
						<>
							{process.env.REACT_APP_ENVIRONMENT === "qa" &&
							![BASE_EARN, BASE_REDEEM, CUSTOM_OFFER, NON_PURCHASE].includes(
								offerDetailsPageData?.offerData?.offerType
							) ? (
								<button
									className="create-btn btn btn-primary"
									onClick={(e) => {
										generateContentDetails();
									}}
								>
									Add Content
								</button>
							) : null}

							{checkRightsIsVisible(
								getRightsKey(["button", "loyalty", "detail", "deactive"])
							) ? (
								<button
									className="activate-button btn btn-primary"
									onClick={(e) => {
										setClickedBtn(DEACTIVATE);
										setShowConfirmationPopup({
											key: "deactivate",
											visibility: true,
										});
									}}
									disabled={
										clickedBtn === DEACTIVATE ||
										!checkRightsIsEditable(
											getRightsKey(["button", "loyalty", "detail", "deactive"])
										)
									}
								>
									{offerDetailsPageData.updateOfferStatusLoading &&
									clickedBtn === DEACTIVATE
										? miniloadingWithoutMessage()
										: DEACTIVATE}
								</button>
							) : null}
						</>
					)}
					<button
						className="create-btn btn btn-secondary"
						onClick={(e) => {
							if (
								offerDetailsPageData.isOfferChanged &&
								offerDetailsPageData.offerStatus ===
									OFFER_DETAILS_STATUS_DRAFT &&
								checkRightsIsEditable(
									getRightsKey([
										"loyalty",
										"detail",
										"setup",
										offerDetailsPageData?.offerData?.offerType
											.split(" ")
											.join(""),
									])
								)
							) {
								dispatch(
									setSourceCancelBtn({
										state: true,
										path: OFFER + VIEW,
									})
								);
								showSetupCreationConfirmationPopup();
							} else {
								navigate(
									"/" + window.location.pathname.split("/")[1] + OFFER + VIEW
								);
							}
						}}
					>
						{CANCEL}
					</button>
				</div>
			</div>
			{/* <AnimatePresence>
        {showDeactivateConfirmationPopup && (
          <DeactivateConfirmationComponent
            setClickedBtn={setClickedBtn}
            onDeactiveClick={handleDeactivateButtonClick}
          />
        )}
      </AnimatePresence> */}
			<AnimatePresence>
				{showConfirmationPopup.visibility && (
					<ConfirmationPopUpComponent
						setClickedBtn={setClickedBtn}
						updateStatusHandler={updateStatusHandler}
						saveAndUpdateStatusHandler={saveAndUpdateStatusHandler}
						secondaryBtnText={
							offerDetailsPageData.isOfferChanged &&
							offerDetailsPageData.offerStatus === OFFER_DETAILS_STATUS_DRAFT
								? "Cancel"
								: "No"
						}
						saveActivate={
							offerDetailsPageData.isOfferChanged &&
							offerDetailsPageData.offerStatus === OFFER_DETAILS_STATUS_DRAFT
								? true
								: false
						}
						isOfferChanged={
							offerDetailsPageData.offerStatus === OFFER_DETAILS_STATUS_DRAFT
								? offerDetailsPageData.isOfferChanged
								: false
						}
						statusKey={showConfirmationPopup.key}
						setShowConfirmationPopup={setShowConfirmationPopup}
					/>
				)}
			</AnimatePresence>
		</>
	);
};

export default OfferDetailsHeader;
