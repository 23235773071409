export const AUTH_LOADER_SOURCE = "AUTH";
export const OFFER_LOADER_SOURCE = "OFFER";
export const CUSTOM_OFFER_LOADER_SOURCE = "CUSTOM_OFFER";
export const CONTENT_OFFER_LOADER_SOURCE = "CONTENT_OFFER";
export const SEGMENT_LOADER_SOURCE = "SEGMENT";
export const ATTRIBUTE_LOADER_SOURCE = "ATTRIBUTE_LOADER_SOURCE";
export const CAMPAIGN_LOADER_SOURCE = "CAMPAIGN_LOADER_SOURCE";
export const SUBCLCUB_LOADER_SOURCE = "SUBCLCUB_LOADER_SOURCE";
export const ABTESTING_LOADER_SOURCE = "ABTESTING_LOADER_SOURCE";
export const OFFER_REVIEW_LOADER_SOURCE = "OFFER_REVIEW_LOADER_SOURCE";
export const TRIGGER_EVENT_LOADER_SOURCE = "TRIGGER_EVENT_LOADER_SOURCE";
export const GET_ORGANIZATION_LOADING_SOURCE ="GET_ORGANIZATION_LOADING_SOURCE";
export const CONNECTOR_LOADING_SOURCE = "CONNECTOR_LOADING_SOURCE";
export const REPORT_LOADER_SOURCE = "REPORT_LOADER_SOURCE";
