import React, { useEffect, useState } from "react";
import "./dashboardWrapper.style.scss";
import GenericTable from "../../utilsComponent/TableUtil/GenericTable";
import {
	DASHBOARD_COLUMNS,
	DASHBOARD_HEADER,
	DASHBOARD_HEADER_DESCRIPTION,
	DASHBOARD,
	OFFER_DASHBOARD_REQ_BODY_KEY,
	OFFER_DASHBOARD_HEADER_TEXT,
	OFFER_DASHBOARD_RES_BODY_KEY,
	dashboardRolePermissionsNames,
} from "./DashboardConstant";

import { useNavigate } from "react-router-dom";
import {
	DETAILS,
	OFFER,
	VIEW_OFFER,
} from "../../constantComponent/PathConstants";
import { useDispatch, useSelector } from "react-redux";
import {
	headers,
	OFRI,
	ESLO,
	OFFER_DETAILS_STATUS_DRAFT_MESSAGE,
	OFFER_DETAILS_STATUS_ACTIVATED_MESSAGE,
	DARK,
  REDUX,
} from "../../constantComponent/Constants";

import BarChart from "../../utilsComponent/Chart/BarChart";
import LineChart from "../../utilsComponent/Chart/LineChart";
import ProgressChart from "../../utilsComponent/Chart/ProgressChart";
import {
	BAR_CHART_DATA,
	BAR_CHART_OPTIONS,
	BAR_CHART_OPTIONS_THEME,
} from "../../constantComponent/ChartConstants";
import {
	PROGRESS_CHART_DATA,
	PROGRESS_CHART_OPTIONS,
} from "../../constantComponent/ChartConstants";
import { LINE_CHART_DATA } from "../../constantComponent/ChartConstants";
import {
	dateRangeOptions,
	dateRangeDefaultValue,
} from "../../constantComponent/ChartConstants";
import "../../../tellurideExtAssets/styles/reactDatePicker.style.scss";
import "../../../tellurideExtAssets/styles/reactCalendar.style.scss";
import "../../utilsComponent/TableUtil/Table.style.scss";
import {
	CREATE_OFFER_LINK,
	VIEW_OFFER_LINK,
} from "../../constantComponent/Constants";
import moment from "moment";
import {
	fetchOfferStatusStats,
	fetchOfferExpirtyStats,
	fetchOfferActivityStats,
	setPageLoaded,
} from "../../../tellurideExtRedux";
import { Chart } from "chart.js";
import Select from "react-select";
import {
	checkRights,
	checkRightsIsEditable,
	checkRightsIsVisible,
	formatDate,
	getAllRights,
	getRightKey,
	getRightsFromSession,
	getRightsKey,
	skey,
} from "../../utilsComponent/CommonUtil";
import { getCustomStyles } from "./DashboardUtil";
import { useTransition } from "react";
import { convertUTCDateToLocalDate } from "../offerDetailsComponent/OfferDetailsUtil";

const DashboardWrapper = ({ setShowOfferPopup }) => {
	const navigate = useNavigate();
	const viewhandller = () => {
		navigate("/" + window.location.pathname.split("/")[1] + OFFER + VIEW_OFFER);
	};

	const createOfferhandller = () => {
		setShowOfferPopup(true);
	};
	const [barData, setBarData] = useState();
	const [barOption, setBarOption] = useState();

	const offeAnalyticsResp = useSelector((state) => state.OfferAnalyticsData);
	const [dateDuration, setDateDuration] = useState(7);

	const dispatch = useDispatch();
	const offerData = useSelector((state) => state.offerDetailsData);
	const securityData = useSelector((state) => state.securityData);
	headers.sessionId = skey();
	headers.requestAccess = getRightsFromSession();
	headers.organization = offerData?.authOrganizationName;
	const requestHeaders = {
		headers,
	};
	const offerStatusRequestBody = {
		requestorID: OFRI,
		messageVersion: process.env.REACT_APP_MESSAGE_VERSION_02,
		startDate: "",
		endDate: "",
	};
	const expiryOfferReqBody = {
		requestorID: OFRI,
		messageVersion: process.env.REACT_APP_MESSAGE_VERSION_01,
	};
	const offerActivityReqBody = {
		requestorID: OFRI,
		messageVersion: process.env.REACT_APP_MESSAGE_VERSION_01,
		activityStart: "",
		activityEnd: "",
	};
	//theme
	const toasterData = useSelector((state) => state.toasterData);
	const [theme, setTheme] = useState(toasterData.isDarkTheme);

	useEffect(() => {
		setTheme(toasterData.isDarkTheme);
	}, [toasterData]);
	//theme
	function updateDateDiff({ value }) {
		setDateDuration(value);
	}

	useEffect(() => {
		// Handling OfferStatusStat response
		var statusStatSummary = offeAnalyticsResp.offeStatusStatsResponse.summary;
		var statusDateList = [];
		var statusActiveList = [];
		var statusDraftList = [];
		var statusExpiredList = [];
		if (statusStatSummary != undefined) {
			statusStatSummary.forEach((statusElement) => {
				let tmpDateBar = new Date(statusElement.date);
				statusDateList.push(
					tmpDateBar.getMonth() + 1 + "/" + tmpDateBar.getDate()
				);
				// var dateSubString = statusElement.date.substring(
				//   5,
				//   statusElement.date.length
				// );
				// statusDateList.push(dateSubString.replace("-", "/"));
				statusActiveList.push(statusElement.active);
				statusDraftList.push(statusElement.inProgress);
				statusExpiredList.push(statusElement.expired);
			});

			BAR_CHART_DATA.labels = statusDateList;

			BAR_CHART_DATA.datasets.forEach((barDataSet) => {
				if (barDataSet.label == OFFER_DETAILS_STATUS_DRAFT_MESSAGE) {
					barDataSet.data = statusDraftList;
					barDataSet.backgroundColor =
						theme === DARK ? "#075cb1" : "rgb(10,132,253)";
				} else if (barDataSet.label == OFFER_DETAILS_STATUS_ACTIVATED_MESSAGE) {
					barDataSet.data = statusActiveList;
					barDataSet.backgroundColor =
						theme === DARK ? "rgb(153, 204, 255)" : "rgb(153, 204, 255)";
				} else {
					barDataSet.data = statusExpiredList;
					barDataSet.backgroundColor =
						theme === DARK ? "rgb(28,62,95)" : "rgb(28,62,95)";
				}
			});
			var offerStatusStatChart = Chart.getChart("offerStatusStatChart");
			offerStatusStatChart.data = BAR_CHART_DATA;
			offerStatusStatChart.update();

			var barChartSpinDiv = document.getElementById(
				"offerStatusStatChartbarChartSpinDiv"
			);
			barChartSpinDiv.style.display = "none";
			var barChartDiv = document.getElementById(
				"offerStatusStatChartbarChartDiv"
			);
			barChartDiv.style.display = "block";
		}

		// Handling ExpiringOffers as of Today
		var expiringOffersObj =
			offeAnalyticsResp.expiringOfferStatsResponse.summary;
		if (expiringOffersObj != undefined) {
			var expiringOfferLabelList = [];
			var expiringOfferValueList = [];
			let newDate = new Date();
			let date = newDate.getDate();
			let month = newDate.getMonth() + 1;
			let year = newDate.getFullYear();
			Object.entries(expiringOffersObj).map(([key, value]) => {
				expiringOfferLabelList.push(key);
				expiringOfferValueList.push(value);
			});
			PROGRESS_CHART_DATA.labels = expiringOfferLabelList;
			PROGRESS_CHART_DATA.datasets.forEach((progressDataSet) => {
				if (progressDataSet.label == "Expiring Offers") {
					progressDataSet.data = expiringOfferLabelList;
				}
			});

			PROGRESS_CHART_DATA.datasets[0].data = expiringOfferValueList;
			PROGRESS_CHART_OPTIONS.plugins.title.text =
				"Expiring Offers as of " + month + "/" + date + "/" + year;
			//Setting to max value for X-axis to handle max value close to multiple of 10s.
			PROGRESS_CHART_OPTIONS.scales.x.max =
				Math.max(...expiringOfferValueList) + 5;
			var expiringOfferStatChart = Chart.getChart("expiringOfferStatChart");
			expiringOfferStatChart.data = PROGRESS_CHART_DATA;
			expiringOfferStatChart.options = PROGRESS_CHART_OPTIONS;
			expiringOfferStatChart.update();

			var progressChartSpinDiv = document.getElementById(
				"progressChartSpinDiv"
			);
			progressChartSpinDiv.style.display = "none";
			var progressChartDiv = document.getElementById("progressChartDiv");
			progressChartDiv.style.display = "block";
		}

		// Handling OfferActivity API response
		var offerActivityList =
			offeAnalyticsResp.offerActivityStatResponse.offerActivities;
		var offerActivityDateList = [];
		var earnOfferList = [];
		var nonEarnOfferList = [];
		if (offerActivityList != undefined) {
			offerActivityList.forEach((activityElement) => {
				let tmpDate = new Date(activityElement.activityDate);
				offerActivityDateList.push(
					tmpDate.getMonth() + 1 + "/" + tmpDate.getDate()
				);
				// var actyDateValue = activityElement.activityDate.substring(
				//   5,
				//   activityElement.activityDate.length
				// );
				// offerActivityDateList.push(actyDateValue.replace("-", "/"));

				// var actyDateValue = activityElement.activityDate.split("-");
				// offerActivityDateList.push(actyDateValue[1]+ "/"+actyDateValue[2]);

				earnOfferList.push(activityElement.earningOffer);
				nonEarnOfferList.push(activityElement.nonEarningOffer);
			});
			LINE_CHART_DATA.labels = offerActivityDateList;
			LINE_CHART_DATA.datasets.forEach((lineDataSet) => {
				if (lineDataSet.label == "Earning Offers") {
					lineDataSet.data = earnOfferList;
					lineDataSet.backgroundColor =
						theme === DARK ? "#3360b0" : "rgb(73, 138, 252)";
					lineDataSet.borderColor =
						theme === DARK ? "#3360b0" : "rgb(73, 138, 252)";
				} else if (lineDataSet.label == "Non-Earning Offers") {
					lineDataSet.data = nonEarnOfferList;
				}
			});
			LINE_CHART_DATA.datasets[0].data = earnOfferList;
			LINE_CHART_DATA.datasets[1].data = nonEarnOfferList;

			var offerActivityStatChart = Chart.getChart("offerActivityStatChart");
			offerActivityStatChart.data = LINE_CHART_DATA;
			offerActivityStatChart.update();

			var lineChartSpinDiv = document.getElementById("lineChartSpinDiv");
			lineChartSpinDiv.style.display = "none";
			var lineChartDiv = document.getElementById("lineChartDiv");
			lineChartDiv.style.display = "block";
		}
	}, [
		offeAnalyticsResp.offeStatusStatsResponse,
		offeAnalyticsResp.expiringOfferStatsResponse,
		offeAnalyticsResp.offerActivityStatResponse,
		theme,
	]);

	useEffect(() => {
		if (offeAnalyticsResp.offeStatusStatsError) {
			var barChartSpinDiv = document.getElementById(
				"offerStatusStatChartbarChartSpinDiv"
			);
			var barChartDiv = document.getElementById(
				"offerStatusStatChartbarChartDiv"
			);
			barChartSpinDiv.style.display = "none";
			barChartDiv.style.display = "none";
			let barChartEmptyDataDiv = document.getElementById(
				"offerStatusStatChartemptyData"
			);
			barChartEmptyDataDiv.style.display = "block";
		} else {
			let barChartEmptyDataDiv = document.getElementById(
				"offerStatusStatChartemptyData"
			);
			barChartEmptyDataDiv.style.display = "none";
		}

		if (offeAnalyticsResp.expiringOfferStatsError) {
			var progressChartSpinDiv = document.getElementById(
				"progressChartSpinDiv"
			);
			progressChartSpinDiv.style.display = "none";
			var progressChartDiv = document.getElementById("progressChartDiv");
			progressChartDiv.style.display = "none";
			let progressChartEmptyDataDiv = document.getElementById(
				"expiringOfferStatChartemptyData"
			);
			progressChartEmptyDataDiv.style.display = "block";
		} else {
			let progressChartEmptyDataDiv = document.getElementById(
				"expiringOfferStatChartemptyData"
			);
			progressChartEmptyDataDiv.style.display = "none";
		}

		if (offeAnalyticsResp.offerActivityStatError) {
			var lineChartSpinDiv = document.getElementById("lineChartSpinDiv");
			var lineChartDiv = document.getElementById("lineChartDiv");
			lineChartSpinDiv.style.display = "none";
			lineChartDiv.style.display = "none";
			let lineChartEmptyDataDiv = document.getElementById(
				"offerActivityStatChartemptyData"
			);
			lineChartEmptyDataDiv.style.display = "block";
		} else {
			let lineChartEmptyDataDiv = document.getElementById(
				"offerActivityStatChartemptyData"
			);
			lineChartEmptyDataDiv.style.display = "none";
		}
	}, [
		offeAnalyticsResp.offeStatusStatsError,
		offeAnalyticsResp.expiringOfferStatsError,
		offeAnalyticsResp.offerActivityStatError,
	]);
	useEffect(() => {
		dispatch(setPageLoaded(true));
		return () => {
			dispatch(setPageLoaded(null));
		};
	}, []);

	useEffect(() => {
		let today = new Date();
		let startDate = new Date(
			new Date(new Date()).setDate(today.getDate() - (dateDuration - 1))
		);
		//Fetching OfferStatusStat
		offerStatusRequestBody.startDate = formatDate(startDate); //formatDate(startDate.toLocaleDateString());
		offerStatusRequestBody.endDate = formatDate(today);
		dispatch(fetchOfferStatusStats(requestHeaders, offerStatusRequestBody));
		var barChartSpinDiv = document.getElementById(
			"offerStatusStatChartbarChartSpinDiv"
		);
		var barChartDiv = document.getElementById(
			"offerStatusStatChartbarChartDiv"
		);
		barChartSpinDiv.style.display = "block";
		barChartDiv.style.display = "none";

		//Fetching OfferActivity
		offerActivityReqBody.activityStart = formatDate(startDate); //formatDate(startDate.toLocaleDateString());
		offerActivityReqBody.activityEnd = formatDate(today);
		dispatch(fetchOfferActivityStats(requestHeaders, offerActivityReqBody));
		var lineChartSpinDiv = document.getElementById("lineChartSpinDiv");
		var lineChartDiv = document.getElementById("lineChartDiv");
		lineChartSpinDiv.style.display = "block";
		lineChartDiv.style.display = "none";

		//Fetching ExpiryOffers
		dispatch(fetchOfferExpirtyStats(requestHeaders, expiryOfferReqBody));
		var progressChartSpinDiv = document.getElementById("progressChartSpinDiv");
		var progressChartDiv = document.getElementById("progressChartDiv");
		progressChartSpinDiv.style.display = "block";
		progressChartDiv.style.display = "none";
	}, [dateDuration]);

	const [dateRange, setDateRange] = useState([
		{
			startDate: moment().subtract(6, "day").valueOf(),
			endDate: moment().valueOf(),
			key: "selection",
		},
	]);
	useEffect(() => {
		let innerSectionWrapper = document.querySelector(".inner-section-wrapper");
		if (innerSectionWrapper) {
			innerSectionWrapper.style.minWidth = "1000px";
		}
		return () => {
			if (innerSectionWrapper) {
				innerSectionWrapper.style.minWidth = "550px";
			}
		};
	}, []);
	const [, startTransition] = useTransition();

	function handleRowClick(row) {
		navigate({
			pathname: "/" + window.location.pathname.split("/")[1] + OFFER + DETAILS,
			search: `?offerid=${row.offerID}`,
		});
	}
	function generateCustomRequestBody(tableReqBody) {
		const today = formatDate(convertUTCDateToLocalDate(Date.now()));
		const calulatedDate = formatDate(
			convertUTCDateToLocalDate(
				new Date(Date.now() - parseInt(dateDuration) * 24 * 60 * 60 * 1000)
			)
		);
		let requestBody = {
			...tableReqBody,
			activityEnd: today,
			activityStart: dateDuration ? calulatedDate : today,
		};
		return requestBody;
	}

	return (
		<div className="dashboardWrapper">
			<div className="welcome-message-container">
				<h5 className="welcome-message-header">{DASHBOARD_HEADER}</h5>
				<div className="message-text">{DASHBOARD_HEADER_DESCRIPTION}</div>
				<div className="message-button">
					{checkRightsIsVisible(
						getRightsKey(["button", "loyalty", "dashboard", "createOffer"])
					) ? (
						<button
							onClick={createOfferhandller}
							className="btn btn-primary message-button-create-Offer"
							disabled={
								!checkRightsIsEditable(
									getRightsKey([
										"button",
										"loyalty",
										"dashboard",
										"createOffer",
									])
								)
							}
						>
							{CREATE_OFFER_LINK}
						</button>
					) : null}
					{checkRightsIsVisible(
						getRightsKey(["link", "loyalty", "dashboard", "viewoffer"])
					) ? (
						<a
							onClick={
								!checkRightsIsEditable(
									getRightsKey(["link", "loyalty", "dashboard", "viewoffer"])
								)
									? ""
									: viewhandller
							}
							className="message-link-create-Offer"
							disabled={
								!checkRightsIsEditable(
									getRightsKey(["link", "loyalty", "dashboard", "viewoffer"])
								)
							}
						>
							{VIEW_OFFER_LINK}
						</a>
					) : null}
				</div>
			</div>

			<div className="dashboard_chart_date_button_container spend-section-fieldset">
				<fieldset className="selector-fieldset reward-fieldset dropdown">
					<legend>Date Range</legend>
					<Select
						defaultValue={dateRangeDefaultValue}
						name="DATE_RANGE"
						options={dateRangeOptions}
						onChange={(value) => {
							startTransition(() => {
								updateDateDiff(value);
							});
						}}
						styles={getCustomStyles(theme)}
						isSearchable={false}
					/>
				</fieldset>
			</div>

			<div className="dashboard_chart_container">
				<BarChart
					data={BAR_CHART_DATA}
					option={BAR_CHART_OPTIONS_THEME(theme)}
					id="offerStatusStatChart"
				/>
				<LineChart id="offerActivityStatChart" />
				<ProgressChart id="expiringOfferStatChart" />
			</div>

			<GenericTable
				COLUMNS={DASHBOARD_COLUMNS}
				FILTER_SHOW_LIMIT={1}
				SHOW_HEADER={false}
				API_URL={process.env.REACT_APP_GET_OFFER_LIST_DASHBOARD}
				TABLE_SRC={DASHBOARD}
				SHOW_HEADER_DASHBOARD={true}
				HEADER_TITLE={OFFER_DASHBOARD_HEADER_TEXT}
				dateDuration={dateDuration}
				SHOW_TOOLTIP={true}
				ROW_CLICKABLE={true}
				ROW_CLICK_HANDLER={handleRowClick}
				REQUEST_BODY_KEY={OFFER_DASHBOARD_REQ_BODY_KEY}
				RESPONSE_BODY_KEY={OFFER_DASHBOARD_RES_BODY_KEY}
				IS_SEARCH_ALLOWED={false}
				CSTM_REQ_BODY={generateCustomRequestBody}
				SAVE_SEARCHED_FILTER={{
					isSaved: true, //  true || false => default : true
					storage: REDUX, // redux  || local || session  => default : "redux"
				}}
			/>
		</div>
	);
};
export default DashboardWrapper;
