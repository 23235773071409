import React, { useEffect, useState } from "react";
import "./AttributeOptions.style.scss";
import { images } from "../../imagesComponent/Images";
import { ZERO } from "../../utilsComponent/GenericRules/GenericRulesConstants.js";
import {
	ATTRIBUTE_OPTIONS_TITLE,
	OPTION,
	OPTIONS,
	OPTION_ALREADY_EXISTS_MESSAGE,
	OPTION_VALIDATION_MESSAGE,
} from "./AttributeOptionsConstant";
import { AnimatePresence, motion } from "framer-motion/dist/framer-motion";
import { useDispatch, useSelector } from "react-redux";
import { setAttributeOptions } from "../../../tellurideExtRedux";
import { DISABLED, LIST, SUCCESS } from "../../constantComponent/Constants";
import { DESCRIPTION_LABEL } from "../../offerSetupComponent/offerDetailsComponent/OfferDetailsConstants.js";

const AttributeOptions = ({
	isDisabled,
	attributeSetupValidation,
	setAttributeSetupValidation,
}) => {
	const [option, setOption] = useState("");
	const [optionDescription, setOptionDescription] = useState("");
	const [optionsList, setOptionsList] = useState([]);
	const metaData = useSelector((state) => state.metaData);
	const updateAttributeDetailsData = metaData.updateAttributeDetailsData;

	function handleBlur() {
		if (optionsList.length === 0 && option.length === 0) {
			setAttributeSetupValidation((prev) => {
				return {
					...prev,
					options: {
						status: true,
						message: OPTION_VALIDATION_MESSAGE,
						touched: true,
						code: 1,
					},
				};
			});
		}
	}
	function handleOptionClick() {
		if (isDisabled) {
			return;
		}
		if (
			option.length > ZERO &&
			optionDescription.length > ZERO &&
			isDisabled === false &&
			attributeSetupValidation?.options?.code !== 2
		) {
			setOptionsList((prev) => [
				...prev,
				{ name: option.trim(), description: optionDescription },
			]);
			setOption("");
			setOptionDescription("");
		} else if (option.length === 0) {
			setAttributeSetupValidation((prev) => {
				return {
					...prev,
					options: {
						...prev.options,
						status: true,
						message: "Option name is required",
						touched: true,
						code: 1,
					},
				};
			});
		} else if (optionDescription.length === 0) {
			setAttributeSetupValidation((prev) => {
				return {
					...prev,
					options: {
						...prev.options,
						status: true,
						message: "Option description is required",
						touched: true,
						code: 1,
					},
				};
			});
		}
	}
	const dispatch = useDispatch();

	const initialAttrSaveData = useSelector(
		(state) => state.metaData.getAttributeDetailsResponse
	);
	useEffect(() => {
		if (initialAttrSaveData?.status === SUCCESS) {
			const attrDetails = initialAttrSaveData?.attribute;
			setOptionsList(attrDetails?.options || []);
		} else {
			setOptionsList([]);
		}
	}, [initialAttrSaveData]);

	useEffect(() => {
		if (
			optionsList.length !== 0 &&
			attributeSetupValidation?.options?.status === true
		) {
			setAttributeSetupValidation((prev) => {
				return {
					...prev,
					options: {
						...prev.options,
						status: false,
						message: "",
					},
				};
			});
		}
		dispatch(
			setAttributeOptions({
				options: optionsList,
			})
		);
	}, [optionsList, dispatch]);

	useEffect(() => {
		setAttributeSetupValidation((prev) => {
			let temp = prev;
			for (let inputKey in prev) {
				if (["options"].includes(inputKey)) {
					if (optionsList.length === 0) {
						temp[inputKey] = {
							...prev[inputKey],
							status: true,
							message: OPTION_VALIDATION_MESSAGE,
							code: 1,
						};
					}
				}
			}
			return temp;
		});
	}, [updateAttributeDetailsData.type]);
	useEffect(() => {
		setAttributeSetupValidation((prev) => {
			if (updateAttributeDetailsData?.type?.toLowerCase() !== LIST) {
				return {
					...prev,
					options: {
						...prev.options,
						status: false,
					},
				};
			}
			return prev;
		});
	}, [updateAttributeDetailsData?.type]);
	useEffect(() => {
		if (optionsList.length > 0) {
			if (optionsList.find((opt) => opt.name === option)) {
				setAttributeSetupValidation((prev) => {
					return {
						...prev,
						options: {
							...prev.options,
							status: true,
							message: OPTION_ALREADY_EXISTS_MESSAGE,
							touched: true,
							code: 2,
						},
					};
				});
			} else {
				setAttributeSetupValidation((prev) => {
					return {
						...prev,
						options: {
							...prev.options,
							status: false,
							message: "",
							code: null,
						},
					};
				});
			}
		} else if (optionsList.length === 0) {
			setAttributeSetupValidation((prev) => {
				return {
					...prev,
					options: {
						...prev.options,
						status: true,
						message: OPTION_VALIDATION_MESSAGE,
						code: 1,
					},
				};
			});
		} else {
			setAttributeSetupValidation((prev) => {
				return {
					...prev,
					options: {
						...prev.options,
						status: false,
						message: "",
					},
				};
			});
		}
	}, [option, optionsList]);

	return (
		<>
			<div className="attribute-options-details-title setup-page-section-title">
				{ATTRIBUTE_OPTIONS_TITLE}
			</div>
			<div className="attribute-options-section">
				<div className="attribute-options-section-inner">
					<div className="attribute-option-section-wrapper">
						<div className="option-add-container">
							<div className="option-input-container">
								<fieldset
									className="attribute-option-fieldset"
									disabled={isDisabled === true ? true : false}
								>
									<legend>{OPTION}</legend>
									<form
										onSubmit={(e) => {
											e.preventDefault();
											handleOptionClick();
										}}
									>
										<input
											type="text"
											value={option}
											onChange={(e) => {
												setOption(e.target.value);
											}}
											autocomplete="off"
											autocorrect="off"
											autocapitalize="off"
											spellcheck="false"
											onBlur={handleBlur}
											placeholder="Enter option name"
											minLength={1}
											maxLength={50}
										/>
										<input
											type="submit"
											className="hidden-submit-btn"
											style={{ display: "none" }}
										/>
									</form>
								</fieldset>
								<fieldset
									className="attribute-option-fieldset"
									disabled={isDisabled === true ? true : false}
								>
									<legend>{DESCRIPTION_LABEL}</legend>
									<form
										onSubmit={(e) => {
											e.preventDefault();
											handleOptionClick();
										}}
									>
										<input
											type="text"
											value={optionDescription}
											onChange={(e) => {
												setOptionDescription(e.target.value);
											}}
											autocomplete="off"
											autocorrect="off"
											autocapitalize="off"
											spellcheck="false"
											onBlur={handleBlur}
											placeholder="Enter option description"
											minLength={1}
											maxLength={50}
										/>
										<input
											type="submit"
											className="hidden-submit-btn"
											style={{ display: "none" }}
										/>
									</form>
								</fieldset>
								{/* <button
									className="btn btn-secondary"
									style={{
										marginTop: "1rem",
									}}
									// disabled={
									// 	option.length === 0 ||
									// 	optionDescription.length === 0 ||
									// 	isDisabled === true ||
									// 	attributeSetupValidation?.options?.status === true
									// }
									onClick={handleOptionClick}
								>
									Add Option
								</button> */}
								{!isDisabled && (
									<img
										src={images.addImage}
										alt="add"
										className={`addRuleBtn ${
											isDisabled === true ? DISABLED : null
										}`}
										onClick={handleOptionClick}
									/>
								)}
							</div>
							{/* <img
								src={images.addImage}
								alt="add"
								className={`addRuleBtn ${
									option.length === 0 ||
									(attributeSetupValidation?.options?.status &&
										attributeSetupValidation?.options?.code === 2)
										? DISABLED
										: null
								}`}
								onClick={handleOptionClick}
							/> */}
							{attributeSetupValidation?.options?.status === true &&
							attributeSetupValidation?.options?.touched === true ? (
								<p className="validation-message option">
									{attributeSetupValidation?.options?.message}
								</p>
							) : null}
						</div>
						<AnimatePresence>
							<motion.div
								className="options-list-container"
								initial={{ opacity: 0 }}
								animate={{ opacity: 1 }}
								exit={{ opacity: 0 }}
							>
								<div className="options-table">
									<div className="options-table-header">
										<div className="options-table-header-title">{OPTIONS}</div>
									</div>
									<div className="options-table-body">
										<AnimatePresence>
											{optionsList.length > ZERO &&
												optionsList.map((option, index) => {
													return (
														<motion.div
															className="options-table-row"
															initial={{ opacity: 0 }}
															animate={{ opacity: 1 }}
															exit={{ opacity: 0 }}
														>
															<div
																className="options-table-row-data"
																key={index}
															>
																<div className="data">
																	<span>{option.name}</span>
																</div>
																{isDisabled === false && (
																	<div className="data delete">
																		<img
																			src={images.trashImage}
																			onClick={() => {
																				if (isDisabled === false) {
																					setOptionsList((prev) => {
																						return prev.filter(
																							(prevOption) =>
																								prevOption.name !== option.name
																						);
																					});
																				}
																			}}
																			alt="delete"
																			className={`delete-img ${
																				isDisabled === true && DISABLED
																			}`}
																		/>
																	</div>
																)}
															</div>
														</motion.div>
													);
												})}
										</AnimatePresence>
									</div>
									<div className="options-table-footer">
										<div className="option-table-footer-title">
											Showing {optionsList.length} of {optionsList.length}
											results
										</div>
									</div>
								</div>
							</motion.div>
						</AnimatePresence>
					</div>
				</div>
			</div>
		</>
	);
};

export default AttributeOptions;
