import React, { useEffect, useState } from "react";
import BarChart from "../../../utilsComponent/Chart/BarChart";
import "./TestProgressStats.style.scss";

import {
  BAR_CHART_DATA,
  BAR_CHART_OPTIONS_THEME,
  CAMPAIGN_BAR_CHART_CAMPAIGN_STATUS_DATA,
  CAMPAIGN_BAR_CHART_CAMPAIGN_STATUS_OPTIONS,
  CAMPAIGN_BAR_CHART_DELIVERY_STATUS_DATA,
  CAMPAIGN_BAR_CHART_DELIVERY_STATUS_OPTIONS,
  CAMPAIGN_BAR_CHART_ENGAGEMENT_STATUS_DATA,
  VARIATION_STAT_DATA,
  VARIATION_STAT_DATA_OPTIONS,
} from "../../../constantComponent/ChartConstants";
import { useDispatch, useSelector } from "react-redux";
import {
  DARK,
  OFFER_DETAILS_STATUS_ACTIVATED_MESSAGE,
  OFFER_DETAILS_STATUS_DRAFT_MESSAGE,
  headers,
} from "../../../constantComponent/Constants";
import { useNavigate } from "react-router-dom";
import { skey } from "../../../utilsComponent/CommonUtil";
import {
  CAMPAIGN_STATUS_STAT_CHART,
  DELIVERY_STATUS_STAT_CHART,
  ENGAGEMENT_STATUS_STAT_CHART,
} from "../../campaignDashboardComponent/CampaignDashboardConstant";
import { useTransition } from "react";
import { Chart } from "chart.js";
import { VARIATION_STAT_CHART } from "../ABTestingConstants";
import { getDataSetJson, testProgressLabelAlias, values_in_graph } from "./testProgressStatsConstant";

const TestProgressStats = ({ variationsData }) => {
  const toasterData = useSelector((state) => state.toasterData);
  const campaignData = useSelector((state) => state.campaignDetailsData);
  const [isDataEmpty, setIsDataEmpty] = useState(false);
  const [theme, setTheme] = useState(toasterData.isDarkTheme);
  function showHideEmptyData(graphId, show) {
    if (show === true) {
      let barChartSpinDiv = document.getElementById(
        graphId + "barChartSpinDiv"
      );
      let barChartDiv = document.getElementById(graphId + "barChartDiv");
      barChartSpinDiv.style.display = "none";
      barChartDiv.style.display = "none";
      let barChartEmptyDataDiv = document.getElementById(graphId + "emptyData");
      barChartEmptyDataDiv.style.display = "block";
    } else {
      let barChartEmptyDataDiv = document.getElementById(graphId + "emptyData");
      barChartEmptyDataDiv.style.display = "none";
    }
  }
  useEffect(() => {
    setTheme(toasterData.isDarkTheme);
  }, [toasterData]);
  useEffect(() => {
    //set labels in the x axis of the graph
    // test-progress-chart-container
    if (variationsData?.length > 0) {
      var variationLabelList = [];
      if (variationsData) {
        variationsData.map((vars, index) => {
          variationLabelList.push(`variation${index + 1}`);
        });
      }
      VARIATION_STAT_DATA.labels = variationLabelList;

      // create datasets array
      let dataSetsArray = [];
      values_in_graph.forEach((vars) => {
        dataSetsArray.push(getDataSetJson(vars.label, vars.color));
      });
      VARIATION_STAT_DATA.datasets = dataSetsArray;

      //set data
      VARIATION_STAT_DATA.datasets.forEach((barDataSet) => {
        let dataArray = [];

        variationsData.forEach((vars) => {
          console.log("vars", vars);
          console.log("barDataSet", barDataSet);
          dataArray.push( vars[testProgressLabelAlias[barDataSet["label"]]]);

          // dataArray.push(
          //   barDataSet["label"] === "opened"
          //     ? vars["impression"]
          //     : vars[testProgressLabelAlias[barDataSet["label"]]]
          // );
        });

        barDataSet.data = dataArray;
        var variationStatChart = Chart.getChart(VARIATION_STAT_CHART);
        variationStatChart.data = VARIATION_STAT_DATA;
        variationStatChart.update();
      });
    } else {
      setIsDataEmpty(true);
    }
  }, []);

  useEffect(() => {
    if (campaignData.variationStatsError || variationsData?.length === 0) {
      showHideEmptyData(VARIATION_STAT_CHART, true);
    } else {
      showHideEmptyData(VARIATION_STAT_CHART, false);
    }
  }, [campaignData.variationStatsError, !variationsData]);
  return (
    <>
      {!isDataEmpty ? (
        <div>
          <BarChart
            data={VARIATION_STAT_DATA}
            option={VARIATION_STAT_DATA_OPTIONS}
            name="campaign"
            id={VARIATION_STAT_CHART}
          />
        </div>
      ) : (
        <div className="test-progress-chart-container">
          <BarChart
            data={VARIATION_STAT_DATA}
            option={VARIATION_STAT_DATA_OPTIONS}
            name="campaign"
            id={VARIATION_STAT_CHART}
          />
        </div>
      )}
    </>
  );
};
export default TestProgressStats;
