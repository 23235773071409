import { stratify, tree } from "d3-hierarchy";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import ReactFlow, {
  Background,
  Controls,
  Handle,
  MarkerType,
  useReactFlow,
} from "reactflow";
import { images } from "../../imagesComponent/Images";
import {
  CURSOR_POINTER,
  DISABLED,
  EMPTY,
} from "../../constantComponent/Constants";
import { pie } from "../SidebarIcons";
import { AiOutlineEdit } from "react-icons/ai";
import { TiDeleteOutline } from "react-icons/ti";
import "./GenericFlow.style.scss";
import CampaignActionComponent from "../../campaignSetupComponent/campaignBuilderComponent/campaignPopups/campaignActionComponent/CampaignActionComponent";
import { checkABTestIndicator, deletNode } from "./GenericFlowUtil";
import { getLayoutedElements } from "./GenericFlowUtil";
import { useEdgesState, useNodesState } from "reactflow/dist/esm";
import { createNode } from "./GenericFlowUtil";
import { createEdge } from "./GenericFlowUtil";
import {
  ADD_NODE,
  BOTTOM,
  CUSTOM_NODE_TYPE,
  DATA_ACTION,
  DELETE_BTN_COLOR,
  DELETE_NODE,
  EDGE_COLOR,
  EDIT_NODE_NAME,
  FIRST,
  LAST,
  MIDDLE,
  MODIFY_NODE,
  SOURCE,
  TARGET,
  TOP,
  edgeType,
  proOptions,
} from "./GenericFlowConstant";
import GenericFlowMiddleWare from "./GenericFlowMiddleWareComponent/GenericFlowMiddleWare";
import { getDisplayName } from "./GenericFlowUtil";
import {
  ACTION,
  CAMPAIGN_ACTION,
} from "../../campaignSetupComponent/campaignBuilderComponent/CampaignBuilderConstants";
import CreateContentComponent from "../../contentSetupComponent/createContentComponent/CreateContentComponent";

const GenericFlow = ({
  theme,
  journeyFlow,
  maxChildNodes,
  isDisabled,
  nodeDataObj,
  actionProps,
  componentNameByType,
  journeyFlowHandler,
  displayNameJson,
  nodeNameisEditable = false,
  rights,
}) => {
  // ... (Canvas builder setup)
  const { nodes: layoutedNodes, edges: layoutedEdges } = getLayoutedElements(
    journeyFlow.nodes.map((node) => {
      return {
        ...node,
        position: {
          x: 0,
          y: 0,
        },
        type: CUSTOM_NODE_TYPE,
        draggable: false,
        deletable: false,
      };
    }),
    journeyFlow.edges.map((edge) => {
      return {
        ...edge,
        type: edgeType.step,
        deletable: false,
        markerEnd: {
          type: MarkerType.ArrowClosed,
          width: 15,
          height: 20,
          color: EDGE_COLOR,
        },
        animated: false,
      };
    })
  );
  const [nodes, setNodes, onNodesChange] = useNodesState(layoutedNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(layoutedEdges);

  // ... (FormatTree logic)
  const { fitView } = useReactFlow();
  const { setViewport, zoomIn, zoomOut, setCenter } = useReactFlow();
  const handleTransform = useCallback(() => {
    setViewport({ x: 10, y: 30, zoom: 1.1 }, { duration: 800 });
  }, [setViewport]);
  const [formatTree, setFormatTree] = useState(null);

  // ... (GenericFlow logic)
  const [isSetDisable, setDisable] = useState(false);
  const [ComponentName, setComponentName] = useState({});
  const [nodeData, setNodeData] = useState();
  const [currentClickedNode, setCurrentClickedNode] = useState();
  const [popUpShowProcess, setPopupShowProcess] = useState(false);
  // ... (Custom node type)
  const nodeTypes = useMemo(
    () => ({
      nodeType: nodeType,
    }),
    []
  );

  //...(For disabling the canvas) // Refactor this later
  useEffect(() => {
    setDisable(isDisabled);
  }, [isDisabled]);

  // for handling the new node and modifying the existing node
  useEffect(() => {
    if (nodeData && nodes.length > 0) {
      // ... (Modifying the existing node)
      if (nodeData.id) {
        setNodes((prev) => {
          return prev.map((prevNode) => {
            if (prevNode.id === nodeData.id) {
              return {
                ...prevNode,
                data: {
                  ...prevNode.data,
                  displayData: {
                    ...nodeData?.data?.displayData,
                  },
                  type: nodeData?.data?.type,
                  value: nodeData?.data?.value,
                },
              };
            }
            return prevNode;
          });
        });
      } else {
        // ... (Adding a new node)
        let newNode = createNode(nodeData?.data?.type, EMPTY);
        let newEdge = createEdge(
          currentClickedNode.id,
          newNode.id,
          edgeType.step,
          EMPTY,
          theme
        );
        setNodes((prev) => [
          ...prev,
          {
            ...newNode,
            data: {
              ...newNode.data,
              displayData: {
                ...nodeData?.data?.displayData,
              },
              type: nodeData?.data?.type,
              value: nodeData?.data?.value,
              name: getDisplayName(nodeData?.data?.type, displayNameJson),
            },
          },
        ]);
        setEdges((eds) => eds.concat(newEdge));
        setFormatTree((prev) => Boolean(!prev));
      }
    }
  }, [nodeData]);
  // for handling the disabling of the nodes
  useEffect(() => {
    setNodes((prev) => {
      return prev.map((prevNode) => {
        return {
          ...prevNode,
          data: {
            ...prevNode.data,
            displayProperties: {
              ...prevNode.data.displayProperties,
              isSetDisable: isSetDisable,
            },
          },
        };
      });
    });
    setFormatTree((prev) => !prev);
  }, [isSetDisable]);

  // Centerting the canvas and return to the parent component
  useEffect(() => {
    fitView();
    setCenter(70, 120);
    journeyFlowHandler({ nodes, edges });
    fitView();
  }, [nodes, edges]);

  // formatting the canvas
  useEffect(() => {
    if (formatTree !== null) {
      const { nodes: newNodes, edges: newEdges } = getLayoutedElements(
        nodes,
        edges
      );
      setNodes(
        newNodes.map((prevNode, index) => {
          let hasChild = false;
          if (edges.find((edge) => edge.source === prevNode.id)) {
            hasChild = true;
          }
          return {
            ...prevNode,
            data: {
              ...prevNode.data,
              displayProperties: {
                ...prevNode.data.displayProperties,
                hasChild: hasChild,
                isSetDisable: isSetDisable,
                position:
                  index === 0
                    ? FIRST
                    : index === newNodes.length - 1
                    ? LAST
                    : MIDDLE,
                index: index,
                // handleBlur: handleNodeNameBlur,
                // handleChange: handleNodeNameChange,
              },
            },
          };
        })
      );
      setEdges(newEdges);
      handleTransform();
    }
  }, [formatTree]);

  // Custom node logic

  // useEffect(() => {
  //   setNodes((prev) => {
  //     return prev.map((prevNode) => {
  //       return {
  //         ...prevNode,
  //         data: {
  //           ...prevNode.data,
  //           displayProperties: {
  //             ...prevNode.data.displayProperties,
  //             // handleBlur: handleNodeNameBlur,
  //             // handleChange: handleNodeNameChange,
  //           },
  //         },
  //         name: prev?.name
  //           ? prev.name
  //           : getDisplayName(prevNode.data.type, displayNameJson),
  //       };
  //     });
  //   });
  // }, []);
  function nodeType({ data, id }) {
    let nodeObj =
      typeof nodeDataObj[data?.type.toLowerCase()] === "function"
        ? nodeDataObj[data?.type.toLowerCase()](data?.value?.action?.offerType)
        : nodeDataObj[data?.type.toLowerCase()];
    return (
      <>
        <div className={`node child`}>
          <div className="node-container">
            {data?.displayProperties?.index !== maxChildNodes && (
              <Handle
                type={SOURCE}
                position={BOTTOM}
                id={id}
                isConnectable={false}
                style={{
                  zIndex: 1,
                  backgroundColor: `${nodeObj?.headerTextColor}`,
                  width: "4px",
                  height: "4px",
                  borderRadius: "50%",
                  opacity: 1,
                }}
              />
            )}
            {!FIRST.includes(data?.displayProperties?.position) && (
              <div>
                <Handle
                  type={TARGET}
                  position={TOP}
                  id={id}
                  isConnectable={false}
                  style={{
                    zIndex: 1,
                    backgroundColor: `${nodeObj?.headerTextColor}`,
                    width: "4px",
                    height: "4px",
                    borderRadius: "50%",
                    opacity: 1,
                  }}
                />
              </div>
            )}
            <div className="node-content">
              <div
                className="icon-container"
                style={{
                  background: nodeObj?.iconBgColor,
                }}
              >
                {nodeObj ? nodeObj["icon1"]() : pie()}
                <span className="node-display-name">
                  {getDisplayName(
                    data?.displayData?.displayName,
                    displayNameJson
                  )}
                </span>
              </div>
              <div className="node-content-container">
                <div
                  style={{
                    background: nodeObj?.headerBgColor,
                  }}
                  className="node-content-header"
                >
                  {data?.displayProperties?.nameIsEditable === true ? (
                    <span
                      style={{
                        border: "none !important",
                        borderBottom: `1px ${nodeObj?.headerTextColor} solid`,
                        // calculate width based on the length of the string
                        width: "max-content !important",
                        textAlign: "center",
                        height: 10,
                        color: nodeObj?.headerTextColor,
                        fontSize: 6,
                        fontWeight: 500,
                        borderRadius: 0,
                        marginLeft: 10,
                        display: "flex",
                      }}
                    >
                      {/* <span
												className="node-content-header-text"
												style={{
													fontSize: 6,
													paddingLeft: 0,
													color: nodeObj?.headerTextColor,
												}}
											>
												{id.substring(0, 5).replace(/-/g, "")}-
											</span> */}
                      <input
                        type="text"
                        style={{
                          fontSize: 6,
                          fontWeight: 500,
                          borderRadius: 0,
                          width: 30,
                          color: nodeObj?.headerTextColor,
                        }}
                        autoFocus
                        value={data?.name}
                        onBlur={() => {
                          data?.displayProperties?.handleBlur(id);
                        }}
                        onChange={(e) => {
                          data?.displayProperties?.handleChange(
                            e.target.value,
                            id
                          );
                        }}
                      />
                    </span>
                  ) : (
                    <span
                      style={{
                        color: nodeObj?.headerTextColor,
                        maxWidth: "50%",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                      className="node-content-header-text"
                      title={
                        data?.name
                          ? data?.name
                          : getDisplayName(
                              data?.displayData?.displayName,
                              displayNameJson
                            )
                      }
                    >
                      <span
                        data-action={EDIT_NODE_NAME}
                        style={{
                          borderBottom:
                            data?.displayProperties?.isSetDisable === false &&
                            nodeNameisEditable
                              ? `1px ${nodeObj?.headerTextColor} solid`
                              : "none",
                        }}
                      >
                        {data?.name
                          ? data?.name
                          : getDisplayName(
                              data?.displayData?.displayName,
                              displayNameJson
                            )}
                      </span>
                    </span>
                  )}
                  <div className="node-content-header-icons">
                    {data?.displayProperties?.isSetDisable === false &&
                      rights.isVisible.editNode && (
                        <div data-action={MODIFY_NODE}>
                          <AiOutlineEdit
                            size={10}
                            color={nodeObj?.headerTextColor}
                            data-action={MODIFY_NODE}
                            className={`${
                              data?.displayProperties?.isSetDisable === false &&
                              CURSOR_POINTER
                            }`}
                          />
                        </div>
                      )}
                    {!FIRST.includes(data?.displayProperties?.position) &&
                      data?.displayProperties?.isSetDisable === false &&
                      rights.isVisible.deletNode && (
                        <div data-action={DELETE_NODE}>
                          <TiDeleteOutline
                            size={10}
                            data-action={DELETE_NODE}
                            color={DELETE_BTN_COLOR}
                            className={`${
                              data?.displayProperties?.isSetDisable === false &&
                              CURSOR_POINTER
                            }  ml-5`}
                          />
                        </div>
                      )}
                  </div>
                </div>
                {checkABTestIndicator(data) && (
                  <div className="ab-test-indicator">
                    <span>A/B test</span>
                    <span>
                      <div class="blinking-green"></div>
                    </span>
                  </div>
                )}
                <div className="node-content-body">
                  {data?.displayData &&
                  (data?.displayData).hasOwnProperty("defaultValue") ? (
                    <span className="node-content-body-value default">
                      {nodeObj?.defaultValue}
                    </span>
                  ) : (
                    <>
                      {nodeObj.dataLabel.map(({ key: label, value }) => {
                        return (
                          <div className="node-content-body-container">
                            <span className="node-content-body-label">
                              {label}
                            </span>
                            <span className="node-content-body-label">:</span>
                            <span
                              className="node-content-body-value data"
                              title={
                                data?.displayData
                                  ? data?.displayData[value]
                                  : data?.displayName
                              }
                            >
                              {data?.displayData
                                ? data?.displayData[value]
                                : data?.displayName}
                            </span>
                          </div>
                        );
                      })}
                    </>
                  )}
                </div>
              </div>
            </div>
            {!data?.displayProperties?.hasChild &&
              data?.displayProperties?.index !== maxChildNodes &&
              data?.value &&
              data?.value !== null && (
                <div data-action={ADD_NODE} className="add-image-container">
                  <img
                    src={images.addImage}
                    data-action={ADD_NODE}
                    className={`addRuleBtn ${
                      data?.displayProperties?.isSetDisable ? DISABLED : EMPTY
                    }`}
                  />
                </div>
              )}
          </div>
        </div>
      </>
    );
  }
  function handleNodeNameBlur(id) {
    if (!nodeNameisEditable) return;
    setNodes((prev) => {
      return prev.map((prevNode) => {
        if (prevNode.id === id && !prevNode.data?.name) {
          return {
            ...prevNode,
            data: {
              ...prevNode.data,
              displayProperties: {
                ...prevNode.data?.displayProperties,
                nameIsEditable: false,
              },
              name: getDisplayName(
                prevNode.data.displayData.displayName,
                displayNameJson
              ),
            },
          };
        } else {
          return {
            ...prevNode,
            data: {
              ...prevNode.data,
              displayProperties: {
                ...prevNode.data?.displayProperties,
                nameIsEditable: false,
              },
            },
          };
        }
      });
    });
    setFormatTree((prev) => !prev);
  }
  function handleNodeNameChange(value, id) {
    if (!nodeNameisEditable) return;
    setNodes((prev) => {
      return prev.map((prevNode) => {
        if (prevNode.id === id) {
          return {
            ...prevNode,
            data: {
              ...prevNode.data,
              name: value,
            },
          };
        }
        return prevNode;
      });
    });
    setFormatTree((prev) => !prev);
  }
  // ... (On node click handler)
  function onNodeClick(event, node) {
    if (
      event.target.getAttribute(DATA_ACTION) === EDIT_NODE_NAME &&
      !isSetDisable &&
      nodeNameisEditable
    ) {
      setNodes((prev) => {
        return prev.map((prevNode) => {
          if (prevNode.id === node.id) {
            return {
              ...prevNode,
              data: {
                ...prevNode.data,
                displayProperties: {
                  ...prevNode?.displayProperties,
                  nameIsEditable: true,
                  handleBlur: handleNodeNameBlur,
                  handleChange: handleNodeNameChange,
                },
              },
            };
          } else {
            return {
              ...prevNode,
              data: {
                ...prevNode.data,
                displayProperties: {
                  ...prevNode.displayProperties,
                  nameIsEditable: false,
                },
              },
            };
          }
        });
      });
      setFormatTree((prev) => !prev);
      return;
    } else if (
      event.target.getAttribute(DATA_ACTION) === ADD_NODE &&
      (!isSetDisable || rights.isEditable.addNode)
    ) {
      handleNodeNameBlur();
      setComponentName({
        name: CampaignActionComponent,
        displayName: ACTION,
      });
      node.newNode = true;
      setCurrentClickedNode({
        ...node,
        newNode: true,
        data: {
          ...node.data,
          value: undefined,
          displayData: undefined,
        },
      });
      // setCurrentClickedNode(node);
    } else if (
      event.target.getAttribute(DATA_ACTION) === MODIFY_NODE &&
      (!isSetDisable || rights.isEditable.editNode)
    ) {
      handleNodeNameBlur();
      setComponentName({
        name: componentNameByType[node?.data?.type.toLowerCase()],
        displayName: node?.data?.type.toLowerCase(),
      });
      node.newNode = false;
      setCurrentClickedNode(node);
    } else if (
      event.target.getAttribute(DATA_ACTION) === DELETE_NODE &&
      (!isSetDisable || rights.isEditable.deletNode)
    ) {
      // handleNodeNameBlur();
      return deletNode(node, edges, nodes, setFormatTree, setEdges, setNodes);
    }
    // else if (
    //   event.target.getAttribute(DATA_ACTION) === DELETE_NODE &&
    //   rights.isEditable.deletNode
    // ) {
    //   console.log("----------");
    //   return deletNode(node, edges, nodes, setFormatTree, setEdges, setNodes);
    //   // return;
    // }
    else {
      handleNodeNameBlur();

      setComponentName({
        name: componentNameByType[node?.data?.type.toLowerCase()],
        displayName: node?.data?.type.toLowerCase(),
        source: "parent",
      });
      node.newNode = false;
      setCurrentClickedNode(node);
      // return;
    }
    handleNodeNameBlur();

    setPopupShowProcess(true);
  }
  return (
    <>
      <div className="generic-canvas-container">
        <ReactFlow
          nodes={nodes}
          edges={edges}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          nodeTypes={nodeTypes}
          onNodeClick={onNodeClick}
          fitView={true}
          proOptions={proOptions}
        >
          <Controls showInteractive={false} />
          {/* <Background
              color="var(--text-primary)"
              gap={16}
              size={0.6}
              innerSize={1}
            /> */}
        </ReactFlow>
      </div>
      <div>
        {popUpShowProcess && (
          <GenericFlowMiddleWare
            theme={theme}
            setPopupShowProcess={setPopupShowProcess}
            currentClickedNode={currentClickedNode}
            setNodeData={setNodeData}
            actionProps={actionProps}
            ComponentName={ComponentName}
            setComponentName={setComponentName}
            displayNameJson={displayNameJson}
            isDisabled={isDisabled}
            nodeDataObj={nodeDataObj}
            rights={rights}
            // setOpenContentPopup={setOpenContentPopup}
            // openContentPopup={openContentPopup}
          />
        )}
        {/* {(openContentPopup && showOfferPopup) && (
					<CreateContentComponent setSelectOffer={setSelectOffer} setShowContentPopup={setShowOfferPopup} />
				)} */}
      </div>
    </>
  );
};

export default GenericFlow;
