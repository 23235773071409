import {
  GET_OFFER_STATUS_STATS_REQUEST,
  GET_OFFER_STATUS_STATS_SUCCESS,
  GET_OFFER_STATUS_STATS_FAILURE,
  GET_OFFER_STATUS_STATS_SUCCESS_FAILURE,
  GET_EXPIRING_OFFER_REQUEST,
  GET_EXPIRING_OFFER_SUCCESS,
  GET_EXPIRING_OFFER_FAILURE,
  GET_EXPIRING_OFFER_SUCCESS_FAILURE,
  GET_OFFER_ACTIVITY_REQUEST,
  GET_OFFER_ACTIVITY_SUCCESS,
  GET_OFFER_ACTIVITY_SUCCESS_FAILURE,
  GET_OFFER_ACTIVITY_FAILURE,
  SET_BLANK_SESSION_ID_ERROR,
  SET_SESSION_TIMEOUT_POPUP,
  GET_OFFER_STATISTICS_REQUEST,
  GET_OFFER_STATISTICS_SUCCESS,
  GET_OFFER_STATISTICS_FAILURE,
  GET_OFFER_STATISTICS_SUCCESS_FAILURE,
} from "./OfferAnalyticsType";

const initialState = {
  offeStatusStatsLoading: true,
  offeStatusStatsResponse: "",
  offeStatusStatsError: false,
  expiringOfferStatsResponse: "",
  expiringOfferStatsError: false,
  expiringOfferStatsLoading: true,
  offerActivityStatLoading: true,
  offerActivityStatResponse: "",
  offerActivityStatError: false,
  apiError: "",
  apiSuccessError: "",
  blankSessionIdError: false,
  showExpiredSessionPopup: false,
  offerStatisticsLoading: true,
  offerStatisticsResponse: "",
  offerStatisticsError: "",
};

export const OfferAnalyticsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_OFFER_STATUS_STATS_REQUEST:
      return {
        ...state,
        offeStatusStatsResponse: "",
        offeStatusStatsLoading: true,
        offeStatusStatsError: false,
      };
    case GET_OFFER_STATUS_STATS_SUCCESS:
      return {
        ...state,
        offeStatusStatsResponse: action.payload,
        offeStatusStatsLoading: false,
        offeStatusStatsError: false,
        apiError: "",
      };
    case GET_OFFER_STATUS_STATS_FAILURE:
      return {
        ...state,
        offeStatusStatsResponse: "",
        offeStatusStatsLoading : false,
        offeStatusStatsError: true,
        apiError: action.payload,
      };
    case GET_OFFER_STATUS_STATS_SUCCESS_FAILURE:
      return {
        ...state,
        offeStatusStatsResponse: "",
        offeStatusStatsError: true,
        offeStatusStatsLoading : false,
        apiError: action.payload,
      };
    case GET_EXPIRING_OFFER_REQUEST:
      return {
        ...state,
        expiringOfferStatsResponse: "",
        expiringOfferStatsLoading: true,
        expiringOfferStatsError: false,

      };
    case GET_EXPIRING_OFFER_SUCCESS:
      return {
        ...state,
        expiringOfferStatsResponse: action.payload,
        expiringOfferStatsError: false,
        expiringOfferStatsLoading: false,
      };
    case GET_EXPIRING_OFFER_SUCCESS_FAILURE:
      return {
        ...state,
        expiringOfferStatsResponse: action.payload,
        apiSuccessError: action.payload,
        expiringOfferStatsLoading: false,
        expiringOfferStatsError: true,
      };
    case GET_EXPIRING_OFFER_FAILURE:
      return {
        ...state,
        expiringOfferStatsError: true,
        apiError: action.payload,
        expiringOfferStatsLoading: false,
      };
    case GET_OFFER_ACTIVITY_REQUEST:
      return {
        ...state,
        offerActivityStatResponse: "",
        offerActivityStatLoading: true,
        offerActivityStatError:false,
      };
    case GET_OFFER_ACTIVITY_SUCCESS:
      return {
        ...state,
        offerActivityStatResponse: action.payload,
        offerActivityStatError: false,
        offerActivityStatLoading: false,
      };
    case GET_OFFER_ACTIVITY_SUCCESS_FAILURE:
      return {
        ...state,
        offerActivityStatResponse: action.payload,
        apiSuccessError: action.payload,
        offerActivityStatLoading: false,
        offerActivityStatError: true,
      };
    case GET_OFFER_ACTIVITY_FAILURE:
      return {
        ...state,
        offerActivityStatError: true,
        apiError: action.payload,
        offerActivityStatLoading: false,

      };
    case SET_BLANK_SESSION_ID_ERROR:
      return {
        ...state,
        blankSessionIdError: true,
      };
    case SET_SESSION_TIMEOUT_POPUP:
      return {
        ...state,
        showExpiredSessionPopup: true,
      };
    case GET_OFFER_STATISTICS_REQUEST:
      return {
        ...state,
        offerStatisticsLoading: true,
        offerStatisticsError: "",
      };
    case GET_OFFER_STATISTICS_SUCCESS:
      return {
        ...state,
        offerStatisticsLoading: false,
        offerStatisticsResponse:action.payload,
        offerStatisticsError: "",
      };
    case GET_OFFER_STATISTICS_SUCCESS_FAILURE:
      return {
        ...state,
        offerStatisticsLoading: false,
        apiSuccessError: action.payload,
        offerStatisticsError:action.payload
      };
    case GET_OFFER_STATISTICS_FAILURE:
      return {
        ...state,
        offerStatisticsLoading: false,
        apiError: action.payload,
        offerStatisticsError:action.payload
      };
    default:
      return state;
  }
};
