import { useState } from "react";
import {
	checkFilterEmpty,
	handleAddFilterClick,
	handleDelete,
	handleEditClick,
	handleFilterChange,
} from "./GenericRulesUtil.js";
import { RuleReader, SubgroupReader } from "./GeneticRulesDesignConstant.jsx";
import { PopOver } from "./PopOver.jsx";
import { DISABLED } from "./GenericRulesConstants.js";
import { AnimatePresence, motion } from "framer-motion/dist/framer-motion";
import { images } from "../../imagesComponent/Images.jsx";

export default function RulesReader({
	expression,
	setExpression,
	editProcess,
	setEditProcess,
	setAttributeModal,
	updatedExpression,
	isSetDisable,
	entitesOptions,
	hideButtons,
}) {
	const [popover, setPopover] = useState({
		status: false,
		elementId: null,
	});

	return (
		<div className={`rule-container ${isSetDisable === true && "disabled"}`}>
			<AnimatePresence>
				{expression.map((elm) => {
					return (
						<motion.div
							className="rule-wrapper"
							key={elm.id}
						>
							<div className="subgroup-container">
								<SubgroupReader
									editProcess={editProcess}
									elm={elm}
									handleDelete={handleDelete}
									setEditProcess={setEditProcess}
									setExpression={setExpression}
									handleFilterChange={handleFilterChange}
									handleEditClick={handleEditClick}
									setAttributeModal={setAttributeModal}
									isSetDisable={isSetDisable}
									hideButtons={hideButtons}
								/>
							</div>
							<div className="filters-container">
								<AnimatePresence>
									{elm.filters.map((filter) => {
										return (
											<RuleReader
												editProcess={editProcess}
												filter={filter}
												handleDelete={handleDelete}
												handleEditClick={handleEditClick}
												handleFilterChange={handleFilterChange}
												setAttributeModal={setAttributeModal}
												setEditProcess={setEditProcess}
												setExpression={setExpression}
												updatedExpression={updatedExpression}
												isSetDisable={isSetDisable}
												entitesOptions={entitesOptions}
												hideButtons={hideButtons}
											/>
										);
									})}
								</AnimatePresence>
								{hideButtons === true ? null : (
									<img
										src={images.addImage}
										alt="add"
										className={(() => {
											return checkFilterEmpty(updatedExpression[0].filters) ===
												true || isSetDisable
												? `addRuleBtn ${DISABLED}`
												: "addRuleBtn";
										})()}
										onClick={() => {
											if (
												checkFilterEmpty(updatedExpression[0].filters) ===
													false &&
												isSetDisable === false
											) {
												handleAddFilterClick(elm.id, setPopover);
											}
										}}
									/>
								)}
								<PopOver
									popover={popover}
									setPopover={setPopover}
									setExpression={setExpression}
								/>
							</div>
						</motion.div>
					);
				})}
			</AnimatePresence>
		</div>
	);
}
