import axios from "axios";
import {
  GET_AUTH_INIT_FAILURE,
  GET_AUTH_INIT_REQUEST,
  GET_AUTH_INIT_SUCCESS,
  GET_AUTH_INIT_SUCCESS_FAILURE,
  GET_AUTH_LOGOUT_FAILURE,
  GET_AUTH_LOGOUT_REQUEST,
  GET_AUTH_LOGOUT_SUCCESS,
  GET_AUTH_LOGOUT_SUCCESS_FAILURE,
  GET_ORG_ID_FAILURE,
  GET_ORG_ID_REQUEST,
  GET_ORG_ID_SUCCESS,
  GET_ORG_ID_SUCCESS_FAILURE,
  SET_AUTHLOADING_FLAG,
  SET_BLANK_SESSION_ID_ERROR,
  SET_SESSION_TIMEOUT_POPUP,
} from "./SecurityType";
import {
  ERROR_MESSAGE,
  logoutAuth0,
  setBlankSession,
  setSessionExpire,
  setToaster,
} from "../reduxUtil";
import { RIGHTS, SKEY } from "../../tellurideExtComponents/constantComponent/Constants";
import { setErrorToaster } from "../toaster/ToasterAction";
import cookie from "react-cookies";

export const fetchAuthInitDetails = (
  requestHeaders,
  requestBody,
  logout,
  userOrg
) => {
  return (dispatch) => {
    dispatch(fetchAuthInitRequest());
    axios
      .post(
        process.env.REACT_APP_URL_DOMAIN + process.env.REACT_APP_AUTH_INIT,
        requestBody,
        requestHeaders
      )
      .then((resp) => {
        if (resp.data.errors !== undefined && resp.data.errors.length !== 0) {
          logoutAuth0(logout, userOrg);
        } else {
          sessionStorage.setItem(SKEY, resp?.data?.tellAuthToken);
          sessionStorage.setItem(RIGHTS, resp?.data?.rights[0]);
          localStorage.setItem("AllRights", resp?.data?.rights);
          dispatch(fetchAuthInitSuccess(resp.data));
        }
      })
      .catch((error) => {
        logoutAuth0(logout, userOrg);
      });
  };
};

const fetchAuthInitRequest = () => {
  return {
    type: GET_AUTH_INIT_REQUEST,
  };
};

const fetchAuthInitSuccess = (apiData) => {
  return {
    type: GET_AUTH_INIT_SUCCESS,
    payload: apiData,
  };
};

const fetchAuthInitFailure = (error) => {
  return {
    type: GET_AUTH_INIT_FAILURE,
    payload: error,
  };
};
const fetchAuthInitSuccessFailure = (error) => {
  return {
    type: GET_AUTH_INIT_SUCCESS_FAILURE,
    payload: error,
  };
};
export const fetchAuthLogoutDetails = (
  requestHeaders,
  requestBody,
  logout,
  orgName
) => {
  return (dispatch) => {
    dispatch(fetchAuthLogoutRequest());
    axios
      .get(
        process.env.REACT_APP_URL_DOMAIN + process.env.REACT_APP_AUTH_LOGOUT,
        requestHeaders,
        requestBody
      )
      .then((resp) => {
        logoutAuth0(logout, orgName);
        if (resp.data.errors !== undefined && resp.data.errors.length !== 0) {
          dispatch(fetchAuthLogoutSuccessFailure(resp.data));
        } else {
          dispatch(fetchAuthLogoutSuccess(resp.data));
        }
      })
      .catch((error) => {
        logoutAuth0(logout, orgName);
        dispatch(fetchAuthLogoutFailure(error.message));
      })
      .finally(() => {
        logoutAuth0(logout, orgName);
      });
  };
};

const fetchAuthLogoutRequest = () => {
  return {
    type: GET_AUTH_LOGOUT_REQUEST,
  };
};

const fetchAuthLogoutSuccess = (apiData) => {
  return {
    type: GET_AUTH_LOGOUT_SUCCESS,
    payload: apiData,
  };
};

const fetchAuthLogoutFailure = (error) => {
  return {
    type: GET_AUTH_LOGOUT_FAILURE,
    payload: error,
  };
};
const fetchAuthLogoutSuccessFailure = (error) => {
  return {
    type: GET_AUTH_LOGOUT_SUCCESS_FAILURE,
    payload: error,
  };
};
export const setAuthLoadingFalse = () => {
  return {
    type: SET_AUTHLOADING_FLAG,
  };
};
export const fetchOrgId = (_requestBody, requestHeaders , organization_id) => {
  return async (dispatch) => {
    dispatch(fetchOrgIdRequest());
    try {
      let resp = await axios.get(
        process.env.REACT_APP_URL_DOMAIN +
          process.env.REACT_APP_AUTH_ORG_DETAILS,
        requestHeaders
      );
      if (resp.data.errors !== undefined && resp.data.errors.length !== 0) {
        dispatch(fetchOrgIdSuccessFailure(resp.data));
        dispatch(setErrorToaster(resp.data.errors[0].message));
        setSessionExpire(resp, setSessionExpirePopUp, dispatch);
      } else {
        dispatch(fetchOrgIdSuccess(resp.data));
        cookie.save(organization_id, resp.data.orgId , { path : '/'})
        sessionStorage.setItem("organization_id", resp.data.orgId);
      }
    } catch (err) {
      //showBoundary(err)
      dispatch(fetchOrgIdFailure(err.message));
      setToaster(err, ERROR_MESSAGE, dispatch);
      setSessionExpire(err, setSessionExpirePopUp, dispatch);
      setBlankSession(err, dispatch, requestHeaders, setBlankSessionIdError);
    }
  };
};

export const fetchOrgIdRequest = () => {
  return {
    type: GET_ORG_ID_REQUEST,
  };
};

export const fetchOrgIdSuccess = (apiData) => {
  return {
    type: GET_ORG_ID_SUCCESS,
    payload: apiData,
  };
};

export const fetchOrgIdFailure = (error) => {
  return {
    type: GET_ORG_ID_FAILURE,
    payload: error,
  };
};

export const fetchOrgIdSuccessFailure = (error) => {
  return {
    type: GET_ORG_ID_SUCCESS_FAILURE,
    payload: error,
  };
};
export const setSessionExpirePopUp = () => {
  return {
    type: SET_SESSION_TIMEOUT_POPUP,
  };
};
export const setBlankSessionIdError = () => {
  return {
    type: SET_BLANK_SESSION_ID_ERROR,
  };
};
