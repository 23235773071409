import "./AttributeHeader.style.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  headers,
  OFRI,
  DATE,
  LIST,
  SUCCESS,
  SAVE,
} from "../../constantComponent/Constants";
import { useAuth0 } from "@auth0/auth0-react";
import { getRightKey, getRightsFromSession, skey } from "../../utilsComponent/CommonUtil";
import { createAttribute } from "../../../tellurideExtRedux";
import { miniloadingWithoutMessage } from "../../LoaderComponents/loadingDesignConstant";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { CONFIGURE_ATTRIBUTE_VIEW } from "../../constantComponent/PathConstants";

const AttributeHeader = ({
  isDisabled,
  setIsDisabled,
  attributeSetupValidation,
  setAttributeSetupValidation,
}) => {
  const dispatch = useDispatch();
  const offerDetailsPageData = useSelector((state) => state.offerDetailsData);
  const securityData = useSelector((state) => state.securityData);
  const metaData = useSelector((state) => state.metaData);
  const navigate = useNavigate();
  const updateAttributeDetailsData = metaData.updateAttributeDetailsData;
  const updateAttributeOptionsData = metaData.updateAttributeOptionsData;
  const updateAttributeValidationData = metaData.updateAttributeValidationData;
  const createAttributeLoading = metaData.createAttributeLoading;
  const createAttributeResponse = metaData.createAttributeResponse;

  const { user } = useAuth0();
  const createAttributeHandller = () => {
    headers.sessionId = skey();
    headers.requestAccess = getRightsFromSession();
    headers.organization = offerDetailsPageData?.authOrganizationName;
    const requestHeaders = {
      headers,
    };
    const requestBody = {
      requestorID: OFRI,
      messageVersion: process.env.REACT_APP_MESSAGE_VERSION_01,
      name: updateAttributeDetailsData.name,
      entity: updateAttributeDetailsData.entity,
      type: updateAttributeDetailsData.type,
      description: updateAttributeDetailsData.description,
      createdBy: user?.email,
    };
    if (updateAttributeDetailsData?.type?.toLowerCase() === LIST) {
      requestBody.options = updateAttributeOptionsData.options;
    } else if (updateAttributeDetailsData?.type?.toLowerCase() !== DATE) {
      requestBody.validation = {
        minLength: updateAttributeValidationData.min,
        maxLength: updateAttributeValidationData.max,
      };
    }
    if (isDisabled === false) {
      if (
        Object.values(attributeSetupValidation)?.every((input) => {
          if (input.status) {
            return input.status === true ? false : true;
          }
          return true;
        }) === true
      ) {
        dispatch(createAttribute(requestBody, requestHeaders, true));
      } else {
        setAttributeSetupValidation((prev) => {
          let temp = JSON.parse(JSON.stringify(prev));
          for (let inputKey in prev) {
            if (prev[inputKey].status === true) {
              temp[inputKey].touched = true;
            }
          }
          return temp;
        });
      }
    }
  };

  useEffect(() => {
    if (createAttributeResponse && createAttributeResponse.status === SUCCESS) {
      setIsDisabled(true);
      window.history.pushState(
        null,
        "",
        `${window.location.href}?code=${createAttributeResponse?.code}`
      );
    }
  }, [createAttributeResponse]);
  return (
    <>
      <div className="attribute-navigation-top-div">
        <div className="empty-space-div"></div>
        <div className="navigation-text">
          <p className="offer-name setup-name-header">Attribute Setup</p>
        </div>
        <div className="navigation-buttons">
          <button
            className="cancel-button btn btn-primary"
            disabled={createAttributeLoading === true ? true : false}
            onClick={(e) => {
              navigate(
                "/" +
                  window.location.pathname.split("/")[1] +
                  CONFIGURE_ATTRIBUTE_VIEW
              );
            }}
          >
            Back
          </button>
          {isDisabled === false && (
            <button
              className="save-button btn btn-primary"
              onClick={createAttributeHandller}
              disabled={createAttributeLoading === true ? true : false}
            >
              {createAttributeLoading === true
                ? miniloadingWithoutMessage()
                : SAVE}
            </button>
          )}
        </div>
      </div>
    </>
  );
};

export default AttributeHeader;
