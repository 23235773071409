import "./PreviewTestContent.style.scss";
import { IoClose } from "react-icons/io5";
import Select from "react-select";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { previewImageContent, sendEmail } from "../../../tellurideExtRedux";
import {
	loading,
	miniloading,
	miniloadingWithoutMessage,
} from "../../LoaderComponents/loadingDesignConstant";
import {
	DARK,
	DROPDOWN_BGCOLOR_DARK_THEME,
	DROPDOWN_FOCUSED_BGCOLOR_DARK_THEME,
	DROPDOWN_FOCUSED_BGCOLOR_LIGHT_THEME,
	DROPDOWN_SELECTED_BGCOLOR_DARK_THEME,
	DROPDOWN_SELECTED_BGCOLOR_LIGHT_THEME,
	LIGHT,
	headers,
} from "../../constantComponent/Constants";
import { motion } from "framer-motion/dist/framer-motion";
import { getRightsFromSession, skey } from "../../utilsComponent/CommonUtil";

const PreviewTestContent = ({
	setIsPreviewImage,
	organizationName,
	contentBuilderData,
	theme,
}) => {
	const dispatch = useDispatch();
	const offerData = useSelector((state) => state.offerDetailsData);
	const [dataUri, setDataUri] = useState("");
	const [emailId, setEmailId] = useState("");
	const [subject, setSubject] = useState("");
	const [template, setTemplate] = useState("vertical");
	const regex = new RegExp("[a-z0-9]+@[a-z]+.[a-z]{2,3}");
	const fetchImage = async (url) => {
		var blob = new Blob([url], { type: "image/png" });
		const reader = new FileReader();
		reader.onload = function () {
			setDataUri(reader.result);
		};
		reader.readAsDataURL(blob);
	};
	useEffect(() => {
		(async () => {
			const imageSrc = await fetchImage(offerData.previewImageContantResponse);
		})();
	}, [offerData.previewImageContantResponse]);

	useEffect(() => {
		const requestBody = {};
		requestBody.partnerId = organizationName;
		requestBody.imageId = contentBuilderData.imageId;
		requestBody.imageName = contentBuilderData.imageName;
		requestBody.contentId = offerData.offerData.offerNumber;
		requestBody.disclaimerLegalCopy = "test";
		// use later
		// requestBody.disclaimerLegalCopy = contentBuilderData.disclaimer
		// use later
		requestBody.previewTemplateName = template;
		contentBuilderData.headlines.forEach((headline, index) => {
			requestBody[`headline${index + 1}`] = headline.value;
		});
		contentBuilderData.bodylines.forEach((bodyline, index) => {
			requestBody[`bodyline${index + 1}`] = bodyline.value;
		});
		headers.sessionId = skey();
		headers.organization = offerData?.authOrganizationName;
		const requestHeaders = {
			headers,
		};

		dispatch(previewImageContent(requestHeaders, requestBody));
	}, [template]);

	const sendEmailCall = () => {
		const emailIdValidation = document.getElementById("emailId");
		const errorClass = document.querySelector(".error-message");
		emailIdValidation.classList.remove("error");
		errorClass.classList.remove("show");

		if (regex.test(emailId)) {
			headers.sessionId = skey();
			headers.organization = offerData?.authOrganizationName;
			const requestHeaders = {
				headers,
			};
			const requestBody = {
				callToActionUrl: contentBuilderData.callToActionURL,
				emailId: emailId,
				contentId: offerData.offerData.offerNumber,
				previewTemplateName: template,
				subject: subject,
			};
			dispatch(sendEmail(requestHeaders, requestBody));
			if (!offerData?.sendEmailLoading) {
				setEmailId("");
				setSubject("");
			}
		} else {
			emailIdValidation.classList.add("error");
			errorClass.classList.add("show");
		}
	};

	const handleCloseClick = () => {
		setIsPreviewImage(false);
	};
	const getCustomStyles = (theme) => {
		return {
			control: (provided, state) => ({
				border: "none",
				borderRadius: "4px",
				top: "2px",
				display: "flex",
				padding: "0px",
				outline: "none",
				height: "34px",
				position: "relative",
				top: "-8px",
				// Add more custom styles as needed
			}),
			option: (provided, state) => ({
				...provided,
				backgroundColor:
					state.isSelected && theme === LIGHT
						? DROPDOWN_SELECTED_BGCOLOR_LIGHT_THEME
						: state.isSelected && theme === DARK
						? DROPDOWN_SELECTED_BGCOLOR_DARK_THEME
						: // ? DROPDOWN_FOCUSED_BGCOLOR_LIGHT_THEME
						state.isFocused && theme === DARK
						? DROPDOWN_FOCUSED_BGCOLOR_DARK_THEME
						: state.isFocused && theme === LIGHT
						? DROPDOWN_FOCUSED_BGCOLOR_LIGHT_THEME
						: theme === DARK
						? DROPDOWN_BGCOLOR_DARK_THEME
						: "#fff",
				color: state.isSelected
					? "#fff"
					: state.isFocused && theme === DARK
					? "#fff"
					: state.isFocused && theme === LIGHT
					? DROPDOWN_SELECTED_BGCOLOR_LIGHT_THEME
					: theme === LIGHT
					? DROPDOWN_SELECTED_BGCOLOR_LIGHT_THEME
					: "#fff",
				padding: 8,
			}),
			// Add more style properties as needed
		};
	};

	return (
		<>
			<div className="communicate-main-div">
				<div className="preview-test-model content">
					<div className="modal-backdrop"></div>
					<motion.div
						className="modal"
						initial={{ opacity: 0 }}
						animate={{ opacity: 1 }}
						exit={{ opacity: 0 }}
					>
						<div className="modal-header icon-btn">
							<h4>Preview and Test</h4>
							<a
								className={
									offerData?.sendEmailLoading ? "icon disabled" : "icon"
								}
								onClick={() => handleCloseClick()}
							>
								<IoClose />
							</a>
						</div>
						{/* {fetchImage(offerData.previewImageContantResponse)} */}
						<section className="modal-body">
							<div className="modal-body-preview-img-test-section">
								<div
									className={
										offerData?.previewImageContentLoading
											? "modal-body-preview-img-section image-loader flex-align-center"
											: "modal-body-preview-img-section image-loader"
									}
								>
									{offerData?.previewImageContentLoading && loading()}
									{!offerData?.previewImageContentLoading && (
										<img src={dataUri} className="email-template-image" />
									)}
								</div>
								<div className="modal-body-test-email-section">
									<fieldset
										className="test-fieldset dropdown template-dropdown"
										//disabled={isSetDisable}
									>
										<legend>Template</legend>
										<Select
											value={{ label: template, value: template }}
											options={[
												{ label: "horizontal", value: "horizontal" },
												{ label: "vertical", value: "vertical" },
												{ label: "mix", value: "mix" },
												{ label: "side by side", value: "side by side" },
											]}
											styles={getCustomStyles(theme)}
											onChange={(e) => {
												setTemplate(e.value);
											}}
										/>
									</fieldset>
									<p className="email-section-headline">Test Email</p>
									<fieldset className="text-box-preview">
										<legend>Subject Line</legend>
										<textarea
											className="input-text-textarea-preview"
											rows="3"
											cols="50"
											name="subject-line"
											value={subject}
											onChange={(e) => setSubject(e.target.value)}
											maxLength="30"
										/>
									</fieldset>
									<fieldset className="test-fieldset" id="emailId">
										<legend>Email</legend>
										<input
											type="text"
											value={emailId}
											onChange={(e) => setEmailId(e.target.value)}
										></input>
									</fieldset>
									<div className="error-message">Enter valid email address</div>

									<div className="preview-test-button">
										{/* <button
											className="btn btn-secondary button"
											onClick={handleCloseClick}
											disabled={offerData?.sendEmailLoading}
										>
											Cancel
										</button> */}
										<button
											className="btn btn-primary button"
											onClick={sendEmailCall}
											disabled={offerData?.sendEmailLoading}
										>
											{offerData?.sendEmailLoading
												? miniloading()
												: "Send"}
										</button>
									</div>
								</div>
							</div>
						</section>
					</motion.div>
				</div>
			</div>
		</>
	);
};

export default PreviewTestContent;
