export const pointsValidityOptions = [
  { value: "Transaction Date plus", label: "Transaction Date" },
  { value: "Posted Date", label: "Posted Date" },
  { value: "Specific Date", label: "Specific Date" },
];
export const pointsValidityOptionsAlias = {
  "Transaction Date plus": "Transaction Date plus",
  "Posted Date": "Transaction Post Date plus",
  "Specific Date": "Date",
};
export const pointsValidityOptionsAliasRev = {
  "Transaction Date plus": "Transaction Date",
  "Transaction Post Date plus": "Posted Date",
  "Date": "Specific Date",
};
export const pointsExpireOptions = [
  { value: "Day(s)", label: "Day(s)" },
  { value: "Week(s)", label: "Week(s)" },
  { value: "Month(s)", label: "Month(s)" },
  { value: "Quarter(s)", label: "Quarter(s)" },
  { value: "Year(s)", label: "Year(s)" },
];
export const DURATION_LIMIT = {
  "Day(s)": 730,
  "Week(s)" : 104,
  "Month(s)": 24,
  "Quarter(s)": 8,
  "Year(s)": 2
}
export const DATE_STATUS = "Date"