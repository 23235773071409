import React, { useEffect, useState } from "react";
import { images } from "../../../../imagesComponent/Images";
import { useDispatch, useSelector } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import { ZERO } from "../../../../utilsComponent/GenericRules/GenericRulesConstants";
import {
  changeDateDashFormat,
  skey,
  trimExtraSpaces,
} from "../../../../utilsComponent/CommonUtil";
import {
  BASE_EARN,
  BASE_REDEEM,
  LOYALTY_CASH,
  OFFER_DETAILS_STATUS_ACTIVATED,
  OFFER_DETAILS_STATUS_DEACTIVATED_EXPIRED_MESSAGE,
  OFFER_DETAILS_STATUS_DRAFT,
  OFRI,
  SAVE,
  headers,
  offer_status,
} from "../../../../constantComponent/Constants";
import {
  createUpdateOffer,
  updateOfferData,
} from "../../../../../tellurideExtRedux";
import moment from "moment";
import { convertUTCDateToLocalDate } from "../../../offerDetailsComponent/OfferDetailsUtil";

const OfferSetupHeader = ({ updatedOfferName, setUpdatedOfferName }) => {
  const dispatch = useDispatch();
  const offerDetailsPageData = useSelector((state) => state.offerDetailsData);
  const [offerNameEditable, setOfferNameEditable] = useState(false);
  const [offerDetailValues, setValues] = useState(
    offerDetailsPageData?.offerData
  );

  useEffect(() => {
    dispatch(updateOfferData(offerDetailValues));
  }, [offerDetailValues]);
  const handleEditClickOfferName = () => {
    setOfferNameEditable(true);
  };
  const handleOfferNameChange = (e) => {
    setUpdatedOfferName(e.target.value);
  };
  const saveOfferName = () => {
    setOfferNameEditable(!offerNameEditable);
  };
  function hideErrorToaster(obj) {
    const backdrop = document.querySelector(".backdrop");
    backdrop.classList.remove("show");
    return;
  }
  return (
    <div className="navigation-top-div offer-setup-header">
      <div className="empty-space-div"></div>
      <div className="navigation-text">
        {offerNameEditable ? (
          <>
            <input
              className="input-offer-name"
              placeholder="Enter new offer name"
              maxLength="50"
              onChange={handleOfferNameChange}
              defaultValue={updatedOfferName}
              type="text"
              autoFocus
              onBlur={() => {
                if (updatedOfferName.trim() === "")
                  setUpdatedOfferName(
                    trimExtraSpaces(offerDetailsPageData?.offerName)
                  );
                saveOfferName();
              }}
              autocomplete="off"
              autocorrect="off"
              autocapitalize="off"
              spellcheck="false"
            />
          </>
        ) : (
          <p className="offer-name setup-name-header">{updatedOfferName}</p>
        )}
        {!offerNameEditable && (
          <>
            {offerDetailsPageData?.offerStatus ===
              OFFER_DETAILS_STATUS_DRAFT && (
              <img
                onClick={handleEditClickOfferName}
                src={images.penIcon}
                className="pen-icon"
              />
            )}
          </>
        )}
        {!offerNameEditable && (
          <div
            className={`status-container ${
              offerDetailsPageData?.offerStatus ===
              OFFER_DETAILS_STATUS_ACTIVATED
                ? moment(offerDetailsPageData?.offerData?.offerEndDate).diff(
                    convertUTCDateToLocalDate(new Date()),
                    "days"
                  ) <= -1
                  ? "EXPIRED"
                  : offerDetailsPageData?.offerStatus
                : offerDetailsPageData?.offerStatus
            }`}
          >
            {" "}
            <span className="offer-status-value">
              {offerDetailsPageData?.offerStatus ===
              OFFER_DETAILS_STATUS_ACTIVATED
                ? moment(offerDetailsPageData?.offerData?.offerEndDate).diff(
                    convertUTCDateToLocalDate(new Date()),
                    "days"
                  ) <= -1
                  ? OFFER_DETAILS_STATUS_DEACTIVATED_EXPIRED_MESSAGE
                  : offer_status[offerDetailsPageData?.offerStatus]
                : offer_status[offerDetailsPageData?.offerStatus]}
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

export default OfferSetupHeader;
