export const SAVE_JOURNEY_REQUEST = "SAVE_JOURNEY_REQUEST";
export const SAVE_JOURNEY_SUCCESS = "SAVE_JOURNEY_SUCCESS";
export const SAVE_JOURNEY_FAILURE = "SAVE_JOURNEY_FAILURE";
export const SAVE_JOURNEY_SUCCESS_FAILURE = "SAVE_JOURNEY_SUCCESS_FAILURE";

export const GET_JOURNEY_DETAILS_REQUEST = "GET_JOURNEY_DETAILS_REQUEST";
export const GET_JOURNEY_DETAILS_SUCCESS = "GET_JOURNEY_DETAILS_SUCCESS";
export const GET_JOURNEY_DETAILS_FAILURE = "GET_JOURNEY_DETAILS_FAILURE";
export const GET_JOURNEY_DETAILS_SUCCESS_FAILURE =
  "GET_JOURNEY_DETAILS_SUCCESS_FAILURE";

export const UPDATE_JOURNEY_STATUS_REQUEST = "UPDATE_JOURNEY_STATUS_REQUEST";
export const UPDATE_JOURNEY_STATUS_SUCCESS = "UPDATE_JOURNEY_STATUS_SUCCESS";
export const UPDATE_JOURNEY_STATUS_FAILURE = "UPDATE_JOURNEY_STATUS_FAILURE";
export const UPDATE_JOURNEY_STATUS_SUCCESS_FAILURE =
  "UPDATE_JOURNEY_STATUS_SUCCESS_FAILURE";
export const SET_SESSION_TIMEOUT_POPUP = "SET_SESSION_TIMEOUT_POPUP";
export const SET_BLANK_SESSION_ID_ERROR = "SET_BLANK_SESSION_ID_ERROR";

export const SET_CUSTOM_OFFER_CHANGE_STATUS = "SET_CUSTOM_OFFER_CHANGE_STATUS";
export const SET_CUSTOM_OFFER_NAME = "SET_CUSTOM_OFFER_NAME";
export const SET_CAMPAIGN_OFFER_CHANGE_STATUS =
  "SET_CAMPAIGN_OFFER_CHANGE_STATUS";

export const GET_CHART_DELIVERY_STATUS_REQUEST =
  "GET_CHART_DELIVERY_STATUS_REQUEST";
export const GET_CHART_DELIVERY_STATUS_SUCCESS =
  "GET_CHART_DELIVERY_STATUS_SUCCESS";
export const GET_CHART_DELIVERY_STATUS_FAILURE =
  "GET_CHART_DELIVERY_STATUS_FAILURE";
export const GET_CHART_DELIVERY_STATUS_SUCCESS_FAILURE =
  "GET_CHART_DELIVERY_STATUS_SUCCESS_FAILURE";

export const GET_JOURNEY_METADATA_REQUEST = "GET_JOURNEY_METADATA_REQUEST";
export const GET_JOURNEY_METADATA_SUCCESS = "GET_JOURNEY_METADATA_SUCCESS";
export const GET_JOURNEY_METADATA_FAILURE = "GET_JOURNEY_METADATA_FAILURE";
export const GET_JOURNEY_METADATA_SUCCESS_FAILURE =
  "GET_JOURNEY_METADATA_SUCCESS_FAILURE";
export const SAVE_WINNER_REQUEST = "SAVE_WINNER_REQUEST";
export const SAVE_WINNER_SUCCESS = "SAVE_WINNER_SUCCESS";
export const SAVE_WINNER_FAILURE = "SAVE_WINNER_FAILURE";
export const SAVE_WINNER_SUCCESS_FAILURE = "SAVE_WINNER_FAILURE";

export const GET_VARIATION_STATS_REQUEST = "GET_VARIATION_STATS_REQUEST";
export const GET_VARIATION_STATS_SUCCESS = "GET_VARIATION_STATS_SUCCESS";
export const GET_VARIATION_STATS_FAILURE = "GET_VARIATION_STATS_FAILURE";
export const GET_VARIATION_STATS_SUCCESS_FAILURE =
  "GET_VARIATION_STATS_SUCCESS_FAILURE";
