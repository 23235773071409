export const COLUMNS = [
  {
    title: "Campaign ID",
    key: "id",
    type: "string",
    visible: true,
    filter: true,
    sort: true,
    minWidth: 150,
    initialWidth: 150,
    defaultWidth: 150,
    resizable: true,
    reqBodyKey: { attrName: "id", isAllowed: true },
  },
  {
    title: "Campaign Name",
    key: "name",
    type: "string",
    visible: true,
    filter: true,
    sort: true,
    minWidth: 140,
    initialWidth: 140,
    defaultWidth: 140,
    resizable: true,
    reqBodyKey: { attrName: "name", isAllowed: true },
  },
  {
    title: "Description",
    key: "description",
    type: "string",
    visible: true,
    filter: true,
    sort: true,
    advanceFilter: true,
    minWidth: 180,
    initialWidth: 180,
    defaultWidth: 180,
    resizable: true,
    reqBodyKey: { attrName: "description", isAllowed: true },
  },
  {
    title: "Type",
    key: "type",
    type: "select",
    visible: true,
    filter: true,
    sort: false,
    advanceFilter: false,
    multi: true,
    options: ["Segment Campaign", "Event Campaign"],
    minWidth: 85,
    initialWidth: 85,
    defaultWidth: 85,
    resizable: true,
    reqBodyKey: { attrName: "type", isAllowed: true },
    rights: [
      {
        key: "Segment Campaign",
        rights: ["filter","campaigns","campaign","type","segmentcampaign"],
      },
      {
        key: "Event Campaign",
        rights: ["filter","campaigns","campaign","type","eventcampaign"],
      },
    ],
  },
  {
    title: "Status",
    key: "status",
    type: "select",
    visible: true,
    filter: true,
    sort: true,
    advanceFilter: true,
    multi: true,
    options: ["ACTIVE", "INACTIVE", "DRAFT", "EXPIRED", "AB-Testing"],
    minWidth: 100,
    initialWidth: 100,
    defaultWidth: 100,
    resizable: false,
    reqBodyKey: { attrName: "status", isAllowed: true },
  },
  {
    title: "Start Date",
    key: "startDate",
    type: "date",
    visible: true,
    filter: true,
    sort: true,
    advanceFilter: true,
    minWidth: 100,
    initialWidth: 100,
    defaultWidth: 100,
    resizable: true,
    reqBodyKey: { attrName: "offerStartDate", isAllowed: true },
  },
  {
    title: "End Date",
    key: "endDate",
    type: "date",
    visible: true,
    filter: true,
    sort: true,
    advanceFilter: true,
    minWidth: 100,
    initialWidth: 100,
    defaultWidth: 100,
    resizable: true,
    reqBodyKey: { attrName: "offerEndDate", isAllowed: true },
  },
  {
    title: "Last Modified",
    key: "lastEditedDate",
    type: "date",
    visible: true,
    filter: true,
    sort: true,
    advanceFilter: true,
    minWidth: 130,
    initialWidth: 130,
    defaultWidth: 130,
    resizable: true,
    reqBodyKey: { attrName: "lastEditedDate", isAllowed: true },
  },
  {
    title: "Modified By",
    key: "createdBy",
    type: "string",
    visible: true,
    filter: true,
    sort: true,
    advanceFilter: true,
    minWidth: 150,
    initialWidth: 150,
    defaultWidth: 150,
    resizable: true,
    reqBodyKey: { attrName: "createdBy", isAllowed: true },
  },
];
export const VIEW_CAMPAIGN_PLACEHOLDER = "Search campaigns";
export const VIEW_CAMPAIGN = "VIEW_CAMPAIGN";
export const CAMPAIGN_REQ_BODY_KEY = "filter";
export const CAMPAIGN_RES_BODY_KEY = "list";
export const DATE_TYPE_OPTIONS = [
  { code: "LiveDate", name: "Live Date" },
  { code: "OfferStartDate", name: "Start Date" },
  { code: "OfferEndDate", name: " End Date" },
];
