import React, { useEffect, useState } from "react";
import OfferLayoutGrid from "./OfferLayoutGrid";
import "./OfferLayoutGrid.styles.scss";
import { offerLayoutData, tabs } from "./OfferLayoutGridConstants";
import { offerStatus } from "./OfferLayoutUtil";
const OfferLayoutWrapper = () => {
  const [offerTab, setOfferTab] = useState("available");
  const [offers, setOffers] = useState(offerLayoutData?.offer);
  const [arrangedOffers, setArrangedOffers] = useState({});
  const [styleSettings, setStyleSettings] = useState([]);
  const [presentOffers, setPresentOffers] = useState([]);
  // useEffect(async ()=>{
  //   try {
  //     const response = await fetch("https://rewards.uat.telluride.intra.shopyourway.com/tellurideAS/profile/GetMemberTargetedOffers", {
  //       method: 'POST',
  //       headers: {
  //         'Content-Type': 'application/json',
  //       },
  //       body: JSON.stringify({
  //         "endDTTM": "2024-05-28T10:00:00",
  //         "liveDate": "2024-05-28T00:42:00",
  //         "startDTTM": "2024-05-28T00:42:00",
  //         "offerNumber": "",
  //         "requestorID": "PSYW",
  //         "couponNumber": "",
  //         "memberNumber": "7081197637561466",
  //         "messageVersion": "05",
  //         "languagePreference": "E"
  //     }),
  //     });

  //     if (!response.ok) {
  //       throw new Error('Network response was not ok');
  //     }

  //     const data = await response.json();
  //     console.log(data);
  //     setOffers(data.offers.offerExtended || []);
  //     // setLoading(false);
  //   } catch (error) {
  //     // setError(error);
  //     // setLoading(false);
  //     console.log(error);
  //   }
  // },[])
  function applyStyles(styles) {
    styles.map((styleObj) => {
      const elements = document.querySelectorAll(styleObj.selector);
      elements.forEach((element) => {
        Object.keys(styleObj.styles).forEach((styleProperty) => {
          element.style[styleProperty] = styleObj.styles[styleProperty];
        });
      });
    });
  }

  // Reapply styles when DOM changes
  const observer = new MutationObserver(() => {
    applyStyles(styleSettings);
  });
  observer.observe(document.body, { childList: true, subtree: true });
  useEffect(() => {
    if (offers) {
      offers.forEach((offer) => {
        const startDate =
          offer?.offerMemberGroupDetailList?.offerMemberGroupDetail[0]
            ?.registrationStartDate;
        const endDate =
          offer?.offerMemberGroupDetailList?.offerMemberGroupDetail[0]
            ?.registrationEndDate;
        let status = offerStatus(offer?.used, endDate);
        setArrangedOffers((prev) => ({
          ...prev,
          [status]: [...(prev[status] || []), offer],
        }));
      });
      console.log(arrangedOffers);
    }
  }, []);
  useEffect(() => {
    window.addEventListener("message", (event) => {
      if (event.data.type === "APPLY_STYLES") {
        console.log("Message received:", event.data);
        const styles = event.data.styleSettings;
        setStyleSettings(styles);
        if (styles) {
          applyStyles(styles);
        }
      }
    });
  }, [presentOffers]);
  return (
    <div className="_offer_wrapper">
      <div className="_offer_header">
        {tabs.map((tab, index) => {
          return (
            <div
              className={`_offer_header_tab ${
                offerTab === tab?.value ? "_offer_header_tab_active" : ""
              }`}
              key={index}
              onClick={() => {
                setOfferTab(tab?.value);
                setPresentOffers(arrangedOffers[offerTab]);
              }}
            >
              {tab?.title}
            </div>
          );
        })}
      </div>
      <div className="_offer_content_wrapper">
        {arrangedOffers[offerTab]?.map((offer, index) => {
          return <OfferLayoutGrid key={index} offer={offer} />;
        })}
      </div>
    </div>
  );
};

export default OfferLayoutWrapper;
