import { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
	DUAL_NAVLINKS,
	activeLinkColor,
} from "../../constantComponent/Constants";
import {
	ACTIVE,
	DISABLED,
	ZERO,
} from "../../utilsComponent/GenericRules/GenericRulesConstants.js";
import "./NavigationBar.style.scss";
import { handleNavigation, handleModelPopup } from "./NavigationBarUtil";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTransition } from "react";
import {
	setLeavingSetupPageState,
	setLeavingCreateSegmentPageState,
	setSourceCancelBtn,
} from "../../../tellurideExtRedux";
import {
	ATTRIBUTE,
	CAMPAIGN,
	CONFIGURE,
	DETAILS,
	CUSTOM,
	OFFER,
	VIEW,
} from "../../constantComponent/PathConstants.js";
import {
	checkRightsIsVisible,
	getRightsKey,
} from "../../utilsComponent/CommonUtil.js";

const NavigationBar = ({ showOfferPopup, setShowOfferPopup, src }) => {
	const location = useLocation();
	const navigate = useNavigate();
	const [NAVLINKS, SETNAVLINKS] = useState(DUAL_NAVLINKS);

	const dispatch = useDispatch();
	const [currentNavLink, setCurrentNavLink] = useState([]);
	const leaveSetupCreation = useSelector((state) => state.leaveSetupCreation);
	const offerData = useSelector((state) => state.offerDetailsData);
	const campaignDetailsPageData = useSelector(
		(state) => state.campaignDetailsData
	);
	const segmentData = useSelector((state) => state.segmentDetailsData);
	const journeyDetailsData = useSelector((state) => state.journeyDetailsData);

	const [isPending, startTransition] = useTransition();
	const [navLinksLoading, setNavLinksLoading] = useState(true);
	useEffect(() => {
		if (leaveSetupCreation.leaveSetupPageState) {
			if (leaveSetupCreation.sourceCancelBtnState) {
				handleNavigation(
					navigate,
					{
						url: leaveSetupCreation.pathCancelBtn,
					},
					false,
					setShowOfferPopup
				);
			} else {
				handleNavigation(
					navigate,
					currentNavLink,
					showOfferPopup,
					setShowOfferPopup
				);
			}
			dispatch(setSourceCancelBtn({ state: false, path: "" }));
			dispatch(setLeavingSetupPageState(false));
		}
	}, [leaveSetupCreation]);

	useEffect(() => {
		let currentParentPath = `/${
			location.pathname.split("/").filter(Boolean)[1]
		}`;
		let childPath = `/${location.pathname}`
			.split("/")
			.filter(Boolean)
			.slice(1)
			.join("/");
		childPath = "/" + childPath;
		startTransition(() => {
			SETNAVLINKS((navLinks) => {
				return navLinks.map((navLink) => {
					if (navLink.activeOption.includes(currentParentPath)) {
						let subLinkTobeUpdated = navLink.subLinks.find((subLink) => {
							return subLink.activeOption.includes(childPath) && checkRightsIsVisible(getRightsKey(subLink.rights));
						});

						return {
							...navLink,
							isActive: true,
							subLinks: navLink.subLinks.map((subLink) => {
								if (
									subLinkTobeUpdated &&
									subLinkTobeUpdated.url === subLink.url
								) {
									return {
										...subLink,
										isActive: true,
									};
								}
								return {
									...subLink,
									isActive: false,
								};
							}),
						};
					}
					return {
						...navLink,
						isActive: false,
					};
				});
			});
		});
		setNavLinksLoading(false);
	}, [location.pathname]);
	return (
		<>
			<div className="parent-navbar">
				{NAVLINKS.map((navLink) => {
					if (
						navLink.isVisible &&
						checkRightsIsVisible(getRightsKey(navLink.rights))
					) {
						return (
							<div
								data-tooltip={navLink.label}
								key={navLink.url}
								className={`nav-link ${navLink.isDisabled && DISABLED}  ${
									navLink.isActive && ACTIVE
								} ${navLink.class}`}
								onClick={() => {
									setCurrentNavLink(navLink);
									handleModelPopup(
										location,
										navigate,
										navLink,
										showOfferPopup,
										setShowOfferPopup,
										offerData.offerStatus,
										offerData.isOfferChanged,
										src,
										segmentData.segmentEdited,
										journeyDetailsData?.isOfferChanged,
										journeyDetailsData?.updateJourneyStatus,
										journeyDetailsData?.isOfferChanged,
										journeyDetailsData?.updateJourneyStatus
									);
								}}
							>
								<div className={`icon-container`}>
									{navLink.icon(
										location.pathname === navLink.url,
										navLink.isDisabled,
										activeLinkColor
									)}
								</div>
							</div>
						);
					}
				})}
			</div>
			{isPending || navLinksLoading ? (
				<div className="child-navbar"></div>
			) : NAVLINKS.find((navLink) => navLink.isActive === true) &&
			  NAVLINKS.find((navLink) => navLink.isActive === true)?.subLinks.length >
					ZERO ? (
				<div className="child-navbar">
					{NAVLINKS.map((navLink) => {
						if (navLink.isActive === true) {
							return navLink.subLinks.map((subLink) => {
								if (
									subLink.isVisible &&
									checkRightsIsVisible(getRightsKey(subLink.rights))
								) {
									return (
										<div
											data-tooltip={subLink.label}
											key={navLink.url + subLink.path}
											className={`sub-link ${subLink.isDisabled && DISABLED}  ${
												subLink.isActive && ACTIVE
											} ${subLink.class}`}
											onClick={() => {
												setCurrentNavLink(subLink);
												handleModelPopup(
													location,
													navigate,
													subLink,
													showOfferPopup,
													setShowOfferPopup,
													offerData.offerStatus,
													offerData.isOfferChanged,
													src,
													segmentData.segmentEdited,
													journeyDetailsData?.isOfferChanged,
													journeyDetailsData?.updateJourneyStatus,
													journeyDetailsData?.isOfferChanged,
													journeyDetailsData?.updateJourneyStatus
												);
											}}
										>
											<div className={`icon-container`}>
												{subLink.icon(
													location.pathname === navLink.url + subLink.path,
													subLink.isDisabled,
													activeLinkColor
												)}
											</div>
										</div>
									);
								}
							});
						}
					})}
				</div>
			) : null}
		</>
	);
};
export default NavigationBar;
