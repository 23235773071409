import React, { useEffect, useState } from "react";
import { CUSTOM_OFFER_DETAILS_TITLE } from "./CustomOfferDetailsConstants";
import {
  DESCRIPTION_LABEL,
  END_DATE_INFO,
  END_DATE_LABEL,
  OFFER_DETAILS_TITLE,
  OFFER_ID_INFO,
  OFFER_ID_LABEL,
  OFFER_START_DATE,
  OFFER_TYPE_LABEL,
  START_DATE_INFO,
  START_DATE_LABEL,
} from "../offerDetailsComponent/OfferDetailsConstants";
import {
  changeDateDashFormat,
  copyToClipBoard,
} from "../../utilsComponent/CommonUtil";
import "./CustomOfferDetails.style.scss";

import DatePicker from "react-date-picker";
import { images } from "../../imagesComponent/Images";
import moment from "moment";
import {
  dateHandler,
  validateDescription,
} from "../offerDetailsComponent/OfferDetailsUtil";
import { RxCopy } from "react-icons/rx";
import { useSelector } from "react-redux";

const CustomOfferDetails = ({
  isSetDisable,
  customOfferDetailsData,
  setCustomOfferDetailsData,
}) => {
  const [copyText, setCopyText] = useState(false);
  const toasterData = useSelector((state) => state.toasterData);
  const [theme, setTheme] = useState(toasterData.isDarkTheme);

  useEffect(() => {
    setTheme(toasterData.isDarkTheme);
  }, [toasterData.isDarkTheme]);
  return (
    <>
      <div className={`custom-offer-details-main-container`}>
        <div className="custom-offer-details-title setup-page-section-title">
          {OFFER_DETAILS_TITLE}
        </div>
        <section className="custom-offer-details-section">
          <div className="form-div">
            <div className="offer-code-wrapper">
              <div className="offer-code">
                <div className="offer-info-container">
                  <label className="offer-info-label">
                    {OFFER_ID_LABEL} :{" "}
                  </label>
                  <span className="offer-info-bold">
                    {customOfferDetailsData?.offerID}
                  </span>
                  {copyText === false ? (
                    <RxCopy
                      className="copy-icon"
                      onClick={() => {
                        setCopyText(true);
                        copyToClipBoard(customOfferDetailsData?.offerID);
                        setTimeout(() => {
                          setCopyText(false);
                        }, 3000);
                      }}
                    />
                  ) : (
                    <p className="copy-icon-text">Copied!</p>
                  )}
                </div>
                <p>{OFFER_ID_INFO}</p>
              </div>
              <div className="offer-code">
                <div className="offer-info-container offer-type-container">
                  <label className="offer-info-label offer-type-label">
                    {OFFER_TYPE_LABEL} :{" "}
                  </label>
                  <span className="offer-info-bold">
                    {customOfferDetailsData?.journeyType}
                  </span>
                </div>
              </div>
            </div>

            <div className="date-wrapper">
              <div className="date-wrapper-inside">
                <fieldset id="start-date" disabled={isSetDisable}>
                  <legend>{START_DATE_LABEL}</legend>
                  <DatePicker
                    calendarIcon={
                      <img
                        src={images.calendarIcon}
                        className="calendar-icon"
                      />
                    }
                    clearIcon={null}
                    format="y-MM-dd"
                    value={moment(
                      customOfferDetailsData?.startDate
                    ).toLocaleString("en-US")}
                    minDate={moment(
                      customOfferDetailsData?.createdDate,
                      "YYYY-MM-DD"
                    ).toDate()}
                    onChange={(value) => {
                      setCustomOfferDetailsData((prev) => {
                        return {
                          ...prev,
                          startDate: new Date(value).toLocaleDateString(
                            "en-US"
                          ),
                        };
                      });
                    }}
                    disabled={isSetDisable}
                    className={"top-230 left-67"}
                  />
                </fieldset>

                <div className="info-div">
                  <p>{START_DATE_INFO}</p>
                </div>
              </div>

              <div className="date-wrapper-inside" id="end-date">
                <fieldset disabled={isSetDisable}>
                  <legend>{END_DATE_LABEL}</legend>
                  <DatePicker
                    calendarIcon={
                      <img
                        src={images.calendarIcon}
                        className="calendar-icon"
                      />
                    }
                    clearIcon={null}
                    format="y-MM-dd"
                    minDate={moment(
                      changeDateDashFormat(customOfferDetailsData?.startDate),
                      "YYYY-MM-DD"
                    ).toDate()}
                    onChange={(value) => {
                      setCustomOfferDetailsData((prev) => {
                        return {
                          ...prev,
                          endDate: new Date(value).toLocaleDateString("en-US"),
                        };
                      });
                    }}
                    value={moment(
                      customOfferDetailsData?.endDate
                    ).toLocaleString("en-US")}
                    disabled={isSetDisable}
                    className={"top-230"}
                  />
                </fieldset>

                <div className="info-div">
                  <p>{END_DATE_INFO}</p>
                </div>
              </div>
            </div>

            <div className="textbox-wrapper">
              <fieldset className="text-box" disabled={isSetDisable}>
                <legend>{DESCRIPTION_LABEL}</legend>
                <textarea
                  className="input-text-textarea"
                  rows="3"
                  cols="50"
                  value={customOfferDetailsData?.description}
                  maxLength="250"
                  name="offerDescription"
                  onChange={(e) =>
                    setCustomOfferDetailsData((prev) => {
                      return {
                        ...prev,
                        description: validateDescription(e),
                      };
                    })
                  }
                />
              </fieldset>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default CustomOfferDetails;
