import { FaRegCheckCircle, FaRegTimesCircle } from "react-icons/fa";
import {
  DARK,
  LIGHT,
  TOASTER_ERROR,
  TOASTER_SUCCESS,
} from "../../tellurideExtComponents/constantComponent/Constants";
import {
  SET_TOASTER_LIST,
  DELETE_TOASTER_LIST,
  SET_THEME_TOGGLE,
  SET_PAGE_LOADED,
} from "./ToasterType";

export const setSuccessToaster = (successMessage) => {
  const payload = {
    title: "Success",
    type: TOASTER_SUCCESS,
    description: successMessage,
    icon: <FaRegCheckCircle color="#22bb33" size={30} />,
  };
  return {
    type: SET_TOASTER_LIST,
    payload: payload,
  };
};
export const setErrorToaster = (errorMessage) => {
  const payload = {
    title: "Error",
    type: TOASTER_ERROR,
    description: errorMessage,
    icon: <FaRegTimesCircle color="red" size={30} />,
  };
  return {
    type: SET_TOASTER_LIST,
    payload: payload,
  };
};
export const deleteToasterList = (id) => {
  return {
    type: DELETE_TOASTER_LIST,
    payload: id,
  };
};

export const setDarkLightModeStatus = (status) => {
  localStorage.setItem("theme", status ? DARK : LIGHT);
  return {
    type: SET_THEME_TOGGLE,
    payload: status,
  };
};
export const setPageLoaded = (status) => {
  return {
    type: SET_PAGE_LOADED,
    payload: status,
  };
};
