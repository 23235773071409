import React, { useState } from "react";
import "./TestProgressComponent.style.scss";
import { emptyData } from "../../../constantComponent/DesignConstant";

const TestProgressComponent = ({
  variationsData,
  abTestDetails,
  isAbTestPresent,
}) => {
  const [isDataEmpty, setIsDataEmpty] = useState(variationsData?.length === 0);
  return (
    <>
      {!isDataEmpty ? (
        <div className="test-progress-section">
          {isAbTestPresent && (
            <div className="test-progress-header">
              <p className="progress-title">Test in Progress...</p>
            </div>
          )}

          {/* <div className="test-progress-body">
        <p>
          Time remaining : <span>1d</span>
          <span>2h</span>
          <span>24m</span>
        </p>
      </div> */}
          <div className="test-progress-footer-1">
            <div className="email-data">
              <p>
                Sent : <span>{abTestDetails.totalSent}</span>
              </p>
            </div>

            <div className="email-data">
              <p>
                Delivered : <span>{abTestDetails.totalDelivered}</span>
              </p>
            </div>
          </div>
          <div className="test-progress-footer-2">
            <div className="email-data">
              <p>
                Opened : <span>{abTestDetails.totalImpression}</span>
              </p>
            </div>
            <div className="email-data">
              <p>
                Clicked : <span>{abTestDetails.totalClicked}</span>
              </p>
            </div>
          </div>
        </div>
      ) : (
        <div className="empty-table-container">{emptyData()}</div>
      )}
    </>
  );
};

export default TestProgressComponent;
