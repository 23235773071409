import "./CampaignWrapper.style.scss";
import { useEffect, useState } from "react";

import CampaignHeader from "../campaignHeaderComponent/CampaignHeader";
import CampaignDetails from "../campaignDetailsComponent/CampaignDetails";

//import { getEntitiesDetails } from "../../../tellurideExtRedux/metaData/MetaDataAction.js";
import { useDispatch, useSelector } from "react-redux";

import Toaster from "../../errorsComponent/Toaster.jsx";
import Loading from "../../LoaderComponents/Loading.jsx";
import {
  CAMPAIGN_LOADER_SOURCE,
  OFFER_LOADER_SOURCE,
} from "../../LoaderComponents/LoadingConstant.js";
import { ErrorBoundary } from "react-error-boundary";
import Error from "../../errorsComponent/Error.jsx";
import { useErrorBoundary } from "react-error-boundary";
import checkIcon from "../../../tellurideExtAssets/images/offerSetupImages/check.png";
import errorIcon from "../../../tellurideExtAssets/images/offerSetupImages/error.svg";
import {
  ESLO,
  OFFER_DETAILS_STATUS_DRAFT,
  OFRI,
  OFRP,
  SUCCESS,
  TOASTER_ERROR,
  TOASTER_SUCCESS,
  WEEKS,
} from "../../constantComponent/Constants.js";
import DateRangeFilter from "../../utilsComponent/CalendarDateRange/CalendarDateRange.js";

import { headers } from "../../constantComponent/Constants.js";

import { useNavigate } from "react-router-dom";
import {
  VIEW_OFFER,
  OFFER,
  CAMPAIGN,
  VIEW,
} from "../../constantComponent/PathConstants.js";
import {
  changeDateDashFormat,
  checkRightsIsEditable,
  checkRightsIsVisible,
  getRightsFromSession,
  getRightsKey,
  skey,
} from "../../utilsComponent/CommonUtil.js";

import CampaignBuilder from "../campaignBuilderComponent/CampaignBuilder";
import { ReactFlowProvider } from "reactflow";
import ABTestingWrapper from "../campaignABTestingComponent/ABTestingWrapper.jsx";
import GenericCron from "../../utilsComponent/GenericCron/GenericCron.jsx";
import { ALLOWED_PERIODS } from "../campaignDetailsComponent/CampaignDetailsConstants.js";
import {
  DAILY_LABEL,
  DAILY_VALUE,
  DAY_LABEL,
  DAY_VALUE,
  DEFAULT_CRON,
  DOES_NOT_REPEAT_LABEL,
  DOES_NOT_REPEAT_VALUE,
  FIRST_DAY_OF_MONTH_LABEL,
  FIRST_DAY_OF_MONTH_VALUE,
  LAST_DAY_OF_MONTH_LABEL,
  LAST_DAY_OF_MONTH_VALUE,
  MONTHLY_LABEL,
  MONTHLY_VALUE,
  REPEAT_LABEL,
  REPEAT_VALUE,
  STAR,
  WEEKLY_LABEL,
  WEEKLY_VALUE,
} from "../../utilsComponent/GenericCron/GenericCronConstant.js";
import isEqual from "react-fast-compare";
import {
  fetchJourneyDetails,
  setCampaignOfferChangeStatus,
} from "../../../tellurideExtRedux/journey/JourneyAction.js";
import UnauthorizedAccess from "../../unauthorizeComponent/UnauthorizeWrapper.jsx";
const CampaignWrapper = ({ setShowOfferPopup }) => {
  const dispatch = useDispatch();
  const [isDisabled, setIsDisabled] = useState(false);
  const offerDetailsPageData = useSelector((state) => state.offerDetailsData);
  const journeyDetailsData = useSelector((state) => state.journeyDetailsData);
  const securityData = useSelector((state) => state.securityData);
  const queryString = require("query-string");
  const offerNumber = queryString.parse(window.location.search).offerid;
  const [triggerEventName, setTriggerEventName] = useState("");
  const navigate = useNavigate();
  const [campaignDetailsData, setCampaignDetailsData] = useState({});
  const [campaignBuilderData, setCampaignBuilderData] = useState({
    journeyFlow: {
      nodes: [],
      edges: [],
    },
  });
  const [BACKEND_CAMPAIGN_DATA, SET_BACKEND_CAMPAIGN_DATA] = useState({});

  const [offerSetupDisabled, setOfferSetupDiabled] = useState(isDisabled);
  const journeyType =
    journeyDetailsData?.journeyDetailsResponse?.journeyAttributes?.journeyAttribute?.some(
      (attribute) => attribute.name === "DISPLAY_OFFER_TYPE"
    )
      ? journeyDetailsData?.journeyDetailsResponse.journeyAttributes.journeyAttribute.find(
          (attribute) => attribute.name === "DISPLAY_OFFER_TYPE"
        ).values.value[0]
      : journeyDetailsData?.journeyDetailsResponse?.journeyType;
  useEffect(() => {
    if (journeyType) {
      const isEditable = checkRightsIsEditable(
        getRightsKey([
          "campaign",
          "detail",
          "setup",
          journeyType.split(" ").join(""),
        ])
      );
      if (
        journeyDetailsData?.journeyDetailsResponse?.journeyData
          ?.journeyStatus === OFFER_DETAILS_STATUS_DRAFT &&
        isEditable
      ) {
        setOfferSetupDiabled(false);
      } else {
        setOfferSetupDiabled(true);
      }
    }
  }, [journeyType, isDisabled]);
  // alert(!checkRightsIsEditable(
  //   getRightsKey(["button","campaign","createcampaign","save"])
  // ) || isDisabled)
  const [GenericCronObj, setGenericCronObj] = useState({
    startDate: campaignDetailsData?.startDate,
    time: {
      hour: 0,
      minute: 0,
      value: "12:00",
    },
    repeatFrequency: {
      label: DOES_NOT_REPEAT_LABEL,
      value: DOES_NOT_REPEAT_VALUE,
    },
    repeatPeriod: "",
    weeksValue: "",
    repeatMonthly: "",
    repeatMonthlyDate: [
      {
        label: new Date().getDate(),
        value: new Date().getDate(),
      },
    ],
    endDate: campaignDetailsData?.endDate,
  });

  useEffect(() => {
    headers.sessionId = skey();
    headers.requestAccess = getRightsFromSession();
    headers.organization = offerDetailsPageData?.authOrganizationName;
    const requestHeaders = {
      headers,
    };
    const offerRequestBody = {
      requestorID: OFRI,
      messageVersion: process.env.REACT_APP_MESSAGE_VERSION_03,
      // orgName: offerDetailsPageData?.organizationName,
      journeyNumber: offerNumber ? offerNumber : "",
    };
    dispatch(
      fetchJourneyDetails(requestHeaders, offerRequestBody, {
        navigate: navigate,
        successRedirect: "",
        errorRedirectionPath: CAMPAIGN + VIEW,
      })
    );
  }, []);

  useEffect(() => {
    setIsDisabled(
      journeyDetailsData?.journeyDetailsResponse?.journeyData?.journeyStatus ===
        OFFER_DETAILS_STATUS_DRAFT
        ? false
        : true
    );
    if (
      journeyDetailsData?.journeyDetailsLoading === false &&
      journeyDetailsData?.journeyDetailsResponse &&
      journeyDetailsData?.journeyDetailsResponse?.status === SUCCESS
    ) {
      setCampaignDetailsData({
        description:
          journeyDetailsData?.journeyDetailsResponse?.journeyData
            ?.journeyDescription,
        startDate:
          journeyDetailsData?.journeyDetailsResponse?.journeyData
            ?.journeyStartDate,
        endDate:
          journeyDetailsData?.journeyDetailsResponse?.journeyData
            ?.journeyEndDate,
        cronExpression:
          journeyDetailsData?.journeyDetailsResponse?.journeyFlow?.metaData
            ?.scheduleCronExpression || "00 0 * * *",
      });
      if (
        journeyDetailsData?.journeyDetailsResponse?.journeyFlow?.nodes?.length >
          0 ||
        journeyDetailsData?.journeyDetailsResponse?.journeyFlow?.edges?.length >
          0
      ) {
        setCampaignBuilderData({
          journeyFlow: {
            nodes:
              journeyDetailsData?.journeyDetailsResponse?.journeyFlow?.nodes.map(
                (node) => {
                  return {
                    ...node,
                    data: {
                      // displayData: node.data.displayData,
                      type: node.data.type,
                      value: node.data.value,
                    },
                  };
                }
              ),
            edges:
              journeyDetailsData?.journeyDetailsResponse?.journeyFlow?.edges,
          },
        });
      }
      SET_BACKEND_CAMPAIGN_DATA((prev) => {
        return {
          ...prev,
          description:
            journeyDetailsData?.journeyDetailsResponse?.journeyData
              ?.journeyDescription,
          startDate:
            journeyDetailsData?.journeyDetailsResponse?.journeyData
              ?.journeyStartDate,
          endDate:
            journeyDetailsData?.journeyDetailsResponse?.journeyData
              ?.journeyEndDate,
          cronExpression:
            journeyDetailsData?.journeyDetailsResponse?.journeyFlow?.metaData
              ?.scheduleCronExpression || "00 0 * * *",
          nodes:
            journeyDetailsData?.journeyDetailsResponse?.journeyFlow?.nodes.map(
              (node) => {
                return {
                  ...node,
                  data: {
                    // displayData: node.data.displayData,
                    type: node.data.type,
                    value: node.data.value,
                  },
                };
              }
            ) || [],
          edges:
            journeyDetailsData?.journeyDetailsResponse?.journeyFlow?.edges ||
            [],
        };
      });

      const backendCronExpression =
        journeyDetailsData?.journeyDetailsResponse?.journeyFlow?.metaData
          ?.scheduleCronExpression || "00 0 * * *";

      let minute = backendCronExpression.split(" ")[0];
      minute = minute === "0" ? "00" : minute;
      if (minute.length === 1) {
        minute = `0${minute}`;
      }
      let hour = backendCronExpression.split(" ")[1];
      let repeatFrequency = {
        label: REPEAT_LABEL,
        value: REPEAT_VALUE,
      };
      let weeksValue = backendCronExpression.split(" ")[4];
      let repeatPeriod = {
        label: DAILY_LABEL,
        value: DAILY_VALUE,
      };
      let repeatMonthly = "";
      let repeatMonthlyDate = [
        {
          label: new Date().getDate(),
          value: new Date().getDate(),
        },
      ];
      if (weeksValue !== STAR) {
        weeksValue = weeksValue.split(",").map((d) => WEEKS[d]);
        repeatPeriod = {
          label: WEEKLY_LABEL,
          value: WEEKLY_VALUE,
        };
      }
      let monthsValue = backendCronExpression.split(" ")[2];
      if (monthsValue !== STAR) {
        repeatPeriod = {
          label: MONTHLY_LABEL,
          value: MONTHLY_VALUE,
        };
        if (monthsValue === LAST_DAY_OF_MONTH_VALUE) {
          repeatMonthly = {
            label: LAST_DAY_OF_MONTH_LABEL,
            value: LAST_DAY_OF_MONTH_VALUE,
          };
        } else if (monthsValue === FIRST_DAY_OF_MONTH_VALUE) {
          repeatMonthly = {
            label: FIRST_DAY_OF_MONTH_LABEL,
            value: FIRST_DAY_OF_MONTH_VALUE,
          };
        } else {
          monthsValue = monthsValue.split(",").map((d) => parseInt(d));
          repeatMonthly = {
            label: DAY_LABEL,
            value: DAY_VALUE,
          };
          repeatMonthlyDate = monthsValue.map((d) => {
            return {
              label: d,
              value: d,
            };
          });
        }
      }
      let cronObj = {
        time: {
          hour: hour,
          minute: minute,
          value: `${hour}:${minute}`,
        },
        repeatFrequency: repeatFrequency,
        repeatPeriod: repeatPeriod,
        weeksValue: weeksValue,
        repeatMonthly: repeatMonthly,
        repeatMonthlyDate: repeatMonthlyDate,
        startDate:
          journeyDetailsData?.journeyDetailsResponse?.journeyData
            ?.journeyStartDate,
        endDate:
          journeyDetailsData?.journeyDetailsResponse?.journeyData
            ?.journeyEndDate,
      };
      if (cronObj.startDate === cronObj.endDate) {
        cronObj.repeatFrequency = {
          label: DOES_NOT_REPEAT_LABEL,
          value: DOES_NOT_REPEAT_VALUE,
        };
        cronObj.repeatPeriod = "";
        cronObj.weeksValue = "";
        cronObj.repeatMonthly = "";
        cronObj.repeatMonthlyDate = [
          {
            label: new Date().getDate(),
            value: new Date().getDate(),
          },
        ];
      }
      setGenericCronObj((prev) => {
        return {
          ...prev,
          ...cronObj,
        };
      });
    }
  }, [journeyDetailsData?.journeyDetailsResponse]);

  useEffect(() => {
    if (
      journeyDetailsData?.journeyDetailsResponse?.journeyData?.journeyStatus ===
      OFFER_DETAILS_STATUS_DRAFT
    ) {
      let isOfferEdited = false;
      let editedCampaignJson = {
        description: campaignDetailsData?.description,
        ...(journeyType === "Segment Campaign" && {
          cronExpression: campaignDetailsData?.cronExpression,
        }),
        startDate: changeDateDashFormat(campaignDetailsData?.startDate),
        endDate: changeDateDashFormat(campaignDetailsData?.endDate),
        nodes: campaignBuilderData?.journeyFlow?.nodes.map((node) => {
          let nodeObj = {};
          nodeObj.data = {
            // displayData: node.data.displayData,
            type: node.data.type,
          };
          nodeObj.id = node.id;
          if (node.data.value) {
            nodeObj.data.value = node.data.value;
          }
          return {
            ...nodeObj,
          };
        }),
        edges:
          campaignBuilderData?.journeyFlow?.edges.map((edge) => {
            return {
              id: edge.id,
              source: edge.source,
              target: edge.target,
            };
          }) || [],
      };
      // Now ignore all bewlow keys
      console.log(editedCampaignJson, BACKEND_CAMPAIGN_DATA);
      if (
        journeyDetailsData?.journeyDetailsResponse?.journeyData?.journeyType ===
        "CMP-EVENT"
      ) {
        delete editedCampaignJson.cronExpression;
        delete BACKEND_CAMPAIGN_DATA.cronExpression;
      }
      if (isEqual(editedCampaignJson, BACKEND_CAMPAIGN_DATA)) {
        // dispatch(setOFferChangeStatus(false));

        isOfferEdited = false;
      } else {
        isOfferEdited = true;
        // dispatch(setOFferChangeStatus(true));
      }
      if (
        journeyDetailsData?.isOfferChanged !== isOfferEdited &&
        checkRightsIsEditable(
          getRightsKey([
            "campaign",
            "detail",
            "setup",
            journeyType.split(" ").join(""),
          ])
        )
      ) {
        dispatch(setCampaignOfferChangeStatus(isOfferEdited));
      }
    }
  }, [campaignBuilderData, campaignDetailsData]);

  const { showBoundary } = useErrorBoundary();

  //theme
  const toasterData = useSelector((state) => state.toasterData);
  const [theme, setTheme] = useState(toasterData.isDarkTheme);
  useEffect(() => {
    setTheme(toasterData.isDarkTheme);
  }, [toasterData]);
  //theme

  return (
    <ErrorBoundary FallbackComponent={Error} onError={(error) => {}}>
      <Loading source={CAMPAIGN_LOADER_SOURCE}>
        {journeyType &&
        checkRightsIsVisible(
          getRightsKey([
            "campaign",
            "detail",
            "setup",
            journeyType.split(" ").join(""),
          ])
        ) ? (
          <div className="campaign-page-wrapper">
            <CampaignHeader
              theme={theme}
              campaignDetailsData={campaignDetailsData}
              campaignBuilderData={campaignBuilderData}
              isDisabled={offerSetupDisabled}
              triggerEventName={triggerEventName}
            />
            <section className="display-flex display-wrapper">
              <div className="inner-section-wrapper" style={{ height: "100%" }}>
                {journeyDetailsData?.journeyDetailsResponse?.journeyData
                  ?.journeyType === "CMP-BATCH" && (
                  <div style={{ marginLeft: 10 }}>
                    <GenericCron
                      isDisabled={offerSetupDisabled}
                      theme={theme}
                      campaignDetailsData={campaignDetailsData}
                      setCampaignDetailsData={setCampaignDetailsData}
                      GenericCronObj={GenericCronObj}
                      setGenericCronObj={setGenericCronObj}
                    />
                  </div>
                )}
                <ReactFlowProvider>
                  <CampaignBuilder
                    theme={theme}
                    campaignBuilderData={campaignBuilderData}
                    setCampaignBuilderData={setCampaignBuilderData}
                    isDisabled={offerSetupDisabled}
                    setShowOfferPopup={setShowOfferPopup}
                    triggerEventName={triggerEventName}
                    setTriggerEventName={setTriggerEventName}
                  />
                </ReactFlowProvider>
                {/* <ABTestingWrapper/> */}
              </div>
              <div
                className="sticky-right campaign-detail-desktop"
                style={{ height: "max-content" }}
              >
                <CampaignDetails
                  theme={theme}
                  campaignDetailsData={campaignDetailsData}
                  setCampaignDetailsData={setCampaignDetailsData}
                  isDisabled={offerSetupDisabled}
                />
              </div>
            </section>
          </div>
        ) : (
          <UnauthorizedAccess />
        )}
      </Loading>

      {/* <CampaignActionComponent /> */}
    </ErrorBoundary>
  );
};
CampaignWrapper.displayName = "CampaignWrapper";
export default CampaignWrapper;
