import Rules from "../rulesComponent/Rules.jsx";
import "./OfferWrapper.style.scss";
import { useEffect, useState } from "react";
import RewardSection from "../rewardComponent/Reward";
import RedeemSection from "../redeemComponent/Redeem";
import EventRuleAction from "../eventProcessComponent/EventRuleAction";
import OfferDetailsHeader from "../offerDetailsHeaderComponent/OfferDetailsHeader";
import OfferDetails from "../offerDetailsComponent/OfferDetails.jsx";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../../LoaderComponents/Loading";
import { OFFER_LOADER_SOURCE } from "../../LoaderComponents/LoadingConstant.js";
import { ErrorBoundary } from "react-error-boundary";
import Error from "../../../tellurideExtComponents/errorsComponent/Error";
import { useErrorBoundary } from "react-error-boundary";
import {
	BASE_EARN,
	BASE_REDEEM,
	LOYALTY_CASH,
	NON_PURCHASE,
	OFFER_DETAILS_STATUS_DRAFT,
	OFRI,
	POINTS_EARN,
	PRICE_DISCOUNT,
	EVENTPROCESSING,
	EVENT_OFFER,
} from "../../constantComponent/Constants.js";
import {
	fetchOfferDetails,
	getEntitiesDetails,
	getProgramTypesDetails,
	resetUploadImageData,
	setOFferChangeStatus,
} from "../../../tellurideExtRedux";
import { headers } from "../../constantComponent/Constants";
import { generateInitialLinkedOfferJSON } from "../communicateComponent/CommunicateUtil.js";
import { generateInitialJSON } from "../rewardComponent/RewardUtil.jsx";
import {
	INITIAL_DEFAULT_RULES,
	ZERO,
} from "../../utilsComponent/GenericRules/GenericRulesConstants.js";
import { useNavigate } from "react-router-dom";
import { VIEW_OFFER, OFFER } from "../../constantComponent/PathConstants";
import {
	areJsonEqual,
	checkRightsIsEditable,
	checkRightsIsVisible,
	getRightsFromSession,
	getRightsKey,
	skey,
} from "../../utilsComponent/CommonUtil.js";
import DiscountSection from "../discountComponent/Discount.jsx";
import PointsValidity from "../PointsValidityComponent/PointsValidity.jsx";
import DiscountOfferCap from "../capComponent/DiscountOfferCap.jsx";
import EarnOfferCap from "../capComponent/EarnOfferCap.jsx";
import { generateInitialOfferAttr } from "../PointsValidityComponent/PointsValidityUtil.js";

import isEqual from "react-fast-compare";
import UnauthorizedAccess from "../../unauthorizeComponent/UnauthorizeWrapper.jsx";

const PageWrapper = () => {
	const dispatch = useDispatch();
	const offerDetailsPageData = useSelector((state) => state.offerDetailsData);
	const linkedData =
		offerDetailsPageData?.linkedOffers === undefined
			? JSON.parse(JSON.stringify(generateInitialLinkedOfferJSON()))
			: JSON.parse(JSON.stringify(offerDetailsPageData?.linkedOffers));
	const rewardData =
		offerDetailsPageData.rewardData === undefined
			? JSON.parse(JSON.stringify(generateInitialJSON()))
			: JSON.parse(JSON.stringify(offerDetailsPageData.rewardData));

	const offerData = offerDetailsPageData.offerData;
	const ruleData =
		Object.keys(offerDetailsPageData.ruleData).length > ZERO
			? offerDetailsPageData.ruleData?.rule[0]?.condition
			: INITIAL_DEFAULT_RULES;
	const offerAttributeData =
		offerDetailsPageData.offerAttributes === undefined ||
		offerDetailsPageData.offerAttributes === ""
			? JSON.parse(JSON.stringify(generateInitialOfferAttr()))
			: JSON.parse(JSON.stringify(offerDetailsPageData.offerAttributes));
	const updatedRewardData =
		offerDetailsPageData?.updateRewardData === undefined
			? rewardData
			: offerDetailsPageData?.updateRewardData;
	const updatedRuleData = offerDetailsPageData?.updateRuleData;
	const updatedOfferDetailData = offerDetailsPageData?.updateOfferData;

	const updateLinkedData =
		offerDetailsPageData?.updateLinkedOfferData === undefined
			? JSON.parse(JSON.stringify(generateInitialLinkedOfferJSON()))
			: JSON.parse(JSON.stringify(offerDetailsPageData?.updateLinkedOfferData));
	const updatedOfferAttributes = offerDetailsPageData.updateOfferAttributes;
	const Offer_Status = offerDetailsPageData.offerStatus;
	const queryString = require("query-string");
	const offerNumber = queryString.parse(window.location.search).offerid;
	const navigate = useNavigate();
	let rewardCapData =
		rewardData &&
		offerData.offerType !== "Message only" &&
		offerData.offerType !== EVENT_OFFER
			? rewardData?.action
				? rewardData?.action[0]
					? rewardData?.action[0]?.actionProperties?.actionProperty?.filter(
							({ propertyType }) => propertyType === "CAP"
					  )[0]
					: ""
				: ""
			: "";
	let capData = rewardCapData ? rewardCapData : "";
	let updatedCapData = offerDetailsPageData?.updateCapData
		? offerDetailsPageData.updateCapData
		: "";
	const offerType = offerData.offerType;
	const [fetchDetailsFirst, setFetchDetailsFirst] = useState(null);
	useEffect(() => {
		// checking if rules are changed
		if (Offer_Status === OFFER_DETAILS_STATUS_DRAFT) {
			let isOfferEdited = false;
			let isLinkedDataEdited = false;
			if (
				offerDetailsPageData.linkedOffers ||
				(updateLinkedData &&
					updateLinkedData[0].offerData.linkedOfferStatus === "Y")
			) {
				if (!isEqual(linkedData, updateLinkedData)) {
					isLinkedDataEdited = true;
				} else {
					isLinkedDataEdited = false;
				}
			} else {
				isLinkedDataEdited = false;
			}
			if (
				!isEqual(ruleData, updatedRuleData) ||
				!isEqual(offerData, updatedOfferDetailData) ||
				!isEqual(offerDetailsPageData.rewardData, updatedRewardData) ||
				//special case for cap data
				!areJsonEqual(capData, updatedCapData) ||
				isLinkedDataEdited ||
				(offerType === PRICE_DISCOUNT ||
				offerType === LOYALTY_CASH ||
				offerType === BASE_REDEEM
					? false
					: !isEqual(offerAttributeData, updatedOfferAttributes))
			) {
				isOfferEdited = true;
			} else {
				isOfferEdited = false;
			}
			if (offerType === EVENT_OFFER) {
				let rules = offerDetailsPageData.offerDetailsResponse?.rules?.rule
					? offerDetailsPageData?.offerDetailsResponse?.rules?.rule[0]
							?.conditions?.condition[0]?.values?.value
					: undefined;
				let backendActionJson = rewardData?.actionJson || {};
				let frontendActionJson = {};
				// console.log("====================================");
				// console.log(offerDetailsPageData?.uiActionJson);
				// console.log("====================================");
				try {
					frontendActionJson = JSON.parse(
						offerDetailsPageData?.uiActionJson?.action
							? offerDetailsPageData?.uiActionJson?.action[0]?.actionJson
							: "{}"
					);
				} catch (error) {
					frontendActionJson = {};
				}
				// console.log("====================================");
				// console.log(
				// 	"backendActionJson",
				// 	backendActionJson,
				// 	"frontendActionJson",
				// 	frontendActionJson,
				// 	isEqual(backendActionJson, frontendActionJson)
				// );
				// console.log("====================================");
				if (
					!isEqual(offerData, updatedOfferDetailData) ||
					!isEqual(rules ? rules[0] : {}, updatedRuleData) ||
					!isEqual(backendActionJson, frontendActionJson)
				) {
					isOfferEdited = true;
				} else {
					isOfferEdited = false;
				}
			}
			if (
				offerDetailsPageData.isOfferChanged !== isOfferEdited &&
				checkRightsIsEditable(
					getRightsKey([
						"loyalty",
						"detail",
						"setup",
						offerDetailsPageData?.offerData?.offerType.split(" ").join(""),
					])
				)
			) {
				dispatch(setOFferChangeStatus(isOfferEdited));
			}
		}
	}, [
		Offer_Status,
		dispatch,
		linkedData,
		offerData,
		offerDetailsPageData,
		rewardData,
		ruleData,
		updateLinkedData,
		updatedOfferDetailData,
		updatedRewardData,
		updatedRuleData,
		updatedOfferAttributes,
		capData,
		updatedCapData,
	]);

	const { showBoundary } = useErrorBoundary();
	useEffect(() => {
		if (fetchDetailsFirst === null) {
			headers.sessionId = skey();
			headers.requestAccess = getRightsFromSession();
			headers.organization = offerDetailsPageData?.authOrganizationName;
			const requestHeaders = {
				headers,
			};
			const offerRequestBody = {
				requestorID: OFRI,
				messageVersion: process.env.REACT_APP_MESSAGE_VERSION_03,
				offerNumber: offerNumber ? offerNumber : "",
			};
			dispatch(
				fetchOfferDetails(requestHeaders, offerRequestBody, {
					navigate: navigate,
					successRedirect: "",
					errorRedirectionPath: OFFER + VIEW_OFFER,
				})
			);

			setFetchDetailsFirst(true);
		}
		return () => {
			dispatch(resetUploadImageData());
		};
	}, []);
	useEffect(() => {
		if (offerType) {
			headers.sessionId = skey();
			headers.requestAccess = getRightsFromSession();
			headers.organization = offerDetailsPageData?.authOrganizationName;
			const requestHeaders = {
				headers,
			};
			const entitiesRequestBody = {
				requestorID: OFRI,
				messageVersion: process.env.REACT_APP_MESSAGE_VERSION_01,
				offerType: offerType,
			};
			// ProgramType change
			// const programTypesRequestBody = {
			// 	requestorID: OFRI,
			// 	messageVersion: process.env.REACT_APP_MESSAGE_VERSION_01,
			// };
			dispatch(
				getEntitiesDetails(showBoundary, entitiesRequestBody, requestHeaders)
			);
			// ProgramType change
			// dispatch(
			// 	getProgramTypesDetails(programTypesRequestBody, requestHeaders)
			// );
		}
	}, [offerType]);

	//theme
	const toasterData = useSelector((state) => state.toasterData);
	const [theme, setTheme] = useState(toasterData.isDarkTheme);

	const [offerSetupDisabled, setOfferSetupDisabled] = useState();
	// !checkRightsIsEditable(
	// 	getRightsKey(["button", "loyalty", "createoffer", "save"])
	// ) ||
	useEffect(() => {
		if (offerType) {
			const isEditable = checkRightsIsEditable(
				getRightsKey([
					"loyalty",
					"detail",
					"setup",
					offerType.split(" ").join(""),
				])
			);

			if (Offer_Status === OFFER_DETAILS_STATUS_DRAFT && isEditable) {
				setOfferSetupDisabled(false);
			} else {
				setOfferSetupDisabled(true);
			}
		}
	}, [offerType, Offer_Status]);
	useEffect(() => {
		setTheme(toasterData.isDarkTheme);
	}, [toasterData]);
	//theme
	return (
		<ErrorBoundary FallbackComponent={Error} onError={(error) => {}}>
			<Loading source={OFFER_LOADER_SOURCE}>
				{offerType &&
				checkRightsIsVisible(
					getRightsKey([
						"loyalty",
						"detail",
						"setup",
						offerType.split(" ").join("").toLowerCase(),
					])
				) ? (
					<div className="page-wrapper">
						{[
							POINTS_EARN,
							LOYALTY_CASH,
							NON_PURCHASE,
							BASE_EARN,
							BASE_REDEEM,
							PRICE_DISCOUNT,
							EVENT_OFFER,
						].includes(offerType) ? (
							<OfferDetailsHeader />
						) : null}
						<section className="display-flex display-wrapper">
							<div className="inner-section-wrapper" style={{ height: "100%" }}>
								{[PRICE_DISCOUNT].includes(offerType) ? (
									<DiscountSection
										theme={theme}
										disabled={offerSetupDisabled}
									/>
								) : null}

								{[POINTS_EARN, BASE_EARN, NON_PURCHASE].includes(offerType) ? (
									<RewardSection theme={theme} disabled={offerSetupDisabled} />
								) : null}

								{[LOYALTY_CASH, BASE_REDEEM].includes(offerType) ? (
									<RedeemSection theme={theme} disabled={offerSetupDisabled} />
								) : null}

								{[EVENT_OFFER].includes(offerType) ? (
									<EventRuleAction
										theme={theme}
										disabled={offerSetupDisabled}
									/>
								) : null}
								{[
									POINTS_EARN,
									LOYALTY_CASH,
									NON_PURCHASE,
									BASE_EARN,
									BASE_REDEEM,
									PRICE_DISCOUNT,
								].includes(offerType) ? (
									<Rules theme={theme} disabled={offerSetupDisabled} />
								) : null}

								{[BASE_EARN, POINTS_EARN, NON_PURCHASE].includes(offerType) ? (
									<PointsValidity theme={theme} disabled={offerSetupDisabled} />
								) : null}

								{[PRICE_DISCOUNT].includes(offerType) ? (
									<DiscountOfferCap
										theme={theme}
										disabled={offerSetupDisabled}
									/>
								) : null}

								{[POINTS_EARN, LOYALTY_CASH, NON_PURCHASE].includes(
									offerType
								) ? (
									<EarnOfferCap theme={theme} disabled={offerSetupDisabled} />
								) : null}
							</div>
							<div className="sticky-right offer-detail-desktop">
								{[
									POINTS_EARN,
									LOYALTY_CASH,
									NON_PURCHASE,
									BASE_EARN,
									BASE_REDEEM,
									PRICE_DISCOUNT,
									EVENT_OFFER,
								].includes(offerType) ? (
									<OfferDetails disabled={offerSetupDisabled} />
								) : null}
							</div>
						</section>
					</div>
				) : (
					<UnauthorizedAccess />
				)}
			</Loading>
		</ErrorBoundary>
	);
};
export default PageWrapper;
