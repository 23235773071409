import {
	LEAVING_OFFER_CREATION_PAGE_MESSAGE,
	LEAVING_OFFER_CREATION_PAGE_MESSAGE_CONTINUE,
} from "../../constantComponent/MessageConstant";
import { IoClose } from "react-icons/io5";
import "../Backdrop.style.scss";
import "./LeavingSetupPageConfirmationComponent.style.scss";
import { hideSetupPageConfirmationPopup } from "../../navigationBarComponent/internalNavigationBarComponent/NavigationBarUtil";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setLeavingSetupPageState } from "../../../tellurideExtRedux/leavingSetupCreation/LeavingSetupCreationAction";

const LeavingSetupPageConfirmationComponent = ({
	title = LEAVING_OFFER_CREATION_PAGE_MESSAGE,
	message = LEAVING_OFFER_CREATION_PAGE_MESSAGE_CONTINUE,
}) => {
	const dispatch = useDispatch();

	return (
		<>
			<div className="createOffer-confirmation-model">
				<div
					id="createOffer-confirmation-model"
					className="backdrop fixed-position-backdrop"
				>
					<div className="modal">
						<section className="modal-body">
							<div className="flex center body">
								<div className="flex center column">
									<b className="mb-12 fs-16">{title}</b>
									<p>{message}</p>
								</div>
							</div>
						</section>
						<div className="modal-footer">
							<div className="alignment">
								<button
									className="save-button btn btn-secondary"
									onClick={hideSetupPageConfirmationPopup}
								>
									No
								</button>
								<button
									className="save-button btn btn-primary"
									onClick={() => {
										dispatch(setLeavingSetupPageState(true));
									}}
								>
									Yes
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default LeavingSetupPageConfirmationComponent;
