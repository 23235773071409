import React, { useState } from "react";
import "./SubclubDetails.style.scss";
import { useDispatch, useSelector } from "react-redux";
import { STRING } from "../../constantComponent/Constants";
import { images } from "../../imagesComponent/Images";
import { SUBCLUBS_DETAILS_HEADER } from "../SubclubConstants";
function SubclubDetails({
	isDisabled,
	subclubDetails,
	setSubclubDetails,
	setValidation,
	validation,
}) {
	return (
		<>
			<div className="attribute-details-title setup-page-section-title">
				{SUBCLUBS_DETAILS_HEADER}
			</div>
			<div className="attribute-details-section">
				<div className="attribute-details-section-inner">
					<div
						className="attribute-details-section-fieldset"
						style={{ alignItems: "flex-start", flexWrap: "wrap" }}
					>
						<fieldset
							className="attribute-details-fieldset"
							disabled={isDisabled === true ? true : false}
							style={{ minWidth: 200 }}
						>
							<legend className="required">Subclub Code</legend>
							<input
								type="text"
								value={subclubDetails.code}
								name="code"
								onChange={(e) => {
									setSubclubDetails((prev) => {
										return { ...prev, [e.target.name]: e.target.value };
									});
								}}
								autocomplete="off"
								autocorrect="off"
								autocapitalize="off"
								spellcheck="false"
								defaultValue={"Code"}
								maxLength="8"
							/>
							<small
								className="error-message"
								style={{
									fontSize: 12,
								}}
							>
								{validation?.code?.status === true &&
									validation?.code?.touched === true &&
									validation?.code?.message}
							</small>
						</fieldset>
						<fieldset
							className="attribute-details-fieldset"
							disabled={isDisabled === true ? true : false}
							style={{ minWidth: 200 }}
						>
							<legend className="required">Subclub Name</legend>
							<input
								type="text"
								value={subclubDetails.name}
								name="name"
								onChange={(e) => {
									setSubclubDetails((prev) => {
										return { ...prev, [e.target.name]: e.target.value };
									});
								}}
								autocomplete="off"
								autocorrect="off"
								autocapitalize="off"
								spellcheck="false"
								defaultValue={"Name"}
								maxLength="100"
							/>
							<small
								className="error-message"
								style={{
									fontSize: 12,
								}}
							>
								{validation?.name?.status === true &&
									validation?.name?.touched === true &&
									validation?.name?.message}
							</small>
						</fieldset>

						<div className="attr-description-wrapper">
							<fieldset
								className="text-box description-fieldset"
								disabled={isDisabled === true ? true : false}
								style={{ flex: 1 }}
							>
								<legend className="required">Subclub Description</legend>
								<textarea
									className="input-text-textarea"
									rows="2"
									cols="50"
									maxLength="200"
									name="description"
									onChange={(e) => {
										setSubclubDetails((prev) => {
											return { ...prev, [e.target.name]: e.target.value };
										});
									}}
									disabled={isDisabled === true ? true : false}
									value={subclubDetails.description}
								/>
							</fieldset>
							<small
								className="error-message attr-description"
								style={{
									fontSize: 12,
								}}
							>
								{validation?.description?.status === true &&
									validation?.description?.touched === true &&
									validation?.description?.message}
							</small>
						</div>
					</div>
					<div className="row row-2">
						<div className="subclub-info">
							<img className="info-img" src={images.infoImage}></img>
							<span>
								Setup subclubs that will be used to reward loyalty members with
								specific perks.
							</span>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default SubclubDetails;
