import {
  DROPDOWN_CHANGE_MESSAGE,
  LEAVING_DROPDOWN_CHANGE_MESSAGE,
} from "../../constantComponent/MessageConstant";
import { IoClose } from "react-icons/io5";
import "../Backdrop.style.scss";
import "./ChangeCampaignActionEmailDropDownValue.style.scss";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { motion } from "framer-motion/dist/framer-motion";

const ChangeCampaignActionEmailDropDownValue = ({
  setShowPopup,
  setIsChangeDropDownValue,
  title = DROPDOWN_CHANGE_MESSAGE,
  message = LEAVING_DROPDOWN_CHANGE_MESSAGE,
}) => {
  return (
    <>
      <section className="email-confirmation-popup-backdrop">
        <motion.div
          className="inner-modal"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <div className="createOffer-confirmation-model">
            <div className="modal">
              <section className="modal-body">
                <div className="flex center body">
                  <div className="flex center column">
                    <b className="mb-12 fs-16">{title}</b>
                    <p>{message}</p>
                  </div>
                </div>
              </section>
              <div className="modal-footer">
                <div className="alignment">
                  <button
                    className="save-button btn btn-secondary"
                    onClick={() => {
                      setIsChangeDropDownValue(false);
                      setShowPopup({
                        content: "dropdownConfirmationPopup",
                        isVisible: false,
                      });
                    }}
                  >
                    No
                  </button>
                  <button
                    className="save-button btn btn-primary"
                    onClick={() => {
                      setIsChangeDropDownValue(true);
                      setShowPopup({
                        content: "dropdownConfirmationPopup",
                        isVisible: false,
                      });
                    }}
                  >
                    Yes
                  </button>
                </div>
              </div>
            </div>
          </div>
        </motion.div>
      </section>
    </>
  );
};

export default ChangeCampaignActionEmailDropDownValue;
