import Select from "react-select";
import {
	ATTR,
	ATTR_DEFAULT_VALUE,
	ENTITY,
	multi_operators,
	OPERATOR,
	OPERATOR_DEFAULT_VALUE,
	VALUE,
	VALUE_DEFAULT_VALUE,
} from "../GenericRules/GenericRulesConstants";
import "./ReactSelect.styles.scss";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { DARK, DROPDOWN_BGCOLOR_DARK_THEME, DROPDOWN_FOCUSED_BGCOLOR_DARK_THEME, DROPDOWN_FOCUSED_BGCOLOR_LIGHT_THEME, DROPDOWN_SELECTED_BGCOLOR_DARK_THEME, DROPDOWN_SELECTED_BGCOLOR_LIGHT_THEME, LIGHT } from "../../constantComponent/Constants";
import { resetFilters } from "../GenericRules/GenericRulesUtil";

const ReactSelect = ({
	options = [],
	name,
	filter,
	setExpression,
	handleFilterChange,
	setAttributeModal,
	setEditProcess,
	filterType,
}) => {
	const toasterData = useSelector((state) => state.toasterData);
	const [theme, setTheme] = useState(toasterData.isDarkTheme);
	useEffect(() => {
		setTheme(toasterData.isDarkTheme);
	}, [toasterData]);
	if (options.length > 0) {
		if (filterType && filterType === VALUE) {
			options = options.map((optObj) => ({
				label: optObj.name,
				value: optObj.name,
			}));
		} else {
			options = options.map((label) => ({
				label,
				value: label,
			}));
		}
	}

	function handleChange({ value }, { name }) {
		let e = { target: { name: name, value: value } };
		if (name.includes(ENTITY)) {
			handleFilterChange(e, filter.id, setExpression, setEditProcess);
			// reset all attribute/operator,input values
			resetFilters(setExpression, filter, [
				{ filterType: ATTR, filterResetValue: ATTR_DEFAULT_VALUE },
				{ filterType: OPERATOR, filterResetValue: OPERATOR_DEFAULT_VALUE },
				{ filterType: VALUE, filterResetValue: VALUE_DEFAULT_VALUE },
			]);
			setAttributeModal((prev) => {
				return {
					...prev,
					status: true,
					id: filter.id,
					entityValue: value,
					selectedAttr: filter?.attr,
				};
			});
		} else if (name.includes(OPERATOR)) {
			// currentValue=value;
			// previousValue=filter.operator
			if (
				!multi_operators.includes(filter.operator) ||
				!multi_operators.includes(value)
			) {
				resetFilters(setExpression, filter, [
					{ filterType: VALUE, filterResetValue: VALUE_DEFAULT_VALUE },
				]);
			}
			handleFilterChange(e, filter.id, setExpression, setEditProcess);
		} else {
			handleFilterChange(e, filter.id, setExpression, setEditProcess);
		}
		setEditProcess({
			status: false,
			id: null,
			filterType: "",
		});
	}

	const customStyles = {
		option: (provided, state) => ({
			...provided,
			backgroundColor:
				state.isSelected && theme === LIGHT
					? DROPDOWN_SELECTED_BGCOLOR_LIGHT_THEME
					: state.isSelected && theme === DARK
					? DROPDOWN_SELECTED_BGCOLOR_DARK_THEME
					: // ? DROPDOWN_FOCUSED_BGCOLOR_LIGHT_THEME
					state.isFocused && theme === DARK
					? DROPDOWN_FOCUSED_BGCOLOR_DARK_THEME
					: state.isFocused && theme === LIGHT
					? DROPDOWN_FOCUSED_BGCOLOR_LIGHT_THEME
					: theme === DARK
					? DROPDOWN_BGCOLOR_DARK_THEME
					: "#fff",
			color: state.isSelected
				? "#fff"
				: state.isFocused && theme === DARK
				? "#fff"
				: state.isFocused && theme === LIGHT
				? DROPDOWN_SELECTED_BGCOLOR_LIGHT_THEME
				: theme === LIGHT
				? DROPDOWN_SELECTED_BGCOLOR_LIGHT_THEME
				: "#fff",
			padding: 8,
			fontSize: 14,
		}),
		control: (baseStyles, state) => ({
			...baseStyles,
			position: "relative",
			top: -5,
			width: "max-content",
			backgroundColor: theme === DARK ? DROPDOWN_SELECTED_BGCOLOR_DARK_THEME : "none",
			fontSize: 14,
			padding: 0,
		}),
	};

	return (
		<>
			<Select
				options={options}
				styles={customStyles}
				onChange={handleChange}
				name={name}
				value={{ label: filter[filterType], value: filter[filterType] }}
				defaultMenuIsOpen
				autoFocus
				isSearchable={false}
				onBlur={() => {
					setEditProcess({
						status: false,
						id: null,
						filterType: "",
					});
				}}
				className="react-select-dropdown"
				menuPlacement="auto"
				maxMenuHeight={150}
			/>
		</>
	);
};

export default ReactSelect;
