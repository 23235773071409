import "./SubclubWrapper.style.scss";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../../LoaderComponents/Loading.jsx";
import { ErrorBoundary } from "react-error-boundary";
import Error from "../../errorsComponent/Error.jsx";
import { LIST, REDUX, SUCCESS } from "../../constantComponent/Constants.js";
import SubclubDetails from "../subclubDetailsComponent/SubclubDetails.jsx";
import { SUBCLCUB_LOADER_SOURCE } from "../../LoaderComponents/LoadingConstant.js";
import GenericTable from "../../utilsComponent/TableUtil/GenericTable.jsx";

import {
	COLUMNS,
	FILTER,
	SUBCLUB_TABLE_CODE,
	SUBCLUBS,
	SUBCLUBS_TABLE_HEADER,
	VIEW_SUBCLUB_PLACEHOLDER,
} from "../SubclubConstants.js";
import SubclubHeader from "../subclubHeaderComponent/SubclubHeader.jsx";
import { resetCreateSubclubResponse } from "../../../tellurideExtRedux/subclub/SubclubAction.js";
import {
	checkRightsIsEditable,
	getAllRights,
	getRightsKey,
} from "../../utilsComponent/CommonUtil.js";

function SubclubWrapper({ setRedirectionHeader }) {
	const subclubData = useSelector((state) => state.subclubData);
	const dispatch = useDispatch();
	const [isDisabled, setIsDisabled] = useState(
		checkRightsIsEditable(
			getRightsKey(["button", "configure", "subclub", "save"])
		)
	);

	const toasterData = useSelector((state) => state.toasterData);
	const [theme, setTheme] = useState(toasterData.isDarkTheme);
	const [subclubDetails, setSubclubDetails] = useState({
		code: "",
		name: "",
		description: "",
	});
	const [subclubLayout, setSubclubLayout] = useState({
		detailsSection: false,
	});

	const [btnConfig, setBtnConfig] = useState({
		back: {
			visible: false,
			disabled: false,
		},
		save: {
			visible: false,
			disabled: false,
		},
		create: {
			visible: true,
			disabled: false,
		},
	});
	const [refresh, setRefresh] = useState(false);
	const [validation, setValidation] = useState({
		code: {
			status: false,
			message: "",
			touched: false,
		},
		name: {
			status: false,
			message: "",
			touched: false,
		},
		description: {
			status: false,
			message: "",
			touched: false,
		},
	});
	useEffect(() => {
		setTheme(toasterData.isDarkTheme);
	}, [toasterData]);

	useEffect(() => {
		if (subclubDetails.code.trim().length < 1) {
			setValidation((prev) => {
				return {
					...prev,
					code: {
						...prev.code,
						status: true,
						message: "Subclub Code is required",
					},
				};
			});
		} else if (subclubDetails.code.trim().length > 8) {
			setValidation((prev) => {
				return {
					...prev,
					code: {
						...prev.code,
						status: true,
						message: "Subclub Code should be less than 8 characters",
					},
				};
			});
		} else {
			setValidation((prev) => {
				return {
					...prev,
					code: {
						...prev.code,
						status: false,
						message: "",
					},
				};
			});
		}
		if (subclubDetails.name.trim().length < 1) {
			setValidation((prev) => {
				return {
					...prev,
					name: {
						...prev.name,
						status: true,
						message: "Subclub Name is required",
					},
				};
			});
		} else if (subclubDetails.name.trim().length > 100) {
			setValidation((prev) => {
				return {
					...prev,
					name: {
						...prev.name,
						status: true,
						message: "Subclub Name should be less than 100 characters",
					},
				};
			});
		} else {
			setValidation((prev) => {
				return {
					...prev,
					name: {
						...prev.name,
						status: false,
						message: "",
					},
				};
			});
		}
		if (subclubDetails.description.trim().length < 1) {
			setValidation((prev) => {
				return {
					...prev,
					description: {
						...prev.description,
						status: true,
						message: "Subclub Description is required",
					},
				};
			});
		} else if (subclubDetails.description.trim().length > 200) {
			setValidation((prev) => {
				return {
					...prev,
					description: {
						...prev.description,
						status: true,
						message: "Subclub Description should be less than 200 characters",
					},
				};
			});
		} else {
			setValidation((prev) => {
				return {
					...prev,
					description: {
						...prev.description,
						status: false,
						message: "",
					},
				};
			});
		}
	}, [subclubDetails]);

	useEffect(() => {
		if (
			subclubData.createSubclubLoading === false &&
			subclubData.createSubclubResponse &&
			subclubData.createSubclubResponse?.status === SUCCESS
		) {
			setBtnConfig({
				back: {
					visible: true,
					disabled: false,
				},
				save: {
					visible: false,
					disabled: false,
				},
				create: {
					visible: true,
					disabled: false,
				},
			});
			setIsDisabled(true);
			setRefresh((prev) => !prev);
			setValidation({
				code: {
					status: false,
					message: "",
					touched: false,
				},
				name: {
					status: false,
					message: "",
					touched: false,
				},
				description: {
					status: false,
					message: "",
					touched: false,
				},
			});
			dispatch(resetCreateSubclubResponse());
		}
		return () => {
			dispatch(resetCreateSubclubResponse());
		};
	}, [subclubData.createSubclubResponse, subclubData.createSubclubLoading]);
	useEffect(() => {
		setRedirectionHeader("Configure Subclub");
	}, []);

	function generateCustomRequestBody(tableReqBody) {
		let requestBody = {
			...tableReqBody,
			code: SUBCLUB_TABLE_CODE,
		};
		return requestBody;
	}

	return (
		<ErrorBoundary FallbackComponent={Error} onError={(error) => {}}>
			<Loading source={SUBCLCUB_LOADER_SOURCE}>
				<div className="page-wrapper attribute-wrapper">
					<SubclubHeader
						isDisabled={isDisabled}
						setIsDisabled={setIsDisabled}
						theme={theme}
						subclubDetails={subclubDetails}
						setSubclubLayout={setSubclubLayout}
						btnConfig={btnConfig}
						setBtnConfig={setBtnConfig}
						setSubclubDetails={setSubclubDetails}
						setRefresh={setRefresh}
						setValidation={setValidation}
						validation={validation}
						subclubLayout={subclubLayout}
					/>
					<section className="display-flex attribute-non-flex">
						<div className="inner-section-wrapper">
							{subclubLayout.detailsSection ? (
								<SubclubDetails
									isDisabled={isDisabled}
									theme={theme}
									subclubDetails={subclubDetails}
									setSubclubDetails={setSubclubDetails}
									setValidation={setValidation}
									validation={validation}
								/>
							) : null}
							<div className="subclub-table">
								{subclubLayout.detailsSection ? (
									<div className="attribute-details-title setup-page-section-title">
										{SUBCLUBS_TABLE_HEADER}
									</div>
								) : null}
								<GenericTable
									COLUMNS={COLUMNS}
									FILTER_SHOW_LIMIT={1}
									SHOW_HEADER={true}
									ADVANCE_SEARCH_PLACEHOLDER={VIEW_SUBCLUB_PLACEHOLDER}
									API_URL={process.env.REACT_APP_GET_SUBCLUB_LIST}
									TABLE_SRC={SUBCLUBS}
									SHOW_HEADER_DASHBOARD={false}
									SHOW_TOOLTIP={false}
									ROW_CLICKABLE={false}
									ADVANCE_SEARCH_HEADER={{
										searchBar: true,
									}}
									REQUEST_BODY_KEY={FILTER}
									RESPONSE_BODY_KEY={LIST}
									IS_SEARCH_ALLOWED={true}
									refresh={refresh}
									CSTM_REQ_BODY={generateCustomRequestBody}
									SAVE_SEARCHED_FILTER={{
										isSaved: true, //  true || false => default : true
										storage: REDUX, // redux  || local || session  => default : "redux"
									}}
								/>
							</div>
						</div>
					</section>
				</div>
			</Loading>
		</ErrorBoundary>
	);
}
export default SubclubWrapper;
