import React, { useEffect, useState } from "react";
import "./Reward.style.scss";
import {
	generateInitialJSON,
	getCustomStyles,
	getInputOfferFor,
	getInputOfferRange,
} from "./RewardUtil";
import { useDispatch, useSelector } from "react-redux";
import {
	OFFER_FOR,
	OFFER_RANGE,
	TIER,
	offerRangeOptions,
	offerForOptions,
	VALUE,
	MIN,
	infoOfferFor,
	SPEND,
	TRIP,
	ITEM,
	FLAT,
	QUANTITY,
	REWARD_TITLE,
	OFFER_FOR_TITLE,
	OFFER_RANGE_TITLE,
	offerRangeAlias,
	offerForAlias,
	MULTIPLIER,
	POINTS_TITLE,
	offerForOptionsMission,
	OFFER_THRESHOLD,
	offerForOptionsPointsEarn,
} from "./RewardConstant";
import {
	setErrorrsForValidations,
	updateRewardData,
} from "../../../tellurideExtRedux/offerDetails/OfferDetailsAction";
import Select from "react-select";
import {
	BASE_EARN,
	MISSION,
	NON_PURCHASE,
	OFFER_DETAILS_STATUS_DRAFT,
	POINTS_EARN,
} from "../../constantComponent/Constants";
import { AiOutlineInfoCircle } from "react-icons/ai";
const RewardSection = ({ theme, disabled }) => {
	const dispatch = useDispatch();
	const offerData = useSelector((state) => state.offerDetailsData);
	const offerType =
		offerData.offerData.offerType === POINTS_EARN &&
		offerData.offerAttributes.offerAttribute.some(
			(attribute) => attribute.name === "DISPLAY_OFFER_TYPE"
		)
			? offerData.offerAttributes.offerAttribute.find(
					(attribute) => attribute.name === "DISPLAY_OFFER_TYPE"
			  ).values.value[0]
			: offerData.offerData.offerType;

	const initialRewardData = useSelector(
		(state) => state.offerDetailsData.rewardData
	);
	const [awardDetailData, setAwardDetaildata] = useState(
		initialRewardData === undefined
			? JSON.parse(JSON.stringify(generateInitialJSON()))
			: JSON.parse(JSON.stringify(initialRewardData))
	);
	const [offerFor, setOfferFor] = useState("");
	const [offerRange, setOfferRange] = useState("");
	const [offerForValue, setOfferForValue] = useState("");
	const [offerThreshold, setOfferThreshold] = useState("");
	const [offerRangeValue, setOfferRangeValue] = useState("");
	const [actProperty, setActProperty] = useState([]);
	const [isSetDisable, setDisable] = useState(disabled);
	const [showInfo, setShowInfo] = useState(false);
	// useEffect(() => {
	//   setDisable(
	//     offerData?.offerStatus !== OFFER_DETAILS_STATUS_DRAFT ? true : false || disabled
	//   );
	// }, [offerData?.offerStatus,disabled]);
	useEffect(() => {
		offerData.offerValidationErrors.createOffer.discount = false;
		dispatch(setErrorrsForValidations(offerData.offerValidationErrors));
		awardDetailData &&
			awardDetailData?.action[0]?.actionProperties?.actionProperty?.map(
				(element) => {
					if (!element?.propertyType) {
						element?.property?.map((ele) => {
							if (ele.name === OFFER_FOR) {
								ele.values.value[0]
									? ele.values.value[0] === ITEM
										? setOfferFor(QUANTITY)
										: setOfferFor(ele.values.value[0])
									: setOfferFor(SPEND);
							} else if (ele.name === OFFER_RANGE) {
								ele.values.value[0]
									? setOfferRange(ele.values.value[0])
									: offerType === BASE_EARN
									? setOfferRange(MULTIPLIER)
									: setOfferRange(FLAT);
							} else if (ele.name === OFFER_THRESHOLD) {
								setOfferThreshold(ele.values.value[0]);
							}
						});
					} else if (element?.propertyType === TIER.toUpperCase()) {
						element?.property?.map((ele) => {
							if (ele.name === VALUE) {
								setOfferRangeValue(
									ele.values.value[0]
										? parseFloat(ele.values.value[0]).toLocaleString("en-US")
										: ele.values.value[0]
								);
							} else if (ele.name === MIN) {
								setOfferForValue(ele.values.value[0]);
							}
						});
						setActProperty((prevArr) => [...prevArr, element]);
					}
				}
			);
	}, []);

	//update offerFor and offerRange if user chose from the dropdown
	useEffect(() => {
		if (awardDetailData) {
			setAwardDetaildata((prevAwardDetails) => {
				return {
					action: [
						{
							actionID: prevAwardDetails?.action[0]?.actionID,
							actionName: prevAwardDetails?.action[0]?.actionName,
							actionProperties: {
								actionProperty:
									prevAwardDetails?.action[0]?.actionProperties?.actionProperty?.map(
										(element) => {
											if (!element?.propertyType) {
												let prop = element?.property?.map((ele) => {
													if (ele.name === OFFER_FOR) {
														offerFor === QUANTITY
															? (ele.values.value[0] = ITEM)
															: (ele.values.value[0] = offerFor);
														return ele;
													} else if (ele.name === OFFER_RANGE) {
														ele.values.value[0] =
															offerType === BASE_EARN ? MULTIPLIER : offerRange;
														return ele;
													} else if (
														ele.name === OFFER_THRESHOLD &&
														offerType === MISSION
													) {
														offerThreshold &&
														offerThreshold.includes(".") === false
															? (ele.values.value[0] = offerThreshold + ".00")
															: (ele.values.value[0] = offerThreshold);
														return ele;
													}
													return ele;
												});
												return {
													...element,
													property: prop,
												};
											} else if (element?.propertyType === TIER.toUpperCase()) {
												let prop = element?.property?.map((ele) => {
													if (ele.name === VALUE) {
														ele.values.value[0] = offerRangeValue
															? offerRangeValue.replace(/,/g, "")
															: "";
														return ele;
													} else if ((ele.name = MIN)) {
														let value = "";
														if (offerFor === SPEND && offerForValue === "") {
															value = "0.01";
														} else if (
															(offerFor === TRIP || offerFor === QUANTITY) &&
															offerForValue === ""
														) {
															value = "1";
														} else {
															if (
																offerFor === SPEND &&
																offerForValue &&
																offerForValue.includes(".") === false
															) {
																value = offerForValue + ".00";
															} else {
																value = offerForValue;
															}
														}
														ele.values.value[0] = value
															? value.replace(/,/g, "")
															: "";
														return ele;
													}
													return ele;
												});
												return {
													...element,
													property: prop,
												};
											}
											return element;
										}
									),
							},
						},
					],
				};
			});
		}
	}, [offerRange, offerFor, offerRangeValue, offerForValue, offerThreshold]);

	useEffect(() => {
		dispatch(updateRewardData(awardDetailData));
	}, [awardDetailData, dispatch]);

	function handleChangeSelect({ value }, { name }) {
		let e = { target: { name: name, value: value } };
		// name === OFFER_RANGE ? setOfferRange(value) : setOfferFor(value);
		// name === OFFER_RANGE ? setOfferRangeValue("") setOfferThreshold(""): setOfferForValue("");

		if (name === OFFER_RANGE) {
			setOfferRange(value);
			setOfferRangeValue("");
		} else {
			setOfferFor(value);
			setOfferThreshold("");
			setOfferForValue("");
		}
	}
	return (
		<>
			<div className="reward-details-title setup-page-section-title">
				{REWARD_TITLE}
			</div>
			<div className="spend-section">
				<div className="spend-section-inner">
					{offerType !== BASE_EARN && offerType !== NON_PURCHASE && (
						<div className="spend-section-fieldset">
							<fieldset
								className="selector-fieldset reward-fieldset dropdown"
								disabled={disabled}
							>
								<legend>{OFFER_FOR_TITLE}</legend>
								<Select
									defaultInputValue={offerFor}
									value={
										offerFor && {
											label:
												offerType === MISSION
													? offerForOptionsMission.find(
															(option) => option.value === offerFor
													  ).label
													: offerForAlias[offerFor],
											value: offerFor,
										}
									}
									name="OFFER_FOR"
									options={
										offerType === MISSION
											? offerForOptionsMission
											: offerType === POINTS_EARN
											? offerForOptionsPointsEarn
											: offerForOptions
									}
									styles={getCustomStyles(theme)}
									onChange={handleChangeSelect}
									isDisabled={disabled}
								/>
							</fieldset>

							<div
								className="selector-fieldset reward-fieldset"
								style={{ position: "relative" }}
							>
								<fieldset
									className={`selector-fieldset reward-fieldset ${
										offerType !== BASE_EARN && offerType !== NON_PURCHASE
											? ""
											: "ml-0"
									} dropdown`}
									disabled={disabled}
									style={{ position: "relative" }}
								>
									<legend>{OFFER_RANGE_TITLE}</legend>
									<Select
										defaultInputValue={offerRange}
										value={
											offerRange && {
												label: offerRangeAlias[offerRange],
												value: offerRange,
											}
										}
										name="OFFER_RANGE"
										options={offerRangeOptions}
										styles={getCustomStyles(theme)}
										onChange={handleChangeSelect}
										isDisabled={disabled}
									/>
								</fieldset>
								{offerRange === MULTIPLIER && (
									<>
										<div className="ml-10 info-btn-reward points-award-type">
											<AiOutlineInfoCircle
												className="info-icon"
												onMouseEnter={() => {
													setShowInfo(true);
												}}
												onMouseLeave={() => {
													setShowInfo(false);
												}}
											/>

											{showInfo && (
												<>
													<div
														className="info-msg-point"
														onMouseEnter={() => {
															setShowInfo(true);
														}}
														onMouseLeave={() => {
															setShowInfo(false);
														}}
													>
														<div className="triangle"></div>
														<p>{POINTS_TITLE} </p>
														Points multiplier can be used to configure number of
														points you want to award per qualified dollar spend.
														E.g. if Points per dollar is 10 and qualified spend
														is $50, then total 500 points will be awarded.
													</div>
												</>
											)}
										</div>
									</>
								)}
							</div>
						</div>
					)}

					<div
						className={`row-2 ${
							offerType !== BASE_EARN && offerType !== NON_PURCHASE
								? "mt-32"
								: "mt-0"
						}`}
					>
						{offerType !== NON_PURCHASE && (
							<>
								{offerFor &&
									getInputOfferFor(
										offerFor,
										offerForValue,
										setOfferForValue,
										disabled,
										offerType,
										offerThreshold,
										setOfferThreshold
									)}
							</>
						)}

						{offerRange &&
							getInputOfferRange(
								offerRange,
								offerRangeValue,
								setOfferRangeValue,
								disabled,
								offerType
							)}
					</div>
				</div>
			</div>
		</>
	);
};

export default RewardSection;
