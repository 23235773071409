import { FaAngleRight, FaAngleLeft } from "react-icons/fa";
import React from "react";
import {
	DISABLED,
	NEXT,
	PREVIOUS,
	ZERO,
} from "../GenericRules/GenericRulesConstants";
import {
	handleItemsPerPageChange,
	handlePaginationLink,
} from "./GenericTableUtil";

export function Pagination({
	pagination,
	setPagination,
	setFetchList,
	paginationRef,
	dataFound,
	steps = 50,
}) {
	return (
		<div className="pagination-container" ref={paginationRef}>
			<div className="pagination">
				{pagination?.totalRecords > steps ? (
					<select
						className="pagination-dropdown"
						onChange={(e) =>
							handleItemsPerPageChange(e, setPagination, setFetchList)
						}
						value={pagination?.recordPerPage}
					>
						{Array(pagination?.totalRecords)
							.fill("")
							.map((_, index) => {
								if (index % steps === ZERO && index !== ZERO) {
									return <option key={index}>{index}</option>;
								}
							})}
					</select>
				) : null}
				<>{`${
					dataFound === true
						? pagination?.currentPage * pagination?.recordPerPage -
						  pagination?.recordPerPage +
						  1
						: 0
				} - ${
					pagination?.currentPage * pagination?.recordPerPage >=
					pagination?.totalRecords
						? pagination?.totalRecords
						: pagination?.currentPage * pagination?.recordPerPage
				} of ${pagination?.totalRecords}`}</>
				<div
					className={`${PREVIOUS} pagination-link ${
						pagination?.currentPage === 1 && DISABLED
					}`}
					onClick={() => {
						handlePaginationLink(
							PREVIOUS,
							setPagination,
							setFetchList,
							pagination
						);
					}}
				>
					<FaAngleLeft className={PREVIOUS} />
				</div>
				<div
					className={`${NEXT} pagination-link ${
						pagination?.recordPerPage * pagination?.currentPage >=
							pagination?.totalRecords && DISABLED
					}`}
					onClick={() => {
						handlePaginationLink(NEXT, setPagination, setFetchList, pagination);
					}}
				>
					<FaAngleRight className={NEXT} />
				</div>
			</div>
		</div>
	);
}
