export function getDateString(str) {
  const index = str.indexOf("T");
  if (index !== -1) {
    return str.substring(0, index);
  }
  return str;
}

export function convertDateFormat(dateString) {
  const originalDate = new Date(dateString);
  const year = originalDate.getFullYear();
  const month = String(originalDate.getMonth() + 1).padStart(2, "0");
  const day = String(originalDate.getDate()).padStart(2, "0");
  const yyyy_mm_dd = `${year}-${month}-${day}`;
  return yyyy_mm_dd;
}

export function getPlusDate(str){
  return str.substring(0, str.indexOf('d'));
}
