import { DARK } from "./Constants";

//var progress = document.getElementById('animationProgress');
export const BAR_CHART_LABELS = [];
export const BAR_CHART_DATA = {
  labels: BAR_CHART_LABELS,
  datasets: [
    {
      barPercentage: 0.4,
      label: "Draft",
      data: [],
      backgroundColor: "rgb(10,132,253)",
      font: {
        family: "Poppins",
      },
    },
    {
      barPercentage: 0.4,
      label: "Active",
      data: [],
      backgroundColor: "rgb(153, 204, 255)",
      font: {
        family: "Poppins",
      },
    },
    {
      barPercentage: 0.4,
      label: "Expired",
      data: [],
      backgroundColor: "rgb(28,62,95)",
      font: {
        family: "Poppins",
      },
    },
  ],
};
// </block:setup>

//<block:options>
export const BAR_CHART_OPTIONS = {
  plugins: {
    legend: {
      display: true,
      labels: {
        boxWidth: 10,
        boxHeight: 10,
        textAlign: "left",
        padding: 10,
        font: {
          family: "Poppins",
        },
      },
      title: {
        display: true,
        text: "Offer Status",
        align: "start",
        font: {
          family: "Poppins",
        },
      },
      datalabels: {
        display: false,
        font: {
          family: "Poppins",
        },
      },
    },
  },
  responsive: true,
  interaction: {
    mode: "index",
  },
  scales: {
    x: {
      stacked: true,
      grid: {
        display: false,
      },
      font: {
        family: "Poppins",
      },
    },
    y: {
      stacked: true,
      grid: {
        display: false,
      },
      font: {
        family: "Poppins",
      },
    },
  },
};
export const BAR_CHART_OPTIONS_THEME = (theme) => {
  return {
    plugins: {
      legend: {
        display: true,
        labels: {
          boxWidth: 10,
          boxHeight: 10,
          textAlign: "left",
          padding: 10,
          color: theme === DARK ? "white" : "black",
          font: {
            family: "Poppins",
          },
        },
      },
      title: {
        display: true,
        text: "Offer Status",
        align: "start",
        color: theme === DARK ? "white" : "black",
        font: {
          family: "Poppins",
        },
      },
      datalabels: {
        display: false,
        font: {
          family: "Poppins",
        },
      },
    },
    responsive: true,
    interaction: {
      mode: "index",
    },
    scales: {
      x: {
        stacked: true,
        grid: {
          display: false,
        },
        font: {
          family: "Poppins",
        },
      },
      y: {
        stacked: true,
        grid: {
          display: false,
        },
        font: {
          family: "Poppins",
        },
      },
    },
  };
};
//</block:options>

export const LINE_CHART_LABELS = [];
export const LINE_CHART_DATA = {
  labels: LINE_CHART_LABELS,
  datasets: [
    {
      label: "Earning Offers",
      data: [],
      // borderColor: 'rgb(56, 97, 166)',
      borderColor: "rgb(73, 138, 252)",
      backgroundColor: "rgb(73, 138, 252)",
      font: {
        family: "Poppins",
      },
    },
    {
      label: "Non-Earning Offers",
      data: [],
      //borderColor: 'rgb(179, 179, 87)',
      borderColor: "rgb(128, 128, 128)",
      backgroundColor: "rgb(128, 128, 128)",
      font: {
        family: "Poppins",
      },
    },
  ],
};
// </block:setup>

//<block:options>
export const LINE_CHART_OPTIONS = {
  responsive: true,
  plugins: {
    legend: {
      display: true,
      labels: {
        boxWidth: 7,
        boxHeight: 7,
        textAlign: "left",
        padding: 10,
        color: "black",
        font: {
          family: "Poppins",
        },
      },
    },
    title: {
      display: true,
      text: "Offers Activity",
      position: "top",
      align: "start",
      color: "black",
      font: {
        family: "Poppins",
      },
    },
    datalabels: {
      display: false,
      font: {
        family: "Poppins",
      },
    },
  },
  scales: {
    x: {
      grid: {
        display: false,
        font: {
          family: "Poppins",
        },
      },
    },
    y: {
      grid: {
        display: false,
        font: {
          family: "Poppins",
        },
      },
    },
  },
};
export const LINE_CHART_OPTIONS_THEME = (theme) => {
  return {
    responsive: true,
    plugins: {
      legend: {
        display: true,
        labels: {
          boxWidth: 7,
          boxHeight: 7,
          textAlign: "left",
          padding: 10,
          color: theme === DARK ? "white" : "black",
          font: {
            family: "Poppins",
          },
        },
      },
      title: {
        display: true,
        text: "Offers Activity",
        position: "top",
        align: "start",
        color: theme === DARK ? "white" : "black",
        font: {
          family: "Poppins",
        },
      },
      datalabels: {
        display: false,
        font: {
          family: "Poppins",
        },
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
          font: {
            family: "Poppins",
          },
        },
      },
      y: {
        grid: {
          display: false,
          font: {
            family: "Poppins",
          },
        },
      },
    },
  };
};
//</block:options>

export const PROGRESS_CHART_LABELS = [];
export const PROGRESS_CHART_DATA = {
  labels: PROGRESS_CHART_LABELS,
  datasets: [
    {
      label: "Expiring Offers",
      data: [],
      backgroundColor: [
        "rgba(51, 51, 255, 0.4)",
        "rgba(51, 0, 102, 0.8)",
        "rgba(0, 51, 102, 0.4)",
        "rgba(153,0,0, 0.4)",
        "rgba(153, 204, 255, 0.8)",
      ],
      borderColor: [
        "rgb(51, 51, 255)",
        "rgb(51, 0, 102)",
        "rgb(0, 51, 102)",
        "rgb(153,0,0)",
        "rgb(153, 204, 255)",
      ],
      font: {
        family: "Poppins",
      },
      borderWidth: 0,
      barPercentage: 0.3,
      borderRadius: 15,
    },
  ],
};
// </block:setup>

//<block:options>
export const PROGRESS_CHART_OPTIONS_THEMED = (theme) => {
  return {
    responsive: true,
    indexAxis: "y",
    scales: {
      x: {
        display: false,
        ticks: {
          display: false,
          //padding: 100,
          //backdropPadding: 100,
        },
        grid: {
          display: false,
        },
        font: {
          family: "Poppins",
        },
      },
      y: {
        grid: {
          display: false,
        },
        beginAtZero: true,
        font: {
          family: "Poppins",
        },
      },
    },
    plugins: {
      //offset:20,
      legend: {
        display: false,
        font: {
          family: "Poppins",
        },
      },
      title: {
        display: true,
        text: "Expiring Offers as of 7/11/2023",
        color: theme === DARK ? "white" : "black",
        position: "top",
        align: "start",
        font: {
          family: "Poppins",
        },
      },
      datalabels: {
        display: true,
        color: theme === DARK ? "white" : "black",
        anchor: "end",
        offset: -20,
        align: "start",
        font: {
          family: "Poppins",
        },
      },
    },
  };
};
export const PROGRESS_CHART_OPTIONS = {
  responsive: true,
  indexAxis: "y",
  scales: {
    x: {
      display: false,
      ticks: {
        display: false,
        //padding: 100,
        //backdropPadding: 100,
      },
      grid: {
        display: false,
      },
      font: {
        family: "Poppins",
      },
    },
    y: {
      grid: {
        display: false,
      },
      font: {
        family: "Poppins",
      },
      beginAtZero: true,
    },
  },
  plugins: {
    //offset:20,
    legend: {
      display: false,
      font: {
        family: "Poppins",
      },
    },
    title: {
      display: true,
      text: "Expiring Offers as of 7/11/2023",
      position: "top",
      align: "start",
      font: {
        family: "Poppins",
      },
    },
    datalabels: {
      display: true,
      color: "black",
      anchor: "end",
      offset: -20,
      align: "start",
      font: {
        family: "Poppins",
      },
    },
  },
};
//</block:options>

//Date range select options
export const dateRangeOptions = [
  { value: "7", label: "Last 7 Days" },
  { value: "14", label: "Last 14 Days" },
  { value: "30", label: "Last 30 Days" },
];
export const dateRangeDefaultValue = [{ value: "7", label: "Last 7 Days" }];

// Campaign Data Set
export const CAMPAIGN_BAR_CHART_CAMPAIGN_STATUS_DATA = {
  labels: BAR_CHART_LABELS,
  datasets: [
    {
      barPercentage: 0.4,
      label: "Total Active",
      data: [],
      backgroundColor: "#3B8AC3",
      font: {
        family: "Poppins",
      },
    },
    {
      barPercentage: 0.4,
      label: "Completed",
      data: [],
      backgroundColor: "#86E8FE",
      font: {
        family: "Poppins",
      },
    },
    {
      barPercentage: 0.4,
      label: "Total Draft",
      data: [],
      backgroundColor: "#6979A1",
      font: {
        family: "Poppins",
      },
    },
  ],
};
export const CAMPAIGN_BAR_CHART_CAMPAIGN_STATUS_OPTIONS = {
  plugins: {
    legend: {
      display: true,
      labels: {
        boxWidth: 10,
        boxHeight: 10,
        textAlign: "left",
        padding: 10,
        font: {
          family: "Poppins",
        },
      },
    },
    title: {
      display: true,
      text: "Campaign Status",
      align: "start",
      font: {
        family: "Poppins",
      },
    },
    datalabels: {
      display: false,
      font: {
        family: "Poppins",
      },
    },
  },
  responsive: true,
  interaction: {
    mode: "index",
  },
  scales: {
    x: {
      stacked: true,
      grid: {
        display: false,
      },
      font: {
        family: "Poppins",
      },
    },
    y: {
      min: 0,
      max: 50,
      stacked: true,
      grid: {
        display: false,
      },
      font: {
        family: "Poppins",
      },
    },
  },
};

// Delivery Status
export const CAMPAIGN_BAR_CHART_DELIVERY_STATUS_DATA = {
  labels: BAR_CHART_LABELS,
  datasets: [
    {
      barPercentage: 0.4,
      label: "Sent",
      data: [],
      backgroundColor: "#0275FC",
      font: {
        family: "Poppins",
      },
    },
    {
      barPercentage: 0.4,
      label: "Delivered",
      data: [],
      backgroundColor: "#38B435",
      font: {
        family: "Poppins",
      },
    },
  ],
};
export const CAMPAIGN_BAR_CHART_DELIVERY_STATUS_OPTIONS = {
  plugins: {
    legend: {
      display: true,
      labels: {
        boxWidth: 10,
        boxHeight: 10,
        textAlign: "left",
        padding: 10,
        font: {
          family: "Poppins",
        },
      },
    },
    title: {
      display: true,
      text: "Delivery Status",
      align: "start",
      font: {
        family: "Poppins",
      },
    },
    datalabels: {
      display: false,
      font: {
        family: "Poppins",
      },
    },
  },
  responsive: true,
  interaction: {
    mode: "index",
  },
  scales: {
    x: {
      stacked: false,
      grid: {
        display: false,
      },
      font: {
        family: "Poppins",
      },
    },
    y: {
      ticks: {
        callback: function (value, index, ticks) {
          return value;
        },
      },
      font: {
        family: "Poppins",
      },
      stacked: false,
      grid: {
        display: false,
      },
    },
  },
};

// Engagemnet Status
export const CAMPAIGN_BAR_CHART_ENGAGEMENT_STATUS_DATA = {
  labels: BAR_CHART_LABELS,
  datasets: [
    {
      barPercentage: 0.4,
      label: "Opened",
      data: [],
      backgroundColor: "#1033E9",
      font: {
        family: "Poppins",
      },
    },
    {
      barPercentage: 0.4,
      label: "Clicked",
      data: [],
      backgroundColor: "#D43E3E",
      font: {
        family: "Poppins",
      },
    },
  ],
};
export const CAMPAIGN_BAR_CHART_ENGAGEMENT_STATUS_OPTIONS = {
  plugins: {
    legend: {
      display: true,
      labels: {
        boxWidth: 10,
        boxHeight: 10,
        textAlign: "left",
        padding: 10,
        font: {
          family: "Poppins",
        },
      },
    },
    title: {
      display: true,
      text: "Engagement Status",
      align: "start",
      font: {
        family: "Poppins",
      },
    },
    datalabels: {
      display: false,
      font: {
        family: "Poppins",
      },
    },
  },
  responsive: true,
  interaction: {
    mode: "index",
  },
  scales: {
    x: {
      stacked: false,
      grid: {
        display: false,
      },
      font: {
        family: "Poppins",
      },
    },
    y: {
      ticks: {
        callback: function (value, index, ticks) {
          return value;
        },
      },
      stacked: false,
      grid: {
        display: false,
      },
      font: {
        family: "Poppins",
      },
    },
  },
};
export const VARIATION_STAT_DATA = {
	labels: BAR_CHART_LABELS,
	datasets: [
	 
	],
  };

export const VARIATION_STAT_DATA_OPTIONS = {
	plugins: {
	  legend: {
		display: true,
		labels: {
		  boxWidth: 10,
		  boxHeight: 10,
		  textAlign: "left",
		  padding: 10,
		  font: {
			family: "Poppins",
		  },
		},
	  },
	  title: {
		display: true,
		text: "Variation Status",
		align: "start",
		font: {
		  family: "Poppins",
		},
	  },
	  datalabels: {
		display: false,
		font: {
		  family: "Poppins",
		},
	  },
	},
	responsive: true,
	interaction: {
	  mode: "index",
	},
	scales: {
	  x: {
		stacked: false,
		grid: {
		  display: false,
		},
		font: {
		  family: "Poppins",
		},
	  },
	  y: {
		ticks: {
		  callback: function (value, index, ticks) {
			return value;
		  },
		},
		font: {
		  family: "Poppins",
		},
		stacked: false,
		grid: {
		  display: false,
		},
	  },
	},
  };
  