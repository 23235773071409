import { VscSearchStop } from "react-icons/vsc";

export function emptyData(message) {
	return (
		<div className="empty-data-container">
			{/* <VscSearchStop className="no-search-icon" size={22} /> */}
			<svg
				className="no-search-icon"
				width="50"
				height="50"
				viewBox="0 0 50 50"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M23.9584 43.7503C34.889 43.7503 43.75 34.8893 43.75 23.9587C43.75 13.028 34.889 4.16699 23.9584 4.16699C13.0277 4.16699 4.16669 13.028 4.16669 23.9587C4.16669 34.8893 13.0277 43.7503 23.9584 43.7503ZM28.8983 16.7059C29.5369 16.0672 30.5725 16.0672 31.2111 16.7059C31.8498 17.3446 31.8498 18.3801 31.2111 19.0188L26.2715 23.9584L31.2109 28.8979C31.8496 29.5366 31.8496 30.5721 31.2109 31.2108C30.5722 31.8494 29.5367 31.8494 28.898 31.2108L23.9586 26.2713L19.0185 31.2114C18.3798 31.8501 17.3443 31.8501 16.7056 31.2114C16.067 30.5727 16.067 29.5372 16.7056 28.8985L21.6457 23.9584L16.7054 19.0181C16.0667 18.3795 16.0667 17.344 16.7054 16.7053C17.3441 16.0666 18.3796 16.0666 19.0183 16.7053L23.9586 21.6456L28.8983 16.7059Z"
					fill="#292D32"
				/>
				<path
					d="M44.375 45.8327C44 45.8327 43.625 45.6868 43.3542 45.416L39.4792 41.541C38.9167 40.9785 38.9167 40.0618 39.4792 39.4785C40.0417 38.916 40.9584 38.916 41.5417 39.4785L45.4167 43.3535C45.9792 43.916 45.9792 44.8327 45.4167 45.416C45.125 45.6868 44.75 45.8327 44.375 45.8327Z"
					fill="#292D32"
				/>
			</svg>

			<p>{message ? message : "No Data to display"}</p>
		</div>
	);
}
