import {
	CREATE_SUBCLUB_FAILURE,
	CREATE_SUBCLUB_REQUEST,
	CREATE_SUBCLUB_SUCCESS,
	CREATE_SUBCLUB_SUCCESS_FAILURE,
	GET_SUBCLUB_LIST_FAILURE,
	GET_SUBCLUB_LIST_REQUEST,
	GET_SUBCLUB_LIST_SUCCESS,
	GET_SUBCLUB_LIST_SUCCESS_FAILURE,
	RESET_CREATE_SUBCLUB_RESPONSE,
} from "./SubclubType";

const initialState = {
	createSubclubLoading: false,
	createSubclubResponse: null,
	createSubclubError: false,
	getSubclubListLoading: false,
	getSubclubListResponse: null,
	getSubclubListError: false,
};

export const TellurideSubclubReducer = (state = initialState, action) => {
	switch (action.type) {
		case CREATE_SUBCLUB_REQUEST:
			return {
				...state,
				createSubclubLoading: true,
			};
		case CREATE_SUBCLUB_SUCCESS:
			return {
				...state,
				createSubclubLoading: false,
				createSubclubResponse: action.payload,
				createSubclubError: false,
			};
		case CREATE_SUBCLUB_SUCCESS_FAILURE:
			return {
				...state,
				createSubclubLoading: false,
				createSubclubResponse: "",
				createSubclubError: true,
			};
		case CREATE_SUBCLUB_FAILURE:
			return {
				...state,
				createSubclubLoading: false,
				createSubclubResponse: "",
				createSubclubError: true,
			};
		case RESET_CREATE_SUBCLUB_RESPONSE:
			return {
				...state,
				createSubclubResponse: "",
			};
		case GET_SUBCLUB_LIST_REQUEST:
			return {
				...state,
				getSubclubListLoading: true,
			};
		case GET_SUBCLUB_LIST_SUCCESS:
			return {
				...state,
				getSubclubListLoading: false,
				getSubclubListResponse: action.payload,
				getSubclubListError: false,
			};
		case GET_SUBCLUB_LIST_SUCCESS_FAILURE:
			return {
				...state,
				getSubclubListLoading: false,
				getSubclubListResponse: action.payload,
				getSubclubListError: true,
			};
		case GET_SUBCLUB_LIST_FAILURE:
			return {
				...state,
				getSubclubListLoading: false,
				getSubclubListResponse: action.payload,
				getSubclubListError: true,
			};
		default:
			return state;
	}
};
