import React from "react";
import "./SegmentTableView.style.scss";
import GenericTable from "../../utilsComponent/TableUtil/GenericTable";
import { DETAILS, SEGMENTS } from "../../constantComponent/PathConstants";
import {
	SEGMENT_LIST_COLUMNS,
	SEGMENT_SEARCH_TEXT,
	VIEW_SEGMENT,
	SEGMENT_LIST_REQ_BODY_KEY,
	SEGMENT_LIST_RES_BODY_KEY,
} from "./SegmentTableViewConstants";
import { useNavigate } from "react-router-dom";
import { REDUX } from "../../constantComponent/Constants";
import { getRightsKey } from "../../utilsComponent/CommonUtil";

const SegmentView = ({ setShowOfferPopup }) => {
	function btnClickHandler() {
		setShowOfferPopup(true);
	}
	const advanceSearchHeader = {
		searchBar: true,
		globalFilter: true,
		dateType: false,
		dateRange: false,
		tableHeaderBtn: {
			label: "Create Segment",
			clickHandler: btnClickHandler,
			rights: getRightsKey([
				"button",
				"segmentation",
				"viewsegmentation",
				"createsegment",
			]),
		},
	};
	const navigate = useNavigate();
	function handleRowClick(row) {
		navigate({
			pathname:
				"/" + window.location.pathname.split("/")[1] + SEGMENTS + DETAILS,
			search: `?groupId=${row.groupId}`,
		});
	}

	return (
		<GenericTable
			COLUMNS={SEGMENT_LIST_COLUMNS}
			FILTER_SHOW_LIMIT={1}
			SHOW_HEADER={true}
			ADVANCE_SEARCH_HEADER={advanceSearchHeader}
			ADVANCE_SEARCH_PLACEHOLDER={SEGMENT_SEARCH_TEXT}
			API_URL={process.env.REACT_APP_GET_SEGMENTS_LIST}
			TABLE_SRC={VIEW_SEGMENT}
			SHOW_HEADER_DASHBOARD={false}
			SHOW_TOOLTIP={false}
			ROW_CLICKABLE={true}
			ROW_CLICK_HANDLER={handleRowClick}
			REQUEST_BODY_KEY={SEGMENT_LIST_REQ_BODY_KEY}
			RESPONSE_BODY_KEY={SEGMENT_LIST_RES_BODY_KEY}
			IS_SEARCH_ALLOWED={true}
			SAVE_SEARCHED_FILTER={{
				isSaved: true, //  true || false => default : true
				storage: REDUX, // redux  || local || session  => default : "redux"
			}}
		/>
	);
};

export default SegmentView;
