export const OFFER_SETUP_COMMUNICATION_STATUS_Y = "Y";
export const OFFER_SETUP_COMMUNICATION_STATUS_N = "N";
export const OFFER_SETUP_COMMUNICATION_LINK_STATUS_RENAME = "RENAME";
export const OFFER_SETUP_COMMUNICATION_LINK_STATUS_NEW = "NEW";
export const OFFER_SETUP_COMMUNICATION_LINK_STATUS_DELETE = "DELETE";
export const OFFER_SETUP_COMMUNICATION_CREATE_ELEMENT = "a";
export const OFFER_SETUP_COMMUNICATION_CREATE_EMAIL_ELEMENT = "emailId";
export const OFFER_SETUP_COMMUNICATION_ERROR_QUERY_SELECT = ".error-message";
export const OFFER_SETUP_COMMUNICATION_ERROR = "error";
export const OFFER_SETUP_COMMUNICATION_SHOW = "show";
export const OFFER_SETUP_COMMUNICATION_DISPLAY_NONE = "none";
export const OFFER_SETUP_WHAT_TO_COMMUNICATION_TITLE = "What to communicate";
export const OFFER_SETUP_COMMUNICATION_EMAIL_TITLE = "Email Content";
export const OFFER_SETUP_COMMUNICATION_PREVIEW_TEST_TITLE = "Preview & Test";
export const OFFER_SETUP_COMMUNICATION_HEADLINE_TITLE = "HeadLine";
export const OFFER_SETUP_COMMUNICATION_BODYLINE_TITLE = "Bodyline";
export const OFFER_SETUP_COMMUNICATION_ACTION_URL_TITLE = "Call To Action URL";
export const OFFER_SETUP_COMMUNICATION_TEMPLATE_TITLE = "Template";
export const OFFER_SETUP_COMMUNICATION_SUBJECT_LINE_TITLE = "Subject Line";
export const OFFER_SETUP_COMMUNICATION_SUBJECT_EMAIL_TITLE = "Email";
export const OFFER_SETUP_COMMUNICATION_SUBJECT_SEND_TITLE = "Send";
export const OFFER_SETUP_COMMUNICATION_SENDING_LOADER_TITLE = "Sending...";
export const OFFER_SETUP_COMMUNICATION_SUBJECT_EMAIL_ERROR_MESSAGE =
  "Enter valid email address";
export const OFFER_SETUP_COMMUNICATION_PREVIEW_TEMPLATE_NAME = "vertical";

export const OFFER_SETUP_CREATE_OFFER_PROCESSING_LOADER_TITLE = "Processing...";
export const OFFER_SETUP_CREATE_OFFER_CONTINUE_SETUP = "Continue to setup";

export const OFFER_SETUP_DISCOUNT_SPEND_EXCRED = "spend_excred";
export const OFFER_SETUP_DISCOUNT_APPLY_ON = "APPLY_ON";
export const OFFER_SETUP_DISCOUNT_ALL_ITEMS = "ALL_ITEMS";

export const OFFER_SETUP_OFFER_STATS = "Offer Stats";
export const OFFER_SETUP_OFFER_POINTS_AWARDED_TITLE = "Points awarded";
export const OFFER_SETUP_OFFER_POINTS_MEMBER_BENEFITED = "Members benefited";
export const OFFER_SETUP_OFFER_POINTS_SALES_IMPACTED = "Sales impacted";
export const OFFER_SETUP_OFFER_POINTS_LAST_AWARDED = "Last awarded";
export const CAP = "CAP";

export const HEADLINE = "headline";
export const BODYLINE = "bodyline";
export const REQUEST_BODY_FILE = "file";
export const REQUEST_BODY_PARTNER_ID = "partnerId";
export const REQUEST_BODY_CONTENT_ID = "contentId";
export const SCROLL_BEHAVIOUR_SMOOTH = "smooth";
export const ENTER = "Enter";
export const RESPONSE_STATUS_SUCCESS = "success";

export const REQUEST_IMAGE_TYPE = "image/jpeg";
export const RESPONSE_STATUS = "200";

export const CONTENT_NAVIGATION_PATH = "/campaign/content/details?offerid=";
export const content_offer_request = {
  requestorID: "OFRI",
  messageVersion: process.env.REACT_APP_MESSAGE_VERSION_05,
  offerData: {
    offerCategory: "stackable",
    offerSubCategory: "",
    offerType: "Message only",
    offerSubType: "MESSAGE",
    offerPointsDollarName: "",
    offerDescription: "",
    linkedOfferStatus: "N",
    isContentOffer: "Y",

    deleteImages: {
      imageIds: [],
    },
  },
  actions: {
    action: [
      {
        actionName: "My Action",
        actionType: "MESSAGE",
        actionJson: {
          // headline1: "", // api call  for headline
          // bodyline1: "", // api call  for  bodyline
          imageId: "",
          imageName: "",
          disclaimerLegalCopy: "",
          callToActionURL: "",
          category: "MARKETING",
          subCategory: "Default",
        },
      },
    ],
  },
};

export const contentGenerationRequestBody = {
  prompt: "",
  varg: {
    company: "",
    // offercode: "50 percent off",
    // occassion: "holi",
    // userinput: "for shopping on all online store.",
  },
  responseText: ["Headline", "Bodyline"],
  numberOfSuggestion: 1,
};
