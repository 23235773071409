import React, { useEffect, useState } from "react";
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend,
	PointElement,
} from "chart.js";
import "chart.js/auto";
import { Bar } from "react-chartjs-2";
import {
	BAR_CHART_LABELS,
	BAR_CHART_DATA,
	BAR_CHART_OPTIONS,
	BAR_CHART_OPTIONS_THEME,
} from "../../constantComponent/ChartConstants";
import { loading } from "../../LoaderComponents/loadingDesignConstant";
import { useSelector } from "react-redux";
import { emptyData } from "../../constantComponent/DesignConstant";

ChartJS.register(
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend,
	PointElement
);

const BarChart = ({ id, data, option, name }) => {
	return (
		<div className="graph-div-border mr-0">
			<div
				id={`${id}barChartSpinDiv`}
				className="chart_spin_div mr-0"
				style={{ display: "none" }}
			>
				{loading()}
			</div>
			<div
				id={`${id}emptyData`}
				className="chart_spin_div mr-0"
				style={{ display: "none" }}
			>
				{emptyData()}
			</div>
			<div id={`${id}barChartDiv`}>
				<Bar id={id} data={data} options={option}></Bar>
			</div>
		</div>
	);
};
// </block:actions>
export default BarChart;
