export const RESET = "reset";
export const ASC = "asc";
export const DESC = "desc";
export const SELECT = "select";
export const SELECTED = "selected";
export const LEFT = "left";
export const RIGHT = "right";
export const APPLY = "APPLY";
export const TOOLTIP_ACTIVE = "tooltip-active";
export const FILTER = "Filter";
export const SINGLE = "SINGLE";
export const SEARCH = "Search";
export const DEFAULT_SEARCH_INPUT_PLACEHOLDER = "Search";
export const OFFER_STATUS = "offerStatus";
export const STATUS = "status";
export const SAVED_FILTERS = "saved-filters";
