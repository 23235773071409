import React, { useEffect, useState } from "react";
import "./AttributeValidation.style.scss";
import { useDispatch, useSelector } from "react-redux";
import { setAttributeValidation } from "../../../tellurideExtRedux";
import {
  DATE,
  NUMBER,
  STRING,
  SUCCESS,
} from "../../constantComponent/Constants";
import { NumericFormat } from "react-number-format";
import { LENGTH, VALUE } from "./AttributeValidationConstant";
import {
  ATTR_MIN_MAX_LENGTH_VALIDATION_MESSAGE,
  ATTR_MIN_MAX_VALUE_VALIDATION_MESSAGE,
  IS_REQUIRED,
} from "../../constantComponent/UIErrorMessages";

const AttributeValidation = ({
  isDisabled,
  attributeSetupValidation,
  setAttributeSetupValidation,
}) => {
  const [validation, setValidation] = useState({ min: "", max: "" });

  const metaData = useSelector((state) => state.metaData);
  const updateAttributeDetailsData = metaData.updateAttributeDetailsData;
  const [validationDisabled, setValidationDisabled] = useState(
    updateAttributeDetailsData?.type?.toLowerCase() === DATE ? true : false
  );

  function handleChange(e) {
    if (isDisabled === false) {
      let name = e.target.name;
      let value = e.target.value;
      setValidation((prev) => {
        return {
          ...prev,
          [name]: value,
        };
      });
      if (value.length === 0) {
        setAttributeSetupValidation((prev) => {
          return {
            ...prev,
            [name]: {
              ...prev[name],
              message: `${name} ${
                updateAttributeDetailsData?.type?.toLowerCase() === STRING
                  ? LENGTH
                  : VALUE
              } ${IS_REQUIRED}`,
              status: true,
            },
          };
        });
      } else if (updateAttributeDetailsData?.type?.toLowerCase() === STRING) {
        let inputNumber = parseInt(value);
        if (inputNumber < 1 || inputNumber > 30) {
          setAttributeSetupValidation((prev) => {
            return {
              ...prev,
              [name]: {
                ...prev[name],
                message: `${name} ${ATTR_MIN_MAX_LENGTH_VALIDATION_MESSAGE}`,
                status: true,
                touched: true,
              },
            };
          });
        } else {
          setAttributeSetupValidation((prev) => {
            return {
              ...prev,
              [name]: {
                ...prev[name],
                message: ``,
                status: false,
              },
            };
          });
        }
      } else if (updateAttributeDetailsData?.type?.toLowerCase() === NUMBER) {
        let inputNumber = parseInt(value);
        if (inputNumber < 1 || inputNumber > 30) {
          setAttributeSetupValidation((prev) => {
            return {
              ...prev,
              [name]: {
                ...prev[name],
                message: `${name} ${ATTR_MIN_MAX_VALUE_VALIDATION_MESSAGE}`,
                status: true,
                touched: true,
              },
            };
          });
        } else {
          setAttributeSetupValidation((prev) => {
            return {
              ...prev,
              [name]: {
                ...prev[name],
                message: ``,
                status: false,
              },
            };
          });
        }
      } else {
        setAttributeSetupValidation((prev) => {
          return {
            ...prev,
            [name]: {
              ...prev[name],
              message: ``,
              status: false,
            },
          };
        });
      }
    }
  }
  function handleBlur(e) {
    let name = e.target.name;
    let value = e.target.value;
    if (validation[name]?.length === 0) {
      setAttributeSetupValidation((prev) => {
        return {
          ...prev,
          [name]: {
            message: `${name} ${
              updateAttributeDetailsData?.type?.toLowerCase() === STRING
                ? LENGTH
                : VALUE
            } ${IS_REQUIRED}`,
            status: true,
            touched: true,
          },
        };
      });
    } else if (updateAttributeDetailsData?.type?.toLowerCase() === STRING) {
      let inputNumber = parseInt(value);
      if (inputNumber < 1 || inputNumber > 30) {
        setAttributeSetupValidation((prev) => {
          return {
            ...prev,
            [name]: {
              ...prev[name],
              message: `${name} ${ATTR_MIN_MAX_LENGTH_VALIDATION_MESSAGE}`,
              status: true,
              touched: true,
            },
          };
        });
      } else {
        setAttributeSetupValidation((prev) => {
          return {
            ...prev,
            [name]: {
              ...prev[name],
              message: ``,
              status: false,
            },
          };
        });
      }
    } else if (updateAttributeDetailsData?.type?.toLowerCase() === NUMBER) {
      let inputNumber = parseInt(value);
      if (inputNumber < 1 || inputNumber > 30) {
        setAttributeSetupValidation((prev) => {
          return {
            ...prev,
            [name]: {
              ...prev[name],
              message: `${name} ${ATTR_MIN_MAX_VALUE_VALIDATION_MESSAGE}`,
              status: true,
              touched: true,
            },
          };
        });
      } else {
        setAttributeSetupValidation((prev) => {
          return {
            ...prev,
            [name]: {
              ...prev[name],
              message: ``,
              status: false,
            },
          };
        });
      }
    } else {
      setAttributeSetupValidation((prev) => {
        return {
          ...prev,
          [name]: {
            ...prev[name],
            message: ``,
            status: false,
          },
        };
      });
    }
  }
  const dispatch = useDispatch();

  const initialAttrSaveData = useSelector(
    (state) => state.metaData.getAttributeDetailsResponse
  );
  useEffect(() => {
    if (initialAttrSaveData?.status === SUCCESS) {
      const attrDetails = initialAttrSaveData?.attribute;
      setValidation({
        min: attrDetails?.validation?.minLength,
        max: attrDetails?.validation?.maxLength,
      });
    } else {
      setValidation({
        min: "",
        max: "",
      });
    }
  }, [initialAttrSaveData]);

  useEffect(() => {
    if (updateAttributeDetailsData?.type?.toLowerCase() === DATE) {
      setValidationDisabled(true);
      setAttributeSetupValidation((prev) => {
        return {
          ...prev,
          min: {
            ...prev.min,
            status: false,
          },
          max: {
            ...prev.max,
            status: false,
          },
        };
      });
    } else {
      setValidationDisabled(false);
    }
  }, [updateAttributeDetailsData.type]);
  useEffect(() => {
    dispatch(setAttributeValidation(validation));
  }, [validation, dispatch]);

  useEffect(() => {
    if (updateAttributeDetailsData?.type?.toLowerCase() === DATE) {
      setAttributeSetupValidation((prev) => {
        return {
          ...prev,
          min: {
            ...prev.min,
            status: false,
          },
          max: {
            ...prev.max,
            status: false,
          },
        };
      });
    } else {
      setAttributeSetupValidation((prev) => {
        let temp = JSON.parse(JSON.stringify(prev));
        for (let inputKey in prev) {
          if (["min", "max"].includes(inputKey)) {
            if (validation[inputKey]?.length === 0) {
              temp[inputKey] = {
                ...prev[inputKey],
                status: true,
                message: `${inputKey} ${
                  updateAttributeDetailsData?.type?.toLowerCase() === STRING
                    ? LENGTH
                    : VALUE
                } ${IS_REQUIRED}`,
              };
            } else {
              if (updateAttributeDetailsData?.type?.toLowerCase() === STRING) {
                let inputNumber = parseInt(validation[inputKey]);
                if (inputNumber < 1 || inputNumber > 30) {
                  temp[inputKey] = {
                    ...prev[inputKey],
                    status: true,
                    message: `${inputKey} ${ATTR_MIN_MAX_LENGTH_VALIDATION_MESSAGE}`,
                  };
                } else {
                  temp[inputKey] = {
                    ...prev[inputKey],
                    status: false,
                    message: ``,
                  };
                }
              } else if (
                updateAttributeDetailsData?.type?.toLowerCase() === NUMBER
              ) {
                let inputNumber = parseInt(validation[inputKey]);
                if (inputNumber < 1 || inputNumber > 30) {
                  temp[inputKey] = {
                    ...prev[inputKey],
                    status: true,
                    message: `${inputKey} ${ATTR_MIN_MAX_VALUE_VALIDATION_MESSAGE}`,
                  };
                } else {
                  temp[inputKey] = {
                    ...prev[inputKey],
                    status: false,
                    message: ``,
                  };
                }
              }
            }
          }
        }
        return temp;
      });
    }
  }, [validation, updateAttributeDetailsData.type]);

  useEffect(() => {
    if (updateAttributeDetailsData?.type?.toLowerCase() === DATE) {
      setValidationDisabled(true);
      setAttributeSetupValidation((prev) => {
        return {
          ...prev,
          min: {
            ...prev.min,
            status: false,
          },
          max: {
            ...prev.max,
            status: false,
          },
        };
      });
      setValidation({
        max: "",
        min: "",
      });
    } else {
      setValidationDisabled(false);
    }
  }, [updateAttributeDetailsData.type]);
  return (
    <>
      <div className="attribute-options-details-title setup-page-section-title">
        Validation
      </div>
      <div className="attribute-options-section">
        <div className="attribute-options-section-inner">
          <div className="attribute-option-section-wrapper">
            <div className="option-add-container">
              <fieldset
                className="selector-fieldset attribute-details-fieldset"
                disabled={
                  isDisabled || validationDisabled === true ? true : false
                }
              >
                <legend>
                  Min
                  {updateAttributeDetailsData?.type?.toLowerCase() === STRING
                    ? LENGTH
                    : VALUE}
                </legend>
                <NumericFormat
                  value={validation.min}
                  decimalScale={0}
                  displayType="input"
                  renderText={(value) => <b>{value}</b>}
                  name="min"
                  onValueChange={(_values, { event }) => {
                    let target = {
                      name: event.target.name,
                      value: event.target.value,
                    };
                    handleChange({ target });
                  }}
                  isAllowed={(values) => {
                    const { floatValue } = values;
                    return floatValue < 2147483647 || floatValue === undefined;
                  }}
                  onBlur={(event) => {
                    let target = {
                      name: event.target.name,
                      value: event.target.value,
                    };
                    handleBlur({ target });
                  }}
                  className="amount"
                />
                <small className="error-message">
                  {attributeSetupValidation?.min?.status === true &&
                  attributeSetupValidation?.min?.touched === true
                    ? attributeSetupValidation?.min?.message
                    : null}
                </small>
              </fieldset>
            </div>
            <div className="option-add-container">
              <fieldset
                className="selector-fieldset attribute-details-fieldset"
                disabled={
                  isDisabled || validationDisabled === true ? true : false
                }
              >
                <legend>
                  Max
                  {updateAttributeDetailsData?.type?.toLowerCase() === STRING
                    ? LENGTH
                    : VALUE}
                </legend>
                <NumericFormat
                  value={validation.max}
                  decimalScale={0}
                  displayType="input"
                  renderText={(value) => <b>{value}</b>}
                  name="max"
                  onValueChange={(_values, { event }) => {
                    let target = {
                      name: event.target.name,
                      value: event.target.value,
                    };
                    handleChange({ target });
                  }}
                  isAllowed={(values) => {
                    const { floatValue } = values;
                    return floatValue < 2147483647 || floatValue === undefined;
                  }}
                  onBlur={(event) => {
                    let target = {
                      name: event.target.name,
                      value: event.target.value,
                    };
                    handleBlur({ target });
                  }}
                  className="amount"
                />
                <small className="error-message">
                  {attributeSetupValidation?.max?.status === true &&
                  attributeSetupValidation?.max?.touched === true
                    ? attributeSetupValidation?.max?.message
                    : null}
                </small>
              </fieldset>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AttributeValidation;
