import { useEffect } from "react";
import InputField from "../../utilsComponent/InputFieldHandler/InputField";
import { INPUT_FIELDS } from "./ContentBuilderContstants";

export function createHeadline() {
	return {
		id: generateUniqueId(),
		value: "",
	};
}
export function createBodyline() {
	return {
		id: generateUniqueId(),
		value: "",
	};
}
export function generateUniqueId() {
	return Math.random().toString(36).substring(2, 9);
}
