export const VARIATION_STAT_CHART = "variationStatChart";
export const ABTestingSampleData = [
  {
    task_name: "N2",
    variationId: "N22",
    sent: 100,
    delivered: 90,
    impression: 500,
    click: 20,
    subject: "SaloniVariationTesting1",
    senderEmail: "test@shopyourway.com",
    contentId: "00-1241928",
    templateId: "horizontal",
    percentage: "80",
  },
  {
    task_name: "N2",
    variationId: "N23",
    sent: 200,
    delivered: 10,
    impression: 600,
    click: 5,
    subject: "SaloniVariationTesting2",
    senderEmail: "test@shopyourway.com",
    contentId: "00-1241928",
    templateId: "horizontal",
    percentage: "10",
  },
];
