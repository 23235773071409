import "./SegmentWrapper.style.scss";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../../LoaderComponents/Loading";
import { SEGMENT_LOADER_SOURCE } from "../../LoaderComponents/LoadingConstant.js";
import { ErrorBoundary } from "react-error-boundary";
import Error from "../../../tellurideExtComponents/errorsComponent/Error";
import SegmentDetailsHeader from "../segmentDetailsHeaderComponent/SegmentDetailsHeader";
import SegmentUploadList from "../UploadListComponent/UploadList";
import SegmentContent from "../segmentContentComponent/SegmentContent";
import SegmentDetails from "../segmentDetailsComponent/SegmentDetails";
import {
  DISABLED,
  OFRI,
} from "../../constantComponent/Constants.js";
import {
  getSegmentDetails,
  updateSegmentDetails,
  setSegmentMode,
  setSegmentEditMode,
} from "../../../tellurideExtRedux";
import { headers } from "../../constantComponent/Constants";
import _ from "lodash";
import { useNavigate } from "react-router-dom";
import {
  checkRightsIsEditable,
  checkRightsIsVisible,
  getRightsFromSession,
  getRightsKey,
  skey,
} from "../../utilsComponent/CommonUtil.js";
import UnauthorizedAccess from "../../unauthorizeComponent/UnauthorizeWrapper.jsx";

const SegmentWrapper = () => {
  const dispatch = useDispatch();
  const offerData = useSelector((state) => state.offerDetailsData);
  const segmentData = useSelector((state) => state.segmentDetailsData);
  const [updateSegmentData, setUpdateSegmentData] = useState(
    JSON.parse(JSON.stringify(segmentData.updateSegmentDetailsResponse))
  );
  const queryString = require("query-string");
  const groupId = queryString.parse(window.location.search).groupId;

  const navigate = useNavigate();

  const [segmentSetupDisabled, setSegmentSetupDisabled] = useState(
    segmentData.segmentMode === DISABLED
  );
  const [uploadedFile, setUploadedFile] = useState(null);
  const [offerType, setOfferType] = useState("");
	const [fetchDetailsFirst, setFetchDetailsFirst] = useState(null);

  useEffect(() => {
    if (segmentData?.segmentDetailsResponse?.groupType) {
      setOfferType(segmentData?.segmentDetailsResponse?.groupType);
      setSegmentSetupDisabled(
        !checkRightsIsEditable(
          getRightsKey([
            "segmentation",
            "detail",
            "setup",
            segmentData?.segmentDetailsResponse?.groupType.split(" ").join(""),
          ])
        ) || segmentData.segmentMode === DISABLED
      );
    }
  }, [segmentData?.segmentDetailsResponse, segmentData.segmentMode]);
  useEffect(() => {
    dispatch(updateSegmentDetails(updateSegmentData));
  }, [updateSegmentData]);

  useEffect(() => {
    //  Fetch Segment detail
		if (fetchDetailsFirst === null) {
    headers.sessionId = skey();
    headers.requestAccess = getRightsFromSession();
    headers.organization = offerData?.authOrganizationName;
    const requestHeaders = {
      headers,
    };
    const segmentDetailsRequestBody = {
      requestorID: OFRI,
      messageVersion: process.env.REACT_APP_MESSAGE_VERSION_02,
      id: groupId ? groupId : "",
    };
    if (groupId !== "" && groupId !== undefined) {
      dispatch(
        getSegmentDetails(requestHeaders, segmentDetailsRequestBody, navigate)
      );
      }
      setFetchDetailsFirst(true);
  }
  }, []);
  // useEffect(() => {
  //   //  Fetch Segment detail
  //   if (
  //     segmentData?.createUpdateSegmentLoading === false &&
  //     segmentData?.createUpdateSegmentResponseStatus === "Success"
  //   ) {
  //     headers.sessionId = skey();
  //     headers.requestAccess = getRightsFromSession();
  //     headers.organization = offerData?.authOrganizationName;
  //     const requestHeaders = {
  //       headers,
  //     };
  //     const segmentDetailsRequestBody = {
  //       requestorID: OFRI,
  //       messageVersion: process.env.REACT_APP_MESSAGE_VERSION_02,
  //       id: segmentData.createUpdateSegmentResponse.id,
  //       organization: offerData?.authOrganizationName,
  //     };
  //     if (groupId !== "" && groupId !== undefined) {
  //       dispatch(
  //         getSegmentDetails(requestHeaders, segmentDetailsRequestBody, navigate)
  //       );
  //     }
  //   }
  // }, [segmentData.createUpdateSegmentResponse]);
  useEffect(() => {
    return () => {
      dispatch(setSegmentMode(DISABLED));
    };
  }, []);

  useEffect(() => {
    let segmentEdited = false;
    if (
      segmentData.updateSegmentDetailsResponse.description !==
        segmentData.segmentDetailsResponse.description ||
      segmentData.isFileUploaded
    ) {
      segmentEdited = true;
    } else {
      segmentEdited = false;
    }
    if (
      segmentData.segmentEdited !== segmentEdited &&
      checkRightsIsEditable(
        getRightsKey([
          "segmentation",
          "detail",
          "setup",
          offerType.split(" ").join(""),
        ])
      )
    ) {
      dispatch(setSegmentEditMode(segmentEdited));
    }
  }, [segmentData]);

  return (
    <ErrorBoundary FallbackComponent={Error} onError={(error) => {}}>
      <Loading source={SEGMENT_LOADER_SOURCE}>
        {checkRightsIsVisible(
          getRightsKey(["segmentation", "detail", "setup", offerType])
        ) ? (
          <div className="segment-wrapper">
            <SegmentDetailsHeader
              uploadedFile={uploadedFile}
              setUploadedFile={setUploadedFile}
            />
            <section className="display-flex segment-non-flex">
              <div className="inner-section-wrapper">
                <SegmentUploadList
                  setUploadedFile={setUploadedFile}
                  uploadedFile={uploadedFile}
                  disabled={segmentSetupDisabled}
                />
                <SegmentContent />
              </div>
              <div className="sticky-right segment-details-desktop">
                <SegmentDetails disabled={segmentSetupDisabled} />
              </div>
            </section>
          </div>
        ) : (
          <UnauthorizedAccess />
        )}
      </Loading>
    </ErrorBoundary>
  );
};

export default SegmentWrapper;
