import React from "react";
import { FaRegTimesCircle } from "react-icons/fa";
import { CREATE_OFFER_MODAL_TITLE } from "./CreateOfferConstants";
import { DESCRIPTION_LABEL } from "../offerDetailsComponent/OfferDetailsConstants";

const GenerateOfferComponent = ({ setShowOfferPopup , showComponent , setShowComponent}) => {
  return (
    <div>
      <>
        <div className="header">
          <h1 className="fs-16">What would be like to setup?</h1>
          <FaRegTimesCircle
            onClick={() => {
              setShowOfferPopup(false);
            }}
            size={25}
            className="offer-modal-close-icon"
          />
        </div>

        <fieldset style={{marginBottom: "2rem"}} className="text-box mb-15">
          <legend>{DESCRIPTION_LABEL}</legend>
          <textarea
            className="input-text-textarea"
            rows="3"
            cols="50"
            maxLength="250"
            // onChange={(e) => {
            // 	setCampaignDetailsData((prev) => {
            // 		return {
            // 			...prev,
            // 			description: validateDescription(e),
            // 		};
            // 	});
            // }}
          />
        </fieldset>
        <footer className="offer-modal-footer">
          {showComponent === "generate_offer" && (
            <button
              className="btn btn-secondary"
              onClick={() => {
                setShowComponent("create_offer");
              }}
            >
              back
            </button>
          )}

          <button
            className="btn btn-primary continue-setup-btn"
            onClick={() => {
              setShowComponent("generate_offer");
            }}
          >
            <span
              style={{
                display: "flex",
                alignItems: "center",
              }}
              className="mr-8"
            >
            </span>
            Generate Offer
          </button>
        </footer>
      </>
    </div>
  );
};

export default GenerateOfferComponent;
