import { DARK, LIGHT } from "../../tellurideExtComponents/constantComponent/Constants";
import {
  SET_TOASTER_LIST,
  DELETE_TOASTER_LIST,
  SET_THEME_TOGGLE,
  SET_PAGE_LOADED,
} from "./ToasterType";

const initialState = {
  toasterList: [],
  count: 0,
  isDarkTheme: LIGHT,
  pageLoaded: false,
};

export const ToasterReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_TOASTER_LIST:
      action.payload.id = state.count;
      state.count++;
      return {
        ...state,
        toasterList: [...state.toasterList.concat([action.payload])],
      };
    case DELETE_TOASTER_LIST:
      return {
        ...state,
        toasterList: [
          ...state.toasterList.filter((toast) => toast.id !== action.payload),
        ],
      };
    case SET_THEME_TOGGLE:
      return {
        ...state,
        isDarkTheme: action.payload ? DARK : LIGHT,
      };
    case SET_PAGE_LOADED:
      return {
        ...state,
        pageLoaded: action.payload,
      };
    default:
      return state;
  }
};
