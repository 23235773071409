import React, { useEffect, useState } from "react";
import { motion } from "framer-motion/dist/framer-motion";
import { FaRegTimesCircle } from "react-icons/fa";
import { FiSearch } from "react-icons/fi";
import Select from "react-select";
import { getCustomStyles } from "../../../campaignDetailsComponent/CampaignDetailsUtil";
import { images } from "../../../../imagesComponent/Images";
import { getOfferList } from "../../../../../tellurideExtRedux";
import { useDispatch, useSelector } from "react-redux";
import { DARK, OFRI, headers } from "../../../../constantComponent/Constants";
import { skey } from "../../../../utilsComponent/CommonUtil";
import Skeleton from "react-loading-skeleton";
import { emptyData } from "../../../../constantComponent/DesignConstant";
import "./SelectDelayComponent.style.scss";

import {
  BACK,
  CONTENT_ID,
  CONTENT_NAME,
  CONTENT_TYPE_INFO_1,
  CONTENT_TYPE_INFO_2,
  CREATED_BY,
  DEFAULT,
  WAIT,
  EMAIL,
  EMAIL_ERROR_MSG,
  EMAIL_VERIFICATION_REGEX,
  MODIFY,
  PROCEED,
  SEARCH_FOR_CONTENT,
  SENDER_DISPLAY_NAME,
  SENDER_EMAIL,
  SUBJECT_LINE,
  TEMPLATE,
  displayNameTemp,
  days,
  hours,
  minutes,
  ACTION,
} from "../../CampaignBuilderConstants";
import {
  OFFER_SETUP_COMMUNICATION_ERROR,
  OFFER_SETUP_COMMUNICATION_ERROR_QUERY_SELECT,
  OFFER_SETUP_COMMUNICATION_SHOW,
  OFFER_SETUP_COMMUNICATION_SUBJECT_EMAIL_ERROR_MESSAGE,
} from "../../../../offerSetupComponent/offerSetupConstant";
import { duration } from "moment";
import CampaignActionComponent from "../campaignActionComponent/CampaignActionComponent";
import { updateNodeData } from "../../../../utilsComponent/GenericFlow/GenericFlowUtil";
import { extractTimeValues } from "../../CampaignBuilderUtil";
const SelectDelayComponent = ({
  theme,
  setPopupShowProcess,
  currentClickedNode,
  setNodeData,
  setComponentName,
  isDisabled,
}) => {
  const [selectedDelay, setSelectedDelay] = useState({
    duration: currentClickedNode?.data?.displayData?.duration,
  });
  const [delayTime, setDelayTime] = useState(
    selectedDelay.duration ? extractTimeValues(selectedDelay?.duration) : []
  );
  const [delayValue, setDelayValue] = useState({});
  useEffect(() => {
    if (delayTime) {
      let delayString = "";
      delayTime.map((delay, index) => {
        switch (index) {
          case 0:
            delayString += delay ? delay + "d" : "";
            break;
          case 1:
            delayString += delay ? delay + "h" : "";
            break;
          case 2:
            delayString += delay ? delay + "m" : "";
            break;
          default:
            break;
        }
      });

      setSelectedDelay((prev) => {
        return {
          ...prev,
          duration: delayString,
          days: delayTime[0],
          hours: delayTime[1],
          minutes: delayTime[2],
        };
      });
    }
  }, [delayTime]);
  useEffect(() => {
    const eventNodeData = {
      displayData: { duration: selectedDelay.duration },
      value: { action: { duration: selectedDelay.duration } },
    };
    setDelayValue(eventNodeData);
  }, [selectedDelay]);
  return (
    <>
      <div className="content-popup-wrapper select-delay-popup">
        <div className="content-popup-body">
          {/* ---------------------------DAYS---------------------------*/}
          {/* <div>
            <legend>Days</legend>
            <input
              type="text"
              className="attribute-search p-10 mb-35"
              onChange={(e) => {
                const updatedDelayTime = [...delayTime];
                updatedDelayTime[0] = e.target.value;
                setDelayTime(updatedDelayTime);
              }}
            />
          </div> */}
          <div className="dashboard_chart_date_button_container spend-section-fieldset">
            <fieldset
              className="selector-fieldset reward-fieldset dropdown"
              disabled={isDisabled}
            >
              <legend>Days</legend>
              <Select
                name="DATE_RANGE"
                options={days}
                value={{
                  value: delayTime[0] ? delayTime[0] : "",
                  label: delayTime[0] ? delayTime[0] : "",
                }}
                onChange={(e) => {
                  const updatedDelayTime = [...delayTime];
                  updatedDelayTime[0] = e.value;
                  setDelayTime(updatedDelayTime);
                }}
                styles={getCustomStyles(theme)}
                isSearchable={false}
                isDisabled={isDisabled}
              />
            </fieldset>
          </div>
          {/* ---------------------------HOURS--------------------------- */}
          {/* <div>
            <legend>Hours</legend>
            <input
              type="text"
              className="attribute-search p-10  mb-35"
              onChange={(e) => {
                const updatedDelayTime = [...delayTime];
                updatedDelayTime[1] = e.target.value;
                setDelayTime(updatedDelayTime);
              }}
            />
          </div> */}
          <div className="dashboard_chart_date_button_container spend-section-fieldset">
            <fieldset
              className="selector-fieldset reward-fieldset dropdown"
              disabled={isDisabled}
            >
              <legend>Hours</legend>
              <Select
                name="DATE_RANGE"
                options={hours}
                value={{
                  value: delayTime[1] ? delayTime[1] : "",
                  label: delayTime[1] ? delayTime[1] : "",
                }}
                onChange={(e) => {
                  const updatedDelayTime = [...delayTime];
                  updatedDelayTime[1] = e.value;
                  setDelayTime(updatedDelayTime);
                }}
                styles={getCustomStyles(theme)}
                isSearchable={false}
                isDisabled={isDisabled}
              />
            </fieldset>
          </div>
          {/* ---------------------------MINUTES--------------------------- */}
          {/* <div>
            <legend>Minutes</legend>
            <input
              type="text"
              className="attribute-search p-10  mb-35"
              onChange={(e) => {
                const updatedDelayTime = [...delayTime];
                updatedDelayTime[2] = e.target.value;
                setDelayTime(updatedDelayTime);
              }}
            />
          </div> */}
          <div className="dashboard_chart_date_button_container spend-section-fieldset">
            <fieldset
              className="selector-fieldset reward-fieldset dropdown"
              disabled={isDisabled}
            >
              <legend>Minutes</legend>
              <Select
                name="DATE_RANGE"
                options={minutes}
                value={{
                  value: delayTime[2] ? delayTime[2] : "",
                  label: delayTime[2] ? delayTime[2] : "",
                }}
                onChange={(e) => {
                  const updatedDelayTime = [...delayTime];
                  updatedDelayTime[2] = e.value;
                  setDelayTime(updatedDelayTime);
                }}
                styles={getCustomStyles(theme)}
                isSearchable={false}
                isDisabled={isDisabled}
              />
            </fieldset>
          </div>
        </div>
        {/* ------------------END--------------------- */}
        <footer className="campaign-modal-footer">
          <button
            className={`btn btn-primary continue-setup-btn ${
              selectedDelay === "" ? "disable" : ""
              }`}
            disabled={isDisabled}
            //   disabled={selectedContent.contentId === "" ? true : false}
            onClick={() => {
              if (isDisabled) {
                return;
              }
              updateNodeData(
                currentClickedNode,
                setNodeData,
                "WAIT",
                setPopupShowProcess,
                delayValue
              );
            }}
          >
            {currentClickedNode?.newNode ? PROCEED : MODIFY}
          </button>
          {currentClickedNode?.newNode && (
            <button
              className="btn btn-secondary"
              onClick={() => {
                setComponentName({
                  name: CampaignActionComponent,
                  displayName: ACTION,
                });
              }}
            >
              {BACK}
            </button>
          )}
        </footer>
      </div>
    </>
  );
};

export default SelectDelayComponent;
