export const SEGMENT_LIST_COLUMNS = [
	{
		title: "Segment Name",
		key: "groupName",
		type: "string",
		visible: true,
		filter: true,
		sort: true,
		advanceFilter: true,
		minWidth: 150,
		initialWidth: 150,
		defaultWidth: 150,
		resizable: true,
		reqBodyKey: { attrName: "name", isAllowed: true },
	},
	{
		title: "Description",
		key: "description",
		type: "string",
		visible: true,
		filter: true,
		sort: true,
		advanceFilter: true,
		minWidth: 180,
		initialWidth: 180,
		defaultWidth: 180,
		resizable: true,
		reqBodyKey: { attrName: "description", isAllowed: true },
	},
	{
		title: "Type",
		key: "groupType",
		type: "select",
		visible: true,
		filter: true,
		sort: true,
		multi: true,
		options: ["Static", "Data Driven", "Batch"],
		advanceFilter: true,
		minWidth: 80,
		initialWidth: 80,
		defaultWidth: 80,
		reqBodyKey: { attrName: "type", isAllowed: true },
		rights: [
			{
			  key: "Static",
			  rights: ["filter","segmentation","viewsegmentation","type","static"],
			},
			{
			  key: "Data Driven",
			  rights: ["filter","segmentation","viewsegmentation","type","datadriven"],
			},
			{
			  key: "Batch",
			  rights: ["filter","segmentation","viewsegmentation","type","batch"],
			},
		  ],
	},
	{
		title: "Member Count",
		key: "countInTheList",
		type: "string",
		visible: true,
		filter: false,
		sort: true,
		minWidth: 80,
		initialWidth: 80,
		defaultWidth: 80,
		reqBodyKey: { attrName: "", isAllowed: false },
	},
	{
		title: "Linked Offers",
		key: "linkedOffers",
		type: "string",
		visible: false,
		filter: false,
		sort: true,
		minWidth: 80,
		initialWidth: 80,
		defaultWidth: 80,
		reqBodyKey: { attrName: "", isAllowed: false },
	},
	{
		title: "Modified By",
		key: "modifiedBy",
		type: "string",
		visible: true,
		filter: true,
		sort: true,
		advanceFilter: true,
		minWidth: 140,
		initialWidth: 140,
		defaultWidth: 140,
		reqBodyKey: { attrName: "lastModifiedBy", isAllowed: true },
	},
	{
		title: "Modified Date",
		key: "lastModifiedDate",
		type: "string",
		visible: true,
		filter: false,
		sort: true,
		minWidth: 110,
		initialWidth: 110,
		defaultWidth: 110,
		reqBodyKey: { attrName: "", isAllowed: false },
	},
];

export const SEGMENT_CONTENT_LIST_COLUMNS = [
	{
		title: "Member Number",
		key: "memberId",
		type: "string",
		visible: true,
		filter: false,
		sort: false,
		advanceFilter: false,
		minWidth: 150,
		initialWidth: 150,
		defaultWidth: 150,
		resizable: true,
	},
	{
		title: "Created By",
		key: "createdBy",
		type: "string",
		visible: true,
		filter: false,
		sort: false,
		advanceFilter: false,
		minWidth: 180,
		initialWidth: 180,
		defaultWidth: 180,
		resizable: true,
	},
	{
		title: "Created Date",
		key: "createdDate",
		type: "string",
		visible: true,
		filter: false,
		sort: false,
		advanceFilter: false,
		minWidth: 180,
		initialWidth: 180,
		defaultWidth: 180,
	},
];
export const CREATE_SEGMENT_BUTTON = "Create New Segment";
export const SEGMENT_SEARCH_TEXT = "Search Segments";
export const VIEW_SEGMENT = "VIEW_SEGMENT";
export const SEGMENT_SEARCH_MEMBER_TEXT = "Search Member";
export const SEGMENT_LIST_REQ_BODY_KEY = "search";
export const SEGMENT_LIST_RES_BODY_KEY = "groupList";
