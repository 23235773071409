import { useEffect, useState } from "react";
import "./Rules.style.scss";

import {
	BASE_REDEEM_RULE_SCREEN_HEADER,
	INITIAL_DEFAULT_RULES,
	RULE_SCREEN_HEADER,
	ZERO,
	nonpurchase_entites_options,
} from "../../utilsComponent/GenericRules/GenericRulesConstants.js";
import { useDispatch, useSelector } from "react-redux";
import {
	updateRuleData,
} from "../../../tellurideExtRedux/offerDetails/OfferDetailsAction";
import {
	BASE_REDEEM,
	NON_PURCHASE,
	OFFER_DETAILS_STATUS_DRAFT,
} from "../../constantComponent/Constants.js";
import GenericRules from "../../utilsComponent/GenericRules/GenericRules.jsx";
import { checkFilterEmpty, convertJSON, convertToInitialJSON } from "../../utilsComponent/GenericRules/GenericRulesUtil.js";

export default function Rules({disabled}) {
	const offerData = useSelector((state) => state.offerDetailsData);
	const offerType = offerData?.offerData?.offerType;
	const [isSetDisable, setDisable] = useState(disabled);

	const initialRules = useSelector((state) => state.offerDetailsData.ruleData);
    const dispatch = useDispatch();
	const [expression, setExpression] = useState(
		Object.keys(initialRules).length > ZERO
			? convertToInitialJSON(initialRules?.rule[0]?.condition)
			: convertToInitialJSON(INITIAL_DEFAULT_RULES)
	);
	useEffect(() => {
		if (!checkFilterEmpty(expression[0].filters)) {
			dispatch(updateRuleData(convertJSON(expression)));
		} 
	}, [dispatch, expression]);

	// useEffect(() => {
	// 	setDisable(
	// 		offerData?.offerStatus !== OFFER_DETAILS_STATUS_DRAFT ? true : false || disabled
	// 	);
	// }, [offerData?.offerStatus,disabled]);
	const metaData = useSelector((state) => state.metaData);


	return (
		<div className="rule-screen-container content-section">
			<h2 className="rule-screen-header setup-page-section-title">
				{offerType === BASE_REDEEM
					? BASE_REDEEM_RULE_SCREEN_HEADER
					: RULE_SCREEN_HEADER}
			</h2>
			<GenericRules
				expression={expression}
				setExpression={setExpression}
				isSetDisable={disabled}
				entitesOptions={metaData?.entitiesResponse.entities}
			/>
		</div>
	);
}
