import "./AttributeWrapper.style.scss";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../../LoaderComponents/Loading";
import { ErrorBoundary } from "react-error-boundary";
import Error from "../../../tellurideExtComponents/errorsComponent/Error";
import { useErrorBoundary } from "react-error-boundary";
import { LIST, OFRI, SUCCESS } from "../../constantComponent/Constants.js";
import {
  getEntitiesDetails,
  getSingleAttributeDetails,
  resetAttributeDetailsResponse,
  resetCreateAttributeResponse,
  setAttributeDetailsLoading,
} from "../../../tellurideExtRedux";
import { headers } from "../../constantComponent/Constants";
import { useNavigate } from "react-router-dom";
import {
  checkRightsIsEditable,
  checkRightsIsVisible,
  getAllRights,
  getRightKey,
  getRightsFromSession,
  getRightsKey,
  skey,
} from "../../utilsComponent/CommonUtil.js";
import AttributeDetails from "../attributeDetailsComponent/AttributeDetails.jsx";
import AttributeOptions from "../attributeOptionsComponent/AttributeOptions.jsx";
import AttributeValidation from "../attributeValidationComponent/AttributeValidation";
import { ATTRIBUTE_LOADER_SOURCE } from "../../LoaderComponents/LoadingConstant";
import AttributeHeader from "../attributeHeaderComponent/AttributeHeader";
import  UnauthorizedAccess  from "../../unauthorizeComponent/UnauthorizeWrapper.jsx";

function AttributeWrapper() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { showBoundary } = useErrorBoundary();
  const securityData = useSelector((state) => state.securityData);
  const metaData = useSelector((state) => state.metaData);
  const offerDetailsPageData = useSelector((state) => state.offerDetailsData);
  const updateAttributeDetailsData = metaData.updateAttributeDetailsData;
  const queryString = require("query-string");
  const attrCode = queryString.parse(window.location.search).code;
  const [isDisabled, setIsDisabled] = useState(
    !checkRightsIsEditable(
      getRightsKey(["configure", "attribute", "detail", "setup"])
    )
  );

  const [showOptions, setShowOptions] = useState(
    updateAttributeDetailsData?.type?.toLowerCase() === LIST ? true : false
  );
  const initialAttrSaveData = useSelector(
    (state) => state.metaData.getAttributeDetailsResponse
  );
  const [attributeSetupValidation, setAttributeSetupValidation] = useState({
    name: {},
    type: {},
    entity: {},
    description: {},
    min: {},
    max: {},
    options: {},
  });
  useEffect(() => {
    if (initialAttrSaveData?.status === SUCCESS) {
      if (
        initialAttrSaveData.attribute?.type &&
        initialAttrSaveData.attribute?.type.toLowerCase() === LIST
      ) {
        setShowOptions(true);
      } else {
        setShowOptions(false);
      }
    }
  }, [initialAttrSaveData]);

  useEffect(() => {
    if (updateAttributeDetailsData?.type?.toLowerCase() === LIST) {
      setShowOptions(true);
      setAttributeSetupValidation((prev) => {
        return {
          ...prev,
          min: {
            ...prev.min,
            status: false,
          },
          max: {
            ...prev.max,
            status: false,
          },
        };
      });
    } else {
      setShowOptions(false);
    }
  }, [updateAttributeDetailsData.type]);

  useEffect(() => {
    headers.sessionId = skey();
    headers.requestAccess = getRightsFromSession();
    headers.organization = offerDetailsPageData?.authOrganizationName;
    const requestHeaders = {
      headers,
    };
    const entitiesRequestBody = {
      requestorID: OFRI,
      messageVersion: process.env.REACT_APP_MESSAGE_VERSION_01,
    };
    dispatch(
      getEntitiesDetails(showBoundary, entitiesRequestBody, requestHeaders)
    );
  }, []);
  useEffect(() => {
    headers.sessionId = skey();
    headers.requestAccess = getRightsFromSession();
    headers.organization = offerDetailsPageData?.authOrganizationName;
    const requestHeaders = {
      headers,
    };
    const requestBody = {
      requestorID: OFRI,
      messageVersion: process.env.REACT_APP_MESSAGE_VERSION_03,
      code: attrCode,
    };
    if (attrCode || attrCode?.length === 0 || attrCode === null) {
      setIsDisabled(true);
      dispatch(
        getSingleAttributeDetails(requestBody, requestHeaders, navigate)
      );
    } else {
      dispatch(setAttributeDetailsLoading(false));
      dispatch(resetAttributeDetailsResponse());
    }
    return () => {
      dispatch(resetAttributeDetailsResponse());
      dispatch(resetCreateAttributeResponse());
    };
  }, []);
  const toasterData = useSelector((state) => state.toasterData);
  const [theme, setTheme] = useState(toasterData.isDarkTheme);
  useEffect(() => {
    setTheme(toasterData.isDarkTheme);
  }, [toasterData]);
  return (
	<>
        {checkRightsIsVisible(
          getRightsKey(["configure", "attribute", "detail", "setup"])
        ) ? (
    <ErrorBoundary FallbackComponent={Error} onError={(error) => {}}>
      <Loading source={ATTRIBUTE_LOADER_SOURCE}>
          <div className="page-wrapper attribute-wrapper">
            <AttributeHeader
              isDisabled={isDisabled}
              setIsDisabled={setIsDisabled}
              attributeSetupValidation={attributeSetupValidation}
              setAttributeSetupValidation={setAttributeSetupValidation}
              theme={theme}
            />
            <section className="display-flex attribute-non-flex">
              <div className="inner-section-wrapper">
                <AttributeDetails
                  isDisabled={isDisabled}
                  attributeSetupValidation={attributeSetupValidation}
                  setAttributeSetupValidation={setAttributeSetupValidation}
                  theme={theme}
                />
              </div>
              <div className="sticky-right">
                {showOptions === true ? (
                  <AttributeOptions
                    isDisabled={isDisabled}
                    attributeSetupValidation={attributeSetupValidation}
                    setAttributeSetupValidation={setAttributeSetupValidation}
                    theme={theme}
                  />
                ) : (
                  <AttributeValidation
                    isDisabled={isDisabled}
                    setIsDisabled={setIsDisabled}
                    attributeSetupValidation={attributeSetupValidation}
                    setAttributeSetupValidation={setAttributeSetupValidation}
                    theme={theme}
                  />
                )}
              </div>
            </section>
          </div>
		  </Loading>
		  </ErrorBoundary>
		  ) : (
			<UnauthorizedAccess />
		  )}
		  </>
  );
}
export default AttributeWrapper;
