export const OFFER_DETAILS_TITLE = "Offer details";
export const OFFER_ID_LABEL = "Offer ID";
export const OFFER_ID_INFO = "System-generated number for the offer.";
export const OFFER_TYPE_LABEL = "Offer Type";
export const OFFER_CATEGORY_LABEL = "Offer Category";
export const OFFER_SUBTYPE_LABEL = "Offer SubType";
export const START_DATE_LABEL = "Start Date";
export const START_DATE_INFO = "Date, Offer will be active from.";
export const END_DATE_LABEL = "End Date";
export const END_DATE_INFO = "Date, Offer will stop being awarded.";
export const DESCRIPTION_LABEL = "Description";
export const OFFER_START_DATE = "offerStartDate";
export const OFFER_END_DATE = "offerEndDate";
export const CTA_URL_DATA =
  "Clicking on email, member will be redirected to this URL.";
export const OFFER_CATEGORY_OPTIONS = [
  { label: "Stackable", value: "Stackable" },
  { label: "Non Stackable", value: "Non Stackable" },
  { label: "Always Earn", value: "Always Applicable" },
];
export const EVENT_OFFER_SUBTYPE_OPTIONS = [
  { value: "REWARDS", label: "REWARDS" },
  { value: "CARDLINK", label: "CARDLINK" },
  { value: "LOADCOUPON", label: "LOADCOUPON" },
  { value: "EXTN", label: "EXTN" },
  { value: "VERIFY", label: "VERIFY" },
  { value: "CHECKOUT", label: "CHECKOUT" },
];
export const OFFER_CATEGORY_OPTIONS_ALIAS = {
  "Stackable" : "Stackable",
  "Non Stackable" : "Non Stackable",
  "Always Applicable": "Always Earn"
}