export const GET_CHART_CAMPAIGN_STATUS_REQUEST =
  "GET_CHART_CAMPAIGN_STATUS_REQUEST";
export const GET_CHART_CAMPAIGN_STATUS_SUCCESS =
  "GET_CHART_CAMPAIGN_STATUS_SUCCESS";
export const GET_CHART_CAMPAIGN_STATUS_FAILURE =
  "GET_CHART_CAMPAIGN_STATUS_FAILURE";
export const GET_CHART_CAMPAIGN_STATUS_SUCCESS_FAILURE =
  "GET_CHART_CAMPAIGN_STATUS_SUCCESS_FAILURE";

export const GET_CHART_ENGAGEMENT_STATUS_REQUEST =
  "GET_CHART_ENGAGEMENT_STATUS_REQUEST";
export const GET_CHART_ENGAGEMENT_STATUS_SUCCESS =
  "GET_CHART_ENGAGEMENT_STATUS_SUCCESS";
export const GET_CHART_ENGAGEMENT_STATUS_FAILURE =
  "GET_CHART_ENGAGEMENT_STATUSS_FAILURE";
export const GET_CHART_ENGAGEMENT_STATUS_SUCCESS_FAILURE =
  "GET_CHART_ENGAGEMENT_STATUS_SUCCESS_FAILURE";

export const UPDATE_CAMPAIGN_STATUS_REQUEST = "UPDATE_CAMPAIGN_STATUS_REQUEST";
export const UPDATE_CAMPAIGN_STATUS_SUCCESS = "UPDATE_CAMPAIGN_STATUS_SUCCESS";
export const UPDATE_CAMPAIGN_STATUS_FAILURE = "UPDATE_CAMPAIGN_STATUS_FAILURE";
export const UPDATE_CAMPAIGN_STATUS_SUCCESS_FAILURE =
  "UPDATE_CAMPAIGN_STATUS_SUCCESS_FAILURE";

export const SET_SESSION_TIMEOUT_POPUP = "SET_SESSION_TIMEOUT_POPUP";
export const SET_BLANK_SESSION_ID_ERROR = "SET_BLANK_SESSION_ID_ERROR";
export const SET_CAMPAIGN_OFFER_CHANGE_STATUS =
  "SET_CAMPAIGN_OFFER_CHANGE_STATUS";

export const GET_CAMPAIGN_PERFORMANCE_LIST_REQUEST =
  "GET_CAMPAIGN_PERFORMANCE_LIST_REQUEST";
export const GET_CAMPAIGN_PERFORMANCE_LIST_SUCCESS =
  "GET_CAMPAIGN_PERFORMANCE_LIST_SUCCESS";
export const GET_CAMPAIGN_PERFORMANCE_LIST_FAILURE =
  "GET_CAMPAIGN_PERFORMANCE_LIST_FAILURE";
export const GET_CAMPAIGN_PERFORMANCE_LIST_SUCCESS_FAILURE =
  "GET_CAMPAIGN_PERFORMANCE_LIST_SUCCESS_FAILURE";

export const GET_VARIATION_STATS_REQUEST = "GET_VARIATION_STATS_REQUEST";
export const GET_VARIATION_STATS_SUCCESS = "GET_VARIATION_STATS_SUCCESS";
export const GET_VARIATION_STATS_FAILURE = "GET_VARIATION_STATS_FAILURE";
export const GET_VARIATION_STATS_SUCCESS_FAILURE =
  "GET_VARIATION_STATS_SUCCESS_FAILURE";

export const SAVE_WINNER_REQUEST = "SAVE_WINNER_REQUEST";
export const SAVE_WINNER_SUCCESS = "SAVE_WINNER_SUCCESS";
export const SAVE_WINNER_FAILURE = "SAVE_WINNER_FAILURE";
export const SAVE_WINNER_SUCCESS_FAILURE = "SAVE_WINNER_FAILURE";

export const LAUNCH_CAMPAIGN_OFFER_REQUEST = "LAUNCH_CAMPAIGN_OFFER_REQUEST";
export const LAUNCH_CAMPAIGN_OFFER_SUCCESS = "LAUNCH_CAMPAIGN_OFFER_SUCCESS";
export const LAUNCH_CAMPAIGN_OFFER_FAILURE = "LAUNCH_CAMPAIGN_OFFER_FAILURE";
export const LAUNCH_CAMPAIGN_OFFER_SUCCESS_FAILURE =
  "LAUNCH_CAMPAIGN_OFFER_FAILURE";

export const AB_TEST_START_REQUEST = "AB_TEST_START_REQUEST";
export const AB_TEST_START_SUCCESS = "AB_TEST_START_SUCCESS";
export const AB_TEST_START_FAILURE = "AB_TEST_START_FAILURE";
export const AB_TEST_START_SUCCESS_FAILURE = "AB_TEST_START_FAILURE";
