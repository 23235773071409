import React from "react";
import "./Loading.style.scss";
import { useSelector } from "react-redux";
const AuthLoading = ({ loginRedirectLoading = false, children }) => {
  const securityData = useSelector((state) => state.securityData);
  return securityData.authLoading === true ||
    securityData.authLogoutLoading ||
    loginRedirectLoading ? (
    <div className="loader-position">
      <div className="custom-loader" />
    </div>
  ) : (
    children
  );
};

export default AuthLoading;
