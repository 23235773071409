export const RULE_SCREEN_HEADER = "When to award";
export const BASE_REDEEM_RULE_SCREEN_HEADER = "When to redeem";
export const FILTER_HEADER = "of the below criteria matches";

export const SUBGROUP = "subgroup";
export const RULE = "rule";
export const ANY = "ANY";
export const ALL = "ALL";
export const PASSES = "PASSES";
export const COMBINATION_DROPDOWN = "combination-dropdown";
export const JOIN_DROPDOWN = "join-dropdown";
export const ENTITY_DROPDOWN = "entity-dropdown";
export const ATTRIBUTE_DROPDOWN = "attribute-dropdown";
export const OPERATOR_DROPDOWN = "operator-dropdown";
export const ATTRIBUTE_VALUE_INPUT = "attribute-value-input";

export const ID = "id";
export const COMBINATION = "combination";
export const JOIN = "join";
export const ATTRIBUTE = "attribute";
export const ATTR = "attr";
export const NAME = "NAME";
export const ENTITY = "entity";
export const OPERATOR = "operator";
export const VALUE = "value";
export const DROPDOWN = "dropdown";

export const PREVIOUS = "previous";
export const NEXT = "next";
export const ATTRIBUTE_TITLE = "Attribute Name";
export const DESCRIPTION_TITLE = "Description";
export const CODE_TITLE_ATTRIBUTE = "Attribute";
export const SEGMENT_TITLE = "Value";
export const COUNT = "Count";
export const ATTRIBUTE_MODAL_HEADER =
  "Choose an attribute to set up the rule on";
export const MEMBER_GROUP_HEADER = "Choose a value to set up the rule on";
export const ZERO = 0;
export const DISABLED = "disabled";
export const ACTIVE = "active";
export const SEMI_ACTIVE = "semi-active";
export const PREVIOUS_BTN = "Previous";
export const NEXT_BTN = "Next";
export const POPOVER_MENU_ITEM_1 = "Add subgroup";
export const POPOVER_MENU_ITEM_2 = "Add rule";

export const ENTITY_DEFAULT_VALUE = "Select entity";
export const ATTR_DEFAULT_VALUE = "Select attribute";
export const OPERATOR_DEFAULT_VALUE = "Select operator";
export const ATTR_INPUT = "Enter value";
export const VALUE_DEFAULT_VALUE = "";
export const SUBGROUP_OPTIONS = ["ALL", "ANY"];
export const OBJECT = "object";
export const DATE = "date";
export const NUMBER = "number";
export const POPUP = "popup";
export const STRING = "string";
export const LIST = "list";
export const INPUT_LIST_OPTIONS = "INPUT_LIST_OPTIONS";
export const TYPE = "TYPE";
export const ASCENDING = "ascending";
export const DESCENDING = "descending";
export const SORTED = "sorted";
export const PLEASE_WAIT_MSG = "Please wait...";
export const EMPTY_ERROR = "empty-error";
export const RULE_SECTION = "Rule";
export const STRING_INPUT_LIMIT = 30;
export const NUMBER_INPUT_LIMIT = 7;

export const INITIAL_DEFAULT_RULES = {
  PASSES_ALL: [],
};
export const Operator = [
  {
    datatype: "String",
    operators: ["==", "!=","LIKE", "NOTLIKE"],
  },
  {
    datatype: "Number",
    operators: ["==", "!=", "<", ">", "<=", ">="],
  },
  {
    datatype: "Date",
    operators: ["==", "!=", "<", ">", "<=", ">="],
  },
  {
    datatype: "List",
    // operators: ["==", "!=", "IN", "NOTIN"],
    operators: ["IN", "NOTIN"],
  },
];
export const multi_select_operator = [
  {
    attribute: "memberGroup",
    operators: ["IN", "NOTIN"],
  },
  {
    attribute: "affinities",
    operators: ["IN", "NOTIN"],
  },
];
export const multi_operators = ["IN", "NOTIN"];
export const multi_attributes = ["memberGroup", "affinities"];
export const MEMBERGROUP = "memberGroup";

export const nonpurchase_entites_options = ["member"];

export const segment_group_model_columns = [
  {
    title: "Segment",
    key: "groupName",
    type: "string",
    visible: true,
    rowConfig: {
      data: "-",
    },
  },
  {
    title: "Created By",
    key: "createdBy",
    type: "string",
    visible: true,
    rowConfig: {
      data: "-",
    },
  },
  {
    title: "Member Count",
    key: "countInTheList",
    type: "string",
    visible: true,
    rowConfig: {
      data: "-",
    },
  },
];

export const popupConfig = {
  tootltip: true,
  theme: "",
  selectedDataKey: "",
  isDisabled: false,
};

export const attribute_modal_columns = [
  {
    title: "Attribute",
    key: "name",
    subKey: "code",
    sortKey: "name",
    type: "string",
    sort: true,
    visible: true,
    rowConfig: {
      data: "-",
    },
  },
  {
    title: "Description",
    key: "description",
    sortKey: "description",
    type: "string",
    sort: false,
    visible: true,
    rowConfig: {
      data: "-",
    },
  },
];
