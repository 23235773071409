import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import ErrorBoundary from "antd/es/alert/ErrorBoundary";
import ConnectorsListComponent from "../connectorsListComponent/ConnectorsListComponent";
import { CONNECTOR_LOADING_SOURCE } from "../../LoaderComponents/LoadingConstant";
import Loading from "../../LoaderComponents/Loading";
import CreateConnectorPopup from "../createConnectorPopup/CreateConnectorPopup";
import SetupConnectorComponent from "../setupConnectorComponent/SetupConnectorComponent";

const ConnectorsWrapperComponent = ({setRedirectionHeader}) => {
  //theme
  const toasterData = useSelector((state) => state.toasterData);
  const [theme, setTheme] = useState(toasterData.isDarkTheme);
  useEffect(() => {
    setTheme(toasterData.isDarkTheme);
    setRedirectionHeader("Configure Connectors")
  }, [toasterData]);
  //theme
  const [isShowCreatePopup, setIsShowCreatePopup] = useState(false);
  const [currentClickedData, setCurrentClickedData] = useState("");

  return (
    <ErrorBoundary FallbackComponent={Error} onError={(error) => {}}>
        <SetupConnectorComponent
          setIsShowCreatePopup={setIsShowCreatePopup}
          setCurrentClickedData={setCurrentClickedData}
        />
        <ConnectorsListComponent
          theme={theme}
          setIsShowCreatePopup={setIsShowCreatePopup}
          setCurrentClickedData={setCurrentClickedData}
        />
        {isShowCreatePopup && (
          <CreateConnectorPopup
            setIsShowCreatePopup={setIsShowCreatePopup}
            currentClickedData={currentClickedData}
          />
        )}
    </ErrorBoundary>
  );
};

export default ConnectorsWrapperComponent;
