import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { AnimatePresence } from "framer-motion/dist/framer-motion";
import { calculateSubgroup, handleResize } from "./GenericRulesUtil";
import "./GenericRules.style.scss";
import RulesReader from "./GenericRulesReader";
import { AttributeModal } from "./AttributeModal";

function GenericRules({
	expression,
	setExpression,
	isSetDisable,
	entitesOptions,
	hideButtons = false,
}) {
	const [editProcess, setEditProcess] = useState({
		status: false,
		id: null,
		filterType: null,
	});
	const [attributeModal, setAttributeModal] = useState({
		status: false,
		id: null,
		entityValue: null,
		selectedAttr: null,
	});
	const [subgroupCount, setSubgroupCount] = useState(0);
	const containerRef = useRef();
	const filtersRef = useRef();

	useLayoutEffect(() => {
		handleResize(containerRef, filtersRef);
		window.addEventListener("resize", () =>
			handleResize(containerRef, filtersRef)
		);
	}, []);
	useEffect(() => {
		setSubgroupCount(0);
		calculateSubgroup(expression[0], setSubgroupCount);
	}, [expression]);
	useEffect(() => {
		if (subgroupCount >= 4) {
			containerRef.current.style.overflowX = "auto";
		} else {
			containerRef.current.style.overflowX = "unset";
		}
	}, [subgroupCount]);

	return (
		<div className="rule-screen-wrapper generic-rule" ref={containerRef}>
			<div className="rule-screen" ref={filtersRef}>
				<RulesReader
					expression={expression}
					setExpression={setExpression}
					editProcess={editProcess}
					setEditProcess={setEditProcess}
					setAttributeModal={setAttributeModal}
					updatedExpression={expression}
					isSetDisable={isSetDisable}
					entitesOptions={entitesOptions}
					hideButtons={hideButtons}
				/>
				<AnimatePresence>
					{attributeModal.status === true && (
						<AttributeModal
							attributeModal={attributeModal}
							setAttributeModal={setAttributeModal}
							setExpression={setExpression}
						/>
					)}
				</AnimatePresence>
			</div>
		</div>
	);
}

export default GenericRules;
