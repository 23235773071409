export const GET_OFFER_DETAILS_REQUEST = "GET_OFFER_DETAILS_REQUEST";
export const GET_OFFER_DETAILS_SUCCESS = "GET_OFFER_DETAILS_SUCCESS";
export const GET_OFFER_DETAILS_FAILURE = "GET_OFFER_DETAILS_FAILURE";
export const GET_OFFER_DETAILS_SUCCESS_FAILURE =
  "GET_OFFER_DETAILS_SUCCESS_FAILURE";
export const SET_REWARD_DATA = "SET_REWARD_DATA";
export const SET_RULE_DATA = "SET_RULE_DATA";
export const SET_OFFER_DATA = "SET_OFFER_DATA";
export const SET_LINKED_DATA = "SET_LINKED_DATA";
export const SET_COMMUNICATE_DATA = "SET_COMMUNICATE_DATA";
export const SET_OFFER_ATTRIBUTE_DATA = "SET_OFFER_ATTRIBUTE_DATA";
export const SET_CAP_DATA = "SET_CAP_DATA";
export const SET_EVENT_OFFER_RULE_DATA = "SET_EVENT_OFFER_RULE_DATA";
export const SET_EVENT_OFFER_ACTION_DATA = "SET_EVENT_OFFER_ACTION_DATA";

export const CREATE_UPDATE_OFFER_REQUEST = "CREATE_UPDATE_OFFER_REQUEST";
export const CREATE_UPDATE_OFFER_SUCCESS = "CREATE_UPDATE_OFFER_SUCCESS";
export const CREATE_UPDATE_OFFER_SUCCESS_FAILURE =
  "CREATE_UPDATE_OFFER_SUCCESS_FAILURE";
export const CREATE_UPDATE_OFFER_FAILURE = "CREATE_UPDATE_OFFER_FAILURE";
export const UPDATE_VALIDATION_ERRORS = "UPDATE_VALIDATION_ERRORS";
export const RESET_VALIDATION_ERRORS = "RESET_VALIDATION_ERRORS";

export const UPDATE_OFFER_STATUS_REQUEST = "CREATE_UPDATE_OFFER_STATUS_REQUEST";
export const UPDATE_OFFER_STATUS_SUCCESS = "CREATE_UPDATE_OFFER_STATUS_SUCCESS";
export const UPDATE_OFFER_STATUS_SUCCESS_FAILURE =
  "CREATE_UPDATE_OFFER_STATUS_SUCCESS_FAILURE";
export const UPDATE_OFFER_STATUS_FAILURE = "CREATE_UPDATE_OFFER_STATUS_FAILURE";

export const GET_OFFER_LIST_REQUEST = "GET_OFFER_LIST_REQUEST";
export const GET_OFFER_LIST_SUCCESS = "GET_OFFER_LIST_SUCCESS";
export const GET_OFFER_LIST_SUCCESS_FAILURE = "GET_OFFER_LIST_SUCCESS_FAILURE";
export const GET_OFFER_LIST_FAILURE = "GET_OFFER_LIST_FAILURE";
export const SET_UPDATE_STATUS_LOADED = "SET_UPDATE_STATUS_LOADED";

export const UPLOAD_IMAGE_CONTENT_REQUEST = "UPLOAD_IMAGE_CONTENT_REQUEST";
export const UPLOAD_IMAGE_CONTENT_SUCCESS = "UPLOAD_IMAGE_CONTENT_SUCCESS";
export const UPLOAD_IMAGE_CONTENT_SUCCESS_FAILURE =
  "UPLOAD_IMAGE_CONTENT_SUCCESS_FAILURE";
export const UPLOAD_IMAGE_CONTENT_FAILURE = "UPLOAD_IMAGE_CONTENT_FAILURE";

export const DELETE_IMAGE_CONTENT_REQUEST = "DELETE_IMAGE_CONTENT_REQUEST";
export const DELETE_IMAGE_CONTENT_SUCCESS = "DELETE_IMAGE_CONTENT_SUCCESS";
export const DELETE_IMAGE_CONTENT_SUCCESS_FAILURE =
  "DELETE_IMAGE_CONTENT_SUCCESS_FAILURE";
export const DELETE_IMAGE_CONTENT_FAILURE = "DELETE_IMAGE_CONTENT_FAILURE";

export const LOAD_IMAGE_CONTENT_REQUEST = "LOAD_IMAGE_CONTENT_REQUEST";
export const LOAD_IMAGE_CONTENT_SUCCESS = "LOAD_IMAGE_CONTENT_SUCCESS";
export const LOAD_IMAGE_CONTENT_SUCCESS_FAILURE =
  "LOAD_IMAGE_CONTENT_SUCCESS_FAILURE";
export const LOAD_IMAGE_CONTENT_FAILURE = "LOAD_IMAGE_CONTENT_FAILURE";

export const PREVIEW_IMAGE_CONTENT_REQUEST = "PREVIEW_IMAGE_CONTENT_REQUEST";
export const PREVIEW_IMAGE_CONTENT_SUCCESS = "PREVIEW_IMAGE_CONTENT_SUCCESS";
export const PREVIEW_IMAGE_CONTENT_SUCCESS_FAILURE =
  "PREVIEW_IMAGE_CONTENT_SUCCESS_FAILURE";
export const PREVIEW_IMAGE_CONTENT_FAILURE = "PREVIEW_IMAGE_CONTENT_FAILURE";

export const SEND_EMAIL_REQUEST = "SEND_EMAIL_REQUEST";
export const SEND_EMAIL_SUCCESS = "SEND_EMAIL_SUCCESS";
export const SEND_EMAIL_SUCCESS_FAILURE = "SEND_EMAIL_SUCCESS_FAILURE";
export const SEND_EMAIL_FAILURE = "SEND_EMAIL_FAILURE";
export const SET_OFFER_CHANGE_STATUS = "SET_OFFER_CHANGE_STATUS";

export const SET_ORGANZTATION_NAME = "SET_ORGANZTATION_NAME";
export const SET_AUTH_ORGANZTATION_NAME = "SET_AUTH_ORGANZTATION_NAME";

export const SET_UPLOAD_IMAGE = "SET_UPLOAD_IMAGE";
export const SET_BLANK_SESSION_ID_ERROR = "SET_BLANK_SESSION_ID_ERROR";
export const SET_SESSION_TIMEOUT_POPUP = "SET_SESSION_TIMEOUT_POPUP";

export const SET_OFFER_VALIDATION_ERRORS = "SET_OFFER_VALIDATION_ERRORS";


export const CREATE_UPDATE_CONTENT_OFFER_REQUEST = "CREATE_UPDATE_CONTENT_OFFER_REQUEST";
export const CREATE_UPDATE_CONTENT_OFFER_SUCCESS = "CREATE_UPDATE_CONTENT_OFFER_SUCCESS";
export const CREATE_UPDATE_CONTENT_OFFER_SUCCESS_FAILURE =
  "CREATE_UPDATE_CONTENT_OFFER_SUCCESS_FAILURE";
export const CREATE_UPDATE_CONTENT_OFFER_FAILURE = "CREATE_UPDATE_CONTENT_OFFER_FAILURE";
export const RESET_UPLOAD_IMAGE_DATA = "RESET_UPLOAD_IMAGE_DATA";
export const SET_EVENT_PROCESSING_ERROR = "SET_EVENT_PROCESSING_ERROR";
export const GET_ACTION_VERSION_JSON_REQUEST = "GET_ACTION_VERSION_JSON_REQUEST";
export const GET_ACTION_VERSION_JSON_SUCCESS = "GET_ACTION_VERSION_JSON_SUCCESS";
export const GET_ACTION_VERSION_JSON_FAILURE = "GET_ACTION_VERSION_JSON_FAILURE";
export const SET_ACTION_JSON = "SET_ACTION_JSON";
export const RESET_ACTION_JSON_RESPONSE = "RESET_ACTION_JSON_RESPONSE";

export const GET_REWARD_OFFER_CODE_REQUEST = "GET_REWARD_OFFER_CODE_REQUEST";
export const GET_REWARD_OFFER_CODE_SUCCESS = "GET_REWARD_OFFER_CODE_SUCCESS";
export const GET_REWARD_OFFER_CODE_FAILURE = "GET_REWARD_OFFER_CODE_FAILURE";
export const GET_REWARD_OFFER_CODE_SUCCESS_FAILURE = "GET_REWARD_OFFER_CODE_SUCCESS_FAILURE";