import { campaignPopupJson } from "../../constantComponent/Constants";
import "../Backdrop.style.scss";
import "./ConfirmationPopUpComponent.style.scss";
import { motion } from "framer-motion/dist/framer-motion";

const ConfirmationPopUpComponent = (props) => {
  return (
    <>
      <div className="activate-confirmation-model">
        <div id="backdrop" className="backdrop fixed-position-backdrop show">
          <motion.div
            className="modal"
            initial={{ opacity: 0, scale: 0 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.85 }}
          >
            {/* <div className="modal-header header-text icon-btn">
                            <span className="icon" onClick={(e) => {
                                props.onDeactiveClick(false);
                            }}>
                                <IoClose />
                            </span>
                        </div> */}
            {props?.isOfferChanged ? (
              <section className="modal-body">
                <div className="flex center body">
                  {/* to be changed */}
                  <p>{campaignPopupJson[props.statusKey]?.bodyTextSave}</p>
                </div>
              </section>
            ) : (
              <section className="modal-body">
                <div className="flex center body">
                  <p>{campaignPopupJson[props.statusKey]?.bodyText}</p>
                </div>
              </section>
            )}
            <div className="modal-footer">
              <div className="alignment">
                <button
                  className="save-button btn btn-secondary"
                  onClick={(e) => {
                    props.setClickedBtn("");
                    props.setShowConfirmationPopup({
                      key: "",
                      visibility: false,
                    });
                  }}
                >
                  {props.secondaryBtnText}
                </button>
                {props.saveActivate ? (
                  <button
                    className="save-button btn btn-primary"
                    onClick={(e) => {
                      props.setClickedBtn("");
                      props.saveAndUpdateStatusHandler(
                        campaignPopupJson[props.statusKey].status
                      );
                      props.setShowConfirmationPopup({
                        key: "",
                        visibility: false,
                      });
                    }}
                  >
                    {campaignPopupJson[props.statusKey]?.buttonSave}
                  </button>
                ) : (
                  <button
                    className="save-button btn btn-primary"
                    onClick={(e) => {
                      props.setClickedBtn(
                        campaignPopupJson[props.statusKey].clickedBtnKey
                      );
                      props.updateStatusHandler(
                        campaignPopupJson[props.statusKey].status
                      );
                      props.setShowConfirmationPopup({
                        key: "",
                        visibility: false,
                      });
                    }}
                  >
                    {campaignPopupJson[props.statusKey]?.button}
                  </button>
                )}
              </div>
            </div>
          </motion.div>
        </div>
      </div>
    </>
  );
};

export default ConfirmationPopUpComponent;
