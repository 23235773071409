export const CAMPAIGN_DASHBOARD_COLUMNS = [
  {
    title: "Campaign ID",
    key: "offer_code",
    type: "string",
    visible: true,
    filter: true,
    sort: false,
    minWidth: 150,
    initialWidth: 150,
    defaultWidth: 150,
    resizable: false,
    reqBodyKey: { attrName: "offerID", isAllowed: true },
  },
  {
    title: "Campaign Name",
    key: "offer_name",
    type: "string",
    visible: true,
    filter: true,
    sort: true,
    minWidth: 150,
    initialWidth: 150,
    defaultWidth: 150,
    resizable: false,
    reqBodyKey: { attrName: "offerName", isAllowed: true },
  },
  {
    title: "Type",
    key: "offer_subtype_code",
    type: "select",
    visible: true,
    filter: true,
    sort: false,
    advanceFilter: false,
    multi: true,
    options: ["Segment Campaign"],
    minWidth: 85,
    initialWidth: 85,
    defaultWidth: 85,
    resizable: true,
    reqBodyKey: { attrName: "offerType", isAllowed: true },
    rights: [
      {
        key: "Segment Campaign",
        rights: ["filter", "campaign", "dashboard", "type", "segmentcampaign"],
      },
      {
        key: "Event Campaign",
        rights: ["filter", "campaign", "dashboard", "type", "eventcampaign"],
      },
    ],
  },
  {
    title: "Status",
    key: "status",
    type: "select",
    visible: true,
    filter: true,
    sort: true,
    advanceFilter: true,
    multi: true,
    options: ["ACTIVATED", "INACTIVE", "DRAFT", "EXPIRED"],
    minWidth: 100,
    initialWidth: 100,
    defaultWidth: 100,
    resizable: false,
    reqBodyKey: { attrName: "offerStatus", isAllowed: true },
  },
  {
    title: "Start Date",
    key: "effective_date_start",
    type: "date",
    visible: true,
    filter: true,
    sort: true,
    minWidth: 100,
    initialWidth: 100,
    defaultWidth: 100,
    resizable: false,
    reqBodyKey: { attrName: "effective_date_start", isAllowed: true },
  },
  {
    title: "End Date",
    key: "effective_date_end",
    type: "date",
    visible: true,
    filter: true,
    sort: true,
    minWidth: 100,
    initialWidth: 100,
    defaultWidth: 100,
    resizable: false,
    reqBodyKey: { attrName: "effective_date_end", isAllowed: true },
  },
  {
    title: "Sent",
    key: "sent",
    type: "string",
    visible: true,
    filter: false,
    sort: true,
    minWidth: 100,
    initialWidth: 100,
    defaultWidth: 100,
    resizable: false,
    reqBodyKey: { attrName: "", isAllowed: false },
  },
  {
    title: "Delivered",
    key: "delivered",
    type: "string",
    visible: true,
    filter: false,
    sort: true,
    minWidth: 100,
    initialWidth: 100,
    defaultWidth: 100,
    resizable: false,
    reqBodyKey: { attrName: "", isAllowed: false },
  },
  {
    title: "Opened",
    key: "impression",
    type: "string",
    visible: true,
    filter: false,
    sort: true,
    minWidth: 100,
    initialWidth: 100,
    defaultWidth: 100,
    resizable: false,
    reqBodyKey: { attrName: "", isAllowed: false },
  },
  {
    title: "Clicked",
    key: "click",
    type: "string",
    visible: true,
    filter: false,
    sort: true,
    minWidth: 100,
    initialWidth: 100,
    defaultWidth: 100,
    resizable: false,
    reqBodyKey: { attrName: "", isAllowed: false },
  },
];
export const DASHBOARD_HEADER = "Let's Get Started";
export const DASHBOARD_HEADER_DESCRIPTION =
  "Create engaging campaigns to proactively connect with and serve your loyal customers.";
export const DASHBOARD = "DASHBOARD";
export const CAMPAIGN_DASHBOARD_LIST_REQ_BODY_KEY = "filter";
export const CAMPAIGN_DASHBOARD_LIST_RES_BODY_KEY = "offerPerformance";
export const CAMPAIGN_DASHBOARD_HEADER_TITLE = "Campaign Performance";
export const CAMPAIGN_DASHBOARD = "CAMPAIGN_DASHBOARD";
export const CAMPAIGN_STATUS_STAT_CHART = "campaignStatusStatChart";
export const DELIVERY_STATUS_STAT_CHART = "deliveryStatusStatChart";
export const ENGAGEMENT_STATUS_STAT_CHART = "engagementStatusStatChart";

export const CAMPAIGN_TYPE = "offer_subtype_code";