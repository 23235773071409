export const ACTION_HISTORY_VERSION_LEGEND = "Action version history";

export const initialEventOfferRule = {
    rule: [
      {
        entity: "AdvanceRule",
        condition: "",
      },
    ],
  };

  export const initialEventOfferAction = {
        actionName: "OFFER_ACTION",
        actionJson: "",
  };

  