import {
	giftIcon,
	messageIcon,
	percentIcon,
	ticketIcon,
} from "../../utilsComponent/SidebarIcons";
import { images } from "../../imagesComponent/Images";
import {
	checkRightsIsVisible,
	getAllRights,
	getRightsKey,
} from "../../utilsComponent/CommonUtil";

export const CREATE_CAMPAIGN_MODAL_TITLE = "Start defining your Campaign...";
export const CAMPAIGN_CAMPAIGN_TITLE = "What type of campaign is this?";
export const CAMPAIGN_CAN_DO_TITLE = "What can this campaign do?";
export const CREATE_CAMPAIGN_POINTS_TITLE = "Points earn offer";
export const CREATE_CAMPAIGN_POINTS_HEADER =
	"Create offer to award points to member based on qualifying activity";
export const CREATE_CAMPAIGN_POINTS_DESCRIPTION =
	"e.g. Award 10% points on spend of over $50, Award 150,000 in points for making a purchase on Mother's day";
export const CREATE_CAMPAIGN_PRICE_TITLE = "Price discount offer";
export const CREATE_CAMPAIGN_PRICE_HEADER =
	"Create offer to give price discount to member based on qualifying activity";
export const CREATE_CAMPAIGN_PRICE_DESCRIPTION =
	"e.g. 5% discount on Mother's day; $10 off on spend of $50 and more";
export const CAMPAIGN_NAME_LABEL = "Campaign Name";
export const CAMPAIGN_NAME_DESCRIPTION_LABEL = "Campaign Description";
export const CAMPAIGN_TRIGGER_TYPES = [
	{
		title: "Segment-triggered",
		key: "CMP-BATCH",
		header:
			"Target specific set of members e.g. first-time buyers to encourage a second purchase.",
		description: "",
		disabled: false,
		icon: "",
		rights: ["popup", "campaign", "createcampaign", "segmentcampaign"]
	},
	{
		title: "Event-triggered",
		key: "CMP-EVENT",
		header:
			"Automatically initiates campaign on an event e.g. sending a discount email when a shopping cart is abandoned.",
		description: "",
		disabled: false,
		icon: "",
		rights: ["popup", "campaign", "createcampaign", "eventcampaign"]
	},
	// {
	//   title: "One-time send",
	//   key: "3",
	//   header: "Ideal for responding when member performs an event.",
	//   description: "",
	//   disabled: true,
	//   status: "coming soon",
	//   icon: "",
	// },
	// {
	//   title: "Recurring",
	//   key: "4",
	//   header: "Ideal for member segment specific campaigns.",
	//   description: "",
	//   disabled: true,
	//   status: "coming soon",
	//   icon: "",
	// },
];
export const CAMPAIGN_CAN_DO_TYPES = [
	{
		title: "Communication",
		key: "1",
		header: "For sending welcome and re-engagement communication.",
		description: "",
		disabled: false,
		icon: "",
	},
	{
		title: "Earn Points",
		key: "2",
		header: "For points award campaigns.",
		description: "",
		disabled: false,
		icon: "",
	},
	{
		title: "Survey",
		key: "3",
		header: "For collecting member profile data.",
		description: "",
		disabled: false,
		icon: "",
	},
	{
		title: "Profile Update",
		key: "4",
		header: "For performing profile updates.",
		description: "",
		disabled: false,
		icon: "",
	},
	{
		title: "Award Offers",
		key: "5",
		header: "For registering members to offers.",
		description: "",
		disabled: false,
		icon: "",
	},
];

export const displayOfferType = {
	"CMP-EVENT": ["Event Campaign"],
	"CMP-BATCH": ["Segment Campaign"],
};
