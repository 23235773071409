import React from "react";
import "./Loading.style.scss";
import { useSelector } from "react-redux";
import {
  ABTESTING_LOADER_SOURCE,
  ATTRIBUTE_LOADER_SOURCE,
  AUTH_LOADER_SOURCE,
  CAMPAIGN_LOADER_SOURCE,
  CONNECTOR_LOADING_SOURCE,
  CONTENT_OFFER_LOADER_SOURCE,
  CUSTOM_OFFER_LOADER_SOURCE,
  GET_ORGANIZATION_LOADING_SOURCE,
  OFFER_LOADER_SOURCE,
  OFFER_REVIEW_LOADER_SOURCE,
  REPORT_LOADER_SOURCE,
  SEGMENT_LOADER_SOURCE,
  SUBCLCUB_LOADER_SOURCE,
  TRIGGER_EVENT_LOADER_SOURCE,
} from "./LoadingConstant";
const Loading = (props) => {
  const offerDetails = useSelector((state) => state.offerDetailsData);
  const promptData = useSelector((state) => state.promptData);
  const segmentDetailsPageData = useSelector(
    (state) => state.segmentDetailsData
  );
  const metaData = useSelector((state) => state.metaData);
  const campaignData = useSelector((state) => state.campaignDetailsData);
  const journeyDetailsData = useSelector((state) => state.journeyDetailsData);
  const securityData = useSelector((state) => state.securityData);
  const reportTokenData = useSelector(
    (state) => state.reportTokenData
  );
  let loadingConditionResult = false;
  // if (props.source === OFFER_LOADER_SOURCE) {
  //   loadingConditionResult =
  //     !offerDetails.offerDetailsLoaded ||
  //     offerDetails.responseLoaded !== 3 ||
  //     promptData.contentGenerationLoading || metaData.entityLoading
  // }
  // if (props.source === CUSTOM_OFFER_LOADER_SOURCE) {
  //   loadingConditionResult = journeyDetailsData?.journeyDetailsLoading;
  // } else if (props.source === CONTENT_OFFER_LOADER_SOURCE) {
  //   loadingConditionResult =
  //     !offerDetails.offerDetailsLoaded ||
  //     offerDetails.responseLoaded !== 3 ||
  //     metaData.categoriesLoading;
  // } else if (props.source === SEGMENT_LOADER_SOURCE) {
  //   loadingConditionResult =
  //     segmentDetailsPageData.segmentDetailsLoading ||
  //     segmentDetailsPageData.responseLoaded !== 3;
  // } else if (props.source === AUTH_LOADER_SOURCE) {
  //   // No condition for AUTH.  loadingConditionResult returns false.
  // } else if (props.source === ATTRIBUTE_LOADER_SOURCE) {
  //   loadingConditionResult =
  //     metaData.entityLoading ||
  //     metaData.getAttributeDetailsLoading ||
  //     metaData.attributeResponseLoaded !== 3;
  // } else if (props.source === CAMPAIGN_LOADER_SOURCE) {
  //   loadingConditionResult =
  //     journeyDetailsData?.journeyDetailsLoading ||
  //     journeyDetailsData?.responseLoaded !== 3;
  // } else if (props.source === SUBCLCUB_LOADER_SOURCE) {
  //   loadingConditionResult = false;
  // } else if (props.source === ABTESTING_LOADER_SOURCE) {
  //   loadingConditionResult = campaignData.variationStatsLoading;
  // } else if (props.source === OFFER_REVIEW_LOADER_SOURCE) {
  //   loadingConditionResult = !offerDetails.offerDetailsLoaded || metaData.entityLoading;
  // } else if (props.source === TRIGGER_EVENT_LOADER_SOURCE) {
  //   loadingConditionResult = journeyDetailsData?.journeyMetaDataLoading;
  // } else if (props.source === GET_ORGANIZATION_LOADING_SOURCE) {
  //   loadingConditionResult = securityData?.orgidLoading;
  // }
  switch (props.source) {
    case OFFER_LOADER_SOURCE:
      loadingConditionResult =
        !offerDetails.offerDetailsLoaded ||
        offerDetails.responseLoaded !== 3 ||
        promptData.contentGenerationLoading || metaData.entityLoading;
      break;
  
    case CUSTOM_OFFER_LOADER_SOURCE:
      loadingConditionResult = journeyDetailsData?.journeyDetailsLoading;
      break;
  
    case CONTENT_OFFER_LOADER_SOURCE:
      loadingConditionResult =
        !offerDetails.offerDetailsLoaded ||
        offerDetails.responseLoaded !== 3 ||
        metaData.categoriesLoading;
      break;
  
    case SEGMENT_LOADER_SOURCE:
      loadingConditionResult =
        segmentDetailsPageData.segmentDetailsLoading ||
        segmentDetailsPageData.responseLoaded !== 3;
      break;
  
    case AUTH_LOADER_SOURCE:
      // No condition for AUTH.  loadingConditionResult returns false.
      loadingConditionResult = false;
      break;
  
    case ATTRIBUTE_LOADER_SOURCE:
      loadingConditionResult =
        metaData.entityLoading ||
        metaData.getAttributeDetailsLoading ||
        metaData.attributeResponseLoaded !== 3;
      break;
  
    case CAMPAIGN_LOADER_SOURCE:
      loadingConditionResult =
        journeyDetailsData?.journeyDetailsLoading ||
        journeyDetailsData?.responseLoaded !== 3;
      break;
  
    case SUBCLCUB_LOADER_SOURCE:
      loadingConditionResult = false;
      break;
  
    case ABTESTING_LOADER_SOURCE:
      loadingConditionResult = campaignData.variationStatsLoading;
      break;
  
    case OFFER_REVIEW_LOADER_SOURCE:
      loadingConditionResult = !offerDetails.offerDetailsLoaded || metaData.entityLoading;
      break;
  
    case TRIGGER_EVENT_LOADER_SOURCE:
      loadingConditionResult = journeyDetailsData?.journeyMetaDataLoading;
      break;
  
    case GET_ORGANIZATION_LOADING_SOURCE:
      loadingConditionResult = securityData?.orgidLoading;
      break;
    case REPORT_LOADER_SOURCE:
      loadingConditionResult = reportTokenData?.reportTokenLoading;
      break;
    case CONNECTOR_LOADING_SOURCE:
      loadingConditionResult = metaData?.connectorListLoading
      break;
    default:
      // Default case to handle unknown sources, if necessary.
      loadingConditionResult = false;
      break;
  }


  return loadingConditionResult ? (
    <div className="loader-position">
      <div className="custom-loader" />
    </div>
  ) : (
    props.children
  );
};

export default Loading;
