import React, { useEffect, useState } from "react";
import "./PointsValidity.style.scss";
import Select from "react-select";

import {
  generateInitialOfferAttr,
  getCustomStyles,
  getInputValidity,
  getInputsForPointsExpire,
  getInputsForPointsOn,
  handleEditBlur,
  handleEditClick,
  modifyInitialData,
} from "../PointsValidityComponent/PointsValidityUtil";
import {
  DATE_STATUS,
  DURATION_LIMIT,
  pointsExpireOptions,
  pointsValidityOptions,
  pointsValidityOptionsAlias,
  pointsValidityOptionsAliasRev,
} from "./PointsValidityConstants";
import "../../../tellurideExtAssets/styles/reactCalendar.style.scss";
import "../../../tellurideExtAssets/styles/reactDatePicker.style.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  setErrorrsForValidations,
  updateOfferAttributeData,
} from "../../../tellurideExtRedux";
import {
  BASE_EARN,
  DATE,
  OFFER_DETAILS_STATUS_DRAFT,
} from "../../constantComponent/Constants";
const PointsValidity = ({ theme: theme,disabled }) => {
  const dispatch = useDispatch();
  const initialOfferAttributes = useSelector(
    (state) => state.offerDetailsData.offerAttributes
  );
  const offerDetailsPageData = useSelector(
    (state) => state.offerDetailsData.offerData
  );
  const offerDetailsData = useSelector((state) => state.offerDetailsData);
  const offerType = offerDetailsData.offerData.offerType;
  const [offerAttributeData, setOfferAttributeData] = useState(
    initialOfferAttributes === undefined || initialOfferAttributes === ""
      ? JSON.parse(JSON.stringify(generateInitialOfferAttr()))
      : JSON.parse(JSON.stringify(initialOfferAttributes))
  );
  const [pointAwardOn, setPointAwardOn] = useState("");
  const [pointExpireOn, setPointExpireOn] = useState("");
  const [pointExpirePlus, setPointExpirePlus] = useState("");
  const [pointAwardPlus, setPointAwardPlus] = useState("");
  const [pointAwardOnUnit, setPointAwardOnUnit] = useState("");
  const [pointExpireUnit, setPointExpireUnit] = useState("");
  const [pointsOnDate, setPointOnDate] = useState("");
  const [pointsExpiryDate, setPointExpiryDate] = useState("");
  const [editProcess, setEditProcess] = useState({
    status: false,
    name: null,
  });
  const [isSetDisable, setDisable] = useState(disabled);
  // useEffect(() => {
  //   setDisable(
  //     offerDetailsData?.offerStatus !== OFFER_DETAILS_STATUS_DRAFT
  //       ? true
  //       : false || disabled
  //   );
  // }, [offerDetailsData?.offerStatus,disabled]);
  useEffect(() => {
    dispatch(updateOfferAttributeData(offerAttributeData));
  }, [offerAttributeData]);
  useEffect(() => {
    offerAttributeData?.offerAttribute?.map((attribute) => {
      if (attribute.name === "POINTS_AWARD_ON") {
        setPointAwardOn(attribute.values.value[0]);
      } else if (attribute.name === "POINTS_EXPIRE_ON") {
        setPointExpireOn(attribute.values.value[0]);
      } else if (attribute.name === "POINTS_EXPIRE_PLUS") {
        setPointExpirePlus(attribute.values.value[0]);
      } else if (attribute.name === "POINTS_EXPIRE_PLUS_UNIT") {
        setPointExpireUnit(attribute.values.value[0]);
      } else if (attribute.name === "POINTS_AWARD_DATE_PLUS") {
        setPointAwardPlus(attribute.values.value[0]);
      } else if (attribute.name === "POINTS_AWARD_DATE") {
        setPointOnDate(attribute.values.value[0]);
      } else if (attribute.name === "POINTS_EXPIRE_DATE") {
        setPointExpiryDate(attribute.values.value[0]);
      }
    });
  }, []);

  function handleChangeSelect({ value }, { name }) {
    let e = { target: { name: name, value: value } };
    if (name === "POINTS_AWARD_ON") {
      if (value === "Transaction Date" || value === "Posted Date") {
        setPointAwardPlus("0");
      }
      if (value === "Specific Date") {
        modifyInitialData(offerAttributeData, setOfferAttributeData, {
          attributeNameToAdd: "POINTS_AWARD_DATE",
          attributeNameToRemove: "POINTS_AWARD_PLUS_UNIT",
        });
      }
      setPointAwardOn(pointsValidityOptionsAlias[value]);
      handleEditBlur(setEditProcess);
    } else if (name === "POINTS_EXPIRE_ON") {
      if (value === "Transaction Date" || value === "Posted Date") {
        setPointExpirePlus("0");
      }
      if (value === "Specific Date") {
        modifyInitialData(offerAttributeData, setOfferAttributeData, {
          attributeNameToAdd: "POINTS_EXPIRE_DATE",
          attributeNameToRemove: "POINTS_EXPIRE_PLUS_UNIT",
        });
      }
      setPointExpireOn(pointsValidityOptionsAlias[value]);
      handleEditBlur(setEditProcess);
    } else if (name === "POINTS_EXPIRE_PLUS_UNIT") {
      setPointExpireUnit(value);
      handleEditBlur(setEditProcess);
    } else if (name === "POINTS_AWARD_PLUS_UNIT") {
      setPointAwardOnUnit(value);
      handleEditBlur(setEditProcess);
    }
    handleEditBlur(setEditProcess);
  }

  useEffect(() => {
    setOfferAttributeData((prevOfferAttributeData) => {
      return {
        offerAttribute: prevOfferAttributeData.offerAttribute.map((ele) => {
          if (ele.name === "POINTS_AWARD_ON") {
            ele.values.value[0] = pointAwardOn;
          } else if (ele.name === "POINTS_EXPIRE_ON") {
            ele.values.value[0] = pointExpireOn;
          } else if (ele.name === "POINTS_EXPIRE_PLUS_UNIT") {
            ele.values.value[0] = pointExpireUnit;
          } else if (ele.name === "POINTS_EXPIRE_PLUS") {
            ele.values.value[0] = pointExpirePlus;
          } else if (ele.name === "POINTS_AWARD_DATE") {
            ele.values.value[0] = pointsOnDate;
          } else if (ele.name === "POINTS_EXPIRE_DATE") {
            ele.values.value[0] = pointsExpiryDate;
          } else if (ele.name === "POINTS_AWARD_DATE_PLUS") {
            ele.values.value[0] = pointAwardPlus;
          }
          return ele;
        }),
      };
    });
    let validate = false;
    if (
      parseInt(pointExpirePlus) > DURATION_LIMIT[pointExpireUnit] ||
      parseInt(pointAwardPlus) > DURATION_LIMIT["Day(s)"]
    ) {
      offerDetailsData.offerValidationErrors.createOffer.pointValidity = true;
      dispatch(
        setErrorrsForValidations(offerDetailsData.offerValidationErrors)
      );
    } else if (
      parseInt(pointExpirePlus) < DURATION_LIMIT[pointExpireUnit] ||
      parseInt(pointAwardPlus) < DURATION_LIMIT["Day(s)"]
    ) {
      offerDetailsData.offerValidationErrors.createOffer.pointValidity = false;
      dispatch(
        setErrorrsForValidations(offerDetailsData.offerValidationErrors)
      );
    } else if (
      pointAwardOn === "Transaction Date plus" &&
      pointExpireOn === "Transaction Date plus"
    ) {
      if (
        pointAwardPlus &&
        pointExpirePlus &&
        pointExpireUnit === "Day(s)" &&
        parseInt(pointAwardPlus) > parseInt(pointExpirePlus)
      ) {
        setEditProcess({
          status: true,
          name: "award-expire-input",
        });
        offerDetailsData.offerValidationErrors.createOffer.pointValidity = true;
        dispatch(
          setErrorrsForValidations(offerDetailsData.offerValidationErrors)
        );
      } else {
        offerDetailsData.offerValidationErrors.createOffer.pointValidity = false;
        dispatch(
          setErrorrsForValidations(offerDetailsData.offerValidationErrors)
        );
      }
    } else if (
      pointAwardOn === "Transaction Post Date plus" &&
      pointExpireOn === "Transaction Post Date plus" &&
      pointExpireUnit === "Day(s)"
    ) {
      if (
        pointAwardPlus &&
        pointExpirePlus &&
        parseInt(pointAwardPlus) > parseInt(pointExpirePlus)
      ) {
        offerDetailsData.offerValidationErrors.createOffer.pointValidity = true;
        dispatch(
          setErrorrsForValidations(offerDetailsData.offerValidationErrors)
        );
      } else {
        offerDetailsData.offerValidationErrors.createOffer.pointValidity = false;
        dispatch(
          setErrorrsForValidations(offerDetailsData.offerValidationErrors)
        );
      }
    }
    dispatch(updateOfferAttributeData(offerAttributeData));
  }, [
    pointAwardOn,
    pointExpireOn,
    pointAwardPlus,
    pointExpirePlus,
    pointAwardOnUnit,
    pointExpireUnit,
    pointsOnDate,
    pointsExpiryDate,
  ]);
  return (
    <>
      <section>
        <div className="section-top-title setup-page-section-title">
          What is the point validity
        </div>
        <div className="points-validation-wrapper">
          <div className="valid-from-section">
            <p className="valid-from">
              Points valid from
              {editProcess.status === true &&
              disabled === false &&
              editProcess.name === "point-award-on" ? (
                <fieldset className="point-fieldset dropdown">
                  <Select
                    name="POINTS_AWARD_ON"
                    options={pointsValidityOptions}
                    styles={getCustomStyles(theme)}
                    onChange={handleChangeSelect}
                    value={{
                      label: pointsValidityOptionsAliasRev[pointAwardOn],
                      value: pointAwardOn,
                    }}
                    onBlur={() => {
                      handleEditBlur(setEditProcess);
                    }}
                    defaultMenuIsOpen
                    autoFocus
                    menuPlacement="auto"
                    maxMenuHeight={150}
                  />
                </fieldset>
              ) : (
                <span
                  data-name="point-award-on"
                  onClick={(e) => {
                    handleEditClick(e, setEditProcess);
                  }}
                  className="editable"
                >
                  {pointsValidityOptionsAliasRev[pointAwardOn]}
                </span>
              )}
              <>
                {pointAwardOn === DATE_STATUS ? (
                  <span>
                    {getInputsForPointsOn(
                      offerAttributeData,
                      setOfferAttributeData,
                      pointAwardOn,
                      pointExpireOn,
                      pointsOnDate,
                      setPointOnDate,
                      editProcess,
                      setEditProcess,
                      offerDetailsPageData,
                      pointExpirePlus,
                      "pointsOn",
                      pointExpireUnit,
                      offerDetailsData,
                      disabled
                    )}
                  </span>
                ) : (
                  <>
                    {offerType !== BASE_EARN && (
                      <>
                        <span>plus</span>
                        <span>
                          {getInputsForPointsOn(
                            offerAttributeData,
                            setOfferAttributeData,
                            pointAwardOn,
                            pointExpireOn,
                            pointAwardPlus,
                            setPointAwardPlus,
                            editProcess,
                            setEditProcess,
                            offerDetailsPageData,
                            pointExpirePlus,
                            "pointsOn",
                            pointExpireUnit,
                            offerDetailsData,
                            disabled
                          )}
                        </span>
                        <span>
                          <span>Days</span>
                        </span>
                      </>
                    )}
                  </>
                )}
              </>
            </p>
          </div>
          <div className="expires-on-section">
            <p className="valid-from">
              Points Expire on
              {editProcess.status === true &&
              disabled === false &&
              editProcess.name === "point-expire-on" ? (
                <fieldset
                  className="point-fieldset dropdown"
                  // disabled={isSetDisable}
                >
                  <Select
                    name="POINTS_EXPIRE_ON"
                    options={pointsValidityOptions}
                    styles={getCustomStyles(theme)}
                    value={{
                      label: pointsValidityOptionsAliasRev[pointExpireOn],
                      value: pointExpireOn,
                    }}
                    onChange={handleChangeSelect}
                    onBlur={() => {
                      handleEditBlur(setEditProcess);
                    }}
                    defaultMenuIsOpen
                    autoFocus
                    menuPlacement="auto"
                    maxMenuHeight={150}
                  />
                </fieldset>
              ) : (
                <span
                  data-name="point-expire-on"
                  onClick={(e) => {
                    handleEditClick(e, setEditProcess);
                  }}
                  className="editable"
                >
                  {pointsValidityOptionsAliasRev[pointExpireOn]}
                </span>
              )}
              {pointExpireOn === DATE_STATUS ? (
                <span>
                  {getInputsForPointsExpire(
                    offerAttributeData,
                    setOfferAttributeData,
                    pointExpireOn,
                    pointAwardOn,
                    pointsExpiryDate,
                    setPointExpiryDate,
                    editProcess,
                    setEditProcess,
                    offerDetailsPageData,
                    pointAwardPlus,
                    "pointsExpire",
                    pointExpireUnit,
                    offerDetailsData,
                    disabled
                  )}
                </span>
              ) : (
                <>
                  <span>plus</span>
                  <span>
                    {getInputsForPointsExpire(
                      offerAttributeData,
                      setOfferAttributeData,
                      pointExpireOn,
                      pointAwardOn,
                      pointExpirePlus,
                      setPointExpirePlus,
                      editProcess,
                      setEditProcess,
                      offerDetailsPageData,
                      pointAwardPlus,
                      "pointsExpire",
                      pointExpireUnit,
                      offerDetailsData,
                      disabled
                    )}
                  </span>
                  <span>
                    {(editProcess.status === true &&
                      editProcess.name === "points-expire-plus-unit" &&
                      disabled === false) ||
                    (pointExpirePlus >= "0" && pointExpireUnit === "") ? (
                      <fieldset
                        className="point-fieldset dropdown"
                        // disabled={isSetDisable}
                      >
                        <Select
                          name="POINTS_EXPIRE_PLUS_UNIT"
                          options={pointsExpireOptions}
                          styles={getCustomStyles(theme)}
                          onChange={handleChangeSelect}
                          value={{
                            label: pointExpireUnit,
                            value: pointExpireUnit,
                          }}
                          onBlur={() => {
                            handleEditBlur(setEditProcess);
                          }}
                          defaultMenuIsOpen
                          autoFocus
                          menuPlacement="auto"
                          maxMenuHeight={150}
                        />
                      </fieldset>
                    ) : (
                      <span
                        data-name="points-expire-plus-unit"
                        onClick={(e) => {
                          handleEditClick(e, setEditProcess);
                        }}
                        className="editable"
                      >
                        {pointExpireUnit}
                      </span>
                    )}
                  </span>
                </>
              )}
            </p>
          </div>
        </div>
      </section>
    </>
  );
};

export default PointsValidity;
