import {
  GET_CHART_CAMPAIGN_STATUS_FAILURE,
  GET_CHART_CAMPAIGN_STATUS_REQUEST,
  GET_CHART_CAMPAIGN_STATUS_SUCCESS,
  GET_CHART_CAMPAIGN_STATUS_SUCCESS_FAILURE,
  GET_CHART_ENGAGEMENT_STATUS_FAILURE,
  GET_CHART_ENGAGEMENT_STATUS_REQUEST,
  GET_CHART_ENGAGEMENT_STATUS_SUCCESS,
  GET_CHART_ENGAGEMENT_STATUS_SUCCESS_FAILURE,
  UPDATE_CAMPAIGN_STATUS_REQUEST,
  UPDATE_CAMPAIGN_STATUS_SUCCESS,
  UPDATE_CAMPAIGN_STATUS_SUCCESS_FAILURE,
  UPDATE_CAMPAIGN_STATUS_FAILURE,
  SET_CAMPAIGN_OFFER_CHANGE_STATUS,
  GET_CAMPAIGN_PERFORMANCE_LIST_REQUEST,
  GET_CAMPAIGN_PERFORMANCE_LIST_SUCCESS,
  GET_CAMPAIGN_PERFORMANCE_LIST_SUCCESS_FAILURE,
  GET_CAMPAIGN_PERFORMANCE_LIST_FAILURE,
  GET_VARIATION_STATS_REQUEST,
  GET_VARIATION_STATS_SUCCESS,
  GET_VARIATION_STATS_SUCCESS_FAILURE,
  GET_VARIATION_STATS_FAILURE,
  SAVE_WINNER_REQUEST,
  SAVE_WINNER_SUCCESS,
  SAVE_WINNER_SUCCESS_FAILURE,
  SAVE_WINNER_FAILURE,
  LAUNCH_CAMPAIGN_OFFER_REQUEST,
  LAUNCH_CAMPAIGN_OFFER_SUCCESS,
  LAUNCH_CAMPAIGN_OFFER_FAILURE,
  LAUNCH_CAMPAIGN_OFFER_SUCCESS_FAILURE,
  AB_TEST_START_REQUEST,
  AB_TEST_START_SUCCESS,
  AB_TEST_START_FAILURE,
  AB_TEST_START_SUCCESS_FAILURE,
} from "./CampaignDetailsType";

const initialState = {
  isChartLoading: false,
  campaignStatusChartDetails: "",
  deliveryStatusChartDetails: "",
  enagagementStatusChartDetails: "",
  apiError: "",
  apiSuccessError: "",
  isCampaignDetailsLoading: false,
  workFlowDetailsLoading: false,
  workFlowDetailsResponse: "",
  updateCampaignStatusLoading: false,
  updateCampaignStatusResponse: "",
  saveCampaignOfferLoading: false,
  saveCampaignOfferResponse: "",
  campaignDetailsResponse: "",
  campaignBuilderDetails: "",
  campaignDetailsData: "",
  getCampaignListResponse: "",
  getCampaignListLoading: false,
  responseLoaded: 1,
  campaignDetailsLoaded: false,
  isOfferChanged: false,
  campaignStatus: "",
  campaignType: "",
  campaignStatusChartDetailsError: false,
  deliveryStatusChartDetailsError: false,
  getCampaignPerformanceListResponse: "",
  getCampaignPerformanceListLoading: false,
  getCampaignPerformanceListError: null,
  campaignMetaDataLoading: false,
  campaignMetaDataResponse: "",
  campaignMetaDataTriggerEvents: "",
  campaignMetaDataErrorResponse: "",
  variationStatsLoading: false,
  variationStatsResponse: "",
  variationStatsError: "",
  saveWinnerLoading: false,
  saveWinnerResponse: "",
  saveWinnerError: "",
  launchCampaignOfferLoading: false,
  launchCampaignOfferResponse: "",
  launchCampaignOfferError: "",
  abTestStartLoading: false,
  abTestStartResponse: "",
  abTestStartError: "",
};

export const CampaignDetailsReducer = (state = initialState, action) => {
  switch (action.type) {
    // Camapaign
    case GET_CHART_CAMPAIGN_STATUS_REQUEST:
      return {
        ...state,
        isChartLoading: true,
        campaignStatusChartDetails: "",
        campaignStatusChartDetailsError: false,
      };
    case GET_CHART_CAMPAIGN_STATUS_SUCCESS:
      return {
        ...state,
        isChartLoading: false,
        campaignStatusChartDetails: action.payload,
        campaignStatusChartDetailsError: false,
      };
    case GET_CHART_CAMPAIGN_STATUS_FAILURE:
      return {
        ...state,
        isChartLoading: false,
        apiError: action.payload,
        apiSuccessError: "",
        campaignStatusChartDetails: "",
        campaignStatusChartDetailsError: true,
      };
    case GET_CHART_CAMPAIGN_STATUS_SUCCESS_FAILURE:
      return {
        ...state,
        isChartLoading: false,
        apiError: "",
        campaignStatusChartDetails: "",
        apiSuccessError: action.payload,
        campaignStatusChartDetailsError: true,
      };
      return {
        ...state,
        isChartLoading: false,
        apiError: "",
        apiSuccessError: action.payload,
        deliveryStatusChartDetails: "",
        deliveryStatusChartDetailsError: true,
      };
    // Engagement
    case GET_CHART_ENGAGEMENT_STATUS_REQUEST:
      return {
        ...state,
        isChartLoading: true,
        enagagementStatusChartDetails: "",
      };
    case GET_CHART_ENGAGEMENT_STATUS_SUCCESS:
      return {
        ...state,
        isChartLoading: false,
        enagagementStatusChartDetails: action.payload,
      };
    case GET_CHART_ENGAGEMENT_STATUS_FAILURE:
      return {
        ...state,
        isChartLoading: false,
        apiError: action.payload,
        apiSuccessError: "",
      };
    case GET_CHART_ENGAGEMENT_STATUS_SUCCESS_FAILURE:
      return {
        ...state,
        isChartLoading: false,
        apiError: "",
        apiSuccessError: action.payload,
      };
    case UPDATE_CAMPAIGN_STATUS_REQUEST:
      return {
        ...state,
        updateCampaignStatusLoading: true,
      };
    case UPDATE_CAMPAIGN_STATUS_SUCCESS:
      return {
        ...state,
        updateCampaignStatusResponse: action.payload,
        updateCampaignStatusLoading: false,
      };
    case UPDATE_CAMPAIGN_STATUS_SUCCESS_FAILURE:
      return {
        ...state,
        updateCampaignStatusLoading: false,
        apiSuccessError: action.payload,
      };
    case UPDATE_CAMPAIGN_STATUS_FAILURE:
      return {
        ...state,
        updateCampaignStatusLoading: false,
        apiError: action.payload,
      };
    case GET_CAMPAIGN_PERFORMANCE_LIST_REQUEST:
      return {
        ...state,
        getCampaignPerformanceListLoading: true,
        getCampaignPerformanceListError: null,
        responseLoaded: 1,
        campaignDetailsLoaded: false,
      };
    case GET_CAMPAIGN_PERFORMANCE_LIST_SUCCESS:
      return {
        ...state,
        getCampaignPerformanceListResponse: action.payload,
        getCampaignPerformanceListLoading: false,
        getCampaignPerformanceListError: null,
        apiSuccessError: "",
      };
    case GET_CAMPAIGN_PERFORMANCE_LIST_SUCCESS_FAILURE:
      return {
        ...state,
        getCampaignPerformanceListLoading: false,
        apiSuccessError: action.payload,
        getCampaignPerformanceListError: action.payload,
      };
    case GET_CAMPAIGN_PERFORMANCE_LIST_FAILURE:
      return {
        ...state,
        getCampaignPerformanceListLoading: false,
        getCampaignPerformanceListError: action.payload,
        apiError: action.payload,
      };

    case SET_CAMPAIGN_OFFER_CHANGE_STATUS: {
      return {
        ...state,
        isOfferChanged: action.payload,
      };
    }

    case GET_VARIATION_STATS_REQUEST: {
      return {
        ...state,
        variationStatsLoading: true,
      };
    }

    case GET_VARIATION_STATS_SUCCESS: {
      return {
        ...state,
        variationStatsLoading: false,
        variationStatsResponse: action.payload,
      };
    }

    case GET_VARIATION_STATS_SUCCESS_FAILURE: {
      return {
        ...state,
        variationStatsLoading: false,
        variationStatsError: action.payload,
      };
    }

    case GET_VARIATION_STATS_FAILURE: {
      return {
        ...state,
        variationStatsLoading: false,
        apiError: action.payload,
      };
    }

    case SAVE_WINNER_REQUEST: {
      return {
        ...state,
        saveWinnerLoading: true,
      };
    }

    case SAVE_WINNER_SUCCESS: {
      return {
        ...state,
        saveWinnerLoading: false,
        saveWinnerResponse: action.payload,
      };
    }

    case SAVE_WINNER_SUCCESS_FAILURE: {
      return {
        ...state,
        saveWinnerLoading: false,
        saveWinnerError: action.payload,
      };
    }

    case SAVE_WINNER_FAILURE: {
      return {
        ...state,
        saveWinnerLoading: false,
        saveWinnerError: false,
        apiError: action.payload,
      };
    }

    case LAUNCH_CAMPAIGN_OFFER_REQUEST: {
      return {
        ...state,
        launchCampaignOfferLoading: true,
      };
    }

    case LAUNCH_CAMPAIGN_OFFER_SUCCESS: {
      return {
        ...state,
        launchCampaignOfferLoading: false,
        launchCampaignOfferResponse: action.payload,
      };
    }

    case LAUNCH_CAMPAIGN_OFFER_FAILURE: {
      return {
        ...state,
        launchCampaignOfferLoading: false,
        launchCampaignOfferError: "",
        apiError: action.payload,
      };
    }

    case LAUNCH_CAMPAIGN_OFFER_SUCCESS_FAILURE: {
      return {
        ...state,
        launchCampaignOfferLoading: false,
        launchCampaignOfferError: action.payload,
      };
    }

    case AB_TEST_START_REQUEST: {
      return {
        ...state,
        abTestStartLoading: true,
      };
    }

    case AB_TEST_START_SUCCESS: {
      return {
        ...state,
        abTestStartLoading: false,
        abTestStartResponse: action.payload,
      };
    }

    case AB_TEST_START_FAILURE: {
      return {
        ...state,
        abTestStartLoading: false,
        abTestStartError: "",
        apiError: action.payload,
      };
    }

    case AB_TEST_START_SUCCESS_FAILURE: {
      return {
        ...state,
        abTestStartLoading: false,
        abTestStartError: action.payload,
      };
    }

    default:
      return state;
  }
};
