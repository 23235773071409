import {
	DARK,
	DROPDOWN_BGCOLOR_DARK_THEME,
	DROPDOWN_FOCUSED_BGCOLOR_DARK_THEME,
	DROPDOWN_FOCUSED_BGCOLOR_LIGHT_THEME,
	DROPDOWN_SELECTED_BGCOLOR_DARK_THEME,
	DROPDOWN_SELECTED_BGCOLOR_LIGHT_THEME,
	LIGHT,
} from "../../constantComponent/Constants";

export const customSelectStyles = (theme) => {
	return {
		option: (provided, state) => ({
			...provided,
			backgroundColor:
				state.isSelected && theme === LIGHT
					? DROPDOWN_SELECTED_BGCOLOR_LIGHT_THEME
					: state.isSelected && theme === DARK
					? DROPDOWN_SELECTED_BGCOLOR_DARK_THEME
					: // ? DROPDOWN_FOCUSED_BGCOLOR_LIGHT_THEME
					state.isFocused && theme === DARK
					? DROPDOWN_FOCUSED_BGCOLOR_DARK_THEME
					: state.isFocused && theme === LIGHT
					? DROPDOWN_FOCUSED_BGCOLOR_LIGHT_THEME
					: theme === DARK
					? DROPDOWN_BGCOLOR_DARK_THEME
					: "#fff",
			color: state.isSelected
				? "#fff"
				: state.isFocused && theme === DARK
				? "#fff"
				: state.isFocused && theme === LIGHT
				? DROPDOWN_SELECTED_BGCOLOR_LIGHT_THEME
				: theme === LIGHT
				? DROPDOWN_SELECTED_BGCOLOR_LIGHT_THEME
				: "#fff",
			padding: 8,
			fontSize: 14,
		}),
		control: (baseStyles, state) => ({
			...baseStyles,
			position: "relative",
			top: 5,
			backgroundColor:
				theme === DARK ? DROPDOWN_SELECTED_BGCOLOR_DARK_THEME : "none",
			fontSize: 14,
			padding: 0,
			width: "100%",
		}),
	};
};
export const DOES_NOT_REPEAT_VALUE = "does_not_repeat";
export const DOES_NOT_REPEAT_LABEL = "Does not repeat";
export const DAILY_VALUE = "daily";
export const DAILY_LABEL = "Daily";
export const WEEKLY_LABEL = "Weekly";
export const WEEKLY_VALUE = "weekly";
export const MONTHLY_LABEL = "Monthly";
export const MONTHLY_VALUE = "monthly";
export const DAY_LABEL = "Day";
export const DAY_VALUE = "day";
export const REPEAT_LABEL = "Repeat";
export const REPEAT_VALUE = "repeat";
export const STAR = "*";
export const FIRST_DAY_OF_MONTH_LABEL = "First day of the month";
export const FIRST_DAY_OF_MONTH_VALUE = "1";
export const LAST_DAY_OF_MONTH_LABEL = "Last day of the month";
export const LAST_DAY_OF_MONTH_VALUE = "L";
export const TIME_ZERO = "00";
export const WEEKS_ARRAY = [
	{
		label: "Sunday",
		value: "sunday",
	},
	{
		label: "Monday",
		value: "monday",
	},
	{
		label: "Tuesday",
		value: "tuesday",
	},
	{
		label: "Wednesday",
		value: "wednesday",
	},
	{
		label: "Thursday",
		value: "thursday",
	},
	{
		label: "Friday",
		value: "friday",
	},
	{
		label: "Saturday",
		value: "saturday",
	},
];

export const DEFAULT_CRON = "0 0 * * *";
