import { useState } from "react";
import { TRIGGER_EVENT_LOADER_SOURCE } from "../../../../LoaderComponents/LoadingConstant";
import { triggerEvents } from "../CustomOfferSetupPopupConstants";
import "./SubclubEventSelectionPopupComponent.style.scss";
import Loading from "../../../../LoaderComponents/Loading";
import { updateNodeData } from "../../../../utilsComponent/GenericFlow/GenericFlowUtil";
import SubClubComponent from "../subclubPopupComponent/SubclubPopupComponent";
import { selectedDisplayNameAlias } from "../../customOfferBuilder/CustomOfferBuilderConstants";
const SubclubEventSelectionPopupComponent = ({
  isDisabled,
  theme,
  setPopupShowProcess,
  currentClickedNode,
  setNodeData,
  setComponentName,
  ComponentName,
}) => {
  const [selectEvent, setSelectEvent] = useState({
    triggerPoint: currentClickedNode?.data?.value?.action?.triggerPoint,
  });

  return (
    <>
      {triggerEvents.find(
        (eventVal) =>
          eventVal.eventName ===
            currentClickedNode?.data?.value?.action?.triggerPoint &&
          ComponentName.source === "parent" &&
          setComponentName({
            name: eventVal.onClick,
            props: {},
            displayName: eventVal.key,
          })
      )}
      <Loading source={TRIGGER_EVENT_LOADER_SOURCE}>
        <section>
          <div
            className="inner-modal-actions"
            style={{
              transform: "none !important",
            }}
          >
            <>
              <div className="segment-popup-wrapper">
                <div
                  className="event-selection-body campaign-action-main-div"
                  style={{
                    width: "98% !important",
                    padding: 10,
                  }}
                >
                  {triggerEvents &&
                    triggerEvents.map((eve, index) => {
                      return (
                        <div
                          key={index}
                          className={`action-div fs-12 ${
                            selectedDisplayNameAlias[eve.eventName] ===
                            selectEvent.triggerPoint
                              ? "selected"
                              : ""
                          } ${isDisabled ? "disabled" : ""}`}
                          onClick={() => {
                            if (isDisabled) {
                              return;
                            }
                            if (eve?.onClick === "eventListener") {
                              setSelectEvent((prev) => {
                                return {
                                  ...prev,
                                  triggerPoint: eve.eventName,
                                };
                              });
                              const eventNodeData = {
                                displayData: {
                                  name: eve.eventName,
                                },
                                value: {
                                  action: {
                                    triggerPoint: eve.key,
                                  },
                                  rules: {
                                    type: "AdvanceRule",
                                    condition: {
                                      PASSES_ALL: [
                                        {
                                          entity: "requestParameters",
                                          attr: "eventType",
                                          operator: "==",
                                          value: "CRME",
                                          type: "String",
                                        },
                                      ],
                                    },
                                    ruleEntity: ["requestParameters"],
                                  },
                                },
                                
                              };
                              updateNodeData(
                                currentClickedNode,
                                setNodeData,
                                "START_TRIGGER",
                                setPopupShowProcess,
                                eventNodeData
                              );
                            } else {
                              setComponentName({
                                name: eve.onClick,
                                props: eve?.props || {},
                                displayName: eve?.type,
                              });
                            }
                          }}
                        >
                          <div
                            className={
                              eve.isdisabled
                                ? "campaign-event-logo disabled"
                                : "campaign-event-logo"
                            }
                          >
                            <eve.icon size={20} style={{ stroke: "white" }} />
                          </div>
                          <p>{eve.displayName}</p>
                        </div>
                      );
                    })}
                </div>
              </div>
            </>
          </div>
        </section>
      </Loading>
    </>
  );
};
export default SubclubEventSelectionPopupComponent;
