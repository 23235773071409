import { motion } from "framer-motion/dist/framer-motion";
import {
  CAMPAIGN_ACTION,
  CAMPAIGN_ACTION_TITLE_1,
  CAMPAIGN_ACTION_TITLE_2,
  CAMPAIGN_ACTION_TITLE_3,
  CAMPAIGN_ACTION_TYPES_WAITS,
  CAMPAIGN_ACTION_TYPES_FLOW_CONTROL,
  CAMPAIGN_ACTION_TYPES_MESSAGING,
} from "../../CampaignBuilderConstants";
import { FaRegTimesCircle } from "react-icons/fa";
import { RxExit } from "react-icons/rx";
import { IoMdAlarm } from "react-icons/io";
import "./CampaignActionComponent.style.scss";
import { BsChat } from "react-icons/bs";
import { HiOutlineMail } from "react-icons/hi";
import { BiMobileAlt } from "react-icons/bi";
import { useState } from "react";
import { useSelector } from "react-redux";

const CampaignActionComponent = ({
  theme,
  setPopupShowProcess,
  currentClickedNode,
  setNodeData,
  actionProps,
  setComponentName,
}) => {
  return (
    <>
      <div className="campaign-type-section trigger-campaign">
        {actionProps.map((prop, index) => {
          return (
            <>
              <h5
                key={index}
                style={{
                  justifySelf: "left",
                  width: "100%",
                }}
              >
                {prop.heading}
              </h5>
              <div className="campaign-action-main-div">
                {prop.actions.map((action, index) => {
                  return (
                    <div
                      key={index}
                      className={
                        action.isdisabled ? "action-div disabled" : "action-div"
                      }
                      onClick={() => {
                        setComponentName({
                          name: action.onClick,
                          props: action?.props || {},
                          displayName: action?.type,
                        });
                      }}
                    >
                      <div
                        className={
                          action.isdisabled
                            ? "campaign-event-logo disabled"
                            : "campaign-event-logo"
                        }
                      >
                        <action.icon size={20} style={{ stroke: "white" }} />
                      </div>
                      <p>{action.displayName}</p>
                    </div>
                  );
                })}
              </div>
            </>
          );
        })}
      </div>
    </>
  );
};

export default CampaignActionComponent;
