import { FaRegTimesCircle } from "react-icons/fa";
import {
  CAMPAIGN_ACTION,
  WAIT,
  EMAIL,
  SEND_EMAIL,
  ACTION,
} from "../../../campaignSetupComponent/campaignBuilderComponent/CampaignBuilderConstants.js";
import { motion } from "framer-motion/dist/framer-motion";
import "./GenericFlowMiddleWare.style.scss";
import CampaignActionComponent from "../../../campaignSetupComponent/campaignBuilderComponent/campaignPopups/campaignActionComponent/CampaignActionComponent.jsx";
import SegmentPopupComponent from "../../../campaignSetupComponent/campaignBuilderComponent/campaignPopups/segmentPopupComponent/SegmentPopupComponent.jsx";
import React, { useEffect, useState } from "react";
import { getDisplayName } from "../GenericFlowUtil.js";
import { maximize, minimize } from "../../SidebarIcons.js";
const GenericFlowMiddleWare = ({
  theme,
  setPopupShowProcess,
  currentClickedNode,
  setNodeData,
  actionProps,
  ComponentName,
  setComponentName,
  displayNameJson,
  nodeDataObj,
  isDisabled,
  // setOpenContentPopup,
  // openContentPopup
}) => {
  const displayName = ComponentName?.displayName || ACTION;
  let nodeObj =
  typeof nodeDataObj[currentClickedNode?.data?.type] === "function"
    ? nodeDataObj[currentClickedNode?.data?.type]()
    : nodeDataObj[currentClickedNode?.data?.type];
	const [screen, setScreen] = useState({
		resize:
    nodeObj?.resize?.status || false,
		maximize: false,
	});
	useEffect(() => {
		if (screen.resize) {
			if (screen.maximize) {
				document.getElementsByClassName("inner-modal-actions")[0].style.width =
					nodeObj?.resize?.maxWidth ||
					"57rem";
			} else {
				document.getElementsByClassName("inner-modal-actions")[0].style.width =
					nodeObj?.resize?.minWidth ||
					"35rem";
			}
		}
	}, [screen]);
	return (
		<section className="action-campaign-modal-backdrop">
			<motion.div
				className="inner-modal-actions"
				initial={{ x: "100%" }}
				animate={{ x: 0 }}
				exit={{
					x: "100%",
					opacity: 0,
					transition: { duration: 0.5, ease: "easeOut" },
				}}
				transition={{ duration: 0.5, ease: "easeOut" }}
				style={{
					width: ComponentName?.style?.width
						? ComponentName.style.width
						: "35rem",
				}}
			>
				<div className="header">
					<h4>{getDisplayName(displayName, displayNameJson)}</h4>
					<div className="close-campaign-popup-container">
						{screen.resize &&
							(screen.maximize ? (
								<div
									className="mt-6 cursor-pointer"
									onClick={() => {
										setScreen({
											resize: true,
											maximize: false,
										});
									}}
								>
									{minimize()}
								</div>
							) : (
								<div
									className="mt-6 cursor-pointer"
									onClick={() => {
										setScreen({
											resize: true,
											maximize: true,
										});
									}}
								>
									{maximize()}
								</div>
							))}
						<FaRegTimesCircle
							onClick={() => {
								setPopupShowProcess(false);
								setNodeData(null);
							}}
							size={25}
							className="campaign-modal-close-icon"
						/>
					</div>
				</div>
				<div className="campaignPopup">
					<ComponentName.name
						theme={theme}
						setPopupShowProcess={setPopupShowProcess}
						currentClickedNode={currentClickedNode}
						setNodeData={setNodeData}
						actionProps={actionProps}
						setComponentName={setComponentName}
						{...ComponentName?.props}
						ComponentName={ComponentName}
						setScreen={setScreen}
						isDisabled={isDisabled}
						// setOpenContentPopup={setOpenContentPopup}
					/>
				</div>
			</motion.div>
		</section>
	);
};

export default GenericFlowMiddleWare;
