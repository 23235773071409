import {
GET_CHATBOT_ANSWER_REQUEST,
GET_CHATBOT_ANSWER_SUCCESS,
GET_CHATBOT_ANSWER_FAILURE,
GET_CHATBOT_ANSWER_SUCCESS_FAILURE,
SET_BLANK_SESSION_ID_ERROR,
SET_SESSION_TIMEOUT_POPUP
} from "./ChatbotType";

const initialState = {
    chatbotAnswerLoading: true,
    chatbotAnswerResponse: "",
    chatbotAnswerError: "",
	blankSessionIdError: false,
	showExpiredSessionPopup: false,    
  };

  export const ChatbotReducer = (state = initialState,action ) => {

    switch(action.type){
        case GET_CHATBOT_ANSWER_REQUEST:
            return {
                ...state,
                chatbotAnswerLoading:true,
            };
        case GET_CHATBOT_ANSWER_SUCCESS:
            return {
                ...state,
                chatbotAnswerLoading:false,
                chatbotAnswerResponse:action.payload,
                chatbotAnswerError: "",
            };
        case GET_CHATBOT_ANSWER_FAILURE:
            return {
                ...state,
                chatbotAnswerLoading:false,
                chatbotAnswerResponse:"",
                chatbotAnswerError: action.payload,
            };
        case GET_CHATBOT_ANSWER_SUCCESS_FAILURE:
            return {
                ...state,
                chatbotAnswerLoading:false,
                chatbotAnswerResponse:"",
                chatbotAnswerError: action.payload,                
            };
        case SET_BLANK_SESSION_ID_ERROR:
            return {
                ...state,
                blankSessionIdError: true,
            };
        case SET_SESSION_TIMEOUT_POPUP:
            return {
                ...state,
                showExpiredSessionPopup: true,
            };
        default:
            return state;    
    }
  };