import React, { useEffect, useRef, useState } from "react";
import {
	CREATED_BY,
	CREATE_DATE,
	MEMBER_NUMBER,
	SEGMENT_HEADER,
	segment_columns,
} from "../../segmentSetupComponent/segmentContentComponent/SegmentContentConstants";
import SmallGenericTableColumn from "./SmallGenericTableColumn";
import SmallGenericTableBody from "./SmallGenericTableBody";
import "./SmallGenericTable.style.scss";
import {
	ASCENDING,
	DESCENDING,
	SORTED,
	ZERO,
} from "../GenericRules/GenericRulesConstants";
import { getSmallGenericTableList, handleSort } from "./SmallGeneralTableUtil";
import { Pagination } from "../TableUtil/GenericTablePagination";
import { useDispatch, useSelector } from "react-redux";

function SmallGenericTable({
	Columns,
	tableData,
	setTableData,
	selectedData,
	isRowSelectable,
	isRowMultiSelectable,
	config,
	rowClickHandler,
	multiSelectRowClickHandler,
	allRowClickHandler,
	API_URL,
	searchValue,
	isShowPagination,
	RESPONSE_BODY_KEY,
	isDataLoading,
	initialPagination,
	smallGenericTableRquestBody,
	triggerList,
}) {
	const paginationRef = useRef(null);
	const dispatch = useDispatch();

	const offerData = useSelector((state) => state.offerDetailsData);
	const tableDataValue = useSelector((state) => state.genericTableList);

	let tableLoading = tableDataValue.genericTableDataLoading;
	const tableResponse = tableDataValue.genericTableDataResponse;
	const tableError = tableDataValue.genericTableDataError;

	const [isPageDataLoading, setIsPageDataLoading] = useState(true);
	const [list, SetList] = useState([]);
	const [pagination, setPagination] = useState({
		recordPerPage: initialPagination?.recordPerPage || 10, // Adjust records per page as needed
		currentPage: initialPagination?.currentPage || 1,
		totalPages: 0,
		totalRecords: 0,
	});
	const [fetchList, setFetchList] = useState(false);
	const [isSorting, setIsSorting] = useState({
		status: false,
		order: null,
	});

	useEffect(() => {
		setIsPageDataLoading(true);
		setPagination({
			recordPerPage: initialPagination?.recordPerPage || 10,
			currentPage: initialPagination?.currentPage || 1,
			totalPages: 0,
			totalRecords: 0,
		});
		setIsPageDataLoading(false);
		if (!isShowPagination) {
			setIsPageDataLoading(false);
			tableLoading = false;
		}
	}, []);

	useEffect(() => {
		if (tableError) {
			SetList([]);
			setIsPageDataLoading(false);
		}
	}, [tableLoading, tableError, tableResponse]);

	useEffect(() => {
		if (tableLoading === false && tableResponse) {
			if (tableResponse?.[RESPONSE_BODY_KEY]) {
        SetList(tableResponse[RESPONSE_BODY_KEY]);
				setPagination((prev) => {
					return {
						currentPage: parseInt(tableResponse.pagination.currentPage),
						recordPerPage: parseInt(tableResponse.pagination.recordPerPage)
							? parseInt(tableResponse.pagination.recordPerPage)
							: prev.recordPerPage,
						totalPages: parseInt(tableResponse.pagination.totalPages),
						totalRecords: parseInt(tableResponse.pagination.totalRecords),
					};
				});
			}
			// setIsDataLoading(false);
		}
	}, [tableLoading, tableResponse]);

	useEffect(() => {
		if (isShowPagination) {
			const controller = new AbortController();
			getSmallGenericTableList(
				API_URL,
				controller,
				offerData?.authOrganizationName,
				dispatch,
				{ ...smallGenericTableRquestBody, pagination }
			);
		}
	}, [fetchList, searchValue, triggerList]);

	function sortHandler(order, sortKey) {
		handleSort(
			tableData,
			setTableData,
			isSorting,
			setIsSorting,
			order,
			sortKey
		);
	}

	return (
		<div className="small-generic-table small-tabledata-container">
			<SmallGenericTableColumn
				columns={Columns}
				isRowMultiSelectable={isRowMultiSelectable}
				tableData={isShowPagination ? list : tableData}
				rowClickHandler={allRowClickHandler}
				selectedData={selectedData}
				config={config}
				handleSort={sortHandler}
				isSorting={isSorting}
			/>
			<SmallGenericTableBody
				columns={Columns}
				tableData={isShowPagination ? list : tableData}
				isDataLoading={
					isShowPagination ? isPageDataLoading || tableLoading : isDataLoading
				}
				isRowMultiSelectable={isRowMultiSelectable}
				isRowSelectable={isRowSelectable}
				selectedData={selectedData}
				config={config}
				rowClickHandler={rowClickHandler}
				multiSelectRowClickHandler={multiSelectRowClickHandler}
				pagination={pagination}
			/>
			{isShowPagination && (
				<Pagination
					pagination={pagination}
					setPagination={setPagination}
					setFetchList={setFetchList}
					paginationRef={paginationRef}
					dataFound={list && list.length > ZERO ? true : false}
					steps={initialPagination?.recordPerPage}
				/>
			)}
		</div>
	);
}

export default React.memo(SmallGenericTable);
