export const generateInitialLinkedOfferJSON = () => {
  const jsonData = {
    linkedOffers: [
      {
        offerData: {
          offerType: "Message only",
          offerSubType: "MESSAGE",
          offerPointsDollarName: "",
          linkedOfferStatus: "",
        },
        actions: {
          action: [
            {
              actionName: "My Action",
              actionType: "MESSAGE",
              actionJson: {
                headline: "",
                bodyline: "",
                imageId: "",
                imageName: "",
                callToActionURL: ""
              },
            },
          ],
        },
      },
    ],
  };
  return jsonData.linkedOffers;
};
