export const discountIntervalUnitOptions = [
  { value: "PER_TRANSACTION", label: "Per Transaction" },
  { value: "YEAR", label: "Yearly" },
  { value: "QUARTER", label: "Quarterly" },
  { value: "MONTH", label: "Monthly" },
  { value: "WEEK", label: "Weekly" },
  { value: "DAY", label: "Daily" },
];

export const discountIntervalAlias = {
  PER_TRANSACTION: "Per Transaction",
  YEAR: "Yearly",
  QUARTER: "Quarterly",
  MONTH: "Monthly",
  WEEK: "Weekly",
  DAY: "Daily",
};

export const discountIntervalAliasRev = {
  "Per Transaction": "PER_TRANSACTION",
  Yearly: "YEAR",
  Quarterly: "QUARTER",
  Monthly: "MONTH",
  Weekly: "WEEK",
  Daily: "DAY",
};

export const earnCapIntervalUnitOptions = [
  { value: "CALDAY", label: "Calendar Day(s)" },
  { value: "CALMONTH", label: "Calendar Month(s)" },
  { value: "CALYEAR", label: "Calendar Year(s)" },
  { value: "DAY", label: "Day(s)" },
  { value: "WEEK", label: "Week(s)" },
  { value: "MONTH", label: "Month(s)" },
  { value: "YEAR", label: "Year(s)" },
];

export const earnCapIntervalAlias = {
  YEAR: "Year(s)",
  MONTH: "Month(s)",
  WEEK: "Week(s)",
  DAY: "Day(s)",
  CALDAY: "Calendar Day(s)",
  CALMONTH: "Calendar Month(s)",
  CALYEAR: "Calendar Year(s)",
};

export const discountCapNewProperty = {
  property: [
    {
      name: "MEMBER_TIMES_CAP",
      values: {
        value: ["0"],
      },
    },
    {
      name: "MEMBER_CAP_PERIOD",
      values: {
        value: ["MONTH"],
      },
    },
  ],
  propertyType: "CAP",
};

export const generateInitialDiscountCapProperty = () => {
  const discountDefaultCapData = {
    property: [
      {
        name: "MEMBER_TIMES_CAP",
        values: {
          value: ["0"],
        },
      },
      {
        name: "MEMBER_CAP_PERIOD",
        values: {
          value: ["MONTH"],
        },
      },
    ],
    propertyType: "CAP",
  };

  return discountDefaultCapData;
};

export const generateInitialEarnCapProperty = () => {
  const earnDefaultCapData = {
    property: [
      {
        name: "MEMBER_TIMES_CAP",
        values: {
          value: ["0"],
        },
      },
      {
        name: "MEMBER_POINTS_CAP",
        values: {
          value: ["0"],
        },
      },
      {
        name: "DURATION_TIMES_CAP_DAYS",
        values: {
          value: ["0"],
        },
      },
      {
        name: "DURATION_TIMES_CAP_CODE",
        values: {
          value: ["MONTH"],
        },
      },
      {
        name: "DURATION_TIMES_CAP_VALUE",
        values: {
          value: ["0"],
        },
      },
      {
        name: "DURATION_POINTS_CAP_DAYS",
        values: {
          value: ["0"],
        },
      },
      {
        name: "DURATION_POINTS_CAP_CODE",
        values: {
          value: ["MONTH"],
        },
      },
      {
        name: "DURATION_POINTS_CAP_VALUE",
        values: {
          value: ["0"],
        },
      },
      {
        name: "OFFER_TIMES_CAP",
        values: {
          value: ["0"],
        },
      },
      {
        name: "OFFER_POINTS_CAP",
        values: {
          value: ["0"],
        },
      },
      {
        name: "STORE_TIMES_CAP",
        values: {
          value: ["0"],
        },
      },
      {
        name: "STORE_POINTS_CAP",
        values: {
          value: ["0"],
        },
      },
    ],
    propertyType: "CAP",
  };

  return earnDefaultCapData;
};

export const EarnCapNewProperty = {
  property: [
    {
      name: "MEMBER_TIMES_CAP",
      values: {
        value: ["0"],
      },
    },
    {
      name: "MEMBER_POINTS_CAP",
      values: {
        value: ["0"],
      },
    },
    {
      name: "DURATION_TIMES_CAP_DAYS",
      values: {
        value: ["0"],
      },
    },
    {
      name: "DURATION_TIMES_CAP_CODE",
      values: {
        value: ["MONTH"],
      },
    },
    {
      name: "DURATION_TIMES_CAP_VALUE",
      values: {
        value: ["0"],
      },
    },
    {
      name: "DURATION_POINTS_CAP_DAYS",
      values: {
        value: ["0"],
      },
    },
    {
      name: "DURATION_POINTS_CAP_CODE",
      values: {
        value: ["MONTH"],
      },
    },
    {
      name: "DURATION_POINTS_CAP_VALUE",
      values: {
        value: ["0"],
      },
    },
    {
      name: "OFFER_TIMES_CAP",
      values: {
        value: ["0"],
      },
    },
    {
      name: "OFFER_POINTS_CAP",
      values: {
        value: ["0"],
      },
    },
    {
      name: "STORE_TIMES_CAP",
      values: {
        value: ["0"],
      },
    },
    {
      name: "STORE_POINTS_CAP",
      values: {
        value: ["0"],
      },
    },
  ],
  propertyType: "CAP",
};

export const capPeriodValueProperty = {
  name: "MEMBER_CAP_PERIOD_VALUE",
  values: {
    value: [""],
  },
};

export const generateEmptyCapProperty = () => {
  const emptyCapProperty = {
    name: "",
    values: {
      value: [""],
    },
  };

  return emptyCapProperty;
};

export const capEmptyProperty = {
  name: "",
  values: {
    value: [""],
  },
};
export const MEMBER_CAP_INFO_TITLE = "What is Member Cap?";
export const MEMBER_CAP_INFO_DETAIL =
  "Member Cap defines the maximum number of times the offer can be applied to a member or maximum amount of points can be awarded to a member in the given period of time.";
export const OFFER_CAP_INFO_TITLE = "What is Offer Cap?";
export const OFFER_CAP_INFO_DETAIL =
  "Offer Cap defines the maximum number of times the offer can be applied before offer expiry or maximum amount of points can be awarded before offer expiry";
export const DISCOUNT_CAP_INFO_TITLE = "What is Discount Cap?";
export const DISCOUNT_CAP_INFO_DETAIL =
  "Discount Cap defines the maximum number of times the discount can be applied to a member in the given period of time.";

export const MEMBER_AWARDS_TIMES = [
  { name: "MEMBER_TIMES_CAP", values: { value: ["0"] } },
  {
    name: "DURATION_TIMES_CAP_DAYS",
    values: {
      value: ["0"],
    },
  },
  {
    name: "DURATION_TIMES_CAP_CODE",
    values: { value: ["MONTH"] },
  },
  {
    name: "DURATION_TIMES_CAP_VALUE",
    values: { value: ["0"] },
  },
];

export const MEMBER_AWARDS_POINTS = [
  { name: "MEMBER_POINTS_CAP", values: { value: ["0"] } },
  {
    name: "DURATION_POINTS_CAP_DAYS",
    values: {
      value: ["0"],
    },
  },
  {
    name: "DURATION_POINTS_CAP_CODE",
    values: { value: ["MONTH"] },
  },
  {
    name: "DURATION_POINTS_CAP_VALUE",
    values: { value: ["0"] },
  },
];

export const OFFER_LEVEL_POINTS = [
  {
    name: "STORE_POINTS_CAP",
    values: {
      value: ["0"],
    },
  },
];

export const OFFER_LEVEL_TIMES = [
  {
    name: "STORE_TIMES_CAP",
    values: {
      value: ["0"],
    },
  },
];

export const MEMBER_TIMES_CAP = "MEMBER_TIMES_CAP";
export const MEMBER_POINTS_CAP = "MEMBER_POINTS_CAP";
export const DURATION_TIMES_CAP_CODE = "DURATION_TIMES_CAP_CODE";
export const DURATION_TIMES_CAP_DAYS = "DURATION_TIMES_CAP_DAYS";
export const DURATION_POINTS_CAP_DAYS = "DURATION_POINTS_CAP_DAYS";
export const DURATION_POINTS_CAP_CODE = "DURATION_POINTS_CAP_CODE";
export const DURATION_TIMES_CAP_VALUE = "DURATION_TIMES_CAP_VALUE";
export const DURATION_POINTS_CAP_VALUE = "DURATION_POINTS_CAP_VALUE";
export const STORE_TIMES_CAP = "STORE_TIMES_CAP";
export const STORE_POINTS_CAP = "STORE_POINTS_CAP";
