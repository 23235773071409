import { AiOutlineLoading3Quarters } from "react-icons/ai";
//import { FaSpinner } from 'react-icons/fa';
import SkeletonTableLoading from "./SkeletonTableLoading";
import "./Loading.style.scss"
export function miniloading(message) {
	return (
		<div className="miniloading-card">
			<div className="mini-loader"></div>
			{message}
		</div>
	);
}
export function loading() {
	return (
		<div className="empty-data-container">
			<div className="ring">
				Loading..
				<span className="spin_span"></span>
			</div>
		</div>
	);
}

export function tableLoader(columns,layoutLoading) {
	const n = 50;
	return [...Array(n)].map((loading) => (
		<div className="col" key={loading}>
			<SkeletonTableLoading columns={columns} layoutLoading={layoutLoading}/>
		</div>
	));
}
export function miniloadingWithoutMessage() {
	return (
		<div className="miniloading-card">
			<div className="mini-loader"></div>
		</div>
	);
}
export function DotLoading() {
	return (
		<>
			<div className="json-loading-backdrop"></div>
			<div className="json-loading-container">
				<div className="json-loader"></div>
			</div>
		</>
	);
}
