export const GET_AUTH_INIT_REQUEST = "GET_AUTH_INIT_REQUEST";
export const GET_AUTH_INIT_SUCCESS = "GET_AUTH_INIT_SUCCESS";
export const GET_AUTH_INIT_FAILURE = "GET_AUTH_INIT_FAILURE";
export const GET_AUTH_INIT_SUCCESS_FAILURE = "GET_AUTH_INIT_SUCCESS_FAILURE";

export const GET_AUTH_LOGOUT_REQUEST = "GET_AUTH_LOGOUT_REQUEST";
export const GET_AUTH_LOGOUT_SUCCESS = "GET_AUTH_LOGOUT_SUCCESS";
export const GET_AUTH_LOGOUT_FAILURE = "GET_AUTH_LOGOUT_FAILURE";
export const GET_AUTH_LOGOUT_SUCCESS_FAILURE =
  "GET_AUTH_LOGOUT_SUCCESS_FAILURE";
export const SET_AUTHLOADING_FLAG = "SET_AUTHLOADING_FLAG";

export const GET_ORG_ID_REQUEST = "GET_ORG_ID_REQUEST";
export const GET_ORG_ID_SUCCESS = "GET_ORG_ID_SUCCESS";
export const GET_ORG_ID_FAILURE = "GET_ORG_ID_FAILURE";
export const GET_ORG_ID_SUCCESS_FAILURE = "GET_ORG_ID_SUCCESS_FAILURE";
export const SET_SESSION_TIMEOUT_POPUP = "SET_SESSION_TIMEOUT_POPUP";
export const SET_BLANK_SESSION_ID_ERROR = "SET_BLANK_SESSION_ID_ERROR";
