import { get } from "lodash";
import {
	GET_ENTITIES_REQUEST,
	GET_ENTITIES_SUCCESS,
	GET_ENTITIES_FAILURE,
	GET_ATTRIBUTES_REQUEST,
	GET_ATTRIBUTES_SUCCESS,
	GET_ATTRIBUTES_FAILURE,
	SET_ATTRIBUTE_LIST,
	SET_BLANK_SESSION_ID_ERROR,
	SET_SESSION_TIMEOUT_POPUP,
	CREATE_ATTRIBUTE_REQUEST,
	CREATE_ATTRIBUTE_SUCCESS,
	CREATE_ATTRIBUTE_FAILURE,
	CREATE_ATTRIBUTE_SUCCESS_FAILURE,
	GET_ATTRIBUTE_LIST_REQUEST,
	GET_ATTRIBUTE_LIST_SUCCESS,
	GET_ATTRIBUTE_LIST_FAILURE,
	GET_ATTRIBUTE_LIST_SUCCESS_FAILURE,
	GET_ATTRIBUTE_DETAILS_REQUEST,
	GET_ATTRIBUTE_DETAILS_SUCCESS,
	GET_ATTRIBUTE_DETAILS_FAILURE,
	GET_ATTRIBUTE_DETAILS_SUCCESS_FAILURE,
	SET_ATTRIBUTE_DETAILS,
	SET_ATTRIBUTE_OPTIONS,
	SET_ATTRIBUTE_DETAILS_LOADING,
	RESET_ATTRIBUTE_DETAILS_SUCCESS,
	RESET_ATTRIBUTE_DETAILS_RESPONSE,
	RESET_CREATE_ATTRIBUTE_RESPONSE,
	SET_ATTRIBUTE_VALIDATION,
	GET_CATEGORIES_REQUEST,
	GET_CATEGORIES_SUCCESS,
	GET_CATEGORIES_FAILURE,
	GET_CATEGORIES_SUCCESS_FAILURE,
	GET_SUB_CATEGORIES_REQUEST,
	GET_SUB_CATEGORIES_SUCCESS,
	GET_SUB_CATEGORIES_FAILURE,
	GET_SUB_CATEGORIES_SUCCESS_FAILURE,
	GET_CONTENT_ATTRS_SUCCESS_FAILURE,
	GET_CONTENT_ATTRS_FAILURE,
	GET_CONTENT_ATTRS_SUCCESS,
	GET_CONTENT_ATTRS_REQUEST,
	RESET_CONTENT_METADATA,
	GET_OFFER_TYPES_REQUEST,
	GET_OFFER_TYPES_SUCCESS,
	GET_OFFER_TYPES_FAILURE,
	GET_OFFER_TYPES_SUCCESS_FAILURE,
	GET_REPORT_LINK_REQUEST,
	GET_REPORT_LINK_SUCCESS,
	GET_REPORT_LINK_FAILURE,
	GET_REPORT_LINK_SUCCESS_FAILURE,
	GET_CONNECTOR_TABLE_SUCCESS_FAILURE,
	GET_CONNECTOR_TABLE_FAILURE,
	GET_CONNECTOR_TABLE_SUCCESS,
	GET_CONNECTOR_TABLE_REQUEST,
	GET_ADD_CONNECTOR_LIST_REQUEST,
	GET_ADD_CONNECTOR_LIST_SUCCESS,
	GET_ADD_CONNECTOR_LIST_FAILURE,
	GET_ADD_CONNECTOR_LIST_SUCCESS_FAILURE,
	GET_ADD_CONNECTOR_OPTIONS_SUCCESS_FAILURE,
	GET_ADD_CONNECTOR_OPTIONS_FAILURE,
	GET_ADD_CONNECTOR_OPTIONS_SUCCESS,
	GET_ADD_CONNECTOR_OPTIONS_REQUEST,
	CREATE_CONNECTOR_SUCCESS,
	CREATE_CONNECTOR_REQUEST,
	CREATE_CONNECTOR_FAILURE,
	CREATE_CONNECTOR_SUCCESS_FAILURE,
	GET_PROGRAM_TYPES_REQUEST,
	GET_PROGRAM_TYPES_SUCCESS,
	GET_PROGRAM_TYPES_FAILURE,
	IS_SYW_OFFERCODE_EXISTS,
} from "./MetaDataType";

const initialState = {
	entityLoading: true,
	attributeLoading: false,
	entitiesResponse: "",
	attributesResponse: "",
	attributeList: [],
	apiError: "",
	apiSuccessError: "",
	blankSessionIdError: false,
	showExpiredSessionPopup: false,
	createAttributeLoading: false,
	createAttributeResponse: "",
	createAttributeError: "",
	getAttributeListLoading: false,
	getAttributeListResponse: "",
	getAttributeListError: "",
	getAttributeDetailsLoading: true,
	getAttributeDetailsResponse: "",
	attributeResponseLoaded: 1,
	getAttributeDetailsError: "",
	attributeDetailsData: "",
	updateAttributeDetailsData: "",
	attributeOptionsData: "",
	updateAttributeOptionsData: "",
	updateAttributeValidationData: "",
	categoriesLoading: true,
	categoriesResponse: "",
	subCategoriesLoading: false,
	subCategoriesResponse: "",
	getContentAttrsLoading: false,
	getContentAttrsResponse: "",
	getContentAttrsFlag: false,
	getContentAttrsError: "",
	offerTypeListLoading: true,
	offerTypeListResponse: "",
	offerTypeListError: false,
	reportLinkLoading: true,
	reportLinkResponse: "",
	reportLinkError: false,
	connectorListLoading: true,
	connectorListResponse: "",
	connectorListError: false,
	addConnectorOptionsLoading: true,
	addConnectorOptionsResponse: "",
	addConnectorOptionsError: false,
	createConnectorLoding: false,
	createConnectorResponse: "",
	createConnectorError: false,
	programTypesLoading: true,
	programTypesResponse: "",
	programTypesError: null,
	isSywOfferCodeExists: false
};

export const TellurideMetaDataReducer = (state = initialState, action) => {
	switch (action.type) {
		case GET_ENTITIES_REQUEST:
			return {
				...state,
				entityLoading: true,
			};

		case GET_ENTITIES_SUCCESS:
			return {
				...state,
				entityLoading: false,
				entitiesResponse: action.payload,
			};

		case GET_ENTITIES_FAILURE:
			return {
				...state,
				entityLoading: false,
				entitiesResponse: "",
				apiError: action.payload,
			};

		case GET_ATTRIBUTES_REQUEST:
			return {
				...state,
				attributeLoading: true,
			};

		case GET_ATTRIBUTES_SUCCESS:
			return {
				...state,
				attributeLoading: false,
				attributesResponse: action.payload,
			};

		case GET_ATTRIBUTES_FAILURE:
			return {
				...state,
				attributeLoading: false,
				attributesResponse: "",
				apiError: action.payload,
			};
		case SET_ATTRIBUTE_LIST:
			return {
				...state,
				attributeList: action.payload,
			};
		case SET_BLANK_SESSION_ID_ERROR:
			return {
				...state,
				blankSessionIdError: true,
			};
		case SET_SESSION_TIMEOUT_POPUP:
			return {
				...state,
				showExpiredSessionPopup: true,
			};
		case CREATE_ATTRIBUTE_REQUEST:
			return {
				...state,
				createAttributeLoading: true,
			};

		case CREATE_ATTRIBUTE_SUCCESS:
			return {
				...state,
				createAttributeLoading: false,
				createAttributeResponse: action.payload,
			};

		case CREATE_ATTRIBUTE_FAILURE:
			return {
				...state,
				createAttributeLoading: false,
				createAttributeError: action.payload,
				apiError: action.payload,
			};
		case CREATE_ATTRIBUTE_SUCCESS_FAILURE:
			return {
				...state,
				createAttributeLoading: false,
				createAttributeError: action.payload,
				apiSuccessError: action.payload,
			};
		case GET_ATTRIBUTE_LIST_REQUEST:
			return {
				...state,
				getAttributeListLoading: true,
				attributeResponseLoaded: 1,
			};

		case GET_ATTRIBUTE_LIST_SUCCESS:
			return {
				...state,
				getAttributeListError: "",
				getAttributeListLoading: false,
				getAttributeListResponse: action.payload,
			};

		case GET_ATTRIBUTE_LIST_FAILURE:
			return {
				...state,
				getAttributeListError: action.payload,
				getAttributeListLoading: false,
				apiError: action.payload,
			};
		case GET_ATTRIBUTE_LIST_SUCCESS_FAILURE:
			return {
				...state,
				getAttributeListError: action.payload,
				getAttributeListLoading: false,
				apiSuccessError: action.payload,
			};
		case GET_ATTRIBUTE_DETAILS_REQUEST:
			return {
				...state,
				getAttributeDetailsLoading: true,
				attributeResponseLoaded: 2,
			};

		case GET_ATTRIBUTE_DETAILS_SUCCESS:
			return {
				...state,
				getAttributeDetailsLoading: false,
				getAttributeDetailsResponse: action.payload,
				attributeResponseLoaded: 3,
			};

		case GET_ATTRIBUTE_DETAILS_FAILURE:
			return {
				...state,
				getAttributeDetailsError: action.payload,
				getAttributeDetailsLoading: false,
				apiError: action.payload,
			};
		case GET_ATTRIBUTE_DETAILS_SUCCESS_FAILURE:
			return {
				...state,
				getAttributeDetailsError: action.payload,
				getAttributeDetailsLoading: false,
				apiSuccessError: action.payload,
			};
		case SET_ATTRIBUTE_DETAILS:
			return {
				...state,
				updateAttributeDetailsData: action.payload,
			};
		case SET_ATTRIBUTE_OPTIONS:
			return {
				...state,
				updateAttributeOptionsData: action.payload,
			};
		case SET_ATTRIBUTE_VALIDATION:
			return {
				...state,
				updateAttributeValidationData: action.payload,
			};
		case SET_ATTRIBUTE_DETAILS_LOADING:
			return {
				...state,
				attributeResponseLoaded: 3,
				getAttributeDetailsLoading: action.payload,
			};
		case RESET_ATTRIBUTE_DETAILS_RESPONSE:
			return {
				...state,
				getAttributeDetailsResponse: "",
			};
		case RESET_CREATE_ATTRIBUTE_RESPONSE:
			return {
				...state,
				createAttributeResponse: "",
			};
		case GET_CATEGORIES_REQUEST:
			return {
				...state,
				categoriesLoading: true,
			};

		case GET_CATEGORIES_SUCCESS:
			return {
				...state,
				categoriesLoading: false,
				categoriesResponse: action.payload,
			};

		case GET_CATEGORIES_FAILURE:
			return {
				...state,
				categoriesLoading: false,
				categoriesResponse: "",
				apiError: action.payload,
			};
		case GET_CATEGORIES_SUCCESS_FAILURE:
			return {
				...state,
				categoriesLoading: false,
				apiSuccessError: action.payload,
			};
		case GET_SUB_CATEGORIES_REQUEST:
			return {
				...state,
				subCategoriesLoading: true,
			};

		case GET_SUB_CATEGORIES_SUCCESS:
			return {
				...state,
				subCategoriesLoading: false,
				subCategoriesResponse: action.payload,
			};

		case GET_SUB_CATEGORIES_FAILURE:
			return {
				...state,
				subCategoriesLoading: false,
				subCategoriesResponse: "",
				apiError: action.payload,
			};
		case GET_SUB_CATEGORIES_SUCCESS_FAILURE:
			return {
				...state,
				subCategoriesLoading: false,
				apiSuccessError: action.payload,
			};
		case GET_CONTENT_ATTRS_REQUEST:
			return {
				...state,
				getContentAttrsLoading: true,
				getContentAttrsError: false,
				getContentAttrsFlag: false
			};

		case GET_CONTENT_ATTRS_SUCCESS:
			return {
				...state,
				getContentAttrsLoading: false,
				getContentAttrsResponse: action.payload,
				getContentAttrsError: false,
				getContentAttrsFlag: true
			};

		case GET_CONTENT_ATTRS_FAILURE:
			return {
				...state,
				getContentAttrsLoading: false,
				getContentAttrsResponse: "",
				getContentAttrsError: true,
				getContentAttrsFlag: true,
				apiError: action.payload,
			};
		case GET_CONTENT_ATTRS_SUCCESS_FAILURE:
			return {
				...state,
				getContentAttrsLoading: false,
				getContentAttrsResponse: "",
				getContentAttrsError: true,
				getContentAttrsFlag: true,
				apiSuccessError: action.payload,
			};
		case RESET_CONTENT_METADATA:
			return {
				...state,
				getContentAttrsResponse: "",
				categoriesResponse: "",
				subCategoriesResponse: "",
			};
		case GET_OFFER_TYPES_REQUEST:
			return {
				...state,
				offerTypeListLoading: true,
				offerTypeListError: false,
			};

		case GET_OFFER_TYPES_SUCCESS:
			return {
				...state,
				offerTypeListLoading: false,
				offerTypeListResponse: action.payload,
				offerTypeListError: false,
			};

		case GET_OFFER_TYPES_FAILURE:
			return {
				...state,
				offerTypeListLoading: false,
				offerTypeListResponse: "",
				offerTypeListError: true,
				apiError: action.payload,
			};
		case GET_OFFER_TYPES_SUCCESS_FAILURE:
			return {
				...state,
				offerTypeListLoading: false,
				offerTypeListResponse: "",
				offerTypeListError: true,
				apiSuccessError: action.payload,
			};
		case GET_CONNECTOR_TABLE_REQUEST:
			return {
				...state,
				connectorListLoading: true,
				connectorListError: false,
			};
		case GET_CONNECTOR_TABLE_SUCCESS:
			return {
				...state,
				connectorListLoading: false,
				connectorListResponse: action.payload,
				connectorListError: false,
			};
		case GET_CONNECTOR_TABLE_FAILURE:
			return {
				...state,
				connectorListLoading: false,
				connectorListResponse: "",
				connectorListError: true,
				apiError: action.payload,
			};
		case GET_CONNECTOR_TABLE_SUCCESS_FAILURE:
			return {
				...state,
				connectorListLoading: false,
				connectorListResponse: "",
				connectorListError: true,
				apiSuccessError: action.payload,
			};

		case GET_REPORT_LINK_REQUEST:
			return {
				...state,
				reportLinkLoading: true,
				reportLinkError: false,
			};

		case GET_REPORT_LINK_SUCCESS:
			return {
				...state,
				reportLinkLoading: false,
				reportLinkResponse: action.payload,
				reportLinkError: false,
			};

		case GET_REPORT_LINK_FAILURE:
			return {
				...state,
				reportLinkLoading: false,
				reportLinkResponse: "",
				reportLinkError: true,
				apiError: action.payload,
			};
		case GET_REPORT_LINK_SUCCESS_FAILURE:
			return {
				...state,
				reportLinkLoading: false,
				reportLinkResponse: "",
				reportLinkError: true,
				apiSuccessError: action.payload,
			};
		case GET_ADD_CONNECTOR_OPTIONS_REQUEST:
			return {
				...state,
				addConnectorOptionsLoading: true,
				addConnectorOptionsError: false,
			};
		case GET_ADD_CONNECTOR_OPTIONS_SUCCESS:
			return {
				...state,
				addConnectorOptionsLoading: false,
				addConnectorOptionsResponse: action.payload,
				addConnectorOptionsError: false,
			};
		case GET_ADD_CONNECTOR_OPTIONS_FAILURE:
			return {
				...state,
				addConnectorOptionsLoading: false,
				addConnectorOptionsResponse: "",
				addConnectorOptionsError: true,
				apiError: action.payload,
			};
		case GET_ADD_CONNECTOR_OPTIONS_SUCCESS_FAILURE:
			return {
				...state,
				addConnectorOptionsLoading: false,
				addConnectorOptionsResponse: "",
				addConnectorOptionsError: true,
				apiSuccessError: action.payload,
			};
		case CREATE_CONNECTOR_REQUEST:
			return {
				...state,
				createConnectorLoding: true,
				createConnectorError: false,
			};
		case CREATE_CONNECTOR_SUCCESS:
			return {
				...state,
				createConnectorLoding: false,
				createConnectorResponse: action.payload,
				createConnectorError: false,
			};
		case CREATE_CONNECTOR_FAILURE:
			return {
				...state,
				createConnectorLoding: false,
				createConnectorResponse: "",
				createConnectorError: true,
				apiError: action.payload,
			};
		case CREATE_CONNECTOR_SUCCESS_FAILURE:
			return {
				...state,
				createConnectorLoding: false,
				createConnectorResponse: "",
				createConnectorError: true,
				apiSuccessError: action.payload,
			};
		case GET_PROGRAM_TYPES_REQUEST:
			return {
				...state,
				programTypesLoading: true,
			};

		case GET_PROGRAM_TYPES_SUCCESS:
			return {
				...state,
				programTypesLoading: false,
				programTypesResponse: action.payload,
			};

		case GET_PROGRAM_TYPES_FAILURE:
			return {
				...state,
				programTypesLoading: false,
				programTypesResponse: "",
				apiError: action.payload,
				programTypesError: null,
			};
		case IS_SYW_OFFERCODE_EXISTS:
			return {
				...state,
				isSywOfferCodeExists: action.payload
			}
		default:
			return state;
	}
};
