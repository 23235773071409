import {
  groupPeople,
  loyaltyCashOfferIcon,
  nonPurchaseOfferIcon,
  pie,
  pointEarnOfferIcon,
} from "../../../utilsComponent/SidebarIcons";
import OfferSelectionPopupComponent from "../customOfferSetupPopups/offerSelectionPopupComponent/OfferSelectionPopupComponent";
import { getDataLabelByOfferType } from "./CustomOfferBuilderUtil";

export const CUSTOM_OFFER_BUILDER_HEADER = "Offer Builder";
export const FIRST = "first";
export const LAST = "last";
export const MIDDLE = "middle";
export const initialNodes = [
  {
    id: "N1",
    position: {
      x: 0,
      y: 0,
    },
    data: {
      type: "",
      displayData: {
        defaultValue: "",
      },
      value: null,
      displayProperties: {
        nodeType: "Child",
      },
    },
    deletable: false,
    draggable: false,
    type: "nodeType",
  },
];
export const initialEdges = [];
export const customOfferNodeTypes = {
  "CMP-BATCH": {
    nodesAllowed: 1,
    actionName: "offer",
    campaignType: "Segment",
  },
  "CMP-EVENT": {
    nodesAllowed: 2,
    actionName: "START_TRIGGER",
    campaignType: "Event",
  },
};
export const nodeDataObj = {
  start_trigger: {
    name: "name",
    // count: "memberCount",
    icon1: pie,
    defaultValue: "Choose Event",
    requiredValuesInNode: ["name"],
    iconColor: "#fff",
    iconBgColor: "#2962ff",
    headerBgColor: "#f4f6ff",
    headerTextColor: "#2962ff",
    dataLabel: [
      { key: "Event", value: "name" },
      // { key: "Event", value: "triggerPoint" },
    ],
  },
  offer: (offerType) => {
    return {
      name: "name",
      // count: "memberCount",
      icon1: getOfferIcon(offerType),
      defaultValue: `Select your ${offerType} offer`,
      requiredValuesInNode: ["name"],
      iconColor: "#fff",
      iconBgColor: "#9D69A3",
      headerBgColor: "#f7f0f8",
      headerTextColor: "#9D69A3",
      dataLabel: [{ key: offerType, value: "id" }],
    };
  },
  register_offer: (offerType) => {
    return {
      name: "name",
      // count: "memberCount",
      icon1: getOfferIcon(offerType),
      defaultValue: `Select your ${offerType} offer`,
      requiredValuesInNode: ["offerID", "awardDate", "expiryDate"],
      iconColor: "#fff",
      iconBgColor: "#9D69A3",
      headerBgColor: "#f7f0f8",
      headerTextColor: "#9D69A3",
      dataLabel: [
        { key: offerType, value: "offerCode" },
        { key: "Start Time", value: "registrationStartTime" },
        { key: "End Time", value: "registrationEndTime" },
      ],
    };
  },
  award_points: (offerType) => {
    return {
      name: "name",
      // count: "memberCount",
      icon1: getOfferIcon(offerType),
      defaultValue: `Select your ${offerType} offer`,
      requiredValuesInNode: ["offerID", "awardDate", "expiryDate"],
      iconColor: "#fff",
      iconBgColor: "#9D69A3",
      headerBgColor: "#f7f0f8",
      headerTextColor: "#9D69A3",
      dataLabel: [
        { key: "Non Purchase", value: "offerCode" },
        { key: "Award Points", value: "awardPoints" },
      ],
    };
  },
};
function getOfferIcon(offerType) {
  switch (offerType) {
    case "Loyalty Cash":
      return loyaltyCashOfferIcon;
    case "Points Earn":
      return pointEarnOfferIcon;
    case "Bonus Points":
      return pointEarnOfferIcon;
    case "NonPurchase PointsEarn":
      return nonPurchaseOfferIcon;
    default:
      return null;
  }
}
export const actionProps = [
  {
    heading: "Select action",
    actions: [
      {
        displayName: "Loyalty Cash",
        key: "Loyalty Cash",
        type: "offer",
        isdisabled: false,
        icon: loyaltyCashOfferIcon,
        onClick: OfferSelectionPopupComponent,
        props: {
          offerType: "Loyalty Cash",
          suffix: "_LC",
        },
      },
      {
        displayName: "Non-purchase Points Earn",
        key: "Non-purchase Points Earn",
        type: "offer",
        isdisabled: false,
        icon: nonPurchaseOfferIcon,
        onClick: OfferSelectionPopupComponent,
        props: {
          offerType: "NonPurchase PointsEarn",
          suffix: "_NPE",
        },
      },
      {
        displayName: "Points Earn Offer",
        key: "Points Earn",
        type: "offer",
        isdisabled: true,
        icon: pointEarnOfferIcon,
        onClick: OfferSelectionPopupComponent,
        props: {
          offerType: "Points Earn",
          suffix: "_PE",
        },
      },
      {
        displayName: "Bonus Points",
        key: "bonus",
        type: "offer",
        isdisabled: true,
        icon: pointEarnOfferIcon,
        onClick: "eventListener",
      },
    ],
    showInCampaignType: ["CMP-BATCH", "CMP-EVENT"],
  },
];
export const displayNameAlias = {
  offer: "Action",
  SEGMENT: "SEGMENT",
  EMAIL: "EMAIL",
  wait: "wait",
  WAIT: "WAIT",
  START_TRIGGER: "EVENT",
  start_trigger: "EVENT",
  ACTION: "action",
  "Create Offer": "Create Offer",
  offerSetup: "offer setup",
  offerReview: "Offer Review",
  REGISTER_OFFER: "Action",
  register_offer: "Action",
  AWARD_POINTS: "Action",
  award_points: "Action",
  crme: "Subclub"
};

export const selectedDisplayNameAlias = {
  enroll_member: "crme",
  crme: "enroll_member",
  browse_product: "browse_product"
}
