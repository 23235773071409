import { nodeDataObj } from "./CampaignBuilderConstants";

export function extractTimeValues(timeString) {
	const regex = /(\d+d)?(\d+h)?(\d+m)?/;
	const match = timeString.match(regex);
	if (!match) {
		return null;
	}
	const [, days, hours, minutes] = match.map(part => {
		if (part) {
			const number = parseInt(part);
			return isNaN(number) ? "" : number;
		} else {
			return "";
		}
	});
	return [days || "", hours || "", minutes || ""];
}
export function getDisplayData(value, type , status , journeyType) {
	if (!value) {
		return {
			defaultValue: "Select a value",
			displayName: type,
		};
	}
	let displayData = {
		displayName: type,
	};
	let nodeObj =
		typeof nodeDataObj[type] === "function"
			? nodeDataObj[type]()
			: nodeDataObj[type];
	nodeObj?.dataLabel.forEach((label) => {
		if (type === "email") {
			displayData[label.value] = value.action.variations[0][label.value];
			displayData["journeyStatus"] = status;
			displayData["journeyType"] = journeyType;
		} else {
			displayData[label.value] = value.action[label.value];
		}
	});
	return displayData;
}
