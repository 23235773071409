export const DEACTIVATE_CONFIRMATION_MESSAGE =
  "Are you sure that you want to deactivate this offer?";
export const ACTIVATE_CONFIRMATION_MESSAGE =
  "Are you sure that you want to activate this offer?";
export const SAVE_AND_ACTIVATE_CONFIRMATION_MESSAGE =
  "You have unsaved changes. Are you sure you want to save and activate?";
export const LEAVING_OFFER_CREATION_PAGE_MESSAGE =
  "Are you sure you want to leave this page?";
export const LEAVING_OFFER_CREATION_PAGE_MESSAGE_CONTINUE =
  "You may have unsaved content, and will be lost unless you save it.";
export const FILE_SIZE_MESSAGE = "File size should be less than 1 MB";
export const DROPDOWN_CHANGE_MESSAGE = " Are you sure you want to change it";
export const LEAVING_DROPDOWN_CHANGE_MESSAGE =
  "You may have unsaved content, and will be lost.";
export const CREATE_NEW_CONTENT_WARINING_TITLE_MESSAGE = "Are you sure you want to create new content?";
export const CREATE_NEW_CONTENT_WARINING_MESSAGE = "You may have unsaved details, and it will be lost."
export const START_AB_TEST_CONFIRMATION_MESSAGE ="You have unsaved changes. Are you sure you want to save and start AB Testing?";
export const START_AB_TEST_MESSAGE =" Are you sure you want to start AB Testing?";
export const CREATE_EXISTING_SUBTYPE_CONNECTOR_MESSAGE = "A connector of this subtype already exists. Creating a new one will overwrite the existing connector. Are you sure you want to proceed?"
