import {
  checkRightsIsEditable,
  getAllRights,
  getRightsKey,
} from "../../utilsComponent/CommonUtil";
import {
  buyOneGetOneOfferIcon,
  pointEarnOfferIcon,
  pointsCorrectionOfferIcon,
  priceDiscountOfferIcon,
  loyaltyCashOfferIcon,
  baseRedeemOfferIcon,
  missionOfferIcon,
  baseEarnOfferIcon,
  customOfferIcon,
  nonPurchaseOfferIcon,
  eventOfferIcon,
} from "../../utilsComponent/SidebarIcons";

export const CREATE_OFFER_MODAL_TITLE = "Start setting up your offer...";
export const CREATE_OFFER_TITLE = "What is the Offer Type";
export const CREATE_OFFER_POINTS_TITLE = "Points earn offer";
export const CREATE_OFFER_POINTS_HEADER =
  "Create offer to award points to member based on qualifying activity";
export const CREATE_OFFER_POINTS_DESCRIPTION =
  "e.g. Award 10% points on spend of over $50, Award 150,000 in points for making a purchase on Mother's day";
export const CREATE_OFFER_PRICE_TITLE = "Price discount offer";
export const CREATE_OFFER_PRICE_HEADER =
  "Create offer to give price discount to member based on qualifying activity";
export const CREATE_OFFER_PRICE_DESCRIPTION =
  "e.g. 5% discount on Mother's day; $10 off on spend of $50 and more";
export const OFFER_NAME_LABEL = "Offer Name";
export const OFFER_TYPES = [
  {
    title: "Points Earn Offer",
    key: "Points Earn",
    header:
      "Create Offer to award points to member based on qualifying activity.",
    description: "E.g. Award 10% points on spend of over $50.",
    disabled: false,
    icon: pointEarnOfferIcon,
    isCustom: false,
    rights: ["popup", "loyalty", "createoffer", "pointsearnoffer"],
  },
  {
    title: "Price Discount Offer",
    key: "Price Discount",
    header:
      "Create Offer to give price discount to member based on qualifying activity.",
    description: "E.g: Provide 5% price discount on Mother's day.",
    disabled: false,
    icon: priceDiscountOfferIcon,
    rights: ["popup", "loyalty", "createoffer", "pointsdiscountoffer"],
  },
  {
    title: "Loyalty Cash Offer",
    key: "Loyalty Cash",
    header:
      "Create offer to award surprise points which can be used in current transaction.",
    description:
      "E.g. provide $10 in points if member is buying a particular product.",
    disabled: false,
    icon: loyaltyCashOfferIcon,
    rights: ["popup", "loyalty", "createoffer", "loyaltycashoffer"],
  },
  {
    title: "Base Earn Offer",
    key: "Base Earn",
    header: "Create Offer to award guaranteed base points for all purchases.",
    description: "E.g: Provide 10 points per dollar spend for every purchase.",
    disabled: false,
    icon: baseEarnOfferIcon,
    rights: ["popup", "loyalty", "createoffer", "baseearnoffer"],
  },
  {
    title: "Base Redeem Offer",
    key: "Base Redeem",
    header:
      "Create offer to define points to dollar conversion ratio for point redemption.",
    description: "E.g. 1,000 points are equivalent to $1.",
    disabled: false,
    icon: baseRedeemOfferIcon,
    rights: ["popup", "loyalty", "createoffer", "baseredeemoffer"],
  },
  {
    title: "Mission Offer",
    key: "Mission",
    header:
      "Create offer to award points when member completes trip/spend based mission.",
    description: "E.g. Award $5 for completing 3 trips in a month.",
    disabled: false,
    icon: missionOfferIcon,
    rights: ["popup", "loyalty", "createoffer", "missionoffer"],
  },
  {
    title: "Custom Offer",
    key: "customOffer",
    header: "Create offer to issue promotions based on custom triggers.",
    description:
      "E.g. Award $10 worth of Loyalty Cash if member is added to a subclub.",
    disabled: false,
    icon: customOfferIcon,
    isCustom: true,
    rights: ["popup", "loyalty", "createoffer", "customoffer"],
  },
  {
    title: "Non-Purchase Points Earn",
    key: "Non-purchase Points Earn",
    header:
      "Create offer to inject points into member's account without any purchase or events.",
    description:
      "E.g. Award $10 to all member in hurricane-affected East coast.",
    disabled:false,
    icon: nonPurchaseOfferIcon,
    rights: ["popup", "loyalty", "createoffer", "nonpurchasepointsearn"],
  },
  {
    title: "Event Process Offer",
    key: "Event Offer",
    header:
      "(For Developers only). Create event offer to implement complex logic with less code.",
    description:
      "E.g. Calling external APIs and processing the response.",
    disabled: false,
    icon: eventOfferIcon,
    rights: ["popup", "loyalty", "createoffer", "eventprocessoffer"]
  },  
];

export const OFFER_TYPE_ALIAS = {
  "Points Earn": "Points Earn",
  "Price Discount": "Price Discount",
  "Loyalty Cash": "Loyalty Cash",
  "Base Earn": "Base Earn",
  "Base Redeem": "Base Redeem",
  Mission: "Points Earn",
  customOffer: "Custom Offer",
  "Non-purchase Points Earn": "NonPurchase PointsEarn",
  "Event Offer":"Event Offer",
};
