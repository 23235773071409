import { images } from "../imagesComponent/Images";

export const API_TOKEN = "API Token";
export const CONNECTOR = "Connector";

export const connector_colums = [
  {
    title: "Connector Name",
    key: "name",
    type: "string",
    visible: true,
  },
  {
    title: "Type",
    key: "type",
    type: "string",
    visible: true,
  },
  {
    title: "Sub Type",
    key: "subType",
    type: "string",
    visible: true,
  },
  {
    title: "Description ",
    key: "description",
    type: "string",
    visible: true,
  },
];

export const dummy_connector_data = [
  {
    connector: "Marigold Engage",
    createdBy: "User222",
    createdDate: "02/06/2024",
  },
  {
    connector: "Klaviyo",
    createdBy: "User282",
    createdDate: "02/26/2024",
  },
];

export const setup_connector_list_details = [
  {
    connectorName: "Marigold Engage",
    key: "marigoldEngage",
    connectorLogo: images.marigoldEngageLogo,
    isVisible: true,
    isDisabled: false,
    width: 140,
    height: 140,
  },
  {
    connectorName: "Klaviyo",
    key: "klaviyo",
    connectorLogo: images.klaviyoLogo,
    isVisible: true,
    isDisabled: false,
    width: 120,
    height: 160,
  },
  {
    connectorName: "Mail Chimp",
    key: "mailChimp",
    connectorLogo: images.mailChimpLogo,
    isVisible: true,
    isDisabled: false,
    width: 130,
    height: 80,
  },
  {
    connectorName: "Sales Force",
    key: "salesForce",
    connectorLogo: images.salesForceLogo,
    isVisible: true,
    isDisabled: false,
    width: 220,
    height: 220,
  },
  {
    connectorName: "Convert kit",
    key: "convertkit",
    connectorLogo: images.convertkitLogo,
    isVisible: true,
    isDisabled: false,
    width: 100,
    height: 100,
  },
];

export const connector_options_image_urls = {
  Sailthru: { src: images.marigoldEngageLogo, width: 140, height: 140 },
  klaviyo: { src: images.klaviyoLogo, width: 120, height: 160 },
  Klaviyo: { src: images.klaviyoLogo, width: 120, height: 160 },
  MySQL: { src: images.mySqlLogo, width: 120, height: 100 },
  SAILTHRU: { src: images.marigoldEngageLogo, width: 140, height: 140 },
};

export const connectorInputFieldsJSON = {
  inputType: "",
  displayName: "",
  fieldname: "",
  validation: {
    required: false,
    minLength: 0,
    maxLength: 0,
    pattern: "",
    patternMessage: "",
  },
  theme: "light",
  disabled: false,
};

export const inputTypes = {
  string: "text",
};
