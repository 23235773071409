import React, { useEffect, useState } from "react";
import Select from "react-select";
import { getCustomStyles } from "../../../campaignDetailsComponent/CampaignDetailsUtil";
import "./EmailContentSelectionPopup.style.scss";
import {
  BACK,
  CONTENT_TYPE_INFO_1,
  CONTENT_TYPE_INFO_2,
  PROCEED,
  SENDER_DISPLAY_NAME,
  SENDER_EMAIL,
  SUBJECT_LINE,
  TEMPLATE,
  displayNameTemp,
  testBasedDropdownValues,
  templateDropdownValues,
  senderEmailDropdownValues,
  selectedEmailContentKeys,
  ACTION,
  variationKeys,
  email_variation_json,
  SUBJECTLINE,
  NONE,
  EMAIL,
} from "../../CampaignBuilderConstants";
import CampaignActionComponent from "../campaignActionComponent/CampaignActionComponent";
import { updateNodeData } from "../../../../utilsComponent/GenericFlow/GenericFlowUtil";
import ABTestingSetup from "../../../campaignABTestingComponent/ABTestingSetup/ABTestingSetup";
import ContentPopup from "./ContentPopup";
import {
  getCommonJSON,
  getTestBasedArrayForEmail,
} from "./EmailContentSelectionUtil";
import ChangeCampaignActionEmailDropDownValue from "../../../../modelComponent/confirmationABTestEmailDropDownComponent/ChangeCampaignActionEmailDropDownValue";
import ABTestingWrapper from "../../../campaignABTestingComponent/ABTestingWrapper";
import { generateUniqueVariationId } from "../../../../utilsComponent/CommonUtil";
import { is } from "date-fns/locale";
import { useNavigate } from "react-router-dom";
import CreateContentConfirmationComponent from "../../../../modelComponent/createContentConfirmationComponent/CreateContentConfirmationComponent";
import { useSelector } from "react-redux";

const EmailContentSelectionPopup = ({
  theme,
  setPopupShowProcess,
  currentClickedNode,
  setNodeData,
  setComponentName,
  setScreen,
  isDisabled,
  // setOpenContentPopup,
}) => {
  let navigate = useNavigate();
  const [openContentPopup, setOpenContentPopup] = useState(false);
  const journeyDetailsData = useSelector((state) => state.journeyDetailsData);
  const journeyData = journeyDetailsData?.journeyDetailsResponse?.journeyData;
  const [selectedEmailContentData, setSelectedEmailContentData] = useState({
    contentId:
      currentClickedNode?.data?.value?.action?.variations[0]?.contentId,
    name: currentClickedNode?.data?.value?.action?.variations[0]?.contentName,
  });
  const [openEmailContent, setOpenEmailContent] = useState(false);
  const [validation, setValidation] = useState({
    subject: {
      error: false,
      errorMessage: "",
      touched: false,
    },
    senderEmail: {
      error: false,
      errorMessage: "",
      touched: false,
    },
    senderDisplayName: {
      error: false,
      errorMessage: "",
      touched: false,
    },
  });
  const [emailContentType, setEmailContentType] = useState(
    currentClickedNode?.data?.value?.action?.abTestType
      ? currentClickedNode?.data?.value?.action?.abTestType === SUBJECTLINE
        ? {
            label: "Subject Line",
            value: "subject_line",
          }
        : {
            label: "None",
            value: "none",
          }
      : {
          label: "None",
          value: "none",
        }
    // currentClickedNode?.data?.value?.action?.abTestEnabled
    //   ? currentClickedNode?.data?.value?.action?.abTestEnabled === "N"
    //     ? {
    //         label: "None",
    //         value: "none",
    //       }
    //     : {
    //         label: "Subject Line",
    //         value: "subject_line",
    //       }
    //   : {
    //       label: "None",
    //       value: "none",
    //     }
  );
  const [variations, setVariations] = useState(
    getTestBasedArrayForEmail(emailContentType.value)
  );
  const [emailContentSelectionValue, setEmailContentSelectionValue] = useState(
    {}
  );
  const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);
  const [isChangeDropDownValue, setIsChangeDropDownValue] = useState(false);
  const [testDropDownValue, setTestDropDownValue] = useState({});
  const [showPopup, setShowPopup] = useState({
    content: "",
    isVisible: false,
  });

  //------------------------------------------------------------------------------------------------
  //------------------------------------------------------------------------------------------------
  //------------------------------------------------------------------------------------------------

  const [testBasedData, setTestBasedData] = useState(
    currentClickedNode?.data?.value
      ? currentClickedNode?.data?.value?.action?.variations
        ? currentClickedNode?.data?.value?.action?.variations
        : getTestBasedArrayForEmail(emailContentType.value)
      : getTestBasedArrayForEmail(emailContentType.value)
  );
  const [commonData, setCommonData] = useState(() => {
    let commonData = {};
    Object.keys(email_variation_json[emailContentType.value].common).map(
      (key) => {
        commonData[key] =
          currentClickedNode?.data?.value?.action?.variations[0][key];
      }
    );
    return commonData;
  });

  const [showComponent, setShowComponent] = useState("ab-testing-setup");
  const [screenMode, setScreenMode] = useState("min");
  const isAbTestPresent =
    // emailContentType.value === "subject_line" ||
    journeyDetailsData?.journeyDetailsResponse?.journeyFlow?.nodes?.some(
      (node) =>
        node?.id === currentClickedNode?.id &&
        node?.data?.value?.action?.abTestEnabled === "Y"
    );
  //-----------------------------------------------------------------------------------------------
  const [showEmaiStat, setShowEmaiStat] = useState(
    currentClickedNode?.data?.type?.toLowerCase() === EMAIL &&
      journeyDetailsData?.journeyDetailsResponse?.journeyFlow?.nodes?.some(
        (node) => {
          return currentClickedNode.id === node.id;
        }
      )
  );
  useEffect(() => {
    if (isChangeDropDownValue) {
      setTestBasedData(getTestBasedArrayForEmail(testDropDownValue.value));
      setCommonData(getCommonJSON(testDropDownValue.value));
      setEmailContentType(testDropDownValue);
      setSelectedEmailContentData({});
      setIsChangeDropDownValue(false);
    }
  }, [isChangeDropDownValue]);
  useEffect(() => {
    let newJson = {};
    if (testBasedData.length <= 1) {
      Object.keys(variationKeys).map((variation) => {
        newJson[variation] = commonData[variation];
      });
      setTestBasedData([newJson]);
    }
  }, [commonData]);
  useEffect(() => {
    if (selectedEmailContentData) {
      let contentData = commonData;
      selectedEmailContentKeys.keys.map((contentKey) => {
        Object.keys(commonData).map((commonDataKey) => {
          if (contentKey === commonDataKey) {
            contentData = {
              ...contentData,
              [commonDataKey]: selectedEmailContentData[contentKey]
                ? selectedEmailContentData[contentKey]
                : "",
            };
          }
        });
      });
      setCommonData(contentData);
    }
  }, [selectedEmailContentData]);

  useEffect(() => {
    if (!commonData?.templateId) {
      commonData.templateId = "horizontal";
    }
    const emailSelectionNodeData = {
      value: {
        action: {
          abTestEnabled: emailContentType.value === "subject_line" ? "Y" : "N",
          // abTestValue: emailContentType.value,
          variations: testBasedData.map((vars) => {
            return {
              ...vars,
              contentId: commonData?.contentId,
              variationId: vars.variationId
                ? vars.variationId
                : generateUniqueVariationId(),
              percentage:
                testBasedData.length === 1
                  ? "100"
                  : vars.percentage
                  ? vars.percentage
                  : "", //to be changed
              senderDisplayName: commonData?.senderDisplayName,
              senderEmail: commonData?.senderEmail,
              templateId: commonData?.templateId,
              contentName: commonData?.name,
            };
          }),
        },
      },
      displayData: {
        ...commonData,
        contentName: commonData?.name,
        journeyStatus: journeyData?.journeyStatus,
        journeyType: journeyData?.journeyType,
      },
    };
    if (emailContentType.value === "subject_line") {
      emailSelectionNodeData.value.action.abTestType = SUBJECTLINE;
    } else {
      emailSelectionNodeData.value.action.abTestType = NONE;
    }
    setEmailContentSelectionValue(emailSelectionNodeData);
  }, [commonData, testBasedData]);

  //------------------------------------------------------------------------------------------------

  const updateCommonData = (name, value) => {
    let updatedCommonData = commonData;
    Object.keys(commonData).map((variationKey) => {
      if (variationKey === name) {
        if (variationKey === "senderEmail") {
          updatedCommonData = {
            ...updatedCommonData,
            [variationKey]: value,
            senderDisplayName: displayNameTemp[value],
          };
        } else {
          updatedCommonData = { ...commonData, [variationKey]: value };
        }
      }
    });
    setCommonData(updatedCommonData);
  };

  //------------------------------------------------------------------------------------------------
  //------------------------------------------------------------------------------------------------
  //------------------------------------------------------------------------------------------------

  // const changeSubjectline = (value, variation) => {
  //  const updatedVariations = variations.map((v) => {
  //      if (v.variationId === variation.variationId) {
  //          return { ...v, subject: value };
  //      }
  //      return v;
  //  });
  //  setVariations(updatedVariations);
  // };

  // onChangeHandler
  function checkValidation() {
    let isValid = true;
    if (commonData?.subject) {
      if (commonData.subject === "") {
        setValidation((prev) => {
          return {
            ...prev,
            subject: {
              error: true,
              errorMessage: "Subject is required",
              touched: true,
            },
          };
        });
        isValid = false;
      } else if (commonData.subject.length > 30) {
        setValidation((prev) => {
          return {
            ...prev,
            subject: {
              error: true,
              errorMessage: "Subject should be less than 30 characters",
              touched: true,
            },
          };
        });
        isValid = false;
      }
    }
    if (commonData.senderEmail === "") {
      setValidation((prev) => {
        return {
          ...prev,
          senderEmail: {
            error: true,
            errorMessage: "Sender Email is required",
            touched: true,
          },
        };
      });
      isValid = false;
    }
    if (commonData.senderDisplayName === "") {
      setValidation((prev) => {
        return {
          ...prev,
          senderDisplayName: {
            error: true,
            errorMessage: "Sender Display Name is required",
            touched: true,
          },
        };
      });
      isValid = false;
    } else if (commonData.senderDisplayName.length > 25) {
      setValidation((prev) => {
        return {
          ...prev,
          senderDisplayName: {
            error: true,
            errorMessage:
              "Sender display name should be less than 25 characters",
            touched: true,
          },
        };
      });
      isValid = false;
    }
    return isValid;
  }
  return (
    <>
      {/* {journeyData?.journeyType === "CMP-BATCH" && ( */}
      <div className="tab-container">
        <div
          className={`tab1 ${
            showComponent === "ab-testing-setup" ? "selected" : null
          }`}
          onClick={() => {
            setShowComponent("ab-testing-setup");
            setScreen((prev) => {
              return {
                ...prev,
                resize: false,
                maximize: false,
              };
            });
          }}
        >
          <div className="triangle"></div>
          Email content details
        </div>
        <div
          className={`tab2 ${
            showComponent === "test-result" ? "selected" : null
          } ${!showEmaiStat ? "disabled-tab" : "null"}`}
          onClick={() => {
            if (showEmaiStat) {
              setShowComponent("test-result");
              setScreen((prev) => {
                return {
                  ...prev,
                  resize: true,
                };
              });
            }
          }}
        >
          <div className="triangle"></div>
          {isAbTestPresent ? "A/B test results" : "Email Stats"}
        </div>
      </div>
      {/* )} */}

      {showComponent === "ab-testing-setup" && (
        <>
          <div className="email-content content-popup-wrapper">
            <div className="content-popup-body">
              {/* <span className="ml-10">
            A/B Testing
            <label className="switch">
              <input
                type="checkbox"
                onChange={() => {
                  setComponentName({
                    name: ABTestingSetup,
                  });
                }}
                // checked={
                //   // btnMemberClicked ? enableMemberCap : isMemberCapExist
                // }
                // disabled={isSetDisable}
              />
              <span className="slider round"></span>
            </label>
          </span> */}

              {/* {journeyData?.journeyType === "CMP-BATCH" && ( */}
              <fieldset
                className="content-type-dropdown test-fieldset dropdown"
                disabled={isDisabled}
              >
                <legend>{"A/B Test Based on"}</legend>
                <Select
                  value={{
                    label: emailContentType.label,
                    value: emailContentType.value,
                  }}
                  options={testBasedDropdownValues}
                  styles={getCustomStyles(theme)}
                  menuPlacement="auto"
                  isSearchable={false}
                  onChange={({ label, value }) => {
                    if (emailContentType.value !== value) {
                      setShowPopup({
                        content: "dropdownConfirmationPopup",
                        isVisible: true,
                      });
                      setTestDropDownValue({ label: label, value: value });
                    }
                    // setTestBasedData(getTestBasedArrayForEmail(value));
                    // setCommonData(getCommonJSON(value));
                    // setEmailContentType({ label: label, value: value });
                    // setSelectedEmailContentData({});
                  }}
                />
              </fieldset>
              {/* )} */}

              {openEmailContent && (
                <ContentPopup
                  theme={theme}
                  selectedEmailContentData={selectedEmailContentData}
                  setSelectedEmailContentData={setSelectedEmailContentData}
                  setOpenEmailContent={setOpenEmailContent}
                />
              )}

              {/* ---------------------------------------------------------------------------------------- */}
              {/* ------------------------------------------COMMON STARTS----------------------------------- */}
              {/* ---------------------------------------------------------------------------------------- */}

              <div className="input-row-1">
                {Object.keys(
                  email_variation_json[emailContentType.value].common
                ).map((key, index) => {
                  return (
                    <>
                      {key === "contentId" && (
                        <div key={index}>
                          <div className="test-content-section">
                            <p>
                              <span className="required border-none">
                                Content
                              </span>{" "}
                              :{" "}
                              <span
                                className={`${isDisabled ? "disabled" : ""}`}
                              >
                                <span
                                  onClick={() => {
                                    if (!isDisabled) {
                                      setOpenEmailContent(true);
                                    }
                                  }}
                                >
                                  {commonData.name
                                    ? commonData.name
                                    : "Select content here"}
                                </span>
                              </span>{" "}
                            </p>
                          </div>
                          {!isDisabled && (
                            <strong
                              className="fs-13 mt-6"
                              // style={{ paddingLeft: "10px" }}
                              onClick={() => {
                                // setShowOfferPopup(true)
                              }}
                            >
                              {CONTENT_TYPE_INFO_1}{" "}
                              <span
                                className="create-content-link"
                                onClick={() => {
                                  setShowPopup({
                                    content:
                                      "confirmationCreateNewContentPopup",
                                    isVisible: true,
                                  });
                                }}
                              >
                                {CONTENT_TYPE_INFO_2}
                              </span>
                            </strong>
                          )}
                        </div>
                      )}
                      {/* -------------------------------------------subject------------------------------------ */}
                      {key === "subject" && (
                        <>
                          {/* {JSON.stringify(variations)} */}
                          <fieldset
                            className="text-box-preview"
                            disabled={isDisabled}
                          >
                            <legend className="required">{SUBJECT_LINE}</legend>
                            <textarea
                              className="input-text-textarea-preview"
                              rows="2"
                              cols="68"
                              name="subject-line"
                              maxLength="30"
                              value={commonData[key]}
                              onChange={(e) => {
                                updateCommonData(key, e.target.value);
                              }}
                              disabled={isDisabled}
                            />
                            <small className="error-message subject">
                              {validation.subject.touched &&
                              validation.subject.error
                                ? validation.subject.errorMessage
                                : null}
                            </small>
                          </fieldset>
                        </>
                      )}
                      {/* -------------------------------------Template/templateId------------------------------------ */}
                      {key === "templateId" && (
                        <fieldset
                          className="test-fieldset dropdown template-dropdown"
                          disabled={isDisabled}
                        >
                          <legend>{TEMPLATE}</legend>
                          <Select
                            value={{
                              label: commonData[key]
                                ? commonData[key]
                                : "horizontal",
                              value: commonData[key]
                                ? commonData[key]
                                : "horizontal",
                            }}
                            options={templateDropdownValues}
                            styles={getCustomStyles(theme)}
                            menuPlacement="auto"
                            onChange={(e) => {
                              updateCommonData(key, e.value);
                              // setSelectedContent((prev) => {
                              //   return {
                              //     ...prev,
                              //     templateId: e.value,
                              //   };
                              // });
                            }}
                            isSearchable={false}
                          />
                        </fieldset>
                      )}
                      {/* -------------------------------------Sender Email/senderEmail------------------------------------ */}
                      {key === "senderEmail" && (
                        <fieldset
                          className="email-dropdown dropdown"
                          disabled={isDisabled}
                        >
                          <legend className="required">{SENDER_EMAIL}</legend>
                          <Select
                            value={{
                              label: commonData[key],
                              value: displayNameTemp[commonData[key]],
                            }}
                            options={senderEmailDropdownValues}
                            styles={getCustomStyles(theme)}
                            menuPlacement="auto"
                            onChange={(e) => {
                              updateCommonData(key, e.value);
                              // setSelectedContent((prev) => {
                              //   return {
                              //     ...prev,
                              //     senderEmail: e.value,
                              //     senderDisplayName: displayNameTemp[e.value],
                              //   };
                              // });
                            }}
                            isSearchable={false}
                          />
                          <small className="error-message email">
                            {validation.senderEmail.touched &&
                            validation.senderEmail.error
                              ? validation.senderEmail.errorMessage
                              : null}
                          </small>
                        </fieldset>
                      )}
                      {/* -------------------------------------Sender Display Name/name------------------------------------ */}
                      {key === "senderDisplayName" && (
                        <fieldset
                          className="sender-display-input"
                          disabled={true}
                        >
                          <legend className="required">
                            {SENDER_DISPLAY_NAME}
                          </legend>
                          <input
                            type="text"
                            value={commonData[key]}
                            maxLength="25"
                            // onChange={(e) => {
                            //  setSelectedContent((prev) => {
                            //      return {
                            //          ...prev,
                            //          senderDisplayName: e.target.value,
                            //      };
                            //  });
                            // }}
                            readOnly
                          ></input>
                          <small className="error-message sender-display-name">
                            {validation.senderDisplayName.touched &&
                            validation.senderDisplayName.error
                              ? validation.senderDisplayName.errorMessage
                              : null}
                          </small>
                        </fieldset>
                      )}
                      {/* ---------------------------------------------------------------------------------------- */}
                      {/* ------------------------------------------COMMON ENDS----------------------------------- */}
                      {/* ---------------------------------------------------------------------------------------- */}
                    </>
                  );
                })}
              </div>
              {/* --------------------------------------------- variations-----------------------------------------*/}
              {emailContentType.value === "subject_line" && (
                <ABTestingSetup
                  variations={testBasedData}
                  setVariations={setTestBasedData}
                  isDisabled={isDisabled}
                  journeyType={journeyData?.journeyType}
                />
              )}
            </div>
            <footer className="campaign-modal-footer">
              <button
                className={`btn btn-primary continue-setup-btn ${
                  commonData?.contentId === "" ? "disable" : ""
                }`}
                disabled={
                  !commonData?.contentId ||
                  !commonData.senderEmail ||
                  isDisabled
                    ? true
                    : false
                }
                onClick={() => {
                  if (checkValidation()) {
                    updateNodeData(
                      currentClickedNode,
                      setNodeData,
                      "EMAIL",
                      setPopupShowProcess,
                      emailContentSelectionValue
                    );
                  }
                }}
              >
                {PROCEED}
              </button>
              {currentClickedNode?.newNode && (
                <button
                  className="btn btn-secondary"
                  onClick={() => {
                    setComponentName({
                      name: CampaignActionComponent,
                      displayName: ACTION,
                    });
                  }}
                >
                  {BACK}
                </button>
              )}
            </footer>
          </div>
          {showPopup.content === "dropdownConfirmationPopup" &&
            showPopup.isVisible && (
              <ChangeCampaignActionEmailDropDownValue
                setShowPopup={setShowPopup}
                setIsChangeDropDownValue={setIsChangeDropDownValue}
              />
            )}
          {showPopup.content === "confirmationCreateNewContentPopup" &&
            showPopup.isVisible && (
              <CreateContentConfirmationComponent
                setShowPopup={setShowPopup}
                setOpenContentPopup={setOpenContentPopup}
              />
            )}
        </>
      )}

      {showComponent === "test-result" && (
        <ABTestingWrapper
          showComponent={showComponent}
          setShowComponent={setShowComponent}
          node={currentClickedNode}
          setScreen={setScreen}
          isDisabled={isDisabled}
          setPopupShowProcess={setPopupShowProcess}
          winnerVariationId={
            currentClickedNode?.data?.value?.action?.winnerVariationId
          }
          emailContentType={emailContentType}
        />
      )}
    </>
  );
};

export default EmailContentSelectionPopup;
