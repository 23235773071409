import {
  ANY,
  ATTR_DEFAULT_VALUE,
  ENTITY_DEFAULT_VALUE,
  ID,
  PASSES,
  OPERATOR_DEFAULT_VALUE,
  POPOVER_MENU_ITEM_1,
  POPOVER_MENU_ITEM_2,
  RULE,
  SUBGROUP,
  VALUE_DEFAULT_VALUE,
} from "./GenericRulesConstants";
import { addNewfilter, generateUniqueId } from "./GenericRulesUtil";

export function PopOver({ popover, setPopover, setExpression }) {
  // Add new subgroup/rule to expression
  const handlePopoverMenuClick = (newFilterType) => {
    if (newFilterType === SUBGROUP) {
      let newSubGrp = {
        id: generateUniqueId(),
        type: SUBGROUP,
        combination: PASSES,
        join: ANY,
        filters: [],
      };
      setExpression((prevExpression) => {
        const { entireObj } = addNewfilter(
          prevExpression,
          ID,
          popover.elementId,
          newSubGrp
        );
        return [...entireObj];
      });
    } else {
      let newRule = {
        id: generateUniqueId(),
        type: RULE,
        entity: ENTITY_DEFAULT_VALUE,
        attr: ATTR_DEFAULT_VALUE,
        operator: OPERATOR_DEFAULT_VALUE,
        value: VALUE_DEFAULT_VALUE,
      };
      setExpression((prevExpression) => {
        const { entireObj } = addNewfilter(
          prevExpression,
          ID,
          popover.elementId,
          newRule
        );
        return [...entireObj];
      });
    }
    setPopover({ status: false, elementId: null });
  };
  return (
    <>
      {popover.status === true && (
        <div className="popover-container">
          <div
            className="popover-backdrop"
            onClick={() => {
              setPopover({
                status: false,
                elementId: null,
              });
            }}
          ></div>
          <div className="popover">
            <div
              className="menu-item"
              onClick={() => {
                handlePopoverMenuClick(SUBGROUP);
              }}
            >
              {POPOVER_MENU_ITEM_1}
            </div>
            <div
              className="menu-item"
              onClick={() => {
                handlePopoverMenuClick(RULE);
              }}
            >
              {POPOVER_MENU_ITEM_2}
            </div>
          </div>
        </div>
      )}
    </>
  );
}
