import { getGenericTableDetails } from "../../../tellurideExtRedux";
import { OFRI, headers } from "../../constantComponent/Constants";
import { getRightsFromSession, skey } from "../CommonUtil";
import {
  ASCENDING,
  DESCENDING,
  SORTED,
  ZERO,
} from "../GenericRules/GenericRulesConstants";

export function handleSort(
  tableData,
  setTableData,
  isSorting,
  setIsSorting,
  order,
  sortKey
) {
  if (tableData && tableData.length > ZERO) {
    if (order === isSorting.order) {
      setTableData(() => [...tableData]);
      setIsSorting({
        order: null,
        status: null,
      });
    } else {
      if (order === ASCENDING) {
        let sortedList = tableData.sort(
          ({ [sortKey]: attr1 }, { [sortKey]: attr2 }) =>
            attr1.localeCompare(attr2)
        );
        setTableData([...sortedList]);
        setIsSorting({
          order: ASCENDING,
          status: SORTED,
        });
      } else if (order === DESCENDING) {
        let sortedList = tableData.sort(
          ({ [sortKey]: attr1 }, { [sortKey]: attr2 }) =>
            attr2.localeCompare(attr1)
        );
        setTableData([...sortedList]);
        setIsSorting({
          order: DESCENDING,
          status: SORTED,
        });
      }
    }
  } else {
    return;
  }
}

export function getSmallGenericTableList(
  API_URL,
  controller,
  authOrganizationName,
  dispatch,
  smallGenericTableRquestBody
) {
  let apiurl = process.env.REACT_APP_URL_DOMAIN + API_URL;
  headers.sessionId = skey();
  headers.requestAccess = getRightsFromSession();
  headers.organization = authOrganizationName;
  const requestHeaders = {
    headers,
  };

  let getGeneralListRequestBody = smallGenericTableRquestBody;
  dispatch(
    getGenericTableDetails(
      requestHeaders,
      getGeneralListRequestBody,
      apiurl,
      controller
    )
  );
}
