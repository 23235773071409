import { stratify, tree } from "d3-hierarchy";
import { MarkerType, useReactFlow } from "reactflow";
import { LIGHT } from "../../constantComponent/Constants";
import {
  EMAIL,
  nodeDataObj,
} from "../../campaignSetupComponent/campaignBuilderComponent/CampaignBuilderConstants";
import { v4 as uuidv4 } from "uuid";
import { toTitleCase } from "../CommonUtil";

export const getLayoutedElements = (nodes, edges) => {
  const g = tree();
  const nodeWidth = 150;
  const nodeHeight = 80;
  if (nodes.length === 0) return { nodes, edges };
  const width = nodeWidth;
  const height = nodeHeight;
  const hierarchy = stratify()
    .id((node) => node.id)
    .parentId((node) => edges.find((edge) => edge.target === node.id)?.source);
  const root = hierarchy(nodes);
  const layout = g.nodeSize([width * 1.5, height * 1.5])(root);

  return {
    nodes: layout.descendants().map((node) => {
      return {
        ...node.data,
        position: { x: node.x, y: node.y },
      };
    }),
    edges,
  };
};
export function generateUniqueNodeId() {
  return uuidv4().replace(/-/g, "");
}
export function createNode(actionType) {
  return {
    id: `N-${generateUniqueNodeId()}`,
    position: {
      x: 0,
      y: 0,
    },
    data: {
      type: actionType,
      displayName: actionType,
      value: {
        defaultValue: "",
      },
      displayProperties: {
        nodeType: "Child",
      },
    },
    deletable: false,
    draggable: false,
    type: "nodeType",
  };
}
export function createEdge(source, target, type = "step", label, theme) {
  return {
    id: `E-${source}-${target}`,
    source: source,
    target: target,
    type: type,
    label: label ? label : null,
    deletable: false,
    markerEnd: {
      type: MarkerType.ArrowClosed,
      width: 20,
      height: 20,
      color: theme === LIGHT ? "#b1b1b7" : "#ffffff",
    },
  };
}
export const getId = (count, setCount) => {
  let tempCount = count + 1;
  setCount(tempCount);
  return `${tempCount}`;
};
//take to generic
function getValueJson(actionName, selectedData) {
  let requiredValueObject = {};
  nodeDataObj[actionName].requiredValuesInNode.forEach((element) => {
    requiredValueObject[element] = selectedData[element]
      ? selectedData[element]
      : "";
  });
  return requiredValueObject;
}

// function getDisplayDataJson(actionName , selectedData) {
//   let requiredDisplayObject = {};
//   nodeDataObj[actionName].displayDataInNode.forEach((element) => {
//     //selectedData[element]
//     requiredDisplayObject[element] =
//   });
//   return requiredDisplayObject;
// }
export function updateNodeData(
  currentClickedNode,
  setNodeData,
  actionName,
  setPopupShowProcess,
  selectedData
) {
  // let valueJson = getValueJson(actionName, selectedData);
  setNodeData({
    id:
      currentClickedNode.newNode === false ? currentClickedNode.id : undefined,
    data: {
      type: actionName,
      displayData: { ...selectedData.displayData, displayName: actionName },
      value: selectedData.value,
    },
  });

  setPopupShowProcess(false);
  return currentClickedNode;
}

export function hasChild(edges, id) {
  return edges.map((edge) => {
    if (edge.source === id) {
      return true;
    }
  });
}
export const deletNode = (
  currentNode,
  edges,
  nodes,
  setFormatTree,
  setEdges,
  setNodes
) => {
  let edgesTemp = edges.filter((val) => !(val.target === currentNode.id));
  let parentSource = edges.filter((val) => val.target === currentNode.id);
  let flag = true;
  setEdges(
    edgesTemp.map((edge) => {
      if (edge.source === currentNode.id) {
        flag = false;
        edge.source = parentSource[0].source;
      }
      return edge;
    })
  );
  setNodes(nodes.filter((val) => !(val.id === currentNode.id)));
  setFormatTree((prev) => !prev);
};
export const getDisplayName = (name, displayNameJson) => {
  name = displayNameJson[name];
  if (name) {
    return toTitleCase(name);
    // return name.toUpperCase();
  } else {
    return "";
  }
};
export const checkABTestIndicator = (data) => {
  if (
    data?.type?.toLowerCase() === EMAIL &&
    data?.value?.action?.abTestEnabled === "Y"
  ) {
    if (data?.displayData?.journeyType === "CMP-BATCH") {
      return data?.displayData?.journeyStatus === "AB-TESTING";
    } else if (data?.displayData?.journeyType === "CMP-EVENT") {
      return data?.displayData?.journeyStatus === "ACTIVATED";
    }
  }
  return false;
};
