import React, { useState, useEffect } from "react";
import "./Toaster.style.scss";
import { TOASTER_ERROR } from "../constantComponent/Constants";
import { useDispatch, useSelector } from "react-redux";
import { ZERO } from "../utilsComponent/GenericRules/GenericRulesConstants";
import { deleteToasterList } from "../../tellurideExtRedux";
import { IoClose } from "react-icons/io5";
import { AnimatePresence, motion } from "framer-motion/dist/framer-motion";

const Toaster = (props) => {
  const { position, autoDelete, autoDeleteTime } = props;
  const [list, setList] = useState([]);
  const toastData = useSelector((state) => state.toasterData);
  const dispatch = useDispatch();

  /*
    id: 0,
    title: 'Success',
    description: 'This is a success toast component',
    type: TOASTER_SUCCESS,
    icon: checkIcon
  //   */
  const [theme, setTheme] = useState(toastData.isDarkTheme);
  useEffect(() => {
    setTheme(toastData.isDarkTheme);
  }, [toastData]);
  useEffect(() => {
    const interval = setInterval(() => {
      if (autoDelete && list && list.length) {
        deleteToast(list[0].id);
      }
    }, autoDeleteTime);

    return () => {
      clearInterval(interval);
    };
  }, [autoDelete, autoDeleteTime, list]);

  const deleteToast = (id) => {
    dispatch(deleteToasterList(id));
  };
  useEffect(() => {
    if (toastData) {
      setList(toastData.toasterList);
    }
  }, [toastData.toasterList]);
  return (
    <>
      <AnimatePresence>
        <motion.div className={`notification-container ${position}`}>
          <AnimatePresence>
            {list &&
              list.length > ZERO &&
              list.map((toast, i) => (
                <motion.div
                 data-theme={theme}
                  key={toast.id}
                  className={`notification toast ${position} ${
                    toast.type === TOASTER_ERROR
                      ? "notification-error"
                      : "notification-success"
                  } ${toast.id}`}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  // exit={{ transform: "translateX(100%)" }}
                  exit={{ opacity: 0 }}
                  onClick={() => deleteToast(toast.id)}
            >
                  <div className="notification-image">{toast.icon}</div>
                  <div>
                    <p className="notification-title">{toast.title}</p>
                    <p className="notification-message">{toast.description}</p>
                  </div>
                  <button className="toast-close-btn">
                    <span className="icon">
                      <IoClose />
                    </span>
                  </button>
                </motion.div>
              ))}
          </AnimatePresence>
        </motion.div>
      </AnimatePresence>
    </>
  );
};

export default Toaster;
