export const popupStyleByType = {
  segment: { parentPopupHeight: "41rem", innerBodyHeight: "8rem" },
};
export const proOptions = { hideAttribution: true };
export const edgeType = {
  step: "step",
};
export const EDGE_COLOR = "#b1b1b7";
export const ADD_NODE = "addNode";
export const MODIFY_NODE = "modifyNode";
export const DELETE_NODE = "deleteNode";
export const DATA_ACTION = "data-action";
export const FIRST = "first";
export const LAST = "last";
export const MIDDLE = "middle";
export const DELETE_BTN_COLOR = "#e13838";
export const TARGET = "target";
export const TOP = "top";
export const SOURCE = "source";
export const BOTTOM = "bottom";
export const CUSTOM_NODE_TYPE = "nodeType";
export const EDIT_NODE_NAME = "editNodeName";
