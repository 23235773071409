import "./Event.style.scss";
import {
	useEffect,
	useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	headers,
	OFRI,
} from "../../constantComponent/Constants";
import Select from "react-select";
import {
	updateRuleData,
	updateRewardData,
	setEventProcessingErrorStatus,
	getActionVersionJson,
	setActionVersionJson,
	resetActionVersionJsonResponse,
} from "../../../tellurideExtRedux/offerDetails/OfferDetailsAction";
import {
	ACTION_HISTORY_VERSION_LEGEND,
} from "../eventProcessComponent/EventConstant";
//import {getCustomStyles} from "./OfferDetailsUtil";
import JsonEditor from "../../utilsComponent/GenericEditor/JsonEditor";
import { getCustomStyles } from "../offerDetailsComponent/OfferDetailsUtil";
import { DotLoading } from "../../LoaderComponents/loadingDesignConstant";
import { getRightsFromSession, skey } from "../../utilsComponent/CommonUtil";

const EventRuleAction = ({ theme, disabled }) => {
	const offerData = useSelector((state) => state.offerDetailsData);
	const actionVersionJsonLoading = offerData.actionVersionJsonLoading;
	const actionVersionJsonResponse = offerData.actionVersionJsonResponse;
	const dispatch = useDispatch();

	const [actionVersionList, setActionVersionList] = useState([]);
	const [currentActionVersion, setCurrentActionVersion] = useState("");
	const [ruleJsonvalue, setRuleJsonValue] = useState("{}");
	const actions = offerData?.offerDetailsResponse?.actions || {};
	const actionsHistoryVersions = offerData?.offerDetailsResponse?.actionVersion;
	const rules = offerData?.offerDetailsResponse?.rules || {};
	const [actionJsonvalue, setActionJsonValue] = useState(
		actions ? "{}" : offerData?.offerDetailsResponse?.actions?.actionJson
	);
	const [validationErrors, setValidationErrors] = useState({
		ruleJson: {
			status: false,
			msg: "",
		},
		actionJson: {
			status: false,
			msg: "",
		},
	});

	useEffect(() => {
		if (actions?.actionJson) {
			setActionJsonValue(JSON.stringify(actions.actionJson, null, 2));
		}
	}, [actions?.actionJson]);
	useEffect(() => {
		if (rules?.rule) {
			const value = rules?.rule[0]?.conditions?.condition[0]?.values?.value[0];
			setRuleJsonValue(JSON.stringify(JSON.parse(value), null, 2));
		}
	}, [rules?.rule]);
	useEffect(() => {
		if (actionsHistoryVersions) {
			const actionVersionList = actionsHistoryVersions?.version;
			if (actionVersionList && actionVersionList.length > 0) {
				setActionVersionList(actionVersionList);
				const latestVersion = actionVersionList.sort(({ version: a }, { version: b }) => b - a)[0];
				setCurrentActionVersion(latestVersion.version);	
			}
		}
	}, [actionsHistoryVersions]);

	useEffect(() => {
		if (!actionVersionJsonLoading && actionVersionJsonResponse) {
			const requestedActionVersion = actionVersionJsonResponse?.actions?.action;
			if (requestedActionVersion && requestedActionVersion.length > 0) {
				const actionJson = requestedActionVersion[0]?.actionJson;
				setActionJsonValue(JSON.stringify(actionJson, null, 2));
			}
		}
	}, [actionVersionJsonLoading, actionVersionJsonResponse]);

	useEffect(() => {
		const actions = {
			action: [
				{
					actionJson: actionJsonvalue,
					actionName: "OFFER_ACTION",
				},
			],
		};
		dispatch(updateRewardData(actions));
		dispatch(setActionVersionJson(actions));
	}, [actionJsonvalue]);
	useEffect(() => {
		dispatch(updateRuleData(ruleJsonvalue));
	}, [ruleJsonvalue]);
	const handleEvtActionVersionChange = (event) => {
		const value = event.value;
		if (value) {
			headers.sessionId = skey();
			headers.requestAccess = getRightsFromSession();
			headers.organization = offerData?.authOrganizationName;
			const requestHeaders = {
				headers,
			};

			const requestBody = {
				requestorID: OFRI,
				messageVersion: process.env.REACT_APP_MESSAGE_VERSION_03,
				offerNumber: offerData?.offerData?.offerNumber,
				actionVersion: value,
			};
			setCurrentActionVersion(value);
			dispatch(getActionVersionJson(requestHeaders, requestBody));
		}
	};



	useEffect(() => {
		if (
			validationErrors["ruleJson"].status ||
			validationErrors["actionJson"].status
		) {
			dispatch(setEventProcessingErrorStatus(true));
		} else {
			dispatch(setEventProcessingErrorStatus(false));
		}
	}, [validationErrors]);
	useEffect(() => {
		return () => {
			dispatch(resetActionVersionJsonResponse());
		};
	}, []);


	return (
		<>
			<section>
				<div className="section-top-title setup-page-section-title">
					When to trigger
				</div>
				<div className="event-proc-main-div">
					<aside className={`info-block ${disabled ? "disabled" : ""}`}>
						<section class="file-marker">
							<div className="box-container">
								<div className="box-title">Rule Json</div>
								<div className="box-contents">
									<JsonEditor
										value={ruleJsonvalue}
										height="300px"
										onChange={(value) => setRuleJsonValue(value)}
										theme={theme}
										setValidationErrors={setValidationErrors}
										jsonKey="ruleJson"
										isDisabled={disabled}
									/>
								</div>
							</div>
						</section>
					</aside>
				</div>

				<div className="section-top-title setup-page-section-title">
					What to trigger
				</div>
				<div className="event-proc-main-div">
					<fieldset
						className="dropdown version-history-dropdown"
						disabled={
							disabled ||
							!offerData.offerDetailsLoaded ||
							actionVersionJsonLoading
						}
					>
						<legend>{ACTION_HISTORY_VERSION_LEGEND}</legend>
						<Select
							value={
								currentActionVersion
									? {
											label: currentActionVersion,
											value: currentActionVersion,
									  }
									: {
											label: null,
											value: null,
									  }
							}
							isSearchable={false}
							isLoading={!offerData.offerDetailsLoaded}
							options={actionVersionList.map((action) => {
								return {
									label: action.version,
									value: action.version,
								};
							})}
							name="Action_History_version"
							styles={getCustomStyles(theme)}
							onChange={handleEvtActionVersionChange}
							isDisabled={
								disabled ||
								!offerData.offerDetailsLoaded ||
								actionVersionJsonLoading
							}
						/>
					</fieldset>
					<aside className={`info-block ${disabled ? "disabled" : ""}`}>
						<section class="file-marker">
							<div className="box-container">
								<div className="box-title">Action Json</div>
								<div className="box-contents">
									<JsonEditor
										value={actionJsonvalue}
										height="300px"
										onChange={(value) => setActionJsonValue(value)}
										theme={theme}
										setValidationErrors={setValidationErrors}
										jsonKey={"actionJson"}
										isDisabled={disabled}
									/>
								</div>
							</div>
						</section>
						{actionVersionJsonLoading ? <DotLoading /> : null}
					</aside>
				</div>
				<p>&nbsp;</p>
			</section>
		</>
	);
};
export default EventRuleAction;
