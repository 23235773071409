import {
  ATTR,
  ATTRIBUTE_DROPDOWN,
  ATTRIBUTE_VALUE_INPUT,
  COMBINATION,
  COMBINATION_DROPDOWN,
  DROPDOWN,
  ENTITY,
  ENTITY_DROPDOWN,
  ID,
  JOIN,
  JOIN_DROPDOWN,
  PASSES,
  OPERATOR,
  OPERATOR_DROPDOWN,
  RULE,
  SUBGROUP,
  VALUE,
  INPUT_LIST_OPTIONS,
  TYPE,
  ENTITY_DEFAULT_VALUE,
  ATTR_DEFAULT_VALUE,
  OPERATOR_DEFAULT_VALUE,
  VALUE_DEFAULT_VALUE,
  ZERO,
  ALL,
  NAME,
} from "./GenericRulesConstants";

//converts jsonstate to backend json
export function convertFiltersToJSON(filters, combination, join) {
  let convertedFilters = [];
  filters.forEach((filter) => {
    if (filter.type === SUBGROUP) {
      let subgroup = convertFiltersToJSON(
        filter.filters,
        filter.combination,
        filter.join
      );
      let subgroupItem = {
        [`${filter.combination}_${filter.join}`]: subgroup,
      };
      convertedFilters.push(subgroupItem);
    } else if (filter.type === RULE) {
      convertedFilters.push({
        entity: filter.entity,
        attr: filter.attr,
        operator: filter.operator,
        value: filter.value,
        name: filter?.name,
        type: filter?.attributeType || "",
      });
    }
  });
  return convertedFilters;
}

export function convertJSON(JSONTOBECONVERTED) {
  let convertedJSON = {
    [`${JSONTOBECONVERTED[0].combination}_${JSONTOBECONVERTED[0].join}`]:
      convertFiltersToJSON(
        JSONTOBECONVERTED[0].filters,
        JSONTOBECONVERTED[0].combination,
        JSONTOBECONVERTED[0].join
      ),
  };

  return convertedJSON;
}

//converts backend json to jsonstate
export function convertFiltersToState(filters) {
  let convertedFilters = [];
  filters.forEach((filter) => {
    if (filter.PASSES_ANY || filter.PASSES_ALL) {
      let key = Object.keys(filter)[0];
      let combination = key.split("_")[0];
      let join = key.split("_")[1];
      let subgroupFilters = convertFiltersToState(filter[key]);
      let subgroupItem = {
        id: generateUniqueId(),
        combination,
        filters: subgroupFilters,
        join,
        type: SUBGROUP,
      };
      convertedFilters.push(subgroupItem);
    } else {
      let ruleItem = {
        id: generateUniqueId(),
        entity: filter.entity,
        attr: filter.attr,
        operator: filter.operator,
        value: filter.value,
        type: RULE,
        name: filter?.name,
        attributeType: filter.type,
      };
      convertedFilters.push(ruleItem);
    }
  });
  return convertedFilters;
}

export function convertToInitialJSON(json) {
  let key = Object.keys(json)[0];
  let combination = key.split("_")[0];
  let join = key.split("_")[1];
  let initialJSON = {
    id: generateUniqueId(),
    combination,
    filters: convertFiltersToState(json[key]),
    join,
    type: SUBGROUP,
  };

  return [initialJSON];
}
// generates random string
export function generateUniqueId() {
  return Math.random().toString(36).substring(2, 9);
}
// deletes rule/subgroup based on id passed
export function handleDelete(id, setExpression, areRulesDisabled) {
  if (areRulesDisabled === false) {
    setExpression((prevExpression) => {
      if (prevExpression && prevExpression[0].filters.length !== ZERO) {
        deleteFilter(prevExpression, id);
        if (prevExpression.length === 0) {
          let newInitialExpression = {
            id: generateUniqueId(),
            type: SUBGROUP,
            combination: PASSES,
            join: ALL,
            filters: [],
          };
          return [newInitialExpression];
        }
      }
      return [...prevExpression];
    });
  }
}
// updates the value of input/select dropdown
export function handleFilterChange(e, id, setExpression, setEditProcess) {
  setExpression((prevExpression) => {
    let inputName = e.target.name;
    switch (inputName) {
      case COMBINATION_DROPDOWN: {
        const { entireObj } = updateFilterValue(
          prevExpression,
          ID,
          id,
          e.target.value,
          COMBINATION
        );
        return [...entireObj];
      }
      case JOIN_DROPDOWN: {
        const { entireObj } = updateFilterValue(
          prevExpression,
          ID,
          id,
          e.target.value,
          JOIN
        );
        return [...entireObj];
      }
      case ENTITY_DROPDOWN: {
        const { entireObj } = updateFilterValue(
          prevExpression,
          ID,
          id,
          e.target.value,
          ENTITY
        );
        return [...entireObj];
      }
      case ATTRIBUTE_DROPDOWN: {
        const { entireObj } = updateFilterValue(
          prevExpression,
          ID,
          id,
          e.target.value,
          ATTR
        );
        return [...entireObj];
      }
      case OPERATOR_DROPDOWN: {
        const { entireObj } = updateFilterValue(
          prevExpression,
          ID,
          id,
          e.target.value,
          OPERATOR
        );
        return [...entireObj];
      }
      case ATTRIBUTE_VALUE_INPUT: {
        const { entireObj } = updateFilterValue(
          prevExpression,
          ID,
          id,
          e.target.value,
          VALUE
        );
        return [...entireObj];
      }
      default:
        return prevExpression;
    }
  });
  if (e.target.name.includes(DROPDOWN)) {
    setEditProcess({ status: false, id: null });
  }
}
// Turns on editing mode on for passed input id
export function handleEditClick(
  id,
  filterType,
  setEditProcess,
  areRulesDisabled
) {
  if (areRulesDisabled === false) {
    setEditProcess({
      status: true,
      id: id,
      filterType: filterType,
    });
  }
}
// turn on add subgroup/rule popver
export function handleAddFilterClick(id, setPopover) {
  setPopover({
    status: true,
    elementId: id,
  });
}
//returns updated json with new filter(subgroup/rule) added
export function addNewfilter(entireObj, keyToFind, valToFind, newObj) {
  let found = false;
  JSON.stringify(entireObj, (_, nestedValue) => {
    if (!found && nestedValue && nestedValue[keyToFind] === valToFind) {
      nestedValue.filters.push(newObj);
      found = true;
    }
    return nestedValue;
  });
  return { entireObj };
}
//returns updated json with desired filter (subgroup/rule) deleted
export function deleteFilter(jsonArray, idToDelete) {
  for (let i = 0; i < jsonArray.length; i++) {
    const entry = jsonArray[i];
    if (entry.id === idToDelete) {
      jsonArray.splice(i, 1);
      return true;
    }
    if (entry.filters && Array.isArray(entry.filters)) {
      for (let j = 0; j < entry.filters.length; j++) {
        const filter = entry.filters[j];
        if (filter.id === idToDelete) {
          entry.filters.splice(j, 1);
          return true;
        }
        if (filter.filters && Array.isArray(filter.filters)) {
          if (deleteFilter(filter.filters, idToDelete)) {
            return true;
          }
        }
      }
    }
  }
  return false;
}
//returns updated json with desired filter (subgroup/rule) updated
export function updateFilterValue(
  entireObj,
  keyToFind,
  valToFind,
  newValue,
  inputType
) {
  let found = false;
  JSON.stringify(entireObj, (_, nestedValue) => {
    if (!found && nestedValue && nestedValue[keyToFind] === valToFind) {
      switch (inputType) {
        case COMBINATION:
          nestedValue.combination = newValue;
          break;
        case JOIN:
          nestedValue.join = newValue;
          break;
        case ENTITY:
          nestedValue.entity = newValue;
          break;
        case ATTR:
          nestedValue.attr = newValue;
          break;
        case OPERATOR:
          nestedValue.operator = newValue;
          break;
        case VALUE:
          nestedValue.value = newValue;
          break;
        case INPUT_LIST_OPTIONS:
          nestedValue.inputListOptions = newValue;
          break;
        case TYPE:
          nestedValue.attributeType = newValue;
          break;
        case NAME:
          nestedValue.name = newValue;
          break;
        default:
      }
      found = true;
    }
    return nestedValue;
  });
  return { entireObj };
}
// returns true if any of the filters are empty or not filled
export function checkFilterEmpty(filters) {
  return (
    filters &&
    filters.length > ZERO &&
    filters.some((filter) => {
      if (filter.type === SUBGROUP) {
        return checkFilterEmpty(filter.filters);
      } else {
        for (let key in filter) {
          if (
            [
              ENTITY_DEFAULT_VALUE,
              ATTR_DEFAULT_VALUE,
              OPERATOR_DEFAULT_VALUE,
              VALUE_DEFAULT_VALUE,
            ].includes(filter[key])
          ) {
            return true;
          }
          if (Array.isArray(filter.value) && filter.value.length === ZERO) {
            return true;
          }
        }
        return false;
      }
    })
  );
}
export function checkSubGroupEmpty(filters) {
  if (filters && filters.length > ZERO) {
    return false;
  } else {
    return true;
  }
}

export function resetFilters(setExpression, filter, filtersResetArray) {
  filtersResetArray.forEach(({ filterType, filterResetValue }) => {
    setExpression((prevExpression) => {
      const { entireObj } = updateFilterValue(
        prevExpression,
        ID,
        filter.id,
        filterResetValue,
        filterType
      );
      return [...entireObj];
    });
  });
}

export function setAttributeValue(setExpression, attributeModal, value) {
  setExpression((prevExpression) => {
    const { entireObj } = updateFilterValue(
      prevExpression,
      ID,
      attributeModal.id,
      value,
      ATTR
    );
    return [...entireObj];
  });
}
export function setAttributeType(setExpression, attributeModal, value) {
  setExpression((prevExpression) => {
    const { entireObj } = updateFilterValue(
      prevExpression,
      ID,
      attributeModal.id,
      value,
      TYPE
    );
    return [...entireObj];
  });
}
export function setAttributeName(setExpression, attributeModal, value) {
  setExpression((prevExpression) => {
    const { entireObj } = updateFilterValue(
      prevExpression,
      ID,
      attributeModal.id,
      value,
      NAME
    );
    return [...entireObj];
  });
}

export function setAttrInputDropdownOptions(
  setExpression,
  attributeModal,
  options
) {
  setExpression((prevExpression) => {
    const { entireObj } = updateFilterValue(
      prevExpression,
      ID,
      attributeModal.id,
      options,
      INPUT_LIST_OPTIONS
    );
    return [...entireObj];
  });
}
export function setInputValue(setExpression, id, value) {
  setExpression((prevExpression) => {
    const { entireObj } = updateFilterValue(
      prevExpression,
      ID,
      id,
      value,
      VALUE
    );
    return [...entireObj];
  });
}
export function handleResize(containerRef, filtersRef) {
  const container = containerRef?.current;
  const filter = filtersRef?.current;
  const availableWidth = container?.clientWidth - 150;
  if (container && filter) {
    if (filter.clientWidth > availableWidth) {
      filter.style.maxWidth = `${availableWidth}px`;
    } else {
      filter.style.maxWidth = `${availableWidth}px`;
    }
  }
}
export function calculateSubgroup(filter, setSubgroupCount) {
  if (filter.type === SUBGROUP) {
    setSubgroupCount((prevCount) => {
      return prevCount + 1;
    });
    filter.filters.forEach((fil) => {
      calculateSubgroup(fil, setSubgroupCount);
    });
  }
}
