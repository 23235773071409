import { SET_LEAVE_SETUP_CREATION_PAGE, SET_SOURCE_CANCEL_BTN } from "./LeavingSetupCreationType";

export const setLeavingSetupPageState = (value) => {
	return (dispatch) => {
		dispatch(setModelPopupState(value));
	};
};
const setModelPopupState = (data) => {
	return {
		type: SET_LEAVE_SETUP_CREATION_PAGE,
		payload: data,
	};
};
export const setSourceCancelBtn = (value) => {
	return (dispatch) => {
		dispatch(sourceCancelBtn(value));
	};
};
const sourceCancelBtn = (data) => {
    return {
		type : SET_SOURCE_CANCEL_BTN,
		payload : data
	}
}
