import React, { useEffect, useState } from "react";
import "./GenericCron.style.scss";
import Select from "react-select";
import { images } from "../../imagesComponent/Images";

import "../../../tellurideExtAssets/styles/reactCalendar.style.scss";
import "../../../tellurideExtAssets/styles/reactDatePicker.style.scss";
import DatePicker from "react-date-picker";
import { changeDateDashFormat } from "../CommonUtil";
import moment from "moment";
import TimeKeeper from "react-timekeeper";
import {
	DAILY_LABEL,
	DAILY_VALUE,
	DAY_LABEL,
	DAY_VALUE,
	DOES_NOT_REPEAT_LABEL,
	DOES_NOT_REPEAT_VALUE,
	FIRST_DAY_OF_MONTH_LABEL,
	FIRST_DAY_OF_MONTH_VALUE,
	LAST_DAY_OF_MONTH_LABEL,
	LAST_DAY_OF_MONTH_VALUE,
	MONTHLY_LABEL,
	MONTHLY_VALUE,
	REPEAT_LABEL,
	REPEAT_VALUE,
	STAR,
	TIME_ZERO,
	WEEKLY_LABEL,
	WEEKLY_VALUE,
	WEEKS_ARRAY,
	customSelectStyles,
} from "./GenericCronConstant";
import { EN_US, WEEKS } from "../../constantComponent/Constants";
import { ACTIVE } from "../GenericRules/GenericRulesConstants";

function GenericCron(props) {
	const {
		theme,
		campaignDetailsData,
		setCampaignDetailsData,
		GenericCronObj,
		setGenericCronObj,
		isDisabled,
	} = props;
	const [showTime, setShowTime] = React.useState(false);

	const [setRepeatDropdownState, setSetRepeatDropdownState] =
		React.useState(false);
		const [showRepeatDropdown, setShowRepeatDropdown] = React.useState(false);
		const [showRepeatMonthlyDropdown, setShowRepeatMonthlyDropdown] =
		React.useState(false);
		const [showRepeatMonthlyDateDropdown, setShowRepeatMonthlyDateDropdown] =
		React.useState(false);
		const [isRepeatDropdownState, setIsRepeatDropDownState] = useState(true);

	useEffect(() => {
		setCampaignDetailsData((prev) => {
			return {
				...prev,
				startDate: GenericCronObj?.startDate,
				endDate: GenericCronObj?.endDate,
				cronExpression: generateCronExpression(GenericCronObj),
				frequencyType: isRepeatDropdownState ? "REPEAT" : "NOREPEAT"
			};
		});
	}, [GenericCronObj.startDate, GenericCronObj.endDate, GenericCronObj, isRepeatDropdownState]);

	function generateCronExpression() {
		let cronValue = "";
		if (GenericCronObj?.repeatFrequency?.value === DOES_NOT_REPEAT_VALUE) {
			cronValue = `${GenericCronObj?.time?.minute} ${GenericCronObj?.time?.hour} ${STAR} ${STAR} ${STAR}`;
			setIsRepeatDropDownState(false)
		} else {
			setIsRepeatDropDownState(true)
			if (GenericCronObj?.repeatPeriod?.value === DAILY_VALUE) {
				cronValue = `${GenericCronObj?.time?.minute} ${GenericCronObj?.time?.hour} ${STAR} ${STAR} ${STAR}`;
			} else if (GenericCronObj?.repeatPeriod?.value === WEEKLY_VALUE) {
				cronValue = `${GenericCronObj?.time?.minute} ${
					GenericCronObj?.time?.hour
				} ${STAR} ${STAR} ${
					GenericCronObj?.weeksValue.length > 0
						? GenericCronObj?.weeksValue
								.map((day) => WEEKS.indexOf(day))
								.join(",")
						: `${STAR}`
				}`;
			} else if (GenericCronObj?.repeatPeriod?.value === MONTHLY_VALUE) {
				cronValue = `${GenericCronObj?.time?.minute} ${
					GenericCronObj?.time?.hour
				} ${
					GenericCronObj?.repeatMonthly.value === DAY_VALUE
						? GenericCronObj?.repeatMonthlyDate?.map((d) => d.value).join(",")
						: GenericCronObj?.repeatMonthly.value
				} ${STAR} ${STAR}`;
			}
		}
		return cronValue;
	}

	function startDatechangeHandler(value) {
		if (new Date(value) >= new Date(campaignDetailsData?.endDate)) {
			let endDate = new Date(value);
			endDate.setDate(endDate.getDate() + 1);
			endDate = endDate.toLocaleDateString(EN_US);
			setGenericCronObj((prev) => {
				return {
					...prev,
					startDate: new Date(value).toLocaleDateString(EN_US),
					endDate: endDate,
				};
			});
			setCampaignDetailsData((prev) => {
				return {
					...prev,
					startDate: new Date(value).toLocaleDateString(EN_US),
					endDate: endDate,
				};
			});
		} else {
			setGenericCronObj((prev) => {
				return {
					...prev,
					startDate: new Date(value).toLocaleDateString(EN_US),
				};
			});
			setCampaignDetailsData((prev) => {
				return {
					...prev,
					startDate: new Date(value).toLocaleDateString(EN_US),
				};
			});
		}
	}
	function convert24HourTo12Hour(time24) {
		// Extract hours and minutes
		const [hours, minutes] = time24.split(":").map(Number);

		// Convert to 12-hour format
		const period = hours > 12 ? "PM" : "AM";
		const formattedHours = hours % 12 === 0 ? 12 : hours % 12;

		// Construct the formatted time
		const formattedTime = `${formattedHours}:${minutes
			.toString()
			.padStart(2, "0")} ${period}`;

		return formattedTime;
	}
	function timechangeHandler(newTime) {
		setGenericCronObj((prev) => {
			return {
				...prev,
				time: {
					hour: newTime.hour24,
					minute: newTime.formatted24.split(":")[1],
					value: newTime.formatted24.split(":").join(":"),
				},
			};
		});
	}
	function repeatFrequencyChangeHandler(value) {
		setGenericCronObj((prev) => {
			return {
				...prev,
				repeatFrequency: value,
			};
		});
		if (value.value === REPEAT_VALUE) {
			setIsRepeatDropDownState(true);
			let endDate = new Date(campaignDetailsData?.endDate).toLocaleDateString(
				EN_US
			);
			let startDate = new Date(
				campaignDetailsData?.startDate
			).toLocaleDateString(EN_US);
			if (new Date(endDate) <= new Date(startDate)) {
				endDate = new Date(startDate);
				endDate.setDate(endDate.getDate() + 1);
				endDate = endDate.toLocaleDateString(EN_US);
			}
			setGenericCronObj((prev) => {
				return {
					...prev,
					repeatPeriod: {
						label: DAILY_LABEL,
						value: DAILY_VALUE,
					},
					endDate: endDate,
				};
			});
		} else {
			setIsRepeatDropDownState(false);
			setGenericCronObj((prev) => {
				return {
					...prev,
					repeatPeriod: "",
					endDate: campaignDetailsData?.startDate,
					weeksValue: "",
					repeatMonthly: "",
					repeatMonthlyDate: "",
				};
			});
		}
		setSetRepeatDropdownState(false);
	}
	function repeatPeriodChangeHandler(value) {
		setGenericCronObj((prev) => {
			return {
				...prev,
				repeatPeriod: value,
			};
		});
		if (value.value === WEEKLY_VALUE) {
			setGenericCronObj((prev) => {
				return {
					...prev,
					weeksValue: [WEEKS[new Date().getDay()]],
				};
			});
		} else if (value.value === MONTHLY_VALUE) {
			setGenericCronObj((prev) => {
				return {
					...prev,
					repeatMonthly: {
						label: DAY_LABEL,
						value: DAY_VALUE,
					},
					repeatMonthlyDate: [
						{
							label: new Date().getDate(),
							value: new Date().getDate(),
						},
					],
				};
			});
		}
		setShowRepeatDropdown(false);
	}
	function weeklyDayChangeHandler(dayName) {
		let WeeksValue = GenericCronObj.weeksValue;
		if (WeeksValue.includes(dayName.value)) {
			WeeksValue = WeeksValue.filter((d) => d !== dayName.value);
		} else {
			WeeksValue = [...WeeksValue, dayName.value];
		}
		setGenericCronObj((prev) => {
			return {
				...prev,
				weeksValue: WeeksValue,
			};
		});
	}
	function repeatMonthlyChangeHandler(value) {
		setGenericCronObj((prev) => {
			return {
				...prev,
				repeatMonthly: value,
			};
		});
		if (value.value === DAY_VALUE) {
			setShowRepeatMonthlyDateDropdown(true);
		}
		setShowRepeatMonthlyDropdown(false);
	}
	function repeatMonthlyDateChangeHandler(value) {
		setGenericCronObj((prev) => {
			return {
				...prev,
				repeatMonthlyDate:
					value.length > 0
						? value
						: [
								{
									label: new Date().getDate(),
									value: new Date().getDate(),
								},
						  ],
			};
		});
		setShowRepeatMonthlyDateDropdown(false);
	}
	function endDatechangeHandler(value) {
		setGenericCronObj((prev) => {
			return {
				...prev,
				endDate: new Date(value).toLocaleDateString(EN_US),
			};
		});
		setCampaignDetailsData((prev) => {
			return {
				...prev,
				endDate: new Date(value).toLocaleDateString(EN_US),
			};
		});
	}

	function handleBlur(e) {
		if (showTime) {
			setShowTime(false);
		}
	}

	return (
		<>
			{showTime && <div className="time-picker-overlay" onClick={handleBlur} />}
			<div className="generic-cron-wrapper">
				<div className="generic-cron-title setup-page-section-title">
					Schedule Campaign
				</div>
				<section className="generic-cron-section">
					<div className="generic-cron-create-container">
						<div className={`generic-cron-create-row`}>
							<span className="cron-text-label">Starts on</span>
							<div className={`Date-wrapper ${isDisabled && "disabled"}`}>
								<DatePicker
									calendarIcon={null}
									clearIcon={null}
									// minDate={moment(
									// 	GenericCronObj?.startDate,
									// 	"YYYY-MM-DD"
									// ).toDate()}
									onChange={startDatechangeHandler}
									format="y-MM-dd"
									value={moment(GenericCronObj?.startDate).toLocaleString(
										EN_US
									)}
									disabled={isDisabled}
								/>
							</div>
							<span className="cron-text-label">at</span>
							<div className={`time-wrapper ${isDisabled && "disabled"}`}>
								{showTime ? (
									<TimeKeeper
										time={GenericCronObj?.time?.value}
										onChange={timechangeHandler}
										onDoneClick={() => {
											setShowTime(false);
										}}
										switchToMinuteOnHourSelect
									/>
								) : (
									<div className="time-picker">
										<div
											className={`time-picker-value ${
												isDisabled && "disabled"
											}`}
											onClick={() => {
												if (isDisabled !== true) {
													setShowTime(true);
												}
											}}
										>
											{convert24HourTo12Hour(GenericCronObj?.time?.value)}
										</div>
									</div>
								)}
							</div>
							<div className="repeat-dropdown-container">
								{setRepeatDropdownState === false ? (
									<span
										className={`repeat-dropdown text cron-text-label ${
											isDisabled && "disabled"
										}`}
										onClick={() => {
											if (isDisabled !== true) {
												setSetRepeatDropdownState(true);
											}
										}}
									>
										{GenericCronObj?.repeatFrequency?.label}
									</span>
								) : (
									<Select
										className="repeat-dropdown"
										defaultValue={{
											label: DOES_NOT_REPEAT_LABEL,
											value: DOES_NOT_REPEAT_VALUE,
										}}
										isSearchable={false}
										value={{
											label: GenericCronObj?.repeatFrequency?.label,
											value: GenericCronObj?.repeatFrequency?.value,
										}}
										autoFocus
										options={[
											{
												label: REPEAT_LABEL,
												value: REPEAT_VALUE,
											},
											{
												label: DOES_NOT_REPEAT_LABEL,
												value: DOES_NOT_REPEAT_VALUE,
											},
										]}
										onChange={repeatFrequencyChangeHandler}
										onBlur={() => {
											setSetRepeatDropdownState(false);
										}}
										isDisabled={isDisabled}
										styles={customSelectStyles(theme)}
										defaultMenuIsOpen
									/>
								)}
								{GenericCronObj?.repeatFrequency?.value === REPEAT_VALUE && (
									<>
										<div>
											{showRepeatDropdown === true ? (
												<Select
													className="repeat-dropdown"
													isSearchable={false}
													autoFocus
													styles={customSelectStyles(theme)}
													value={{
														label: GenericCronObj?.repeatPeriod?.label,
														value: GenericCronObj?.repeatPeriod?.value,
													}}
													options={[
														{
															label: DAILY_LABEL,
															value: DAILY_VALUE,
														},
														{
															label: WEEKLY_LABEL,
															value: WEEKLY_VALUE,
														},
														{
															label: MONTHLY_LABEL,
															value: MONTHLY_VALUE,
														},
													]}
													onChange={repeatPeriodChangeHandler}
													onBlur={() => {
														setShowRepeatDropdown(false);
													}}
													isDisabled={isDisabled}
													defaultMenuIsOpen
												/>
											) : (
												<div
													className={`repeat-dropdown text ${
														isDisabled && "disabled"
													}`}
													onClick={() => {
														if (isDisabled !== true) {
															setShowRepeatDropdown(true);
														}
													}}
												>
													{GenericCronObj?.repeatPeriod?.label}
												</div>
											)}
										</div>
										{GenericCronObj?.repeatPeriod?.value === WEEKLY_VALUE && (
											<div className="day-tabs-container">
												{WEEKS_ARRAY.map((dayName, index) => {
													return (
														<div
															key={index}
															className={`day-tab ${
																GenericCronObj.weeksValue.includes(
																	dayName.value
																) && ACTIVE
															} ${isDisabled && "disabled"}`}
															onClick={() => {
																if (isDisabled !== true) {
																	weeklyDayChangeHandler(dayName);
																}
															}}
														>
															<div className={`day-tab-text`}>
																{dayName.label.substring(0, 3)}
															</div>
														</div>
													);
												})}
											</div>
										)}
										{GenericCronObj?.repeatPeriod?.value === MONTHLY_VALUE && (
											<>
												<span className="cron-text-label">on</span>

												{showRepeatMonthlyDropdown === true ? (
													<Select
														className="repeat-dropdown"
														autoFocus
														isSearchable={false}
														styles={customSelectStyles(theme)}
														value={{
															label: GenericCronObj?.repeatMonthly?.label,
															value: GenericCronObj?.repeatMonthly?.value,
														}}
														options={[
															{
																label: DAY_LABEL,
																value: DAY_VALUE,
															},
															{
																label: FIRST_DAY_OF_MONTH_LABEL,
																value: FIRST_DAY_OF_MONTH_VALUE,
															},
															{
																label: LAST_DAY_OF_MONTH_LABEL,
																value: LAST_DAY_OF_MONTH_VALUE,
															},
														]}
														onChange={repeatMonthlyChangeHandler}
														onBlur={() => {
															setShowRepeatMonthlyDropdown(false);
														}}
														isDisabled={isDisabled}
														defaultMenuIsOpen
													/>
												) : (
													<div
														className={`repeat-dropdown text ${
															isDisabled && "disabled"
														}`}
														onClick={() => {
															if (isDisabled !== true) {
																setShowRepeatMonthlyDropdown(true);
															}
														}}
													>
														{GenericCronObj?.repeatMonthly?.label}
													</div>
												)}

												{GenericCronObj?.repeatMonthly?.value === DAY_VALUE &&
													(showRepeatMonthlyDateDropdown === true ? (
														<Select
															options={new Array(31).fill(0).map((_, i) => {
																return {
																	label: i + 1,
																	value: i + 1,
																};
															})}
															isSearchable={false}
															hideSelectedOptions={false}
															isClearable={false}
															onChange={repeatMonthlyDateChangeHandler}
															isMulti
															onBlur={() => {
																setShowRepeatMonthlyDateDropdown(false);
															}}
															autoFocus
															value={GenericCronObj?.repeatMonthlyDate}
															className="date-select-dropdown"
															menuPlacement="auto"
															styles={customSelectStyles(theme)}
															defaultMenuIsOpen
															isDisabled={isDisabled}
														/>
													) : (
														<div
															className={`repeat-dropdown text date-text ${
																isDisabled && "disabled"
															}`}
															onClick={() => {
																if (isDisabled !== true) {
																	setShowRepeatMonthlyDateDropdown(true);
																}
															}}
														>
															{GenericCronObj?.repeatMonthlyDate
																?.map((d) => d.label)
																.join(",")}
														</div>
													))}
											</>
										)}
									</>
								)}
							</div>
						</div>
						{GenericCronObj?.repeatFrequency?.value === REPEAT_VALUE && (
							<div className={`generic-cron-create-row`}>
								<span className="cron-text-label">Ends on</span>
								<div className={`Date-wrapper ${isDisabled && "disabled"}`}>
									<DatePicker
										calendarIcon={
											<img
												src={images.calendarIcon}
												className="calendar-icon"
											/>
										}
										clearIcon={null}
										format="y-MM-dd"
										minDate={moment(
											changeDateDashFormat(GenericCronObj?.startDate),
											"YYYY-MM-DD"
										)
											.add(1, "days")
											.toDate()}
										onChange={endDatechangeHandler}
										value={moment(GenericCronObj?.endDate).toLocaleString(
											EN_US
										)}
										disabled={isDisabled}
									/>
								</div>
							</div>
						)}
					</div>
				</section>
			</div>
		</>
	);
}
export default GenericCron;
