import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { Route, Routes, Navigate } from "react-router-dom";
import "../../../tellurideExtAssets/styles/Styles.style.scss";
import Error from "../../errorsComponent/Error";
import Header from "../../headerComponent/Header";
import NavigationBar from "../../navigationBarComponent/internalNavigationBarComponent/NavigationBar";
import "./ConfigureRedirection.style.scss";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import AuthLoading from "../../LoaderComponents/AuthLoading";
import SessionExpiredConfirmation from "../../modelComponent/sessionExpiredConfirmationComponent/SessionExpiredConfirmation";
import { logoutAuth0 } from "../../../tellurideExtRedux/reduxUtil";
import UnauthorizedAccess,{
  PageNotFound,
} from "../../unauthorizeComponent/UnauthorizeWrapper";
import { DUAL_NAVLINKS } from "../../constantComponent/Constants";
import {
  checkRightsIsEditable,
  getRightsKey,
} from "../../utilsComponent/CommonUtil";
const ConfigureRedirection = () => {
  const [showOfferPopup, setShowOfferPopup] = useState(false);
  const location = useLocation();
  const scrollTargetDiv = useRef();
  const { logout } = useAuth0();
  const OfferAnalyticsBlankSessionIdError = useSelector(
    (state) => state.OfferAnalyticsData.blankSessionIdError
  );
  const offerDetailsDataBlankSessionIdError = useSelector(
    (state) => state.offerDetailsData.blankSessionIdError
  );
  const metaDataBlankSessionIdError = useSelector(
    (state) => state.metaData.blankSessionIdError
  );
  const OfferAnalyticsSessionExpire = useSelector(
    (state) => state.OfferAnalyticsData.showExpiredSessionPopup
  );
  const offerDetailsDataSessionExpire = useSelector(
    (state) => state.offerDetailsData.showExpiredSessionPopup
  );
  const metaDataSessionExpire = useSelector(
    (state) => state.metaData.showExpiredSessionPopup
  );
  const offerDetailsPageData = useSelector((state) => state.offerDetailsData);
  const orgName = offerDetailsPageData?.authOrganizationName;

  //theme
  const toasterData = useSelector((state) => state.toasterData);
  const [theme, setTheme] = useState(toasterData.isDarkTheme);
  const [redirectionHeader, setRedirectionHeader] = useState(
    "Configure Attributes"
  );
  useEffect(() => {
    setTheme(toasterData.isDarkTheme);
  }, [toasterData]);
  //theme

  useLayoutEffect(() => {
    scrollTargetDiv?.current?.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [location]);

  useEffect(() => {
    if (
      [
        OfferAnalyticsBlankSessionIdError,
        offerDetailsDataBlankSessionIdError,
        metaDataBlankSessionIdError,
      ].includes(true)
    ) {
      logoutAuth0(logout, orgName);
    }
  }, [
    OfferAnalyticsBlankSessionIdError,
    offerDetailsDataBlankSessionIdError,
    metaDataBlankSessionIdError,
    logout,
  ]);

  return (
    <div data-theme={theme}>
      <AuthLoading>
        <div className="redirection-page-wrapper">
          <Header title={redirectionHeader} />
          <section className="display-flex-wrapper" ref={scrollTargetDiv}>
            <NavigationBar
              showOfferPopup={showOfferPopup}
              setShowOfferPopup={setShowOfferPopup}
            />
            <div className="inner-section-wrapper">
              <ErrorBoundary
                FallbackComponent={Error}
              >
                <Routes>
                  {DUAL_NAVLINKS.find(
                    (dualNavLink) => dualNavLink.label === "Configure"
                  ).subLinks.map((SubLink, index) => {
                    if (SubLink.isRoute) {
                      return (
                        <React.Fragment key={SubLink.id}>
                          <>
                            <Route
                              path={SubLink.path}
                              key={index}
                              element={
                                checkRightsIsEditable(
                                  getRightsKey(SubLink.rights)
                                ) ? (
                                  <SubLink.Component.Name
                                    setRedirectionHeader={setRedirectionHeader}
                                  />
                                ) : (
                                  <UnauthorizedAccess />
                                )
                              }
                            />
                            {SubLink.isActive && (
                              <Route
                                path="/"
                                element={
                                  <Navigate
                                    to={
                                      "/" +
                                      window.location.pathname.split("/")[1] +
                                      SubLink.url
                                    }
                                    replace
                                  />
                                }
                              />
                            )}
                          </>
                        </React.Fragment>
                      );
                    }
                  })}
                  <Route
                    path="*"
                    Component={() => {
                      return <PageNotFound pageName={"Page not found !"} />;
                    }}
                  />
                </Routes>
              </ErrorBoundary>
            </div>
          </section>
        </div>
        {offerDetailsDataSessionExpire === true ||
        OfferAnalyticsSessionExpire === true ||
        metaDataSessionExpire === true ? (
          <SessionExpiredConfirmation />
        ) : null}
      </AuthLoading>
    </div>
  );
};

export default ConfigureRedirection;
