import React, { useEffect, useState } from "react";
import { DARK, OFRI, headers } from "../../../../constantComponent/Constants";
import {
	getRightsFromSession,
	skey,
} from "../../../../utilsComponent/CommonUtil";
import { useDispatch, useSelector } from "react-redux";
import { getOfferList } from "../../../../../tellurideExtRedux";
import {
	CONTENT_ID,
	CONTENT_NAME,
	CREATED_BY,
	SEARCH_FOR_CONTENT,
	campaign_content_columns,
	popupConfig,
} from "../../CampaignBuilderConstants";
import Skeleton from "react-loading-skeleton";
import { emptyData } from "../../../../constantComponent/DesignConstant";
import { motion } from "framer-motion/dist/framer-motion";
import { FaRegTimesCircle } from "react-icons/fa";
import SmallGenericTable from "../../../../utilsComponent/SmallGenericTable/SmallGenericTable";

const ContentPopup = ({
	theme,
	selectedEmailContentData,
	setSelectedEmailContentData,
	setOpenEmailContent,
}) => {
	const offerData = useSelector((state) => state.offerDetailsData);
	const dispatch = useDispatch();
	let tableLoading = offerData.getOfferListLoading;
	let tableResponse = offerData.getOfferListResponse;
	const [searchBody, setSearchBody] = useState("");
	const [searchText, setSearchText] = useState("");
	const [triggerList, setTriggerList] = useState(null);
	const securityData = useSelector((state) => state.securityData);

	function rowClickHandler(slectedData) {
		setSelectedEmailContentData((prev) => {
			return {
				...prev,
				contentId: slectedData.id,
				name: slectedData.name,
				createdBy: slectedData.createdBy,
			};
		});
		setOpenEmailContent(false);
	}
	return (
		<section className="email-popup-backdrop">
			<motion.div
				className="inner-modal"
				initial={{ opacity: 0 }}
				animate={{ opacity: 1 }}
				exit={{ opacity: 0 }}
			>
				<FaRegTimesCircle
					onClick={() => {
						setOpenEmailContent(false);
					}}
					size={25}
					className="email-modal-close-icon"
				/>
				<div className="search-bar-container-email">
					<form
						onSubmit={(e) => {
							e.preventDefault();
							setSearchBody(searchText);
							setTriggerList((prev) => !Boolean(prev));
						}}
					>
						<input
							type="text"
							autoFocus
							value={searchText}
							className="attribute-search"
							placeholder={SEARCH_FOR_CONTENT}
							onChange={(e) => {
								setSearchText(e.target.value);
							}}
						/>
					</form>

					<div
						className="action-search-query-icon"
						onClick={() => {
							setSearchBody(searchText);
						}}
					></div>
				</div>
				<div className="action-content-table">
					<SmallGenericTable
						Columns={campaign_content_columns}
						tableData={tableResponse?.offerList}
						isDataLoading={tableLoading}
						selectedData={[selectedEmailContentData.contentId]}
						isRowSelectable={true}
						isRowMultiSelectable={false}
						rowClickHandler={rowClickHandler}
						API_URL={process.env.REACT_APP_GET_OFFER_LIST}
						config={{
							...popupConfig,
							theme: theme,
							selectedDataKey: "id",
							border: false,
						}}
						isShowPagination={true}
						initialPagination={{
							recordPerPage: 10,
							currentPage: 1,
						}}
						smallGenericTableRquestBody={{
							requestorID: OFRI,
							messageVersion: process.env.REACT_APP_MESSAGE_VERSION_01,
							offerSearch: {
								id: "",
								name: "",
								description: "",
								type: "Message only",
								status: "ACTIVE",
								offerStartDate: "",
								offerEndDate: "",
								lastEditedDate: "",
								createdBy: "",
								searchQuery: searchText,
							},
							sort: [
								{
									name: "lastEditedDate",
									sortPriority: "1",
									sortType: "desc",
								},
							],
						}}
						RESPONSE_BODY_KEY={"offerList"}
						triggerList={triggerList}
					/>
				</div>
			</motion.div>
		</section>
	);
};

export default ContentPopup;
