export const GET_OFFER_STATUS_STATS_REQUEST = "GET_OFFER_STATUS_STATS_REQUEST";
export const GET_OFFER_STATUS_STATS_SUCCESS = "GET_OFFER_STATUS_STATS_SUCCESS";
export const GET_OFFER_STATUS_STATS_FAILURE = "GET_OFFER_STATUS_STATS_FAILURE";
export const GET_OFFER_STATUS_STATS_SUCCESS_FAILURE =
  "GET_OFFER_STATUS_STATS_SUCCESS_FAILURE";

export const GET_EXPIRING_OFFER_REQUEST = "GET_EXPIRING_OFFER_REQUEST";
export const GET_EXPIRING_OFFER_SUCCESS = "GET_EXPIRING_OFFER_SUCCESS";
export const GET_EXPIRING_OFFER_FAILURE = "GET_EXPIRING_OFFER_FAILURE";
export const GET_EXPIRING_OFFER_SUCCESS_FAILURE =
  "GET_EXPIRING_OFFER_SUCCESS_FAILURE";

export const GET_OFFER_ACTIVITY_REQUEST = "GET_OFFER_ACTIVITY_REQUEST";
export const GET_OFFER_ACTIVITY_SUCCESS = "GET_OFFER_ACTIVITY_SUCCESS";
export const GET_OFFER_ACTIVITY_SUCCESS_FAILURE =
  "GET_OFFER_ACTIVITY_SUCCESS_FAILURE";
export const GET_OFFER_ACTIVITY_FAILURE = "GET_OFFER_ACTIVITY_FAILURE";
export const SET_BLANK_SESSION_ID_ERROR = "SET_BLANK_SESSION_ID_ERROR";
export const SET_SESSION_TIMEOUT_POPUP = "SET_SESSION_TIMEOUT_POPUP"

export const GET_OFFER_STATISTICS_REQUEST = "GET_OFFER_STATISTICS_REQUEST";
export const GET_OFFER_STATISTICS_SUCCESS = "GET_OFFER_STATISTICS_SUCCESS";
export const GET_OFFER_STATISTICS_FAILURE = "GET_OFFER_STATISTICS_FAILURE";
export const GET_OFFER_STATISTICS_SUCCESS_FAILURE =
  "GET_OFFER_STATISTICS_SUCCESS_FAILURE";
