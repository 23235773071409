import { GrFormClose } from "react-icons/gr";
import { BsFilterRight } from "react-icons/bs";
import { FaFilter } from "react-icons/fa";
import { FiFilter, FiSearch } from "react-icons/fi";
import { AiOutlineMenu, AiOutlineCalendar } from "react-icons/ai";
import { BsArrowCounterclockwise } from "react-icons/bs";
import React, { useEffect, useState } from "react";
import {
	ALL,
	DATE,
	DISABLED,
	STRING,
	ZERO,
} from "../GenericRules/GenericRulesConstants";
import { images } from "../../imagesComponent/Images";
import DatePicker from "react-date-picker";
import {
	checkRightsIsEditable,
	checkRightsIsVisible,
	formatDate,
	getRightsKey,
} from "../CommonUtil";

import Select from "react-select";
import {
	clearFilter,
	getDaysFromStartEndDates,
	handleExtraFilterClear,
	handleFilter,
	handleSearch,
	handleSearchChange,
	handleSearchClear,
} from "./GenericTableUtil";
import {
	APPLY,
	FILTER,
	LEFT,
	RIGHT,
	SELECT,
	SINGLE,
} from "./GenericTableConstants";
import { convertUTCDateToLocalDate } from "../../offerSetupComponent/offerDetailsComponent/OfferDetailsUtil";
import DateRangeFilter from "../CalendarDateRange/CalendarDateRange";
import { useSelector } from "react-redux";
import { IconContext } from "react-icons";
import {
	DARK,
	DROPDOWN_BGCOLOR_DARK_THEME,
	DROPDOWN_FOCUSED_BGCOLOR_DARK_THEME,
	DROPDOWN_FOCUSED_BGCOLOR_LIGHT_THEME,
	DROPDOWN_SELECTED_BGCOLOR_DARK_THEME,
	DROPDOWN_SELECTED_BGCOLOR_LIGHT_THEME,
	LIGHT,
} from "../../constantComponent/Constants";

export function filteredIcon(col, setColumnFilter) {
	return (
		<div
			className="filtered-icon-container"
			onClick={() => {
				setColumnFilter((prev) => {
					return {
						...prev,
						status: true,
						columnName: col.key,
					};
				});
			}}
		>
			<BsFilterRight className="filter-menu-icon" />
			<FaFilter className="filtered-icon" size={8} />
		</div>
	);
}
export function filterIcon(col, setColumnFilter) {
	return (
		<div
			className="filter-icon-container"
			onClick={() => {
				setColumnFilter({
					status: true,
					columnName: col.key,
				});
			}}
		>
			<AiOutlineMenu className="filter-icon" size={14} />
		</div>
	);
}
export function GlobalFilterPopOver({
	setToggleFilter,
	filter,
	columns,
	setFilter,
	setFetchList,
}) {
	//theme
	const toasterData = useSelector((state) => state.toasterData);
	const [theme, setTheme] = useState(toasterData.isDarkTheme);
	useEffect(() => {
		setTheme(toasterData.isDarkTheme);
	}, [toasterData]);
	//theme
	const [globalFilterInputs, setGlobalFilterInputs] = useState(
		columns
			.map((col) => {
				return col.filter
					? {
							key: col.key,
							inputValue: "",
							columnTitle: col.title,
					  }
					: null;
			})
			?.filter(Boolean)
			.map((globalFilter) => {
				let existingFilter = filter.find(
					({ status, name }) => status && name === globalFilter.key
				);
				if (existingFilter) {
					return {
						...globalFilter,
						inputValue: existingFilter.data,
					};
				} else {
					return globalFilter;
				}
			})
	);
	function handleGlobalFilterChange(e, col) {
		setGlobalFilterInputs((prevFiltersInput) => {
			return prevFiltersInput.map((prevGlobalFilter) => {
				if (prevGlobalFilter.key === col.key) {
					return {
						...prevGlobalFilter,
						inputValue: e.target.value,
					};
				} else {
					return {
						...prevGlobalFilter,
					};
				}
			});
		});
	}
	function handleGlobalFilterSubmit() {
		setFilter((prevFilters) => {
			globalFilterInputs.forEach(({ key, inputValue, columnTitle }) => {
				if (inputValue.length > ZERO) {
					let existingFilter = prevFilters.find(
						({ name, status }) => name === key && status
					);
					if (existingFilter) {
						prevFilters = prevFilters.map((prevFilter) => {
							if (prevFilter.name === key) {
								return {
									...prevFilter,
									data: inputValue,
									columnTitle: columnTitle,
								};
							} else {
								return prevFilter;
							}
						});
					} else {
						prevFilters.push({
							status: true,
							type: FILTER,
							data: inputValue,
							name: key,
							columnTitle: columnTitle,
						});
					}
				} else {
					prevFilters = prevFilters.filter(({ name }) => name !== key);
				}
			});
			return prevFilters;
		});
		setFetchList((prev) => !prev);
		setToggleFilter(false);
	}
	useEffect(() => {
		setGlobalFilterInputs(
			columns
				.map((col) => {
					return col.filter
						? {
								key: col.key,
								inputValue: "",
								columnTitle: col.title,
						  }
						: null;
				})
				?.filter(Boolean)
				.map((globalFilter) => {
					let existingFilter = filter.find(
						({ status, name }) => status && name === globalFilter.key
					);
					if (existingFilter) {
						return {
							...globalFilter,
							inputValue: existingFilter.data,
						};
					} else {
						return globalFilter;
					}
				})
		);
	}, [filter]);
	return (
		<React.Fragment>
			<div
				className="global-filter-backdrop"
				onClick={() => {
					setToggleFilter(false);
				}}
			></div>
			<div className={`filter-outer`}>
				<IconContext.Provider value={{ color: "blue", size: "50px" }}>
					<GrFormClose
						color="red"
						className="filter-clear-icon"
						size={20}
						onClick={() => {
							setToggleFilter(false);
						}}
					/>
				</IconContext.Provider>
				<p>Filter results by...</p>
				<div className="filter-inner">
					{columns.map((col) => {
						return (
							col.advanceFilter &&
							col.filter && (
								<div className="filter-box" key={col.key}>
									{(() => {
										switch (col.type) {
											case DATE:
												return (
													<fieldset>
														<legend className="global-filter-input-label">
															{col.title}
														</legend>
														<DatePicker
															calendarIcon={
																<img
																	src={images.calendarIcon}
																	className="calendar-icon"
																	alt="calendar"
																/>
															}
															clearIcon={null}
															value={convertUTCDateToLocalDate(
																globalFilterInputs.find(
																	({ key }) => key === col.key
																).inputValue
															)}
															format="y-MM-dd"
															onChange={(value) => {
																handleGlobalFilterChange(
																	{ target: { value: formatDate(value) } },
																	col
																);
															}}
														/>
													</fieldset>
												);
											case STRING:
												return (
													<fieldset>
														<legend className="global-filter-input-label">
															{col.title}
														</legend>
														<input
															type="text"
															value={
																globalFilterInputs.find(
																	({ key }) => key === col.key
																).inputValue
															}
															onInput={(e) => {
																handleGlobalFilterChange(e, col);
															}}
														/>
													</fieldset>
												);
											case SELECT:
												return (
													<>
														<fieldset style={{ height: "auto" }}>
															<legend className="global-filter-input-label">
																{col.title}
															</legend>
															<Select
																options={col.options
																	.filter((option) => {
																		if (col.rights) {
																			const right = col.rights.find(
																				(right) => right.key === option
																			);
																			return (
																				right &&
																				checkRightsIsEditable(
																					getRightsKey(right.rights)
																				)
																			);
																		}
																		return true;
																	})
																	.map((option) => {
																		return {
																			label: option,
																			value: option,
																		};
																	})}
																isMulti={col.multi ? true : false}
																hideSelectedOptions={false}
																styles={{
																	option: (provided, state) => ({
																		...provided,
																		backgroundColor:
																			state.isSelected && theme === LIGHT
																				? DROPDOWN_SELECTED_BGCOLOR_LIGHT_THEME
																				: state.isSelected && theme === DARK
																				? DROPDOWN_SELECTED_BGCOLOR_DARK_THEME
																				: // ? DROPDOWN_FOCUSED_BGCOLOR_LIGHT_THEME
																				state.isFocused && theme === DARK
																				? DROPDOWN_FOCUSED_BGCOLOR_DARK_THEME
																				: state.isFocused && theme === LIGHT
																				? DROPDOWN_FOCUSED_BGCOLOR_LIGHT_THEME
																				: theme === DARK
																				? DROPDOWN_BGCOLOR_DARK_THEME
																				: "#fff",
																		color: state.isSelected
																			? "#fff"
																			: state.isFocused && theme === DARK
																			? "#fff"
																			: state.isFocused && theme === LIGHT
																			? DROPDOWN_SELECTED_BGCOLOR_LIGHT_THEME
																			: theme === LIGHT
																			? DROPDOWN_SELECTED_BGCOLOR_LIGHT_THEME
																			: "#fff",
																		padding: 8,
																		fontSize: 12,
																	}),
																	control: (baseStyles, state) => ({
																		...baseStyles,
																		position: "relative",
																		backgroundColor:
																			theme === DARK
																				? DROPDOWN_SELECTED_BGCOLOR_DARK_THEME
																				: "none",
																	}),
																	...getCustomStyles(theme),
																}}
																onChange={(value) => {
																	if (col.multi === true) {
																		if (value?.length > 0) {
																			handleGlobalFilterChange(
																				{
																					target: {
																						value: value
																							.map(
																								(selectedOption) =>
																									selectedOption.value
																							)
																							.join(","),
																					},
																				},
																				col
																			);
																		} else {
																			handleGlobalFilterChange(
																				{ target: { value: "" } },
																				col
																			);
																		}
																	} else {
																		if (value?.value) {
																			handleGlobalFilterChange(
																				{ target: { value: value.value } },
																				col
																			);
																		} else {
																			handleGlobalFilterChange(
																				{ target: { value: "" } },
																				col
																			);
																		}
																	}
																}}
																value={
																	globalFilterInputs.find(
																		({ key }) => key === col.key
																	)?.inputValue
																		? col.multi === true
																			? globalFilterInputs
																					.find(({ key }) => key === col.key)
																					.inputValue.split(",")
																					.map((selectedOption) => ({
																						label: selectedOption,
																						value: selectedOption,
																					}))
																			: {
																					label: globalFilterInputs.find(
																						({ key }) => key === col.key
																					).inputValue,
																					value: globalFilterInputs.find(
																						({ key }) => key === col.key
																					).inputValue,
																			  }
																		: null
																}
																placeholder=""
																isClearable={false}
																className="react-select-dropdown global-filter-input"
																closeMenuOnSelect={col.multi ? false : true}
															/>
														</fieldset>
														{/*
                          <div className="select-container">
                            <legend className="global-filter-input-label status-label">
                              {col.title}
                            </legend>
                            <div className="select-options-container">
                              {col.options.map((option) => {
                                return (
                                  <div
                                    className={`option ${
                                      globalFilterInputs.find(
                                        ({ key, inputValue }) =>
                                          key === col.key &&
                                          inputValue === option
                                      )
                                        ? SELECTED
                                        : null
                                    }`}
                                    onClick={() => {
                                      handleGlobalFilterChange(
                                        { target: { value: option } },
                                        col
                                      );
                                    }}
                                  >
                                    {option}
                                  </div>
                                );
                              })}
                            </div>
                            </div>*/}
													</>
												);
											default:
												return null;
										}
									})()}
								</div>
							)
						);
					})}
				</div>
				<div className="filter-popup-footer">
					<button
						className="btn btn-secondary"
						onClick={() => {
							setFilter([]);
							setFetchList((prev) => !prev);
							setToggleFilter(false);
						}}
					>
						Reset
					</button>
					<button
						className="btn btn-primary"
						onClick={handleGlobalFilterSubmit}
					>
						Apply
					</button>
				</div>
			</div>
		</React.Fragment>
	);
}

export function AdvanceSearchReader({
	filter,
	search,
	extraFiltersPopover,
	setExtraFiltersPopover,
	setFilter,
	setSearch,
	setFetchList,
	FILTER_SHOW_LIMIT,
}) {
	return (
		<div className="advance-search-container">
			{[...search, ...filter].length > ZERO
				? [...search, ...filter]
						.slice(0, FILTER_SHOW_LIMIT)
						?.map((col, index) => {
							return (
								<div className="query-wrapper" key={index}>
									<span>
										{col.data.length > 20
											? `${col.data.slice(0, 20)}...`
											: col.data}
									</span>
									{col.type === FILTER ? (
										<sub>{`(${col.columnTitle})`}</sub>
									) : null}
									<GrFormClose
										className="filter-clear-icon"
										size={20}
										onClick={() => {
											if (col.type === FILTER) {
												clearFilter(
													SINGLE,
													col,
													filter,
													setFilter,
													setSearch,
													setFetchList
												);
											} else {
												handleSearchClear(col.data, setSearch, setFetchList);
											}
										}}
									/>
								</div>
							);
						})
				: null}
			{[...search, ...filter]?.slice(FILTER_SHOW_LIMIT)?.length > 0 ? (
				<div
					className="query-wrapper-extra"
					onClick={() => {
						setExtraFiltersPopover(true);
					}}
				>
					+{[...search, ...filter].slice(FILTER_SHOW_LIMIT)?.length}
					{extraFiltersPopover === true && (
						<div className="filter-popover-container">
							<div
								className="filter-popover-backdrop"
								onClick={(e) => {
									setExtraFiltersPopover(false);
									e.stopPropagation();
								}}
							></div>
							<div className="filter-popover">
								{[...search, ...filter]
									.slice(FILTER_SHOW_LIMIT)
									?.map((filterCol) => {
										return (
											<div className="filter-menu-item" key={filterCol}>
												<b>
													{filterCol.data.length > 20
														? `${filterCol.data.slice(0, 20)}...`
														: filterCol.data}
												</b>
												{filterCol.type === FILTER ? (
													<sub>{`(${filterCol.columnTitle})`}</sub>
												) : null}
												<GrFormClose
													className="filter-menu-clear-icon"
													size={20}
													onClick={(e) => {
														if (filterCol.type === FILTER) {
															handleExtraFilterClear(
																filterCol,
																filter,
																setFilter,
																setFetchList
															);
														} else {
															handleSearchClear(
																filterCol.data,
																setSearch,
																setFetchList
															);
														}
														e.stopPropagation();
													}}
												/>
											</div>
										);
									})}
							</div>
						</div>
					)}
				</div>
			) : null}
			{[...filter, ...search]?.length > 1 ? (
				<div
					className={`clear-filter-container`}
					onClick={() => {
						clearFilter(ALL, null, filter, setFilter, setSearch, setFetchList);
					}}
				>
					<div className="clear-filter-icon-container">
						<BsArrowCounterclockwise className={`clear-icon`} />
					</div>
					<span className={`clear-filter-link`}>Clear</span>
				</div>
			) : null}
		</div>
	);
}

export function ColumnFilterPopover({
	columnFilter,
	setColumnFilter,
	col,
	shiftLeft,
	shiftRight,
	filter,
	setFilter,
	setFetchList,
}) {
	const [filterInputValue, setFilterInputValue] = useState(
		filter?.find((column) => column.name === col.key && column.status)?.data ||
			""
	);
	const toasterData = useSelector((state) => state.toasterData);
	const [theme, setTheme] = useState(toasterData.isDarkTheme);
	useEffect(() => {
		setTheme(toasterData.isDarkTheme);
	}, [toasterData]);
	useEffect(() => {
		setFilterInputValue(
			filter?.find((column) => column.name === col.key && column.status)
				?.data || ""
		);
	}, [col.key, filter, columnFilter]);
	return (
		<>
			{columnFilter.status && columnFilter.columnName === col.key ? (
				<>
					<div
						className="col-filter-backdrop"
						onClick={() => {
							setColumnFilter({
								status: false,
								columnName: null,
							});
						}}
					></div>
					<div
						className={`col-filter-container ${shiftLeft ? LEFT : null} ${
							shiftRight ? RIGHT : null
						}`}
					>
						<div className="col-filter-popover">
							<div className="col-filter-header">Filter on {col.title}</div>
							<GrFormClose
								className="filter-clear-icon"
								size={20}
								onClick={() => {
									setColumnFilter({
										status: false,
										columnName: null,
									});
								}}
							/>
							<div className="col-filter-wrapper">
								<div>
									{(() => {
										switch (col.type) {
											case STRING:
												return (
													<input
														type="text"
														className="col-filter-input"
														placeholder="Enter text..."
														value={filterInputValue}
														onChange={(e) => {
															setFilterInputValue(e.target.value);
														}}
														autoFocus
													/>
												);
											case DATE:
												return (
													<fieldset className="column-date-picker-container">
														<legend className="column-date-picker-legend" />
														<DatePicker
															calendarIcon={
																<img
																	src={images.calendarIcon}
																	className="calendar-icon"
																	alt="calendar"
																/>
															}
															clearIcon={null}
															value={convertUTCDateToLocalDate(
																filterInputValue
															)}
															format="y-MM-dd"
															onChange={(value) => {
																setFilterInputValue(formatDate(value));
															}}
														/>
													</fieldset>
												);
											case SELECT:
												return (
													<Select
														options={col.options
															.filter((option) => {
																if (col.rights) {
																	const right = col.rights.find(
																		(right) => right.key === option
																	);
																	return (
																		right &&
																		checkRightsIsEditable(
																			getRightsKey(right.rights)
																		)
																	);
																}
																return true;
															})
															.map((option) => {
																return {
																	label: option,
																	value: option,
																};
															})}
														hideSelectedOptions={false}
														closeMenuOnSelect={col.multi ? false : true}
														isClearable={false}
														styles={{
															option: (provided, state) => ({
																...provided,
																backgroundColor:
																	state.isSelected && theme === LIGHT
																		? DROPDOWN_SELECTED_BGCOLOR_LIGHT_THEME
																		: state.isSelected && theme === DARK
																		? DROPDOWN_SELECTED_BGCOLOR_DARK_THEME
																		: // ? DROPDOWN_FOCUSED_BGCOLOR_LIGHT_THEME
																		state.isFocused && theme === DARK
																		? DROPDOWN_FOCUSED_BGCOLOR_DARK_THEME
																		: state.isFocused && theme === LIGHT
																		? DROPDOWN_FOCUSED_BGCOLOR_LIGHT_THEME
																		: theme === DARK
																		? DROPDOWN_BGCOLOR_DARK_THEME
																		: "#fff",
																color: state.isSelected
																	? "#fff"
																	: state.isFocused && theme === DARK
																	? "#fff"
																	: state.isFocused && theme === LIGHT
																	? DROPDOWN_SELECTED_BGCOLOR_LIGHT_THEME
																	: theme === LIGHT
																	? DROPDOWN_SELECTED_BGCOLOR_LIGHT_THEME
																	: "#fff",
																padding: 8,
																fontSize: 12,
															}),
															control: (baseStyles, state) => ({
																...baseStyles,
																position: "relative",
																top: 5,
																backgroundColor:
																	theme === DARK
																		? DROPDOWN_SELECTED_BGCOLOR_DARK_THEME
																		: "none",
																maxHeight: "50px",
																width: "100%",
															}),
														}}
														isMulti={col.multi ? true : false}
														onChange={(value) => {
															// if (value) {
															//   setFilterInputValue(value.value);
															// } else {
															//   setFilterInputValue("");
															// }
															///
															if (col.multi === true) {
																if (value?.length > 0) {
																	setFilterInputValue(
																		value
																			.map(
																				(selectedOption) => selectedOption.value
																			)
																			.join(",")
																	);
																} else {
																	setFilterInputValue("");
																}
															} else {
																if (value?.value) {
																	setFilterInputValue(value.value);
																} else {
																	setFilterInputValue("");
																}
															}
														}}
														value={
															filterInputValue
																? col.multi === true
																	? filterInputValue
																			.split(",")
																			.map((selectedOption) => ({
																				label: selectedOption,
																				value: selectedOption,
																			}))
																	: {
																			label: filterInputValue,
																			value: filterInputValue,
																	  }
																: null
														}
														className="react-select-dropdown"
													/>
												);
											default:
												return null;
										}
									})()}
								</div>
							</div>
							<div className="col-filter-btn-container">
								<button
									className="btn btn-primary col-filter-btn apply"
									onClick={() => {
										if (filterInputValue.length > ZERO) {
											handleFilter(
												col,
												filterInputValue,
												APPLY,
												filter,
												setFilter,
												setFetchList
											);
											setFilterInputValue("");
											setColumnFilter({
												status: false,
												columnName: null,
											});
										}
									}}
									disabled={filterInputValue.length > ZERO ? false : true}
								>
									Apply
								</button>
								<div
									className={`clear-filter-container ${
										filter?.find(
											(column) => column.name === col.key && column.status
										)
											? false
											: DISABLED
									}`}
									onClick={() => {
										if (
											filter?.find(
												(column) => column.name === col.key && column.status
											)
										) {
											handleFilter(
												col,
												null,
												null,
												filter,
												setFilter,
												setFetchList
											);
											setFilterInputValue("");
											setColumnFilter({
												status: false,
												columnName: null,
											});
										}
									}}
								>
									<div className="clear-filter-icon-container">
										<BsArrowCounterclockwise
											disabled={
												filter?.find(
													(column) => column.name === col.key && column.status
												)
													? false
													: true
											}
											className={`clear-icon`}
										/>
									</div>
									<span className={`clear-filter-link`}>Clear</span>
								</div>
							</div>
						</div>
					</div>
				</>
			) : null}
		</>
	);
}
export const TableHeaderDataType = [
	{ value: "LiveDate", label: "Live Date" },
	{ value: "OfferStartDate", label: "Offer Start Date" },
	{ value: "OfferEndDate", label: "Offer End Date" },
];
export function TableAdvanceSearchHeader({
	searchInputQuery,
	setSearch,
	setFetchList,
	setSearchInputQuery,
	setToggleFilter,
	toggleFilter,
	filter,
	columns,
	setFilter,
	extraFiltersPopover,
	setExtraFiltersPopover,
	FILTER_SHOW_LIMIT,
	search,
	advanceSearchPlaceholder,
	dateRange,
	setDateRange,
	dateTypeOptions,
	dateType,
	setDateType,
	ADVANCE_SEARCH_HEADER,
}) {
	const toasterData = useSelector((state) => state.toasterData);
	const [theme, setTheme] = useState(toasterData.isDarkTheme);
	useEffect(() => {
		setTheme(toasterData.isDarkTheme);
	}, [toasterData]);
	return (
		<div className="header-container header-container-size">
			<div className="search-bar-container">
				<div className="search-bar">
					{ADVANCE_SEARCH_HEADER?.searchBar === true ? (
						<>
							<form
								onSubmit={(e) => {
									e.preventDefault();
									handleSearch(
										searchInputQuery,
										setSearch,
										setFetchList,
										setSearchInputQuery
									);
								}}
							>
								<input
									type="text"
									className="search-input"
									placeholder={advanceSearchPlaceholder}
									value={searchInputQuery}
									onInput={(e) => handleSearchChange(e, setSearchInputQuery)}
								/>
							</form>
							<div className="search-query-icon-container">
								<span className="vertical-line"></span>
								<FiSearch
									className={`search-icon ${
										searchInputQuery.length === ZERO && DISABLED
									}`}
									onClick={() =>
										handleSearch(
											searchInputQuery,
											setSearch,
											setFetchList,
											setSearchInputQuery
										)
									}
								/>
							</div>
						</>
					) : null}
					{ADVANCE_SEARCH_HEADER?.globalFilter === true ? (
						<div>
							<div
								className="icon-container"
								onClick={() => {
									setToggleFilter((prev) => !prev);
								}}
							>
								<FiFilter />
							</div>
							{toggleFilter && (
								<GlobalFilterPopOver
									setToggleFilter={setToggleFilter}
									filter={filter}
									columns={columns}
									setFilter={setFilter}
									setFetchList={setFetchList}
								/>
							)}
						</div>
					) : null}
				</div>
				<div className="advance-search-container">
					<AdvanceSearchReader
						filter={filter}
						clearFilter={clearFilter}
						search={search}
						setSearch={setSearch}
						extraFiltersPopover={extraFiltersPopover}
						setExtraFiltersPopover={setExtraFiltersPopover}
						setFilter={setFilter}
						setFetchList={setFetchList}
						FILTER_SHOW_LIMIT={FILTER_SHOW_LIMIT}
					/>
				</div>

				<div className="date-container">
					{ADVANCE_SEARCH_HEADER?.dateType === true ? (
						<div className="date-type-container">
							<Select
								options={dateTypeOptions.map((option) => ({
									label: option.name,
									value: option.code,
								}))}
								isSearchable={false}
								styles={{
									option: (provided, state) => ({
										...provided,
										backgroundColor:
											state.isSelected && theme === LIGHT
												? DROPDOWN_SELECTED_BGCOLOR_LIGHT_THEME
												: state.isSelected && theme === DARK
												? DROPDOWN_SELECTED_BGCOLOR_DARK_THEME
												: // ? DROPDOWN_FOCUSED_BGCOLOR_LIGHT_THEME
												state.isFocused && theme === DARK
												? DROPDOWN_FOCUSED_BGCOLOR_DARK_THEME
												: state.isFocused && theme === LIGHT
												? DROPDOWN_FOCUSED_BGCOLOR_LIGHT_THEME
												: theme === DARK
												? DROPDOWN_BGCOLOR_DARK_THEME
												: "#fff",
										color: state.isSelected
											? "#fff"
											: state.isFocused && theme === DARK
											? "#fff"
											: state.isFocused && theme === LIGHT
											? DROPDOWN_SELECTED_BGCOLOR_LIGHT_THEME
											: theme === LIGHT
											? DROPDOWN_SELECTED_BGCOLOR_LIGHT_THEME
											: "#fff",
										padding: 8,
									}),
									control: (baseStyles, state) => ({
										...baseStyles,
										position: "relative",
										top: -5,
										borderRadius: 10,
										border: theme === DARK ? "none" : "1px solid #c6c6d3",
										backgroundColor:
											theme === DARK ? "var(--popup-background)" : "none",
									}),
								}}
								value={{
									label: dateTypeOptions.find((opt) => opt.code === dateType)
										.name,
									value: dateType,
								}}
								onChange={(value) => {
									setDateType(value.value);
									setFetchList((prev) => !prev);
								}}
								className="react-select-dropdown date-type-dropdown"
							/>
						</div>
					) : null}
					{ADVANCE_SEARCH_HEADER?.dateRange === true ? (
						<div className="date">
							<div className="date-icon">
								<AiOutlineCalendar />
							</div>
							<span className="days-count">
								{getDaysFromStartEndDates(dateRange)} Days
							</span>
							<DateRangeFilter
								state={dateRange}
								setState={setDateRange}
								onChangeHandler={() => {
									setFetchList((prev) => !prev);
								}}
							/>
						</div>
					) : null}
					{ADVANCE_SEARCH_HEADER?.tableHeaderBtn && checkRightsIsVisible(
						ADVANCE_SEARCH_HEADER?.tableHeaderBtn?.rights
					)? (
						<button
							className="create-btn btn btn-primary"
							onClick={() =>
								!checkRightsIsEditable(
									ADVANCE_SEARCH_HEADER?.tableHeaderBtn?.rights
								)
									? ""
									: ADVANCE_SEARCH_HEADER?.tableHeaderBtn?.clickHandler()
							}
							disabled={
								!checkRightsIsEditable(
									ADVANCE_SEARCH_HEADER?.tableHeaderBtn?.rights
								)
							}
						>
							{ADVANCE_SEARCH_HEADER?.tableHeaderBtn?.label}
						</button>
					) : null}
				</div>
			</div>
		</div>
	);
}

export const getCustomStyles = (theme) => {
	return {
		control: (provided, state) => ({
			border: "none",
			borderRadius: "4px",
			display: "flex",
			padding: "0px",
			outline: "none",
			maxHeight: "50px",
			overflowY: "auto",
			position: "relative",
			top: "-5px",
			paddingTop: 2,
			// Add more custom styles as needed
		}),
		option: (provided, state) => ({
			...provided,
			backgroundColor:
				state.isSelected && theme === LIGHT
					? DROPDOWN_SELECTED_BGCOLOR_LIGHT_THEME
					: state.isSelected && theme === DARK
					? DROPDOWN_SELECTED_BGCOLOR_DARK_THEME
					: // ? DROPDOWN_FOCUSED_BGCOLOR_LIGHT_THEME
					state.isFocused && theme === DARK
					? DROPDOWN_FOCUSED_BGCOLOR_DARK_THEME
					: state.isFocused && theme === LIGHT
					? DROPDOWN_FOCUSED_BGCOLOR_LIGHT_THEME
					: theme === DARK
					? DROPDOWN_BGCOLOR_DARK_THEME
					: "#fff",
			color: state.isSelected
				? "#fff"
				: state.isFocused && theme === DARK
				? "#fff"
				: state.isFocused && theme === LIGHT
				? DROPDOWN_SELECTED_BGCOLOR_LIGHT_THEME
				: theme === LIGHT
				? DROPDOWN_SELECTED_BGCOLOR_LIGHT_THEME
				: "#fff",
			padding: 8,
		}),
		// Add more style properties as needed
	};
};
