export const CATEGORY_HEADER = "What is the category of your content";
export const COMMUNICATE_HEADER = "What to communicate";
export const DISPLAY_HEADER = "What to display";
export const INPUT_FIELDS = {
	Marketing: [
		{
			inputType: "string",
			displayName: "Marketing Name",
			fieldname: "MarketingName",
			validation: {
				required: true,
				minLength: 5,
				maxLength: 50,
				pattern: /^[a-zA-Z0-9]+$/,
				patternMessage: "Only alphanumeric characters are allowed",
			},
		},
		{
			inputType: "textArea",
			displayName: "Marketing Description",
			fieldname: "MarketingDescription",
			validation: {
				required: true,
				minLength: 5,
				maxLength: 100,
				pattern: /^[a-zA-Z0-9]+$/,
				patternMessage: "Only alphanumeric characters are allowed",
			},
		},
		{
			inputType: "image",
			displayName: "Marketing Image",
			fieldname: "MarketingImage",
			uploadText: "Drag & drop Images",
			validation: {
				required: true,
				imageType: ["jpg", "jpeg", "png"],
				imageSize: 1000000,
			},
		},
	],
	ONLINE: [
		{
			inputType: "string",
			displayName: "xyz Name",
			fieldname: "xyzName",
			validation: {
				required: true,
				minLength: 5,
				maxLength: 50,
				pattern: /^[a-zA-Z0-9]+$/,
				patternMessage: "Only alphanumeric characters are allowed",
			},
		},
		{
			inputType: "textArea",
			displayName: "xyz Description",
			fieldname: "xyzDescription",
			validation: {
				required: true,
				minLength: 5,
				maxLength: 100,
				pattern: /^[a-zA-Z0-9]+$/,
				patternMessage: "Only alphanumeric characters are allowed",
			},
		},
		{
			inputType: "image",
			displayName: "xyz Image",
			fieldname: "xyzImage",
			uploadText: "Drag & drop Images",
			validation: {
				required: true,
				imageType: ["jpg", "jpeg", "png"],
				imageSize: 1000000,
			},
		},
	],
};

export const tempPromtResponse = {
	promptResponse: {
		id: "cmpl-8f5MlXaWlZC5uh4pJ6enmleaF0Nbe",
		object: "text_completion",
		created: 1704802767,
		model: "gpt-3.5-turbo-instruct",
		choices: [
			{
				text: '\n\n"Celebrate the New Year with 50% OFF on ALL Online Shopping at FFB Corp! Don\'t miss out on our unbeatable deals!"',
				index: 0,
				finish_reason: "stop",
			},
			{
				text: '\n\n"New Year, New Savings: Enjoy 50% Off on All Online Shopping with FFB Corp!"',
				index: 1,
				finish_reason: "stop",
			},
			{
				text: '\n\n"New Year, New Deals: 50% off with FFB Corp. on all online stores! Shop now and save big!"',
				index: 2,
				finish_reason: "stop",
			},
		],
		usage: {
			prompt_tokens: 39,
			completion_tokens: 81,
			total_tokens: 120,
		},
	},
};
export const CUSTOM = "Custom";
