import React, { useEffect, useState } from "react";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import Loading from "../../tellurideExtComponents/LoaderComponents/Loading";
import { AUTH_LOADER_SOURCE } from "../../tellurideExtComponents/LoaderComponents/LoadingConstant";
import {
	fetchAuthInitDetails,
	setAuthLoadingFalse,
} from "../../tellurideExtRedux/security/SecurityAction";
import { useDispatch } from "react-redux";
import { SKEY } from "../../tellurideExtComponents/constantComponent/Constants";
import { setOrganizationName } from "../../tellurideExtRedux";
import { logoutAuth0 } from "../../tellurideExtRedux/reduxUtil";
export const AuthGuard = ({ component: Component }) => {
	const ComponentWithAuth = withAuthenticationRequired(Component, {
		onRedirecting: () => (
			<div className="page-layout">
				<Loading source={AUTH_LOADER_SOURCE} />
			</div>
		),
	});
	const { getAccessTokenSilently, isAuthenticated, user, logout } = useAuth0();
	const dispatch = useDispatch();
	useEffect(() => {
		if (isAuthenticated) {
			window.addEventListener("storage", handleSessionStorageChange);
		}
		return () => {
			if (localStorage.getItem("AllRights")) {
				window.removeEventListener("storage", handleSessionStorageChange);
			}
		};
	}, [isAuthenticated]);
	function handleSessionStorageChange(event) {
		if (event.storageArea === sessionStorage && event.key === "AllRights") {
			logoutAuth0(logout);
		}
	}
	useEffect(() => {
		if (isAuthenticated) {
			const getToken = async () => {
				try {
					const token = await getAccessTokenSilently();
					sessionStorage.setItem("accessToken", token);
					const headers = {
						"Content-Type": "application/json",
						accept: "application/json",
						organization: user ? user["https://offer-ui.syw.com/org_name"] : "",
					};
					const requestHeaders = {
						headers,
					};
					const requestBody = {
						userId: user ? user.email : "",
						auth0Token: token,
						signInTimeInMillis: Date.now(),
					};
					if (user) {
						let urlElements = window.location.pathname.split("/");
						if (
							!urlElements.includes(user["https://offer-ui.syw.com/org_name"])
						) {
							let urlElements = window.location.pathname.split("/");
							let urlElelement = urlElements[1];
							window.history.pushState(
								null,
								"",
								window.location.href.replace(
									urlElelement,
									user["https://offer-ui.syw.com/org_name"]
								)
							);
							// window.location.replace(
							//   window.location.href.replace(
							//     urlElelement,
							//     user["https://offer-ui.syw.com/org_name"]
							//   )
							// );
						}

						const userOrg = user["https://offer-ui.syw.com/org_name"];
						dispatch(setOrganizationName(userOrg));
						if (user["https://offer-ui.syw.com/roles"]) {
							//requestBody.roles= JSON.stringify(user["https://offer-ui.syw.com/roles"]);
							requestBody.roles = `[${user[
								"https://offer-ui.syw.com/roles"
							].join(",")}]`;
						}
						// requestBody.roles = user
						//   ? user["https://offer-ui.syw.com/roles"]
						//   : "";
						if (!sessionStorage.getItem(SKEY)) {
							dispatch(
								fetchAuthInitDetails(
									requestHeaders,
									requestBody,
									logout,
									userOrg
								)
							);
						} else {
							dispatch(setAuthLoadingFalse());
						}
					}
				} catch (e) {
					console.error(e);
				}
			};

			getToken();
		}
		// if (!isAuthenticated) {
		//    getAccessTokenSilently();
		// }
	}, [isAuthenticated]);

	return <ComponentWithAuth />;
};
