import React, { useEffect, useState } from "react";
import "./AttributeDetails.style.scss";
import { getCustomStyles } from "./AttributeDetailsUtil";
import {
	ATTRIBUTES_TYPES,
	ATTRIBUTE_DETAILS_HEADER,
	ATTRIBUTE_INPUT_ARRAY,
	ATTRIBUTE_INPUT_DESCRIPTION,
	ATTRIBUTE_INPUT_ENTITY,
	ATTRIBUTE_INPUT_LIST_INFO_BODY,
	ATTRIBUTE_INPUT_LIST_INFO_TITLE,
	ATTRIBUTE_INPUT_NAME,
	ATTRIBUTE_INPUT_TYPE,
} from "./AttributeDetailsConstant";
import Select from "react-select";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { DESCRIPTION_LABEL } from "../../offerSetupComponent/offerDetailsComponent/OfferDetailsConstants";
import { useDispatch, useSelector } from "react-redux";
import { setAttributeDetails } from "../../../tellurideExtRedux";
import { LIST, STRING, SUCCESS } from "../../constantComponent/Constants";
import {
	ATTR_DESCRIPTION_LENGTH_VALIDATION_MESSAGE,
	ATTR_NAME_LENGTH_VALIDATION_MESSAGE,
	IS_REQUIRED,
} from "../../constantComponent/UIErrorMessages";
import { images } from "../../imagesComponent/Images";
function AttributeDetails({
	isDisabled,
	attributeSetupValidation,
	setAttributeSetupValidation,
	theme,
}) {
	const [attributeSetup, setAttibuteSetup] = useState({
		name: "",
		type: STRING,
		entity: "",
		description: "",
	});

	const [showInfo, setShowInfo] = useState(false);
	const metaData = useSelector((state) => state.metaData);
	const updateAttributeDetailsData = metaData.updateAttributeDetailsData;
	const entities = metaData?.entitiesResponse?.entities || [];

	const initialAttrSaveData = metaData.getAttributeDetailsResponse;
	useEffect(() => {
		if (initialAttrSaveData?.status === SUCCESS) {
			const attrDetails = initialAttrSaveData?.attribute;
			setAttibuteSetup({
				name: attrDetails?.name,
				type: attrDetails?.type,
				entity: attrDetails?.entity,
				description: attrDetails?.description,
			});
		}
	}, [initialAttrSaveData]);
	useEffect(() => {
		setAttributeSetupValidation((prev) => {
			let temp = prev;
			for (let inputKey in temp) {
				if (ATTRIBUTE_INPUT_ARRAY.includes(inputKey)) {
					if (attributeSetup[inputKey]?.length === 0) {
						temp[inputKey] = {
							...temp[inputKey],
							status: true,
							message: `${inputKey} ${IS_REQUIRED}`,
						};
					} else if (inputKey === ATTRIBUTE_INPUT_NAME) {
						if (
							attributeSetup[inputKey]?.length < 2 ||
							attributeSetup[inputKey]?.length > 36
						) {
							setAttributeSetupValidation((prev) => {
								return {
									...prev,
									name: {
										...prev[ATTRIBUTE_INPUT_NAME],
										message: `${inputKey} ${ATTR_NAME_LENGTH_VALIDATION_MESSAGE}`,
										status: true,
									},
								};
							});
						} else {
							setAttributeSetupValidation((prev) => {
								return {
									...prev,
									[inputKey]: {
										...prev[inputKey],
										message: ``,
										status: false,
									},
								};
							});
						}
					} else if (inputKey === ATTRIBUTE_INPUT_DESCRIPTION) {
						if (
							attributeSetup[inputKey]?.length < 5 ||
							attributeSetup[inputKey]?.length > 100
						) {
							setAttributeSetupValidation((prev) => {
								return {
									...prev,
									[inputKey]: {
										...prev[inputKey],
										message: `${inputKey} ${ATTR_DESCRIPTION_LENGTH_VALIDATION_MESSAGE}`,
										status: true,
									},
								};
							});
						} else {
							setAttributeSetupValidation((prev) => {
								return {
									...prev,
									[inputKey]: {
										...prev[inputKey],
										message: ``,
										status: false,
									},
								};
							});
						}
					}
				}
			}
			return temp;
		});
	}, [attributeSetup]);

	function handlechange(name, value) {
		if (isDisabled === false) {
			setAttibuteSetup((prev) => {
				return {
					...prev,
					[name]: value,
				};
			});
			if (value.length === 0) {
				setAttributeSetupValidation((prev) => {
					return {
						...prev,
						[name]: {
							...prev[name],
							message: `${name} ${IS_REQUIRED}`,
							status: true,
						},
					};
				});
			} else if (name === ATTRIBUTE_INPUT_NAME) {
				if (value.length < 2 || value.length > 36) {
					setAttributeSetupValidation((prev) => {
						return {
							...prev,
							name: {
								...prev[ATTRIBUTE_INPUT_NAME],
								message: `${name} ${ATTR_NAME_LENGTH_VALIDATION_MESSAGE}`,
								status: true,
								touched: true,
							},
						};
					});
				} else {
					setAttributeSetupValidation((prev) => {
						return {
							...prev,
							[name]: {
								...prev[name],
								message: ``,
								status: false,
							},
						};
					});
				}
			} else if (name === ATTRIBUTE_INPUT_DESCRIPTION) {
				if (value.length < 5 || value.length > 100) {
					setAttributeSetupValidation((prev) => {
						return {
							...prev,
							[name]: {
								...prev[name],
								message: `${name} ${ATTR_DESCRIPTION_LENGTH_VALIDATION_MESSAGE}`,
								status: true,
								touched: true,
							},
						};
					});
				} else {
					setAttributeSetupValidation((prev) => {
						return {
							...prev,
							[name]: {
								...prev[name],
								message: ``,
								status: false,
							},
						};
					});
				}
			} else {
				setAttributeSetupValidation((prev) => {
					return {
						...prev,
						[name]: {
							...prev[name],
							message: ``,
							status: false,
						},
					};
				});
			}
		}
	}
	function handleBlur(e) {
		let name = e.target.name;
		let value = e.target.value;
		if (attributeSetup[name]?.length === 0) {
			setAttributeSetupValidation((prev) => {
				return {
					...prev,
					[name]: {
						message: `${name} ${IS_REQUIRED}`,
						status: true,
						touched: true,
					},
				};
			});
		} else if (name === ATTRIBUTE_INPUT_NAME) {
			if (value.length < 2 || value.length > 36) {
				setAttributeSetupValidation((prev) => {
					return {
						...prev,
						name: {
							...prev[ATTRIBUTE_INPUT_NAME],
							message: `${name} ${ATTR_NAME_LENGTH_VALIDATION_MESSAGE}`,
							status: true,
							touched: true,
						},
					};
				});
			} else {
				setAttributeSetupValidation((prev) => {
					return {
						...prev,
						[name]: {
							...prev[name],
							message: ``,
							status: false,
						},
					};
				});
			}
		} else if (name === ATTRIBUTE_INPUT_DESCRIPTION) {
			if (value.length < 5 || value.length > 100) {
				setAttributeSetupValidation((prev) => {
					return {
						...prev,
						[name]: {
							...prev[name],
							message: `${name} ${ATTR_DESCRIPTION_LENGTH_VALIDATION_MESSAGE}`,
							status: true,
							touched: true,
						},
					};
				});
			} else {
				setAttributeSetupValidation((prev) => {
					return {
						...prev,
						[name]: {
							...prev[name],
							message: ``,
							status: false,
						},
					};
				});
			}
		} else {
			setAttributeSetupValidation((prev) => {
				return {
					...prev,
					[name]: {
						...prev[name],
						message: ``,
						status: false,
					},
				};
			});
		}
	}
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(
			setAttributeDetails({
				...updateAttributeDetailsData,
				...attributeSetup,
			})
		);
	}, [attributeSetup, dispatch]);
	return (
		<>
			<div className="attribute-details-title setup-page-section-title">
				{ATTRIBUTE_DETAILS_HEADER}
			</div>
			<div className="attribute-details-section">
				<div className="attribute-details-section-inner">
					<div className="attribute-details-section-fieldset">
						<fieldset
							className="attribute-details-fieldset"
							disabled={isDisabled === true ? true : false}
						>
							<legend>Attribute Name</legend>
							<input
								type="text"
								value={attributeSetup.name}
								name="name"
								onChange={(e) => {
									handlechange(e.target.name, e.target.value);
								}}
								onBlur={handleBlur}
								autocomplete="off"
								autocorrect="off"
								autocapitalize="off"
								spellcheck="false"
							/>
							<small className="error-message">
								{attributeSetupValidation?.name?.status === true &&
									attributeSetupValidation?.name?.touched === true &&
									attributeSetupValidation?.name?.message}
							</small>
						</fieldset>

						<div className="attribute-details-fieldset">
							<fieldset
								className="selector-fieldset attribute-details-fieldset dropdown"
								disabled={isDisabled === true ? true : false}
							>
								<legend>Attribute Type</legend>
								<Select
									value={{
										label: ATTRIBUTES_TYPES.find(
											(opt) => opt.value === attributeSetup.type
										)?.label,
										value: attributeSetup.type,
									}}
									options={ATTRIBUTES_TYPES}
									styles={getCustomStyles(theme)}
									onChange={({ value }) => {
										handlechange(ATTRIBUTE_INPUT_TYPE, value);
									}}
									isDisabled={isDisabled === true ? true : false}
									onBlur={() => {
										handleBlur({ target: { name: ATTRIBUTE_INPUT_TYPE } });
									}}
								/>
							</fieldset>
							<div className="info-icon-container">
								{attributeSetup?.type?.toLowerCase() === LIST && (
									<img
										src={images.infoImage}
										className="info-icon"
										onMouseEnter={() => {
											setShowInfo(true);
										}}
										onMouseLeave={() => {
											setShowInfo(false);
										}}
									/>
								)}

								{showInfo && (
									<>
										<div className="info-msg-point">
											<div className="triangle"></div>
											<p>{ATTRIBUTE_INPUT_LIST_INFO_TITLE}</p>
											{ATTRIBUTE_INPUT_LIST_INFO_BODY}
										</div>
									</>
								)}
							</div>
							<small className="error-message">
								{attributeSetupValidation?.type?.status === true &&
									attributeSetupValidation?.type?.touched === true &&
									attributeSetupValidation?.type?.message}
							</small>
						</div>
					</div>
					<div className="row-2">
						<div className="col-1">
							<div className="outer-input">
								<fieldset
									className="selector-fieldset attribute-details-fieldset entity-fieldset dropdown"
									disabled={isDisabled === true ? true : false}
								>
									<legend>Entity</legend>
									<Select
										value={{
											label: attributeSetup.entity,
											value: attributeSetup.entity,
										}}
										name="OFFER_FOR"
										options={entities?.map((entity) => ({
											label: entity,
											value: entity,
										}))}
										styles={getCustomStyles(theme)}
										onChange={({ value }) => {
											handlechange(ATTRIBUTE_INPUT_ENTITY, value);
										}}
										className="entity-dropdown"
										isDisabled={isDisabled === true ? true : false}
										onBlur={() => {
											handleBlur({ target: { name: ATTRIBUTE_INPUT_ENTITY } });
										}}
									/>
									<small className="error-message">
										{attributeSetupValidation?.entity?.status === true &&
											attributeSetupValidation?.entity?.touched === true &&
											attributeSetupValidation?.entity?.message}
									</small>
								</fieldset>
							</div>
						</div>
						<div className="col-1">
							<div className="outer-input">
								<div className="attr-description-wrapper">
									<fieldset
										className="text-box description-fieldset"
										disabled={isDisabled === true ? true : false}
									>
										<legend>{DESCRIPTION_LABEL}</legend>
										<textarea
											className="input-text-textarea"
											rows="2"
											cols="50"
											maxLength="250"
											name="description"
											onChange={(e) => {
												handlechange(e.target.name, e.target.value);
											}}
											disabled={isDisabled === true ? true : false}
											value={attributeSetup.description}
											onBlur={handleBlur}
										/>
									</fieldset>
									<small className="error-message attr-description">
										{attributeSetupValidation?.description?.status === true &&
											attributeSetupValidation?.description?.touched === true &&
											attributeSetupValidation?.description?.message}
									</small>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default AttributeDetails;
