import React, { useEffect, useRef, useState } from "react";
import moment from "moment";
import { DateRangePicker } from "react-date-range";
import "./CalendarDateRange.style.scss";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file

import { BACKDROP_ID, DASH } from "./DefaultRange";
// TO-DO
// state, setstate, months will be received as a props from parent component

const DateRangeFilter = ({ state, setState, onChangeHandler }) => {
  const [isModelOpen, setModelOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState("");
  const formatDate = (date) => moment(date).format("YYYY-MM-DD");
  const filteredStartDate = state[0].startDate
    ? formatDate(state[0].startDate)
    : "";
  const filteredEndDate = state[0].endDate ? formatDate(state[0].endDate) : "";

  function showCalendarBackdrop() {
    const backdrop = document.querySelector(".backdrop");
    backdrop.classList.add("show");
    setModelOpen(true);
    return;
  }

  function hideCalendarBackdrop() {
    const backdrop = document.querySelector(".backdrop");
    setModelOpen(false);
    backdrop?.classList.remove("show");
  }

  function findComposePath(event) {
    let isBackdropExist = false;
    event.composedPath().map((value) => {
      if (value.id === BACKDROP_ID) {
        isBackdropExist = true;
      }
    });
    return isBackdropExist;
  }

  useEffect(() => {
    if (state[0].startDate !== "" && state[0].endDate !== "") {
      setSelectedDate(filteredStartDate + DASH + filteredEndDate);
    }
  }, [filteredStartDate, filteredEndDate]);

  useEffect(() => {
    document.addEventListener("mousedown", function (event) {
      var backdrop = document.getElementById("backdrop");
      if (event.target != backdrop && event.target.parentNode != backdrop) {
        if (!findComposePath(event)) {
          if (isModelOpen) {
            hideCalendarBackdrop();
          }
        }
      }
    });
    return () => {
      document.removeEventListener("mousedown", function (event) {
        var backdrop = document.getElementById("backdrop");
        if (event.target != backdrop && event.target.parentNode != backdrop) {
          if (!findComposePath(event)) {
            if (isModelOpen) {
              hideCalendarBackdrop();
            }
          }
        }
      });
    };
  }, [isModelOpen]);

  const onChange = (ranges) => {
    if (
      moment(ranges.startDate).format("MM-DD-YYYY") !==
      moment(ranges.endDate).format("MM-DD-YYYY")
    ) {
      hideCalendarBackdrop();
    } else if (ranges.startDate === "" && ranges.endDate === "") {
      hideCalendarBackdrop();
    }
    if (onChangeHandler) {
      onChangeHandler();
    }
  };

  const handleOnChange = (ranges) => {
    const { selection } = ranges;
    onChange(selection);
    setState([selection]);
  };

  return (
    <>
      <div className="col-1">
        <div className="outer-input">
          <fieldset
            className="start-end-fieldset flex center"
            onClick={() => showCalendarBackdrop()}
          >
            <input
              type="text"
              placeholder={
                selectedDate ? selectedDate : "Start Date - End Date"
              }
              readOnly
            />
          </fieldset>
        </div>
      </div>
      <div
        id="backdrop"
        className="backdrop fixed-position-backdrop calendar-date-range"
      >
        <div className="modal">
          <DateRangePicker
            onChange={handleOnChange}
            showSelectionPreview={true}
            moveRangeOnFirstSelection={false}
            months={1}
            editableDateInputs={true}
            dateDisplayFormat="MMM-dd-yyyy"
            // maxDate={new Date()}
            ranges={state}
            direction="horizontal"
            rangeColors={["#393854"]}
            inputRanges={[]}
          />
        </div>
      </div>
    </>
  );
};

export default DateRangeFilter;
