import React from "react";
import OfferDetailsHeader from "../offerDetailsHeaderComponent/OfferDetailsHeader";
import {
  DOLLAR_OFF,
  FLAT_VALUE,
  MAX,
  MIN,
  PERCENT_OFF,
  QUANTITY,
  SPEND,
  VALUE,
  discountTypeAlias,
} from "./DiscountConstant";
import { NumericFormat } from "react-number-format";
// TO BE USED IN 2ND PHASE
function PropertiesForm({
  prop,
  onKeyPressAction,
  limit,
  addValue,
  index,
  discountType,
  minVal,
  maxVal,
  currentIndex,
  isLastIndex,
  ele,
  dollerPercentOffVal,
  thresholdDropDownValue,
  isSetDisable,
}) {
  return (
    <>
      {prop.name === MIN ? (
        <div className="col-1">
          <div className="outer-input">
            <fieldset
              disabled={currentIndex !== 0 || isSetDisable}
              className="flex discount-fieldset"
            >
              <legend>Min</legend>
              {thresholdDropDownValue === SPEND && (
                <input
                  className="dollar dollar-spend"
                  type="text"
                  defaultValue={"$"}
                  readOnly
                ></input>
              )}
              {thresholdDropDownValue === SPEND && (
                <NumericFormat
                  className="amount"
                  value={minVal}
                  thousandsGroupStyle="lakh"
                  thousandSeparator=","
                  displayType="input"
                  decimalScale={3}
                  renderText={(value) => <b>{value}</b>}
                  isAllowed={(values) => {
                    const { floatValue } = values;
                    return (
                      floatValue <= 9999999.9999 || floatValue === undefined
                    );
                  }}
                  onValueChange={(values, sourceInfo) => {
                    addValue(
                      index,
                      values.formattedValue,
                      MIN,
                      limit,
                      currentIndex
                    );
                    minVal = values;
                  }}
                  onKeyPress={(e) => {
                    onKeyPressAction(e);
                  }}
                />
              )}
              {thresholdDropDownValue === QUANTITY && (
                <NumericFormat
                  className="amount"
                  value={minVal}
                  thousandsGroupStyle="lakh"
                  thousandSeparator=","
                  displayType="input"
                  decimalScale={0}
                  renderText={(value) => <b>{value}</b>}
                  isAllowed={(values) => {
                    const { floatValue } = values;
                    return floatValue < 99999999 || floatValue === undefined;
                  }}
                  onValueChange={(values, sourceInfo) => {
                    addValue(
                      index,
                      values.formattedValue,
                      MIN,
                      limit,
                      currentIndex
                    );
                    minVal = values;
                  }}
                  onKeyPress={(e) => {
                    onKeyPressAction(e);
                  }}
                />
              )}
            </fieldset>
          </div>
          <div className="error-message"></div>
        </div>
      ) : (
        <></>
      )}
      {prop.name === MAX ? (
        <div className="col-1">
          <div className="outer-input">
            <fieldset
              className="flex discount-fieldset"
              disabled={isSetDisable}
            >
              <legend>Max</legend>
              {thresholdDropDownValue === SPEND && (
                <input
                  className="dollar dollar-spend"
                  type="text"
                  defaultValue={"$"}
                  readOnly
                ></input>
              )}
              {thresholdDropDownValue === SPEND && (
                <NumericFormat
                  className="amount"
                  value={maxVal}
                  thousandsGroupStyle="lakh"
                  thousandSeparator=","
                  displayType="input"
                  decimalScale={3}
                  renderText={(value) => <b>{value}</b>}
                  isAllowed={(values) => {
                    const { floatValue } = values;
                    return (
                      floatValue <= 9999999.9999 || floatValue === undefined
                    );
                  }}
                  onValueChange={(values, sourceInfo) => {
                    addValue(
                      index,
                      values.formattedValue,
                      MAX,
                      limit,
                      currentIndex
                    );
                    maxVal = values;
                  }}
                  onKeyPress={(e) => {
                    onKeyPressAction(e);
                  }}
                />
              )}
              {thresholdDropDownValue === QUANTITY && (
                <NumericFormat
                  className="amount"
                  value={maxVal}
                  thousandsGroupStyle="lakh"
                  thousandSeparator=","
                  displayType="input"
                  decimalScale={0}
                  renderText={(value) => <b>{value}</b>}
                  isAllowed={(values) => {
                    const { floatValue } = values;
                    return floatValue <= 9999999 || floatValue === undefined;
                  }}
                  onValueChange={(values, sourceInfo) => {
                    addValue(
                      index,
                      values.formattedValue,
                      MAX,
                      limit,
                      currentIndex
                    );
                    maxVal = values;
                  }}
                  onKeyPress={(e) => {
                    onKeyPressAction(e);
                  }}
                />
              )}
            </fieldset>
          </div>
          <div
            className={
              isLastIndex
                ? maxVal
                  ? parseFloat(minVal) >= parseFloat(maxVal)
                    ? "error-message show"
                    : "error-message"
                  : "error-message"
                : parseFloat(minVal) >= parseFloat(maxVal)
                ? "error-message show"
                : "error-message"
            }
          >
            {isLastIndex
              ? maxVal
                ? parseFloat(minVal) >= parseFloat(maxVal)
                  ? (ele.isMaxInValid = true)
                  : (ele.isMaxInValid = false)
                : (ele.isMaxInValid = false)
              : parseFloat(minVal) >= parseFloat(maxVal)
              ? (ele.isMaxInValid = true)
              : (ele.isMaxInValid = false)}
            Max should be greater than Min
          </div>
        </div>
      ) : (
        <></>
      )}
      {prop.name === DOLLAR_OFF || prop.name === PERCENT_OFF ? (
        <div className="col-1">
          <div className="outer-input">
            <fieldset
              className="flex discount-fieldset"
              disabled={isSetDisable}
            >
              <legend>{discountTypeAlias[discountType] + " Discount"}</legend>
              {prop.name !== PERCENT_OFF && (
                <input
                  className="dollar dollar-spend"
                  type="text"
                  defaultValue={"$"}
                  readOnly
                ></input>
              )}
              {discountType === DOLLAR_OFF && (
                <NumericFormat
                  className="amount"
                  value={dollerPercentOffVal}
                  thousandsGroupStyle="lakh"
                  thousandSeparator=","
                  displayType="input"
                  renderText={(value) => <b>{value}</b>}
                  isAllowed={(values) => {
                    const { floatValue } = values;
                    return (
                      floatValue <= 9999999.9999 || floatValue === undefined
                    );
                  }}
                  onValueChange={(values, sourceInfo) => {
                    addValue(index, values.formattedValue, discountType, limit);
                    dollerPercentOffVal = values;
                  }}
                  onKeyPress={(e) => {
                    onKeyPressAction(e);
                  }}
                />
              )}
              {discountType === PERCENT_OFF && (
                <NumericFormat
                  className="amount"
                  value={dollerPercentOffVal}
                  thousandsGroupStyle="lakh"
                  thousandSeparator=","
                  displayType="input"
                  decimalScale={0}
                  renderText={(value) => <b>{value}</b>}
                  isAllowed={(values) => {
                    const { floatValue } = values;
                    return floatValue <= 100 || floatValue === undefined;
                  }}
                  onValueChange={(values, sourceInfo) => {
                    addValue(index, values.formattedValue, discountType, limit);
                    dollerPercentOffVal = values;
                  }}
                  onKeyPress={(e) => {
                    onKeyPressAction(e);
                  }}
                />
              )}
            </fieldset>
          </div>
          <div
            className={
              dollerPercentOffVal
                ? dollerPercentOffVal <= 0
                  ? "error-message show"
                  : "error-message"
                : "error-message show"
            }
          >
            {dollerPercentOffVal
              ? dollerPercentOffVal <= 0
                ? (ele.isDollarPercentInValid = true)
                : (ele.isDollarPercentInValid = false)
              : (ele.isDollarPercentInValid = true)}
            {discountTypeAlias[discountType] + " value is required"}
          </div>
        </div>
      ) : (
        <></>
      )}
      {prop.name === FLAT_VALUE ? (
        <div className="col-1">
          <div className="outer-input">
            <fieldset>
              <legend>Points</legend>
              <input
                className="dollar dollar-spend"
                type="text"
                defaultValue={"$"}
                readOnly
              ></input>
              <input
                className="amount"
                type="text"
                value={prop.values.value.map((val) => {
                  return val;
                })}
                onChange={(e) => {
                  addValue(index, e.target.value, FLAT_VALUE, limit);
                }}
                onKeyPress={(e) => {
                  onKeyPressAction(e);
                }}
              ></input>
              {/* <span>Spend Threshold</span> */}
            </fieldset>
          </div>
        </div>
      ) : (
        <></>
      )}
      {prop.name === VALUE ? (
        <div className="col-1">
          <div className="outer-input">
            <fieldset>
              <legend>Points</legend>
              <input
                className="dollar dollar-spend"
                type="text"
                defaultValue={"$"}
                readOnly
              ></input>
              <input
                className="amount"
                type="text"
                value={prop.values.value.map((val) => {
                  return val;
                })}
                onChange={(e) => {
                  addValue(index, e.target.value, VALUE, limit);
                }}
                onKeyPress={(e) => {
                  onKeyPressAction(e);
                }}
              ></input>
              {/* <span>Spend Threshold</span> */}
            </fieldset>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
}

export default PropertiesForm;
