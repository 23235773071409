import {
  ACTIVATE_CONFIRMATION_MESSAGE,
  DEACTIVATE_CONFIRMATION_MESSAGE,
  SAVE_AND_ACTIVATE_CONFIRMATION_MESSAGE,
} from "../../constantComponent/MessageConstant";
import "../Backdrop.style.scss";
import "./SessionExpiredConfirmation.style.scss";
import { IoClose } from "react-icons/io5";
import { useSelector } from "react-redux";
import { AnimatePresence, motion } from "framer-motion/dist/framer-motion";
import { useAuth0 } from "@auth0/auth0-react";
import { images } from "../../imagesComponent/Images";
import { useEffect, useState } from "react";
import { logoutAuth0 } from "../../../tellurideExtRedux/reduxUtil";

const SessionExpiredConfirmation = (props) => {
  const { logout } = useAuth0();
  const offerDetailsPageData = useSelector((state) => state.offerDetailsData);
  const orgName = offerDetailsPageData?.authOrganizationName;

  return (
    <>
      <div className="session-confirmation-model">
        <div id="backdrop" className="backdrop fixed-position-backdrop show">
          <motion.div
            className="modal"
            initial={{ opacity: 0, scale: 0 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.85 }}
          >
            <section className="modal-body">
              <div className="flex center body">
                <img src={images.logoutIcon} />
                {/* to be changed */}
                {/* <h1>Your session has expired.</h1> */}
                <p className="flex center">
                  You have been logged out due to inactivity.
                </p>
                <p className="flex center">Please login again.</p>
              </div>
            </section>
            <div className="modal-footer">
              <div className="alignment">
                <button
                  className="save-button btn btn-primary"
                  onClick={() => {
                    // logout({
                    //   logoutParams: { returnTo: window.location.origin },
                    // })
                    localStorage.setItem(
                      "lastVisitedUrl",
                      window.location.href
                    );

                    logoutAuth0(logout, orgName);
                  }}
                >
                  {" "}
                  Login
                </button>
              </div>
            </div>
          </motion.div>
        </div>
      </div>
    </>
  );
};

export default SessionExpiredConfirmation;
