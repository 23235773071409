import { useAuth0 } from "@auth0/auth0-react";
import Popup from "reactjs-popup";
import { images } from "../imagesComponent/Images";
import "./Header.style.scss";
import { useEffect, useState } from "react";
import { setDarkLightModeStatus } from "../../tellurideExtRedux";
import { useDispatch, useSelector } from "react-redux";
import { fetchAuthLogoutDetails } from "../../tellurideExtRedux/security/SecurityAction";
import { skey } from "../utilsComponent/CommonUtil";
import { DARK, THEME } from "./HeaderConstant";

const Header = ({ title }) => {
	const { user, logout } = useAuth0();
	const [menuOpened, setMenuOpened] = useState(false);
	const dispatch = useDispatch();
	const offerDetailsPageData = useSelector((state) => state.offerDetailsData);
	const orgName = offerDetailsPageData?.authOrganizationName;
	//theme
	const toasterData = useSelector((state) => state.toasterData);
	const [theme, setTheme] = useState(
		localStorage.getItem(THEME)
			? localStorage.getItem(THEME)
			: toasterData.isDarkTheme
	);
	useEffect(() => {
		if (localStorage.getItem(THEME)) {
			dispatch(
				setDarkLightModeStatus(
					localStorage.getItem(THEME) === DARK ? true : false
				)
			);
		}
	}, []);
	useEffect(() => {
		setTheme(toasterData.isDarkTheme);
		document.body.setAttribute("data-body-theme", toasterData.isDarkTheme);
	}, [toasterData]);
	//theme

	function apiLogout() {
		//ChatBot change
		localStorage.removeItem('chat_messages');
		localStorage.removeItem('chatbot_display_param_status');
		
		const headers = {
			sessionId: skey(),
			organization: user?.["https://offer-ui.syw.com/org_name"],
		};
		const requestHeaders = {
			headers,
		};
		dispatch(fetchAuthLogoutDetails(requestHeaders, "", logout, orgName));
	}
	return (
		<>
			<section className="header-main-container">
				<div className="header-page-name">
					<img
						src={images.tellurideLogoWhite}
						style={{
							position: "absolute",
							top: "2px",
							width: "65px",
							cursor: "pointer",
						}}
						alt=""
						onClick={() => {
							window.location.pathname = "/";
						}}
					/>
				</div>
				<div className="header-space-area">
					<div className="header-title">{title}</div>
				</div>
				<div className="dark-light-toggle">
					{theme === DARK && (
						<div
							className="themeIcons"
							onClick={() => {
								dispatch(
									setDarkLightModeStatus(
										toasterData.isDarkTheme === DARK ? false : true
									)
								);
							}}
						>
							<img src={images.darkModeIcon} />
						</div>
					)}
					{theme !== DARK && (
						<div
							className="themeIcons"
							onClick={() => {
								dispatch(
									setDarkLightModeStatus(
										toasterData.isDarkTheme === DARK ? false : true
									)
								);
							}}
						>
							<img src={images.lightModeIcon} />
						</div>
					)}
				</div>
				<div className="right-content">
					<div className="syw-dropdown">
						<p>{user.name}</p>
					</div>
					<div className="avatar-div">
						<Popup
							trigger={
								<img
									src={images.arrowDown}
									className={`down-arrow  ${menuOpened && "menu-opened"}`}
								/>
							}
							position="bottom right"
							on="click"
							closeOnDocumentClick
							mouseLeaveDelay={300}
							mouseEnterDelay={0}
							contentStyle={{ padding: "0px", border: "none" }}
							arrow={false}
							offsetY={21}
							offsetX={10}
							onOpen={() => {
								setMenuOpened(true);
							}}
							onClose={() => {
								setMenuOpened(false);
							}}
						>
							<div className="menu" data-theme={theme}>
								<div className="c-details">
									<p className="details_corp">
										{user["https://offer-ui.syw.com/org_display_name"]}
									</p>
								</div>
								<div className="c-details">
									<p className="details_name">{user.name}</p>
									<p className="details_email">{user.email}</p>
								</div>
								<div className="menu-item" onClick={() => apiLogout()}>
									<img src={images.logoutIcon} className="logout_icon" />
									Logout
								</div>
							</div>
						</Popup>
					</div>
				</div>
			</section>
		</>
	);
};

export default Header;
