import {
  GET_REPORT_TOKEN_FAILURE,
  GET_REPORT_TOKEN_REQUEST,
  GET_REPORT_TOKEN_SUCCESS,
  GET_REPORT_TOKEN_SUCCESS_FAILURE,
  SET_SESSION_TIMEOUT_POPUP,
} from "./ReportDetilasType";

const initialState = {
  reportTokenLoading: false,
  reportTokenResponse: "",
  reportToken: "",
  dashboardId: "",
  reportTokenPageError: "",
  showExpiredSessionPopup: false,
};

export const reportTokenReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_REPORT_TOKEN_REQUEST:
      return {
        ...state,
        reportTokenLoading: true,
      };
    case GET_REPORT_TOKEN_SUCCESS:
      return {
        ...state,
        reportTokenLoading: false,
        reportTokenResponse: action.payload,
        reportToken: action.payload.token,
        dashboardId: action.payload.id,
      };
    case GET_REPORT_TOKEN_SUCCESS_FAILURE:
      return {
        ...state,
        reportTokenLoading: false,
        reportTokenPageError: action.payload,
      };
    case GET_REPORT_TOKEN_FAILURE:
      return {
        ...state,
        reportTokenLoading: false,
        reportTokenPageError: action.payload,
      };
    case SET_SESSION_TIMEOUT_POPUP:
      return {
        ...state,
        showExpiredSessionPopup: true,
      };
    default:
      return state;
  }
};
