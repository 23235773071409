import DatePicker from "react-date-picker";
import { images } from "../../imagesComponent/Images";
import moment from "moment";
import { setErrorrsForValidations } from "../../../tellurideExtRedux";
import {
  onKeyPressAction,
  onKeyPressActionNonDecimal,
} from "../../utilsComponent/CommonUtil";
import { DURATION_LIMIT } from "./PointsValidityConstants";
import {
  DARK,
  DROPDOWN_BGCOLOR_DARK_THEME,
  DROPDOWN_FOCUSED_BGCOLOR_DARK_THEME,
  DROPDOWN_FOCUSED_BGCOLOR_LIGHT_THEME,
  DROPDOWN_SELECTED_BGCOLOR_DARK_THEME,
  DROPDOWN_SELECTED_BGCOLOR_LIGHT_THEME,
  LIGHT,
} from "../../constantComponent/Constants";

export const getCustomStyles = (theme) => {
  return {
    control: (provided, state) => ({
      border: "none",
      borderRadius: "4px",
      display: "flex",
      padding: "0px",
      outline: "none",
      height: "34px",
      position: "relative",
      left: "-8px",
      margin: 0,
      // Add more custom styles as needed
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor:
        state.isSelected && theme === LIGHT
          ? DROPDOWN_SELECTED_BGCOLOR_LIGHT_THEME
          : state.isSelected && theme === DARK
          ? DROPDOWN_SELECTED_BGCOLOR_DARK_THEME
          : // ? DROPDOWN_FOCUSED_BGCOLOR_LIGHT_THEME
          state.isFocused && theme === DARK
          ? DROPDOWN_FOCUSED_BGCOLOR_DARK_THEME
          : state.isFocused && theme === LIGHT
          ? DROPDOWN_FOCUSED_BGCOLOR_LIGHT_THEME
          : theme === DARK
          ? DROPDOWN_BGCOLOR_DARK_THEME
          : "#fff",
      color: state.isSelected
        ? "#fff"
        : state.isFocused && theme === DARK
        ? "#fff"
        : state.isFocused && theme === LIGHT
        ? DROPDOWN_SELECTED_BGCOLOR_LIGHT_THEME
        : theme === LIGHT
        ? DROPDOWN_SELECTED_BGCOLOR_LIGHT_THEME
        : "#fff",
      padding: 8,
    }),
    // Add more style properties as needed
  };
};
export const getInputValidity = (
  inputType,
  value,
  setValue,
  setEditProcess,
  valueToCompareWith,
  sectionFlag,
  pointExpireUnit,
  pointAwardOn,
  pointExpireOn,
  offerDetailsData
) => {
  switch (inputType) {
    case "textBox":
      return (
        <>
          <div className="input-with-error">
            <input
              autoFocus
              className={`text-input-number ${
                value === "" ? "empty-error" : null
              }`}
              type="text"
              onChange={(e) => {
                setValue(e.target.value);
              }}
              defaultValue={value}
              onBlur={(e) => {
                if (value) {
                  setValue(e.target.value);
                  if (
                    !offerDetailsData.offerValidationErrors.createOffer
                      .pointValidity
                  ) {
                    handleEditBlur(setEditProcess);
                  }
                }
              }}
              onKeyPress={(e) => {
                onKeyPressActionNonDecimal(e);
              }}
            ></input>
            {pointAwardOn === pointExpireOn && pointExpireUnit === "Day(s)" && (
              <>
                {" "}
                <small
                  className={`${
                    sectionFlag === "pointsExpire" ? "error-expire" : "error-on"
                  }`}
                >
                  {" "}
                  {valueToCompareWith &&
                  sectionFlag === "pointsExpire" &&
                  parseInt(value) < parseInt(valueToCompareWith)
                    ? "Enter value greater than or equal to" +
                      valueToCompareWith
                    : valueToCompareWith &&
                      sectionFlag === "pointsOn" &&
                      parseInt(value) > parseInt(valueToCompareWith)
                    ? "Enter value less than or equal to " + valueToCompareWith
                    : ""}
                </small>
              </>
            )}
            {((sectionFlag === "pointsExpire" &&
              parseInt(value) > DURATION_LIMIT[pointExpireUnit]) ||
              (sectionFlag === "pointsOn" &&
                parseInt(value) > DURATION_LIMIT["Day(s)"])) && (
              <>
                <small
                  className={`${
                    sectionFlag === "pointsExpire" ? "error-expire" : "error-on"
                  }`}
                >
                  Enter value less than or equal to{" "}
                  {sectionFlag === "pointsExpire"
                    ? DURATION_LIMIT[pointExpireUnit]
                    : DURATION_LIMIT["Day(s)"]}
                </small>
              </>
            )}
          </div>
        </>
      );

    default:
      break;
  }
};
export const generateInitialOfferAttr = () => {
  const offerAttrInital = {
    offerAttribute: [
      {
        name: "OFFER_DAILY_IND",
        values: {
          value: ["N"],
        },
      },
      {
        name: "MULTI_TRAN_IND",
        values: {
          value: ["N"],
        },
      },
      {
        name: "ROLLING_PERIOD",
        values: {
          value: ["N"],
        },
      },
      {
        name: "POINTS_AWARD_ON",
        values: {
          value: ["Transaction Date plus"],
        },
      },
      {
        name: "POINTS_AWARD_DATE_PLUS",
        values: {
          value: ["0"],
        },
      },
      {
        name: "POINTS_EXPIRE_ON",
        values: {
          value: ["Transaction Date plus"],
        },
      },
      {
        name: "POINTS_EXPIRE_PLUS",
        values: {
          value: ["0"],
        },
      },
    ],
  };

  return offerAttrInital;
};
export const getInputsForPointsOn = (
  offerAttributeData,
  setOfferAttributeData,
  pointAwardOn,
  pointExpireOn,
  value,
  setValue,
  editProcess,
  setEditProcess,
  offerDetailsPageData,
  valueToCompareWith,
  sectionFlag,
  pointExpireUnit,
  offerDetailsData,
  isSetDisable
) => {
  function setPointsOnDateValue(value) {
    handleEditBlur(setEditProcess);
    setValue(formatPointsDate(value));
  }
  if (
    pointAwardOn === "Transaction Date plus" ||
    pointAwardOn === "Transaction Post Date plus"
  ) {
    return (
      <>
        {editProcess.status === true &&
        isSetDisable === false &&
        editProcess.name === "award-plus-input" ? (
          <span>
            {getInputValidity(
              "textBox",
              value,
              setValue,
              setEditProcess,
              valueToCompareWith,
              sectionFlag,
              pointExpireUnit,
              pointAwardOn,
              pointExpireOn,
              offerDetailsData
            )}
          </span>
        ) : (
          <span
            data-name="award-plus-input"
            onClick={(e) => {
              handleEditClick(e, setEditProcess);
            }}
            className="editable"
          >
            {value === "" ? "0" : value}
          </span>
        )}
      </>
    );
  } else if (pointAwardOn === "Date") {
    return (
      <>
        {(editProcess.status === true &&
          editProcess.name === "award-on-date" &&
          isSetDisable === false) ||
        value === "" ? (
          <fieldset>
            <DatePicker
              calendarIcon={
                <img src={images.calendarIcon} className="calendar-icon" />
              }
              clearIcon={null}
              format="y-MM-dd"
              minDate={moment(
                offerDetailsPageData?.offerStartDate,
                "YYYY-MM-DD"
              ).toDate()}
              onChange={(value) => {
                setPointsOnDateValue(
                  new Date(value).toLocaleDateString("en-US")
                );
              }}
              value={value ? moment(value).toLocaleString("en-US") : ""}
              //  disabled={isSetDisable}
              className={"top-4 top"}
              onCalendarClose={() => {
                handleEditBlur(setEditProcess);
              }}
              isOpen
            />
          </fieldset>
        ) : (
          <span
            data-name="award-on-date"
            onClick={(e) => {
              handleEditClick(e, setEditProcess);
            }}
            className="editable"
          >
            {value}
          </span>
        )}
      </>
    );
  }
};
export const getInputsForPointsExpire = (
  offerAttributeData,
  setOfferAttributeData,
  pointExpireOn,
  pointAwardOn,
  value,
  setValue,
  editProcess,
  setEditProcess,
  offerDetailsPageData,
  valueToCompareWith,
  sectionFlag,
  pointExpireUnit,
  offerDetailsData,
  isSetDisable
) => {
  function setPointsExpireDateValue(value) {
    handleEditBlur(setEditProcess);
    setValue(formatPointsDate(value));
  }
  if (
    pointExpireOn === "Transaction Date plus" ||
    pointExpireOn === "Transaction Post Date plus"
  ) {
    return (
      <>
        {editProcess.status === true &&
        editProcess.name === "award-expire-input" &&
        isSetDisable === false ? (
          <span>
            {getInputValidity(
              "textBox",
              value,
              setValue,
              setEditProcess,
              valueToCompareWith,
              sectionFlag,
              pointExpireUnit,
              pointAwardOn,
              pointExpireOn,
              offerDetailsData
            )}
          </span>
        ) : (
          <span
            data-name="award-expire-input"
            onClick={(e) => {
              handleEditClick(e, setEditProcess);
            }}
            className="editable"
          >
            {value === "" ? "0" : value}
          </span>
        )}
      </>
    );
  } else if (pointExpireOn === "Date") {
    return (
      <>
        {(editProcess.status === true &&
          editProcess.name === "point-expire-date" &&
          isSetDisable === false) ||
        value === "" ? (
          <fieldset>
            <DatePicker
              calendarIcon={
                <img src={images.calendarIcon} className="calendar-icon" />
              }
              clearIcon={null}
              format="y-MM-dd"
              minDate={moment(
                offerDetailsPageData?.offerEndDate,
                "YYYY-MM-DD"
              ).toDate()}
              onChange={(value) => {
                setPointsExpireDateValue(
                  new Date(value).toLocaleDateString("en-US")
                );
              }}
              value={value ? moment(value).toLocaleString("en-US") : ""}
              //  disabled={isSetDisable}
              onCalendarClose={() => {
                handleEditBlur(setEditProcess);
              }}
              className={"top-4 top"}
              isOpen
            />
          </fieldset>
        ) : (
          <span
            data-name="point-expire-date"
            onClick={(e) => {
              handleEditClick(e, setEditProcess);
            }}
            className="editable"
          >
            {value}
          </span>
        )}
      </>
    );
  }
};

export const handleEditClick = (e, setEditProcess) => {
  setEditProcess({
    status: true,
    name: e.target.dataset.name,
  });
};

export const handleEditBlur = (setEditProcess) => {
  setEditProcess({
    status: false,
    name: null,
  });
};

export const modifyInitialData = (
  offerAttributeData,
  setOfferAttribute,
  attributeModified
) => {
  const attributeNameToAdd = attributeModified.attributeNameToAdd;
  const attributeNameToRemove = attributeModified.attributeNameToRemove;
  const offerAttribute = offerAttributeData.offerAttribute;
  const attributeNameToAddIsPresent = offerAttribute.some(
    (attribute) => attribute.name === attributeNameToAdd
  );
  const attributeNameToRemoveIsPresent = offerAttribute.some(
    (attribute) => attribute.name === attributeNameToRemove
  );
  const newAttribute = {
    name: attributeNameToAdd,
    values: {
      value: [""],
    },
  };
  const filteredAttributes = offerAttribute.filter(
    (attribute) => attribute.name !== attributeNameToRemove
  );
  if (!attributeNameToAddIsPresent && attributeNameToAdd != "") {
    const updatedAttributes = attributeNameToRemoveIsPresent
      ? [...filteredAttributes, newAttribute]
      : [...offerAttribute, newAttribute];
    setOfferAttribute(() => {
      return {
        offerAttribute: updatedAttributes,
      };
    });
  } else if (attributeNameToRemoveIsPresent) {
    setOfferAttribute(() => {
      return {
        offerAttribute: filteredAttributes,
      };
    });
  }
};

export const formatPointsDate = (dat) => {
  let yourdate = dat.split("/").reverse();
  if (yourdate[2] < 10) {
    yourdate[2] = "0" + yourdate[2];
  }
  if (yourdate[1] < 10) {
    yourdate[1] = "0" + yourdate[1];
  }
  let tmp = yourdate[2];
  yourdate[2] = yourdate[1];
  yourdate[1] = tmp;
  yourdate = yourdate.join("-");
  return yourdate;
};
