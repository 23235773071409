export const COLUMNS = [
  {
    title: "Content ID",
    key: "id",
    type: "string",
    visible: true,
    filter: true,
    sort: true,
    minWidth: 150,
    initialWidth: 150,
    defaultWidth: 150,
    resizable: true,
    reqBodyKey: { attrName: "id", isAllowed: true },
  },
  {
    title: "Content Name",
    key: "name",
    type: "string",
    visible: true,
    filter: true,
    sort: true,
    minWidth: 140,
    initialWidth: 140,
    defaultWidth: 140,
    resizable: true,
    reqBodyKey: { attrName: "name", isAllowed: true },
  },
  {
    title: "Description",
    key: "description",
    type: "string",
    visible: true,
    filter: true,
    sort: true,
    advanceFilter: true,
    minWidth: 180,
    initialWidth: 180,
    defaultWidth: 180,
    resizable: true,
    reqBodyKey: { attrName: "description", isAllowed: true },
  },
  {
    title: "Type",
    key: "type",
    type: "select",
    visible: true,
    filter: true,
    sort: false,
    advanceFilter: false,
    options: ["Message only", "Custom"],
    minWidth: 85,
    initialWidth: 85,
    defaultWidth: 85,
    resizable: true,
    reqBodyKey: { attrName: "type", isAllowed: true },
    rights: [
      {
        key: "Message only",
        rights: ["filter", "campaigns", "content", "type", "messageonly"],
      },
      {
        key: "Custom",
        rights: ["filter", "campaigns", "content", "type", "custom"],
      },
      {
        key: "Points Offer",
        rights: ["filter", "campaigns", "content", "type", "point"],
      },
      {
        key: "Coupon Offer",
        rights: ["filter", "campaigns", "content", "type", "coupon"],
      },
      {
        key: "Price Discount Offer",
        rights: ["filter", "campaigns", "content", "type", "pricediscount"],
      },
    ],
  },
  {
    title: "Status",
    key: "status",
    type: "select",
    visible: true,
    filter: true,
    sort: true,
    advanceFilter: true,
    multi: true,
    options: ["ACTIVE", "INACTIVE", "DRAFT", "EXPIRED"],
    minWidth: 100,
    initialWidth: 100,
    defaultWidth: 100,
    resizable: false,
    reqBodyKey: { attrName: "status", isAllowed: true },
  },
  {
    title: "Start Date",
    key: "startDate",
    type: "date",
    visible: true,
    filter: true,
    sort: true,
    advanceFilter: true,
    minWidth: 100,
    initialWidth: 100,
    defaultWidth: 100,
    resizable: true,
    reqBodyKey: { attrName: "offerStartDate", isAllowed: true },
  },
  {
    title: "End Date",
    key: "endDate",
    type: "date",
    visible: true,
    filter: true,
    sort: true,
    advanceFilter: true,
    minWidth: 100,
    initialWidth: 100,
    defaultWidth: 100,
    resizable: true,
    reqBodyKey: { attrName: "offerEndDate", isAllowed: true },
  },
  {
    title: "Last Modified",
    key: "lastEditedDate",
    type: "date",
    visible: true,
    filter: true,
    sort: true,
    advanceFilter: true,
    minWidth: 130,
    initialWidth: 130,
    defaultWidth: 130,
    resizable: true,
    reqBodyKey: { attrName: "lastEditedDate", isAllowed: true },
  },
  {
    title: "Created By",
    key: "createdBy",
    type: "string",
    visible: true,
    filter: true,
    sort: true,
    advanceFilter: true,
    minWidth: 150,
    initialWidth: 150,
    defaultWidth: 150,
    resizable: true,
    reqBodyKey: { attrName: "createdBy", isAllowed: true },
  },
];
export const VIEW_CONTENT_PLACEHOLDER =
  "Search by Content ID or Content Name...";
export const VIEW_CONTENT = "VIEW_CONTENT";
