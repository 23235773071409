import { DISABLED } from "../../tellurideExtComponents/constantComponent/Constants";
import {
  GET_SEGMENT_DETAILS_REQUEST,
  GET_SEGMENT_DETAILS_SUCCESS,
  GET_SEGMENT_DETAILS_FAILURE,
  GET_SEGMENT_DETAILS_SUCCESS_FAILURE,
  GET_SEGMENT_LIST_REQUEST,
  GET_SEGMENT_LIST_SUCCESS,
  GET_SEGMENT_LIST_SUCCESS_FAILURE,
  GET_SEGMENT_LIST_FAILURE,
  SEARCH_SEGMENT_CONTENT_REQUEST,
  SEARCH_SEGMENT_CONTENT_SUCCESS,
  SEARCH_SEGMENT_CONTENT_FAILURE,
  SEARCH_SEGMENT_CONTENT_SUCCESS_FAILURE,
  CREATE_UPDATE_SEGMENT_REQUEST,
  CREATE_UPDATE_SEGMENT_SUCCESS,
  CREATE_UPDATE_SEGMENT_SUCCESS_FAILURE,
  CREATE_UPDATE_SEGMENT_FAILURE,
  RESET_VALIDATION_ERRORS,
  UPDATE_VALIDATION_ERRORS,
  SET_SEGMENT_CHANGE_STATUS,
  SET_ORGANZTATION_NAME,
  SET_UPLOAD_IMAGE,
  SET_BLANK_SESSION_ID_ERROR,
  SET_SESSION_TIMEOUT_POPUP,
  SET_AUTH_ORGANZTATION_NAME,
  UPLOAD_FILE_REQUEST,
  UPLOAD_FILE_SUCCESS,
  UPLOAD_FILE_FAILURE,
  UPLOAD_FILE_SUCCESS_FAILURE,
  SET_SEGMENT_DETAILS,
  SET_UPLOAD_FILE,
  SET_SEGMENT_MODE,
  SET_SEGMENT_EDIT_STATUS,
  RESET_CREATE_UPDATE_SEGMENT,
} from "./SegmentDetailsType";

const initialState = {
  segmentDetailsResponse: "",
  updateSegmentDetailsResponse:"",
  segmentDetailsLoading: true,
  searchSegmentContentResponse: "",
  searchSegmentContentLoading: false,
  searchSegmentContentError: "",
  isNewSegmentFlow:"",
  createUpdateSegmentResponseStatus: "",
  createUpdateSegmentLoading: false,
  createUpdateSegmentResponse: "",
  createUpdateSegmentError: "",
  apiError: "",
  apiSuccessError: "",
  getSegmentListResponse: "",
  getSegmentListLoading: false,
  imageUploadStatusLoading: "",
  imageUploadStatusResponse: "",
  uploadFileResponse: "",
  uploadFileLoading: false,
  uploadFileError: "",
  deleteImageContentLoading: false,
  deleteImageResponse: "",
  loadImageContentLoading: false,
  loadImageContentResponse: "",
  getSegmentListError: "",
  organizationName: "",
  offerNumber: "",
  responseLoaded: 1,
  imageUploadJson: "",
  blankSessionIdError: false,
  showExpiredSessionPopup: false,
  authOrganizationName: "",
  isFileUploaded: false,
  segmentMode:DISABLED,
  segmentEdited:false
};

export const SegmentDetailsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_SEGMENT_DETAILS_REQUEST:
      return {
        ...state,
        segmentDetailsResponse: "",
        segmentDetailsLoading: true,
        responseLoaded: 2,
        isSegmentExist: false,
      };
    case GET_SEGMENT_DETAILS_SUCCESS:
      return {
        ...state,
        segmentDetailsResponse: action.payload,
        updateSegmentDetailsResponse: action.payload,
        segmentDetailsLoading: false,
        responseLoaded: 3,
        isSegmentExist: false,
        createUpdateSegmentResponse: "",
        createUpdateSegmentResponseStatus: "",
      };
    case GET_SEGMENT_DETAILS_FAILURE:
      return {
        ...state,
        segmentDetailsLoading: false,
        segmentDetailsError: action.payload,
        apiError: action.payload,
        createUpdateSegmentResponse: "",
        createUpdateSegmentResponseStatus: "",
        responseLoaded: 1,
      };
    case GET_SEGMENT_DETAILS_SUCCESS_FAILURE:
      return {
        ...state,
        segmentDetailsLoading: false,
        apiSuccessError: action.payload,
        segmentDetailsError: action.payload,
        createUpdateSegmentResponse: "",
        createUpdateSegmentResponseStatus: "",
        responseLoaded: 1,
      };
    case CREATE_UPDATE_SEGMENT_REQUEST:
      return {
        ...state,
        createUpdateSegmentError: "",
        createUpdateSegmentLoading: true,
        segmentDetailsLoading: true,
        createUpdateSegmentResponseStatus: "",
      };
    case CREATE_UPDATE_SEGMENT_SUCCESS:
      return {
        ...state,
        createUpdateSegmentResponse: action.payload,
        createUpdateSegmentLoading: false,
        segmentDetailsLoading: false,
        createUpdateSegmentResponseStatus: action.payload.statusText,
        groupId: action.payload.id,
        segmentDetailsResponse: "",
        createUpdateSegmentError: "",
        updateSegmentDetailsResponse:"",
      };
    case CREATE_UPDATE_SEGMENT_SUCCESS_FAILURE:
      return {
        ...state,
        createUpdateSegmentError: action.payload,
        createUpdateSegmentLoading: false,
        segmentDetailsLoading: false,
        apiSuccessError: action.payload,
      };
    case CREATE_UPDATE_SEGMENT_FAILURE:
      return {
        ...state,
        segmentDetailsLoading: false,
        createUpdateSegmentLoading: false,
        createUpdateSegmentError: action.payload,
        apiError: action.payload,
      };
    case RESET_CREATE_UPDATE_SEGMENT:
      return {
        ...state,
        createUpdateSegmentResponse: "",
        createUpdateSegmentLoading: false,
        createUpdateSegmentError: "",
        createUpdateSegmentResponseStatus: "",
        segmentDetailsResponse: "",
        updateSegmentDetailsResponse: "",
      };
    case UPDATE_VALIDATION_ERRORS:
      return {
        ...state,
        offerDataValidationErrors: [action.payload],
      };
    case RESET_VALIDATION_ERRORS:
      return {
        ...state,
        offerDataValidationErrors: [],
      };
    case GET_SEGMENT_LIST_REQUEST:
      return {
        ...state,
        getSegmentListLoading: true,
        getSegmentListError: null,
        responseLoaded: 1,
        //getSegmentListResponse: "",
      };
    case GET_SEGMENT_LIST_SUCCESS:
      return {
        ...state,
        getSegmentListResponse: action.payload,
        getSegmentListLoading: false,
        getSegmentListError: null,
        apiSuccessError: "",
        //offerStatus: action.payload.offerInfoResponse[0].offerStatus,
      };
    case GET_SEGMENT_LIST_SUCCESS_FAILURE:
      return {
        ...state,
        //updateOfferStatusResponse: action.payload,
        getSegmentListLoading: false,
        //offerStatus: action.payload.status,
        apiSuccessError: action.payload,
        getSegmentListError: action.payload,
      };
    case GET_SEGMENT_LIST_FAILURE:
      return {
        ...state,
        getSegmentListLoading: false,
        getSegmentListError: action.payload,
        apiError: action.payload,
      };
    case SEARCH_SEGMENT_CONTENT_REQUEST:
      return {
        ...state,
        searchSegmentContentResponse: "",
        searchSegmentContentLoading: true,
        // responseLoaded: 2,
      };
    case SEARCH_SEGMENT_CONTENT_SUCCESS:
      return {
        ...state,
        searchSegmentContentResponse: action.payload,
        searchSegmentContentLoading: false,
      };
    case SEARCH_SEGMENT_CONTENT_FAILURE:
      return {
        ...state,
        searchSegmentContentLoading: false,
        searchSegmentContentError: action.payload,
        apiError: action.payload,
      };
    case SEARCH_SEGMENT_CONTENT_SUCCESS_FAILURE:
      return {
        ...state,
        searchSegmentContentLoading: false,
        apiSuccessError: action.payload,
        searchSegmentContentError: action.payload,
      };
    case UPLOAD_FILE_REQUEST:
      return {
        ...state,
        uploadFileResponse: "",
        uploadFileLoading: true,
      };
    case UPLOAD_FILE_SUCCESS:
      return {
        ...state,
        uploadFileResponse: action.payload,
        uploadFileLoading: false,
      };
    case UPLOAD_FILE_FAILURE:
      return {
        ...state,
        uploadFileLoading: false,
        uploadFileError: action.payload,
        apiError: action.payload,
      };
    case UPLOAD_FILE_SUCCESS_FAILURE:
      return {
        ...state,
        uploadFileLoading: false,
        apiSuccessError: action.payload,
        uploadFileError: action.payload,
        responseLoaded: 1,
      };
    case SET_SEGMENT_MODE:
      return {
        ...state,
        segmentMode: action.payload,
      };
    case SET_SEGMENT_DETAILS:
      return {
        ...state,
        updateSegmentDetailsResponse: action.payload
      }
    case SET_UPLOAD_FILE:
      return {
        ...state,
        isFileUploaded: action.payload
      }
    case SET_SEGMENT_EDIT_STATUS:
      return {
        ...state,
        segmentEdited: action.payload
      }
    default:
      return state;
  }
};
