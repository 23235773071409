import React, { useEffect, useState } from "react";
import EmailContentSelectionPopup from "../../campaignBuilderComponent/campaignPopups/EmailContentSelectionPopup/EmailContentSelectionPopup";
import "./ABTestingSetup.style.scss";
import { TiDeleteOutline } from "react-icons/ti";
import {
  MODIFY,
  email_variation_json,
} from "../../campaignBuilderComponent/CampaignBuilderConstants";
import { getCustomStyles } from "../../campaignDetailsComponent/CampaignDetailsUtil";
import {
  END_DATE_LABEL,
  START_DATE_LABEL,
} from "../../campaignDetailsComponent/CampaignDetailsConstants";
import { images } from "../../../imagesComponent/Images";
import moment from "moment";
import DatePicker from "react-date-picker";
import Select from "react-select";
import {
  generateUniqueId,
  onKeyPressAction,
} from "../../../utilsComponent/CommonUtil";
import {
  getTestBasedArrayForEmail,
  getVariationJson,
} from "../../campaignBuilderComponent/campaignPopups/EmailContentSelectionPopup/EmailContentSelectionUtil";
import { NumericFormat } from "react-number-format";

const ABTestingSetup = ({
  variations,
  setVariations,
  isDisabled,
  journeyType,
}) => {
  const [editProcess, setEditProcess] = useState({
    status: false,
    name: null,
  });
  const [availablePercentage, setAvailablePercentage] = useState(100);
  useEffect(() => {
    if (journeyType === "CMP-EVENT") {
      let eachPercentValue = parseInt(100 / variations.length);
      variations.map((variation) => {
        variation.percentage = eachPercentValue;
      });
    }
  }, [variations]);
  function createNewVariation(id) {
    let newVariation = getTestBasedArrayForEmail("subject_line", 1)[0];
    newVariation.percentage = 0;
    setVariations((prev) => [...prev, newVariation]);
  }
  const handleEditBlur = () => {
    setEditProcess({
      status: false,
      name: null,
    });
    calculatePercentage();
  };
  const handleEditClick = (e) => {
    setEditProcess({
      status: true,
      name: e.target.dataset.name,
    });
  };
  function calculatePercentage() {
    let totalPercentage = variations.reduce(
      (acc, val) => acc + val.percentage,
      0
    );
    if (totalPercentage >= 100) {
      setAvailablePercentage(0);
    } else {
      setAvailablePercentage(100 - totalPercentage);
    }
  }
  useEffect(() => {
    calculatePercentage();
  }, [variations]);

  return (
    <div className="ab-testing-setup">
      {/* <span className="ab-testing-toggle">
        A/B Testing
        <label className="switch">
          <input
            type="checkbox"
            onChange={() => {
              setComponentName({
                name: EmailContentSelectionPopup,
              });
            }}
            checked={true}
            // disabled={isSetDisable}
          />
          <span className="slider round"></span>
        </label>
      </span> */}
      <div className="mt-16 ab-testing-scheduler">
        {/* <div className="date-time-wrapper">
          <span>
            <div className="date-wrapper-inside">
              <fieldset id="start-date">
                <legend>{START_DATE_LABEL}</legend>
                <DatePicker
                  calendarIcon={
                    <img src={images.calendarIcon} className="calendar-icon" />
                  }
                  format="y-MM-dd"
                  clearIcon={null}
                  value={moment("2023-12-05").toLocaleString("en-US")}
                  //   minDate={moment(
                  //     campaignData?.createdDate,
                  //     "YYYY-MM-DD"
                  //   ).toDate()}
                  //   onChange={(value) => {
                  //     setCampaignDetailsData((prev) => {
                  //       return {
                  //         ...prev,
                  //         startDate: new Date(value).toLocaleDateString("en-US"),
                  //       };
                  //     });
                  //   }}
                  //   disabled={isSetDisable}
                />
              </fieldset>
            </div>
          </span>

          <div className="date-wrapper-inside">
            <fieldset id="start-date">
              <legend>{"Start Time"}</legend>
              <input type="text" value={"08:10 AM"}></input>
            </fieldset>
          </div>
        </div>

        <div className="date-time-wrapper">
          <span>
            <div className="date-wrapper-inside">
              <fieldset id="start-date">
                <legend>{END_DATE_LABEL}</legend>
                <DatePicker
                  calendarIcon={
                    <img src={images.calendarIcon} className="calendar-icon" />
                  }
                  format="y-MM-dd"
                  clearIcon={null}
                  value={moment("2023-12-05").toLocaleString("en-US")}
                  //   minDate={moment(
                  //     campaignData?.createdDate,
                  //     "YYYY-MM-DD"
                  //   ).toDate()}
                  //   onChange={(value) => {
                  //     setCampaignDetailsData((prev) => {
                  //       return {
                  //         ...prev,
                  //         startDate: new Date(value).toLocaleDateString("en-US"),
                  //       };
                  //     });
                  //   }}
                  //   disabled={isSetDisable}
                />
              </fieldset>
            </div>
          </span>
          <div className="date-wrapper-inside">
            <fieldset id="start-date">
              <legend>{"End Time"}</legend>
              <input type="text" value={"08:10 AM"}></input>
            </fieldset>
          </div>
        </div> */}
      </div>

      {/* <p style={{ marginTop: "16px" }}>
          Use{" "}
          <span style={{ borderBottom: "1px solid", fontWeight: "600" }}>
            Best Open Rate
          </span>{" "}
          to decide the effective strategy
        </p> */}
      <div className="variation-wrapper">
        {Object.entries(variations).map(([key, variation], index) => {
          return (
            <div>
              {!variation.hasOwnProperty("winner") && (
                <div className="variation-container">
                  {/* {Object.keys(variation).map((key) => { */}
                  {/* return ( */}
                  <>
                    <div className="variation-header">
                      {Object.keys(variation).map((key) => {
                        return (
                          <>
                            {key === "variationId" && (
                              <span className="variationTitle">
                                Variation {index + 1}
                              </span>
                            )}
                          </>
                        );
                      })}

                      <span
                        className={
                          isDisabled || journeyType === "CMP-EVENT"
                            ? "variation-actions disabled"
                            : "variation-actions"
                        }
                      >
                        {editProcess.name === "percentage" + index && (
                          <span className="info-variation">
                            {`Total available percentage : ${availablePercentage}
                                            `}
                          </span>
                        )}
                        {/* ------------------------------percentage---------------------------- */}
                        {Object.keys(variation).map((key) => {
                          return (
                            <>
                              {key === "percentage" && (
                                <>
                                  {editProcess.name === "percentage" + index ? (
                                    <>
                                      <NumericFormat
                                        className={"text-input-number"}
                                        autoFocus="true"
                                        value={variation[key]}
                                        thousandsGroupStyle="lakh"
                                        thousandSeparator=","
                                        displayType="input"
                                        decimalScale={0}
                                        allowNegative={false}
                                        disabled={journeyType === "CMP-EVENT"}
                                        renderText={(memberDurationValue) => (
                                          <b>{memberDurationValue}</b>
                                        )}
                                        onValueChange={(values, sourceInfo) => {
                                          if (
                                            values.floatValue &&
                                            values.floatValue.toString()
                                              .length <= 2 &&
                                            values.floatValue +
                                              // calculate the sum of all variations except this
                                              variations.reduce((acc, val) => {
                                                if (
                                                  val.variationId !==
                                                  variation.variationId
                                                ) {
                                                  return acc + val.percentage;
                                                }
                                                return acc;
                                              }, 0) <=
                                              100
                                          ) {
                                            const updatedVariations =
                                              variations.map((v) => {
                                                if (
                                                  v.variationId ===
                                                  variation.variationId
                                                ) {
                                                  return {
                                                    ...v,
                                                    percentage:
                                                      values.floatValue || 0,
                                                  };
                                                }
                                                return v;
                                              });
                                            setVariations(updatedVariations);
                                          } else {
                                            const updatedVariations =
                                              variations.map((v) => {
                                                if (
                                                  v.variationId ===
                                                  variation.variationId
                                                ) {
                                                  return {
                                                    ...v,
                                                    percentage: 0,
                                                  };
                                                }
                                                return v;
                                              });
                                            setVariations(updatedVariations);
                                          }
                                        }}
                                        onBlur={(e) => {
                                          handleEditBlur();
                                        }}
                                        onKeyPress={(e) => {
                                          onKeyPressAction(e);
                                        }}
                                      />
                                      {/* <span>
                                  <input
                                    type="text"
                                    onChange={(e) => {
                                      const updatedVariations = variations.map(
                                        (v) => {
                                          if (
                                            v.variationId ===
                                            variation.variationId
                                          ) {
                                            return {
                                              ...v,
                                              percentage: e.target.value,
                                            };
                                          }
                                          return v;
                                        }
                                      );

                                      setVariations(updatedVariations);
                                    }}
                                  />
                                </span> */}
                                    </>
                                  ) : (
                                    <span
                                      data-name={"percentage" + index}
                                      onClick={(e) => {
                                        if (!isDisabled) {
                                          handleEditClick(e, setEditProcess);
                                        }
                                      }}
                                    >
                                      {variation[key] + "%"}
                                    </span>
                                  )}
                                  {variations.length > 2 && (
                                    <TiDeleteOutline
                                      size={20}
                                      color="#e13838"
                                      style={{ marginLeft: "5px" }}
                                      onClick={() => {
                                        if (variations.length > 2) {
                                          setVariations(
                                            variations.filter(
                                              (val) =>
                                                !(
                                                  val.variationId ===
                                                  variation.variationId
                                                )
                                            )
                                          );
                                        }
                                      }}
                                    />
                                  )}
                                </>
                              )}
                            </>
                          );
                        })}
                      </span>
                    </div>
                    {/* ------------------------------Subject Line---------------------------- */}
                    {Object.keys(variation).map((key) => {
                      return (
                        <>
                          {key === "subject" && (
                            <div className="textbox-wrapper">
                              <fieldset
                                className="text-box"
                                disabled={isDisabled}
                              >
                                <legend>{"Subject Line"}</legend>
                                <textarea
                                  className="input-text-textarea"
                                  rows="3"
                                  cols="50"
                                  maxLength="250"
                                  name="campaignDescription"
                                  value={variation[key]}
                                  onChange={(e) => {
                                    const updatedVariations = variations.map(
                                      (v) => {
                                        if (
                                          v.variationId ===
                                          variation.variationId
                                        ) {
                                          return {
                                            ...v,
                                            [key]: e.target.value,
                                          };
                                        }
                                        return v;
                                      }
                                    );

                                    setVariations(updatedVariations);
                                  }}
                                />
                              </fieldset>
                            </div>
                          )}
                        </>
                      );
                    })}
                  </>
                  {/* );
                })} */}
                </div>
              )}
            </div>
          );
        })}
      </div>
      {!isDisabled && (
        <div
          className="add-variation-btn"
          onClick={() => createNewVariation(variations.length)}
        >
          <span style={{ fontSize: "30px" }}>+</span>{" "}
          <span style={{ fontSize: "16px", borderBottom: "1px solid" }}>
            Add Variation
          </span>
        </div>
      )}
    </div>
  );
};

export default ABTestingSetup;
