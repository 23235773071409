/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-no-comment-textnodes */
import { React, useState } from "react";
import "./OfferSelectionPopupComponent.style.scss";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import Skeleton from "react-loading-skeleton";
import {
	CONTINUE_TO_SETUP,
	DARK,
	ENABLED,
	LOYALTY_CASH,
	OFRI,
	RequestAccess,
	headers,
	uploadFileHeader,
} from "../../../../constantComponent/Constants";

import {
	createUpdateOffer,
	createUpdateSegment,
	getOfferList,
	resetCreateUpdateSegmentResponse,
} from "../../../../../tellurideExtRedux";
import {
	generateUniqueIdByLength,
	getRightsFromSession,
	skey,
	trimExtraSpaces,
} from "../../../../utilsComponent/CommonUtil";
import { emptyData } from "../../../../constantComponent/DesignConstant";
import { updateNodeData } from "../../../../utilsComponent/GenericFlow/GenericFlowUtil";
import { PROCEED } from "../../../../campaignSetupComponent/campaignBuilderComponent/CampaignBuilderConstants";
import { displayName } from "react-quill";
import { OFFER_TYPE_ALIAS } from "../../../createOfferComponent/CreateOfferConstants";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import OfferSetupPopUpComponent from "../offerSetupPopupComponent/OfferSetupPopUpComponent";
import {
	OFFER_SETUP_CREATE_OFFER_PROCESSING_LOADER_TITLE,
	RESPONSE_STATUS_SUCCESS,
} from "../../../offerSetupConstant";
import {
	miniloading,
	miniloadingWithoutMessage,
} from "../../../../LoaderComponents/loadingDesignConstant";
import OfferReviewPopUpComponent from "../offerReviewPopupComponent/OfferReviewPopUpComponent";
import {
	offer_selection_columns,
	popupConfig,
} from "../CustomOfferSetupPopupConstants";
import SmallGenericTable from "../../../../utilsComponent/SmallGenericTable/SmallGenericTable";
import { set } from "lodash";
const OfferSelectionPopupComponent = ({
	theme,
	setPopupShowProcess,
	currentClickedNode,
	setNodeData,
	isDisabled,
	...rest
}) => {
	let { offerType, suffix } = rest;
	if (offerType === undefined) {
		offerType = currentClickedNode?.data?.value?.action?.offerType;
	}
	const offerData = useSelector((state) => state.offerDetailsData);
	const securityData = useSelector((state) => state.securityData);
	const journeyDetailsData = useSelector((state) => state.journeyDetailsData);
	const segmentData = useSelector((state) => state.segmentDetailsData);

	const customOfferName = journeyDetailsData?.customOfferName;

	const [searchInput, setSearchInput] = useState("");
	const [triggerList, setTriggerList] = useState(null);
	const [selectedOffer, setSelectedOffer] = useState({
		id: currentClickedNode?.data?.value?.action?.id,
		name: currentClickedNode?.data?.value?.action?.name,
	});
	const [segmentName, setSegmentName] = useState(
		customOfferName + "_" + generateUniqueIdByLength(3)
	);
	const [searchBody, setSearchBody] = useState("");
	const [offerSelectionValue, setOfferSelectionValue] = useState({});
	const dispatch = useDispatch();
	let navigate = useNavigate();

	const { user } = useAuth0();

	let tableLoading = offerData.getOfferListLoading;
	let tableResponse = offerData.getOfferListResponse;
	// useEffect(() => {
	//   const controller = new AbortController();
	//   let apiurl =
	//     process.env.REACT_APP_URL_DOMAIN + process.env.REACT_APP_GET_OFFER_LIST;

	//   headers.sessionId = skey();
	//   headers.requestAccess = getRightsFromSession();
	//   headers.organization = offerData?.authOrganizationName;
	//   const requestHeaders = {
	//     headers,
	//   };

	//   let getGeneralListRequestBody = {
	//     requestorID: OFRI,
	//     messageVersion: process.env.REACT_APP_MESSAGE_VERSION_01,
	//     offerSearch: {
	//       id: "",
	//       name: "",
	//       description: "",
	//       type: offerType,
	//       status: "",
	//       offerStartDate: "",
	//       offerEndDate: "",
	//       lastEditedDate: "",
	//       createdBy: "",
	//       searchQuery: searchInput,
	//     },
	//     pagination: {
	//       recordPerPage: 5,
	//       currentPage: 1,
	//     },
	//     sort: [
	//       {
	//         name: "lastEditedDate",
	//         sortPriority: "1",
	//         sortType: "desc",
	//       },
	//     ],
	//   };
	//   dispatch(
	//     getOfferList(
	//       requestHeaders,
	//       getGeneralListRequestBody,
	//       apiurl,
	//       controller
	//     )
	//   );
	// }, [searchBody]);
	const createSegmentHandler = async () => {
		uploadFileHeader.sessionId = skey();
		uploadFileHeader.organization = offerData?.authOrganizationName;
		uploadFileHeader.requestAccess = getRightsFromSession();
		const headers = uploadFileHeader;
		headers.sessionId = skey();
		headers.organization = offerData?.authOrganizationName;
		headers.requestAccess = RequestAccess;
		const requestHeaders = {
			headers,
		};
		let requestBody = new FormData();
		requestBody.append("file", "");
		requestBody.append("requestorID", OFRI);
		requestBody.append(
			"messageVersion",
			process.env.REACT_APP_MESSAGE_VERSION_01
		);
		// requestBody.append("name", "TEST");
		requestBody.append("name", segmentName);
		requestBody.append("description", "");
		requestBody.append("type", "Member");
		requestBody.append("action", "");
		requestBody.append("userId", user ? user.email : "");
		await dispatch(
			createUpdateSegment(requestHeaders, requestBody, false, ENABLED)
		);
	};
	useEffect(() => {
		if (segmentData?.createUpdateSegmentLoading === false) {
			if (segmentData?.createUpdateSegmentResponseStatus === "Success") {
				createSelectedOffer();
			} else if (
				segmentData?.createUpdateSegmentError === "Group Name already exist"
			) {
				setSegmentName(customOfferName + "_" + generateUniqueIdByLength(3));
				createSegmentHandler();
			}
		}
	}, [
		segmentData?.createUpdateSegmentLoading,
		segmentData.createUpdateSegmentError,
		segmentData?.createUpdateSegmentResponseStatus,
	]);
	function createSelectedOffer() {
		headers.sessionId = skey();
		headers.requestAccess = getRightsFromSession();
		headers.organization = offerData?.authOrganizationName;
		// headers.organization = "ffb"; //later change it
		const requestHeaders = {
			headers,
		};
		const newCustomOfferName = trimExtraSpaces(
			customOfferName
				? customOfferName + suffix
				: journeyDetailsData?.journeyDetailsResponse?.journeyData?.journeyName +
						suffix
		);
		const requestBody = {
			requestorID: OFRI,
			messageVersion: process.env.REACT_APP_MESSAGE_VERSION_05,
			offerData: {
				offerType: offerType,
				offerSubType: "",
				offerPointsDollarName: newCustomOfferName,
				modifiedBy: user?.email,
			},
			...(offerType === LOYALTY_CASH && {
				rules: {
					rule: [
						{
							entity: "AdvanceRule",
							condition: {
								PASSES_ALL: [
									{
										entity: "member",
										attr: "memberGroup",
										operator: "IN",
										value: [
											{
												code: segmentName,
												name: segmentName,
											},
										],
										type: "List",
									},
								],
							},
						},
					],
				},
			}),
		};
		dispatch(
			createUpdateOffer(
				requestHeaders,
				requestBody,
				false,
				false,
				navigate,
				true
			)
		);
	}
	useEffect(() => {
		if (
			offerData?.createUpdateOfferLoading === false &&
			offerData?.createUpdateOfferResponse?.status ===
				RESPONSE_STATUS_SUCCESS &&
			offerData?.offerNumber
		) {
			dispatch(resetCreateUpdateSegmentResponse());
			rest.setComponentName({
				//   name: OfferSetupPopUpComponent,
				name: () => (
					<OfferSetupPopUpComponent
						theme={theme}
						currentClickedNode={currentClickedNode}
						setNodeData={setNodeData}
						setPopupShowProcess={setPopupShowProcess}
						offerType={offerType}
						isDisabled={isDisabled}
						setComponentName={rest.setComponentName}
					/>
				),
				displayName: "offerSetup",
				style: {
					width: "44rem",
				},
			});
		}
	}, [dispatch, offerData]);
	useEffect(() => {
		const offerNodeData = {
			displayData: {
				id: selectedOffer?.id,
				offerType: offerType,
			},
			value: {
				action: {
					id: selectedOffer?.id,
					name: selectedOffer?.name,
					offerType: offerType,
				},
			},
		};
		setOfferSelectionValue(offerNodeData);
	}, [selectedOffer]);

	function handleSearchChange(e) {
		setSearchInput(e.target.value);
	}
	function handleRowClickOfferSelection(row) {
		setSelectedOffer((prev) => {
			return {
				...prev,
				id: row.id,
				name: row.name,
			};
		});
	}
	return (
		<section>
			<>
				{/* <div className="header">
						<h4>{SEGMENT_USE_TITLE}</h4>
						<div className="segemnt-close-icon">
							<FaRegTimesCircle
								onClick={() => {
									setPopupShowProcess(false);
									setNodeData(null);
								}}
								size={25}
								className="campaign-modal-close-icon"
							/>
						</div>
        </div>
         */}
				<div
					className="search-bar-container-campaign"
					style={{
						display: "flex",
						alignItems: "center",
					}}
				>
					<form
						onSubmit={(e) => {
							e.preventDefault();
							setSearchBody(searchInput);
							setTriggerList((prev) => !Boolean(prev));
						}}
						style={{
							display: "flex",
							justifyContent: "center",
						}}
					>
						<input
							type="text"
							placeholder={`Search ${offerType} offer`}
							className="attribute-search"
							value={searchInput}
							onChange={handleSearchChange}
							style={{
								width: "31rem",
							}}
						/>
					</form>
				</div>

				<div className="campaign-type-section trigger-campaign">
					<SmallGenericTable
						Columns={offer_selection_columns}
						tableData={tableResponse?.offerList}
						isDataLoading={tableLoading}
						selectedData={[selectedOffer.id]}
						isRowSelectable={true}
						isRowMultiSelectable={false}
						rowClickHandler={handleRowClickOfferSelection}
						config={{
							...popupConfig,
							theme: theme,
							selectedDataKey: "id",
							isDisabled: isDisabled,
							border: true,
						}}
						isShowPagination={true}
						initialPagination={{
							recordPerPage: 10,
							currentPage: 1,
						}}
						API_URL={process.env.REACT_APP_GET_OFFER_LIST}
						RESPONSE_BODY_KEY={"offerList"}
						smallGenericTableRquestBody={{
							requestorID: OFRI,
							messageVersion: process.env.REACT_APP_MESSAGE_VERSION_01,
							offerSearch: {
								id: "",
								name: "",
								description: "",
								type: offerType,
								status: "ACTIVE",
								offerStartDate: "",
								offerEndDate: "",
								lastEditedDate: "",
								createdBy: "",
								searchQuery: searchInput,
							},
							sort: [],
						}}
						triggerList={triggerList}
					/>
					<div className="mt-12 not-seeing-msg-container">
						<p style={{ display: "inline" }}>
							Not seeing the offer you are looking for?
						</p>{" "}
						{offerData.createUpdateOfferLoading ||
						segmentData.createUpdateSegmentLoading ? (
							miniloadingWithoutMessage()
						) : (
							<a
								onClick={() => {
									if (offerType === LOYALTY_CASH) {
										createSegmentHandler();
									} else {
										createSelectedOffer();
									}
								}}
								className="message-link-create-Offer"
							>
								{`Create ${offerType} Offer`}
							</a>
						)}
					</div>
				</div>
				<footer className="campaign-modal-footer">
					{/* <button
              className="btn btn-secondary"
              onClick={() => {
                setPopupShowProcess(false);
                setNodeData(null);
              }}
            >
              {CANCEL}
            </button> */}
					<button
						className="btn btn-primary continue-setup-btn"
						disabled={selectedOffer.id ? false : true}
						onClick={() => {
							// if (selectedOffer.id) {
							// 	updateNodeData(
							// 		currentClickedNode,
							// 		setNodeData,
							// 		"offer",
							// 		setPopupShowProcess,
							// 		offerSelectionValue
							// 	);
							// }
							rest.setComponentName({
								name: () => (
									<OfferReviewPopUpComponent
										theme={theme}
										currentClickedNode={currentClickedNode}
										setNodeData={setNodeData}
										setPopupShowProcess={setPopupShowProcess}
										offerType={offerType}
										setComponentName={rest.setComponentName}
										offerNumber={selectedOffer?.id}
										isDisabled={isDisabled}
										SourceComponent={OfferSelectionPopupComponent}
									/>
								),
								displayName: "offerReview",
							});
						}}
					>
						{PROCEED}
					</button>
				</footer>
			</>
		</section>
	);
};

export default OfferSelectionPopupComponent;
