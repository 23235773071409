import { React, useState } from "react";
import "./CreateOfferComponent.style.scss";
import { FaRegTimesCircle } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { DETAILS, CUSTOM, OFFER } from "../../constantComponent/PathConstants";
import {
  headers,
  MISSION,
  OFRI,
  POINTS_EARN,
  EVENTPROCESSING,
  EVENT_OFFER
} from "../../constantComponent/Constants";
import { useDispatch, useSelector } from "react-redux";
import { createUpdateOffer } from "../../../tellurideExtRedux/offerDetails/OfferDetailsAction";
import { useEffect } from "react";
import { miniloading } from "../../LoaderComponents/loadingDesignConstant";
import {
  CREATE_OFFER_MODAL_TITLE,
  CREATE_OFFER_TITLE,
  OFFER_NAME_LABEL,
  OFFER_TYPES,
  OFFER_TYPE_ALIAS,
} from "./CreateOfferConstants";
import { useAuth0 } from "@auth0/auth0-react";
import { motion } from "framer-motion/dist/framer-motion";
import {
  checkRightsIsEditable,
  checkRightsIsVisible,
  getRightsFromSession,
  getRightsKey,
  skey,
  trimExtraSpaces,
} from "../../utilsComponent/CommonUtil";
import {
  OFFER_SETUP_CREATE_OFFER_CONTINUE_SETUP,
  OFFER_SETUP_CREATE_OFFER_PROCESSING_LOADER_TITLE,
  RESPONSE_STATUS_SUCCESS,
} from "../offerSetupConstant";
import { magicIcon } from "../../utilsComponent/SidebarIcons";
import GenerateOfferComponent from "./GenerateOfferComponent";
import { saveJourney } from "../../../tellurideExtRedux/journey/JourneyAction";
const CreateOfferComponent = ({ setSelectOffer, setShowOfferPopup }) => {
  const dispatch = useDispatch();
  const offerData = useSelector((state) => state.offerDetailsData);
  const journeyDetailsData = useSelector((state) => state.journeyDetailsData);
  const [offerType, setOfferType] = useState(OFFER_TYPES.find((offer)=>checkRightsIsVisible(getRightsKey(offer?.rights))) ? OFFER_TYPES.find((offer)=>checkRightsIsVisible(getRightsKey(offer?.rights))).key : "");
  const [offerName, setOfferName] = useState("");
  let navigate = useNavigate();
  const { user } = useAuth0();
  const handleChangeOfferName = (e) => {
    setOfferName(e.target.value);
  };
  const [showComponent, setShowComponent] = useState("create_offer");
  const createHandler = () => {
    headers.sessionId = skey();
    headers.requestAccess = getRightsFromSession();
    headers.organization = offerData?.authOrganizationName;
    const requestHeaders = {
      headers,
    };
    if (offerType === "customOffer") {
      const requestBody = {
        requestorID: OFRI,
        messageVersion: process.env.REACT_APP_MESSAGE_VERSION_05,
        journeyAttributes: {
          journeyAttribute: [
            {
              name: "DISPLAY_OFFER_TYPE",
              values: {
                value: ["Custom Offer"],
              },
            },
          ],
        },
        journeyData: {
          journeyType: "CMP-EVENT",
          journeySubType: "CMP-EVENT",
          organization: offerData?.authOrganizationName,
          journeyName: trimExtraSpaces(offerName),
          modifiedBy: user?.email,
        },
      };
      dispatch(
        saveJourney(requestHeaders, requestBody, false, false, navigate, window.location.pathname.includes(DETAILS) ? true : false)
      );
    } else {

      const requestBody = {
        requestorID: OFRI,
        messageVersion: process.env.REACT_APP_MESSAGE_VERSION_05,
        offerData: {
          offerType: OFFER_TYPE_ALIAS[offerType], // change to offerType in future
          offerSubType: OFFER_TYPE_ALIAS[offerType] === EVENT_OFFER ? "Rewards" : "",
          offerPointsDollarName: trimExtraSpaces(offerName),
          modifiedBy: user?.email,
        },
      };
      if (offerType === MISSION) {
        requestBody.offerAttributes = {
          offerAttribute: [
            { name: "DISPLAY_OFFER_TYPE", values: { value: ["MISSION"] } },
            {
              name: "MULTI_TRAN_IND",
              values: {
                value: ["Y"],
              },
            },
            {
              name: "ROLLING_PERIOD",
              values: {
                value: ["Y"],
              },
            }
          ],
        };
      }
      dispatch(
        createUpdateOffer(
          requestHeaders,
          requestBody,
          false,
          false,
          navigate,
          window.location.pathname.includes(DETAILS) ? true : false
        )
      );
    }
  };

  useEffect(() => {
    if (
      offerData?.createUpdateOfferLoading === false &&
      offerData?.createUpdateOfferResponse?.status ===
        RESPONSE_STATUS_SUCCESS &&
      offerData?.offerNumber
    ) {
      navigate({
        pathname:
          "/" + window.location.pathname.split("/")[1] + OFFER + DETAILS,
        search: `?offerid=${offerData.offerNumber}`,
      });

      setSelectOffer(true);
      setShowOfferPopup(false);
    }
  }, [dispatch, offerData]);

  useEffect(() => {
    if (
      journeyDetailsData?.saveJourneyLoading === false &&
      journeyDetailsData?.saveJourneyResponse &&
      journeyDetailsData?.saveJourneyResponse?.status ===
        RESPONSE_STATUS_SUCCESS &&
      journeyDetailsData?.saveJourneyResponse?.journeyNumber
    ) {
      navigate({
        pathname:
          "/" +
          window.location.pathname.split("/")[1] +
          OFFER +
          CUSTOM +
          DETAILS,
        search: `?offerid=${journeyDetailsData?.journeyNumber}`,
      });

      setSelectOffer(true);
      setShowOfferPopup(false);
    }
  }, [dispatch, journeyDetailsData]);

  function handleOfferSelect(offer) {
    setOfferType(offer.key);
  }

  return (
    <section className="offer-modal-backdrop">
      <motion.div
        className="inner-modal"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        {showComponent === "create_offer" && (
          <>
            <div className="header">
              <h1>{CREATE_OFFER_MODAL_TITLE}</h1>
              <FaRegTimesCircle
                onClick={() => {
                  setShowOfferPopup(false);
                }}
                size={25}
                className="offer-modal-close-icon"
              />
            </div>
            <fieldset>
              <legend>{OFFER_NAME_LABEL}</legend>
              <input
                type="text"
                value={offerName}
                onChange={handleChangeOfferName}
                disabled={offerData.createUpdateOfferLoading}
                autoFocus
                maxLength="50"
              />
            </fieldset>

            <p className="create-offer-title">{CREATE_OFFER_TITLE}</p>
            <div className="offer-type-section">
              {OFFER_TYPES.map((offer, index) => {
                return (
                  <>
                    {checkRightsIsVisible(getRightsKey(offer?.rights)) && (
                      <div
                        key={index}
                        className={`offer-type ${
                          offerType === offer.key && "selected"
                        } ${
                          (offer.disabled === true && "disabled") ||
                          (!checkRightsIsEditable(
                            getRightsKey(offer?.rights)
                          ) &&
                            "disabled")
                        }`}
                        onClick={() => {
                          if (
                            !offer.disabled ||
                            !checkRightsIsEditable(getRightsKey(offer?.rights))
                          ) {
                            handleOfferSelect(offer);
                          }
                        }}
                      >
                        <input
                          type="radio"
                          className={`offertype-radio-input ${
                            offer.key === offerType && "selected"
                          } ${
                            (offer.disabled === true && "disabled") ||
                            (!checkRightsIsEditable(
                              getRightsKey(offer?.rightKey)
                            ) &&
                              "disabled")
                          }`}
                          checked={offer.key === offerType ? true : false}
                          disabled={
                            !checkRightsIsEditable(
                              getRightsKey(offer?.rights)
                            ) || offer.disabled === true
                              ? true
                              : false
                          }
                        />
                        <span className="create-offer-icon">
                          {offer?.icon()}
                        </span>
                        <p className="offer-title">{offer.title}</p>
                        <p className="offer-header">{offer.header}</p>
                        <p className="offer-body">{offer.description}</p>
                        {offer.status && (
                          <p className="offer-status">{offer.status}</p>
                        )}
                      </div>
                    )}
                  </>
                );
              })}
            </div>
            <footer className="offer-modal-footer">
              <button
                className="btn btn-secondary"
                onClick={() => {
                  setShowOfferPopup(false);
                }}
                disabled={offerData.createUpdateOfferLoading}
              >
                Cancel
              </button>
              {showComponent === "generate_offer" && (
                <button
                  className="btn btn-secondary"
                  onClick={() => {
                    setShowComponent("create_offer");
                  }}
                  disabled={offerData.createUpdateOfferLoading}
                >
                  back
                </button>
              )}
              {process.env.REACT_APP_ENVIRONMENT==="qa" && checkRightsIsVisible(
                getRightsKey([
                  "button",
                  "popup",
                  "loyalty",
                  "createoffer",
                  "generate",
                ])
              ) ? (
                <button
                  id="generateCreateOfferId"
                  className="btn btn-primary continue-setup-btn"
                  disabled={
                    showComponent === "generate_offer" ||
                    !checkRightsIsEditable(
                      getRightsKey([
                        "button",
                        "popup",
                        "loyalty",
                        "createoffer",
                        "generate",
                      ])
                    )
                  }
                  onClick={() => {
                    setShowComponent("generate_offer");
                  }}
                >
                  <span
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                    className="mr-8"
                  >
                    {magicIcon()}
                  </span>
                  Generate
                </button>
              ) : null} 
              {checkRightsIsVisible(
                getRightsKey([
                  "button",
                  "popup",
                  "loyalty",
                  "createoffer",
                  "continuetosetup",
                ])
              ) ? (
                <button
                  className="btn btn-primary continue-setup-btn"
                  disabled={
                    offerName.trim() === "" ||
                    offerData.createUpdateOfferLoading ||
                    offerType === "" ||
                    !checkRightsIsEditable(
                      getRightsKey([
                        "button",
                        "popup",
                        "loyalty",
                        "createoffer",
                        "continuetosetup",
                      ])
                    )
                  }
                  onClick={() => {
                    createHandler();
                  }}
                >
                  {offerData.createUpdateOfferLoading ||
                  journeyDetailsData?.saveJourneyLoading
                    ? miniloading(
                        OFFER_SETUP_CREATE_OFFER_PROCESSING_LOADER_TITLE
                      )
                    : OFFER_SETUP_CREATE_OFFER_CONTINUE_SETUP}
                </button>
              ) : null}
            </footer>
          </>
        )}
        {showComponent === "generate_offer" && (
          <GenerateOfferComponent
            setShowOfferPopup={setShowOfferPopup}
            showComponent={showComponent}
            setShowComponent={setShowComponent}
          />
        )}
      </motion.div>
    </section>
  );
};

export default CreateOfferComponent;
