import {
  ERR_NETWORK,
  TELL400,
  TELL401,
  TELL403,
  TELL98,
  TELL99,
} from "../tellurideExtComponents/errorsComponent/ErrorConstant";
import { setErrorToaster } from "./toaster/ToasterAction";
import cookie from "react-cookies";

export const ERROR_MESSAGE = "something went wrong please try again later";
export const ERR_CANCELED = "ERR_CANCELED";

export const logoutAuth0 = (logout, orgName) => {
  sessionStorage.removeItem("skey");
  sessionStorage.removeItem("accessToken");
  sessionStorage.removeItem("organization_id");
  sessionStorage.removeItem("rights");
  localStorage.removeItem("AllRights");
  cookie.remove(orgName, { path: "/" });
  let returnUrl = window.location.origin + "/auth/logout?orgName=" + orgName;
  logout({
    logoutParams: { returnTo: returnUrl },
  });
};

export function setBlankSession(
  err,
  dispatch,
  requestHeaders,
  setBlankSessionIdError
) {
  if (err.code === ERR_NETWORK && !requestHeaders?.headers?.sessionId) {
    dispatch(setBlankSessionIdError());
  }
}

export function setSessionExpire(resp, setSessionExpirePopUp, dispatch) {
  if (
    [TELL401, TELL400, TELL403, TELL98, TELL99].includes(
      resp?.response?.data?.errors[0]?.code
    )
  ) {
    dispatch(setSessionExpirePopUp());
  }
}
export function setToaster(resp, errMsg, dispatch) {
  if (
    ![TELL401, TELL400, TELL403, TELL98, TELL99].includes(
      resp?.response?.data?.errors[0]?.code
    )
  ) {
    dispatch(setErrorToaster(errMsg));
  }
}

export function previewImageError(
  resp,
  setSessionExpirePopUp,
  errMsg,
  dispatch
) {
  if (resp.response.data) {
    const json = JSON.parse(new TextDecoder().decode(resp.response.data));
    if (json) {
      if (
        ![TELL401, TELL400, TELL403, TELL98, TELL99].includes(
          json?.errors[0]?.code
        )
      ) {
        // dispatch(setErrorToaster(errMsg));
        dispatch(setSessionExpirePopUp());
      }
    }
  }
}
