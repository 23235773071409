import axios from "axios";
import {
    GET_CHATBOT_ANSWER_REQUEST,
    GET_CHATBOT_ANSWER_SUCCESS,
    GET_CHATBOT_ANSWER_FAILURE,
    GET_CHATBOT_ANSWER_SUCCESS_FAILURE,
    SET_BLANK_SESSION_ID_ERROR,
    SET_SESSION_TIMEOUT_POPUP,  
} from "./ChatbotType";
import {
    ERROR_MESSAGE,
    logoutAuth0,
    setBlankSession,
    setSessionExpire,
    setToaster,
  } from "../reduxUtil";

  export const getChatbotAnswer = (
    requestHeaders,
    requestBody
  ) => {
    return async (dispatch) => {
        dispatch(getChatbotAnswerRequest());
      try{  
        const resp = await axios.post(
            process.env.REACT_APP_URL_DOMAIN +
            process.env.REACT_APP_GET_CHATBOT_ANSWER,
            requestBody,
            requestHeaders
        );
        if (resp.data.errors !== undefined && resp.data.errors.length !== 0) {
          dispatch(getChatbotAnswerSuccessFailure(resp.data));
          setSessionExpire(resp, setSessionExpirePopUp, dispatch);          
        }else{
          dispatch(getChatbotAnswerSuccess(resp.data));
        }
      }catch (err) {
        dispatch(getChatbotAnswerFailure(err.message));
        setSessionExpire(err, setSessionExpirePopUp, dispatch);
        setBlankSession(err, dispatch, requestHeaders, setBlankSessionIdError);
      }
    }  
  };

export const getChatbotAnswerRequest = () => {
	return {
		type: GET_CHATBOT_ANSWER_REQUEST,
	};
};
export const getChatbotAnswerSuccess = (apiData) => {
	return {
		type: GET_CHATBOT_ANSWER_SUCCESS,
		payload: apiData,
	};
};
export const getChatbotAnswerFailure = (error) => {
	return {
		type: GET_CHATBOT_ANSWER_FAILURE,
		payload: error,
	};
};
export const getChatbotAnswerSuccessFailure = (error) => {
	return {
		type: GET_CHATBOT_ANSWER_SUCCESS_FAILURE,
		payload: error,
	};
}; 
export const setBlankSessionIdError = () => {
	return {
		type: SET_BLANK_SESSION_ID_ERROR,
	};
};
export const setSessionExpirePopUp = () => {
	return {
		type: SET_SESSION_TIMEOUT_POPUP,
	};
};