import React, { useEffect, useState } from "react";
import "./CustomOfferHeader.style.scss";
import { useDispatch, useSelector } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import {
  changeDateDashFormat,
  checkRightsIsEditable,
  checkRightsIsVisible,
  getAllRights,
  getRightsFromSession,
  getRightsKey,
  skey,
  trimExtraSpaces,
} from "../../utilsComponent/CommonUtil";

import {
  ACTIVATE,
  CANCEL,
  DEACTIVATE,
  EDIT,
  OFFER_DETAILS_STATUS_ACTIVATED,
  OFFER_DETAILS_STATUS_DEACTIVATED,
  OFFER_DETAILS_STATUS_DEACTIVATED_EXPIRED_MESSAGE,
  OFFER_DETAILS_STATUS_DRAFT,
  OFRI,
  SAVE,
  headers,
  offer_status,
} from "../../constantComponent/Constants";
import { images } from "../../imagesComponent/Images";
import moment from "moment";
import { convertUTCDateToLocalDate } from "../offerDetailsComponent/OfferDetailsUtil";
import { miniloadingWithoutMessage } from "../../LoaderComponents/loadingDesignConstant";
import DeactivateConfirmationComponent from "../../modelComponent/deactivateConfirmationComponent/DeactivateConfirmationComponent";
import { AnimatePresence, motion } from "framer-motion/dist/framer-motion";
import { getDisplayName } from "../../utilsComponent/GenericFlow/GenericFlowUtil";
import { displayNameAlias } from "../customOfferSetupComponent/customOfferBuilder/CustomOfferBuilderConstants";
import {
  saveJourney,
  setCustomOfferNameHandler,
  updateJourneyStatus,
} from "../../../tellurideExtRedux/journey/JourneyAction";
import { OFFER, VIEW } from "../../constantComponent/PathConstants";
import { VIEW_OFFER } from "../viewOfferComponent/TableViewConstants";
import ConfirmationPopUpComponent from "../../modelComponent/ConfirmationPopUpComponent/ConfirmationPopUpComponent";
import { updatejourneystatusRequestBody } from "../../campaignSetupComponent/campaignBuilderComponent/CampaignBuilderConstants";
import { setSourceCancelBtn } from "../../../tellurideExtRedux";
import { showSetupCreationConfirmationPopup } from "../../navigationBarComponent/internalNavigationBarComponent/NavigationBarUtil";

const CustomOfferHeader = ({
  isSetDisable,
  customOfferBuilderData,
  customOfferDetailsData,
}) => {
  const journeyDetailsData = useSelector((state) => state.journeyDetailsData);
  const journeyData = journeyDetailsData?.journeyDetailsResponse?.journeyData;
  const { user } = useAuth0();
  const securityData = useSelector((state) => state.securityData);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const offerDetailsPageData = useSelector((state) => state.offerDetailsData);
  const [journeyNameEditable, setJourneyNameEditable] = useState(false);
  const [clickedBtn, setClickedBtn] = useState("");
  const [updatedJourneyName, setUpdatedJourneyName] = useState(
    journeyData?.journeyName
  );
  const [disableSaveBtn, setDisableSaveBtn] = useState(false);
  const [disableActivateBtn, setDisableActivateBtn] = useState(false);

  const [showDeactivateConfirmationPopup, setShowDeactivateConfirmationPopup] =
    useState(false);
  const [showConfirmationPopup, setShowConfirmationPopup] = useState({
    key: "",
    visibility: false,
  });
  const handleEditClickJourneyName = () => {
    setJourneyNameEditable(true);
  };
  const handleJourneyNameChange = (e) => {
    if (e.target.value.length <= 46) {
      setUpdatedJourneyName(e.target.value);
    }
  };
  const saveJourneyName = () => {
    setJourneyNameEditable(!journeyNameEditable);
  };
  function hideErrorToaster(obj) {
    const backdrop = document.querySelector(".backdrop");
    backdrop.classList.remove("show");
    return;
  }
  useEffect(() => {
    if (customOfferBuilderData?.journeyFlow?.nodes.length > 0) {
      const hasActionNode = customOfferBuilderData?.journeyFlow?.nodes.some(
        (node) => node?.data?.name === "Action"
      );
      const hasNullValueNode = customOfferBuilderData?.journeyFlow?.nodes.some(
        (node) => node?.data?.value == null
      );

      if (!hasActionNode) {
        setDisableActivateBtn(true);
      } else {
        setDisableActivateBtn(false);
      }

      if (hasNullValueNode) {
        setDisableActivateBtn(true);
        setDisableSaveBtn(true);
      } else {
        setDisableSaveBtn(false);
      }
    }
  }, [customOfferBuilderData]);
  useEffect(() => {
    dispatch(setCustomOfferNameHandler(updatedJourneyName));
  }, [updatedJourneyName]);
  useEffect(() => {
    if (
      journeyDetailsData?.updateJourneyStatusError ||
      journeyDetailsData?.saveJourneyError
    ) {
      setClickedBtn("");
    }
  }, [journeyDetailsData]);
  const handleClickOnSave = async (status) => {
    setClickedBtn(SAVE);
    headers.sessionId = skey();
    headers.requestAccess = getRightsFromSession();
    headers.organization = offerDetailsPageData?.authOrganizationName;
    const requestHeaders = {
      headers,
    };
    const requestBody = {
      requestorID: "OFRI",
      messageVersion: process.env.REACT_APP_MESSAGE_VERSION_05,
      journeyData: {
        ...journeyData,
        journeyType: "CMP-EVENT",
        journeySubType: "CMP-EVENT",
        journeyDescription: customOfferDetailsData?.description,
        journeyEndDate: changeDateDashFormat(customOfferDetailsData?.endDate),
        journeyStartDate: changeDateDashFormat(
          customOfferDetailsData?.startDate
        ),
        journeyName: trimExtraSpaces(updatedJourneyName),
      },
      journeyFlow: {
        metaData: {
          version: "1",
        },
        nodes: customOfferBuilderData?.journeyFlow?.nodes.map((node) => {
          return {
            id: node.id,
            data: {
              // displayData: node.data.displayData,
              type: node.data.type,
              value: node.data.value,
              name: node?.data?.name
                ? node?.data?.name
                : getDisplayName(node.data.type, displayNameAlias),
            },
          };
        }),
        edges: customOfferBuilderData?.journeyFlow?.edges.map((edge) => {
          return {
            id: edge.id,
            source: edge.source,
            target: edge.target,
            // type: edge.type, if required in future
          };
        }),
      },
    };
    await dispatch(
      saveJourney(requestHeaders, requestBody, true, false, navigate, true)
    );
    if (status) {
      updateStatusHandler(status);
    }
  };

  const updateJourneyStatusHandller = (statusValue) => {
    headers.sessionId = skey();
    headers.requestAccess = getRightsFromSession();
    headers.organization = offerDetailsPageData?.authOrganizationName;
    const requestHeaders = {
      headers,
    };
    const updateOfferStatusRequestBody = {
      requestorID: OFRI,
      messageVersion: process.env.REACT_APP_MESSAGE_VERSION_03,
      modifiedBy: user?.email,
      // modifiedTS: "2023-06-27 20:44:29",
      journeyNumber: customOfferDetailsData?.offerID,
      journeyStatus: statusValue,
    };

    dispatch(
      updateJourneyStatus(
        requestHeaders,
        updateOfferStatusRequestBody,
        journeyData,
        {
          navigate: navigate,
          successRedirect: "",
          errorRedirectionPath: OFFER + VIEW_OFFER,
        }
      )
    );
  };
  // const handleDeactivateButtonClick = (deactivateStatus) => {
  //   if (deactivateStatus) {
  //     updateJourneyStatusHandller(OFFER_DETAILS_STATUS_DEACTIVATED);
  //     setShowDeactivateConfirmationPopup(!deactivateStatus);
  //   } else {
  //     setShowDeactivateConfirmationPopup(deactivateStatus);
  //   }
  // };
  // const handleActivateButtonClick = (activateStatus) => {
  //   if (activateStatus) {
  //     updateJourneyStatusHandller(OFFER_DETAILS_STATUS_ACTIVATED);
  //     setShowActivateConfirmationPopup(!activateStatus);
  //   } else {
  //     setShowActivateConfirmationPopup(activateStatus);
  //   }
  // };
  // const handleSaveActivateButtonClick = (activateStatus) => {
  //   if (activateStatus) {
  //     handleClickOnSave(true);
  //     // updateJourneyStatusHandller(OFFER_DETAILS_STATUS_ACTIVATED);
  //     setShowActivateConfirmationPopup(!activateStatus);
  //   } else {
  //     setShowActivateConfirmationPopup(activateStatus);
  //   }
  // };

  const saveAndUpdateStatusHandler = (status) => {
    handleClickOnSave(status);
  };

  const updateStatusHandler = (status) => {
    headers.sessionId = skey();
    headers.requestAccess = getRightsFromSession();
    headers.organization = journeyData?.organization;
    const requestHeaders = {
      headers,
    };

    const requestBody = updatejourneystatusRequestBody(
      user,
      journeyData,
      status
    );

    dispatch(
      updateJourneyStatus(requestHeaders, requestBody, journeyData, {
        navigate: navigate,
        successRedirect: "",
        errorRedirectionPath: OFFER + VIEW_OFFER,
      })
    );
  };
  return (
    <>
      <div className="journey-header-wrapper">
        <div className="journey-navigation-top-div">
          <div className="empty-space-div"></div>
          <div className="navigation-text">
            {journeyNameEditable && isSetDisable !== true ? (
              <>
                <input
                  className="input-journey-name"
                  placeholder="Enter new journey name"
                  maxLength="46"
                  onChange={handleJourneyNameChange}
                  defaultValue={updatedJourneyName}
                  type="text"
                  autoFocus
                  onBlur={() => {
                    if (updatedJourneyName.trim() === "")
                      setUpdatedJourneyName(
                        trimExtraSpaces(journeyData?.journeyName)
                      );
                    saveJourneyName();
                  }}
                  autocomplete="off"
                  autocorrect="off"
                  autocapitalize="off"
                  spellcheck="false"
                />
              </>
            ) : (
              <p className="journey-name setup-name-header">
                {updatedJourneyName}
              </p>
            )}
            {!journeyNameEditable && isSetDisable !== true && (
              <>
                {checkRightsIsVisible(
                  getRightsKey(["button", "loyalty", "detail", "save"])
                ) ? (
                  <img
                    onClick={
                      checkRightsIsEditable(
                        getRightsKey(["button", "loyalty", "detail", "save"])
                      )
                        ? handleEditClickJourneyName
                        : ""
                    }
                    src={images.penIcon}
                    className="pen-icon"
                  />
                ) : null}
              </>
            )}
            {!journeyNameEditable && (
              <div
                className={`status-container ${
                  journeyData?.journeyStatus === OFFER_DETAILS_STATUS_ACTIVATED
                    ? moment(journeyData?.journeyEndDate).diff(
                        convertUTCDateToLocalDate(new Date()),
                        "days"
                      ) <= -1
                      ? "EXPIRED"
                      : journeyData?.journeyStatus
                    : journeyData?.journeyStatus
                }`}
              >
                {" "}
                <span className="journey-status-value">
                  {journeyData?.journeyStatus === OFFER_DETAILS_STATUS_ACTIVATED
                    ? moment(journeyData?.journeyEndDate).diff(
                        convertUTCDateToLocalDate(new Date()),
                        "days"
                      ) <= -1
                      ? OFFER_DETAILS_STATUS_DEACTIVATED_EXPIRED_MESSAGE
                      : offer_status[journeyData?.journeyStatus]
                    : offer_status[journeyData?.journeyStatus]}
                </span>
              </div>
            )}
          </div>
          {/* {offerDetailsPageData?.offerData?.offerType === "Points Earn" && (
          <div
            className={`stats-button-container ${
              statsPopoverVisible === true ? "active" : null
            }`}
            onClick={() => setStatsPopoverVisible(true)}
          >
            <TfiStatsUp />
          </div>
        )} */}

          <div className="navigation-buttons">
            {journeyData?.journeyStatus === OFFER_DETAILS_STATUS_DRAFT && (
              <>
                {checkRightsIsVisible(
                  getRightsKey(["button", "loyalty", "detail", "save"])
                ) ? (
                  <button
                    className="save-button btn btn-primary"
                    onClick={() => handleClickOnSave()}
                    disabled={
                      clickedBtn ||
                      journeyDetailsData?.saveJourneyLoading ||
                      disableSaveBtn === true ||
                      !checkRightsIsEditable(
                        getRightsKey(["button", "loyalty", "detail", "save"])
                      )
                    }
                  >
                    {journeyDetailsData?.saveJourneyLoading &&
                    clickedBtn === SAVE
                      ? miniloadingWithoutMessage()
                      : SAVE}
                  </button>
                ) : null}
              </>
            )}
            {journeyData?.journeyStatus !== OFFER_DETAILS_STATUS_DRAFT &&
              journeyData?.journeyStatus !== OFFER_DETAILS_STATUS_ACTIVATED && (
                <>
                  {checkRightsIsVisible(
                    getRightsKey(["button", "loyalty", "detail", "edit"])
                  ) ? (
                    <button
                      className="save-button btn btn-primary"
                      onClick={(e) => {
                        setClickedBtn(EDIT);
                        updateStatusHandler(OFFER_DETAILS_STATUS_DRAFT);
                      }}
                      disabled={
                        (clickedBtn === EDIT &&
                          journeyDetailsData?.updateJourneyStatusLoading) ||
                        !checkRightsIsEditable(
                          getRightsKey(["button", "loyalty", "detail", "edit"])
                        )
                      }
                    >
                      {journeyDetailsData?.updateJourneyStatusLoading &&
                      clickedBtn === EDIT
                        ? miniloadingWithoutMessage()
                        : EDIT}
                    </button>
                  ) : null}
                </>
              )}
            {/* ----------------------------ACTIVATE button--------------------------- */}
            {(journeyData?.journeyStatus === OFFER_DETAILS_STATUS_DRAFT ||
              journeyData?.journeyStatus ===
                OFFER_DETAILS_STATUS_DEACTIVATED) && (
              <>
                {checkRightsIsVisible(
                  getRightsKey(["button", "loyalty", "detail", "active"])
                ) ? (
                  <button
                    className="activate-button btn btn-primary"
                    onClick={(e) => {
                      setClickedBtn(ACTIVATE);
                      setShowConfirmationPopup({
                        key: "activate",
                        visibility: true,
                      });
                    }}
                    disabled={
                      clickedBtn ||
                      journeyDetailsData?.updateJourneyStatusLoading ||
                      disableActivateBtn ||
                      !checkRightsIsEditable(
                        getRightsKey(["button", "loyalty", "detail", "active"])
                      )
                    }
                  >
                    {journeyDetailsData?.updateJourneyStatusLoading &&
                    clickedBtn === ACTIVATE
                      ? miniloadingWithoutMessage()
                      : ACTIVATE}
                  </button>
                ) : null}
              </>
            )}
            {/* ---------------------------------DEACTIVATE BUTTON---------------------------- */}
            {journeyData?.journeyStatus === OFFER_DETAILS_STATUS_ACTIVATED && (
              <>
                {checkRightsIsVisible(
                  getRightsKey(["button", "loyalty", "detail", "deactive"])
                ) ? (
                  <button
                    className="activate-button btn btn-primary"
                    onClick={(e) => {
                      setClickedBtn(DEACTIVATE);
                      setShowConfirmationPopup({
                        key: "deactivate",
                        visibility: true,
                      });
                    }}
                    disabled={
                      (clickedBtn === DEACTIVATE &&
                        journeyDetailsData.updateJourneyStatusLoading) ||
                      !checkRightsIsEditable(
                        getRightsKey([
                          "button",
                          "loyalty",
                          "detail",
                          "deactive",
                        ])
                      )
                    }
                  >
                    {journeyDetailsData.updateJourneyStatusLoading &&
                    clickedBtn === DEACTIVATE
                      ? miniloadingWithoutMessage()
                      : DEACTIVATE}
                  </button>
                ) : null}
              </>
            )}
            <button
              className="create-btn btn btn-secondary"
              onClick={(e) => {
                if (
                  journeyDetailsData?.isOfferChanged &&
                  journeyData?.journeyStatus === OFFER_DETAILS_STATUS_DRAFT &&
                  checkRightsIsEditable(
                    getRightsKey([
                      "loyalty",
                      "detail",
                      "setup",
                      customOfferDetailsData?.journeyType?.split(" ").join(""),
                    ])
                  )
                ) {
                  dispatch(
                    setSourceCancelBtn({
                      state: true,
                      path: OFFER + VIEW,
                    })
                  );
                  showSetupCreationConfirmationPopup();
                } else {
                  navigate(
                    "/" + window.location.pathname.split("/")[1] + OFFER + VIEW
                  );
                }
              }}
            >
              {CANCEL}
            </button>
          </div>
        </div>
      </div>
      {/* --------------------------------Confirmation Popup--------------------------- */}
      <AnimatePresence>
        {showConfirmationPopup.visibility && (
          <ConfirmationPopUpComponent
            setClickedBtn={setClickedBtn}
            updateStatusHandler={updateStatusHandler}
            saveAndUpdateStatusHandler={saveAndUpdateStatusHandler}
            secondaryBtnText={
              journeyData?.journeyStatus === OFFER_DETAILS_STATUS_DRAFT &&
              journeyDetailsData.isOfferChanged
                ? "No"
                : "Cancel"
            }
            saveActivate={
              journeyData?.journeyStatus === OFFER_DETAILS_STATUS_DRAFT &&
              journeyDetailsData?.isOfferChanged
                ? true
                : false
            }
            isOfferChanged={
              journeyData?.journeyStatus === OFFER_DETAILS_STATUS_DRAFT
                ? journeyDetailsData.isOfferChanged
                : false
            }
            statusKey={showConfirmationPopup.key}
            setShowConfirmationPopup={setShowConfirmationPopup}
          />
        )}
      </AnimatePresence>
    </>
  );
};

export default CustomOfferHeader;
