import { Auth0Provider } from "@auth0/auth0-react";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  headers,
  orgId,
  validOrg,
} from "../tellurideExtComponents/constantComponent/Constants";
import { useDispatch, useSelector } from "react-redux";
import { fetchOrgId } from "../tellurideExtRedux/security/SecurityAction";
import Loading from "../tellurideExtComponents/LoaderComponents/Loading";
import cookie from "react-cookies";
import { GET_ORGANIZATION_LOADING_SOURCE } from "../tellurideExtComponents/LoaderComponents/LoadingConstant";

export const Auth0ProviderWithNavigate = ({ children }) => {
  const navigate = useNavigate();
  const queryString = require("query-string");
  const securityData = useSelector((state) => state.securityData);
  let urlElements = window.location.pathname.split("/");
  let urlElelement = urlElements[1];
  const dispatch = useDispatch();

  const organization_id = urlElelement ? urlElelement : "";
  const domain = process.env.REACT_APP_AUTH0_DOMAIN;
  const clientId = process.env.REACT_APP_AUTH0_CLIENTID;
  const redirectUri = `${window.location.origin}/login-callback`;
  const onRedirectCallback = (appState) => {
    navigate(appState?.returnTo || window.location.pathname);
  };
  useEffect(() => {


    if (organization_id && !cookie.load(organization_id) && validOrg.includes(organization_id) === false) {
      headers.organization = organization_id;
      delete headers.requestAccess;
      const requestHeaders = {
        headers,
      };

      dispatch(fetchOrgId({}, requestHeaders , organization_id));
    }
  }, []);
  function getOrganizationId() {}
  if (!(domain && clientId && redirectUri)) {
    return null;
  }
  let auth = {
    redirect_uri: redirectUri,
    //organization: orgId[organization_id],
  };
  if (urlElelement === "authorize") {
    const url_org_name = queryString.parse(
      window.location.search
    ).organization_name;
    const url_org_id = queryString.parse(window.location.search).organization;
    const invite = queryString.parse(window.location.search).invitation;
    auth.organization = url_org_id;
    auth.invitation = invite;
  }
  if (cookie.load(organization_id)) {
    auth.organization = cookie.load(organization_id);
  }
  // if (orgId[organization_id]) {
  //   auth.organization = orgId[organization_id];
  //   getOrganizationId(organization_id);
  // }
  return (
    <Loading source={GET_ORGANIZATION_LOADING_SOURCE}>
      <Auth0Provider
        domain={domain}
        clientId={clientId}
        authorizationParams={auth}
        onRedirectCallback={onRedirectCallback}
      >
        {children}
      </Auth0Provider>
    </Loading>
  );
};
