import { createChatBotMessage } from 'react-chatbot-kit';
const Config = {
  initialMessages: [createChatBotMessage(`Welcome ! How can I assist you today?`)],
  botName: "Telluride",
  /*customStyles: {
    botMessageBox: {
      backgroundColor: '#D7E6F9',
      innerWidth: "615px"
    },
    chatButton: {
      backgroundColor: '#214352',
    },
  } */ 
};


export default Config;
