import { useEffect, useState } from "react";
import { GrFormClose } from "react-icons/gr";
import { fetchOfferStatistics } from "../../../tellurideExtRedux";
import { DARK, OFRI } from "../../constantComponent/Constants";
import { useDispatch, useSelector } from "react-redux";
import { headers } from "../../constantComponent/Constants";
import { getRightsFromSession, skey } from "../../utilsComponent/CommonUtil";
import Skeleton from "react-loading-skeleton";
import { IoClose } from "react-icons/io5";
export function StatsPopover({ setStatsPopover }) {
  const dispatch = useDispatch();
  const offerDetailsPageData = useSelector((state) => state.offerDetailsData);
  const securityData = useSelector((state) => state.securityData);
  const offerAnalyticsPageData = useSelector(
    (state) => state.OfferAnalyticsData
  );
  const statisticsLoading = offerAnalyticsPageData.offerStatisticsLoading;
  const statisticsResponse = offerAnalyticsPageData.offerStatisticsResponse;

  useEffect(() => {
    const requestHeaders = {
      headers,
    };
    headers.sessionId = skey();
    headers.requestAccess = getRightsFromSession();
    headers.organization = offerDetailsPageData?.authOrganizationName;
    const requestBody = {
      requestorID: OFRI,
      messageVersion: process.env.REACT_APP_MESSAGE_VERSION_05,
      offerID: offerDetailsPageData.offerDetailsResponse.offerData.offerNumber,
      // organization: offerDetailsPageData?.authOrganizationName,
    };
    dispatch(
      fetchOfferStatistics(requestHeaders, requestBody, setStatsPopover)
    );
  }, []);
  const toasterData = useSelector((state) => state.toasterData);
  const [theme, setTheme] = useState(toasterData.isDarkTheme);
  useEffect(() => {
    setTheme(toasterData.isDarkTheme);
  }, [toasterData]);
  return (
    <div className="popover-container">
      <div
        className="popover-backdrop"
        onClick={() => {
          setStatsPopover(false);
        }}
      ></div>
      <div className="popover">
        {/* <GrFormClose
          className="close-icon"
          onClick={() => {
            setStatsPopover(false);
          }}
        /> */}
        <IoClose
          className="close-icon"
          onClick={() => {
            setStatsPopover(false);
          }}
        />
        <div className="popover-header">Content Stats</div>
        <>
          <div className="menu-item">
            <span className="menu-item-label">Points awarded</span> :
            <span className="menu-item-value points">
              {statisticsLoading === true ? (
                <Skeleton
                  count={1}
                  baseColor={theme === DARK ? "#202020" : ""}
                  highlightColor={theme === DARK ? "#444" : ""}
                />
              ) : (
                statisticsResponse?.offerStatistics?.pointsAmount
              )}
            </span>
          </div>
          <div className="menu-item">
            <span className="menu-item-label">Members benefitted</span> :
            <span className="menu-item-value members">
              {statisticsLoading === true ? (
                <Skeleton
                  count={1}
                  baseColor={theme === DARK ? "#202020" : ""}
                  highlightColor={theme === DARK ? "#444" : ""}
                />
              ) : (
                statisticsResponse?.offerStatistics?.membersBenefited
              )}
            </span>
          </div>
          <div className="menu-item">
            <span className="menu-item-label">Sales impacted</span> :
            <span className="menu-item-value sales">
              {statisticsLoading === true ? (
                <Skeleton
                  count={1}
                  baseColor={theme === DARK ? "#202020" : ""}
                  highlightColor={theme === DARK ? "#444" : ""}
                />
              ) : statisticsResponse?.offerStatistics?.salesImpacted ? (
                `$ ${statisticsResponse?.offerStatistics?.salesImpacted}`
              ) : (
                ""
              )}
            </span>
          </div>
          {statisticsResponse?.offerStatistics?.lastAwarded && (
            <div className="menu-item">
              <span className="menu-item-label">Last awarded</span> :
              <span className="menu-item-value last-awarded-date">
                {statisticsLoading === true ? (
                  <Skeleton
                    count={1}
                    baseColor={theme === DARK ? "#202020" : ""}
                    highlightColor={theme === DARK ? "#444" : ""}
                  />
                ) : (
                  statisticsResponse?.offerStatistics?.lastAwarded
                )}
              </span>
            </div>
          )}
        </>
      </div>
    </div>
  );
}
