import React, { Component } from "react";
import { FaRegTimesCircle } from "react-icons/fa";

const Error = ({ error }) => {
  return (
    <div className="error-box-outer">
      <div className="error-container">
        <div className="error-msg-container">
          <FaRegTimesCircle color="red" size="40" />
          <p>
            Something went wrong. Please try after sometime.
          </p>
        </div>
      </div>
    </div>
  );
};
export default Error;
