import axios from "axios";
import { setErrorToaster, setSuccessToaster } from "../toaster/ToasterAction";
import {
  ERROR_MESSAGE,
  setBlankSession,
  setSessionExpire,
  setToaster,
} from "../reduxUtil";
import {
  setBlankSessionIdError,
  setSessionExpirePopUp,
} from "../metaData/MetaDataAction";
import {
  CREATE_SUBCLUB_FAILURE,
  CREATE_SUBCLUB_REQUEST,
  CREATE_SUBCLUB_SUCCESS,
  CREATE_SUBCLUB_SUCCESS_FAILURE,
  GET_SUBCLUB_LIST_FAILURE,
  GET_SUBCLUB_LIST_REQUEST,
  GET_SUBCLUB_LIST_SUCCESS,
  GET_SUBCLUB_LIST_SUCCESS_FAILURE,
  RESET_CREATE_SUBCLUB_RESPONSE,
} from "./SubclubType";
export const createSubclub = (requestBody, requestHeaders, isShowToaster) => {
  return async (dispatch) => {
    dispatch(getCreateSubclubRequest());
    try {
      let resp = await axios.post(
        process.env.REACT_APP_URL_DOMAIN +
          process.env.REACT_APP_CREATE_SUBCLCUB,
        requestBody,
        requestHeaders
      );
      if (resp.data.errors !== undefined && resp.data.errors.length !== 0) {
        dispatch(getCreateSubclubSuccessFailure(resp.data));
        dispatch(setErrorToaster(resp.data.errors[0].message));
        setSessionExpire(resp, setSessionExpirePopUp, dispatch);
      } else {
        dispatch(getCreateSubclubSuccess(resp.data));
        if (isShowToaster) {
          dispatch(setSuccessToaster("Subclub created successfully"));
        }
      }
    } catch (err) {
      dispatch(getCreateSubclubFailure(err.message));
      setToaster(err, ERROR_MESSAGE, dispatch);
      setSessionExpire(err, setSessionExpirePopUp, dispatch);
      setBlankSession(err, dispatch, requestHeaders, setBlankSessionIdError);
    }
  };
};

export const getCreateSubclubRequest = () => {
  return {
    type: CREATE_SUBCLUB_REQUEST,
  };
};

export const getCreateSubclubSuccess = (apiData) => {
  return {
    type: CREATE_SUBCLUB_SUCCESS,
    payload: apiData,
  };
};

export const getCreateSubclubFailure = (apiData) => {
  return {
    type: CREATE_SUBCLUB_FAILURE,
    payload: apiData,
  };
};
export const getCreateSubclubSuccessFailure = (apiData) => {
  return {
    type: CREATE_SUBCLUB_SUCCESS_FAILURE,
    payload: apiData,
  };
};

export const getSubclubList = (
  requestHeaders,
  requestBody,
  apiurl,
  controller
) => {
  return async (dispatch) => {
    dispatch(getSubclubListRequest());
    try {
      const resp = await axios.post(apiurl, requestBody, {
        ...requestHeaders,
        signal: controller.signal,
      });
      if (resp.data.errors !== undefined && resp.data.errors.length !== 0) {
        dispatch(getSubclubListSuccessFailure(resp.data));
        dispatch(setErrorToaster(resp.data.errors[0].message));
        setSessionExpire(resp, setSessionExpirePopUp, dispatch);
      } else {
        dispatch(getSubclubListSuccess(resp.data));
      }
    } catch (err) {
      dispatch(getSubclubListFailure(err.message));
      setToaster(err, ERROR_MESSAGE, dispatch);
      setSessionExpire(err, setSessionExpirePopUp, dispatch);
      setBlankSession(err, dispatch, requestHeaders, setBlankSessionIdError);
    }
  };
};
export const getSubclubListRequest = () => {
  return {
    type: GET_SUBCLUB_LIST_REQUEST,
  };
};

export const getSubclubListSuccess = (apiData) => {
  return {
    type: GET_SUBCLUB_LIST_SUCCESS,
    payload: apiData,
  };
};
export const getSubclubListFailure = (apiData) => {
  return {
    type: GET_SUBCLUB_LIST_FAILURE,
    payload: apiData,
  };
};
export const getSubclubListSuccessFailure = (apiData) => {
  return {
    type: GET_SUBCLUB_LIST_SUCCESS_FAILURE,
    payload: apiData,
  };
};
export function resetCreateSubclubResponse() {
  return {
    type: RESET_CREATE_SUBCLUB_RESPONSE,
  };
}
