import axios from "axios";
import {
  setBlankSessionIdError,
  setSessionExpirePopUp,
} from "../metaData/MetaDataAction";
import {
  ERROR_MESSAGE,
  setBlankSession,
  setSessionExpire,
  setToaster,
} from "../reduxUtil";
import { setErrorToaster } from "../toaster/ToasterAction";
import {
  CONTENT_GENERATION_REQUEST,
  CONTENT_GENERATION_SUCCESS,
} from "./PromptDataType";

export const emptyContentResponse = () => {
  return (dispatch) => {
    dispatch(contentGenerationDetailsSuccess(""));
  };
};

export const contentGenerationDetails = (requestHeaders, requestBody) => {
  return (dispatch) => {
    dispatch(contentGenerationDetailsRequest());
    axios
      .post(
        process.env.REACT_APP_URL_DOMAIN +
          process.env.REACT_APP_CONTENT_GENERATION,
        requestBody,
        requestHeaders
      )
      .then((resp) => {
        if (resp.data.errors !== undefined && resp.data.errors.length !== 0) {
          // sessionStorage.setItem("offerNumber","")
          dispatch(contentGenerationDetailsSuccessFailure(resp.data));
          dispatch(setErrorToaster(resp.data.errors[0].message));
          setSessionExpire(resp, setSessionExpirePopUp, dispatch);
        } else {
          // sessionStorage.setItem("offerNumber",resp.data?.offerData?.offerNumber)
          dispatch(contentGenerationDetailsSuccess(resp.data));
        }
      })
      .catch((err) => {
        dispatch(contentGenerationDetailsFailure(err.message));
        setToaster(err, ERROR_MESSAGE, dispatch);
        setSessionExpire(err, setSessionExpirePopUp, dispatch);
        setBlankSession(err, dispatch, requestHeaders, setBlankSessionIdError);
      });
  };
};

export const contentGenerationDetailsRequest = () => {
  return {
    type: CONTENT_GENERATION_REQUEST,
  };
};

export const contentGenerationDetailsSuccess = (apiData) => {
  return {
    type: CONTENT_GENERATION_SUCCESS,
    payload: apiData,
  };
};

export const contentGenerationDetailsSuccessFailure = (apiData) => {
  return {
    type: CONTENT_GENERATION_SUCCESS,
    payload: apiData,
  };
};

export const contentGenerationDetailsFailure = (error) => {
  return {
    type: CONTENT_GENERATION_SUCCESS,
    payload: error,
  };
};
