import Select from "react-select";
import {
	ENTITY,
	VALUE,
} from "../GenericRules/GenericRulesConstants";
import "./ReactSelect.styles.scss";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { DARK, DROPDOWN_BGCOLOR_DARK_THEME, DROPDOWN_FOCUSED_BGCOLOR_DARK_THEME, DROPDOWN_FOCUSED_BGCOLOR_LIGHT_THEME, DROPDOWN_SELECTED_BGCOLOR_DARK_THEME, DROPDOWN_SELECTED_BGCOLOR_LIGHT_THEME, LIGHT } from "../../constantComponent/Constants";

const ReactMutliSelect = ({
	options = [],
	name,
	filter,
	setExpression,
	handleFilterChange,
	setAttributeModal,
	setEditProcess,
	filterType,
}) => {
	const toasterData = useSelector((state) => state.toasterData);
	const [theme, setTheme] = useState(toasterData.isDarkTheme);
	useEffect(() => {
		setTheme(toasterData.isDarkTheme);
	}, [toasterData]);
	if (options.length > 0) {
		if (filterType && filterType === VALUE) {
			options = options.map((optObj) => ({
				label: optObj.name,
				value: optObj.code,
			}));
		} else {
			options = options.map((label) => ({
				label,
				value: label,
			}));
		}
	}

	function handleChange(value) {
		let e = {
			target: {
				name: name,
				value: value.map(({ label, value }) => ({ code: value, name: label })),
			},
		};
		if (name.includes(ENTITY)) {
			handleFilterChange(e, filter.id, setExpression, setEditProcess);
			setAttributeModal((prev) => {
				return {
					...prev,
					status: true,
					id: filter.id,
					entityValue: value,
					selectedAttr: filter?.attr,
				};
			});
		} else {
			handleFilterChange(e, filter.id, setExpression, setEditProcess);
		}
		setEditProcess({
			status: false,
			id: null,
			filterType: "",
		});
	}

	const customStyles = {
		option: (provided, state) => ({
			...provided,
			backgroundColor:
				state.isSelected && theme === LIGHT
					? DROPDOWN_SELECTED_BGCOLOR_LIGHT_THEME
					: state.isSelected && theme === DARK
					? DROPDOWN_SELECTED_BGCOLOR_DARK_THEME
					: // ? DROPDOWN_FOCUSED_BGCOLOR_LIGHT_THEME
					state.isFocused && theme === DARK
					? DROPDOWN_FOCUSED_BGCOLOR_DARK_THEME
					: state.isFocused && theme === LIGHT
					? DROPDOWN_FOCUSED_BGCOLOR_LIGHT_THEME
					: theme === DARK
					? DROPDOWN_BGCOLOR_DARK_THEME
					: "#fff",
			color: state.isSelected
				? "#fff"
				: state.isFocused && theme === DARK
				? "#fff"
				: state.isFocused && theme === LIGHT
				? DROPDOWN_SELECTED_BGCOLOR_LIGHT_THEME
				: theme === LIGHT
				? DROPDOWN_SELECTED_BGCOLOR_LIGHT_THEME
				: "#fff",
			padding: 8,
		}),
		control: (baseStyles, state) => ({
			...baseStyles,
			position: "relative",
			top: -5,
		}),
	};

	return (
		<>
			<Select
				options={options}
				styles={customStyles}
				onChange={handleChange}
				name={name}
				value={
					Array.isArray(filter.value)
						? filter.value.map((item) => {
								return {
									label: item.name,
									value: item.code,
								};
						  })
						: null
				}
				defaultMenuIsOpen
				autoFocus
				onBlur={() => {
					setEditProcess({
						status: false,
						id: null,
						filterType: "",
					});
				}}
				className="react-select-dropdown"
				isMulti={true}
			/>
		</>
	);
};

export default ReactMutliSelect;
