/* eslint-disable jsx-a11y/anchor-is-valid */
import "./ContentDetails.style.scss";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";

import {
	changeDateDashFormat,
	copyToClipBoard,
	formatDate,
} from "../../utilsComponent/CommonUtil";
import "../../../tellurideExtAssets/styles/reactCalendar.style.scss";
import "../../../tellurideExtAssets/styles/reactDatePicker.style.scss";
import DatePicker from "react-date-picker";
import { images } from "../../imagesComponent/Images";
import { OFFER_DETAILS_STATUS_DRAFT } from "../../constantComponent/Constants";
import moment from "moment";
import {
	DESCRIPTION_LABEL,
	END_DATE_INFO,
	END_DATE_LABEL,
	CONTENT_CATEGORY_OPTIONS,
	CONTENT_DETAILS_TITLE,
	CONTENT_END_DATE,
	CONTENT_ID_INFO,
	CONTENT_ID_LABEL,
	CONTENT_START_DATE,
	CONTENT_TYPE_LABEL,
	START_DATE_INFO,
	START_DATE_LABEL,
	CONTENT_PREVIEW_AND_TEST,
} from "./ContentDetailsConstants";
import {
	convertUTCDateToLocalDate,
	dateHandler,
	getCustomStyles,
	validateDescription,
	valueHandler,
} from "./ContentDetailsUtil";
//import { OFFER_CATEGORY } from "../rewardComponent/RewardConstant";
import Select from "react-select";
import { RxCopy } from "react-icons/rx";
import PreviewTestContent from "../contentBuilderComponent/PreviewTestContent";
import { PROGRAM_TYPE } from "../../offerSetupComponent/rewardComponent/RewardConstant";
const ContentDetails = ({
	contentDetails,
	setContentDetails,
	isDisabled,
	setIsPreviewImage,
	contentBuilderData,
}) => {
	const dispatch = useDispatch();
	const offerDetailsPageData = useSelector((state) => state.offerDetailsData);
	// ProgramType change
	// const metaData = useSelector((state) => state.metaData);

	// const { programTypesResponse, programTypesLoading } = metaData;

	// const programTypes = programTypesResponse?.programTypes
	// 	? programTypesResponse?.programTypes
	// 	: [];

	const [isSetDisable, setDisable] = useState(false);
	const [copyText, setCopyText] = useState(false);
	const toasterData = useSelector((state) => state.toasterData);
	const [theme, setTheme] = useState(toasterData.isDarkTheme);
	const ContentOfferData = offerDetailsPageData.offerDetailsResponse;

	useEffect(() => {
		setTheme(toasterData.isDarkTheme);
	}, [toasterData.isDarkTheme]);
	useEffect(() => {
		setDisable(isDisabled);
	}, [isDisabled]);
	return (
		<>
			<div className="content-details-main-container">
				<div className="content-details-title setup-page-section-title">
					{CONTENT_DETAILS_TITLE}
				</div>
				<section className="content-details-section">
					<div className="form-div">
						<div className="content-code-wrapper">
							<div className="content-code">
								<div className="content-info-container">
									<label className="content-info-label details-page-label">
										{CONTENT_ID_LABEL} :
									</label>
									<span className="content-info-bold">
										{ContentOfferData?.offerData?.offerNumber}
									</span>
									{copyText === false ? (
										<RxCopy
											className="copy-icon"
											onClick={() => {
												setCopyText(true);
												copyToClipBoard(
													ContentOfferData?.offerData?.offerNumber
												);
												setTimeout(() => {
													setCopyText(false);
												}, 3000);
											}}
										/>
									) : (
										<p className="copy-icon-text">Copied!</p>
									)}
								</div>
								<p>{CONTENT_ID_INFO}</p>
							</div>
							<div className="content-code">
								<div className="content-info-container content-type-container">
									<label className="content-info-label content-type-label details-page-label">
										{CONTENT_TYPE_LABEL} :
									</label>
									<span className="content-info-bold">
										{ContentOfferData?.offerData?.offerType}
									</span>
									{/* <span className="content-info-bold">{"Points Earn"}</span> */}
								</div>
							</div>
						</div>

						<div className="date-wrapper">
							<div className="date-wrapper-inside">
								<fieldset id="start-date" disabled={isSetDisable}>
									<legend>{START_DATE_LABEL}</legend>
									<DatePicker
										calendarIcon={
											<img
												src={images.calendarIcon}
												className="calendar-icon"
											/>
										}
										clearIcon={null}
										format="y-MM-dd"
										value={moment(contentDetails.startDate).toLocaleString(
											"en-US"
										)}
										minDate={moment(
											ContentOfferData?.offerData?.createdDate,
											"YYYY-MM-DD"
										).toDate()}
										onChange={(value) => {
											setContentDetails((prev) => {
												return {
													...prev,
													startDate: new Date(value).toLocaleDateString(
														"en-US"
													),
												};
											});
										}}
										disabled={isSetDisable}
									/>
								</fieldset>

								<div className="info-div">
									<p>{START_DATE_INFO}</p>
								</div>
							</div>

							<div className="date-wrapper-inside" id="end-date">
								<fieldset disabled={isSetDisable}>
									<legend>{END_DATE_LABEL}</legend>
									<DatePicker
										calendarIcon={
											<img
												src={images.calendarIcon}
												className="calendar-icon"
											/>
										}
										clearIcon={null}
										format="y-MM-dd"
										minDate={moment(
											changeDateDashFormat(contentDetails.startDate),
											"YYYY-MM-DD"
										).toDate()}
										onChange={(value) => {
											setContentDetails((prev) => {
												return {
													...prev,
													endDate: new Date(value).toLocaleDateString("en-US"),
												};
											});
										}}
										value={moment(contentDetails.endDate).toLocaleString(
											"en-US"
										)}
										disabled={isSetDisable}
										className={"top-230"}
									/>
								</fieldset>

								<div className="info-div">
									<p>{END_DATE_INFO}</p>
								</div>
							</div>
						</div>
						<div className="textbox-wrapper">
							{/* <fieldset
								disabled={isSetDisable}
								className="dropdown catagory-tablet"
							>
								<legend>{PROGRAM_TYPE}</legend>
								<Select
									styles={getCustomStyles(theme)}
									isDisabled={isSetDisable || programTypesLoading}
									options={programTypes.map((programType) => {
										return {
											label: programType.name,
											value: programType.code,
										};
									})}
									isLoading={programTypesLoading}
									value={
										contentDetails.programType
											? {
													label: programTypes.find(
														(programType) =>
															programType.code === contentDetails.programType
													)?.name,
													value: contentDetails.programType,
											  }
											: null
									}
									onChange={({ value }) => {
										setContentDetails((prev) => ({
											...prev,
											programType: value,
										}));
									}}
									className="offer-category-dropdown"
								/>
							</fieldset> */}
							<fieldset className="text-box" disabled={isSetDisable}>
								<legend>{DESCRIPTION_LABEL}</legend>
								<textarea
									className="input-text-textarea"
									rows="3"
									cols="50"
									value={contentDetails.description}
									maxLength="250"
									name="contentDescription"
									onChange={(e) => {
										setContentDetails((prev) => {
											return {
												...prev,
												description: validateDescription(e),
											};
										});
									}}
								/>
							</fieldset>
						</div>
						<div className="preview-btn-wrapper">
							<a
								className={`preview-link ${
									contentBuilderData.imageId ||
									(contentBuilderData.headlines.length > 0
										? contentBuilderData.headlines.some(
												(h) => h.value.length > 0
										  )
										: false) ||
									(contentBuilderData.bodylines.length > 0
										? contentBuilderData.bodylines.some(
												(b) => b.value.length > 0
										  )
										: false)
										? ""
										: "disabled"
								}`}
								onClick={() => {
									if (
										(contentBuilderData.headlines.length > 0 &&
											contentBuilderData.headlines.some(
												(h) => h.value.length > 0
											)) ||
										(contentBuilderData.bodylines.length > 0 &&
											contentBuilderData.bodylines.some(
												(b) => b.value.length > 0
											)) ||
										contentBuilderData.imageId
									)
										setIsPreviewImage(true);
								}}
							>
								{CONTENT_PREVIEW_AND_TEST}
							</a>
						</div>
					</div>
				</section>
			</div>
		</>
	);
};

export default ContentDetails;
