import { Link } from "react-router-dom";
import { homeIcon } from "../utilsComponent/SidebarIcons";
import "./UnauthorizeWrapper.style.scss";
import { memo, useEffect } from "react";
import { getEntitiesDetails } from "../../tellurideExtRedux";
import { OFRI, headers } from "../constantComponent/Constants";
import { getRightsFromSession, skey } from "../utilsComponent/CommonUtil";
import { useDispatch, useSelector } from "react-redux";
import { useErrorBoundary } from "react-error-boundary";


export const PageNotFound = ({ pageName }) => {
  return (
    <div className="redirection-page-wrapper">
      <section className="display-flex-wrapper">
        <div className="inner-section-wrapper">
          <h1
            style={{
              position: "fixed",
              top: "30%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              fontSize: "3em",
              display: "flex",
              alignItems: "center",
              color: "var(--text-primary)",
            }}
          >
            {pageName}
            <Link
              to={"/"}
              style={{
                display: "flex",
                marginLeft: "10px",
              }}
            >
              {homeIcon()}
            </Link>
          </h1>
        </div>
      </section>
    </div>
  );
};

const UnauthorizedAccess = () => {
	const offerDetailsPageData = useSelector((state) => state.offerDetailsData);
	const dispatch = useDispatch();
	const { showBoundary } = useErrorBoundary();
  
  useEffect(() => {
    headers.sessionId = skey();
    headers.requestAccess = getRightsFromSession();
    headers.organization = offerDetailsPageData?.authOrganizationName;
    const requestHeaders = {
      headers,
    };
    const entitiesRequestBody = {
      requestorID: OFRI,
      messageVersion: process.env.REACT_APP_MESSAGE_VERSION_01,
    };
    dispatch(
      getEntitiesDetails(showBoundary, entitiesRequestBody, requestHeaders)
    );
  }, []);
  return (
    <div className="redirection-page-wrapper">
      <section className="display-flex-wrapper">
        <div className="inner-section-wrapper">
          <h1
            style={{
              position: "fixed",
              top: "40%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              fontSize: "3em",
              display: "flex",
              alignItems: "center",
              color: "var(--text-primary)",
              width: "100%",
              justifyContent: "center",
            }}
          >
            Unauthorized Access
          </h1>
          <h5
            style={{
              position: "fixed",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "100%",
              color: "var(--text-primary)",
              display: "flex",
              justifyContent: "center",
            }}
          >
            Your Account does not have access to this page.
          </h5>
        </div>
      </section>
    </div>
  );
};
export default memo(UnauthorizedAccess);
