import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import AuthLoading from "../../LoaderComponents/AuthLoading";
import Header from "../../headerComponent/Header";
import NavigationBar from "../../navigationBarComponent/internalNavigationBarComponent/NavigationBar";
import { AnimatePresence } from "framer-motion/dist/framer-motion";
import { ErrorBoundary } from "react-error-boundary";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import UnauthorizedAccess,{
  PageNotFound,
} from "../../unauthorizeComponent/UnauthorizeWrapper";
import { useAuth0 } from "@auth0/auth0-react";
import { useSelector } from "react-redux";
import CreateContentComponent from "../../contentSetupComponent/createContentComponent/CreateContentComponent";
import CreateCampaignComponent from "../../campaignSetupComponent/createCampaingComponent/CreateCampaingComponent";
import SessionExpiredConfirmation from "../../modelComponent/sessionExpiredConfirmationComponent/SessionExpiredConfirmation";
import { logoutAuth0 } from "../../../tellurideExtRedux/reduxUtil";
import {
  CAMPAIGN_TITLE,
  CONTENT_TITLE,
  DUAL_NAVLINKS,
} from "../../constantComponent/Constants";
import LeavingSetupPageConfirmationComponent from "../../modelComponent/leavingSetupPageConfirmationComponent/LeavingSetupPageConfirmationComponent";
import {
  checkRightsIsEditable,
  getRightsKey,
} from "../../utilsComponent/CommonUtil";


const CampaignRedirection = () => {
  const [showOfferPopup, setShowOfferPopup] = useState(false);
  const [showCampaignCreatePopup, setShowCampaignCreatePopup] = useState(false);
  const [selectOffer, setSelectOffer] = useState(false);
  const location = useLocation();
  const scrollTargetDiv = useRef();
  const { logout } = useAuth0();
  const OfferAnalyticsBlankSessionIdError = useSelector(
    (state) => state.OfferAnalyticsData.blankSessionIdError
  );
  const offerDetailsDataBlankSessionIdError = useSelector(
    (state) => state.offerDetailsData.blankSessionIdError
  );
  const metaDataBlankSessionIdError = useSelector(
    (state) => state.metaData.blankSessionIdError
  );
  const OfferAnalyticsSessionExpire = useSelector(
    (state) => state.OfferAnalyticsData.showExpiredSessionPopup
  );
  const offerDetailsDataSessionExpire = useSelector(
    (state) => state.offerDetailsData.showExpiredSessionPopup
  );
  const metaDataSessionExpire = useSelector(
    (state) => state.metaData.showExpiredSessionPopup
  );
  const offerDetailsPageData = useSelector((state) => state.offerDetailsData);
  const orgName = offerDetailsPageData?.authOrganizationName;
  const toasterData = useSelector((state) => state.toasterData);
  const [theme, setTheme] = useState(toasterData.isDarkTheme);
  useEffect(() => {
    setTheme(toasterData.isDarkTheme);
  }, [toasterData]);
  //theme
  useLayoutEffect(() => {
    scrollTargetDiv?.current?.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [location]);
  useEffect(() => {
    if (
      [
        OfferAnalyticsBlankSessionIdError,
        offerDetailsDataBlankSessionIdError,
        metaDataBlankSessionIdError,
      ].includes(true)
    ) {
      logoutAuth0(logout, orgName);
    }
  }, [
    OfferAnalyticsBlankSessionIdError,
    offerDetailsDataBlankSessionIdError,
    metaDataBlankSessionIdError,
    logout,
  ]);
  return (
    <div data-theme={theme}>
      <AuthLoading>
        <div className="redirection-page-wrapper">
          <Header
            title={
              window.location.href.includes("/content")
                ? `Content`
                : `Campaigns`
            }
          />
          <section className="display-flex-wrapper" ref={scrollTargetDiv}>
            <NavigationBar
              showOfferPopup={showOfferPopup}
              setShowOfferPopup={setShowOfferPopup}
              src={
                window.location.href.includes("/content")
                  ? CONTENT_TITLE
                  : CAMPAIGN_TITLE
              }
            />
            <AnimatePresence>
              {showOfferPopup && (
                <CreateContentComponent
                  setSelectOffer={setSelectOffer}
                  setShowContentPopup={setShowOfferPopup}
                />
              )}
              {showCampaignCreatePopup && (
                <CreateCampaignComponent
                  setSelectOffer={setSelectOffer}
                  setShowCampaignCreatePopup={setShowCampaignCreatePopup}
                />
              )}
            </AnimatePresence>
            <div className="inner-section-wrapper">
              <ErrorBoundary
                FallbackComponent={Error}
              >
                <Routes>
                  {DUAL_NAVLINKS.find(
                    (dualNavLink) => dualNavLink.label === "Campaigns"
                  ).subLinks.map((SubLink, index) => {
                    if (SubLink.isRoute) {
                      // {console.log(checkRightsIsVisible(getRightsKey(SubLink.rights)));}
                      // {getRightsKey(SubLink.rights)}
                      // {console.log(SubLink)}
                      return (
                        <>
                          <Route
                            key={index}
                            path={SubLink.path}
                            element={
                              checkRightsIsEditable(getRightsKey(SubLink.rights)) ? (
                              <SubLink.Component.Name
                                setShowCampaignCreatePopup={
                                  setShowCampaignCreatePopup
                                }
                                setShowOfferPopup={setShowOfferPopup}
                              />
                              ) : (
                                <UnauthorizedAccess />
                              )
                            }
                          />
                          {SubLink.isActive && (
                            <Route
                              path="/"
                              element={
                                <Navigate
                                  to={
                                    "/" +
                                    window.location.pathname.split("/")[1] +
                                    SubLink.url
                                  }
                                  replace
                                />
                              }
                            />
                          )}
                        </>
                      );
                    }
                  })}
                  <Route
                    path="*"
                    Component={() => {
                      return <PageNotFound pageName={"Page not found !"} />;
                    }}
                  />
                </Routes>
              </ErrorBoundary>
            </div>
          </section>
        </div>
        <LeavingSetupPageConfirmationComponent />
        {offerDetailsDataSessionExpire === true ||
        OfferAnalyticsSessionExpire === true ||
        metaDataSessionExpire === true ? (
          <SessionExpiredConfirmation />
        ) : null}
      </AuthLoading>
    </div>
  );
};

export default React.memo(CampaignRedirection);
