import { useAuth0 } from "@auth0/auth0-react";
import "./SubclubHeader.style.scss";
import { OFRI, headers } from "../../constantComponent/Constants";
import {
  checkRightsIsEditable,
  checkRightsIsVisible,
  getAllRights,
  getRightsFromSession,
  getRightsKey,
  skey,
} from "../../utilsComponent/CommonUtil";
import { useDispatch, useSelector } from "react-redux";
import { createSubclub } from "../../../tellurideExtRedux/subclub/SubclubAction";
import { miniloadingWithoutMessage } from "../../LoaderComponents/loadingDesignConstant";
const SubclubHeader = ({
  setSubclubLayout,
  btnConfig,
  setBtnConfig,
  subclubDetails,
  setSubclubDetails,
  setIsDisabled,
  setRefresh,
  validation,
  setValidation,
  subclubLayout,
}) => {
  const { user } = useAuth0();
  const offerDetailsPageData = useSelector((state) => state.offerDetailsData);
  const subclubData = useSelector((state) => state.subclubData);
  const securityData = useSelector((state) => state.securityData);
  const dispatch = useDispatch();

  function handleSaveClub() {
    if (Object.values(validation).some(({ status }) => status === true)) {
      return setValidation((prev) => ({
        code: {
          ...prev.code,
          touched: true,
        },
        name: {
          ...prev.name,
          touched: true,
        },
        description: {
          ...prev.description,
          touched: true,
        },
      }));
    }
    headers.sessionId = skey();
    headers.requestAccess = getRightsFromSession();
    headers.organization = offerDetailsPageData?.authOrganizationName;
    const requestHeaders = {
      headers,
    };
    const requestBody = {
      requestorID: OFRI,
      messageVersion: process.env.REACT_APP_MESSAGE_VERSION_01,
      name: "AFFILIATIONS",
      entity: "Offer-MasterData",
      type: "String",
      description: "Affiliation Codes",
      createdBy: user?.email,
      organization: offerDetailsPageData?.authOrganizationName,
      options: [
        {
          code: subclubDetails.code.trim(),
          name: subclubDetails.name.trim(),
          description: subclubDetails.description.trim(),
        },
      ],
    };
    dispatch(createSubclub(requestBody, requestHeaders, true));
  }

  return (
    <>
      <div className="attribute-navigation-top-div">
        <div className="empty-space-div"></div>
        <div className="navigation-text">
          <p className="offer-name setup-name-header">
            {subclubLayout.detailsSection === true
              ? "Subclub Setup"
              : "Subclubs"}
          </p>
        </div>
        <div className="navigation-buttons">
          {btnConfig.back.visible && (
            <button
              className="save-button btn btn-secondary"
              disabled={
                btnConfig.back.disabled || subclubData.createSubclubLoading
              }
              onClick={() => {
                setBtnConfig({
                  back: {
                    visible: false,
                    disabled: false,
                  },
                  save: {
                    visible: false,
                    disabled: false,
                  },
                  create: {
                    visible: true,
                    disabled: false,
                  },
                });
                setSubclubLayout({
                  detailsSection: false,
                });
                setRefresh((prev) => !prev);
                setValidation({
                  code: {
                    status: false,
                    message: "",
                    touched: false,
                  },
                  name: {
                    status: false,
                    message: "",
                    touched: false,
                  },
                  description: {
                    status: false,
                    message: "",
                    touched: false,
                  },
                });
              }}
            >
              Back
            </button>
          )}
          {btnConfig.save.visible &&
          checkRightsIsVisible(
            getRightsKey(["button", "configure", "subclub", "createsubclub"])
          ) ? (
            <button
              className="save-button btn btn-primary"
              disabled={
                btnConfig.save.disabled ||
                subclubData.createSubclubLoading ||
                !checkRightsIsEditable(
                  getRightsKey(["button", "configure", "subclub", "save"])
                )
              }
              onClick={() => handleSaveClub()}
            >
              {subclubData.createSubclubLoading
                ? miniloadingWithoutMessage()
                : "Save"}
            </button>
          ) : null}

          {btnConfig.create.visible &&
          checkRightsIsVisible(
            getRightsKey(["button", "configure", "subclub", "createsubclub"])
          ) ? (
            <button
              className="save-button btn btn-primary"
              disabled={
                btnConfig.create.disabled ||
                !checkRightsIsEditable(
                  getRightsKey([
                    "button",
                    "configure",
                    "subclub",
                    "createsubclub",
                  ])
                )
              }
              onClick={() => {
                if (
                  !checkRightsIsEditable(
                    getRightsKey([
                      "button",
                      "configure",
                      "subclub",
                      "createsubclub",
                    ])
                  )
                ) {
                  return;
                }
                setBtnConfig({
                  back: {
                    visible: true,
                    disabled: false,
                  },
                  save: {
                    visible: true,
                    disabled: false,
                  },
                  create: {
                    visible: false,
                    disabled: false,
                  },
                });
                setSubclubLayout({
                  detailsSection: true,
                });
                setSubclubDetails({
                  code: "",
                  name: "",
                  description: "",
                });
                setIsDisabled(false);
              }}
            >
              Create
            </button>
          ) : null}
        </div>
      </div>
    </>
  );
};

export default SubclubHeader;
