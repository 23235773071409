import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  convertDateFormat,
  getDateString,
  getPlusDate,
} from "./offerReviewPopUpUtiils";
import DatePicker from "react-date-picker";
import { images } from "../../../../imagesComponent/Images";
import moment from "moment";
import { NumericFormat } from "react-number-format";
import { dateRegex } from "./OfferReviewConstants";

const OfferRegistration = ({
  currentClickedNode,
  offerNumber,
  isDisabled,
  offerType,
  setSelectedData,
}) => {
  const offerDetailsPageData = useSelector((state) => state.offerDetailsData);

  const [offerValidationData, setofferValidationData] = useState({
    offerCode: currentClickedNode?.data?.value?.action?.offerCode
      ? currentClickedNode?.data?.value?.action?.offerCode
      : offerNumber
      ? offerNumber
      : offerDetailsPageData?.offerNumber,
    registrationStartTime: currentClickedNode?.data?.value?.action
      ?.registrationStartTime
      ? currentClickedNode?.data?.value?.action?.registrationStartTime
      : "0",
    registrationEndTime: currentClickedNode?.data?.value?.action
      ?.registrationEndTime
      ? currentClickedNode?.data?.value?.action?.registrationEndTime
      : "0",
    registrationStartDelta: currentClickedNode?.data?.value?.action
      ?.registrationStartDelta
      ? currentClickedNode?.data?.value?.action?.registrationStartDelta
      : "0",
    registrationEndDelta: currentClickedNode?.data?.value?.action
      ?.registrationEndDelta
      ? currentClickedNode?.data?.value?.action?.registrationEndDelta
      : "0",
  });
  const [selectedOption, setSelectedOption] = useState({
    award: {
      key: "awardDate",
      subKey: currentClickedNode?.data?.value?.action?.registrationStartTime
        ? "awardSpecificDate"
        : "awardPlusDate",
    },
    expiry: {
      key: "expiryDate",
      subKey: currentClickedNode?.data?.value?.action?.registrationEndTime
        ? "expireSpecificDate"
        : "expirePlusDate",
    },
  });
  const [dateValues, setDateValues] = useState({
    awardSpecificDate: dateRegex.test(
      currentClickedNode?.data?.value?.action?.registrationStartTime
    )
      ? getDateString(
          currentClickedNode?.data?.value?.action?.registrationStartTime
        )
      : "",
    awardPlusDate: currentClickedNode?.data?.value?.action
      ?.registrationStartDelta
      ? getPlusDate(
          currentClickedNode?.data?.value?.action?.registrationStartDelta
        )
      : "0",
    awardPlusDateUnit: "",
    expireSpecificDate: dateRegex.test(
      currentClickedNode?.data?.value?.action?.registrationEndTime
    )
      ? getDateString(
          currentClickedNode?.data?.value?.action?.registrationEndTime
        )
      : "",
    expirePlusDate: currentClickedNode?.data?.value?.action
      ?.registrationEndDelta
      ? getPlusDate(
          currentClickedNode?.data?.value?.action?.registrationEndDelta
        )
      : "0",
    expirePlusDateUnit: "",
  });
  const [editProcess, setEditProcess] = useState({
    status: false,
    name: null,
  });
  const handleEditBlur = () => {
    setEditProcess({
      status: false,
      name: null,
    });
  };
  const handleEditClick = (e) => {
    setEditProcess({
      status: true,
      name: e.target.dataset.name,
    });
  };

  useEffect(() => {
    if (selectedOption.award.key === "awardDate") {
      setofferValidationData((prev) => {
        return {
          ...prev,
          [selectedOption.award.key]: dateValues[selectedOption.award.subKey],
        };
      });
    }
    if (selectedOption.expiry.key === "expiryDate") {
      setofferValidationData((prev) => {
        return {
          ...prev,
          [selectedOption.expiry.key]: dateValues[selectedOption.expiry.subKey],
        };
      });
    }
  }, [selectedOption]);

  useEffect(() => {
    const reviewData = {
      displayData: {
        offerCode: offerValidationData?.offerCode,
        ...(selectedOption.award.key === "awardDate" &&
          selectedOption.award.subKey === "awardSpecificDate" && {
            registrationStartTime: offerValidationData?.registrationStartTime,
          }),
        ...(selectedOption.expiry.key === "expiryDate" &&
          selectedOption.expiry.subKey === "expireSpecificDate" && {
            registrationEndTime: offerValidationData?.registrationEndTime,
          }),
        ...(selectedOption.award.key === "awardDate" &&
          selectedOption.award.subKey === "awardPlusDate" && {
            registrationStartTime:
              "Event date + " +
              (offerValidationData?.registrationStartDelta === "0"
                ? offerValidationData?.registrationStartDelta + "d0h0m"
                : offerValidationData?.registrationStartDelta),
          }),
        ...(selectedOption.expiry.key === "expiryDate" &&
          selectedOption.expiry.subKey === "expirePlusDate" && {
            registrationEndTime:
              "Award Date + " +
              (offerValidationData?.registrationEndDelta === "0"
                ? offerValidationData?.registrationEndDelta + "d0h0m"
                : offerValidationData?.registrationEndDelta),
          }),
      },
      value: {
        action: {
          offerType: offerType
            ? offerType
            : currentClickedNode?.data?.value?.action?.offerType
            ? currentClickedNode?.data?.value?.action.offerType
            : "",
          offerCode: offerValidationData?.offerCode,
          groupName: offerDetailsPageData.ruleData.rule
            ? offerDetailsPageData?.ruleData?.rule[0]?.condition?.PASSES_ALL[0]
                ?.value[0]?.code
            : null,
          ...(selectedOption.award.key === "awardDate" &&
            selectedOption.award.subKey === "awardSpecificDate" && {
              registrationStartTime: offerValidationData?.registrationStartTime,
            }),
          ...(selectedOption.expiry.key === "expiryDate" &&
            selectedOption.expiry.subKey === "expireSpecificDate" && {
              registrationEndTime: offerValidationData?.registrationEndTime,
            }),
          ...(selectedOption.award.key === "awardDate" &&
            selectedOption.award.subKey === "awardPlusDate" && {
              registrationStartDelta:
                offerValidationData?.registrationStartDelta === "0"
                  ? offerValidationData?.registrationStartDelta + "d0h0m"
                  : offerValidationData?.registrationStartDelta,
            }),
          ...(selectedOption.expiry.key === "expiryDate" &&
            selectedOption.expiry.subKey === "expirePlusDate" && {
              registrationEndDelta:
                offerValidationData?.registrationEndDelta === "0"
                  ? offerValidationData?.registrationEndDelta + "d0h0m"
                  : offerValidationData?.registrationEndDelta,
            }),
        },
      },
    };
    setSelectedData(reviewData);
  }, [offerValidationData]);
  return (
    <div className="validity-container">
      <div className="sub-header">
        <p>When to add this offer to member's account</p>{" "}
      </div>
      <div className="inner-section-review">
        <div className="row">
          <input
            name="offer-award"
            type="radio"
            checked={selectedOption.award.subKey === "awardPlusDate"}
            onChange={() => {
              setSelectedOption((prev) => {
                return {
                  award: {
                    key: "awardDate",
                    subKey: "awardPlusDate",
                  },
                  expiry: {
                    key: "expiryDate",
                    subKey: "expirePlusDate",
                  },
                };
              });
            }}
          />
          <p className="plus-text">
            <span>Event Trigger date</span> plus{" "}
            {editProcess.name === "award-plus" &&
            editProcess.status === true &&
            isDisabled === false ? (
              <fieldset>
                <NumericFormat
                  className="amount"
                  value={dateValues?.awardPlusDate}
                  decimalScale={0}
                  thousandsGroupStyle="lakh"
                  thousandSeparator=","
                  displayType="input"
                  renderText={(value) => <b>{value}</b>}
                  allowNegative={false}
                  onValueChange={(values, sourceInfo) => {
                    if (editProcess.name === "award-plus") {
                      setDateValues((prev) => {
                        return {
                          ...prev,
                          awardPlusDate: values.value,
                        };
                      });
                    }
                    if (
                      selectedOption.award.key === "awardDate" &&
                      selectedOption.award.subKey === "awardPlusDate"
                    ) {
                      setofferValidationData((prev) => {
                        return {
                          ...prev,
                          registrationStartDelta: values.value
                            ? values.value + "d0h0m"
                            : "0",
                        };
                      });
                    }
                  }}
                  onBlur={() => {
                    handleEditBlur();
                  }}
                  autoFocus
                />
              </fieldset>
            ) : (
              <span
                className="value"
                data-name={"award-plus"}
                onClick={(e) => {
                  if (selectedOption.award.subKey === "awardPlusDate") {
                    handleEditClick(e);
                  }
                }}
              >
                {" "}
                {dateValues.awardPlusDate ? dateValues.awardPlusDate : "0"}{" "}
              </span>
            )}{" "}
            <span>Days</span>
          </p>
        </div>
        <div className="row">
          <input
            name="offer-award"
            type="radio"
            onChange={() => {
              setSelectedOption((prev) => {
                return {
                  award: {
                    key: "awardDate",
                    subKey: "awardSpecificDate",
                  },
                  expiry: {
                    key: "expiryDate",
                    subKey: "expireSpecificDate",
                  },
                };
              });
            }}
            checked={selectedOption.award.subKey === "awardSpecificDate"}
          />
          <p>Specific date</p>
          {editProcess.name === "award-date" &&
          editProcess.status === true &&
          isDisabled === false ? (
            <fieldset>
              <DatePicker
                calendarIcon={
                  <img src={images.calendarIcon} className="calendar-icon" />
                }
                clearIcon={null}
                format="y-MM-dd"
                value={moment(
                  dateValues?.awardSpecificDate
                    ? dateValues?.awardSpecificDate
                    : offerDetailsPageData?.offerData?.offerStartDate
                ).toLocaleString("en-US")}
                minDate={moment(
                  offerDetailsPageData?.offerData?.createdDate,
                  "YYYY-MM-DD"
                ).toDate()}
                onChange={(value) => {
                  setDateValues((prev) => {
                    return {
                      ...prev,
                      awardSpecificDate: convertDateFormat(
                        new Date(value).toLocaleDateString("en-US")
                      ),
                    };
                  });
                  if (
                    dateValues?.expireSpecificDate &&
                    new Date(dateValues?.expireSpecificDate) < new Date(value)
                  ) {
                    setDateValues((prev) => {
                      return {
                        ...prev,
                        expireSpecificDate: convertDateFormat(
                          new Date(value).toLocaleDateString("en-US")
                        ),
                      };
                    });
                  }
                  if (
                    selectedOption.award.key === "awardDate" &&
                    selectedOption.award.subKey === "awardSpecificDate"
                  ) {
                    setofferValidationData((prev) => {
                      return {
                        ...prev,
                        registrationStartTime:
                          convertDateFormat(
                            new Date(value).toLocaleDateString("en-US")
                          ) + "T00:00:00",
                      };
                    });
                  }
                }}
                onCalendarClose={() => {
                  handleEditBlur(setEditProcess);
                }}
                isOpen
              />
            </fieldset>
          ) : (
            <p
              className={isDisabled === false ? `value` : null}
              data-name={"award-date"}
              onClick={(e) => {
                if (selectedOption.award.subKey === "awardSpecificDate") {
                  handleEditClick(e);
                }
              }}
            >
              {dateValues?.awardSpecificDate
                ? dateValues?.awardSpecificDate
                : "Choose a date"}
            </p>
          )}
        </div>
      </div>
      <div className="sub-header">
        <p>When to expire this offer from member's account</p>{" "}
      </div>
      <div className="inner-section-review">
        <div className="row">
          <input
            name="offer-expire"
            type="radio"
            // onChange={() => {
            //   setSelectedOption((prev) => {
            //     return {
            //       expiry: {
            //         key: "expiryDate",
            //         subKey: "expirePlusDate",
            //       },
            //     };
            //   });
            // }}
            disabled
            checked={selectedOption.expiry.subKey === "expirePlusDate"}
          />
          <p className="plus-text">
            <span>Offer Award Date</span> plus{" "}
            {editProcess.name === "expire-plus" &&
            editProcess.status === true &&
            isDisabled === false ? (
              <fieldset>
                <NumericFormat
                  className="amount"
                  value={dateValues?.expirePlusDate}
                  thousandsGroupStyle="lakh"
                  thousandSeparator=","
                  displayType="input"
                  decimalScale={0}
                  renderText={(value) => <b>{value}</b>}
                  allowNegative={false}
                  onValueChange={(values, sourceInfo) => {
                    if (editProcess.name === "expire-plus") {
                      setDateValues((prev) => {
                        return {
                          ...prev,
                          expirePlusDate: values.value,
                        };
                      });
                    }
                    if (
                      selectedOption.expiry.key === "expiryDate" &&
                      selectedOption.expiry.subKey === "expirePlusDate"
                    ) {
                      setofferValidationData((prev) => {
                        return {
                          ...prev,
                          registrationEndDelta: values.value
                            ? values.value + "d0h0m"
                            : "0",
                        };
                      });
                    }
                  }}
                  onBlur={() => {
                    handleEditBlur();
                  }}
                  autoFocus
                />
              </fieldset>
            ) : (
              <span
                className="value"
                data-name={"expire-plus"}
                onClick={(e) => {
                  if (selectedOption.expiry.subKey === "expirePlusDate") {
                    handleEditClick(e);
                  }
                }}
              >
                {" "}
                {dateValues.expirePlusDate
                  ? dateValues.expirePlusDate
                  : "0"}{" "}
              </span>
            )}{" "}
            <span>Days</span>
          </p>
        </div>
        <div className="row">
          <input
            name="offer-expire"
            type="radio"
            // onChange={() => {
            //   setSelectedOption((prev) => {
            //     return {
            //       ...prev,
            //       expiry: {
            //         key: "expiryDate",
            //         subKey: "expireSpecificDate",
            //       },
            //     };
            //   });
            // }}
            disabled
            checked={selectedOption.expiry.subKey === "expireSpecificDate"}
          />

          <p>Specific date</p>
          {editProcess.name === "expire-date" &&
          editProcess.status === true &&
          isDisabled === false ? (
            <fieldset>
              <DatePicker
                calendarIcon={
                  <img src={images.calendarIcon} className="calendar-icon" />
                }
                clearIcon={null}
                format="y-MM-dd"
                value={moment(
                  dateValues?.expireSpecificDate
                    ? dateValues?.expireSpecificDate
                    : offerDetailsPageData?.offerData?.offerEndDate
                ).toLocaleString("en-US")}
                minDate={moment(
                  dateValues?.awardSpecificDate
                    ? dateValues?.awardSpecificDate
                    : offerDetailsPageData?.offerData?.offerStartDate,
                  "YYYY-MM-DD"
                ).toDate()}
                onChange={(value) => {
                  setDateValues((prev) => {
                    return {
                      ...prev,
                      expireSpecificDate: convertDateFormat(
                        new Date(value).toLocaleDateString("en-US")
                      ),
                    };
                  });
                  if (
                    selectedOption.expiry.key === "expiryDate" &&
                    selectedOption.expiry.subKey === "expireSpecificDate"
                  ) {
                    setofferValidationData((prev) => {
                      return {
                        ...prev,
                        registrationEndTime:
                          convertDateFormat(
                            new Date(value).toLocaleDateString("en-US")
                          ) + "T23:59:59",
                      };
                    });
                  }
                }}
                onCalendarClose={() => {
                  handleEditBlur(setEditProcess);
                }}
                isOpen
                className={"top-145"}
              />
            </fieldset>
          ) : (
            <p
              className={isDisabled === false ? `value` : null}
              data-name={"expire-date"}
              onClick={(e) => {
                if (selectedOption.expiry.subKey === "expireSpecificDate") {
                  handleEditClick(e);
                }
              }}
            >
              {dateValues?.expireSpecificDate
                ? dateValues?.expireSpecificDate
                : "Choose a date"}
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default OfferRegistration;
