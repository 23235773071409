import ReactSelect from "../Dropdown/ReactSelect";
import { images } from "../../imagesComponent/Images";
import {
  ATTR_DEFAULT_VALUE,
  DATE,
  DISABLED,
  ENTITY,
  ENTITY_DEFAULT_VALUE,
  ENTITY_DROPDOWN,
  FILTER_HEADER,
  JOIN,
  JOIN_DROPDOWN,
  LIST,
  NUMBER,
  OBJECT,
  OPERATOR,
  OPERATOR_DEFAULT_VALUE,
  OPERATOR_DROPDOWN,
  POPUP,
  STRING,
  SUBGROUP_OPTIONS,
  VALUE,
  ZERO,
  Operator,
  PLEASE_WAIT_MSG,
  EMPTY_ERROR,
  STRING_INPUT_LIMIT,
  NUMBER_INPUT_LIMIT,
  multi_select_operator,
  multi_operators,
  multi_attributes,
  MEMBERGROUP,
} from "./GenericRulesConstants";
import RulesReader from "./GenericRulesReader";
import { useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  getAttributesDetails,
  setAttributeList,
} from "../../../tellurideExtRedux/metaData/MetaDataAction";
import { OFRI } from "../../constantComponent/Constants";
import ReactMutliSelect from "../Dropdown/ReactMutliSelect";
import SegmentGroupModal from "./SegmentGroupModal";
import DatePicker from "react-date-picker";
import { changeDateDashFormatToBackwardSlash, getRightsFromSession, skey } from "../CommonUtil";
import { headers } from "../../constantComponent/Constants";

import { AnimatePresence, motion } from "framer-motion/dist/framer-motion";

// returns dropdown with provided options
export function getDropdown(
  filter,
  filterType,
  handleFilterChange,
  setEditProcess,
  setAttributeModal,
  options,
  setExpression,
  name,
  isMulti
) {
  return (
    <>
      {isMulti ? (
        <ReactMutliSelect
          setExpression={setExpression}
          filter={filter}
          filterType={filterType}
          handleFilterChange={handleFilterChange}
          setAttributeModal={setAttributeModal}
          setEditProcess={setEditProcess}
          name={name}
          options={options}
        />
      ) : (
        <ReactSelect
          setExpression={setExpression}
          filter={filter}
          filterType={filterType}
          handleFilterChange={handleFilterChange}
          setAttributeModal={setAttributeModal}
          setEditProcess={setEditProcess}
          name={name}
          options={options}
        />
      )}
    </>
  );
}

export function getInput(
  inputType,
  handleFilterChange,
  filter,
  setExpression,
  setEditProcess,
  editProcess,
  options
) {
  switch (inputType) {
    case DATE:
      return (
        <fieldset id="start-date" className="calender-fieldset">
          <DatePicker
            name="attribute-value-input"
            value={filter.value}
            calendarIcon={
              <img
                src={images.calendarIcon}
                className="calendar-icon"
                alt="calender-img"
              />
            }
            clearIcon={null}
            format="y-MM-dd"
            onChange={(date) => {
              let e = {
                target: {
                  name: "attribute-value-input",
                  value: changeDateDashFormatToBackwardSlash(
                    new Date(date).toLocaleDateString("en-US")
                  ),
                },
              };
              handleFilterChange(e, filter.id, setExpression, setEditProcess);
              setEditProcess({ id: null, status: false });
            }}
            isOpen
            onCalendarClose={() => {
              setEditProcess({ id: null, status: false });
            }}
          />
        </fieldset>
      );
    case LIST:
      return (
        <AnimatePresence>
          <SegmentGroupModal
            options={options}
            id={filter.id}
            setEditProcess={setEditProcess}
            setExpression={setExpression}
            isMulti={multi_operators.includes(filter.operator)}
            filter={filter}
          />
        </AnimatePresence>
      );
    case STRING:
      if (options && options.length > ZERO) {
        return (
          <AnimatePresence>
            <SegmentGroupModal
              options={options}
              id={filter.id}
              setEditProcess={setEditProcess}
              setExpression={setExpression}
              isMulti={multi_operators.includes(filter.operator)}
              filter={filter}
            />
          </AnimatePresence>
        );
      } else {
        return (
          <input
            type="text"
            name="attribute-value-input"
            value={filter.value}
            autoFocus
            placeholder="Please enter value"
            onChange={(e) => {
              if (e.target.value.length <= STRING_INPUT_LIMIT) {
                handleFilterChange(e, filter.id, setExpression, setEditProcess);
              }
            }}
            maxlength="30"
            onBlur={() => {
              setEditProcess({ id: null, status: false });
            }}
            className={`attribute-value ${
              filter.value.length === ZERO ? EMPTY_ERROR : null
            }`}
          />
        );
      }
    case NUMBER:
      return (
        <input
          type="text"
          name="attribute-value-input"
          value={filter.value}
          autoFocus
          onChange={(e) => {
            if (
              e.target.value.length <= NUMBER_INPUT_LIMIT &&
              Number.isInteger(Number(e.target.value)) === true
            ) {
              e.target.value =
                Number(e.target.value).toFixed() === "0"
                  ? ""
                  : Number(e.target.value).toFixed();
              handleFilterChange(e, filter.id, setExpression, setEditProcess);
            }
          }}
          onBlur={() => {
            setEditProcess({ id: null, status: false });
          }}
          className={`attribute-value ${
            filter.value.length === ZERO ? EMPTY_ERROR : null
          }`}
          maxlength="7"
        />
      );
    case POPUP:
      return (
        <AnimatePresence>
          <SegmentGroupModal
            options={options}
            id={filter.id}
            setEditProcess={setEditProcess}
            setExpression={setExpression}
            isMulti={multi_operators.includes(filter.operator)}
            filter={filter}
          />
        </AnimatePresence>
      );
    default:
      return <span>Please enter value</span>;
  }
}
// Reads the subgroup JSON
export function SubgroupReader({
  elm,
  setExpression,
  setEditProcess,
  handleDelete,
  editProcess,
  handleFilterChange,
  handleEditClick,
  setAttributeModal,
  isSetDisable,
  hideButtons,
}) {
  // if editing is true => return edit mode inputs
  if (editProcess.status === true && editProcess.id === elm.id) {
    return (
      <div className="subgroup-filter-edit-wrapper">
        <div className="subgroup-header">
          <span>IF</span>
        </div>
        {getDropdown(
          elm,
          editProcess.filterType,
          handleFilterChange,
          setEditProcess,
          setAttributeModal,
          SUBGROUP_OPTIONS,
          setExpression,
          JOIN_DROPDOWN
        )}
        <div className="filter-static-header">{FILTER_HEADER}</div>
        {hideButtons === true ? null : (
          <img
            src={images.trashImage}
            className={
              isSetDisable
                ? `deleteFilterBtn subgroup ${DISABLED}`
                : "deleteFilterBtn subgroup"
            }
            onClick={() => {
              handleDelete(elm.id, setExpression, isSetDisable);
            }}
            alt="delete"
          />
        )}
      </div>
    );
  } else {
    // if editing is false => return read-only mode inputs
    return (
      <div className="filter-rule-line">
        <div
          className="subgroup-header"
          onClick={() => {
            handleEditClick(elm.id, JOIN, setEditProcess, isSetDisable);
          }}
        >
          <span>IF</span>
          <span className="square-dropdown">{elm.join}</span>
        </div>
        <span>{FILTER_HEADER}</span>
        {hideButtons === true ? null : (
          <img
            src={images.trashImage}
            className={
              isSetDisable
                ? `deleteFilterBtn subgroup ${DISABLED}`
                : "deleteFilterBtn subgroup"
            }
            onClick={() => {
              handleDelete(elm.id, setExpression, isSetDisable);
            }}
            alt="delete"
          />
        )}
        {isSetDisable}
      </div>
    );
  }
}
// Reads the rule of subgroups
export function RuleReader(props) {
  const { filter, setExpression, handleDelete, isSetDisable, hideButtons } =
    props;
  const metaData = useSelector((state) => state.metaData);

  return (
    <motion.div
      className="filters-wrapper"
      key={filter.id}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      {filter.type !== "subgroup" ? (
        <div className="filter-container">
          <AnimatePresence>
            <div className="filter-rule-line">
              <div className="rule-header">
                <EntityReader {...props} options={props.entitesOptions} />
                {filter.entity === ENTITY_DEFAULT_VALUE ? null : (
                  <AttributeReader {...props} />
                )}
                {filter.entity === ENTITY_DEFAULT_VALUE ||
                filter.attr === ATTR_DEFAULT_VALUE ? null : (
                  <OperatorReader {...props} />
                )}
                {filter.entity === ENTITY_DEFAULT_VALUE ||
                filter.attr === ATTR_DEFAULT_VALUE ||
                filter.operator === OPERATOR_DEFAULT_VALUE ? null : (
                  <InputReader {...props} />
                )}
              </div>
              {hideButtons === true ? null : (
                <img
                  src={images.trashImage}
                  className={
                    isSetDisable
                      ? `deleteFilterBtn rule-delete-btn ${DISABLED}`
                      : "deleteFilterBtn rule-delete-btn"
                  }
                  onClick={() => {
                    handleDelete(filter.id, setExpression, props.isSetDisable);
                  }}
                  alt="delete"
                />
              )}
            </div>
          </AnimatePresence>
        </div>
      ) : (
        <RulesReader expression={[filter]} {...props} />
      )}
    </motion.div>
  );
}
export function EntityReader({
  editProcess,
  handleEditClick,
  filter,
  handleFilterChange,
  setEditProcess,
  setAttributeModal,
  options,
  setExpression,
  isSetDisable,
}) {
  // if entity is in edit mode
  return editProcess.status === true &&
    editProcess.id === filter.id &&
    editProcess.filterType === ENTITY ? (
    getDropdown(
      filter,
      editProcess.filterType,
      handleFilterChange,
      setEditProcess,
      setAttributeModal,
      options,
      setExpression,
      ENTITY_DROPDOWN
    )
  ) : (
    // if entity is in read-only mode
    <motion.span
      onClick={(e) => {
        handleEditClick(filter.id, ENTITY, setEditProcess, isSetDisable);
      }}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      {filter.entity}
    </motion.span>
  );
}
export function AttributeReader({
  filter,
  setEditProcess,
  setAttributeModal,
  isSetDisable,
}) {
  return (
    <motion.span
      onClick={() => {
        if (isSetDisable === false) {
          setEditProcess({
            status: false,
            id: null,
          });
          if (filter.entity !== ENTITY_DEFAULT_VALUE) {
            setAttributeModal((prev) => {
              return {
                ...prev,
                status: true,
                id: filter.id,
                entityValue: filter.entity,
                selectedAttr: filter?.attr,
              };
            });
          }
        }
      }}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      {filter?.name ? filter.name : filter?.attr}
    </motion.span>
  );
}
export function OperatorReader({
  editProcess,
  handleEditClick,
  filter,
  handleFilterChange,
  setEditProcess,
  setAttributeModal,
  setExpression,
  isSetDisable,
}) {
  const dispatch = useDispatch();
  const [options, setOptions] = useState([]);

  const metaData = useSelector((state) => state.metaData);

  const AttributeList = metaData.attributeList;
  const attributesResponse = metaData.attributesResponse;
  const attributeLoading = metaData.attributeLoading;
  const offerData = useSelector((state) => state.offerDetailsData);
  const securityData = useSelector((state) => state.securityData);

  useEffect(() => {
    if (
      filter.id === editProcess.id &&
      !filter.attributeType &&
      !attributeLoading &&
      attributesResponse &&
      filter.entity !== ENTITY_DEFAULT_VALUE &&
      filter.attr !== ATTR_DEFAULT_VALUE
    ) {
      let listFound = AttributeList.find(
        ({ entity }) => entity === filter.entity
      );
      if (!listFound) {
        dispatch(setAttributeList([...AttributeList, attributesResponse]));
      }
    }
  }, [attributesResponse]);

  useEffect(() => {
    if (editProcess.id === filter.id && editProcess.filterType === OPERATOR) {
      if (filter.attributeType) {
        if (multi_attributes.includes(filter.attr)) {
          let operatorOptions = multi_select_operator.find(
            (item) => item.attribute.toLowerCase() === filter.attr.toLowerCase()
          )?.operators;
          setOptions(operatorOptions);
        } else {
          let operatorOptions = Operator.find(
            (item) =>
              item.datatype.toLowerCase() === filter.attributeType.toLowerCase()
          )?.operators;
          setOptions(operatorOptions);
        }
      } else if (
        AttributeList.length > ZERO &&
        AttributeList.find(({ entity }) => entity === filter.entity)
      ) {
        let listFound = AttributeList.find(
          ({ entity }) => entity === filter.entity
        );
        if (listFound) {
          let attributeFound = listFound.attributes.find(
            ({ code }) => code === filter.attr
          );
          if (attributeFound) {
            let attributeType = attributeFound.type;
            if (multi_attributes.includes(filter.attr)) {
              let operatorOptions = multi_select_operator.find(
                (item) =>
                  item.attribute.toLowerCase() === filter.attr.toLowerCase()
              )?.operators;
              setOptions(operatorOptions);
            } else {
              let operatorOptions = Operator.find(
                (item) =>
                  item.datatype.toLowerCase() === attributeType.toLowerCase()
              )?.operators;
              setOptions(operatorOptions);
            }
          }
        }
      } else {
        headers.sessionId = skey();
        headers.requestAccess = getRightsFromSession();
        headers.organization = offerData?.authOrganizationName;
        const requestHeaders = {
          headers,
        };
        const requestBody = {
          messageVersion: process.env.REACT_APP_MESSAGE_VERSION_01,
          requestorID: OFRI,
          entity: filter.entity,
        };
        if (
          attributesResponse &&
          filter.entity !== ENTITY_DEFAULT_VALUE &&
          filter.attr !== ATTR_DEFAULT_VALUE
        ) {
          if (attributesResponse.entity !== filter.entity) {
            dispatch(getAttributesDetails(requestHeaders, requestBody));
          }
        } else {
          dispatch(getAttributesDetails(requestHeaders, requestBody));
        }
      }
    }
  }, [attributesResponse, editProcess, AttributeList]);

  return editProcess.status === true &&
    editProcess.id === filter.id &&
    editProcess.filterType === OPERATOR ? (
    attributeLoading ? (
      PLEASE_WAIT_MSG
    ) : (
      getDropdown(
        filter,
        editProcess.filterType,
        handleFilterChange,
        setEditProcess,
        setAttributeModal,
        options,
        setExpression,
        OPERATOR_DROPDOWN
      )
    )
  ) : filter.operator ? (
    // if operator is in read-only mode
    <motion.span
      onClick={() => {
        handleEditClick(filter.id, OPERATOR, setEditProcess, isSetDisable);
      }}
      className="square-dropdown"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      {filter.operator}
    </motion.span>
  ) : (
    <motion.span
      onClick={() => {
        handleEditClick(filter.id, OPERATOR, setEditProcess, isSetDisable);
      }}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      Select Operator
    </motion.span>
  );
}
export function InputReader({
  editProcess,
  handleEditClick,
  filter,
  handleFilterChange,
  setEditProcess,
  setExpression,
  isSetDisable,
}) {
  const dispatch = useDispatch();

  const [inputType, setInputType] = useState("");
  const [options, setOptions] = useState([]);

  const metaData = useSelector((state) => state.metaData);
  const AttributeList = metaData.attributeList;
  const attributesResponse = metaData.attributesResponse;
  const attributeLoading = metaData.attributeLoading;
  const offerData = useSelector((state) => state.offerDetailsData);
  const securityData = useSelector((state) => state.securityData);

  useEffect(() => {
    if (
      !attributeLoading &&
      attributesResponse &&
      filter.entity !== ENTITY_DEFAULT_VALUE &&
      filter.attr !== ATTR_DEFAULT_VALUE
    ) {
      let listFound = AttributeList.find(
        ({ entity }) => entity === filter.entity
      );
      if (!listFound) {
        dispatch(setAttributeList([...AttributeList, attributesResponse]));
      }
    }
  }, [attributesResponse]);

  useEffect(() => {
    if (editProcess.id === filter.id && editProcess.filterType === VALUE) {
      setInputType(filter.attributeType);
      if (filter.inputListOptions) {
        setOptions(filter.inputListOptions);
      } else if (
        AttributeList.length > ZERO &&
        AttributeList.find(({ entity }) => entity === filter.entity)
      ) {
        let listFound = AttributeList.find(
          ({ entity }) => entity === filter.entity
        );
        if (listFound) {
          let attributeFound = listFound.attributes.find(
            ({ code }) => code === filter.attr
          );
          if (attributeFound) {
            let attributeType = attributeFound.type;
            setInputType(attributeType);
            setOptions(attributeFound?.options);
          }
        }
      } else {
        headers.sessionId = skey();
        headers.requestAccess = getRightsFromSession();
        headers.organization = offerData?.authOrganizationName;
        const requestHeaders = {
          headers,
        };
        const requestBody = {
          messageVersion: process.env.REACT_APP_MESSAGE_VERSION_01,
          requestorID: OFRI,
          entity: filter.entity,
        };
        if (
          attributesResponse &&
          filter.entity !== ENTITY_DEFAULT_VALUE &&
          filter.attr !== ATTR_DEFAULT_VALUE
        ) {
          if (
            attributesResponse.entity !== filter.entity &&
            filter.attr !== MEMBERGROUP
          ) {
            dispatch(getAttributesDetails(requestHeaders, requestBody));
          }
        } else if (filter.attr !== MEMBERGROUP) {
          dispatch(getAttributesDetails(requestHeaders, requestBody));
        }
      }
    }
  }, [attributesResponse, editProcess, AttributeList]);

  return editProcess.status === true &&
    editProcess.id === filter.id &&
    editProcess.filterType === VALUE ? (
    attributeLoading ? (
      PLEASE_WAIT_MSG
    ) : (
      getInput(
        inputType.toLowerCase(),
        handleFilterChange,
        filter,
        setExpression,
        setEditProcess,
        editProcess,
        options
      )
    )
  ) : filter.value ? (
    // if Input is in read-only mode
    (() => {
      if (typeof filter.value === OBJECT && Array.isArray(filter.value)) {
        if (filter.value.length > ZERO) {
          return (
            <motion.span
              onClick={() => {
                handleEditClick(filter.id, VALUE, setEditProcess, isSetDisable);
              }}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="attribute-value-read multi"
            >
              {filter.value.map(({ code }) => code).join(", ")}
            </motion.span>
          );
        } else {
          return (
            <motion.span
              onClick={() => {
                handleEditClick(filter.id, VALUE, setEditProcess, isSetDisable);
              }}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            >
              Please enter value
            </motion.span>
          );
        }
      } else {
        return (
          <motion.span
            onClick={() => {
              handleEditClick(filter.id, VALUE, setEditProcess, isSetDisable);
            }}
            className="attribute-value-read"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            {filter.value}
          </motion.span>
        );
      }
    })()
  ) : (
    <motion.span
      onClick={() => {
        handleEditClick(filter.id, VALUE, setEditProcess, isSetDisable);
      }}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      Please enter value
    </motion.span>
  );
}
