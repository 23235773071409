import axios from "axios";
import {
  ERROR_MESSAGE,
  setBlankSession,
  setSessionExpire,
  setToaster,
} from "../reduxUtil";
import { setErrorToaster, setSuccessToaster } from "../toaster/ToasterAction";
import {
  GET_CHART_DELIVERY_STATUS_FAILURE,
  GET_CHART_DELIVERY_STATUS_REQUEST,
  GET_CHART_DELIVERY_STATUS_SUCCESS,
  GET_CHART_DELIVERY_STATUS_SUCCESS_FAILURE,
  GET_JOURNEY_DETAILS_FAILURE,
  GET_JOURNEY_DETAILS_REQUEST,
  GET_JOURNEY_DETAILS_SUCCESS,
  GET_JOURNEY_DETAILS_SUCCESS_FAILURE,
  GET_JOURNEY_METADATA_FAILURE,
  GET_JOURNEY_METADATA_REQUEST,
  GET_JOURNEY_METADATA_SUCCESS,
  GET_JOURNEY_METADATA_SUCCESS_FAILURE,
  SAVE_JOURNEY_FAILURE,
  SAVE_JOURNEY_REQUEST,
  SAVE_JOURNEY_SUCCESS,
  SAVE_JOURNEY_SUCCESS_FAILURE,
  SET_BLANK_SESSION_ID_ERROR,
  SET_CAMPAIGN_OFFER_CHANGE_STATUS,
  SET_CUSTOM_OFFER_CHANGE_STATUS,
  SET_CUSTOM_OFFER_NAME,
  SET_SESSION_TIMEOUT_POPUP,
  UPDATE_JOURNEY_STATUS_FAILURE,
  UPDATE_JOURNEY_STATUS_REQUEST,
  UPDATE_JOURNEY_STATUS_SUCCESS,
  UPDATE_JOURNEY_STATUS_SUCCESS_FAILURE,
  SAVE_WINNER_REQUEST,
  SAVE_WINNER_SUCCESS,
  SAVE_WINNER_SUCCESS_FAILURE,
  SAVE_WINNER_FAILURE,
  GET_VARIATION_STATS_SUCCESS,
  GET_VARIATION_STATS_SUCCESS_FAILURE,
  GET_VARIATION_STATS_FAILURE,
  GET_VARIATION_STATS_REQUEST,
} from "./JourneyType";
import {
  EDITABLE,
  OFFER_SAVE,
  OFFER_STATUS_UPDATE_SUCCESS_MESSAGE,
  WINNER_VARIATION_SUCCESS,
} from "../../tellurideExtComponents/errorsComponent/ErrorConstant";
import {
  OFFER_DETAILS_STATUS_ACTIVATED,
  OFFER_DETAILS_STATUS_DRAFT,
  OFRI,
} from "../../tellurideExtComponents/constantComponent/Constants";
import {
  OFFER,
  VIEW,
} from "../../tellurideExtComponents/constantComponent/PathConstants";
import { callFetchWorkFlowDetails } from "..";

export const fetchJourneyDetails = (
  requestHeaders,
  requestBody,
  redirection
) => {
  return (dispatch) => {
    dispatch(fetchJourneyDetailsRequest());
    axios
      .post(
        process.env.REACT_APP_URL_DOMAIN + process.env.REACT_APP_GET_JOURNEY,
        requestBody,
        requestHeaders
      )
      .then((resp) => {
        if (resp.data.errors !== undefined && resp.data.errors.length !== 0) {
          if (redirection.errorRedirectionPath) {
            redirection.navigate(
              "/" +
                window.location.pathname.split("/")[1] +
                redirection.errorRedirectionPath
            );
          }
          // sessionStorage.setItem("offerNumber","")
          dispatch(fetchJourneyDetailsSuccessFailure(resp.data));
          dispatch(setErrorToaster(resp.data.errors[0].message));
          setSessionExpire(resp, setSessionExpirePopUp, dispatch);
        } else {
          // sessionStorage.setItem("offerNumber",resp.data?.offerData?.offerNumber)
          dispatch(fetchJourneyDetailsSuccess(resp.data));
        }
      })
      .catch((err) => {
        if (redirection.errorRedirectionPath) {
          redirection.navigate(
            "/" +
              window.location.pathname.split("/")[1] +
              redirection.errorRedirectionPath
          );
        }
        dispatch(fetchJourneyDetailsFailure(err.message));
        setToaster(err, ERROR_MESSAGE, dispatch);
        setSessionExpire(err, setSessionExpirePopUp, dispatch);
        setBlankSession(err, dispatch, requestHeaders, setBlankSessionIdError);
      });
  };
};

const fetchJourneyDetailsRequest = () => {
  return {
    type: GET_JOURNEY_DETAILS_REQUEST,
  };
};

const fetchJourneyDetailsSuccess = (apiData) => {
  return {
    type: GET_JOURNEY_DETAILS_SUCCESS,
    payload: apiData,
  };
};

const fetchJourneyDetailsFailure = (error) => {
  return {
    type: GET_JOURNEY_DETAILS_FAILURE,
    payload: error,
  };
};
const fetchJourneyDetailsSuccessFailure = (error) => {
  return {
    type: GET_JOURNEY_DETAILS_SUCCESS_FAILURE,
    payload: error,
  };
};
export const saveJourney = (
  requestHeaders,
  requestBody,
  isShowToaster,
  isActivateTrue,
  navigate,
  getJourneyCallRequired,
  startAbTestRequestBody,
  isAbTestStart
) => {
  return async (dispatch) => {
    dispatch(saveJourneyRequest());
    try {
      const resp = await axios.post(
        process.env.REACT_APP_URL_DOMAIN + process.env.REACT_APP_SAVE_JOURNEY,
        requestBody,
        requestHeaders
      );
      if (resp.data.errors !== undefined && resp.data.errors.length !== 0) {
        dispatch(saveJourneySuccessFailure(resp.data));
        dispatch(setErrorToaster(resp.data.errors[0].message));
        setSessionExpire(resp, setSessionExpirePopUp, dispatch);
      } else {
        dispatch(saveJourneySuccess(resp.data));
        // if (isAbTestStart) {
        //   dispatch(
        //     updateJourneyStatus(
        //       requestHeaders,
        //       startAbTestRequestBody,
        //       navigate
        //     )
        //   );
        // }
        // if (isActivateTrue === true) {
        //   updateJourneyStatusHandller(
        //     OFFER_DETAILS_STATUS_ACTIVATED,
        //     requestHeaders,
        //     dispatch,
        //     requestBody?.journeyData,
        //     navigate
        //   );
        // }
        if (getJourneyCallRequired === true) {
          const offerRequestBody = {
            requestorID: OFRI,
            messageVersion: process.env.REACT_APP_MESSAGE_VERSION_03,
            journeyNumber: requestBody?.journeyData?.journeyNumber
              ? requestBody?.journeyData?.journeyNumber
              : resp?.data?.journeyNumber,
          };
          dispatch(
            fetchJourneyDetails(requestHeaders, offerRequestBody, {
              navigate: navigate,
              successRedirect: "",
              errorRedirectionPath: OFFER + VIEW,
            })
          );
        }
        if (isShowToaster) {
          dispatch(setSuccessToaster(OFFER_SAVE));
        }
      }
    } catch (err) {
      dispatch(saveJourneyFailure(err.message));
      setToaster(err, ERROR_MESSAGE, dispatch);
      setSessionExpire(err, setSessionExpirePopUp, dispatch);
      setBlankSession(err, dispatch, requestHeaders, setBlankSessionIdError);
    }
  };
};

export const saveJourneyRequest = () => {
  return {
    type: SAVE_JOURNEY_REQUEST,
  };
};

export const saveJourneySuccess = (apiData) => {
  return {
    type: SAVE_JOURNEY_SUCCESS,
    payload: apiData,
  };
};
export const saveJourneyFailure = (apiData) => {
  return {
    type: SAVE_JOURNEY_FAILURE,
    payload: apiData,
  };
};
export const saveJourneySuccessFailure = (apiData) => {
  return {
    type: SAVE_JOURNEY_SUCCESS_FAILURE,
    payload: apiData,
  };
};
export const updateJourneyStatusHandller = (
  statusValue,
  requestHeaders,
  dispatch,
  journeyData,
  navigate,
  userEmail
) => {
  const updateJourneyStatusRequestBody = {
    requestorID: OFRI,
    messageVersion: process.env.REACT_APP_MESSAGE_VERSION_03,
    modifiedBy: journeyData?.modifiedBy,
    // modifiedTS: "2023-06-27 20:44:29",
    journeyNumber: journeyData?.journeyNumber,
    journeyStatus: statusValue,
  };

  dispatch(
    updateJourneyStatus(
      requestHeaders,
      updateJourneyStatusRequestBody,
      navigate,
      journeyData
    )
  );
};
export const updateJourneyStatus = (
  requestHeaders,
  requestBody,
  journeyData,
  redirection
) => {
  return async (dispatch) => {
    dispatch(updateJourneyStatusRequest());
    try {
      const resp = await axios.post(
        process.env.REACT_APP_URL_DOMAIN +
          process.env.REACT_APP_UPDATE_JOURNEY_STATUS,
        requestBody,
        requestHeaders
      );
      const offerRequestBody = {
        requestorID: OFRI,
        messageVersion: process.env.REACT_APP_MESSAGE_VERSION_03,
        journeyNumber: requestBody?.journeyNumber
          ? requestBody?.journeyNumber
          : resp?.data?.journeyNumber,
      };

      if (resp.data.errors !== undefined && resp.data.errors.length !== 0) {
        dispatch(updateJourneyStatusSuccessFailure(resp.data));
        dispatch(setErrorToaster(resp.data.errors[0].message));
        setSessionExpire(resp, setSessionExpirePopUp, dispatch);
      } else {
        dispatch(updateJourneyStatusSuccess(resp.data));
        dispatch(
          fetchJourneyDetails(requestHeaders, offerRequestBody, {
            navigate: redirection.navigate,
            successRedirect: "",
            errorRedirectionPath: redirection.errorRedirectionPath,
          })
        );

        const successMessage =
          resp.data?.journeyStatus === OFFER_DETAILS_STATUS_DRAFT
            ? EDITABLE
            : resp.data?.journeyStatus?.toLowerCase();
        dispatch(
          setSuccessToaster(
            resp.data?.journeyStatus === OFFER_DETAILS_STATUS_DRAFT
              ? successMessage
              : OFFER_STATUS_UPDATE_SUCCESS_MESSAGE + successMessage
          )
        );
      }
    } catch (err) {
      dispatch(updateJourneyStatusFailure(err.message));
      setToaster(err, ERROR_MESSAGE, dispatch);
      //   dispatch(
      //     fetchJourneyDetails(requestHeaders, offerRequestBody, {
      //       navigate: navigate,
      //       successRedirect: "",
      //       errorRedirectionPath: OFFER + VIEW,
      //     })
      //   );
      setSessionExpire(err, setSessionExpirePopUp, dispatch);
      setBlankSession(err, dispatch, requestHeaders, setBlankSessionIdError);
    }
  };
};

export const updateJourneyStatusRequest = () => {
  return {
    type: UPDATE_JOURNEY_STATUS_REQUEST,
  };
};

export const updateJourneyStatusSuccess = (apiData) => {
  return {
    type: UPDATE_JOURNEY_STATUS_SUCCESS,
    payload: apiData,
  };
};
export const updateJourneyStatusFailure = (apiData) => {
  return {
    type: UPDATE_JOURNEY_STATUS_FAILURE,
    payload: apiData,
  };
};
export const updateJourneyStatusSuccessFailure = (apiData) => {
  return {
    type: UPDATE_JOURNEY_STATUS_SUCCESS_FAILURE,
    payload: apiData,
  };
};

export const setSessionExpirePopUp = () => {
  return {
    type: SET_SESSION_TIMEOUT_POPUP,
  };
};
export const setBlankSessionIdError = () => {
  return {
    type: SET_BLANK_SESSION_ID_ERROR,
  };
};

export const setCustomOfferChangedStatus = (status) => {
  return {
    type: SET_CUSTOM_OFFER_CHANGE_STATUS,
    payload: status,
  };
};

export const setCustomOfferNameHandler = (value) => {
  return {
    type: SET_CUSTOM_OFFER_NAME,
    payload: value,
  };
};
export const setCampaignOfferChangeStatus = (status) => {
  return {
    type: SET_CAMPAIGN_OFFER_CHANGE_STATUS,
    payload: status,
  };
};

export const saveVariationWinner = (
  requestHeaders,
  requestBody,
  navigate,
  offerNumber
) => {
  return async (dispatch) => {
    dispatch(saveVariationWinnerRequest());
    try {
      const resp = await axios.post(
        process.env.REACT_APP_URL_DOMAIN +
          process.env.REACT_APP_SAVE_VARIATION_WINNER,
        requestBody,
        requestHeaders
      );
      if (resp.data.errors !== undefined && resp.data.errors.length !== 0) {
        dispatch(saveVariationWinnerSuccessFailure(resp.data));
        dispatch(setErrorToaster(resp.data.errors[0].message));
        setSessionExpire(resp, setSessionExpirePopUp, dispatch);
      } else {
        dispatch(setSuccessToaster(WINNER_VARIATION_SUCCESS));
        dispatch(saveVariationWinnerSuccess(resp.data));
        callFetchWorkFlowDetails(
          requestHeaders,
          dispatch,
          navigate,
          "/campaign/view",
          offerNumber
        );
      }
    } catch (err) {
      dispatch(saveVariationWinnerFailure(err.message));
      setToaster(err, ERROR_MESSAGE, dispatch);
      setSessionExpire(err, setSessionExpirePopUp, dispatch);
      setBlankSession(err, dispatch, requestHeaders, setBlankSessionIdError);
    }
  };
};

export const saveVariationWinnerRequest = () => {
  return {
    type: SAVE_WINNER_REQUEST,
  };
};

export const saveVariationWinnerSuccess = (apiData) => {
  return {
    type: SAVE_WINNER_SUCCESS,
    payload: apiData,
  };
};

export const saveVariationWinnerSuccessFailure = (error) => {
  return {
    type: SAVE_WINNER_SUCCESS_FAILURE,
    payload: error,
  };
};

export const saveVariationWinnerFailure = (error) => {
  return {
    type: SAVE_WINNER_FAILURE,
    payload: error,
  };
};

export const getVariationStats = (requestHeaders, requestBody) => {
  return async (dispatch) => {
    dispatch(getVariationStatsRequest());
    try {
      const resp = await axios.post(
        process.env.REACT_APP_URL_DOMAIN +
          process.env.REACT_APP_VARIATION_STATS,
        requestBody,
        requestHeaders
      );
      if (resp.data.errors !== undefined && resp.data.errors.length !== 0) {
        dispatch(getVariationStatsSuccessFailure(resp.data));
        dispatch(setErrorToaster(resp.data.errors[0].message));
        setSessionExpire(resp, setSessionExpirePopUp, dispatch);
      } else {
        dispatch(getVariationStatsSuccess(resp.data));
      }
    } catch (err) {
      dispatch(getVariationStatsFailure(err.message));
      setToaster(err, ERROR_MESSAGE, dispatch);
      setSessionExpire(err, setSessionExpirePopUp, dispatch);
      setBlankSession(err, dispatch, requestHeaders, setBlankSessionIdError);
    }
  };
};

export const getVariationStatsRequest = () => {
  return {
    type: GET_VARIATION_STATS_REQUEST,
  };
};

export const getVariationStatsSuccess = (apiData) => {
  return {
    type: GET_VARIATION_STATS_SUCCESS,
    payload: apiData,
  };
};

export const getVariationStatsSuccessFailure = (error) => {
  return {
    type: GET_VARIATION_STATS_SUCCESS_FAILURE,
    payload: error,
  };
};

export const getVariationStatsFailure = (error) => {
  return {
    type: GET_VARIATION_STATS_FAILURE,
    payload: error,
  };
};

export const fetchJourneyExecutionStats = (requestHeaders, requestBody) => {
  return (dispatch) => {
    dispatch(fetchJourneyExecutionStatsRequest());
    axios
      .post(
        process.env.REACT_APP_URL_DOMAIN + process.env.REACT_APP_JOURNEY_STATS,
        requestBody,
        requestHeaders
      )
      .then((resp) => {
        if (resp.data.errors !== undefined && resp.data.errors.length !== 0) {
          dispatch(fetchJourneyExecutionStatsSuccessFailure(resp.data));
          dispatch(setErrorToaster(resp.data.errors[0].message));
          setSessionExpire(resp, setSessionExpirePopUp, dispatch);
        } else {
          dispatch(fetchJourneyExecutionStatsSuccess(resp.data));
        }
      })
      .catch((err) => {
        dispatch(fetchJourneyExecutionStatsFailure(err.message));
        setToaster(err, ERROR_MESSAGE, dispatch);
        setSessionExpire(err, setSessionExpirePopUp, dispatch);
        setBlankSession(err, dispatch, requestHeaders, setBlankSessionIdError);
      });
  };
};

export const fetchJourneyExecutionStatsRequest = () => {
  return {
    type: GET_CHART_DELIVERY_STATUS_REQUEST,
  };
};

export const fetchJourneyExecutionStatsSuccess = (apiData) => {
  return {
    type: GET_CHART_DELIVERY_STATUS_SUCCESS,
    payload: apiData,
  };
};

export const fetchJourneyExecutionStatsFailure = (error) => {
  return {
    type: GET_CHART_DELIVERY_STATUS_FAILURE,
    payload: error,
  };
};

export const fetchJourneyExecutionStatsSuccessFailure = (error) => {
  return {
    type: GET_CHART_DELIVERY_STATUS_SUCCESS_FAILURE,
    payload: error,
  };
};
export const getJourneyMetaData = (_requestBody, requestHeaders) => {
  return async (dispatch) => {
    dispatch(getJourneyMetaDataRequest());
    try {
      let resp = await axios.get(
        process.env.REACT_APP_URL_DOMAIN +
          process.env.REACT_APP_JOURNEY_METADATA,
        requestHeaders
      );
      if (resp.data.errors !== undefined && resp.data.errors.length !== 0) {
        dispatch(getJourneyMetaDataFailure(resp.data));
        dispatch(setErrorToaster(resp.data.errors[0].message));
        setSessionExpire(resp, setSessionExpirePopUp, dispatch);
      } else {
        dispatch(getJourneyMetaDataSuccess(resp.data));
      }
    } catch (err) {
      //showBoundary(err)
      dispatch(getJourneyMetaDataFailure(err.message));
      setToaster(err, ERROR_MESSAGE, dispatch);
      setSessionExpire(err, setSessionExpirePopUp, dispatch);
      setBlankSession(err, dispatch, requestHeaders, setBlankSessionIdError);
    }
  };
};

export const getJourneyMetaDataRequest = () => {
  return {
    type: GET_JOURNEY_METADATA_REQUEST,
  };
};

export const getJourneyMetaDataSuccess = (apiData) => {
  return {
    type: GET_JOURNEY_METADATA_SUCCESS,
    payload: apiData,
  };
};

export const getJourneyMetaDataSuccessError = (error) => {
  return {
    type: GET_JOURNEY_METADATA_SUCCESS_FAILURE,
    payload: error,
  };
};

export const getJourneyMetaDataFailure = (error) => {
  return {
    type: GET_JOURNEY_METADATA_FAILURE,
    payload: error,
  };
};
