export const AWARD_HEADER = "Custom award details";
export const FLAT = "Flat";
export const MULTIPLIER = "Multiplier";
export const OFFER_FOR = "OFFER_FOR";
export const OFFER_RANGE = "OFFER_RANGE";
export const TIER = "Tier";
export const MIN = "MIN";
export const MAX = "MAX";
export const FLAT_VALUE = "FLAT";
export const FOR_EVERY = "FOR_EVERY";
export const CAP = "CAP";
export const SPEND_THRESHOLD = "SPEND_THRESHOLD";
export const OFFER_THRESHOLD = "OFFER_THRESHOLD";
export const VALUE = "VALUE";
export const PERCENT_OFF = "PERCENT_OFF";
export const DOLLAR_OFF = "DOLLAR_OFF";
export const SPEND = "Spend";
export const ITEM = "Item";
export const QUANTITY = "quantity";
export const TRIP = "Trip";
export const THRESHOLD = "THRESHOLD";
export const TIER_UPPERCASE = "TIER";
export const DISCOUNT_TYPE = "DISCOUNT_TYPE";
export const APPLY_ON = "APPLY_ON";
export const offerRangeOptions = [
  { value: "Flat", label: "Fixed " },
  { value: "Multiplier", label: "Points Multiplier" },
];
export const offerRangeAlias = {
  Flat: "Fixed ",
  Multiplier: "Points Multiplier",
  Fixed: "Flat",
  "Points Multiplier": "Multiplier",
};
export const offerForOptions = [
  { value: "Spend", label: "Spend" },
  { value: "Quantity", label: "Purchase Quantity" },
  { value: "Trip", label: "Transaction" },
];
export const discountTypeOptions = [
  { value: "PERCENT_OFF", label: "Percent Off" },
  { value: "DOLLAR_OFF", label: "Dollar Off" },
];
export const discountTypeAlias = {
  PERCENT_OFF: "Percent Off",
  DOLLAR_OFF: "Dollar Off",
};
export const offerForAlias = {
  Spend: "Spend",
  Quantity: "Purchase Quantity",
  Trip: "Transaction",
  "Purchase Quantity": "Quantity",
  Transaction: "Trip",
};
export const applyOnOptions = [
  { value: "ALL_ITEMS", label: "Entire Transaction" },
  { value: "PER_ITEM", label: "Per Item" },
];
export const applyOnAlias = {
  ALL_ITEMS: "Entire Transaction",
  PER_ITEM: "Per Item",
};
export const thresholdOptions = [
  { value: "spend_excred", label: "Spend" },
  { value: "quantity", label: "Purchase Quantity" },
];
export const thresholdAlias = {
  spend_excred: "Spend",
  quantity: "Purchase Quantity",
};

export const SPENT_OFFER_FOR = "Spend";
export const thresholdLabel = {
  Spend: "Spend",
  Quantity: "Purchase Quantity",
  Trip: "Transaction Count",
};

export const infoOfferFor = {
  Spend:
    "Offer will be awarded when at least this amount is spent on a transaction (before taxes)",
  Quantity:
    "Offer will be awarded when at least this number of items are purchased",
  Trip: "Offer will be awarded when this number of transactions is reached",
};
export const DISCOUNT_TITLE = "How much discount";
export const DISCOUNT_TYPE_TITLE = "Discount Type";
export const THRESHOLD_TYPE_TITLE = "Event Type";
export const APPLY_ON_TITLE = "Apply on";
