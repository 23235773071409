import React, { useEffect, useState } from "react";
import "./CampaignDashboardWrapper.style.scss";
import GenericTable from "../../utilsComponent/TableUtil/GenericTable";
import {
	DASHBOARD_HEADER,
	DASHBOARD_HEADER_DESCRIPTION,
	CAMPAIGN_DASHBOARD_COLUMNS,
	CAMPAIGN_DASHBOARD_LIST_REQ_BODY_KEY,
	CAMPAIGN_DASHBOARD_LIST_RES_BODY_KEY,
	CAMPAIGN_DASHBOARD_HEADER_TITLE,
	CAMPAIGN_DASHBOARD,
	CAMPAIGN_STATUS_STAT_CHART,
	DELIVERY_STATUS_STAT_CHART,
	ENGAGEMENT_STATUS_STAT_CHART,
	CAMPAIGN_TYPE,
} from "./CampaignDashboardConstant";

import { useNavigate } from "react-router-dom";
import { CAMPAIGN, DETAILS, VIEW } from "../../constantComponent/PathConstants";
import { useDispatch, useSelector } from "react-redux";

import BarChart from "../../utilsComponent/Chart/BarChart";
import {
	CAMPAIGN_BAR_CHART_CAMPAIGN_STATUS_DATA,
	CAMPAIGN_BAR_CHART_CAMPAIGN_STATUS_OPTIONS,
	CAMPAIGN_BAR_CHART_DELIVERY_STATUS_OPTIONS,
	CAMPAIGN_BAR_CHART_DELIVERY_STATUS_DATA,
	CAMPAIGN_BAR_CHART_ENGAGEMENT_STATUS_DATA,
	CAMPAIGN_BAR_CHART_ENGAGEMENT_STATUS_OPTIONS,
} from "../../constantComponent/ChartConstants";
import {
	dateRangeOptions,
	dateRangeDefaultValue,
} from "../../constantComponent/ChartConstants";
import "../../../tellurideExtAssets/styles/reactDatePicker.style.scss";
import "../../../tellurideExtAssets/styles/reactCalendar.style.scss";
import "../../utilsComponent/TableUtil/Table.style.scss";
import { DARK, headers, REDUX, TYPE } from "../../constantComponent/Constants";
import moment from "moment";
import Select from "react-select";
import { getCustomStyles } from "./CampaignDashboardUtil";
import { useTransition } from "react";
import { Chart } from "chart.js";
import { fetchChartCampaignTotalStats } from "../../../tellurideExtRedux";
import {
	checkRightsIsEditable,
	checkRightsIsVisible,
	formatDate,
	getRightsFromSession,
	getRightsKey,
	skey,
} from "../../utilsComponent/CommonUtil";
import { images } from "../../imagesComponent/Images";
import { tickIcon } from "../../utilsComponent/SidebarIcons";
import { fetchJourneyExecutionStats } from "../../../tellurideExtRedux/journey/JourneyAction";
import { convertUTCDateToLocalDate } from "../../offerSetupComponent/offerDetailsComponent/OfferDetailsUtil";
import { getDefaultFilter } from "../../utilsComponent/TableUtil/GenericTableUtil";
import { STATUS } from "../../utilsComponent/TableUtil/GenericTableConstants";

const CampaignDashboardWrapper = ({ setShowCampaignCreatePopup }) => {
	const navigate = useNavigate();
	const viewhandller = () => {
		navigate("/" + window.location.pathname.split("/")[1] + CAMPAIGN + VIEW);
	};

	const createOfferhandller = () => {
		setShowCampaignCreatePopup(true);
	};
	const campaignDetails = useSelector((state) => state.campaignDetailsData);
	const journeyDetailsData = useSelector((state) => state.journeyDetailsData);

	const [dateDuration, setDateDuration] = useState(7);
	const dispatch = useDispatch();
	const offerData = useSelector((state) => state.offerDetailsData);
	headers.sessionId = skey();
	headers.requestAccess = getRightsFromSession();
	headers.organization = offerData?.authOrganizationName;
	const requestHeaders = {
		headers,
	};
	const totalCampaignStatsRequestBody = {
		startDate: "",
		endDate: "",
		requestorID: "OFRI",
		messageVersion: process.env.REACT_APP_MESSAGE_VERSION_03,
	};
	const campaignExecutionStatsRequestBody = {
		cmpStartDate: "",
		cmpEndDate: "",
	};

	//theme
	const toasterData = useSelector((state) => state.toasterData);
	const [theme, setTheme] = useState(toasterData.isDarkTheme);
	useEffect(() => {
		setTheme(toasterData.isDarkTheme);
	}, [toasterData]);
	//theme
	function updateDateDiff({ value }) {
		setDateDuration(value);
	}
	useEffect(() => {
		const campaignData = campaignDetails.campaignStatusChartDetails.summary;
		var statusDateList = [];
		var statusActiveList = [];
		var statusDraftList = [];
		var statusCompletedList = [];
		if (campaignData != undefined) {
			campaignData.forEach((statusElement) => {
				let tmpDateBar = new Date(statusElement.date);
				statusDateList.push(
					tmpDateBar.getMonth() + 1 + "/" + tmpDateBar.getDate()
				);
				statusActiveList.push(statusElement.active);
				statusDraftList.push(statusElement.inProgress);
				statusCompletedList.push(statusElement.expired);
			});

			CAMPAIGN_BAR_CHART_CAMPAIGN_STATUS_DATA.labels = statusDateList;

			CAMPAIGN_BAR_CHART_CAMPAIGN_STATUS_DATA.datasets.forEach((barDataSet) => {
				if (barDataSet.label == "Total Draft") {
					barDataSet.data = statusDraftList;
					barDataSet.backgroundColor = theme === DARK ? "#6979A1" : "#6979A1";
				} else if (barDataSet.label == "Total Active") {
					barDataSet.data = statusActiveList;
					barDataSet.backgroundColor = theme === DARK ? "#3B8AC3" : "#3B8AC3";
				} else if (barDataSet.label == "Completed") {
					barDataSet.data = statusCompletedList;
					barDataSet.backgroundColor = theme === DARK ? "#86E8FE" : "#86E8FE";
				}
			});
			var campaignStatusStatChart = Chart.getChart(CAMPAIGN_STATUS_STAT_CHART);
			campaignStatusStatChart.data = CAMPAIGN_BAR_CHART_CAMPAIGN_STATUS_DATA;
			campaignStatusStatChart.update();

			showHideSpinDiv(CAMPAIGN_STATUS_STAT_CHART, false);
		} else {
			// showHideSpinDiv(CAMPAIGN_STATUS_STAT_CHART, false);
			// showHideEmptyData(CAMPAIGN_STATUS_STAT_CHART, true);
		}
		// DELIVERY
		var deliveryData =
			journeyDetailsData?.deliveryStatusChartDetails?.campaigns;
		var statusDeliveryDateList = [];
		var statusSentList = [];
		var statusDeliveredList = [];
		if (deliveryData != undefined) {
			deliveryData.forEach((statusElement) => {
				let tmpDateBar = new Date(statusElement.date);
				statusDeliveryDateList.push(
					tmpDateBar.getMonth() + 1 + "/" + tmpDateBar.getDate()
				);
				statusSentList.push(statusElement.sent);
				statusDeliveredList.push(statusElement.delivered);
			});

			CAMPAIGN_BAR_CHART_DELIVERY_STATUS_DATA.labels = statusDeliveryDateList;

			CAMPAIGN_BAR_CHART_DELIVERY_STATUS_DATA.datasets.forEach((barDataSet) => {
				if (barDataSet.label == "Sent") {
					barDataSet.data = statusSentList;
					barDataSet.backgroundColor = theme === DARK ? "#0275FC" : "#0275FC";
				} else if (barDataSet.label == "Delivered") {
					barDataSet.data = statusDeliveredList;
					barDataSet.backgroundColor = theme === DARK ? "#38B435" : "#38B435";
				}
			});
			var offerStatusStatChart = Chart.getChart(DELIVERY_STATUS_STAT_CHART);
			offerStatusStatChart.data = CAMPAIGN_BAR_CHART_DELIVERY_STATUS_DATA;
			offerStatusStatChart.update();

			showHideSpinDiv(DELIVERY_STATUS_STAT_CHART, false);
			var statusImpressionList = [];
			var statusClicksList = [];
			var statusEngagementDateList = [];
			var engagementData =
				journeyDetailsData?.deliveryStatusChartDetails?.campaigns;
			if (engagementData != undefined) {
				engagementData.forEach((statusElement) => {
					let tmpDateBar = new Date(statusElement.date);
					statusEngagementDateList.push(
						tmpDateBar.getMonth() + 1 + "/" + tmpDateBar.getDate()
					);
					statusImpressionList.push(statusElement.impression);
					statusClicksList.push(statusElement.click);
				});
				CAMPAIGN_BAR_CHART_ENGAGEMENT_STATUS_DATA.labels =
					statusEngagementDateList;
				CAMPAIGN_BAR_CHART_ENGAGEMENT_STATUS_DATA.datasets.forEach(
					(barDataSet) => {
						if (barDataSet.label == "Opened") {
							barDataSet.data = statusImpressionList;
							barDataSet.backgroundColor =
								theme === DARK ? "#1033E9" : "#1033E9";
						} else if (barDataSet.label == "Clicked") {
							barDataSet.data = statusClicksList;
							barDataSet.backgroundColor =
								theme === DARK ? "#D43E3E" : "#D43E3E";
						}
					}
				);
				let engagementStatChart = Chart.getChart(ENGAGEMENT_STATUS_STAT_CHART);
				engagementStatChart.data = CAMPAIGN_BAR_CHART_ENGAGEMENT_STATUS_DATA;
				engagementStatChart.update();
				showHideSpinDiv(ENGAGEMENT_STATUS_STAT_CHART, false);
			}
		}
	}, [
		campaignDetails?.campaignStatusChartDetails,
		journeyDetailsData?.deliveryStatusChartDetails,
		theme,
	]);

	useEffect(() => {
		let today = new Date();
		let startDate = new Date(
			new Date(new Date()).setDate(today.getDate() - (dateDuration - 1))
		);
		totalCampaignStatsRequestBody.startDate = formatDate(startDate); //formatDate(startDate.toLocaleDateString());
		totalCampaignStatsRequestBody.endDate = formatDate(today);
		totalCampaignStatsRequestBody.offerSubType = ["CMP-BATCH", "CMP-EVENT"];
		totalCampaignStatsRequestBody.offerType = ["EVENTPROC"];
		dispatch(
			fetchChartCampaignTotalStats(
				requestHeaders,
				totalCampaignStatsRequestBody
			)
		);
		showHideSpinDiv(CAMPAIGN_STATUS_STAT_CHART, true);
		campaignExecutionStatsRequestBody.startDate = formatDate(startDate); //formatDate(startDate.toLocaleDateString());
		campaignExecutionStatsRequestBody.endDate = formatDate(today);
		dispatch(
			fetchJourneyExecutionStats(
				requestHeaders,
				campaignExecutionStatsRequestBody
			)
		);
		showHideSpinDiv(DELIVERY_STATUS_STAT_CHART, true);
		showHideSpinDiv(ENGAGEMENT_STATUS_STAT_CHART, true);
	}, [dateDuration]);

	function showHideEmptyData(graphId, show) {
		if (show === true) {
			let barChartSpinDiv = document.getElementById(
				graphId + "barChartSpinDiv"
			);
			let barChartDiv = document.getElementById(graphId + "barChartDiv");
			barChartSpinDiv.style.display = "none";
			barChartDiv.style.display = "none";
			let barChartEmptyDataDiv = document.getElementById(graphId + "emptyData");
			barChartEmptyDataDiv.style.display = "block";
		} else {
			let barChartEmptyDataDiv = document.getElementById(graphId + "emptyData");
			barChartEmptyDataDiv.style.display = "none";
		}
	}
	function showHideSpinDiv(graphId, show) {
		if (show === true) {
			let barChartSpinDiv = document.getElementById(
				graphId + "barChartSpinDiv"
			);
			let barChartDiv = document.getElementById(graphId + "barChartDiv");
			barChartSpinDiv.style.display = "block";
			barChartDiv.style.display = "none";
		} else {
			let barChartSpinDiv = document.getElementById(
				graphId + "barChartSpinDiv"
			);
			let barChartDiv = document.getElementById(graphId + "barChartDiv");
			barChartSpinDiv.style.display = "none";
			barChartDiv.style.display = "block";
		}
	}

	useEffect(() => {
		if (campaignDetails.campaignStatusChartDetailsError) {
			showHideEmptyData(CAMPAIGN_STATUS_STAT_CHART, true);
		} else {
			showHideEmptyData(CAMPAIGN_STATUS_STAT_CHART, false);
		}
		if (campaignDetails.deliveryStatusChartDetailsError) {
			showHideEmptyData(DELIVERY_STATUS_STAT_CHART, true);
			showHideEmptyData(ENGAGEMENT_STATUS_STAT_CHART, true);
		} else {
			showHideEmptyData(DELIVERY_STATUS_STAT_CHART, false);
			showHideEmptyData(ENGAGEMENT_STATUS_STAT_CHART, false);
		}
	}, [
		campaignDetails.campaignStatusChartDetailsError,
		campaignDetails.deliveryStatusChartDetailsError,
	]);
	useEffect(() => {
		let innerSectionWrapper = document.querySelector(".inner-section-wrapper");
		if (innerSectionWrapper) {
			innerSectionWrapper.style.minWidth = "1000px";
		}
		return () => {
			if (innerSectionWrapper) {
				innerSectionWrapper.style.minWidth = "550px";
			}
		};
	}, []);
	const [, startTransition] = useTransition();

	function handleRowClick(row) {
		navigate({
			pathname:
				"/" + window.location.pathname.split("/")[1] + "/campaign" + DETAILS,
			search: `?offerid=${row.offer_code}`,
		});
	}
	function generateCustomRequestBody(tableReqBody) {
		const today = formatDate(convertUTCDateToLocalDate(Date.now()));
		const calulatedDate = formatDate(
			convertUTCDateToLocalDate(
				new Date(Date.now() - parseInt(dateDuration) * 24 * 60 * 60 * 1000)
			)
		);
		let requestBody = {
			...tableReqBody,
			endDate: today,
			startDate: dateDuration ? calulatedDate : today,
			[CAMPAIGN_DASHBOARD_LIST_REQ_BODY_KEY]: {
				...tableReqBody[CAMPAIGN_DASHBOARD_LIST_REQ_BODY_KEY],
				offerStatus: tableReqBody?.filter?.offerStatus
					? tableReqBody?.filter?.offerStatus
					: getDefaultFilter(CAMPAIGN_DASHBOARD_COLUMNS, STATUS),
				offerType: getDefaultFilter(CAMPAIGN_DASHBOARD_COLUMNS, CAMPAIGN_TYPE),
			},
		};

		return requestBody;
	}

	return (
		<div className="campaign-dashboardWrapper">
			<div className="welcome-message-container">
				{/* <img src={images.campaignBanner}></img> */}
				<h5 className="welcome-message-header">{DASHBOARD_HEADER} </h5>
				<div className="message-text">{DASHBOARD_HEADER_DESCRIPTION}</div>
				{/* <div className="tick-messages">
					<div className="tick-message">
						<div className="tick">{tickIcon()}</div>
						<div className="tick-text">Create configurable journeys</div>
					</div>
					<div className="tick-message">
						<div className="tick">{tickIcon()}</div>
						<div className="tick-text">Ability to target segments</div>
					</div>
					<div className="tick-message">
						<div className="tick">{tickIcon()}</div>
						<div className="tick-text">Ability to link with Reward Offers</div>
					</div>
				</div> */}
				<div className="message-button">
					{checkRightsIsVisible(
						getRightsKey(["button", "campaign", "dashboard", "createcampaign"])
					) ? (
						<button
							onClick={() => {
								if (
									!checkRightsIsEditable(
										getRightsKey([
											"button",
											"campaign",
											"dashboard",
											"createcampaign",
										])
									)
								) {
									return;
								}
								createOfferhandller();
							}}
							className="btn btn-primary message-button-create-Offer"
							disabled={
								!checkRightsIsEditable(
									getRightsKey([
										"button",
										"campaign",
										"dashboard",
										"createcampaign",
									])
								)
							}
						>
							Create Campaign
						</button>
					) : null}
					{checkRightsIsVisible(
						getRightsKey(["link", "campaign", "dashboard", "viewcampaigns"])
					) ? (
						<a onClick={viewhandller} className="message-link-create-Offer">
							View Campaigns
						</a>
					) : null}
				</div>
			</div>

			<div className="dashboard_chart_date_button_container spend-section-fieldset">
				<fieldset className="selector-fieldset reward-fieldset dropdown">
					<legend>Date Range</legend>
					<Select
						defaultValue={dateRangeDefaultValue}
						name="DATE_RANGE"
						options={dateRangeOptions}
						onChange={(value) => {
							startTransition(() => {
								updateDateDiff(value);
							});
						}}
						styles={getCustomStyles(theme)}
						isSearchable={false}
					/>
				</fieldset>
			</div>

			<div className="dashboard_chart_container">
				{/* Campaign */}
				<BarChart
					data={CAMPAIGN_BAR_CHART_CAMPAIGN_STATUS_DATA}
					option={CAMPAIGN_BAR_CHART_CAMPAIGN_STATUS_OPTIONS}
					name="campaign"
					id={CAMPAIGN_STATUS_STAT_CHART}
				/>
				{/* Delivery */}
				<BarChart
					data={CAMPAIGN_BAR_CHART_DELIVERY_STATUS_DATA}
					option={CAMPAIGN_BAR_CHART_DELIVERY_STATUS_OPTIONS}
					name="delivery"
					id={DELIVERY_STATUS_STAT_CHART}
				/>
				{/* Engagement */}
				<BarChart
					data={CAMPAIGN_BAR_CHART_ENGAGEMENT_STATUS_DATA}
					option={CAMPAIGN_BAR_CHART_ENGAGEMENT_STATUS_OPTIONS}
					name="engagement"
					id={ENGAGEMENT_STATUS_STAT_CHART}
				/>
			</div>

			<GenericTable
				COLUMNS={CAMPAIGN_DASHBOARD_COLUMNS}
				SHOW_HEADER={false}
				API_URL={process.env.REACT_APP_JOURNEY_PERFORMANCE_STATS}
				TABLE_SRC={CAMPAIGN_DASHBOARD}
				SHOW_HEADER_DASHBOARD={true}
				HEADER_TITLE={CAMPAIGN_DASHBOARD_HEADER_TITLE}
				dateDuration={dateDuration}
				SHOW_TOOLTIP={true}
				ROW_CLICKABLE={true}
				ROW_CLICK_HANDLER={handleRowClick}
				REQUEST_BODY_KEY={CAMPAIGN_DASHBOARD_LIST_REQ_BODY_KEY}
				RESPONSE_BODY_KEY={CAMPAIGN_DASHBOARD_LIST_RES_BODY_KEY}
				IS_SEARCH_ALLOWED={false}
				CSTM_REQ_BODY={generateCustomRequestBody}
				SAVE_SEARCHED_FILTER={{
					isSaved: true, //  true || false => default : true
					storage: REDUX, // redux  || local || session  => default : "redux"
				}}
			/>
		</div>
	);
};

export default CampaignDashboardWrapper;
