import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import "./FilesDragAndDrop.style.scss";
import { useEffect } from "react";
import { images } from "../../imagesComponent/Images";
import { bytesToMB, getExtensionOfFile } from "../CommonUtil";
function FilesDragAndDrop({
  onUpload,
  isSetDisable,
  uploadText,
  additionalText,
  acceptFileTypes,
  sourceComponent,
  title,
  name,
  maxFileSize,
  required = false,
}) {
  const drop = React.useRef(null);
  const drag = React.useRef(null);
  const [dragging, setDragging] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const allowedExtentions = acceptFileTypes
    .split(",")
    .map((ext) => ext.trim())
    .map((ext) => ext.slice(1));
  useEffect(() => {
    if (!isSetDisable) {
      drop.current.addEventListener("dragover", handleDragOver);
      drop.current.addEventListener("drop", handleDrop);
      drop.current.addEventListener("dragenter", handleDragEnter);
      drop.current.addEventListener("dragleave", handleDragLeave);
    }
    return () => {
      if (drop.current === "function" && isSetDisable === false) {
        drop.current.removeEventListener("dragover", handleDragOver);
        drop.current.removeEventListener("drop", handleDrop);
        drop.current.removeEventListener("dragenter", handleDragEnter);
        drop.current.removeEventListener("dragleave", handleDragLeave);
      }
    };
  }, [isSetDisable]);

  const handleErrorMessage = (msg) => {
    setErrorMessage(msg);
    setTimeout(() => {
      setErrorMessage("");
    }, 3000);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };
  const handleDrop = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    const { files } = e.dataTransfer;
    let extension = getExtensionOfFile(files);

    if (files && files.length) {
      if (!allowedExtentions.some((ele) => ele === extension)) {
        setDragging(false);
        handleErrorMessage("Unsupported File");
        return;
      }
      if (maxFileSize && files[0].size > maxFileSize) {
        setDragging(false);
        handleErrorMessage(
          `File size should be less than ${bytesToMB(maxFileSize)} MB`
        );
        return;
      }
      setUploadedFiles((prevState) => [...prevState, files[0]]);
      onUpload(files, e.target.getAttribute("name"));
    }

    setDragging(false);
  }, []);

  const handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (e.target !== drag.current) {
      setDragging(true);
    }
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (e.target === drag.current) {
      setDragging(false);
    }
  };

  const onFileBrowsed = (e) => {
    let files = e.target.files;
    let extension = getExtensionOfFile(files);
    if (!allowedExtentions.some((ele) => ele === extension)) {
      handleErrorMessage("Unsupported File");
      return;
    }
    if (maxFileSize && files[0].size > maxFileSize) {
      handleErrorMessage(
        `File size should be less than ${bytesToMB(maxFileSize)} MB`
      );
      return;
    }
    setUploadedFiles((prevState) => [...prevState, files[0]]);
    onUpload(files, e.target.name);
  };
  return (
    <div
      ref={drop}
      className={`FilesDragAndDrop ${isSetDisable && "disabled"}`}
    >
      {!isSetDisable
        ? dragging && (
            <div
              ref={drag}
              className="FilesDragAndDrop__placeholder"
              name={name}
            >
              Drop file here
            </div>
          )
        : null}
      <div className="FilesDragAndDrop__area">
        {title && (
          <span className={`file-upload-title ${required ? "required" : null}`}>
            {title}
          </span>
        )}
        <img src={images.uploadIcon} className="cloud-upload-icon" />
        <p className="flex center upload-text" draggable="false">
          <span>{uploadText} or&nbsp;</span>
          <label
            htmlFor={`files-${name}`}
            className={`btn browse ${isSetDisable && "disabled"}`}
          >
            Browse
          </label>
          <input
            id={`files-${name}`}
            type="file"
            className="hidden"
            accept={acceptFileTypes}
            onChange={(e) => {
              if (!isSetDisable) {
                onFileBrowsed(e);
              }
            }}
            name={name}
            disabled={isSetDisable ? true : false}
          />
        </p>
        {additionalText && <p className="mt-7 fs-13">{additionalText}</p>}
        <p className="error-size-message">{errorMessage}</p>
      </div>
    </div>
  );
}

FilesDragAndDrop.propTypes = {
  onUpload: PropTypes.func.isRequired,
};

export default React.memo(FilesDragAndDrop);
