import React, { useEffect, useState } from "react";
import "./OfferReviewPopUpComponent.style.scss";
import {
  BACK,
  PROCEED,
} from "../../../../campaignSetupComponent/campaignBuilderComponent/CampaignBuilderConstants";
import OfferSetupPopUpComponent from "../offerSetupPopupComponent/OfferSetupPopUpComponent";
import { useDispatch, useSelector } from "react-redux";
import {
  ACTIVATE,
  DATE,
  LOYALTY_CASH,
  NON_PURCHASE,
  OFFER_DETAILS_STATUS_ACTIVATED,
  OFFER_DETAILS_STATUS_DEACTIVATED,
  OFFER_DETAILS_STATUS_DRAFT,
  OFRI,
  headers,
} from "../../../../constantComponent/Constants";
import { images } from "../../../../imagesComponent/Images";
import DatePicker from "react-date-picker";
import moment from "moment";
import { NumericFormat } from "react-number-format";
import { getRightsFromSession, skey } from "../../../../utilsComponent/CommonUtil";
import {
  fetchOfferDetails,
  updateOfferStatus,
} from "../../../../../tellurideExtRedux";
import { useNavigate } from "react-router-dom";
import { OFFER, VIEW_OFFER } from "../../../../constantComponent/PathConstants";
import {
  OFFER_LOADER_SOURCE,
  OFFER_REVIEW_LOADER_SOURCE,
} from "../../../../LoaderComponents/LoadingConstant";
import Loading from "../../../../LoaderComponents/Loading";
import { RESPONSE_STATUS_SUCCESS } from "../../../offerSetupConstant";
import { updateNodeData } from "../../../../utilsComponent/GenericFlow/GenericFlowUtil";
import { set } from "lodash";
import OfferSelectionPopupComponent from "../offerSelectionPopupComponent/OfferSelectionPopupComponent";
import { convertDateFormat, getDateString } from "./offerReviewPopUpUtiils";
import { useAuth0 } from "@auth0/auth0-react";
import {
  miniloading,
  miniloadingWithoutMessage,
} from "../../../../LoaderComponents/loadingDesignConstant";
import OfferRegistration from "./OfferRegistration";
import { TIER, VALUE } from "../../../rewardComponent/RewardConstant";
import { nodeTypeByOfferType } from "./OfferReviewConstants";
const regex = new RegExp("\\d{1,2}\\/\\d{1,2}\\/\\d{2,4}");
const OfferReviewPopUpComponent = ({
  theme,
  setPopupShowProcess,
  currentClickedNode,
  setNodeData,
  offerType,
  offerNumber,
  isDisabled,
  SourceComponent,
  ...rest
}) => {
  const offerDetailsPageData = useSelector((state) => state.offerDetailsData);
  const journeyDetailsData = useSelector((state) => state.journeyDetailsData);
  const securityData = useSelector((state) => state.securityData);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useAuth0();
  const [offerDisplayData, setOfferDisplayData] = useState({});
  const [updatedOfferStatus, setUpdatedOfferStatus] = useState("");
  const [selectedData, setSelectedData] = useState({});

  const [pointsAward, setPointsAward] = useState(
    currentClickedNode?.data?.value?.action?.pointsAward
      ? currentClickedNode?.data?.value?.action?.pointsAward
      : "0"
  );
  useEffect(() => {
    let pointAwardDefault = null;
    offerDetailsPageData?.offerDetailsResponse?.actions?.action?.forEach(
      (action) => {
        action?.actionProperties?.actionProperty?.forEach((actionProperty) => {
          if (actionProperty?.propertyType === "TIER") {
            actionProperty?.property?.forEach((prop) => {
              if (prop?.name === "VALUE") {
                pointAwardDefault = prop?.values?.value[0];
              }
            });
          }
        });
      }
    );
    setPointsAward(pointAwardDefault);
  }, [offerDetailsPageData]);
  useEffect(() => {
    headers.sessionId = skey();
    headers.requestAccess = getRightsFromSession();
    headers.organization = offerDetailsPageData?.authOrganizationName;
    const requestHeaders = {
      headers,
    };
    const offerRequestBody = {
      requestorID: OFRI,
      messageVersion: process.env.REACT_APP_MESSAGE_VERSION_03,
      offerNumber: currentClickedNode?.data?.value?.action?.offerCode
        ? currentClickedNode?.data?.value?.action?.offerCode
        : offerNumber
        ? offerNumber
        : "",
    };
    dispatch(
      fetchOfferDetails(requestHeaders, offerRequestBody, {
        navigate: navigate,
        successRedirect: "",
        errorRedirectionPath: OFFER + VIEW_OFFER,
      })
    );
  }, []);
  useEffect(() => {
    if (
      offerType === NON_PURCHASE ||
      currentClickedNode?.data?.value?.action?.offerType === NON_PURCHASE
    ) {
      const pointData = {
        displayData: {
          offerType: offerType
            ? offerType
            : currentClickedNode?.data?.value?.action?.offerType
            ? currentClickedNode?.data?.value?.action.offerType
            : "",
          offerCode: offerDisplayData?.offerID,
          awardPoints: pointsAward,
        },
        value: {
          action: {
            offerType: offerType
              ? offerType
              : currentClickedNode?.data?.value?.action?.offerType
              ? currentClickedNode?.data?.value?.action.offerType
              : "",
            offerCode: offerDisplayData?.offerID,
            awardPoints: pointsAward,
          },
        },
      };
      setSelectedData(pointData);
    }
  }, [pointsAward]);
  useEffect(() => {
    if (offerDetailsPageData) {
      setOfferDisplayData({
        offerID: offerDetailsPageData?.offerData?.offerNumber,
        offerType: offerDetailsPageData?.offerData?.offerType,
        offerName: offerDetailsPageData?.offerData?.offerPointsDollarName,
      });
    }
  }, [offerDetailsPageData]);
  const updateStatus = async (
    status,
    statusCall,
    isNewNodeRequired,
    editOffer
  ) => {
    headers.sessionId = skey();
    headers.requestAccess = getRightsFromSession();
    headers.organization = offerDetailsPageData?.authOrganizationName;
    const requestHeaders = {
      headers,
    };
    const updateOfferStatusRequestBody = {
      requestorID: OFRI,
      messageVersion: process.env.REACT_APP_MESSAGE_VERSION_01,
      source: "TELL",
      modifiedBy: user?.email,
      modifiedTS: "2023-06-27 20:44:29",
      offersInfo: {
        offerInfo: [
          {
            offerNumber:
              offerDetailsPageData.offerDetailsResponse.offerData.offerNumber,
            offerStatus: status,
          },
        ],
      },
      additionalAttributes: {
        additionalAttribute: [
          {
            name: "RealTimeFlag",
            values: {
              value: ["Y"],
            },
          },
        ],
      },
    };

    if (statusCall) {
      await dispatch(
        updateOfferStatus(
          requestHeaders,
          updateOfferStatusRequestBody,
          navigate,
          offerDetailsPageData.offerData
        )
      );
    }

    if (isNewNodeRequired) {
      updateNodeData(
        currentClickedNode,
        setNodeData,
        nodeTypeByOfferType[offerDisplayData?.offerType],
        setPopupShowProcess,
        selectedData
      );
    }
    if (editOffer) {
      rest.setComponentName({
        name: () => (
          <OfferSetupPopUpComponent
            theme={theme}
            currentClickedNode={currentClickedNode}
            setNodeData={setNodeData}
            setPopupShowProcess={setPopupShowProcess}
            offerType={
              offerType
                ? offerType
                : currentClickedNode?.data?.value?.action?.offerType
                ? currentClickedNode?.data?.value?.action.offerType
                : ""
            }
            setComponentName={rest.setComponentName}
            offerNumber={offerDisplayData?.offerID}
            isDisabled={isDisabled}
          />
        ),
        displayName: "offerSetup",
        style: {
          width: "44rem",
        },
      });
    }
  };
  return (
    <Loading source={OFFER_REVIEW_LOADER_SOURCE}>
      <>
        <section className="offer-review--wrapper">
          <div className="offer-review-header">
            Review the offer{" "}
            {offerDisplayData?.offerType !== NON_PURCHASE &&
              "and choose award dates"}
          </div>
          <div className="summary-container">
            <div className="sub-header">
              <p>Offer Summary</p>{" "}
              {!isDisabled && (
                <span
                  className="ml-7"
                  onClick={() => {
                    setUpdatedOfferStatus(OFFER_DETAILS_STATUS_DRAFT);
                    updateStatus(
                      OFFER_DETAILS_STATUS_DRAFT,
                      offerDetailsPageData?.offerStatus ===
                        OFFER_DETAILS_STATUS_ACTIVATED ||
                        offerDetailsPageData?.offerStatus ===
                          OFFER_DETAILS_STATUS_DEACTIVATED,
                      false,
                      true
                    );
                  }}
                >
                  {offerDetailsPageData?.updateOfferStatusLoading &&
                  updatedOfferStatus === OFFER_DETAILS_STATUS_DRAFT
                    ? miniloadingWithoutMessage()
                    : "Edit Offer"}
                </span>
              )}
              {journeyDetailsData?.updateJourneyStatus ===
                OFFER_DETAILS_STATUS_ACTIVATED && (
                <span
                  className="ml-7"
                  onClick={() => {
                    updateStatus(
                      OFFER_DETAILS_STATUS_DRAFT,
                      false,
                      false,
                      true
                    );
                  }}
                >
                  View Offer
                </span>
              )}
            </div>

            <div className="inner-section-review">
              <div className="row">
                <p>Offer Name : </p>
                <p>{offerDisplayData?.offerName}</p>
              </div>
              <div className="row">
                <p>Offer ID : </p>
                <p className="ml-28">{offerDisplayData?.offerID}</p>
              </div>
              <div className="row">
                <p>Offer Type : </p>
                <p className="ml-7">{offerDisplayData?.offerType}</p>
              </div>

              {[NON_PURCHASE].includes(offerDisplayData?.offerType) ? (
                <div className="row">
                  <p>Points Award : </p>
                  <p>{pointsAward}</p>
                </div>
              ) : null}
            </div>
          </div>
          {[LOYALTY_CASH].includes(offerDisplayData?.offerType) ? (
            <OfferRegistration
              currentClickedNode={currentClickedNode}
              offerNumber={offerNumber}
              isDisabled={isDisabled}
              offerType={offerType}
              setSelectedData={setSelectedData}
            />
          ) : null}
        </section>
        {isDisabled === false && (
          <footer className="campaign-modal-footer">
            <button
              className="btn btn-primary continue-setup-btn"
              disabled={
                selectedData?.value?.action?.registrationEndTime === "0" ||
                selectedData?.value?.action?.registrationStartTime === "0"
              }
              onClick={() => {
                setUpdatedOfferStatus(OFFER_DETAILS_STATUS_ACTIVATED);
                updateStatus(
                  OFFER_DETAILS_STATUS_ACTIVATED,
                  offerDetailsPageData?.offerStatus ===
                    OFFER_DETAILS_STATUS_DRAFT,
                  OFFER_DETAILS_STATUS_ACTIVATED,
                  true
                );
              }}
            >
              {offerDetailsPageData?.updateOfferStatusLoading &&
              updatedOfferStatus === OFFER_DETAILS_STATUS_ACTIVATED
                ? miniloading("Processing")
                : offerDetailsPageData?.offerStatus ===
                  OFFER_DETAILS_STATUS_DRAFT
                ? ACTIVATE + " and " + PROCEED
                : PROCEED}
            </button>
          </footer>
        )}
      </>
    </Loading>
  );
};

export default OfferReviewPopUpComponent;
