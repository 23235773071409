export const AWARD_HEADER = "Custom award details";
export const FLAT = "Flat";
export const MULTIPLIER = "Multiplier";
export const OFFER_FOR = "OFFER_FOR";
export const OFFER_RANGE = "OFFER_RANGE";
export const TIER = "Tier";
export const MIN = "MIN";
export const MAX = "MAX";
export const FLAT_VALUE = "FLAT";
export const FOR_EVERY = "FOR_EVERY";
export const CAP = "CAP";
export const SPEND_THRESHOLD = "SPEND_THRESHOLD";
export const OFFER_THRESHOLD = "OFFER_THRESHOLD";
export const VALUE = "VALUE";
export const SPEND = "Spend";
export const ITEM = "Item";
export const QUANTITY = "Quantity";
export const TRIP = "Trip";
export const POINTS_TITLE = "What is a Point Multiplier?";
export const DOLLAR_OFF = "DOLLAR_OFF";
export const PERCENT_OFF = "PERCENT_OFF";
export const APPLY_ON = "APPLY_ON";
export const THRESHOLD = "THRESHOLD";
export const offerRangeOptions = [
  { value: "Flat", label: "Fixed " },
  { value: "Multiplier", label: "Points Multiplier" },
];
export const offerRangeAlias = {
  Flat: "Fixed ",
  Multiplier: "Points Multiplier",
  Fixed: "Flat",
  "Points Multiplier": "Multiplier",
};
export const offerForOptions = [
  { value: "Spend", label: "Spend" },
  { value: "Quantity", label: "Purchase Quantity" },
  { value: "Trip", label: "Transaction" },
];

export const offerForOptionsMission = [
  { value: "Spend", label: "Spend" },
  { value: "Quantity", label: "Purchase Quantity" },
  { value: "Trip", label: "Trip" },
];
export const offerForOptionsPointsEarn = [
  { value: "Spend", label: "Spend" },
  { value: "Quantity", label: "Purchase Quantity" },
];

export const offerForAlias = {
  Spend: "Spend",
  Quantity: "Purchase Quantity",
  Trip: "Transaction",
  "Purchase Quantity": "Quantity",
  Transaction: "Trip",
};

export const SPENT_OFFER_FOR = "Spend";
export const thresholdLabel = {
  Spend: "Spend",
  Quantity: "Purchase Quantity",
  Trip: "Transaction Count",
};
export const thresholdLabelMission = {
  Spend: "Spend",
  Quantity: "Purchase Quantity",
  Trip: "Trip",
};
export const infoOfferFor = {
  Spend:
    "Offer will be awarded when at least this amount is spent on a transaction (before taxes)",
  Quantity:
    "Offer will be awarded when at least this number of items are purchased",
  Trip: "Offer will be awarded when this number of transactions is reached",
};

export const infoOfferRange = {
  Flat: "Fixed amount of points to be awarded after the final qualifying trip",
  Multiplier:
    "This amount of points to be awarded for eligible spend in all qualifying trips",
};
export const nonProfitPointsAwardMessage = "Points to be sent as adjustment";
export const REWARD_TITLE = "What to award";
export const OFFER_FOR_TITLE = "Event Type";
export const OFFER_RANGE_TITLE = "Points Award Type";
export const OFFER_CATEGORY = "Offer Category";
export const PROGRAM_TYPE = "Program Type";
export const LOYALTY_CASH = "LoyaltyCash";

export const promptJson = {
  SpendFlatPointsEarnWithoutMin: "$~~values back in points on purchase",
  SpendFlatPointsEarn: "$~~values back in points on purchase above $~~min",
  SpendMultiplierPointsEarn:
    "$~~values back in points per dollar spent on purchase above $~~min",
  ItemMultiplierPointsEarn:
    "$~~values back in points per dollar spent on purchase of a minimum of ~~min items",
  ItemFlatPointsEarn:
    "$~~values back in points on purchase of a minimum of ~~min items",
  SpendFlatLoyaltyCash:
    "$~~values as Loyalty Cash Redemption on a purchase above $~~min",
  ItemFlatLoyaltyCash:
    "$~~values as Loyalty Cash redemption on purchase of a minimum of ~~min items",
  ALL_ITEMSspend_excredPriceDiscount: "~~values off on a purchase above $~~min",
  ALL_ITEMSquantityPriceDiscount:
    "~~values off on a purchase with a minimum quantity of ~~min items",
  PER_ITEMspend_excredPriceDiscount:
    "~~values off on each item priced above $~~min",
  PER_ITEMquantityPriceDiscount:
    "~~values off on items with a minimum quantity of ~~min",
  TripFlatPointsEarn:
    "$~~values back in points on completing ~~min trips of $~~tripThreshold spend each",
  TripMultiplierPointsEarn:
    "$~~values back in points per dollar on completing ~~min trips of $~~tripThreshold spend each",
};
