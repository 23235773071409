import {
  CREATE_EXISTING_SUBTYPE_CONNECTOR_MESSAGE,
} from "../../constantComponent/MessageConstant";
import "../Backdrop.style.scss";
import "./CreateExistingConnectorConfirmationComponent.style.scss";
import { motion } from "framer-motion/dist/framer-motion";

const CreateExistingConnectorConfirmationComponent = (props) => {
  return (
    <>
      <div className="activate-confirmation-model">
        <div id="backdrop" className="backdrop fixed-position-backdrop show">
          <motion.div
            className="modal"
            initial={{ opacity: 0, scale: 0 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.85 }}
          >
            <section className="modal-body">
              <div className="flex center body">
                {/* to be changed */}
                <p>{CREATE_EXISTING_SUBTYPE_CONNECTOR_MESSAGE}</p>
              </div>
            </section>

            <div className="modal-footer">
              <div className="alignment">
                <button
                  className="save-button btn btn-secondary"
                  onClick={(e) => {
                    props.setShowConfirmationPopup({
                        key: "showPopup",
                        visibility: false,
                    });
                  }}
                >
                  Yes
                </button>

                <button
                  className="save-button btn btn-primary"
                  onClick={(e) => {
                    props.onNoClick(true);
                  }}
                >
                  No
                </button>
              </div>
            </div>
          </motion.div>
        </div>
      </div>
    </>
  );
};

export default CreateExistingConnectorConfirmationComponent;
