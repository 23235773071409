import { React, useState } from "react";
import "./SubclubPopupComponent.style.scss";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {
	ACTION,
	BACK,
	CREATED_BY,
	EVENT_USE_TITLE,
	MEMBER_COUNT,
	MODIFY,
	POPUP_HEADER_SEGMENT,
	PROCEED,
	SEGMENT_USE_TITLE,
	START,
} from "../../../../campaignSetupComponent/campaignBuilderComponent/CampaignBuilderConstants";
import { motion } from "framer-motion/dist/framer-motion";
import Skeleton from "react-loading-skeleton";
import { emptyData } from "../../../../constantComponent/DesignConstant";
import { headers } from "../../../../constantComponent/Constants";
import {
	getRightsFromSession,
	skey,
} from "../../../../utilsComponent/CommonUtil";
import { getSegmentsList } from "../../../../../tellurideExtRedux";
import { updateNodeData } from "../../../../utilsComponent/GenericFlow/GenericFlowUtil";
import { getSubclubList } from "../../../../../tellurideExtRedux/subclub/SubclubAction";
import SmallGenericTable from "../../../../utilsComponent/SmallGenericTable/SmallGenericTable";
import {
	popupConfig,
	subclub_columns,
	subclub_triggerPoint,
} from "../CustomOfferSetupPopupConstants";
import SubclubEventSelectionPopupComponent from "../subclubEventSelectionPopupComponent/SubclubEventSelectionPopupComponent";
const SubClubComponent = ({
	theme,
	setPopupShowProcess,
	currentClickedNode,
	setNodeData,
	isDisabled,
	setComponentName,
	// setShowEventSelection // Accept the state setter as a prop
}) => {
	const subclubData = useSelector((state) => state.subclubData);
	const securityData = useSelector((state) => state.securityData);

	const offerData = useSelector((state) => state.offerDetailsData);
	const [searchInput, setSearchInput] = useState("");
	const [triggerList, setTriggerList] = useState(null);
	const [selectedSubclub, setSelectedSubclub] = useState({
		code: currentClickedNode?.data?.value?.rules?.condition?.PASSES_ALL?.[0]
			?.value?.[0]?.code,
		name: currentClickedNode?.data?.value?.rules?.condition?.PASSES_ALL?.[0]
			?.value?.[0]?.name,
		createdBy: currentClickedNode?.data?.value?.action?.createdBy,
	});
	const [searchBody, setSearchBody] = useState("");
	const [subclubSelectionValue, setSubclubSelectionValue] = useState({});

	const dispatch = useDispatch();
	const controller = new AbortController();
	let tableLoading = subclubData?.getSubclubListLoading;
	let tableResponse = subclubData?.getSubclubListResponse;
	let tableError = subclubData?.getSubclubListError;

	useEffect(() => {
		// code: selectedSubclub.code,
		// 			name: selectedSubclub.name,
		// 			createdBy: selectedSubclub.createdBy,
		const segmentNodeData = {
			displayData: {
				name: selectedSubclub.name,
			},
			value: {
				action: {
					triggerPoint: subclub_triggerPoint,
				},
				rules: {
					type: "AdvanceRule",
					condition: {
						PASSES_ALL: [
							{
								entity: "requestParameters",
								attr: "affinities",
								operator: "IN",
								value: [
									{
										code: selectedSubclub.code,
										name: selectedSubclub.name,
									},
								],
								type: "List",
							},
							{
								entity: "requestParameters",
								attr: "eventType",
								operator: "==",
								value: "UMAS",
								type: "String",
							},
						],
					},
					ruleEntity: ["requestParameters"],
				},
			},
		};
		setSubclubSelectionValue(segmentNodeData);
	}, [selectedSubclub]);

	function handleSearchChange(e) {
		setSearchInput(e.target.value);
	}
	function handleRowClickSubclub(row) {
		if (!isDisabled) {
			setSelectedSubclub((prev) => {
				return {
					...prev,
					code: row.code,
					name: row.name,
					createdBy: row.createdBy,
				};
			});
		}
	}

	return (
		<section className="Subclub-component">
			<>
				<div
					className="search-bar-container-campaign"
					style={{
						display: "flex",
						alignItems: "center",
					}}
				>
					<form
						onSubmit={(e) => {
							e.preventDefault();
							setSearchBody(searchInput);
							setTriggerList((prev) => !Boolean(prev));
						}}
						style={{
							display: "flex",
							justifyContent: "center",
						}}
					>
						<input
							type="text"
							placeholder="Search Subclub"
							className="attribute-search"
							value={searchInput}
							onChange={handleSearchChange}
							style={{
								width: "31rem",
							}}
						/>
					</form>
				</div>

				<div className="campaign-type-section trigger-campaign mt-24">
					<SmallGenericTable
						Columns={subclub_columns}
						tableData={tableResponse?.list}
						isDataLoading={tableLoading}
						selectedData={[selectedSubclub.code]}
						isRowSelectable={true}
						isRowMultiSelectable={false}
						rowClickHandler={handleRowClickSubclub}
						config={{
							...popupConfig,
							theme: theme,
							selectedDataKey: "code",
							isDisabled: isDisabled,
							border: true,
						}}
						isShowPagination={true}
						API_URL={process.env.REACT_APP_GET_SUBCLUB_LIST}
						RESPONSE_BODY_KEY={"list"}
						smallGenericTableRquestBody={{
							requestorID: "OFRI",
							messageVersion: "01",
							sort: [],
							filter: {
								code: "",
								name: "",
								description: "",
								createdBy: "",
								searchQuery: searchInput,
							},
							code: "AFFILIATIONS",
						}}
						initialPagination={{
							recordPerPage: 10,
							currentPage: 1,
						}}
						triggerList={triggerList}
					/>
				</div>

				<footer className="campaign-modal-footer">
					{/* <button
              className="btn btn-secondary"
              onClick={() => {
                setPopupShowProcess(false);
                setNodeData(null);
              }}
            >
              {CANCEL}
            </button> */}
					{!isDisabled && (
						<button
							className="btn btn-secondary ml-10"
							onClick={() => {
								setComponentName({
									name: SubclubEventSelectionPopupComponent,
									displayName: ACTION,
								});
							}}
						>
							{BACK}
						</button>
					)}
					<button
						className="btn btn-primary continue-setup-btn"
						disabled={
							selectedSubclub.code && isDisabled === false ? false : true
						}
						onClick={() => {
							updateNodeData(
								currentClickedNode,
								setNodeData,
								"START_TRIGGER",
								setPopupShowProcess,
								subclubSelectionValue
							);
						}}
					>
						{PROCEED}
					</button>
				</footer>
			</>
		</section>
	);
};

export default SubClubComponent;
