import "./Cap.style.scss";
import { useEffect, useLayoutEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  handleEditBlur,
  getCustomStyles,
  handleEditClick,
  formatNumberCommaSeperated,
} from "../capComponent/CapUtil";
import {
  earnCapIntervalUnitOptions,
  earnCapIntervalAlias,
  MEMBER_CAP_INFO_TITLE,
  MEMBER_CAP_INFO_DETAIL,
  OFFER_CAP_INFO_TITLE,
  OFFER_CAP_INFO_DETAIL,
  MEMBER_AWARDS_POINTS,
  MEMBER_AWARDS_TIMES,
  OFFER_LEVEL_POINTS,
  OFFER_LEVEL_TIMES,
  MEMBER_TIMES_CAP,
  MEMBER_POINTS_CAP,
  DURATION_POINTS_CAP_CODE,
  DURATION_TIMES_CAP_CODE,
  DURATION_TIMES_CAP_VALUE,
  DURATION_POINTS_CAP_VALUE,
  STORE_TIMES_CAP,
  STORE_POINTS_CAP,
  DURATION_POINTS_CAP_DAYS,
  DURATION_TIMES_CAP_DAYS,
} from "../capComponent/CapConstants";
import {
  LOYALTY_CASH,
  NON_PURCHASE,
  OFFER_DETAILS_STATUS_DRAFT,
  POINTS_EARN,
} from "../../constantComponent/Constants";
import { onKeyPressAction } from "../../utilsComponent/CommonUtil";
import Select from "react-select";
import { updateCapData } from "../../../tellurideExtRedux/offerDetails/OfferDetailsAction";
import { CAP } from "../discountComponent/DiscountConstant";
import { NumericFormat } from "react-number-format";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { property } from "lodash";

const EarnOfferCap = ({ theme, disabled }) => {
  const offerData = useSelector((state) => state.offerDetailsData);
  const offerType =
    offerData.offerData.offerType === POINTS_EARN &&
    offerData.offerAttributes.offerAttribute.some(
      (attribute) => attribute.name === "DISPLAY_OFFER_TYPE"
    )
      ? offerData.offerAttributes.offerAttribute.find(
          (attribute) => attribute.name === "DISPLAY_OFFER_TYPE"
        ).values.value[0]
      : offerData.offerData.offerType;
  const memberTimesCapSection = useRef();
  const offerTimesCapSection = useRef();
  const offerPointsCapSection = useRef();
  const [showMemberCapInfo, setShowMemberCapInfo] = useState(false);
  const [showOfferCapInfo, setShowOfferCapInfo] = useState(false);

  const dispatch = useDispatch();
  const [isSetDisable, setDisable] = useState(disabled);

  //------------------------------------------------------------------------------

  const initialCapData = useSelector((state) => state.offerDetailsData.capData);
  const [capJSONData, setCapJSONData] = useState("");

  //TIMES
  const [memberLevelCapJSON, setMemberLevelCapJSON] = useState("");
  const [showMemberLevelCap, setShowMemberLevelCap] = useState("");
  const [memberTimesCap, setMemberTimesCap] = useState("0");
  const [memberTimesDurationValue, setMemberTimesDurationValue] = useState("1");
  const [memberTimesDurationCode, setMemberTimesDurationCode] =
    useState("MONTH");
  const [memberLevelCapData, setMemberLevelCapData] = useState({
    value: "points",
    label: "points",
  });
  const [memberTimesCapDurationType, setMemberTimesCapDurationType] =
    useState("entireDuration");

  //POINTS
  const [pointsLevelCapJSON, setPointsLevelCapJSON] = useState("");
  const [showPointsLevelCap, setShowPointsLevelCap] = useState("");
  const [memberPointsCap, setMemberPointsCap] = useState("0");
  const [memberPointsDurationValue, setMemberPointsDurationValue] =
    useState("1");
  const [memberPointsDurationCode, setMemberPointsDurationCode] =
    useState("MONTH");
  const [offerLevelCapData, setOfferLevelCapData] = useState({
    value: "points",
    label: "points",
  });
  const [memberPointsCapDurationType, setMemberPointsCapDurationType] =
    useState("entireDuration");

  const [editProcess, setEditProcess] = useState({
    status: false,
    name: null,
  });

  const showMemberLevelContent = (e) => {
    setShowMemberLevelCap(e.target.checked);
  };
  const showPointsLevelContent = (e) => {
    setShowPointsLevelCap(e.target.checked);
    setOfferCap("0");
  };

  const [offerCap, setOfferCap] = useState("0");

  useEffect(() => {
    if (initialCapData) {
      initialCapData.property.map((property) => {
        if (
          [DURATION_TIMES_CAP_VALUE, DURATION_POINTS_CAP_VALUE].includes(
            property.name
          )
        ) {
          setShowMemberLevelCap(true);
          if (property.name === DURATION_TIMES_CAP_VALUE) {
            setMemberTimesCap(property.values.value[0]);
          } else if (property.name === DURATION_POINTS_CAP_VALUE) {
            setMemberPointsCap(property.values.value[0]);
          }
        } else if (
          [MEMBER_TIMES_CAP, MEMBER_POINTS_CAP].includes(property.name)
        ) {
          setShowMemberLevelCap(true);
          if (property.name === MEMBER_TIMES_CAP) {
            setMemberTimesCap(property.values.value[0]);
          } else if (property.name === MEMBER_POINTS_CAP) {
            setMemberPointsCap(property.values.value[0]);
          }
        } else if (
          [DURATION_TIMES_CAP_CODE, DURATION_POINTS_CAP_CODE].includes(
            property.name
          )
        ) {
          if (property.name === DURATION_TIMES_CAP_CODE) {
            setMemberTimesDurationCode(property.values.value[0]);
          }
          if (property.name === DURATION_POINTS_CAP_CODE) {
            setMemberPointsDurationCode(property.values.value[0]);
          }
        } else if (
          [DURATION_TIMES_CAP_DAYS, DURATION_POINTS_CAP_DAYS].includes(
            property.name
          )
        ) {
          if (property.name === DURATION_TIMES_CAP_DAYS) {
            setMemberTimesDurationValue(
              property.values.value[0] === "0" ? "1" : property.values.value[0]
            );
            property.values.value[0] === "0"
              ? setMemberTimesCapDurationType("entireDuration")
              : setMemberTimesCapDurationType("specificDuration");
          }
          if (property.name === DURATION_POINTS_CAP_DAYS) {
            setMemberPointsDurationValue(
              property.values.value[0] === "0" ? "1" : property.values.value[0]
            );
            property.values.value[0] === "0"
              ? setMemberPointsCapDurationType("entireDuration")
              : setMemberPointsCapDurationType("specificDuration");
          }
        }
        if (
          [DURATION_TIMES_CAP_VALUE, DURATION_POINTS_CAP_VALUE].includes(
            property.name
          )
        ) {
          setShowMemberLevelCap(true);
        }
        // OFFER
        if (property.name === STORE_TIMES_CAP) {
          setOfferLevelCapData({ value: "times", label: "time(s)" });
        }
        if (property.name === STORE_POINTS_CAP) {
          setOfferLevelCapData({ value: "points", label: "points" });
        }

        if ([STORE_TIMES_CAP, STORE_POINTS_CAP].includes(property.name)) {
          setShowPointsLevelCap(true);
          setOfferCap(property.values.value[0]);
        }
      });
    }
  }, [initialCapData]);

  useEffect(() => {
    let newJSON = { property: [] };
    if (showMemberLevelCap) {
      // -------------------------------------------------------------------------------------------------
      // ---------------------------------------------Times-----------------------------------------------
      // -------------------------------------------------------------------------------------------------
      MEMBER_AWARDS_TIMES.map((property) => {
        if (
          property.name === DURATION_TIMES_CAP_VALUE &&
          memberTimesCapDurationType === "specificDuration" &&
          memberTimesCap !== "0"
        ) {
          property.values.value[0] = memberTimesCap;
          newJSON.property.push(property);
        }
        if (
          property.name === MEMBER_TIMES_CAP &&
          memberTimesCapDurationType === "entireDuration" &&
          memberTimesCap !== "0"
        ) {
          property.values.value[0] = memberTimesCap;
          newJSON.property.push(property);
        }
        if (
          property.name === DURATION_TIMES_CAP_CODE &&
          memberTimesDurationCode &&
          memberTimesCap !== "0" &&
          memberTimesDurationCode !== "0"
        ) {
          property.values.value[0] =
            memberTimesCapDurationType === "specificDuration"
              ? memberTimesDurationCode
              : "0";
          if (memberTimesCapDurationType === "specificDuration") {
            newJSON.property.push(property);
          }
        }
        if (
          property.name === DURATION_TIMES_CAP_DAYS &&
          memberTimesDurationValue &&
          memberTimesCap !== "0"
        ) {
          property.values.value[0] =
            memberTimesCapDurationType === "specificDuration"
              ? memberTimesDurationValue
              : "0";
          if (memberTimesCapDurationType === "specificDuration") {
            newJSON.property.push(property);
          }
        }
      });
      // Points
      MEMBER_AWARDS_POINTS.map((property) => {
        if (
          property.name === DURATION_POINTS_CAP_VALUE &&
          memberPointsCap !== "0" &&
          memberPointsCapDurationType === "specificDuration"
        ) {
          property.values.value[0] = memberPointsCap;
          newJSON.property.push(property);
        }
        if (
          property.name === MEMBER_POINTS_CAP &&
          memberPointsCap !== "0" &&
          memberPointsCapDurationType === "entireDuration"
        ) {
          property.values.value[0] = memberPointsCap;
          newJSON.property.push(property);
        }
        if (
          property.name === DURATION_POINTS_CAP_CODE &&
          memberPointsDurationCode &&
          memberPointsCap !== "0" &&
          memberPointsDurationCode !== "0"
        ) {
          property.values.value[0] =
            memberPointsCapDurationType === "specificDuration"
              ? memberPointsDurationCode
              : "0";
          if (memberPointsCapDurationType === "specificDuration") {
            newJSON.property.push(property);
          }
        }
        if (
          property.name === DURATION_POINTS_CAP_DAYS &&
          memberPointsDurationValue &&
          memberPointsCap !== "0" &&
          memberPointsDurationValue !== 0
        ) {
          property.values.value[0] =
            memberPointsCapDurationType === "specificDuration"
              ? memberPointsDurationValue
              : "0";
          if (memberPointsCapDurationType === "specificDuration") {
            newJSON.property.push(property);
          }
        }
      });
    } else if (memberPointsCap === "0" && memberTimesCap === "0") {
      newJSON.property.push();
    } else {
      newJSON.property.push();
    }
    if (offerLevelCapData.value || offerCap) {
      if (offerLevelCapData.value === "points") {
        OFFER_LEVEL_POINTS.map((property) => {
          property.values.value[0] = offerCap;
          if (offerCap !== "0") {
            newJSON.property.push(property);
          }
        });
      } else {
        OFFER_LEVEL_TIMES.map((property) => {
          property.values.value[0] = offerCap;
          if (offerCap !== "0") {
            newJSON.property.push(property);
          }
        });
      }
    }
    if (newJSON.property.length > 0) {
      newJSON.propertyType = "CAP";
      if (
        memberPointsCap === "0" &&
        memberTimesCap === "0" &&
        offerCap === "0"
      ) {
        dispatch(updateCapData(""));
      } else {
        dispatch(updateCapData(newJSON));
      }
    } else {
      dispatch(updateCapData(""));
    }
  }, [
    memberTimesCap,
    memberTimesDurationValue,
    memberTimesDurationCode,
    memberPointsCap,
    memberPointsDurationValue,
    memberPointsDurationCode,
    memberTimesCapDurationType,
    memberPointsCapDurationType,
    showMemberLevelCap,
    offerLevelCapData,
    offerCap,
  ]);

  return (
    <>
      <div className="section-top-title setup-page-section-title">
        {" "}
        What is the cap on points
      </div>
      <div className="cap-main-div">
        <div className="cap-section-inner">
          <div className="mb-16 cap-content-div">
            <div>
              <label className="content-label">Member-level caps</label>
              <label className="info_icon_display">
                <>
                  <div className="info-btn-reward">
                    <AiOutlineInfoCircle
                      className="info-icon"
                      onMouseEnter={() => {
                        setShowMemberCapInfo(true);
                      }}
                      onMouseLeave={() => {
                        setShowMemberCapInfo(false);
                      }}
                    />

                    {showMemberCapInfo && (
                      <>
                        <div
                          className="info-msg-point"
                          onMouseEnter={() => {
                            setShowMemberCapInfo(true);
                          }}
                          onMouseLeave={() => {
                            setShowMemberCapInfo(false);
                          }}
                        >
                          <div className="triangle"></div>
                          <p>{MEMBER_CAP_INFO_TITLE} </p>
                          {MEMBER_CAP_INFO_DETAIL}
                        </div>
                      </>
                    )}
                  </div>
                </>
              </label>
              <label className="switch">
                <input
                  type="checkbox"
                  onChange={showMemberLevelContent}
                  checked={showMemberLevelCap ? true : false}
                  disabled={disabled}
                />
                <span className="slider round"></span>
              </label>
            </div>
          </div>
          {showMemberLevelCap && (
            <>
              <div className="caps-wrapper" ref={memberTimesCapSection}>
                {/*--------------------------------- FOR TIMES ---------------------------------- */}
                {offerType !== LOYALTY_CASH && (
                  <div className="valid-from-section">
                    <p className="valid-from">
                      Award upto
                      <div className="input-with-error">
                        {!disabled && editProcess.name === "offer_cap" ? (
                          <NumericFormat
                            className={`text-input-number ${
                              memberTimesCap === "" ? "empty-error" : null
                            }`}
                            autoFocus="true"
                            value={memberTimesCap}
                            thousandsGroupStyle="lakh"
                            thousandSeparator=","
                            displayType="input"
                            decimalScale={0}
                            renderText={(memberTimesCap) => (
                              <b>{memberTimesCap}</b>
                            )}
                            isAllowed={(values) => {
                              const { value } = values;
                              return value <= 9999999 || value === "";
                            }}
                            onValueChange={(values, sourceInfo) => {
                              const { value } = values;
                              if (value && value !== "") {
                                setMemberTimesCap(value);
                              }
                            }}
                            onBlur={(e) => {
                              handleEditBlur(setEditProcess);
                            }}
                            onKeyPress={(e) => {
                              onKeyPressAction(e);
                            }}
                          />
                        ) : (
                          <span
                            data-name="offer_cap"
                            onClick={(e) => {
                              handleEditClick(e, setEditProcess);
                            }}
                            className={disabled ? "disable" : "editable"}
                          >
                            {formatNumberCommaSeperated(memberTimesCap)}
                          </span>
                        )}
                      </div>
                      <div className="per-member"> time(s) per Member in </div>
                      <div>
                        <div className="flex ml-5 mb-10">
                          <input
                            type="radio"
                            className={`offertype-radio-input
                          ${disabled && "disabled"}`}
                            // checked={offer.key === offerType ? true : false}
                            disabled={disabled}
                            name="timesOfferDuration"
                            value="entireDuration"
                            checked={
                              memberTimesCapDurationType === "entireDuration"
                            }
                            onChange={(e) =>
                              setMemberTimesCapDurationType(e.target.value)
                            }
                          />
                          <p>Entire Offer duration</p>
                        </div>
                        <div className="flex ml-5">
                          <input
                            type="radio"
                            className={`offertype-radio-input
                          ${disabled && "disabled"}`}
                            // checked={offer.key === offerType ? true : false}
                            disabled={disabled}
                            name="timesOfferDuration"
                            value="specificDuration"
                            checked={
                              memberTimesCapDurationType === "specificDuration"
                            }
                            onChange={(e) =>
                              setMemberTimesCapDurationType(e.target.value)
                            }
                          />
                          <div className="input-with-error flex mr-8">
                            {!disabled &&
                            editProcess.name === "points_cap_period_value" ? (
                              <>
                                <NumericFormat
                                  className={`text-input-number ${
                                    memberTimesDurationValue === ""
                                      ? "empty-error"
                                      : null
                                  }`}
                                  autoFocus="true"
                                  value={memberTimesDurationValue}
                                  thousandsGroupStyle="lakh"
                                  thousandSeparator=","
                                  displayType="input"
                                  decimalScale={0}
                                  renderText={(memberTimesDurationValue) => (
                                    <b>{memberTimesDurationValue}</b>
                                  )}
                                  isAllowed={(values) => {
                                    const { value } = values;
                                    return (
                                      (value > 0 && value <= 9999999) ||
                                      value === ""
                                    );
                                  }}
                                  onValueChange={(values, sourceInfo) => {
                                    const { value } = values;
                                    if (value && value !== "") {
                                      setMemberTimesDurationValue(value);
                                    }
                                  }}
                                  onBlur={(e) => {
                                    handleEditBlur(setEditProcess);
                                  }}
                                  onKeyPress={(e) => {
                                    onKeyPressAction(e);
                                  }}
                                  disabled={
                                    memberTimesCapDurationType !==
                                    "specificDuration"
                                  }
                                />
                              </>
                            ) : (
                              <span
                                data-name="points_cap_period_value"
                                onClick={(e) => {
                                  if (
                                    memberTimesCapDurationType ===
                                    "specificDuration"
                                  ) {
                                    handleEditClick(e, setEditProcess);
                                  }
                                }}
                                className={
                                  disabled ||
                                  memberTimesCapDurationType !==
                                    "specificDuration"
                                    ? "disable"
                                    : "editable"
                                }
                              >
                                {memberTimesDurationValue}
                              </span>
                            )}
                          </div>
                          {!disabled &&
                          editProcess.name === "member_times_period_code" ? (
                            <fieldset className="point-fieldset dropdown">
                              <Select
                                name="MEMBER_TIMES_DURATION_CODE"
                                options={earnCapIntervalUnitOptions}
                                styles={getCustomStyles(theme)}
                                // onChange={handleChangeSelect}
                                onChange={(val) => {
                                  setMemberTimesDurationCode(val.value);
                                }}
                                value={{
                                  label: earnCapIntervalAlias[
                                    memberTimesDurationCode
                                  ]
                                    ? earnCapIntervalAlias[
                                        memberTimesDurationCode
                                      ]
                                    : "Monthly",
                                  value:
                                    memberTimesDurationCode !== "QUARTER"
                                      ? memberTimesDurationCode
                                      : "MONTH",
                                }}
                                onBlur={() => {
                                  handleEditBlur(setEditProcess);
                                }}
                                defaultMenuIsOpen
                                autoFocus
                                menuPlacement="auto"
                                maxMenuHeight={150}
                                isDisabled={
                                  memberTimesCapDurationType !==
                                  "specificDuration"
                                }
                              />
                            </fieldset>
                          ) : (
                            <span
                              data-name="member_times_period_code"
                              onClick={(e) => {
                                if (
                                  memberTimesCapDurationType ===
                                  "specificDuration"
                                ) {
                                  handleEditClick(e, setEditProcess);
                                }
                              }}
                              className={
                                disabled ||
                                memberTimesCapDurationType !==
                                  "specificDuration"
                                  ? "disable mr-4"
                                  : "editable mr-4"
                              }
                            >
                              {earnCapIntervalAlias[memberTimesDurationCode]
                                ? earnCapIntervalAlias[memberTimesDurationCode]
                                : "Monthly"}
                            </span>
                          )}
                          duration.
                        </div>
                      </div>
                    </p>
                  </div>
                )}
                {/*--------------------------------- FOR POINTS ---------------------------------- */}
                <div className="valid-from-section">
                  <p className="valid-from">
                    Award upto
                    <div className="input-with-error">
                      {!disabled && editProcess.name === "member_cap_count" ? (
                        <NumericFormat
                          className={`text-input-number ${
                            memberPointsCap === "" ? "empty-error" : null
                          }`}
                          autoFocus="true"
                          value={memberPointsCap}
                          thousandsGroupStyle="lakh"
                          thousandSeparator=","
                          displayType="input"
                          decimalScale={0}
                          renderText={(memberPointsCap) => (
                            <b>{memberPointsCap}</b>
                          )}
                          isAllowed={(values) => {
                            const { value } = values;
                            return value <= 9999999 || value === "";
                          }}
                          onValueChange={(values, sourceInfo) => {
                            const { value } = values;
                            if (value && value !== "") {
                              setMemberPointsCap(value);
                            }
                          }}
                          onBlur={(e) => {
                            handleEditBlur(setEditProcess);
                          }}
                          onKeyPress={(e) => {
                            onKeyPressAction(e);
                          }}
                        />
                      ) : (
                        <span
                          data-name="member_cap_count"
                          onClick={(e) => {
                            handleEditClick(e, setEditProcess);
                          }}
                          className={disabled ? "disable" : "editable"}
                        >
                          {formatNumberCommaSeperated(memberPointsCap)}
                        </span>
                      )}
                    </div>
                    <div className="per-member"> points per Member in </div>
                    <div>
                      <div className="flex ml-5 mb-10">
                        <input
                          type="radio"
                          className={`offertype-radio-input
                          ${disabled && "disabled"}`}
                          // checked={offer.key === offerType ? true : false}
                          disabled={disabled}
                          name="offerPointsDuration"
                          value="entireDuration"
                          checked={
                            memberPointsCapDurationType === "entireDuration"
                          }
                          onChange={(e) =>
                            setMemberPointsCapDurationType(e.target.value)
                          }
                        />
                        <p>Entire Offer duration</p>
                      </div>
                      <div className="flex ml-5">
                        <input
                          type="radio"
                          className={`offertype-radio-input
                          ${disabled && "disabled"}`}
                          // checked={offer.key === offerType ? true : false}
                          disabled={disabled}
                          name="offerPointsDuration"
                          value="specificDuration"
                          checked={
                            memberPointsCapDurationType === "specificDuration"
                          }
                          onChange={(e) =>
                            setMemberPointsCapDurationType(e.target.value)
                          }
                        />
                        <div className="input-with-error flex mr-8">
                          {!disabled &&
                          editProcess.name === "member_cap_period_value" ? (
                            <>
                              <NumericFormat
                                className={`text-input-number ${
                                  memberPointsDurationValue === ""
                                    ? "empty-error"
                                    : null
                                }`}
                                autoFocus="true"
                                value={memberPointsDurationValue}
                                thousandsGroupStyle="lakh"
                                thousandSeparator=","
                                displayType="input"
                                decimalScale={0}
                                renderText={(memberPointsDurationValue) => (
                                  <b>{memberPointsDurationValue}</b>
                                )}
                                isAllowed={(values) => {
                                  const { value } = values;
                                  return (
                                    (value > 0 && value <= 9999999) ||
                                    value === ""
                                  );
                                }}
                                onValueChange={(values, sourceInfo) => {
                                  const { value } = values;
                                  if (value && value !== "") {
                                    setMemberPointsDurationValue(value);
                                  }
                                }}
                                onBlur={(e) => {
                                  handleEditBlur(setEditProcess);
                                }}
                                onKeyPress={(e) => {
                                  onKeyPressAction(e);
                                }}
                                disabled={
                                  memberPointsCapDurationType !==
                                  "specificDuration"
                                }
                              />
                            </>
                          ) : (
                            <span
                              data-name="member_cap_period_value"
                              onClick={(e) => {
                                if (
                                  memberPointsCapDurationType ===
                                  "specificDuration"
                                ) {
                                  handleEditClick(e, setEditProcess);
                                }
                              }}
                              className={
                                disabled ||
                                memberPointsCapDurationType !==
                                  "specificDuration"
                                  ? "disable"
                                  : "editable"
                              }
                            >
                              {memberPointsDurationValue}
                            </span>
                          )}
                        </div>
                        {!disabled &&
                        editProcess.name === "member_points_period_code" ? (
                          <fieldset className="point-fieldset dropdown">
                            <Select
                              name="MEMBER_TIMES_DURATION_CODE"
                              options={earnCapIntervalUnitOptions}
                              styles={getCustomStyles(theme)}
                              // onChange={handleChangeSelect}
                              onChange={(val) => {
                                setMemberPointsDurationCode(val.value);
                              }}
                              value={{
                                label: earnCapIntervalAlias[
                                  memberPointsDurationCode
                                ]
                                  ? earnCapIntervalAlias[
                                      memberPointsDurationCode
                                    ]
                                  : "Monthly",
                                value:
                                  memberPointsDurationCode !== "QUARTER"
                                    ? memberPointsDurationCode
                                    : "MONTH",
                              }}
                              onBlur={() => {
                                handleEditBlur(setEditProcess);
                              }}
                              defaultMenuIsOpen
                              autoFocus
                              menuPlacement="auto"
                              maxMenuHeight={150}
                              isDisabled={
                                memberPointsCapDurationType !==
                                "specificDuration"
                              }
                            />
                          </fieldset>
                        ) : (
                          <span
                            data-name="member_points_period_code"
                            onClick={(e) => {
                              if (
                                memberPointsCapDurationType ===
                                "specificDuration"
                              ) {
                                handleEditClick(e, setEditProcess);
                              }
                            }}
                            className={
                              disabled ||
                              memberPointsCapDurationType !== "specificDuration"
                                ? "disable mr-4"
                                : "editable mr-4"
                            }
                          >
                            {earnCapIntervalAlias[memberPointsDurationCode]
                              ? earnCapIntervalAlias[memberPointsDurationCode]
                              : "Monthly"}
                          </span>
                        )}
                        duration.
                      </div>
                    </div>
                  </p>
                </div>
              </div>
            </>
          )}
        </div>
        {offerType !== NON_PURCHASE && (
          <div className="cap-section-inner">
            <div className="mb-16 cap-content-div">
              <div>
                <label className="content-label">Offer-level caps</label>
                <label className="info_icon_display">
                  <>
                    <div className="info-btn-reward">
                      <AiOutlineInfoCircle
                        className="info-icon"
                        onMouseEnter={() => {
                          setShowOfferCapInfo(true);
                        }}
                        onMouseLeave={() => {
                          setShowOfferCapInfo(false);
                        }}
                      />

                      {showOfferCapInfo && (
                        <>
                          <div
                            className="info-msg-point"
                            onMouseEnter={() => {
                              setShowOfferCapInfo(true);
                            }}
                            onMouseLeave={() => {
                              setShowOfferCapInfo(false);
                            }}
                          >
                            <div className="triangle"></div>
                            <p>{OFFER_CAP_INFO_TITLE} </p>
                            {OFFER_CAP_INFO_DETAIL}
                          </div>
                        </>
                      )}
                    </div>
                  </>
                </label>
                <label className="offer_level_switch">
                  <input
                    type="checkbox"
                    onChange={showPointsLevelContent}
                    checked={showPointsLevelCap ? true : false}
                    disabled={isSetDisable}
                  />
                  <span className="slider round"></span>
                </label>
              </div>
            </div>
            {showPointsLevelCap && (
              <>
                {
                  <div className="caps-wrapper" ref={offerTimesCapSection}>
                    <div className="valid-from-section">
                      <p className="valid-from">
                        Award upto
                        <div className="input-with-error">
                          {!isSetDisable &&
                          editProcess.name === "offer_cap_value" ? (
                            <NumericFormat
                              className={`text-input-number ${
                                offerCap === "" ? "empty-error" : null
                              }`}
                              autoFocus="true"
                              value={offerCap}
                              thousandsGroupStyle="lakh"
                              thousandSeparator=","
                              displayType="input"
                              decimalScale={0}
                              renderText={(offerCap) => <b>{offerCap}</b>}
                              isAllowed={(values) => {
                                const { value } = values;
                                return value <= 9999999 || value === "";
                              }}
                              onValueChange={(values, sourceInfo) => {
                                const { value } = values;
                                if (value && value !== "") {
                                  setOfferCap(value);
                                }
                              }}
                              onBlur={(e) => {
                                handleEditBlur(setEditProcess);
                              }}
                              onKeyPress={(e) => {
                                onKeyPressAction(e);
                              }}
                            />
                          ) : (
                            <>
                              <span
                                data-name="offer_cap_value"
                                onClick={(e) => {
                                  handleEditClick(e, setEditProcess);
                                }}
                                className={
                                  isSetDisable ? "disable" : "editable"
                                }
                              >
                                {formatNumberCommaSeperated(offerCap)}
                              </span>
                            </>
                          )}
                        </div>
                        <div>
                          {!isSetDisable &&
                          editProcess.name === "offer_dropdown" ? (
                            <fieldset className="member-dropdown-fieldset dropdown">
                              <Select
                                className="content-dropdown"
                                options={[
                                  { value: "times", label: "time(s)" },
                                  { value: "points", label: "points" },
                                ]}
                                value={offerLevelCapData}
                                styles={getCustomStyles(theme)}
                                onChange={(e) => {
                                  setOfferLevelCapData(e);
                                }}
                                isDisabled={
                                  isSetDisable === true ? true : false
                                }
                                onBlur={() => {
                                  handleEditBlur(setEditProcess);
                                }}
                                defaultMenuIsOpen
                                autoFocus
                                menuPlacement="auto"
                                maxMenuHeight={150}
                              />
                            </fieldset>
                          ) : (
                            <span
                              data-name="offer_dropdown"
                              onClick={(e) => {
                                handleEditClick(e, setEditProcess);
                              }}
                              className={isSetDisable ? "disable" : "editable"}
                            >
                              {offerLevelCapData.label}
                            </span>
                          )}
                        </div>
                        <p className="valid-from">overall.</p>
                      </p>
                    </div>
                  </div>
                }
                {
                  <div className="caps-wrapper" ref={offerPointsCapSection}>
                    <div className="valid-from-section"></div>
                  </div>
                }
              </>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default EarnOfferCap;
