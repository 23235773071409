import tellUride_Logo from "../../tellurideExtAssets/images/headerSidebarImages/tellUride_Logo.png";
import userImg from "../../tellurideExtAssets/images/headerSidebarImages/userImg.png";
import grid from "../../tellurideExtAssets/images/headerSidebarImages/grid.png";
import hamburger from "../../tellurideExtAssets/images/headerSidebarImages/hamburger.png";
import up from "../../tellurideExtAssets/images/headerSidebarImages/up.png";
import down from "../../tellurideExtAssets/images/headerSidebarImages/down.png";
import downArrow from "../../tellurideExtAssets/images/offerSetupImages/down-arrow-icon-white.png";
import search from "../../tellurideExtAssets/images/offerSetupImages/search.png";
import calenderImg from "../../tellurideExtAssets/images/offerSetupImages/calendar.png";
import addImage from "../../tellurideExtAssets/images/offerSetupImages/add_image.svg";
import editBtn from "../../tellurideExtAssets/images/offerSetupImages/editBtn.png";
import trashImage from "../../tellurideExtAssets/images/offerSetupImages/trash-icon.svg";
import tellurideLogo from "../../tellurideExtAssets/images/offerSetupImages/telluride-logo.png";
import tellurideLogoWhite from "../../tellurideExtAssets/images/offerSetupImages/telluride-logo-white.png";
import infoImg from "../../tellurideExtAssets/images/offerSetupImages/infoImg.png";
import infoIcon from "../../tellurideExtAssets/images/offerSetupImages/info.png";
import penIcon from "../../tellurideExtAssets/images/offerSetupImages/pen.png";
import pointsEarnOfferImage from "../../tellurideExtAssets/images/offerSetupImages/pointsEarnOfferImage.png";
import pointsDiscountOfferImage from "../../tellurideExtAssets/images/offerSetupImages/pointsDiscountOfferImage.png";
import uploadIcon from "../../tellurideExtAssets/images/offerSetupImages/Upload-icon.svg";
import dashboardIcon from "../../tellurideExtAssets/images/headerSidebarImages/dashboard.png";
import dashboardBlackIcon from "../../tellurideExtAssets/images/headerSidebarImages/dashboardBlack.png";
import createOfferIcon from "../../tellurideExtAssets/images/headerSidebarImages/createOffer.png";
import createOfferBlackIcon from "../../tellurideExtAssets/images/headerSidebarImages/createOfferBlack.png";
import viewPromotion from "../../tellurideExtAssets/images/headerSidebarImages/viewPromotion.png";
import viewPromotionBlack from "../../tellurideExtAssets/images/headerSidebarImages/viewPromotionBlack.png";
import calendarIcon from "../../tellurideExtAssets/images/calendarImages/calendar.svg";
import logoutIcon from "../../tellurideExtAssets/images/headerSidebarImages/logout-icon.svg";
import arrowUp from "../../tellurideExtAssets/images/headerSidebarImages/arrow-up.png";
import arrowDown from "../../tellurideExtAssets/images/headerSidebarImages/arrow-down.png";
import infoImage from "../../tellurideExtAssets/images/offerSetupImages/infoImage.svg";
import sessionImage from "../../tellurideExtAssets/images/offerSetupImages/sessionImage.png";
import darkModeIcon from "../../tellurideExtAssets/images/headerSidebarImages/dark-mode.svg";
import lightModeIcon from "../../tellurideExtAssets/images/headerSidebarImages/light-mode.svg"
import viewEye from "../../tellurideExtAssets/images/offerSetupImages/viewEye.png"
import campaignBanner from "../../tellurideExtAssets/images/campaign-banner.png"

import convertkitLogo from "../../tellurideExtAssets/images/connectorLogos/convertkit.png";
import klaviyoLogo from "../../tellurideExtAssets/images/connectorLogos/klaviyo.png";
import mailChimpLogo from "../../tellurideExtAssets/images/connectorLogos/mailchimp.png";
import marigoldEngageLogo from "../../tellurideExtAssets/images/connectorLogos/marigoldEngage.png";
import salesForceLogo from "../../tellurideExtAssets/images/connectorLogos/salesforce.png";
import mySqlLogo from "../../tellurideExtAssets/images/connectorLogos/mysql.png";

export const images = {
  arrowUp,
  arrowDown,
  logoutIcon,
  tellUride_Logo,
  tellurideLogoWhite,
  userImg,
  grid,
  hamburger,
  up,
  down,
  downArrow,
  search,
  calenderImg: calenderImg,
  addImage: addImage,
  trashImage: trashImage,
  editBtn: editBtn,
  tellurideLogo,
  infoIcon,
  penIcon,
  infoImg,
  pointsEarnOfferImage,
  pointsDiscountOfferImage,
  uploadIcon,
  dashboardIcon,
  dashboardBlackIcon,
  createOfferIcon,
  createOfferBlackIcon,
  viewPromotion,
  viewPromotionBlack,
  calendarIcon,
  infoImage,
  sessionImage,
  darkModeIcon,
  lightModeIcon,
  viewEye,
  campaignBanner,
  convertkitLogo,
  klaviyoLogo,
  mailChimpLogo,
  marigoldEngageLogo,
  salesForceLogo,
  mySqlLogo
};
