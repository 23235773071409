import {
	GET_TABLE_LIST_FAILURE,
	GET_TABLE_LIST_REQUEST,
	GET_TABLE_LIST_SUCCESS,
	GET_TABLE_LIST_SUCCESS_FAILURE,
	RESET_TABLE_LIST_DATA,
	SET_TABLE_LIST_SAVED_FILTER,
} from "./GenericTableDetailsType";

const initialState = {
	genericTableDataResponse: "",
	genericTableDataLoading: true,
	genericTableDataError: "",
};

export const genericTableDataReducer = (state = initialState, action) => {
	switch (action.type) {
		case GET_TABLE_LIST_REQUEST:
			return {
				...state,
				genericTableDataLoading: true,
				genericTableDataError: null,
				genericTableDataResponse: "",
			};
		case GET_TABLE_LIST_SUCCESS:
			return {
				...state,
				genericTableDataResponse: action.payload,
				genericTableDataLoading: false,
				genericTableDataError: null,
			};
		case GET_TABLE_LIST_FAILURE:
			return {
				...state,
				genericTableDataLoading: false,
				genericTableDataError: action.payload,
				apiError: action.payload,
			};
		case GET_TABLE_LIST_SUCCESS_FAILURE:
			return {
				...state,
				genericTableDataLoading: false,
				genericTableDataError: action.payload,
			};
		case SET_TABLE_LIST_SAVED_FILTER:
			return {
				...state,
				[action.payload.key]: action.payload.value,
			};
		case RESET_TABLE_LIST_DATA:
			return {
				...state,
				genericTableDataResponse: "",
				genericTableDataLoading: true,
				genericTableDataError: "",
			};
		default:
			return state;
	}
};
