import { orgId } from "../../tellurideExtComponents/constantComponent/Constants";
import {
  GET_AUTH_INIT_FAILURE,
  GET_AUTH_INIT_REQUEST,
  GET_AUTH_INIT_SUCCESS,
  GET_AUTH_INIT_SUCCESS_FAILURE,
  GET_AUTH_LOGOUT_FAILURE,
  GET_AUTH_LOGOUT_REQUEST,
  GET_AUTH_LOGOUT_SUCCESS,
  GET_AUTH_LOGOUT_SUCCESS_FAILURE,
  GET_ORG_ID_FAILURE,
  GET_ORG_ID_REQUEST,
  GET_ORG_ID_SUCCESS,
  GET_ORG_ID_SUCCESS_FAILURE,
  SET_AUTHLOADING_FLAG,
  SET_BLANK_SESSION_ID_ERROR,
  SET_SESSION_TIMEOUT_POPUP,
} from "./SecurityType";

const initialState = {
  authLoading: true,
  authLogoutLoading: false,
  authInitResponse: "",
  authLogoutResponse: "",
  authInitError: "",
  authLogoutError: "",
  blankSessionIdError: false,
  showExpiredSessionPopup: false,
  orgidLoading: false,
  orgidResponse: "",
  orgId: "",
  orgIdError: "",
};

export const SecurityReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_AUTH_INIT_REQUEST:
      return {
        ...state,
        authLoading: true,
      };
    case GET_AUTH_INIT_SUCCESS:
      return {
        ...state,
        authLoading: false,
        authInitResponse: action.payload,
        authInitError: "",
      };
    case GET_AUTH_INIT_FAILURE:
      return {
        ...state,
        authLoading: false,
        authInitError: action.payload,
        authInitResponse: "",
      };
    case GET_AUTH_INIT_SUCCESS_FAILURE:
      return {
        ...state,
        authLoading: false,
        authInitError: action.payload,
        authInitResponse: "",
      };
    case GET_AUTH_LOGOUT_REQUEST:
      return {
        ...state,
        authLogoutLoading: true,
      };
    case GET_AUTH_LOGOUT_SUCCESS:
      return {
        ...state,
        authLogoutLoading: true,
        authLogoutResponse: action.payload,
      };
    case GET_AUTH_LOGOUT_FAILURE:
      return {
        ...state,
        authLogoutLoading: true,
        authLogoutError: action.payload,
      };
    case GET_AUTH_LOGOUT_SUCCESS_FAILURE:
      return {
        ...state,
        authLogoutLoading: true,
        authLogoutError: action.payload,
      };
    case SET_AUTHLOADING_FLAG:
      return {
        ...state,
        authLoading: false,
      };
    case GET_ORG_ID_REQUEST: {
      return {
        ...state,
        orgidLoading: true,
      };
    }
    case GET_ORG_ID_SUCCESS: {
      return {
        ...state,
        orgidLoading: false,
        orgidResponse: action?.payload,
        orgId: action?.payload?.orgId,
      };
    }
    case GET_ORG_ID_SUCCESS_FAILURE: {
      return {
        ...state,
        orgIdError: action?.payload,
        orgidLoading: false,
      };
    }
    case GET_ORG_ID_FAILURE: {
      return {
        ...state,
        orgIdError: action?.payload,
        orgidLoading: false,
      };
    }
    case SET_BLANK_SESSION_ID_ERROR:
      return {
        ...state,
        blankSessionIdError: true,
      };
    case SET_SESSION_TIMEOUT_POPUP:
      return {
        ...state,
        showExpiredSessionPopup: true,
      };
    default:
      return state;
  }
};
