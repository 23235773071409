import {
  DARK,
  DROPDOWN_BGCOLOR_DARK_THEME,
  DROPDOWN_FOCUSED_BGCOLOR_DARK_THEME,
  DROPDOWN_FOCUSED_BGCOLOR_LIGHT_THEME,
  DROPDOWN_SELECTED_BGCOLOR_DARK_THEME,
  DROPDOWN_SELECTED_BGCOLOR_LIGHT_THEME,
  LIGHT,
} from "../../constantComponent/Constants";

export const generateInitialLinkedOfferJSON = () => {
  const jsonData = {
    linkedOffers: [
      {
        offerData: {
          offerType: "EventProcessing",
          offerSubType: "MESSAGE",
          offerPointsDollarName: "",
          linkedOfferStatus: "",
        },
        actions: {
          action: [
            {
              actionName: "My Action",
              actionType: "MESSAGE",
              actionJson: {
                headline: "",
                bodyline: "",
                imageId: "",
                imageName: "",
                callToActionURL: "",
              },
            },
          ],
        },
      },
    ],
  };
  return jsonData.linkedOffers;
};

export const handleEditBlur = (setEditProcess) => {
  setEditProcess({
    status: false,
    name: null,
  });
};

export const getCustomStyles = (theme) => {
  return {
    control: (provided, state) => ({
      border: "none",
      borderRadius: "4px",
      display: "flex",
      padding: "0px",
      outline: "none",
      height: "34px",
      position: "relative",
      left: "-8px",
      margin: 0,
      // Add more custom styles as needed
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor:
        state.isSelected && theme === LIGHT
          ? DROPDOWN_SELECTED_BGCOLOR_LIGHT_THEME
          : state.isSelected && theme === DARK
          ? DROPDOWN_SELECTED_BGCOLOR_DARK_THEME
          : // ? DROPDOWN_FOCUSED_BGCOLOR_LIGHT_THEME
          state.isFocused && theme === DARK
          ? DROPDOWN_FOCUSED_BGCOLOR_DARK_THEME
          : state.isFocused && theme === LIGHT
          ? DROPDOWN_FOCUSED_BGCOLOR_LIGHT_THEME
          : theme === DARK
          ? DROPDOWN_BGCOLOR_DARK_THEME
          : "#fff",
      color: state.isSelected
        ? "#fff"
        : state.isFocused && theme === DARK
        ? "#fff"
        : state.isFocused && theme === LIGHT
        ? DROPDOWN_SELECTED_BGCOLOR_LIGHT_THEME
        : theme === LIGHT
        ? DROPDOWN_SELECTED_BGCOLOR_LIGHT_THEME
        : "#fff",
      padding: 8,
    }),
    // Add more style properties as needed
  };
};

export const handleEditClick = (e, setEditProcess) => {
  setEditProcess({
    status: true,
    name: e.target.dataset.name,
  });
};

export const formatNumberCommaSeperated = (value) => {
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};
