export const CAMPAIGN_DETAILS_TITLE = "Campaign Details";
export const CAMPAIGN_SCHEDULE_TITLE = "Schedule Campaign";
export const CAMPAIGN_ID_LABEL = "Campaign ID";
export const CAMPAIGN_ID_INFO = "System-generated number for the campaign.";
export const CAMPAIGN_TYPE_LABEL = "Campaign Type";
export const START_DATE_LABEL = "Start Date";
export const START_DATE_INFO = "Date, Campaign will be active from.";
export const END_DATE_LABEL = "End Date";
export const END_DATE_INFO = "Date, Campaign will end.";
export const DESCRIPTION_LABEL = "Description";
export const CAMPAIGN_START_DATE = "campaignStartDate";
export const CAMPAIGN_END_DATE = "campaignEndDate";
export const CTA_URL_DATA =
  "Clicking on email, member will be redirected to this URL.";
export const CAMPAIGN_CATEGORY_OPTIONS = [
  { label: "Stackable", value: "Stackable" },
  { label: "Non Stackable", value: "Non Stackable" },
  { label: "Always Earn", value: "Always Earn" },
];
export const ALLOWED_PERIODS=["year", "month","week", "day", "hour"]