import React, { useEffect, useState } from "react";
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend,
	PointElement,
} from "chart.js";
import { Line } from "react-chartjs-2";
import {
	LINE_CHART_LABELS,
	LINE_CHART_DATA,
	LINE_CHART_OPTIONS,
	LINE_CHART_OPTIONS_THEME,
} from "../../constantComponent/ChartConstants";
import { loading } from "../../LoaderComponents/loadingDesignConstant";
import { useSelector } from "react-redux";
import { emptyData } from "../../constantComponent/DesignConstant";

ChartJS.register(
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend,
	PointElement
);

const LineChart = ({ id }) => {
	//theme
	const toasterData = useSelector((state) => state.toasterData);
	const [theme, setTheme] = useState(toasterData.isDarkTheme);
	useEffect(() => {
		setTheme(toasterData.isDarkTheme);
	}, [toasterData]);
	//theme
	return (
		<div className="graph-div-border mr-0">
			<div
				id="lineChartSpinDiv"
				className="chart_spin_div mr-0"
				style={{ display: "none" }}
			>
				{loading()}
			</div>
			<div
				id={`${id}emptyData`}
				className="chart_spin_div mr-0"
				style={{ display: "none" }}
			>
				{emptyData()}
			</div>
			<div id="lineChartDiv">
				<Line
					id={id}
					data={LINE_CHART_DATA}
					options={LINE_CHART_OPTIONS_THEME(theme)}
				></Line>
			</div>
		</div>
	);
};

/*const actions = [
    {
      name: 'Randomize',
      handler(chart) {
        chart.data.datasets.forEach(dataset => {
          //dataset.data = Utils.numbers({count: chart.data.labels.length, min: 0, max: 100});
        });
        chart.update();
      }
    }
  ];*/
// </block:actions>

// <block:setup:1>
export default LineChart;
