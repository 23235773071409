export const GET_SEGMENT_LIST_REQUEST = "GET_SEGMENT_LIST_REQUEST";
export const GET_SEGMENT_LIST_SUCCESS = "GET_SEGMENT_LIST_SUCCESS";
export const GET_SEGMENT_LIST_FAILURE = "GET_SEGMENT_LIST_FAILURE";
export const GET_SEGMENT_LIST_SUCCESS_FAILURE =
  "GET_SEGMENT_LIST_SUCCESS_FAILURE";

export const GET_SEGMENT_DETAILS_REQUEST = "GET_SEGMENT_DETAILS_REQUEST";
export const GET_SEGMENT_DETAILS_SUCCESS = "GET_SEGMENT_DETAILS_SUCCESS";
export const GET_SEGMENT_DETAILS_FAILURE = "GET_SEGMENT_DETAILS_FAILURE";
export const GET_SEGMENT_DETAILS_SUCCESS_FAILURE =
  "GET_SEGMENT_DETAILS_SUCCESS_FAILURE";

export const SEARCH_SEGMENT_CONTENT_REQUEST = "SEARCH_SEGMENT_CONTENT_REQUEST";
export const SEARCH_SEGMENT_CONTENT_SUCCESS = "SEARCH_SEGMENT_CONTENT_SUCCESS";
export const SEARCH_SEGMENT_CONTENT_FAILURE = "SEARCH_SEGMENT_CONTENT_FAILURE";
export const SEARCH_SEGMENT_CONTENT_SUCCESS_FAILURE =
  "SEARCH_SEGMENT_CONTENT_SUCCESS_FAILURE";

export const UPLOAD_FILE_REQUEST = "UPLOAD_FILE_REQUEST";
export const UPLOAD_FILE_SUCCESS = "UPLOAD_FILE_SUCCESS";
export const UPLOAD_FILE_FAILURE = "UPLOAD_FILE_FAILURE";
export const UPLOAD_FILE_SUCCESS_FAILURE = "UPLOAD_FILE_SUCCESS_FAILURE";

export const CREATE_UPDATE_SEGMENT_REQUEST = "CREATE_UPDATE_SEGMENT_REQUEST";
export const CREATE_UPDATE_SEGMENT_SUCCESS = "CREATE_UPDATE_SEGMENT_SUCCESS";
export const CREATE_UPDATE_SEGMENT_FAILURE = "CREATE_UPDATE_SEGMENT_FAILURE";
export const CREATE_UPDATE_SEGMENT_SUCCESS_FAILURE =
  "CREATE_UPDATE_SEGMENT_SUCCESS_FAILURE";
export const RESET_CREATE_UPDATE_SEGMENT =
  "RESET_CREATE_UPDATE_SEGMENT";

export const UPDATE_VALIDATION_ERRORS = "UPDATE_VALIDATION_ERRORS";
export const RESET_VALIDATION_ERRORS = "RESET_VALIDATION_ERRORS";

export const SET_ORGANZTATION_NAME = "SET_ORGANZTATION_NAME";
export const SET_AUTH_ORGANZTATION_NAME = "SET_AUTH_ORGANZTATION_NAME";

export const SET_UPLOAD_IMAGE = "SET_UPLOAD_IMAGE";
export const SET_BLANK_SESSION_ID_ERROR = "SET_BLANK_SESSION_ID_ERROR";
export const SET_SESSION_TIMEOUT_POPUP = "SET_SESSION_TIMEOUT_POPUP";
export const SET_SEGMENT_DETAILS = "SET_SEGMENT_DETAILS";
export const SET_UPLOAD_FILE = "SET_UPLOAD_FILE";
export const SET_SEGMENT_MODE = "SET_SEGMENT_MODE";
export const SET_SEGMENT_EDIT_STATUS = "SET_SEGMENT_EDIT_STATUS";
