import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { DETAILS } from "../../constantComponent/PathConstants";
import GenericTable from "../../utilsComponent/TableUtil/GenericTable";
import {
	COLUMNS,
	VIEW_CONTENT,
	VIEW_CONTENT_PLACEHOLDER,
} from "./ContentListConstants";
import {
	OFFER_TABLE_REQ_BODY_KEY,
	OFFER_TABLE_RES_BODY_KEY,
} from "../../offerSetupComponent/viewOfferComponent/TableViewConstants";
import { getDefaultFilter } from "../../utilsComponent/TableUtil/GenericTableUtil";
import { REDUX, TYPE } from "../../constantComponent/Constants";
import { getRightsKey } from "../../utilsComponent/CommonUtil";

const ContentListComponent = ({ setShowOfferPopup }) => {
	const navigate = useNavigate();
	const location = useLocation();

	function btnClickHandler() {
		setShowOfferPopup(true);
	}
	useEffect(() => {
		if (location.state?.shouldTrigger) {
			setShowOfferPopup(true);
			// remove params shouldTrigger from route
			navigate({
				pathname: location.pathname,
				state: {
					...location.state,
					shouldTrigger: undefined, // or remove it completely
				},
			});
		}
	}, [location.state?.shouldTrigger]);
	const advanceSearchHeader = {
		searchBar: true,
		globalFilter: true,
		dateType: false,
		dateRange: false,
		tableHeaderBtn: {
			label: "Create Content",
			clickHandler: btnClickHandler,
			rights: getRightsKey(["button", "campaign", "content", "createcontent"]),
		},
	};
	function handleRowClick(row) {
		navigate({
			pathname:
				"/" +
				window.location.pathname.split("/")[1] +
				"/campaign/content" +
				DETAILS,
			search: `?offerid=${row.id}`,
		});
	}
	function generateCustomRequestBody(tableReqBody, dateType, dateRange) {
		let requestBody = {
			...tableReqBody,
			[OFFER_TABLE_REQ_BODY_KEY]: {
				...tableReqBody[OFFER_TABLE_REQ_BODY_KEY],
				type: tableReqBody[OFFER_TABLE_REQ_BODY_KEY][TYPE]
					? tableReqBody[OFFER_TABLE_REQ_BODY_KEY][TYPE]
					: getDefaultFilter(COLUMNS, TYPE),
			},
		};
		return requestBody;
	}
	return (
		<GenericTable
			COLUMNS={COLUMNS}
			FILTER_SHOW_LIMIT={1}
			SHOW_HEADER={true}
			ADVANCE_SEARCH_PLACEHOLDER={VIEW_CONTENT_PLACEHOLDER}
			API_URL={process.env.REACT_APP_GET_OFFER_LIST}
			TABLE_SRC={VIEW_CONTENT}
			SHOW_HEADER_DASHBOARD={false}
			SHOW_TOOLTIP={false}
			ROW_CLICKABLE={true}
			ROW_CLICK_HANDLER={handleRowClick}
			ADVANCE_SEARCH_HEADER={advanceSearchHeader}
			REQUEST_BODY_KEY={OFFER_TABLE_REQ_BODY_KEY}
			RESPONSE_BODY_KEY={OFFER_TABLE_RES_BODY_KEY}
			IS_SEARCH_ALLOWED={true}
			CSTM_REQ_BODY={generateCustomRequestBody}
			SAVE_SEARCHED_FILTER={{
				isSaved: true, //  true || false => default : true
				storage: REDUX, // redux  || local || session  => default : "redux"
			}}
		/>
	);
};
export default ContentListComponent;
