import { DARK, DROPDOWN_BGCOLOR_DARK_THEME, DROPDOWN_FOCUSED_BGCOLOR_DARK_THEME, DROPDOWN_FOCUSED_BGCOLOR_LIGHT_THEME, DROPDOWN_SELECTED_BGCOLOR_DARK_THEME, DROPDOWN_SELECTED_BGCOLOR_LIGHT_THEME, LIGHT } from "../../constantComponent/Constants";
import { onKeyPressAction } from "../../utilsComponent/CommonUtil";
import {
	DOLLAR_OFF,
	MAX,
	MIN,
	MULTIPLIER,
	PERCENT_OFF,
	QUANTITY,
	SPEND,
	SPENT_OFFER_FOR,
	TIER,
	TRIP,
	thresholdLabel,
} from "./DiscountConstant";
import { NumericFormat } from "react-number-format";

export const GenerateNewData = (discountType, prevMAxVal, thresholdType) => {
	let addVal = thresholdType === QUANTITY ? 1 : 0.01;
	let minVal = prevMAxVal ? prevMAxVal + addVal : addVal;
	const newData = {
		property: [
			{
				name: "MIN",
				values: {
					value: [minVal],
				},
			},
			{
				name: "MAX",
				values: {
					value: [""],
				},
			},
			{
				name: discountType,
				values: {
					value: [""],
				},
			},
		],
		propertyType: "TIER",
	};
	return newData;
};
export const generateInitialJSON = () => {
	const jsonData = {
		action: [
			{
				actionID: "ACTION-1",
				actionName: "DEALS",
				actionProperties: {
					actionProperty: [
						{
							property: [
								{
									name: "MIN",
									values: {
										value: ["0.01"],
									},
								},
								{
									name: "MAX",
									values: {
										value: [],
									},
								},
								{
									name: "DOLLAR_OFF",
									values: {
										value: [],
									},
								},
							],
							propertyType: "TIER",
						},
						{
							property: [
								{
									name: "DEAL_TYPE", // discount type
									values: {
										value: ["simple"],
									},
								},
								{
									name: "PRICE_TYPE", // not applicable
									values: {
										value: ["tier"],
									},
								},
								{
									name: "APPLY_ON", // apply on
									values: {
										value: ["ALL_ITEMS"],
									},
								},

								{
									name: "THRESHOLD", // threshold type
									values: {
										value: ["spend_excred"],
									},
								},
							],
						},
					],
				},
			},
		],
	};

	return jsonData;
};

export const generateRewardJSON = (jsonData) => {
	let isHasMin = false;
	let isHasMax = false;
	let isHasDollarOrPercent = false;
	let isTierPropertyExist = false;
	jsonData.action[0].actionProperties.actionProperty.map((property) => {
		if (property.propertyType === "TIER") {
			isTierPropertyExist = true;
			property.property.map((propertyValue) => {
				if (propertyValue.name === MIN) {
					isHasMin = true;
				} else if (propertyValue.name === MAX) {
					isHasMax = true;
				} else if (
					propertyValue.name === DOLLAR_OFF ||
					propertyValue.name === PERCENT_OFF
				) {
					isHasDollarOrPercent = true;
				}
			});
			if (!isHasMin) {
				const index = 0;
				const valToPush = {
					name: "MIN",
					values: {
						value: [],
					},
				};
				const newArray = [
					...property.property.slice(0, index),
					valToPush,
					...property.property.slice(index),
				];
				property.property = newArray;
			}
			if (!isHasMax) {
				const index = 1;
				const valToPush = {
					name: "MAX",
					values: {
						value: [],
					},
				};
				const newArray = [
					...property.property.slice(0, index),
					valToPush,
					...property.property.slice(index),
				];
				property.property = newArray;
			}
			if (!isHasDollarOrPercent) {
				const index = 1;
				const valToPush = {
					name: "DOLLAR_OFF",
					values: {
						value: [],
					},
				};
				const newArray = [
					...property.property.slice(0, index),
					valToPush,
					...property.property.slice(index),
				];
				property.property = newArray;
			}
		}
		if (!isTierPropertyExist) {
			jsonData.action[0].actionProperties.actionProperty.push(
				GenerateNewData("DOLLAR_OFF")
			);
		}
	});
	return jsonData;
};

export const formatAmount = (e) => {
	let value = e.target.value.replace(`,`, "", -1);
	if (value !== "") {
		let numericValue = value.replace(/[^0-9.]/g, "");
		const match = /(\d{0,7})[^.]*((?:\.\d{0,3})?)/g.exec(
			value.replace(/[^\d.]/g, "")
		);
		numericValue = match[1] + match[2];
		if (
			numericValue.charAt(numericValue.length - 1) !== "." &&
			!numericValue.startsWith("0.") &&
			!numericValue.split(".")[1]?.includes("0")
		) {
			let formattedValue = parseFloat(numericValue).toLocaleString("en-US", {
				currency: "USD",
			});
			if (numericValue.endsWith(".00") || numericValue.endsWith(".0")) {
				numericValue.endsWith(".00")
					? (formattedValue = formattedValue + ".00")
					: (formattedValue = formattedValue + ".0");
			}
			return formattedValue !== "NaN" ? formattedValue : "";
		} else {
			return numericValue;
		}
	}
};

export const validateNumericValue = (e, offerRangeValue) => {
	if (parseInt(e.target.value.replace(/,/g, ""), 10) > 2147483647)
		return offerRangeValue;
	if (e.target.value !== "") {
		const numericValue = e.target.value.replace(/[^0-9]/g, "");
		if (numericValue) {
			const formattedValue = parseFloat(numericValue).toLocaleString("en-US", {
				currency: "USD",
				maximumFractionDigits: 3,
			});

			return formattedValue;
		}
		return numericValue;
	}
};
export const getInputForMin = () => {
	return (
		<div className="col-2">
			<div className="outer-input">
				<fieldset className="min-max-fieldset">
					<legend>Min</legend>
					<input
						className="dollar dollar-spend"
						type="text"
						defaultValue={"$"}
						readOnly
					></input>
					<NumericFormat
						className="amount"
						value={""}
						decimalScale={3}
						thousandsGroupStyle="lakh"
						thousandSeparator=","
						displayType="input"
						renderText={(value) => <b>{value}</b>}
						isAllowed={(values) => {
							const { floatValue } = values;
							return floatValue < 9999999.9999 || floatValue === undefined;
						}}
					/>
				</fieldset>
			</div>
		</div>
	);
};

export const getInputForMax = () => {
	return (
		<div className="col-2">
			<div className="outer-input">
				<fieldset className="min-max-fieldset">
					<legend>Min</legend>
					<input
						className="dollar dollar-spend"
						type="text"
						defaultValue={"$"}
						readOnly
					></input>
					<NumericFormat
						className="amount"
						value={""}
						decimalScale={3}
						thousandsGroupStyle="lakh"
						thousandSeparator=","
						displayType="input"
						renderText={(value) => <b>{value}</b>}
						isAllowed={(values) => {
							const { floatValue } = values;
							return floatValue < 9999999.9999 || floatValue === undefined;
						}}
					/>
				</fieldset>
			</div>
		</div>
	);
};
export const getInputOfferFor = (
	offerFor,
	offerForValue,
	setOfferForValue,
	isSetDisable
) => {
	let legendText = offerFor;
	let maxAllowedLength = offerFor === TRIP || offerFor === QUANTITY ? 8 : "";
	return (
		<div className="col-1">
			<div className="outer-input">
				<fieldset className="reward-fieldset" disabled={isSetDisable}>
					<legend>{thresholdLabel[offerFor]} Threshold</legend>
					{offerFor === SPENT_OFFER_FOR && (
						<input
							className="dollar dollar-spend"
							type="text"
							defaultValue={"$"}
							readOnly
						></input>
					)}
					{offerFor === SPENT_OFFER_FOR && (
						<NumericFormat
							className="amount"
							value={offerForValue}
							decimalScale={3}
							thousandsGroupStyle="lakh"
							thousandSeparator=","
							displayType="input"
							renderText={(value) => <b>{value}</b>}
							isAllowed={(values) => {
								const { floatValue } = values;
								return floatValue < 9999999.9999 || floatValue === undefined;
							}}
							onValueChange={(values, sourceInfo) => {
								setOfferForValue(values.value);
							}}
						/>
					)}
					{offerFor !== SPENT_OFFER_FOR && (
						<NumericFormat
							className="amount"
							value={offerForValue}
							thousandsGroupStyle="lakh"
							thousandSeparator=","
							displayType="input"
							decimalScale={0}
							renderText={(value) => <b>{value}</b>}
							isAllowed={(values) => {
								const { floatValue } = values;
								return floatValue < 99999999 || floatValue === undefined;
							}}
							onValueChange={(values, sourceInfo) => {
								setOfferForValue(values.value);
							}}
						/>
					)}
					{/* <span>Spend Threshold</span> */}
				</fieldset>
			</div>
		</div>
	);
};

export const getInputOfferRange = (
	offerRange,
	offerRangeValue,
	setOfferRangeValue,
	isSetDisable
) => {
	let legendText = offerRange === MULTIPLIER ? "Per Dollar" : "";
	return (
		<div className="col-1">
			<div className="outer-input">
				<fieldset className="reward-fieldset" disabled={isSetDisable}>
					<legend>Dollar Off Discount</legend>
					<NumericFormat
						value={offerRangeValue}
						decimalScale={0}
						thousandsGroupStyle="lakh"
						thousandSeparator=","
						displayType="input"
						renderText={(value) => <b>{value}</b>}
						onValueChange={(values, sourceInfo) => {
							setOfferRangeValue(values.value);
						}}
						isAllowed={(values) => {
							const { floatValue } = values;
							return floatValue < 2147483647 || floatValue === undefined;
						}}
						className="amount"
					/>
				</fieldset>
			</div>
		</div>
	);
};

export const getCustomStyles = (theme) => {
	return {
		control: (provided, state) => ({
			border: "none",
			borderRadius: "4px",
			top: "2px",
			display: "flex",
			padding: "0px",
			outline: "none",
			height: "34px",
			position: "relative",
			top: "-8px",
			// Add more custom styles as needed
		}),
		option: (provided, state) => ({
			...provided,
			backgroundColor:
				state.isSelected && theme === LIGHT
					? DROPDOWN_SELECTED_BGCOLOR_LIGHT_THEME
					: state.isSelected && theme === DARK
					? DROPDOWN_SELECTED_BGCOLOR_DARK_THEME
					: // ? DROPDOWN_FOCUSED_BGCOLOR_LIGHT_THEME
					state.isFocused && theme === DARK
					? DROPDOWN_FOCUSED_BGCOLOR_DARK_THEME
					: state.isFocused && theme === LIGHT
					? DROPDOWN_FOCUSED_BGCOLOR_LIGHT_THEME
					: theme === DARK
					? DROPDOWN_BGCOLOR_DARK_THEME
					: "#fff",
			color: state.isSelected
				? "#fff"
				: state.isFocused && theme === DARK
				? "#fff"
				: state.isFocused && theme === LIGHT
				? DROPDOWN_SELECTED_BGCOLOR_LIGHT_THEME
				: theme === LIGHT
				? DROPDOWN_SELECTED_BGCOLOR_LIGHT_THEME
				: "#fff",
			padding: 8,
		}),
		// Add more style properties as needed
	};
};
