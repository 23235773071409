import React, { useEffect, useState } from "react";
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend,
	PointElement,
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Bar } from "react-chartjs-2";
import {
	PROGRESS_CHART_LABELS,
	PROGRESS_CHART_DATA,
	PROGRESS_CHART_OPTIONS,
	PROGRESS_CHART_OPTIONS_THEMED,
} from "../../constantComponent/ChartConstants";
import { loading } from "../../LoaderComponents/loadingDesignConstant";
import { useSelector } from "react-redux";
import { emptyData } from "../../constantComponent/DesignConstant";
import { DARK } from "../../constantComponent/Constants";

ChartJS.register(
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend,
	PointElement,
	ChartDataLabels
);
//ChartJS.plugins.register(ChartDataLabels);

const ProgressChart = ({ id }) => {
	//theme
	const toasterData = useSelector((state) => state.toasterData);
	const [theme, setTheme] = useState(toasterData.isDarkTheme);
	useEffect(() => {
		setTheme(toasterData.isDarkTheme);
	}, [toasterData]);
	//theme
	useEffect(() => {
		PROGRESS_CHART_OPTIONS.plugins.datalabels.color =
			theme === DARK ? "white" : "black";
		PROGRESS_CHART_OPTIONS.plugins.title.color =
			theme === DARK ? "white" : "black";
	}, [theme]);
	return (
		<div className="graph-div-border mr-0">
			<div
				id="progressChartSpinDiv"
				className="chart_spin_div mr-0"
				style={{ display: "none" }}
			>
				{loading()}
			</div>
			<div
				id={`${id}emptyData`}
				className="chart_spin_div mr-0"
				style={{ display: "none" }}
			>
				{emptyData()}
			</div>
			<div id="progressChartDiv">
				<Bar
					id={id}
					data={PROGRESS_CHART_DATA}
					options={PROGRESS_CHART_OPTIONS}
				></Bar>
			</div>
		</div>
	);
};

export default ProgressChart;
