import { OFFER_DETAILS_STATUS_ACTIVATED, SAVE_WINNER_CAMPAIGN_STATUS } from "../../../../constantComponent/Constants";
import {
  generateUniqueId,
  generateUniqueVariationId,
} from "../../../../utilsComponent/CommonUtil";
import {
  NO_ACTION,
  SUBJECT_LINE_ACTION,
  email_number_of_variation_arrays,
  email_variation_json,
} from "../../CampaignBuilderConstants";

export const getTestBasedArrayForEmail = (emailContentType, length) => {
  let newVariantArray = [];
  let variantLength = length
    ? length
    : email_number_of_variation_arrays[emailContentType].numberOfVariants;

  Array.from({ length: variantLength }, (_, index) => {
    let jsonValue = {};
    if (
      Object.keys(email_variation_json[emailContentType].testBased).length > 0
    ) {
      Object.entries(email_variation_json[emailContentType].testBased).map(
        ([key, value]) => {
          if (key === "variationId") {
            jsonValue[key] = generateUniqueVariationId();
          } else {
            jsonValue[key] = value;
          }
        }
      );
      newVariantArray.push(jsonValue);
    }
  });
  return newVariantArray;
};

export const getVariationJson = (emailContentType) => {
  let variationJson = email_variation_json[emailContentType].testBased;
  variationJson.variationId = generateUniqueVariationId();
  return variationJson;
};

export const getCommonJSON = (emailContentType) => {
  let jsonValue = {};
  Object.entries(email_variation_json[emailContentType].common).map(
    ([key, value]) => {
      if (key === "variationId") {
        jsonValue[key] = generateUniqueVariationId();
      } else {
        jsonValue[key] = value;
      }
    }
  );
  return jsonValue;
};
export function isWinnerSelectionAllowed(journeyType, journeyStatus) {
  return (
    SAVE_WINNER_CAMPAIGN_STATUS.includes(journeyStatus) ||
    (journeyType === "CMP-EVENT" &&
      journeyStatus === OFFER_DETAILS_STATUS_ACTIVATED)
  );
}
