import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { headers, OFRI } from "../../../constantComponent/Constants";
import { getRightsFromSession, skey } from "../../../utilsComponent/CommonUtil.js";
import { getChatbotAnswer } from "../../../../tellurideExtRedux";
//import parse from 'html-react-parser';
import Markdown from "react-markdown";
import Loader from "./../ChatBotComponents/Loader";

const ActionProvider = ({ createChatBotMessage, setState, children }) => {

  const dispatch = useDispatch();
  const offerData = useSelector((state) => state.offerDetailsData);
  const chatbotData = useSelector((state) => state.chatbotData);
  const [chatbotAnswerRequestBody, setChatbotAnswerRequestBody] = useState(null);

  const echoMessage = (message) => {
    const botMessage = createChatBotMessage('Echo:\n' + message);

    setState((prev) => ({
      ...prev,
      messages: [...prev.messages, botMessage],
    }));
  };

  useEffect(() => {
    if (chatbotAnswerRequestBody !== null && chatbotData?.chatbotAnswerResponse?.message) {
      const msgResp = chatbotData.chatbotAnswerResponse.message;
      const botMessage = createChatBotMessage(<Markdown>{msgResp}</Markdown>);
      setState((prev) => {
        const newPrevMsg = prev.messages.slice(0, -1);
        return {
          ...prev,
          messages: [...newPrevMsg, botMessage],
        };
      });
    }
  }, [chatbotData?.chatbotAnswerResponse]);

  const GetOfferAssistResponse = (message) => {
    headers.sessionId = skey();
    headers.requestAccess = getRightsFromSession();
    headers.organization = offerData?.authOrganizationName;
    const requestHeaders = {
      headers,
    };
    const requestBody = {
      requestorID: OFRI,
      question: message ? message : "",
      session_id: skey().substring(0, 80),
    };
    if (message != null) {
      setChatbotAnswerRequestBody(requestBody);
      dispatch(getChatbotAnswer(requestHeaders, requestBody));
      const loading = createChatBotMessage(<Loader />);
      setState((prev) => ({ ...prev, messages: [...prev.messages, loading], }));
    }
  };

  return (
    <div>
      {React.Children.map(children, (child) => {
        return React.cloneElement(child, {
          actions: { echoMessage, GetOfferAssistResponse },
        });
      })}
    </div>
  );
};

export default ActionProvider;
