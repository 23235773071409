import { useNavigate } from "react-router-dom";
import { DETAILS } from "../../constantComponent/PathConstants";
import GenericTable from "../../utilsComponent/TableUtil/GenericTable";
import {
	COLUMNS,
	DATE_TYPE_OPTIONS,
	VIEW_CAMPAIGN,
	VIEW_CAMPAIGN_PLACEHOLDER,
} from "./CampaignListConstants";
import {
	OFFER_TABLE_REQ_BODY_KEY,
	OFFER_TABLE_RES_BODY_KEY,
} from "../../offerSetupComponent/viewOfferComponent/TableViewConstants";
import { getDefaultFilter } from "../../utilsComponent/TableUtil/GenericTableUtil";
import { formatDate, getRightsKey } from "../../utilsComponent/CommonUtil";
import { REDUX, TYPE } from "../../constantComponent/Constants";
const CampaignListComponent = ({ setShowCampaignCreatePopup }) => {
	const navigate = useNavigate();
	function btnClickHandler() {
		setShowCampaignCreatePopup(true);
	}
	const advanceSearchHeader = {
		searchBar: true,
		globalFilter: true,
		dateType: true,
		dateRange: true,
		tableHeaderBtn: {
			label: "Create Campaign",
			clickHandler: btnClickHandler,
			rights: getRightsKey([
				"button",
				"campaign",
				"campaign",
				"createcampaign",
			]),
		},
	};

	function handleRowClick(row) {
		navigate({
			pathname:
				"/" + window.location.pathname.split("/")[1] + "/campaign" + DETAILS,
			search: `?offerid=${row.id}`,
		});
	}
	function generateCustomRequestBody(tableReqBody, dateType, dateRange) {
		let requestBody = {
			...tableReqBody,
			[OFFER_TABLE_REQ_BODY_KEY]: {
				...tableReqBody[OFFER_TABLE_REQ_BODY_KEY],
				dateType: dateType,
				fromDate: formatDate(dateRange[0].startDate),
				toDate: formatDate(dateRange[0].endDate),
				type: tableReqBody[OFFER_TABLE_REQ_BODY_KEY][TYPE]
					? tableReqBody[OFFER_TABLE_REQ_BODY_KEY][TYPE]
					: getDefaultFilter(COLUMNS, TYPE),
			},
		};
		return requestBody;
	}
	return (
		<GenericTable
			COLUMNS={COLUMNS}
			FILTER_SHOW_LIMIT={1}
			SHOW_HEADER={true}
			ADVANCE_SEARCH_PLACEHOLDER={VIEW_CAMPAIGN_PLACEHOLDER}
			API_URL={process.env.REACT_APP_GET_OFFER_LIST}
			TABLE_SRC={VIEW_CAMPAIGN}
			SHOW_HEADER_DASHBOARD={false}
			SHOW_TOOLTIP={false}
			ROW_CLICKABLE={true}
			ROW_CLICK_HANDLER={handleRowClick}
			ADVANCE_SEARCH_HEADER={advanceSearchHeader}
			DATE_TYPE_OPTIONS={DATE_TYPE_OPTIONS}
			REQUEST_BODY_KEY={OFFER_TABLE_REQ_BODY_KEY}
			RESPONSE_BODY_KEY={OFFER_TABLE_RES_BODY_KEY}
			IS_SEARCH_ALLOWED={true}
			CSTM_REQ_BODY={generateCustomRequestBody}
			SAVE_SEARCHED_FILTER={{
				isSaved: true, //  true || false => default : true
				storage: REDUX, // redux  || local || session  => default : "redux"
			}}
		/>
	);
};
CampaignListComponent.displayName = "CampaignListComponent";
export default CampaignListComponent;
