import {
  LOYALTY_CASH,
  NON_PURCHASE,
} from "../../../constantComponent/Constants";
import {
  nodeDataObj,
  selectedDisplayNameAlias,
} from "./CustomOfferBuilderConstants";

export function getDisplayData(value, type) {
  if (!value) {
    return {
      defaultValue: "Select a value",
      displayName: type,
    };
  }
  let displayData = {
    displayName: type,
  };
  let nodeObj =
    typeof nodeDataObj[type] === "function"
      ? nodeDataObj[type](value?.action?.offerType)
      : nodeDataObj[type];
  nodeObj?.dataLabel.forEach((label) => {
    if (type === "email") {
      displayData[label.value] = value.action.variations[0][label.value];
    } else if (type === "start_trigger") {
      if (value?.rules) {
        value?.action?.triggerPoint === "crme"
          ? (displayData[label.value] =
              selectedDisplayNameAlias[value.action.triggerPoint])
          : (displayData[label.value] = value?.rules.condition.PASSES_ALL.map(
              (rule) =>
                rule?.attr === "affinities" ? rule?.value[0]?.name : null
            ));
      } else {
        displayData[label.value] =
          selectedDisplayNameAlias[value.action.triggerPoint];
      }
    } else if (type === "register_offer") {
      if (label.key === "Start Time") {
        displayData[label.value] = value.action.registrationStartDelta
          ? "Event date + " + value.action.registrationStartDelta
          : value.action.registrationStartTime;
      } else if (label.key === "End Time") {
        displayData[label.value] = value.action.registrationEndDelta
          ? "Award Date + " + value.action.registrationEndDelta
          : value.action.registrationEndTime;
      } else {
        displayData[label.value] = value.action[label.value];
      }
    } else {
      displayData[label.value] = value.action[label.value];
    }
  });
  return displayData;
}

export function getDataLabelByOfferType(offerType) {
  switch (offerType) {
    case LOYALTY_CASH:
      return [
        { key: offerType, value: "offerCode" },
        { key: "Start Time", value: "registrationStartTime" },
        { key: "End Time", value: "registrationEndTime" },
      ];
    case NON_PURCHASE:
      return [
        { key: "Non Purchase", value: "offerCode" },
        { key: "Points Award", value: "pointsAward" },
      ];

    default:
      break;
  }
}
