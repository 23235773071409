import React, { useEffect, useState } from "react";
import "./ABTestingWrapper.style.scss";
import {
  BACK,
  CAMPAIGN_BUILDER_HEADER,
} from "../campaignBuilderComponent/CampaignBuilderConstants";
import TestProgressComponent from "./TestProgressComponents/TestProgressComponent";
import TestProgressStats from "./TestProgressStatsComponent/TestProgressStats";
import TestProgressTableList from "./TestProgressTableListComponent/TestProgressTableList";
import { useDispatch, useSelector } from "react-redux";
import {
  getRightKey,
  getRightsFromSession,
  skey,
} from "../../utilsComponent/CommonUtil";
import {
  OFRI,
  PROCESSING_LOADER,
  SAVE_WINNER_CAMPAIGN_STATUS,
  headers,
} from "../../constantComponent/Constants";
import ErrorBoundary from "antd/es/alert/ErrorBoundary";
import Loading from "../../LoaderComponents/Loading";
import { ABTESTING_LOADER_SOURCE } from "../../LoaderComponents/LoadingConstant";
import { ABTestingSampleData } from "./ABTestingConstants";
import { miniloading } from "../../LoaderComponents/loadingDesignConstant";
import { emptyData } from "../../constantComponent/DesignConstant";
import { useNavigate } from "react-router-dom";
import {
  getVariationStats,
  saveVariationWinner,
} from "../../../tellurideExtRedux/journey/JourneyAction";
import { isWinnerSelectionAllowed } from "../campaignBuilderComponent/campaignPopups/EmailContentSelectionPopup/EmailContentSelectionUtil";
const ABTestingWrapper = ({
  showComponent,
  setShowComponent,
  node,
  setScreen,
  isDisabled,
  setPopupShowProcess,
  winnerVariationId,
  emailContentType,
}) => {
  const offerDetailsPageData = useSelector((state) => state.offerDetailsData);
  const campaignData = useSelector((state) => state.campaignDetailsData);
  const journeyData = useSelector((state) => state.journeyDetailsData);
  const journeyStatus =
    journeyData?.journeyDetailsResponse?.journeyData?.journeyStatus;
  const journeyType =
    journeyData?.journeyDetailsResponse?.journeyData?.journeyType;
  const abTestDetails = campaignData?.variationStatsResponse;
  const variationsData =
    abTestDetails?.variations?.length > 0 ? abTestDetails?.variations : [];
  const [winnerVariation, setWinnerVariation] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const queryString = require("query-string");
  const isAbTestPresent =
    // emailContentType.value === "subject_line" ||
    node?.data?.value?.action?.abTestEnabled === "Y";
  useEffect(() => {
    campaignData.saveWinnerResponse = "";

    headers.sessionId = skey();
    headers.organization = offerDetailsPageData?.authOrganizationName;
    headers.requestAccess = "sec_deactivate~0~0";
    const requestHeaders = {
      headers,
    };
    const requestBody = {
      journeyNumber: queryString.parse(window.location.search).offerid,
      // cmpCode: "JF_1241733",
      taskId: node.id,
      // taskId: "N2",
      requestorID: OFRI,
      messageVersion: process.env.REACT_APP_MESSAGE_VERSION_03,
    };
    dispatch(getVariationStats(requestHeaders, requestBody));
  }, []);

  useEffect(() => {
    if (
      campaignData.workFlowDetailsResponse &&
      campaignData.saveWinnerResponse &&
      !campaignData.saveWinnerLoading
    ) {
      setPopupShowProcess(false);
    }
  }, [campaignData]);

  function saveWinnerVariation() {
    headers.sessionId = skey();
    headers.requestAccess = getRightsFromSession();
    headers.organization = offerDetailsPageData?.authOrganizationName;
    // headers.requestAccess = "sec_deactivate~0~0";
    const requestHeaders = {
      headers,
    };

    const requestBody = {
      requestorID: OFRI,
      messageVersion: process.env.REACT_APP_MESSAGE_VERSION_03,
      journeyNumber: queryString.parse(window.location.search).offerid,
      winnerVariationId: winnerVariation?.variationId,
      taskId: node.id,
      journeyType:
        journeyData?.journeyDetailsResponse?.journeyData?.journeyType,
    };
    dispatch(
      saveVariationWinner(
        requestHeaders,
        requestBody,
        navigate,
        queryString.parse(window.location.search).offerid
      )
    );
  }

  return (
    <ErrorBoundary FallbackComponent={Error} onError={(error) => {}}>
      <Loading source={ABTESTING_LOADER_SOURCE}>
        <section>
          {isAbTestPresent && (
            <div className="section-top-title abtest-section-title">
              {"How did A/B test go"}
            </div>
          )}

          <div className="ab-testing-wrapper">
            {campaignData?.variationStatsResponse?.variations?.length ? (
              <>
                <TestProgressComponent
                  variationsData={variationsData}
                  abTestDetails={abTestDetails}
                  isAbTestPresent={isAbTestPresent}
                />
                <TestProgressStats variationsData={variationsData} />
                {isAbTestPresent && (
                  <TestProgressTableList
                    isDisabled={isDisabled}
                    variationsData={variationsData}
                    winnerVariation={winnerVariation}
                    setWinnerVariation={setWinnerVariation}
                    winnerVariationId={winnerVariationId}
                  />
                )}
              </>
            ) : (
              <div className="empty-table-container">
                {emptyData(
                  isAbTestPresent && journeyStatus === "AB-TESTING"
                    ? "A/B Test is going on"
                    : "No data to display"
                )}
              </div>
            )}
          </div>

          <footer className="campaign-modal-footer">
            {/* <button
              className="btn btn-secondary"
              onClick={() => {
                setPopupShowProcess(false);
                setNodeData(null);
              }}
            >
              {CANCEL}
            </button> */}
            <button
              className="btn btn-secondary ml-10"
              onClick={() => {
                setShowComponent("ab-testing-setup");
                setScreen((prev) => {
                  return {
                    ...prev,
                    resize: false,
                    maximize: false,
                  };
                });
              }}
            >
              {BACK}
            </button>
            {isAbTestPresent && (
              <button
                className="btn btn-primary continue-setup-btn"
                // disabled={
                //   isDisabled ||
                //   winnerVariation === "" ||
                //   variationsData.length === 0
                // }
                disabled={
                  !isWinnerSelectionAllowed(journeyType, journeyStatus) ||
                  winnerVariation === "" ||
                  variationsData.length === 0
                }
                onClick={() => saveWinnerVariation()}
              >
                {" "}
                {campaignData.saveWinnerLoading
                  ? miniloading(PROCESSING_LOADER)
                  : "Save winner variation"}
              </button>
            )}
          </footer>
        </section>
      </Loading>
    </ErrorBoundary>
  );
};

export default ABTestingWrapper;
