import {
    CONTENT_GENERATION_FAILURE,
  CONTENT_GENERATION_REQUEST,
  CONTENT_GENERATION_SUCCESS,
  CONTENT_GENERATION_SUCCESS_FAILURE,
} from "./PromptDataType";

const initialState = {
  contentGenerationLoading: false,
  contentGenerationResponse: "",
  contentGenerationChoiceTexts: "",
  contentGenerationPageError: "",
  apiError: "",
};

export const promptDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case CONTENT_GENERATION_REQUEST:
      return {
        ...state,
        contentGenerationLoading: true,
        contentGenerationResponse: "",
        contentGenerationChoiceTexts: "",
      };

    case CONTENT_GENERATION_SUCCESS:
      return {
        ...state,
        contentGenerationLoading: false,
        contentGenerationResponse: action?.payload,
        contentGenerationChoiceTexts: action?.payload?.promptResponse?.choices,
      };

    case CONTENT_GENERATION_SUCCESS_FAILURE:
      return {
        contentGenerationLoading: false,
        contentGenerationPageError: action?.payload,
        contentGenerationResponse: "",
        contentGenerationChoiceTexts: "",
      };
    case CONTENT_GENERATION_FAILURE:
      return {
        contentGenerationLoading: false,
        contentGenerationPageError: action?.payload,
        apiError: "",
        contentGenerationResponse: "",
        contentGenerationChoiceTexts: "",
      };
    default:
      return state;
  }
};
