import { differenceInCalendarDays } from "date-fns";
import { getGenericTableDetails } from "../../../tellurideExtRedux";
import { OFRI, headers } from "../../constantComponent/Constants";
import {
	ALL,
	ASCENDING,
	NEXT,
	PREVIOUS,
	ZERO,
} from "../GenericRules/GenericRulesConstants";
import {
  checkRightsIsVisible,
  getRightsFromSession,
  getRightsKey,
  skey,
} from "../CommonUtil";
import {
	APPLY,
	ASC,
	DESC,
	FILTER,
	RESET,
	SEARCH,
	SINGLE,
} from "./GenericTableConstants";

export function getDaysFromStartEndDates(dateRange) {
	return (
		differenceInCalendarDays(dateRange[0].endDate, dateRange[0].startDate) + 1
	);
}
export function handleSearchChange({ target: { value } }, setSearchInputQuery) {
	let input = value;
	setSearchInputQuery(input);
}

export function getDefaultFilter(columns, key) {
	const column = columns.find((column) => column.key === key);
	if (column && column.options) {
		const validOptions = column.options.filter((option) => {
			if (column.rights) {
				const rightEntry = column.rights.find((entry) => entry.key === option);
				return (
					rightEntry && checkRightsIsVisible(getRightsKey(rightEntry.rights))
				);
			} else {
				return true;
			}
		});
		return validOptions.join(", ");
	}
	return "";
	// return columns.find((column) => column.key === key)?.options.join(", ");
}

export function handleSearch(
	searchInputQuery,
	setSearch,
	setFetchList,
	setSearchInputQuery
) {
	if (searchInputQuery.length > ZERO) {
		setSearch((prev) => {
			return [...prev, { type: SEARCH, data: searchInputQuery, status: true }];
		});
		setFetchList((prev) => !prev);
		setSearchInputQuery("");
	}
}
export function clearFilter(
	action,
	column,
	filter,
	setFilter,
	setSearch,
	setFetchList
) {
	if (action === SINGLE) {
		if (filter?.find((col) => col.name === column.name && col.status)) {
			setFilter((prev) => {
				return prev.filter((col) => {
					if (col.name !== column.name || !col.status) {
						return true;
					} else {
						return false;
					}
				});
			});
		}
	} else if (action === ALL) {
		setFilter([]);
		setSearch([]);
	}
	setFetchList((prev) => !prev);
}
export function handleExtraFilterClear(
	column,
	filter,
	setFilter,
	setFetchList
) {
	if (filter?.find((col) => col.name === column.name && col.status)) {
		setFilter((prev) => {
			return prev.filter((col) => {
				if (col.name !== column.name || !col.status) {
					return true;
				} else {
					return false;
				}
			});
		});
		setFetchList((prev) => !prev);
	}
}
export function handleSearchClear(searchedQuery, setSearch, setFetchList) {
	setSearch((prev) => {
		return prev.filter(({ data }) => data !== searchedQuery);
	});
	setFetchList((prev) => !prev);
}

export function handleFilter(
	column,
	filterQuery,
	action,
	filter,
	setFilter,
	setFetchList
) {
	if (filter?.find((col) => col.name === column.key && col.status)) {
		if (action === APPLY) {
			setFilter((prev) => {
				return prev.map((col) => {
					if (col.name === column.key && col.status) {
						return {
							...col,
							data: filterQuery,
						};
					}
					return col;
				});
			});
		} else {
			setFilter((prev) => {
				return prev.filter((col) => {
					if (col.name !== column.key || !col.status) {
						return true;
					} else {
						return false;
					}
				});
			});
		}
	} else {
		setFilter((prev) => [
			...prev,
			{
				type: FILTER,
				status: true,
				data: filterQuery,
				name: column.key,
				columnTitle: column.title,
			},
		]);
	}
	setFetchList((prev) => !prev);
}
export function handleSort(
	column,
	sortOrder,
	sort,
	setSort,
	setReset,
	setTableSorted
) {
	let maxPriority = 0;
	for (let { priority } of sort) {
		if (priority > maxPriority) {
			maxPriority = priority;
		}
	}
	setSort((prev) => {
		return prev.map((col) => {
			if (col.name === column) {
				if (col.order === sortOrder) {
					//  reset
					setReset((prev) => {
						return {
							status: !prev.status,
							id: col.priority,
						};
					});
					return {
						...col,
						order: RESET,
						priority: null,
					};
				} else {
					// sort in sortOrder
					return {
						...col,
						order: sortOrder,
						priority: !col.priority ? maxPriority + 1 : col.priority,
					};
				}
			} else {
				return col;
			}
		});
	});
	setTableSorted((prev) => !Boolean(prev));
}

export function handlePaginationLink(
	action,
	setPagination,
	setFetchList,
	pagination
) {
	switch (action) {
		case PREVIOUS:
			if (pagination.currentPage === 1) {
				return;
			} else {
				setPagination((prev) => {
					return {
						...prev,
						currentPage: prev.currentPage - 1,
					};
				});
				return setFetchList((prev) => !prev);
			}
		case NEXT:
			if (
				pagination.recordPerPage * pagination.currentPage >=
				pagination.totalRecords
			) {
				return;
			} else {
				setPagination((prev) => {
					return {
						...prev,
						currentPage: prev.currentPage + 1,
					};
				});
				return setFetchList((prev) => !prev);
			}
		default:
			return;
	}
}
export function handleItemsPerPageChange(e, setPagination, setFetchList) {
	setPagination((prev) => {
		return {
			...prev,
			recordPerPage: parseInt(e.target.value),
			currentPage: 1,
		};
	});
	setFetchList((prev) => !prev);
}
function getFilterRequestBody(columns, filter, search, IS_SEARCH_ALLOWED) {
	let filterBody = {};
	for (const col of columns) {
		if (col.reqBodyKey.isAllowed === true) {
			filterBody[col.reqBodyKey.attrName] =
				filter && filter.length > ZERO
					? filter.find(({ name, status }) => name === col.key && status)
						? filter.find(({ name, status }) => name === col.key && status).data
						: ""
					: "";
		}
	}
	if (IS_SEARCH_ALLOWED === true) {
		filterBody.searchQuery =
			search.length > ZERO
				? search
						.filter((query) => query.status && query.data.length > ZERO)
						.map(({ data }) => data)
						.join(",")
				: "";
	}
	return filterBody;
}

export function getTableList(
	API_URL,
	filter,
	search,
	pagination,
	sort,
	dispatch,
	controller,
	authOrganizationName,
	dateRange,
	dateType,
	REQUEST_BODY_KEY,
	columns,
	IS_SEARCH_ALLOWED,
	CSTM_REQ_BODY,
) {
	let apiurl = process.env.REACT_APP_URL_DOMAIN + API_URL;

	headers.sessionId = skey();
	headers.requestAccess = getRightsFromSession();
	headers.organization = authOrganizationName;
	const requestHeaders = {
		headers,
	};

	let getGeneralListRequestBody = {
		requestorID: OFRI,
		messageVersion: process.env.REACT_APP_MESSAGE_VERSION_01,
		pagination: {
			recordPerPage: pagination.recordPerPage,
			currentPage: pagination.currentPage,
		},
		sort: sort
			.filter((sortObj) => {
				return sortObj.order && sortObj.priority;
			})
			.map((obj) => {
				return {
					name: obj.name,
					sortPriority: obj.priority.toString(),
					sortType:
						obj.order === RESET ? "" : obj.order === ASCENDING ? ASC : DESC,
				};
			}),
		[REQUEST_BODY_KEY]: getFilterRequestBody(
			columns,
			filter,
			search,
			IS_SEARCH_ALLOWED
		),
	};

	if (CSTM_REQ_BODY) {
		getGeneralListRequestBody = {
			...CSTM_REQ_BODY(getGeneralListRequestBody, dateType, dateRange),
		};
	}
	dispatch(
		getGenericTableDetails(
			requestHeaders,
			getGeneralListRequestBody,
			apiurl,
			controller
		)
	);
}
