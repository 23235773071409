import "./CustomOfferWrapper.style.scss";
import { useEffect, useState } from "react";
import OfferDetailsHeader from "../offerDetailsHeaderComponent/OfferDetailsHeader.jsx";
import OfferDetails from "../offerDetailsComponent/OfferDetails.jsx";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../../LoaderComponents/Loading.jsx";
import {
  CUSTOM_OFFER_LOADER_SOURCE,
  OFFER_LOADER_SOURCE,
} from "../../LoaderComponents/LoadingConstant.js";
import { ErrorBoundary } from "react-error-boundary";
import Error from "../../errorsComponent/Error.jsx";
import CustomOfferBuilder from "../customOfferSetupComponent/customOfferBuilder/CustomOfferBuilder.jsx";
import _ from "lodash";

import { ReactFlowProvider } from "reactflow";

import {
  OFFER_DETAILS_STATUS_DRAFT,
  OFRI,
  SUCCESS,
  headers,
} from "../../constantComponent/Constants.js";
import {
  changeDateDashFormat,
  checkRightsIsEditable,
  checkRightsIsVisible,
  getRightsFromSession,
  getRightsKey,
  skey,
} from "../../utilsComponent/CommonUtil.js";
import { OFFER, VIEW_OFFER } from "../../constantComponent/PathConstants.js";
import { useNavigate } from "react-router-dom";
import CustomOfferDetails from "../customOfferDetailsCompoenent/CustomOfferDetails.jsx";
import CustomOfferHeader from "../customOfferHeader/CustomOfferHeader.jsx";
import isEqual from "react-fast-compare";
import {
  fetchJourneyDetails,
  setCustomOfferChangedStatus,
} from "../../../tellurideExtRedux/journey/JourneyAction.js";
import UnauthorizedAccess from "../../unauthorizeComponent/UnauthorizeWrapper.jsx";

const CustomOfferWrapper = () => {
  //theme
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const offerDetailsPageData = useSelector((state) => state.offerDetailsData);
  const securityData = useSelector((state) => state.securityData);

  const [isDisabled, setIsDisabled] = useState(false);
	const [fetchDetailsFirst, setFetchDetailsFirst] = useState(null);


  const journeyDetailsData = useSelector((state) => state.journeyDetailsData);
  const toasterData = useSelector((state) => state.toasterData);
  const queryString = require("query-string");
  const journeyNumber = queryString.parse(window.location.search).offerid;
  const [theme, setTheme] = useState(toasterData.isDarkTheme);
  const [customOfferDetailsData, setCustomOfferDetailsData] = useState({});
  const [customOfferBuilderData, setCustomOfferBuilderData] = useState({
    journeyFlow: {
      nodes: [],
      edges: [],
    },
  });
  const [BACKEND_CUSTOM_OFFER_DATA, SET_BACKEND_CUSTOM_OFFER_DATA] = useState(
    {}
  );
  const [offerSetupDisabled, setOfferSetupDisabled] = useState();

  useEffect(() => {
    setTheme(toasterData.isDarkTheme);
  }, [toasterData]);
  useEffect(() => {
    //  Fetch Entities
		if (fetchDetailsFirst === null) {
    headers.sessionId = skey();
    headers.requestAccess = getRightsFromSession();
    headers.organization = offerDetailsPageData?.authOrganizationName;
    const requestHeaders = {
      headers,
    };
    const offerRequestBody = {
      requestorID: OFRI,
      messageVersion: process.env.REACT_APP_MESSAGE_VERSION_03,
      journeyNumber: journeyNumber ? journeyNumber : "",
    };
    dispatch(
      fetchJourneyDetails(requestHeaders, offerRequestBody, {
        navigate: navigate,
        successRedirect: "",
        errorRedirectionPath: OFFER + VIEW_OFFER,
      })
      );
      setFetchDetailsFirst(true);
    }
  }, []);
  //theme
  useEffect(() => {
    setIsDisabled(
      journeyDetailsData?.journeyDetailsResponse?.journeyData?.journeyStatus ===
        OFFER_DETAILS_STATUS_DRAFT
        ? false
        : true
    );
    if (
      journeyDetailsData?.journeyDetailsLoading === false &&
      journeyDetailsData?.journeyDetailsResponse &&
      journeyDetailsData?.journeyDetailsResponse?.status === SUCCESS
    ) {
      setCustomOfferDetailsData({
        startDate:
          journeyDetailsData?.journeyDetailsResponse?.journeyData
            ?.journeyStartDate,
        endDate:
          journeyDetailsData?.journeyDetailsResponse?.journeyData
            ?.journeyEndDate,
        createdDate:
          journeyDetailsData?.journeyDetailsResponse?.journeyData?.createdDate,
        description:
          journeyDetailsData?.journeyDetailsResponse?.journeyData
            ?.journeyDescription,
        offerID:
          journeyDetailsData?.journeyDetailsResponse?.journeyData
            ?.journeyNumber,
        journeyType:
          journeyDetailsData?.journeyDetailsResponse?.journeyData
            ?.journeyType === "CMP-EVENT" &&
          journeyDetailsData?.journeyDetailsResponse?.journeyAttributes?.journeyAttribute?.some(
            (attribute) => attribute?.name === "DISPLAY_OFFER_TYPE"
          )
            ? journeyDetailsData?.journeyDetailsResponse?.journeyAttributes?.journeyAttribute?.find(
                (attribute) => attribute?.name === "DISPLAY_OFFER_TYPE"
              ).values.value[0]
            : journeyDetailsData?.journeyDetailsResponse?.journeyData
                ?.journeyType,
      });
      if (
        journeyDetailsData?.journeyDetailsResponse?.journeyFlow?.nodes?.length >
          0 ||
        journeyDetailsData?.journeyDetailsResponse?.journeyFlow?.edges?.length >
          0
      ) {
        setCustomOfferBuilderData({
          journeyFlow: {
            nodes:
              journeyDetailsData?.journeyDetailsResponse?.journeyFlow?.nodes.map(
                (node) => {
                  return {
                    ...node,
                    data: {
                      // displayData: node.data.displayData,
                      type: node.data.type,
                      value: node.data.value,
                    },
                  };
                }
              ),
            edges:
              journeyDetailsData?.journeyDetailsResponse?.journeyFlow?.edges,
          },
        });
      }
      SET_BACKEND_CUSTOM_OFFER_DATA((prev) => {
        return {
          ...prev,
          description:
            journeyDetailsData?.journeyDetailsResponse?.journeyData
              ?.journeyDescription,
          startDate:
            journeyDetailsData?.journeyDetailsResponse?.journeyData
              ?.journeyStartDate,
          endDate:
            journeyDetailsData?.journeyDetailsResponse?.journeyData
              ?.journeyEndDate,
          nodes:
            journeyDetailsData?.journeyDetailsResponse?.journeyFlow?.nodes.map(
              (node) => {
                return {
                  ...node,
                  data: {
                    // displayData: node.data.displayData,
                    type: node.data.type,
                    value: node.data.value,
                  },
                };
              }
            ) || [],
          edges:
            journeyDetailsData?.journeyDetailsResponse?.journeyFlow?.edges ||
            [],
        };
      });
    }
  }, [journeyDetailsData?.journeyDetailsResponse]);

  useEffect(() => {
    if (
      journeyDetailsData?.journeyDetailsResponse?.journeyData?.journeyStatus ===
      OFFER_DETAILS_STATUS_DRAFT
    ) {
      let isOfferEdited = false;
      let editedCustomOfferJson = {
        description: customOfferDetailsData?.description,
        startDate: changeDateDashFormat(customOfferDetailsData?.startDate),
        endDate: changeDateDashFormat(customOfferDetailsData?.endDate),
        nodes: customOfferBuilderData?.journeyFlow?.nodes.map((node) => {
          let nodeObj = {};
          nodeObj.data = {
            // displayData: node.data.displayData,
            type: node.data.type,
          };
          nodeObj.id = node.id;
          if (node.data.value) {
            nodeObj.data.value = node.data.value;
          }
          return {
            ...nodeObj,
          };
        }),
        edges:
          customOfferBuilderData?.journeyFlow?.edges.map((edge) => {
            return {
              id: edge.id,
              source: edge.source,
              target: edge.target,
            };
          }) || [],
      };
      // Now ignore all bewlow keys
      if (isEqual(editedCustomOfferJson, BACKEND_CUSTOM_OFFER_DATA)) {
        isOfferEdited = false;
      } else {
        isOfferEdited = true;
        // dispatch(setOFferChangeStatus(true));
      }
      if (journeyDetailsData.isOfferChanged !== isOfferEdited) {
        dispatch(setCustomOfferChangedStatus(isOfferEdited));
      }
    }
  }, [customOfferDetailsData, customOfferBuilderData]);
  // useEffect(() => {
  //   if (customOfferDetailsData?.journeyType) {
  //     setOfferSetupDisabled(
  //       !checkRightsIsEditable(
  //         getRightsKey([
  //           "loyalty",
  //           "detail",
  //           "setup",
  //           customOfferDetailsData?.journeyType?.split(" ").join(""),
  //         ])
  //       )
  //     );
  //   }
  // }, [customOfferDetailsData?.journeyType]);
  useEffect(() => {
    if (customOfferDetailsData?.journeyType) {
      const isEditable = checkRightsIsEditable(
        getRightsKey([
          "loyalty",
          "detail",
          "setup",
          customOfferDetailsData?.journeyType?.split(" ").join(""),
        ])
      );
      if (
        journeyDetailsData?.journeyDetailsResponse?.journeyData
          ?.journeyStatus === OFFER_DETAILS_STATUS_DRAFT &&
        isEditable
      ) {
        setOfferSetupDisabled(false);
      } else {
        setOfferSetupDisabled(true);
      }
    }
  }, [customOfferDetailsData?.journeyType , isDisabled]);
  return (
    <ErrorBoundary FallbackComponent={Error} onError={(error) => {}}>
      <Loading source={CUSTOM_OFFER_LOADER_SOURCE}>
        {customOfferDetailsData?.journeyType &&
        checkRightsIsVisible(
          getRightsKey([
            "loyalty",
            "detail",
            "setup",
            customOfferDetailsData?.journeyType?.split(" ").join(""),
          ])
        ) ? (
          <div className="page-wrapper">
            {/* <OfferDetailsHeader /> */}
            <CustomOfferHeader
              isSetDisable={offerSetupDisabled}
              customOfferBuilderData={customOfferBuilderData}
              customOfferDetailsData={customOfferDetailsData}
            />
            <section className="display-flex display-wrapper">
              <div className="inner-section-wrapper" style={{ height: "100%" }}>
                <ReactFlowProvider>
                  <CustomOfferBuilder
                    isDisabled={offerSetupDisabled}
                    theme={theme}
                    customOfferBuilderData={customOfferBuilderData}
                    setCustomOfferBuilderData={setCustomOfferBuilderData}
                  />
                </ReactFlowProvider>
              </div>
              <div className="sticky-right offer-detail-desktop">
                {/* <OfferDetails /> */}
                <CustomOfferDetails
                  isSetDisable={offerSetupDisabled}
                  customOfferDetailsData={customOfferDetailsData}
                  setCustomOfferDetailsData={setCustomOfferDetailsData}
                />
              </div>
            </section>
          </div>
        ) : (
          <UnauthorizedAccess />
        )}
      </Loading>
    </ErrorBoundary>
  );
};
export default CustomOfferWrapper;
