import { DARK, DROPDOWN_BGCOLOR_DARK_THEME, DROPDOWN_FOCUSED_BGCOLOR_DARK_THEME, DROPDOWN_FOCUSED_BGCOLOR_LIGHT_THEME, DROPDOWN_SELECTED_BGCOLOR_DARK_THEME, DROPDOWN_SELECTED_BGCOLOR_LIGHT_THEME, LIGHT } from "../../constantComponent/Constants";

export const getCustomStyles = (theme) => {
	return {
		control: (provided, state) => ({
			border: "none",
			borderRadius: "4px",
			top: "2px",
			display: "flex",
			padding: "0px",
			outline: "none",
			height: "34px",
			position: "relative",
			top: "-8px",
			fontSize: 13,
			// Add more custom styles as needed
		}),
		option: (provided, state) => ({
			...provided,
			backgroundColor:
				state.isSelected && theme === LIGHT
					? DROPDOWN_SELECTED_BGCOLOR_LIGHT_THEME
					: state.isSelected && theme === DARK
					? DROPDOWN_SELECTED_BGCOLOR_DARK_THEME
					: // ? DROPDOWN_FOCUSED_BGCOLOR_LIGHT_THEME
					state.isFocused && theme === DARK
					? DROPDOWN_FOCUSED_BGCOLOR_DARK_THEME
					: state.isFocused && theme === LIGHT
					? DROPDOWN_FOCUSED_BGCOLOR_LIGHT_THEME
					: theme === DARK
					? DROPDOWN_BGCOLOR_DARK_THEME
					: "#fff",
			color: state.isSelected
				? "#fff"
				: state.isFocused && theme === DARK
				? "#fff"
				: state.isFocused && theme === LIGHT
				? DROPDOWN_SELECTED_BGCOLOR_LIGHT_THEME
				: theme === LIGHT
				? DROPDOWN_SELECTED_BGCOLOR_LIGHT_THEME
				: "#fff",
			padding: 8,
		}),
		// Add more style properties as needed
	};
};
