import axios from "axios";
import { setErrorToaster, setSuccessToaster } from "..";
import {
  OFFER_DETAILS_STATUS_DRAFT,
  TOASTER_SUCCESS,
} from "../../tellurideExtComponents/constantComponent/Constants";
import {
  EDITABLE,
  OFFER_SAVE,
  OFFER_STATUS_UPDATE_MESSAGE,
  OFFER_STATUS_UPDATE_SUCCESS_MESSAGE,
  TELL400,
  TELL401,
  TELL98,
} from "../../tellurideExtComponents/errorsComponent/ErrorConstant";

import {
  GET_OFFER_STATUS_STATS_REQUEST,
  GET_OFFER_STATUS_STATS_SUCCESS,
  GET_OFFER_STATUS_STATS_FAILURE,
  GET_OFFER_STATUS_STATS_SUCCESS_FAILURE,
  GET_EXPIRING_OFFER_REQUEST,
  GET_EXPIRING_OFFER_SUCCESS,
  GET_EXPIRING_OFFER_FAILURE,
  GET_EXPIRING_OFFER_SUCCESS_FAILURE,
  GET_OFFER_ACTIVITY_REQUEST,
  GET_OFFER_ACTIVITY_SUCCESS,
  GET_OFFER_ACTIVITY_SUCCESS_FAILURE,
  GET_OFFER_ACTIVITY_FAILURE,
  SET_BLANK_SESSION_ID_ERROR,
  SET_SESSION_TIMEOUT_POPUP,
  GET_OFFER_STATISTICS_REQUEST,
  GET_OFFER_STATISTICS_FAILURE,
  GET_OFFER_STATISTICS_SUCCESS_FAILURE,
  GET_OFFER_STATISTICS_SUCCESS,
} from "./OfferAnalyticsType";
import {
  ERROR_MESSAGE,
  setBlankSession,
  setSessionExpire,
  setToaster,
} from "../reduxUtil";

export const fetchOfferStatusStats = (requestHeaders, requestBody) => {
  return (dispatch) => {
    dispatch(fetchOfferStatusStatsRequest());
    axios
      .post(
        process.env.REACT_APP_URL_DOMAIN +
          process.env.REACT_APP_GET_OFFER_STATUS_STATS,
        requestBody,
        requestHeaders
      )
      .then((resp) => {
        if (resp.data.errors !== undefined && resp.data.errors.length !== 0) {
          dispatch(fetchOfferStatusStatsSuccessFailure(resp.data));
          dispatch(setErrorToaster(resp.data.errors[0].message));
          setSessionExpire(resp, setSessionExpirePopUp, dispatch);
        } else {
          dispatch(fetchOfferStatusStatsSuccess(resp.data));
        }
      })
      .catch((err) => {
        dispatch(fetchOfferStatusStatsFailure(err.message));
        setToaster(err, ERROR_MESSAGE, dispatch);
        setSessionExpire(err, setSessionExpirePopUp, dispatch);
        setBlankSession(err, dispatch, requestHeaders, setBlankSessionIdError);
      });
  };
};

const fetchOfferStatusStatsRequest = () => {
  return {
    type: GET_OFFER_STATUS_STATS_REQUEST,
  };
};

const fetchOfferStatusStatsSuccess = (apiData) => {
  return {
    type: GET_OFFER_STATUS_STATS_SUCCESS,
    payload: apiData,
  };
};

const fetchOfferStatusStatsFailure = (error) => {
  return {
    type: GET_OFFER_STATUS_STATS_FAILURE,
    payload: error,
  };
};
const fetchOfferStatusStatsSuccessFailure = (error) => {
  return {
    type: GET_OFFER_STATUS_STATS_SUCCESS_FAILURE,
    payload: error,
  };
};

export const fetchOfferExpirtyStats = (requestHeaders, requestBody) => {
  return (dispatch) => {
    dispatch(fetchOfferExpirtyStatsRequest());
    axios
      .post(
        process.env.REACT_APP_URL_DOMAIN +
          process.env.REACT_APP_GET_EXPIRING_OFFER_STATS,
        requestBody,
        requestHeaders
      )
      .then((resp) => {
        if (resp.data.errors !== undefined && resp.data.errors.length !== 0) {
          dispatch(fetchOfferExpirtyStatsSuccessFailure(resp.data));
          dispatch(setErrorToaster(resp.data.errors[0].message));
          setSessionExpire(resp, setSessionExpirePopUp, dispatch);
        } else {
          dispatch(fetchOfferExpirtyStatsSuccess(resp.data));
        }
      })
      .catch((err) => {
        dispatch(fetchOfferExpirtyStatsFailure(err.message));
        setToaster(err, ERROR_MESSAGE, dispatch);
        setSessionExpire(err, setSessionExpirePopUp, dispatch);
        setBlankSession(err, dispatch, requestHeaders, setBlankSessionIdError);
      });
  };
};

const fetchOfferExpirtyStatsRequest = () => {
  return {
    type: GET_EXPIRING_OFFER_REQUEST,
  };
};

const fetchOfferExpirtyStatsSuccess = (apiData) => {
  return {
    type: GET_EXPIRING_OFFER_SUCCESS,
    payload: apiData,
  };
};

const fetchOfferExpirtyStatsFailure = (error) => {
  return {
    type: GET_EXPIRING_OFFER_FAILURE,
    payload: error,
  };
};
const fetchOfferExpirtyStatsSuccessFailure = (error) => {
  return {
    type: GET_EXPIRING_OFFER_SUCCESS_FAILURE,
    payload: error,
  };
};

export const fetchOfferActivityStats = (requestHeaders, requestBody) => {
  return (dispatch) => {
    dispatch(fetchOfferActivityStatsRequest());
    axios
      .post(
        process.env.REACT_APP_URL_DOMAIN +
          process.env.REACT_APP_GET_OFFER_ACTIVITY_STATS,
        requestBody,
        requestHeaders
      )
      .then((resp) => {
        if (resp.data.errors !== undefined && resp.data.errors.length !== 0) {
          dispatch(fetchOfferActivityStatsSuccessFailure(resp.data));
          dispatch(setErrorToaster(resp.data.errors[0].message));
          setSessionExpire(resp, setSessionExpirePopUp, dispatch);
        } else {
          dispatch(fetchOfferActivityStatsSuccess(resp.data));
        }
      })
      .catch((err) => {
        dispatch(fetchOfferActivityStatsFailure(err.message));
        setToaster(err, ERROR_MESSAGE, dispatch);
        setSessionExpire(err, setSessionExpirePopUp, dispatch);
        setBlankSession(err, dispatch, requestHeaders, setBlankSessionIdError);
      });
  };
};

const fetchOfferActivityStatsRequest = () => {
  return {
    type: GET_OFFER_ACTIVITY_REQUEST,
  };
};

const fetchOfferActivityStatsSuccess = (apiData) => {
  return {
    type: GET_OFFER_ACTIVITY_SUCCESS,
    payload: apiData,
  };
};

const fetchOfferActivityStatsFailure = (error) => {
  return {
    type: GET_OFFER_ACTIVITY_FAILURE,
    payload: error,
  };
};
const fetchOfferActivityStatsSuccessFailure = (error) => {
  return {
    type: GET_OFFER_ACTIVITY_SUCCESS_FAILURE,
    payload: error,
  };
};
export const setBlankSessionIdError = () => {
  return {
    type: SET_BLANK_SESSION_ID_ERROR,
  };
};
export const setSessionExpirePopUp = () => {
  return {
    type: SET_SESSION_TIMEOUT_POPUP,
  };
};

export const fetchOfferStatistics = (requestHeaders, requestBody,setStatsPopover) => {
  return (dispatch) => {
    dispatch(fetchOfferStatisticsRequest());
    axios
      .post(
        process.env.REACT_APP_URL_DOMAIN +
          process.env.REACT_APP_GET_OFFER_STATISTICS,
        requestBody,
        requestHeaders
      )
      .then((resp) => {
        if (resp.data.errors !== undefined && resp.data.errors.length !== 0) {
          setStatsPopover(false)
          dispatch(fetchOfferStatisticsSuccessFailure(resp.data));
          dispatch(setErrorToaster(resp.data.errors[0].message));
          setSessionExpire(resp, setSessionExpirePopUp, dispatch);
        } else {
          dispatch(fetchOfferStatisticsSuccess(resp.data));
        }
      })
      .catch((err) => {
        setStatsPopover(false)
        dispatch(fetchOfferStatisticsFailure(err.message));
        setToaster(err, ERROR_MESSAGE, dispatch);
        setSessionExpire(err, setSessionExpirePopUp, dispatch);
        setBlankSession(err, dispatch, requestHeaders, setBlankSessionIdError);
      });
  };
};

const fetchOfferStatisticsRequest = () => {
  return {
    type: GET_OFFER_STATISTICS_REQUEST,
  };
};

const fetchOfferStatisticsSuccess = (apiData) => {
  return {
    type: GET_OFFER_STATISTICS_SUCCESS,
    payload: apiData,
  };
};

const fetchOfferStatisticsFailure = (error) => {
  return {
    type: GET_OFFER_STATISTICS_SUCCESS_FAILURE,
    payload: error,
  };
};
const fetchOfferStatisticsSuccessFailure = (error) => {
  return {
    type: GET_OFFER_STATISTICS_FAILURE,
    payload: error,
  };
};
