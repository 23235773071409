import axios from "axios";
import {
	ERR_CANCELED,
	ERROR_MESSAGE,
	setBlankSession,
	setSessionExpire,
	setToaster,
} from "../reduxUtil";
import {
	GET_TABLE_LIST_FAILURE,
	GET_TABLE_LIST_REQUEST,
	GET_TABLE_LIST_SUCCESS,
	GET_TABLE_LIST_SUCCESS_FAILURE,
	RESET_TABLE_LIST_DATA,
	SET_TABLE_LIST_SAVED_FILTER,
} from "./GenericTableDetailsType";
import { setErrorToaster } from "../toaster/ToasterAction";
import {
	setBlankSessionIdError,
	setSessionExpirePopUp,
} from "../metaData/MetaDataAction";

export const getGenericTableDetails = (
	requestHeaders,
	requestBody,
	apiurl,
	controller
) => {
	return async (dispatch) => {
		dispatch(getGenericTableRequest());
		try {
			const resp = await axios.post(apiurl, requestBody, {
				...requestHeaders,
				signal: controller.signal,
			});
			if (resp.data.errors !== undefined && resp.data.errors.length !== 0) {
				dispatch(getGenericTableSuccessFailure(resp.data));
				dispatch(setErrorToaster(resp.data.errors[0].message));
				setSessionExpire(resp, setSessionExpirePopUp, dispatch);
			} else {
				dispatch(getGenericTableSuccess(resp.data));
			}
		} catch (err) {
			if (err.code !== ERR_CANCELED) {
				setToaster(err, ERROR_MESSAGE, dispatch);
				dispatch(getGenericTableFailure(err.message));
			}
			setSessionExpire(err, setSessionExpirePopUp, dispatch);
			setBlankSession(err, dispatch, requestHeaders, setBlankSessionIdError);
		}
	};
};
export const getGenericTableRequest = () => {
	return {
		type: GET_TABLE_LIST_REQUEST,
	};
};

export const getGenericTableSuccess = (apiData) => {
	return {
		type: GET_TABLE_LIST_SUCCESS,
		payload: apiData,
	};
};
export const getGenericTableFailure = (apiData) => {
	return {
		type: GET_TABLE_LIST_FAILURE,
		payload: apiData,
	};
};
export const getGenericTableSuccessFailure = (apiData) => {
	return {
		type: GET_TABLE_LIST_SUCCESS_FAILURE,
		payload: apiData,
	};
};
export const setGenericTableSavedFilter = (key, value) => {
	return {
		type: SET_TABLE_LIST_SAVED_FILTER,
		payload: { key, value },
	};
};
export const resetGenericTableData = () => {
	return {
		type: RESET_TABLE_LIST_DATA,
	};
};
