export const AWARD_HEADER = "Custom award details";
export const FLAT = "Flat";
export const MULTIPLIER = "Multiplier";
export const OFFER_FOR = "OFFER_FOR";
export const OFFER_RANGE = "OFFER_RANGE";
export const TIER = "Tier";
export const MIN = "MIN";
export const MIN_TITLE = "Min";
export const MAX = "MAX";
export const MAX_TITLE = "Max";
export const POINTS_TITLE = "Points";
export const FLAT_VALUE = "FLAT";
export const FOR_EVERY = "FOR_EVERY";
export const CAP = "CAP";
export const SPEND_THRESHOLD = "SPEND_THRESHOLD";
export const OFFER_THRESHOLD = "OFFER_THRESHOLD";
export const VALUE = "VALUE";
export const SPEND = "Spend";
export const ITEM = "Item";
export const QUANTITY = "Quantity";
export const TRIP = "Trip";

export const offerRangeOptions = [
  { value: "Flat", label: "Fixed " },
  { value: "Multiplier", label: "Points Multiplier" },
];
export const offerRangeAlias = {
  Flat: "Fixed ",
  Multiplier: "Points Multiplier",
  Fixed: "Flat",
  "Points Multiplier": "Multiplier",
};
export const offerForOptions = [
  { value: "Spend", label: "Spend" },
  { value: "Quantity", label: "Purchase Quantity" },
];
export const offerForAlias = {
  Spend: "Spend",
  Quantity: "Purchase Quantity",
  "Purchase Quantity": "Quantity",
};

export const SPENT_OFFER_FOR = "Spend";
export const thresholdLabel = {
  Spend: "Spend",
  Quantity: "Purchase Quantity",
  Trip: "Transaction Count",
};

export const infoOfferFor = {
  Spend:
    "Offer will be awarded when at least this amount is spent on a transaction (before taxes)",
  Quantity:
    "Offer will be awarded when at least this number of items are purchased",
  Trip: "Offer will be awarded when this number of transactions is reached",
};
export const REDEEM_TITLE = "What to award as loyalty cash";
export const BASE_REDEEM_TITLE = "What to redeem";
export const OFFER_FOR_TITLE = "Event Type";
export const OFFER_RANGE_TITLE = "Points Award Type";
export const OFFER_CATEGORY = "Offer Category";
