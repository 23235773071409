import {
  groupPeople,
  subClubIcon,
  textIcon,
} from "../../../utilsComponent/SidebarIcons";
import SubClubComponent from "./subclubPopupComponent/SubclubPopupComponent";

export const popupConfig = {
  tootltip: true,
  theme: "",
  selectedDataKey: "",
  isDisabled: false,
};
export const subclub_columns = [
  {
    title: "Subclub Code",
    key: "code",
    type: "string",
    visible: true,
  },
  {
    title: "Subclub Name",
    key: "name",
    type: "string",
    visible: true,
  },
  {
    title: "Created By",
    key: "createdBy",
    type: "string",
    visible: true,
  },
];
export const offer_selection_columns = [
  {
    title: "Offer Name",
    key: "name",
    type: "string",
    visible: true,
  },
  {
    title: "Status",
    key: "status",
    type: "string",
    visible: true,
  },
  {
    title: "Offer ID",
    key: "id",
    type: "string",
    visible: true,
  },
];

export const triggerEvents = [
  {
    eventName: "browse_product",
    displayName: "Join Subclub",
    key: "crme",
    type: "crme",
    icon: subClubIcon,
    onClick: SubClubComponent,
  },
  {
    eventName: "enroll_member",
    displayName: "Enroll Member",
    key: "crme",
    type: "crme",
    icon: groupPeople,
    onClick: "eventListener",
  },
];

export const customEventLogo = {
  join_subclub: subClubIcon,
  enroll_member: groupPeople,
};

export const subclub_triggerPoint = "browse_product"
export const enroll_member_triggerPoint = "crme"