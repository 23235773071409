import React, { useEffect, useState } from "react";
import SkeletonTableLoading from "../../LoaderComponents/SkeletonTableLoading";
import Skeleton from "react-loading-skeleton";
import { useSelector } from "react-redux";
import { DARK } from "../../constantComponent/Constants";
import { emptyData } from "../../constantComponent/DesignConstant";
import { ZERO } from "../GenericRules/GenericRulesConstants";
import "./SmallGenericTable.style.scss";

function SmallGenericTableBody({
	tableData,
	columns,
	isDataLoading,
	isRowMultiSelectable,
	selectedData,
	config,
	rowClickHandler,
  pagination,
}) {
	return (
		<div className="attribute-list">
			{isDataLoading === true ? (
				<div className="loading-container">
					{[...Array(pagination?.recordPerPage * 2)].map((loading) => (
						<div className="loading-columns">
							{[...Array(3)].map((col) => (
								<div className="loading-column">
									<Skeleton
										baseColor={config.theme === DARK ? "#202020" : ""}
										highlightColor={config.theme === DARK ? "#444" : ""}
									/>
								</div>
							))}
						</div>
					))}
				</div>
			) : tableData && tableData.length > ZERO ? (
				tableData.map((row) => {
					const selectedValExists = isRowMultiSelectable
						? selectedData.some(
								(item) => item.code === row[config.selectedDataKey]
						  )
						: "";

					return (
						<>
							<div
								key={row.id}
								className={` ${
									isRowMultiSelectable
										? selectedValExists
											? "row small-generic-table selected"
											: "row small-generic-table"
										: selectedData &&
										  selectedData.includes(row[config.selectedDataKey])
										? "row small-generic-table selected"
										: "row small-generic-table"
								} ${config.border ? "border" : ""}`}
								onClick={() => {
									if (!config.isDisabled && !isRowMultiSelectable) {
										rowClickHandler(row);
									} else if (isRowMultiSelectable) {
										rowClickHandler(row?.groupName, row?.groupName);
									}
								}}
							>
								{isRowMultiSelectable && (
									<div className="checkbox-container">
										<input
											type="checkbox"
											checked={
												selectedData.find(
													(selectedOption) =>
														selectedOption.code === row?.groupName
												)
													? true
													: false
											}
										/>
									</div>
								)}
								{columns.map((col, colIndex) => {
									if (row.hasOwnProperty(col.key) && col.visible === true) {
										return (
											<>
												<div className={`data`} key={colIndex}>
													<div
														data={row[col.key]}
														className={`
                            ${
															col?.rowConfig?.color
																? "row-data-status complete generic-table-row-data"
																: "row-data generic-table-row-data"
														} ${col?.rowConfig?.className}`}
														title={`${
															row[col.key] ? row[col.key] : row[col.subKey]
														}`}
													>
														{`${row[col.key] ? row[col.key] : row[col.subKey]}`}
													</div>
												</div>
											</>
										);
									} else if (col.visible === true) {
										return (
											<div className="data" key={col.key}>
												{
													<div className="row-data generic-table-row-data">
														{col?.rowConfig?.data ? col?.rowConfig?.data : ""}
													</div>
												}
											</div>
										);
									}
								})}
							</div>
						</>
					);
				})
			) : (
				emptyData()
			)}
		</div>
	);
}

export default React.memo(SmallGenericTableBody);
