import {
  BASE_REDEEM,
  DARK,
  DROPDOWN_BGCOLOR_DARK_THEME,
  DROPDOWN_FOCUSED_BGCOLOR_DARK_THEME,
  DROPDOWN_FOCUSED_BGCOLOR_LIGHT_THEME,
  DROPDOWN_SELECTED_BGCOLOR_DARK_THEME,
  DROPDOWN_SELECTED_BGCOLOR_LIGHT_THEME,
  LIGHT,
} from "../../constantComponent/Constants";
import { onKeyPressAction } from "../../utilsComponent/CommonUtil";
import {
  MULTIPLIER,
  QUANTITY,
  SPEND,
  SPENT_OFFER_FOR,
  TRIP,
  thresholdLabel,
} from "./RedeemConstant";
import { NumericFormat } from "react-number-format";

export const GenerateNewData = () => {
  const newData = {
    actionID: "ACTION-1",
    actionName: "XR",
    actionProperties: {
      actionProperty: [
        {
          property: [
            {
              name: "MIN",
              values: {
                value: [],
              },
            },
            {
              name: "VALUE",
              values: {
                value: [],
              },
            },
          ],
          propertyType: "Tier",
        },
      ],
    },
  };

  return newData;
};
export const generateInitialJSON = (offerType) => {
  const jsonData = {
    action: [
      {
        actionID: "ACTION-1",
        actionName: "XR",
        actionProperties: {
          actionProperty: [
            {
              property: [
                {
                  name: "MEMBER_POINTS_CAP",
                  values: {
                    value: [""],
                  },
                },
              ],
              propertyType: "CAP",
            },
            {
              property: [
                {
                  name: "OFFER_FOR",
                  values: {
                    value: ["Spend"],
                  },
                },
                {
                  name: "OFFER_RANGE",
                  values: {
                    value: [offerType === BASE_REDEEM ? "Multiplier" : "Flat"],
                  },
                },
                {
                  name: "OFFER_THRESHOLD",
                  values: {
                    value: [""],
                  },
                },
              ],
              propertyType: null,
            },
            {
              property: [
                {
                  name: "VALUE",
                  values: {
                    value: [""],
                  },
                },
                {
                  name: "MIN",
                  values: {
                    value: ["0.01"],
                  },
                },
                {
                  name: "CAP",
                  values: {
                    value: [""],
                  },
                },
              ],
              propertyType: "TIER",
            },
          ],
        },
      },
    ],
  };

  return jsonData;
};
export const formatAmount = (e) => {
  let value = e.target.value.replace(`,`, "", -1);
  if (value !== "") {
    let numericValue = value.replace(/[^0-9.]/g, "");
    const match = /(\d{0,7})[^.]*((?:\.\d{0,3})?)/g.exec(
      value.replace(/[^\d.]/g, "")
    );
    numericValue = match[1] + match[2];
    if (
      numericValue.charAt(numericValue.length - 1) !== "." &&
      !numericValue.startsWith("0.") &&
      !numericValue.split(".")[1]?.includes("0")
    ) {
      let formattedValue = parseFloat(numericValue).toLocaleString("en-US", {
        currency: "USD",
      });
      if (numericValue.endsWith(".00") || numericValue.endsWith(".0")) {
        numericValue.endsWith(".00")
          ? (formattedValue = formattedValue + ".00")
          : (formattedValue = formattedValue + ".0");
      }
      return formattedValue !== "NaN" ? formattedValue : "";
    } else {
      return numericValue;
    }
  }
};

export const validateNumericValue = (e, offerRangeValue) => {
  if (parseInt(e.target.value.replace(/,/g, ""), 10) > 2147483647)
    return offerRangeValue;
  if (e.target.value !== "") {
    const numericValue = e.target.value.replace(/[^0-9]/g, "");
    if (numericValue) {
      const formattedValue = parseFloat(numericValue).toLocaleString("en-US", {
        currency: "USD",
        maximumFractionDigits: 3,
      });

      return formattedValue;
    }
    return numericValue;
  }
};

export const getInputOfferFor = (
  offerFor,
  offerForValue,
  setOfferForValue,
  isSetDisable
) => {
  let legendText = offerFor;
  let maxAllowedLength = offerFor === TRIP || offerFor === QUANTITY ? 8 : "";
  return (
    <div className="col-1">
      <div className="outer-input">
        <fieldset className="reward-fieldset" disabled={isSetDisable}>
          <legend>{thresholdLabel[offerFor]} Threshold</legend>
          {offerFor === SPENT_OFFER_FOR && (
            <input
              className="dollar dollar-spend"
              type="text"
              defaultValue={"$"}
              readOnly
            ></input>
          )}
          {offerFor === SPENT_OFFER_FOR && (
            <NumericFormat
              className="amount"
              value={offerForValue}
              decimalScale={3}
              thousandsGroupStyle="lakh"
              thousandSeparator=","
              displayType="input"
              renderText={(value) => <b>{value}</b>}
              onKeyPress={(event) => {
                //To block -ve sign
                if (event.charCode === 45) {
                  event.preventDefault();
                }
              }}
              isAllowed={(values) => {
                const { floatValue } = values;
                return floatValue < 9999999.9999 || floatValue === undefined;
              }}
              onValueChange={(values, sourceInfo) => {
                setOfferForValue(values.value);
              }}
            />
          )}
          {offerFor !== SPENT_OFFER_FOR && (
            <NumericFormat
              className="amount"
              value={offerForValue}
              thousandsGroupStyle="lakh"
              thousandSeparator=","
              displayType="input"
              decimalScale={0}
              renderText={(value) => <b>{value}</b>}
              onKeyPress={(event) => {
                //To block -ve sign
                if (event.charCode === 45) {
                  event.preventDefault();
                }
              }}
              isAllowed={(values) => {
                const { floatValue } = values;
                return floatValue < 10000000 || floatValue === undefined;
              }}
              onValueChange={(values, sourceInfo) => {
                setOfferForValue(values.value);
              }}
            />
          )}
          {/* <span>Spend Threshold</span> */}
        </fieldset>
      </div>
    </div>
  );
};

export const getInputOfferRange = (
  offerRange,
  offerRangeValue,
  setOfferRangeValue,
  isSetDisable,
  legendText
) => {
  //let legendText = offerRange === MULTIPLIER ? "Per Dollar" : "";
  return (
    <div className="col-1">
      <div className="outer-input">
        <fieldset className="reward-fieldset" disabled={isSetDisable}>
          <legend>{legendText}</legend>
          <input
            className="dollar dollar-spend"
            type="text"
            defaultValue={"$"}
            readOnly
          ></input>
          <NumericFormat
            value={offerRangeValue}
            decimalScale={2}
            thousandsGroupStyle="lakh"
            thousandSeparator=","
            displayType="input"
            renderText={(value) => <b>{value}</b>}
            onValueChange={(values, sourceInfo) => {
              setOfferRangeValue(values.value);
            }}
            onKeyPress={(event) => {
              //To block -ve sign
              if (event.charCode === 45) {
                event.preventDefault();
              }
            }}
            isAllowed={(values) => {
              const { floatValue } = values;
              return floatValue < 100000000 || floatValue === undefined;
            }}
            className="amount"
          />
        </fieldset>
      </div>
    </div>
  );
};

export const getCustomStyles = (theme) => {
  return {
    control: (provided, state) => ({
      border: "none",
      borderRadius: "4px",
      top: "2px",
      display: "flex",
      padding: "0px",
      outline: "none",
      height: "34px",
      position: "relative",
      top: "-8px",
      // Add more custom styles as needed
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor:
        state.isSelected && theme === LIGHT
          ? DROPDOWN_SELECTED_BGCOLOR_LIGHT_THEME
          : state.isSelected && theme === DARK
          ? DROPDOWN_SELECTED_BGCOLOR_DARK_THEME
          : // ? DROPDOWN_FOCUSED_BGCOLOR_LIGHT_THEME
          state.isFocused && theme === DARK
          ? DROPDOWN_FOCUSED_BGCOLOR_DARK_THEME
          : state.isFocused && theme === LIGHT
          ? DROPDOWN_FOCUSED_BGCOLOR_LIGHT_THEME
          : theme === DARK
          ? DROPDOWN_BGCOLOR_DARK_THEME
          : "#fff",
      color: state.isSelected
        ? "#fff"
        : state.isFocused && theme === DARK
        ? "#fff"
        : state.isFocused && theme === LIGHT
        ? DROPDOWN_SELECTED_BGCOLOR_LIGHT_THEME
        : theme === LIGHT
        ? DROPDOWN_SELECTED_BGCOLOR_LIGHT_THEME
        : "#fff",
      padding: 8,
    }),
    // Add more style properties as needed
  };
};
