import { MdShoppingCartCheckout } from "react-icons/md";
import {
  delayIcon,
  emailCampaign2Icon,
  emailCampaignIcon,
  emailIcon,
  exitIcon,
  groupPeople,
  pie,
  pushNotificationsIcon,
  segment2Icon,
  segmentIcon,
  segmentationIcon,
  stackIcon,
  textIcon,
} from "../../utilsComponent/SidebarIcons";
import { PiBrowsersFill } from "react-icons/pi";
import {
  generateUniqueId,
  generateUniqueVariationId,
} from "../../utilsComponent/CommonUtil";
import SegmentPopupComponent from "./campaignPopups/segmentPopupComponent/SegmentPopupComponent";
import EmailContentSelectionPopup from "./campaignPopups/EmailContentSelectionPopup/EmailContentSelectionPopup";
import SelectDelayComponent from "./campaignPopups/SelectDelayComponent/SelectDelayComponent";
import EventSelectionComponent from "./campaignPopups/EventSelectionPopUp/EventSelectionComponent";
import { RxExit } from "react-icons/rx";
import { IoMdAlarm } from "react-icons/io";
import { BiMobileAlt } from "react-icons/bi";
import { BsChat } from "react-icons/bs";
import { HiOutlineMail } from "react-icons/hi";
import {
  OFFER_DETAILS_STATUS_AB_TEST,
  OFFER_DETAILS_STATUS_ACTIVATED,
  OFFER_DETAILS_STATUS_DEACTIVATED,
  OFRI,
} from "../../constantComponent/Constants";
import {
  ACTIVATE_CONFIRMATION_MESSAGE,
  DEACTIVATE_CONFIRMATION_MESSAGE,
  SAVE_AND_ACTIVATE_CONFIRMATION_MESSAGE,
  START_AB_TEST_CONFIRMATION_MESSAGE,
  START_AB_TEST_MESSAGE,
} from "../../constantComponent/MessageConstant";

export const initialNodes = [
  {
    id: "N1",
    position: {
      x: 0,
      y: 0,
    },
    data: {
      type: "",
      displayData: {
        defaultValue: "",
      },
      value: null,
      displayProperties: {
        nodeType: "Child",
      },
    },
    deletable: false,
    draggable: false,
    type: "nodeType",
  },
];
export const initialEdges = [];
export const nodeDataObj = {
  segment: {
    name: "groupName",
    count: "memberCount",
    icon1: pie,
    icon2: groupPeople,
    defaultValue: "Choose Segment",
    requiredValuesInNode: [
      "groupId",
      "groupName",
      "memberCount",
      "countInTheList",
    ],
    iconColor: "#fff",
    iconBgColor: "#2962ff",
    headerBgColor: "#f4f6ff",
    headerTextColor: "#2962ff",
    dataLabel: [
      { key: "Name", value: "groupName" },
      { key: "Count", value: "memberCount" },
    ],
    resize: false,
  },
  email: {
    name: "name",
    templateId: "templateId",
    icon1: emailIcon,
    icon2: stackIcon,
    defaultValue: "Choose Email",
    requiredValuesInNode: [
      "name",
      "contentId",
      "subject",
      "templateId",
      "senderDisplayName",
      "senderEmail",
    ],
    // Add green color
    iconColor: "#fff",
    iconBgColor: "#1aad95",
    headerBgColor: "#eef8f7",
    headerTextColor: "#1aad95",
    dataLabel: [
      {
        key: "Name",
        value: "contentName",
      },
      {
        key: "Layout",
        value: "templateId",
      },
    ],
    resize: {
      status: false,
      minWidth: "35rem",
      maxWidth: "70%",
    },
  },
  start_trigger: {
    name: "triggerPoint",
    // count: "memberCount",
    icon1: pie,
    defaultValue: "Choose Event",
    requiredValuesInNode: ["triggerPoint"],
    iconColor: "#fff",
    iconBgColor: "#2962ff",
    headerBgColor: "#f4f6ff",
    headerTextColor: "#2962ff",
    dataLabel: [{ key: "Event", value: "triggerPoint" }],
    resize: false,
  },
  wait: {
    name: "duration",
    icon1: delayIcon,
    defaultValue: "Choose Delay",
    requiredValuesInNode: ["duration"],
    iconColor: "#fff",
    iconBgColor: "#ef8f03",
    headerBgColor: "#fff9ed",
    headerTextColor: "#ef8f03",
    dataLabel: [
      {
        key: "Delay",
        value: "duration",
      },
      // {
      //   key: "Days",
      //   value: "days",
      // },
      // {
      //   key: "Hours",
      //   value: "hours",
      // },
      // {
      //   key: "Minutes",
      //   value: "minutes",
      // },
    ],
    resize: false,
  },
};
export const componentNameByType = {
  segment: SegmentPopupComponent,
  email: EmailContentSelectionPopup,
  wait: SelectDelayComponent,
  start_trigger: EventSelectionComponent,
};

export const SVG_ICONS = {
  segment: {
    icon1: pie,
    icon2: groupPeople,
  },
  send_email: {
    icon1: emailIcon,
    icon2: stackIcon,
  },
};
export const SEGMENT_USE_TITLE = "Which segment to use";
export const EVENT_USE_TITLE = "Which event to use";
export const CAMPAIGN_ACTION = "Select action";
export const CAMPAIGN_ACTION_TYPES_MESSAGING = [
  {
    title: "Email",
    key: "1",
    disabled: false,
    value: "email",
  },
  {
    title: "Text",
    key: "2",
    disabled: true,
    value: "text",
  },
  {
    title: "Push Notification",
    key: "3",
    disabled: true,
    value: "push-notification",
  },
];
export const CAMPAIGN_ACTION_TYPES_FLOW_CONTROL = [
  {
    title: "Exit",
    key: "1",
    disabled: true,
    value: "exit",
  },
];
export const CAMPAIGN_ACTION_TYPES_WAITS = [
  {
    title: "Time Delay",
    key: "1",
    disabled: false,
    value: "WAIT",
  },
];

export const EMAIL = "email";
export const WAIT = "wait";
export const SEGMENT = "segment";
export const START = "START";
export const CAMPAIGN_ACTION_TYPE = "campaign-action";
export const SEND_EMAIL = "email";
export const POPUP_HEADER_SEGMENT = "Segment";
export const CREATED_BY = "Created By";
export const MEMBER_COUNT = "Member Count";
export const SENDER_DISPLAY_NAME = "Sender Display Name";
export const SENDER_EMAIL = "Sender Email";
export const CAMPAIGN_ACTION_TITLE_1 = "Messaging";
export const CAMPAIGN_ACTION_TITLE_2 = "Flow control";
export const CAMPAIGN_ACTION_TITLE_3 = "Delays";
export const EMAIL_VERIFICATION_REGEX = "[a-z0-9]+@[a-z]+.[a-z]{2,3}";
export const SEARCH_FOR_CONTENT = "Search Contents";
export const CONTENT_NAME = "Content Name";
export const CONTENT_ID = "Content ID";
export const CONTENT_TYPE_INFO_1 =
  "Not seeing the content you are looking for?";
export const CONTENT_TYPE_INFO_2 = "Create new content here";
export const SUBJECT_LINE = "Subject Line";
export const TEMPLATE = "Template";
export const EMAIL_ERROR_MSG = "Enter valid email address";
export const BACK = "Back";
export const PROCEED = "Proceed";
export const MODIFY = "Modify";
export const SAVE = "Save";
export const CAMPAIGN_BUILDER_HEADER = "Campaign Builder";
export const displayNameTemp = {
  "test@shopyourway.com": "Test",
};
export const SUBJECTLINE = "SUBJECT_LINE";
export const NONE = "NONE";
export const campaignNodeTypes = {
  "CMP-BATCH": {
    nodesAllowed: 1,
    actionName: "SEGMENT",
    campaignType: "Segment",
  },
  "CMP-EVENT": {
    nodesAllowed: 6,
    actionName: "START_TRIGGER",
    campaignType: "Event",
  },
};
export const eventsLogo = {
  browse: <PiBrowsersFill />,
  add_to_cart: <MdShoppingCartCheckout className="cart-icon" />,
};

export const displayNameAlias = {
  SEGMENT: "SEGMENT",
  segment: "SEGMENT",
  EMAIL: "EMAIL",
  email: "EMAIL",
  wait: "wait",
  WAIT: "WAIT",
  START_TRIGGER: "EVENT",
  start_trigger: "EVENT",
  ACTION: "action",
};
export const ACTION = "ACTION";

export const days = [
  { value: "0", label: "0" },
  { value: "1", label: "1" },
  { value: "2", label: "2" },
  { value: "3", label: "3" },
  { value: "4", label: "4" },
  { value: "5", label: "5" },
  { value: "6", label: "6" },
  { value: "7", label: "7" },
];
export const hours = [
  { value: "0", label: "0" },
  { value: "1", label: "1" },
  { value: "2", label: "2" },
  { value: "3", label: "3" },
  { value: "4", label: "4" },
  { value: "5", label: "5" },
  { value: "6", label: "6" },
  { value: "7", label: "7" },
  { value: "8", label: "8" },
  { value: "9", label: "9" },
  { value: "10", label: "10" },
  { value: "11", label: "11" },
  { value: "12", label: "12" },
  { value: "13", label: "13" },
  { value: "14", label: "14" },
  { value: "15", label: "15" },
  { value: "16", label: "16" },
  { value: "17", label: "17" },
  { value: "18", label: "18" },
  { value: "19", label: "19" },
  { value: "20", label: "20" },
  { value: "21", label: "21" },
  { value: "22", label: "22" },
  { value: "23", label: "23" },
];
export const minutes = [
  { value: "0", label: "0" },
  { value: "1", label: "1" },
  { value: "2", label: "2" },
  { value: "3", label: "3" },
  { value: "4", label: "4" },
  { value: "5", label: "5" },
  { value: "6", label: "6" },
  { value: "7", label: "7" },
  { value: "8", label: "8" },
  { value: "9", label: "9" },
  { value: "10", label: "10" },
  { value: "11", label: "11" },
  { value: "12", label: "12" },
  { value: "13", label: "13" },
  { value: "14", label: "14" },
  { value: "15", label: "15" },
  { value: "16", label: "16" },
  { value: "17", label: "17" },
  { value: "18", label: "18" },
  { value: "19", label: "19" },
  { value: "20", label: "20" },
  { value: "21", label: "21" },
  { value: "22", label: "22" },
  { value: "23", label: "23" },
  { value: "24", label: "24" },
  { value: "25", label: "25" },
  { value: "26", label: "26" },
  { value: "27", label: "27" },
  { value: "28", label: "28" },
  { value: "29", label: "29" },
  { value: "30", label: "30" },
  { value: "31", label: "31" },
  { value: "32", label: "32" },
  { value: "33", label: "33" },
  { value: "34", label: "34" },
  { value: "35", label: "35" },
  { value: "36", label: "36" },
  { value: "37", label: "37" },
  { value: "38", label: "38" },
  { value: "39", label: "39" },
  { value: "40", label: "40" },
  { value: "41", label: "41" },
  { value: "42", label: "42" },
  { value: "43", label: "43" },
  { value: "44", label: "44" },
  { value: "45", label: "45" },
  { value: "46", label: "46" },
  { value: "47", label: "47" },
  { value: "48", label: "48" },
  { value: "49", label: "49" },
  { value: "50", label: "50" },
  { value: "51", label: "51" },
  { value: "52", label: "52" },
  { value: "53", label: "53" },
  { value: "54", label: "54" },
  { value: "55", label: "55" },
  { value: "56", label: "56" },
  { value: "57", label: "57" },
  { value: "58", label: "58" },
  { value: "59", label: "59" },
];

export const initialVariationData = {
  variationId: generateUniqueVariationId(),
};
export const actionProps = [
  {
    heading: "Messaging",
    actions: [
      {
        displayName: "Email",
        key: "email",
        type: "email",
        isdisabled: false,
        icon: emailIcon,
        onClick: EmailContentSelectionPopup,
      },
      {
        displayName: "Text",
        key: "text",
        type: "text",
        isdisabled: true,
        icon: textIcon,
        onClick: "eventListener",
      },
      {
        displayName: "Push Notification",
        key: "push",
        type: "push",
        isdisabled: true,
        icon: pushNotificationsIcon,
        onClick: "eventListener",
      },
    ],
    showInCampaignType: ["CMP-BATCH", "CMP-EVENT"],
  },
  {
    heading: "Time Delay",
    actions: [
      {
        displayName: "Wait",
        key: "wait",
        type: "wait",
        isdisabled: false,
        icon: delayIcon,
        onClick: SelectDelayComponent,
      },
    ],
    showInCampaignType: ["CMP-EVENT"],
  },
  {
    heading: "Flow control",
    actions: [
      {
        displayName: "Exit",
        key: "exit",
        type: "exit",
        isdisabled: true,
        icon: exitIcon,
        onClick: "eventListener",
      },
    ],
    showInCampaignType: ["CMP-BATCH", "CMP-EVENT"],
  },
];

export const SUBJECT_LINE_ACTION = "SUBJECT_LINE_ACTION";
export const NO_ACTION = "NO_ACTION";

export const email_variation_json = {
  none: {
    common: {
      contentId: "",
      subject: "",
      templateId: "horizontal",
      senderEmail: "",
      name: "",
      displayName: "",
      senderDisplayName: "",
      percentage: "",
      variationId: "",
    },
    testBased: {},
  },
  subject_line: {
    common: {
      contentId: "",
      templateId: "horizontal",
      senderEmail: "",
      name: "",
      displayName: "",
      senderDisplayName: "",
    },
    testBased: {
      percentage: 10,
      subject: "",
      variationId: "",
    },
  },
  content: {
    common: {
      templateId: "",
      name: "",
      displayName: "",
      senderEmail: "",
      senderDisplayName: "",
    },
    testBased: {
      percentage: "",
      subject: "",
      variationId: "",
      contentId: "",
    },
  },
};
export const email_display_data = {
  name: "",
  ContentName: "",
  contentId: "",
  templateId: "",
  senderDisplayName: "",
  senderEmail: "",
};
export const email_number_of_variation_arrays = {
  none: { numberOfVariants: 1 },
  subject_line: { numberOfVariants: 2 },
};

export const testBasedDropdownValues = [
  {
    label: "Subject Line",
    value: "subject_line",
  },
  {
    label: "None",
    value: "none",
  },
];

export const templateDropdownValues = [
  { label: "horizontal", value: "horizontal" },
  { label: "vertical", value: "vertical" },
  { label: "mix", value: "mix" },
  { label: "side by side", value: "side by side" },
];

export const senderEmailDropdownValues = [
  {
    label: "test@shopyourway.com",
    value: "test@shopyourway.com",
  },
];

export const emailTemplateName = "templateId";
export const emailSenderEmail = "senderEmail";
export const emailSenderDisplayName = "senderDisplayName";

export const emailSelectedEmailContentId = "contentId";
export const emailSelectedEmailContentName = "name";
export const emailSelectedEmailContentCreatedBy = "createdBy";

export const selectedEmailContentKeys = {
  keys: [
    emailSelectedEmailContentId,
    emailSelectedEmailContentName,
    emailSelectedEmailContentCreatedBy,
  ],
};

export const variationKeys = {
  variationId: "",
  templateId: "",
  percentage: "",
  senderDisplayName: "",
  senderEmail: "",
  contentId: "",
  subject: "",
};

export const updatejourneystatusRequestBody = (user, journeyData, status) => {
  return {
    requestorID: OFRI,
    messageVersion: process.env.REACT_APP_MESSAGE_VERSION_03,
    modifiedBy: user?.email,
    journeyNumber: journeyData?.journeyNumber,
    journeyStatus: status,
    journeyType: journeyData?.journeyType
  };
};
// export const campaignPopupJson = {
//   activate: {
//     headerText: "",
//     bodyText: ACTIVATE_CONFIRMATION_MESSAGE,
//     bodyTextSave: SAVE_AND_ACTIVATE_CONFIRMATION_MESSAGE,
//     buttonSecondary: "",
//     button: "Yes, activate it",
//     buttonSave: "Save and Activate",
//     status: "ACTIVATED"
//   },
//   deactivate: {
//     headerText: "",
//     bodyText: DEACTIVATE_CONFIRMATION_MESSAGE,
//     buttonSecondary: "No",
//     button: "Yes, deactivate it",
//     status: "DEACTIVATED"
//   },
//   startAbTest: {
//     headerText: "",
//     bodyText: START_AB_TEST_MESSAGE,
//     bodyTextSave: START_AB_TEST_CONFIRMATION_MESSAGE,
//     buttonSecondary: "",
//     button: "Yes, Start AB Testing",
//     buttonSave: "Save & Start AB Testing",
//     status: "AB-TESTING"
//   },
// };
export const campaign_segment_columns = [
  {
    title: "Segment",
    key: "groupName",
    type: "string",
    visible: true,
  },
  {
    title: "Created By",
    key: "createdBy",
    type: "string",
    visible: true,
  },
  {
    title: "Member Count",
    key: "countInTheList",
    type: "string",
    visible: true,
  },
];

export const campaign_content_columns = [
  {
    title: "Content Name",
    key: "name",
    type: "string",
    visible: true,
  },
  {
    title: "Created By",
    key: "createdBy",
    type: "string",
    visible: true,
  },
  {
    title: "Content ID",
    key: "id",
    type: "string",
    visible: true,
  },
];
export const popupConfig = {
  tootltip: true,
  theme: "",
  selectedDataKey: "",
  isDisabled: false,
};
