import { React, useState } from "react";
import "./CreateCampaingComponent.style.scss";
import { FaRegTimesCircle } from "react-icons/fa";
import { images } from "../../imagesComponent/Images";
import { useNavigate } from "react-router-dom";
// import { DETAILS, OFFER } from "../../constantComponent/PathConstants";
import {
  COMING_SOON,
  CONTINUE_TO_SETUP,
  ESLO,
  headers,
  OFRI,
  PROCESSING_LOADER,
  SUCCESS,
} from "../../constantComponent/Constants";
import { useDispatch, useSelector } from "react-redux";
import { createUpdateOffer } from "../../../tellurideExtRedux/offerDetails/OfferDetailsAction";
import { useEffect } from "react";
import { miniloading } from "../../LoaderComponents/loadingDesignConstant";
import {
  CAMPAIGN_CAMPAIGN_TITLE,
  CAMPAIGN_CAN_DO_TITLE,
  CAMPAIGN_CAN_DO_TYPES,
  CAMPAIGN_NAME_DESCRIPTION_LABEL,
  CAMPAIGN_NAME_LABEL,
  CAMPAIGN_TRIGGER_TYPES,
  CAMPAIGN_TYPES,
  CREATE_CAMPAIGN_MODAL_TITLE,
  displayOfferType,
} from "./CreateCampaingConstant";
import { useAuth0 } from "@auth0/auth0-react";
import { motion } from "framer-motion/dist/framer-motion";
import {
  checkRightsIsEditable,
  checkRightsIsVisible,
  getAllRights,
  getRightsFromSession,
  getRightsKey,
  skey,
  trimExtraSpaces,
} from "../../utilsComponent/CommonUtil";
import { COMING_SOON_STATUS } from "../../segmentSetupComponent/createSegmentComponent/CreateSegmentConstants";
import { CAMPAIGN, DETAILS } from "../../constantComponent/PathConstants";
import {
  getCampaignDetails,
  saveCampaignOffer,
} from "../../../tellurideExtRedux";
import { RESPONSE_STATUS_SUCCESS } from "../../offerSetupComponent/offerSetupConstant";
import { saveJourney } from "../../../tellurideExtRedux/journey/JourneyAction";

const CreateCampaignComponent = ({
  setSelectOffer,
  setShowCampaignCreatePopup,
}) => {
  const dispatch = useDispatch();
  const offerData = useSelector((state) => state.offerDetailsData);
  // const campaignData = useSelector((state) => state.campaignDetailsData);
  const journeyDetailsData = useSelector((state) => state.journeyDetailsData);
  const [selectedEarn, setSelectedEarn] = useState(true);
  const [selectedDiscount, setSelectedDiscount] = useState(false);
  const [campaignTriggerType, setCampaignTriggerType] = useState("CMP-BATCH");
  const [campaignDoType, setCampaignDoType] = useState(["1"]);
  const [campaignName, setCampaignName] = useState("");
  let navigate = useNavigate();
  const { user } = useAuth0();
  const handleChangeCampaignName = (e) => {
    setCampaignName(e.target.value);
  };
  function handleCampaignSelect(campaign, isTriggerCamping) {
    if (isTriggerCamping) {
      setCampaignTriggerType(campaign.key);
    } else {
      if (!campaignDoType.includes(campaign.key)) {
        campaignDoType.push(campaign.key);
        setCampaignDoType((prev) => [...prev]);
      } else {
        campaignDoType.pop(campaign.key);
        setCampaignDoType((prev) => [...prev]);
      }
    }
  }
  const securityData = useSelector((state) => state.securityData);
  const createHandler = () => {
    headers.sessionId = skey();
    headers.requestAccess = getRightsFromSession();
    headers.organization = offerData?.authOrganizationName;
    const requestHeaders = {
      headers,
    };
    const requestBody = {
      requestorID: OFRI,
      messageVersion: process.env.REACT_APP_MESSAGE_VERSION_05,
      journeyAttributes: {
        journeyAttribute: [
          {
            name: "DISPLAY_OFFER_TYPE",
            values: {
              value: displayOfferType[campaignTriggerType],
            },
          },
        ],
      },
      journeyData: {
        journeyType: campaignTriggerType,
        journeySubType: campaignTriggerType,
        organization: offerData?.authOrganizationName,
        journeyName: campaignName,
        modifiedBy: user?.email,
      },
    };
    dispatch(
      saveJourney(requestHeaders, requestBody, false, false, navigate, window.location.pathname.includes(DETAILS) ? true : false)
    );
  };
  useEffect(() => {
    if (
      journeyDetailsData?.saveJourneyLoading === false &&
      journeyDetailsData?.saveJourneyResponse &&
      journeyDetailsData?.saveJourneyResponse?.status ===
        RESPONSE_STATUS_SUCCESS &&
      journeyDetailsData?.saveJourneyResponse?.journeyNumber
    ) {
      navigate({
        pathname:
          "/" + window.location.pathname.split("/")[1] + CAMPAIGN + DETAILS,
        search: `?offerid=${journeyDetailsData?.journeyNumber}`,
      });
      setSelectOffer(true);
      setShowCampaignCreatePopup(false);
    }
  }, [dispatch, journeyDetailsData]);
  return (
    <section className="campaign-modal-backdrop">
      <motion.div
        className="inner-modal"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <div className="header">
          <h1>{CREATE_CAMPAIGN_MODAL_TITLE}</h1>
          <FaRegTimesCircle
            onClick={() => {
              setShowCampaignCreatePopup(false);
            }}
            size={25}
            className="campaign-modal-close-icon"
          />
        </div>
        <fieldset>
          <legend>{CAMPAIGN_NAME_LABEL}</legend>
          <input
            type="text"
            value={campaignName}
            onChange={handleChangeCampaignName}
            disabled={journeyDetailsData?.saveJourneyLoading}
            autoFocus
            maxLength="50"
          />
        </fieldset>
        {/* <fieldset className="text-box mt-16">
          <legend>{CAMPAIGN_NAME_DESCRIPTION_LABEL}</legend>
          <textarea
            className="input-text-textarea"
            rows="2"
            cols="50"
            maxLength="250"
            name="campaignDescription"
          />
        </fieldset> */}

        <p className="create-campaign-title">{CAMPAIGN_CAMPAIGN_TITLE}</p>
        <div className="campaign-type-section trigger-campaign">
          {CAMPAIGN_TRIGGER_TYPES.map((campaign) => {
            return (
              <>
                {checkRightsIsVisible(getRightsKey(campaign?.rights)) && (
                  <div
                    className={`campaign-type ${
                      campaignTriggerType == campaign.key && "selected"
                    } ${
                      (campaign.disabled === true && "disabled") ||
                      (!checkRightsIsEditable(getRightsKey(campaign.rights)) &&
                        "disabled")
                    }`}
                    onClick={() => {
                      if (
                        !campaign.disabled ||
                        !checkRightsIsEditable(getRightsKey(campaign.rights))
                      ) {
                        handleCampaignSelect(campaign, true);
                      }
                    }}
                    key={campaign.key}
                  >
                    <input
                      type="radio"
                      className={`campaigntype-radio-input ${
                        campaignTriggerType == campaign.key && "selected"
                      } ${
                        (campaign.disabled === true && "disabled") ||
                        (!checkRightsIsEditable(
                          getRightsKey(campaign.rights)
                        ) &&
                          "disabled")
                      }`}
                      checked={
                        campaign.key === campaignTriggerType ? true : false
                      }
                      disabled={
                        campaign.disabled === true ||
                        !checkRightsIsEditable(getRightsKey(campaign.rights))
                          ? true
                          : false
                      }
                    />
                    {/* {campaign?.icon()} */}

                    <p className="campaign-title">{campaign.title}</p>
                    <p className="campaign-header">{campaign.header}</p>
                    <p className="campaign-body">{campaign.description}</p>
                    {campaign.status === COMING_SOON_STATUS && (
                      <p className="campaign-status">Coming soon</p>
                    )}
                  </div>
                )}
              </>
            );
          })}
        </div>
        <footer className="campaign-modal-footer">
          <button
            className="btn btn-secondary"
            onClick={() => {
              setShowCampaignCreatePopup(false);
            }}
            disabled={journeyDetailsData?.saveJourneyLoading}
          >
            Cancel
          </button>
          {checkRightsIsVisible(
            getRightsKey([
              "button",
              "popup",
              "campaign",
              "createcampaign",
              "continuetosetup",
            ])
          ) ? (
            <button
              className="btn btn-primary continue-setup-btn"
              disabled={
                campaignName.trim() === "" ||
                journeyDetailsData?.saveJourneyLoading ||
                !checkRightsIsEditable(
                  getRightsKey([
                    "button",
                    "popup",
                    "campaign",
                    "createcampaign",
                    "continuetosetup",
                  ])
                )
              }
              onClick={() => {
                createHandler();
              }}
            >
              {journeyDetailsData?.saveJourneyLoading
                ? miniloading(PROCESSING_LOADER)
                : CONTINUE_TO_SETUP}
            </button>
          ) : null}
        </footer>
      </motion.div>
    </section>
  );
};

export default CreateCampaignComponent;
