export const CREATE_SEGMENT_MODAL_TITLE = "Start defining the segment";
export const SEGMENT_NAME_LABEL = "Segment Name";
export const CREATE_SEGMENT_TITLE = "What is the Segment Type";
export const SEGMENT_TYPES = [
	{
		title: "Static",
		key: "Static",
		header: "Upload text file with list of members.",
		description:
			"Static list of members, can be appended or overwritten later.",
		status: "&nbsp;",
		disabled: false,
		rights: ["popup", "segmentation", "createsegment", "static"]
	},
	{
		title: "Data Driven",
		key: "Data Driven",
		header: "Segment based on data rules.",
		description:
			"e.g. Members who have purchased appliances within last 6 months.",
		status: "coming soon",
		disabled: true,
		rights: ["popup", "segmentation", "createsegment", "datadriven"]
	},
	{
		title: "Email (non-member)",
		key: "Email (non-member)",
		header: "Upload text file with list of email addresses of non-members.",
		description: "Static list of customer email addresses.",
		status: "coming soon",
		disabled: true,
		rights: ["popup", "segmentation", "createsegment", "email"]
	},
];
export const COMING_SOON_STATUS = "coming soon";
export const CONTINUE_TO_SETUP = "Continue to setup";
