import React, { useCallback, useEffect, useState } from "react";
import GenericFlow from "../../../utilsComponent/GenericFlow/GenericFlow";
import {
  CUSTOM_OFFER_BUILDER_HEADER,
  actionProps,
  customOfferNodeTypes,
  displayNameAlias,
  initialEdges,
  nodeDataObj,
} from "./CustomOfferBuilderConstants.js";
import "./CustomOffer.style.scss";
import { useSelector } from "react-redux";
import { getDisplayName } from "../../../utilsComponent/GenericFlow/GenericFlowUtil";
import { convertToInitialJSON } from "../../../utilsComponent/GenericRules/GenericRulesUtil";
import { INITIAL_DEFAULT_RULES } from "../../../utilsComponent/GenericRules/GenericRulesConstants";
import { initialNodes } from "./CustomOfferBuilderConstants.js";
import SubClubComponent from "../customOfferSetupPopups/subclubPopupComponent/SubclubPopupComponent.jsx";
import OfferSelectionPopupComponent from "../customOfferSetupPopups/offerSelectionPopupComponent/OfferSelectionPopupComponent.jsx";
import OfferReviewPopUpComponent from "../customOfferSetupPopups/offerReviewPopupComponent/OfferReviewPopUpComponent.jsx";
import { getDisplayData } from "./CustomOfferBuilderUtil.js";
import {
  checkRightsIsEditable,
  checkRightsIsVisible,
  getAllRights,
  getRightsKey,
} from "../../../utilsComponent/CommonUtil.js";
import SubclubEventSelectionPopupComponent from "../customOfferSetupPopups/subclubEventSelectionPopupComponent/SubclubEventSelectionPopupComponent.jsx";

function CustomOfferBuilder({ theme, isDisabled, setCustomOfferBuilderData }) {
  const [actionName] = useState(
    customOfferNodeTypes["CMP-EVENT"]["actionName"]
  );
  // const journeyDetailsData = useSelector((state) => state.journeyDetailsData);
  const journeyDetailsData = useSelector((state) => state.journeyDetailsData);
  const maxChildNodes = 1;
  const [journeyFlow] = useState({
    nodes:
      journeyDetailsData?.journeyDetailsResponse?.journeyFlow?.nodes?.length > 0
        ? journeyDetailsData?.journeyDetailsResponse?.journeyFlow?.nodes.map(
            (node) => {
              return {
                ...node,
                data: {
                  ...node.data,
                  displayData: {
                    ...getDisplayData(
                      node.data.value,
                      node.data.type.toLowerCase()
                    ),
                  },
                  name: node?.data?.name
                    ? node?.data?.name
                    : getDisplayName(node.data.type, displayNameAlias),
                },
              };
            }
          )
        : initialNodes.map((node) => {
            node.data.type = actionName;
            node.data.displayData.displayName = actionName;
            node.data.name = getDisplayName(actionName, displayNameAlias);
            return node;
          }),
    edges:
      journeyDetailsData?.journeyDetailsResponse?.journeyFlow?.edges?.length > 0
        ? journeyDetailsData?.journeyDetailsResponse?.journeyFlow?.edges
        : initialEdges,
  });

  function journeyFlowHandler(journeyFlow) {
    setCustomOfferBuilderData({
      journeyFlow: {
        nodes: journeyFlow.nodes,
        edges: journeyFlow.edges,
      },
    });
  }
  // console.log(
  //   "---->>>>>",
  //   !checkRightsIsEditable(
  //     getRightsKey(["loyalty", "detail", "customoffer", "addnode"])
  //   )
  // );
  return (
    <>
      <section
        style={{ height: "calc(100% - 130px)" }}
        className="custom-offer-action-popup-wrapper"
      >
        <div className="section-top-title setup-page-section-title">
          {CUSTOM_OFFER_BUILDER_HEADER}
        </div>
        <div className="generic-flow-builder-wrapper">
          <GenericFlow
            theme={theme}
            journeyFlow={journeyFlow}
            maxChildNodes={maxChildNodes}
            isDisabled={isDisabled}
            nodeDataObj={nodeDataObj}
            actionProps={actionProps}
            componentNameByType={{
              start_trigger: SubclubEventSelectionPopupComponent,
              offer: OfferSelectionPopupComponent,
              register_offer: OfferReviewPopUpComponent,
              award_points: OfferReviewPopUpComponent,
            }}
            journeyFlowHandler={journeyFlowHandler}
            displayNameJson={displayNameAlias}
            nodeNameisEditable={false}
            rights={{
              isEditable: {
                addNode: !checkRightsIsEditable(
                  getRightsKey(["loyalty", "detail", "customoffer", "addnode"])
                ),
                editNode: !checkRightsIsEditable(
                  getRightsKey(["loyalty", "detail", "customoffer", "editnode"])
                ),
                deletNode: !checkRightsIsEditable(
                  getRightsKey([
                    "loyalty",
                    "detail",
                    "customoffer",
                    "deletenode",
                  ])
                ),
              },
              isVisible: {
                addNode: checkRightsIsVisible(
                  getRightsKey(["loyalty", "detail", "customoffer", "addnode"])
                ),
                editNode: checkRightsIsVisible(
                  getRightsKey(["loyalty", "detail", "customoffer", "editnode"])
                ),
                deletNode: checkRightsIsVisible(
                  getRightsKey([
                    "loyalty",
                    "detail",
                    "customoffer",
                    "deletenode",
                  ])
                ),
              },
            }}
          />
        </div>
      </section>
    </>
  );
}

export default CustomOfferBuilder;
