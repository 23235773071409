export const SUBCLUBS_TABLE_HEADER = "Subclubs";
export const SUBCLUBS_DETAILS_HEADER = "Add new Subclub";
export const SUBCLUBS = "SUBCLUBS";
export const VIEW_SUBCLUB_PLACEHOLDER = "Search subclubs";
export const SUBCLUB_TABLE_CODE = "AFFILIATIONS";
export const FILTER = "filter";

export const COLUMNS = [
	{
		title: "Subclub Code",
		key: "code",
		type: "string",
		visible: true,
		filter: true,
		sort: true,
		minWidth: 120,
		initialWidth: 120,
		defaultWidth: 120,
		resizable: true,
		reqBodyKey: { attrName: "code", isAllowed: true },
	},
	{
		title: "Subclub Name",
		key: "name",
		type: "string",
		visible: true,
		filter: true,
		sort: true,
		minWidth: 120,
		initialWidth: 120,
		defaultWidth: 120,
		resizable: true,
		reqBodyKey: { attrName: "name", isAllowed: true },
	},
	{
		title: "Description",
		key: "description",
		type: "string",
		visible: true,
		filter: true,
		sort: true,
		advanceFilter: true,
		minWidth: 240,
		initialWidth: 240,
		defaultWidth: 240,
		resizable: true,
		reqBodyKey: { attrName: "description", isAllowed: true },
	},
	{
		title: "Created by",
		key: "createdBy",
		type: "string",
		visible: true,
		filter: true,
		sort: true,
		advanceFilter: true,
		minWidth: 85,
		initialWidth: 85,
		defaultWidth: 85,
		resizable: true,
		reqBodyKey: { attrName: "createdBy", isAllowed: true },
	},

	{
		title: "Created Date",
		key: "createTs",
		type: "string",
		visible: true,
		filter: false,
		sort: true,
		advanceFilter: false,
		minWidth: 85,
		initialWidth: 85,
		defaultWidth: 85,
		resizable: true,
		reqBodyKey: { attrName: "createTs", isAllowed: false },
	},
	{
		title: "Status",
		key: "status",
		type: "select",
		visible: false,
		filter: false,
		sort: false,
		advanceFilter: false,
		multi: false,
		options: ["active"],
		minWidth: 85,
		initialWidth: 85,
		defaultWidth: 85,
		resizable: true,
		reqBodyKey: { attrName: "", isAllowed: false },
	},
];
