import "./OfferDetails.style.scss";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { updateOfferData } from "../../../tellurideExtRedux";
import { copyToClipBoard, formatDate } from "../../utilsComponent/CommonUtil";
import "../../../tellurideExtAssets/styles/reactCalendar.style.scss";
import "../../../tellurideExtAssets/styles/reactDatePicker.style.scss";
import DatePicker from "react-date-picker";
import { images } from "../../imagesComponent/Images";
import {
	BASE_EARN,
	BASE_REDEEM,
	LOYALTY_CASH,
	POINTS_EARN,
	PRICE_DISCOUNT,
	EVENTPROCESSING,
	EVENT_OFFER,
} from "../../constantComponent/Constants";
import moment from "moment";
import {
	DESCRIPTION_LABEL,
	END_DATE_INFO,
	END_DATE_LABEL,
	OFFER_CATEGORY_LABEL,
	OFFER_SUBTYPE_LABEL,
	OFFER_CATEGORY_OPTIONS,
	OFFER_CATEGORY_OPTIONS_ALIAS,
	OFFER_DETAILS_TITLE,
	OFFER_END_DATE,
	OFFER_ID_INFO,
	OFFER_ID_LABEL,
	OFFER_START_DATE,
	OFFER_TYPE_LABEL,
	START_DATE_INFO,
	START_DATE_LABEL,
	EVENT_OFFER_SUBTYPE_OPTIONS,
} from "./OfferDetailsConstants";
import { dateHandler, getCustomStyles, valueHandler } from "./OfferDetailsUtil";
import {
	OFFER_CATEGORY,
	PROGRAM_TYPE,
} from "../rewardComponent/RewardConstant";
import Select from "react-select";
import { RxCopy } from "react-icons/rx";
const OfferDetails = ({ isTabletModeOn = false, disabled }) => {
	const dispatch = useDispatch();
	const offerDetailsPageData = useSelector((state) => state.offerDetailsData);
	// ProgramType change
	// const metaData = useSelector((state) => state.metaData);
	// const { programTypesResponse, programTypesLoading } = metaData;
	const [offerDetailValues, setValues] = useState(
		offerDetailsPageData?.offerData
	);
	// const programTypes = programTypesResponse?.programTypes
	// 	? programTypesResponse?.programTypes
	// 	: [];
	const offerType = offerDetailValues?.offerType;
	const displayOfferType =
		offerDetailValues?.offerType === POINTS_EARN &&
		offerDetailsPageData?.offerAttributes?.offerAttribute?.some(
			(attribute) => attribute?.name === "DISPLAY_OFFER_TYPE"
		)
			? offerDetailsPageData?.offerAttributes?.offerAttribute?.find(
					(attribute) => attribute?.name === "DISPLAY_OFFER_TYPE"
			  ).values.value[0]
			: offerDetailValues?.offerType;
	const [copyText, setCopyText] = useState(false);
	const [evtOfrSubType, setEvtOfrSubType] = useState(
		offerDetailValues.offerSubType
			? offerDetailValues.offerSubType.toUpperCase()
			: ""
	);
	const toasterData = useSelector((state) => state.toasterData);
	const [theme, setTheme] = useState(toasterData?.isDarkTheme);
	useEffect(() => {
		setTheme(toasterData?.isDarkTheme);
	}, [toasterData]);
	// useEffect(() => {
	// 	setDisable(
	// 		offerDetailsPageData?.offerStatus !== OFFER_DETAILS_STATUS_DRAFT
	// 			? true
	// 			: false || disabled
	// 	);
	// }, [offerDetailsPageData?.offerStatus,disabled]);
	useEffect(() => {
		dispatch(updateOfferData(offerDetailValues));
	}, [offerDetailValues]);
	function handleEvtSubTypeChange({ value }, { name }) {
		setEvtOfrSubType(value);
		setValues((prev) => ({ ...prev, offerSubType: value }));
	}
	return (
		<>
			<div
				className={`offer-details-main-container ${
					isTabletModeOn && "tablet-mode"
				}`}
			>
				<div className="offer-details-title setup-page-section-title">
					{OFFER_DETAILS_TITLE}
				</div>
				<section className="offer-details-section">
					<div className="form-div">
						<div className="offer-code-wrapper">
							<div className="offer-code">
								<div className="offer-info-container">
									<label className="offer-info-label">
										{OFFER_ID_LABEL} :{" "}
									</label>
									<span className="offer-info-bold">
										{offerDetailValues?.offerNumber}
									</span>
									{copyText === false ? (
										<RxCopy
											className="copy-icon"
											onClick={() => {
												setCopyText(true);
												copyToClipBoard(offerDetailValues?.offerNumber);
												setTimeout(() => {
													setCopyText(false);
												}, 3000);
											}}
										/>
									) : (
										<p className="copy-icon-text">Copied!</p>
									)}
								</div>
								<p>{OFFER_ID_INFO}</p>
							</div>
							<div className="offer-code">
								<div className="offer-info-container offer-type-container">
									<label className="offer-info-label offer-type-label">
										{OFFER_TYPE_LABEL} :{" "}
									</label>
									<span className="offer-info-bold">{displayOfferType}</span>
								</div>
							</div>
						</div>
						{[BASE_EARN, BASE_REDEEM].includes(offerType) ? (
							<div className="offer-code">
								<div className="offer-info-container offer-category-container">
									<label className="offer-info-label offer-type-label">
										{OFFER_CATEGORY_LABEL} :{" "}
									</label>
									<span className="offer-info-bold ml-2">{"Always Earn"}</span>
								</div>
							</div>
						) : null}
						{[EVENT_OFFER].includes(offerType) ? (
							<fieldset
								className="catagory-tablet dropdown"
								disabled={disabled}
							>
								<legend>{OFFER_SUBTYPE_LABEL}</legend>
								<Select
									value={{
										label: evtOfrSubType,
										value: evtOfrSubType,
									}}
									name="OFFER_SUBTYPE"
									options={EVENT_OFFER_SUBTYPE_OPTIONS}
									styles={getCustomStyles(theme)}
									onChange={handleEvtSubTypeChange}
									isDisabled={disabled}
									className="offer-category-dropdown"
								/>
							</fieldset>
						) : null}
						<div className="date-wrapper">
							<div className="date-wrapper-inside">
								<fieldset id="start-date" disabled={disabled}>
									<legend>{START_DATE_LABEL}</legend>
									<DatePicker
										calendarIcon={
											<img
												src={images.calendarIcon}
												className="calendar-icon"
											/>
										}
										clearIcon={null}
										format="y-MM-dd"
										value={moment(
											offerDetailValues?.offerStartDate
										).toLocaleString("en-US")}
										minDate={moment(
											offerDetailValues?.createdDate,
											"YYYY-MM-DD"
										).toDate()}
										onChange={(value) => {
											dateHandler(
												OFFER_START_DATE,
												new Date(value).toLocaleDateString("en-US"),
												offerDetailValues,
												setValues
											);
										}}
										disabled={disabled}
										className={"top-230 left-67"}
									/>
								</fieldset>

								<div className="info-div">
									<p>{START_DATE_INFO}</p>
								</div>
							</div>

							<div className="date-wrapper-inside" id="end-date">
								<fieldset disabled={disabled}>
									<legend>{END_DATE_LABEL}</legend>
									<DatePicker
										calendarIcon={
											<img
												src={images.calendarIcon}
												className="calendar-icon"
											/>
										}
										clearIcon={null}
										format="y-MM-dd"
										minDate={moment(
											offerDetailValues?.offerStartDate,
											"YYYY-MM-DD"
										).toDate()}
										onChange={(value) => {
											dateHandler(
												OFFER_END_DATE,
												new Date(value).toLocaleDateString("en-US"),
												offerDetailValues,
												setValues
											);
										}}
										value={moment(
											offerDetailValues?.offerEndDate
										).toLocaleString("en-US")}
										disabled={disabled}
										className={"top-230"}
									/>
								</fieldset>

								<div className="info-div">
									<p>{END_DATE_INFO}</p>
								</div>
							</div>
						</div>

						<div className="textbox-wrapper">
							{[POINTS_EARN, PRICE_DISCOUNT, LOYALTY_CASH].includes(
								offerType
							) ? (
								<fieldset
									disabled={disabled}
									className="dropdown catagory-tablet"
								>
									<legend>{OFFER_CATEGORY}</legend>
									<Select
										styles={getCustomStyles(theme)}
										isDisabled={disabled}
										options={OFFER_CATEGORY_OPTIONS}
										value={{
											label:
												OFFER_CATEGORY_OPTIONS_ALIAS[
													offerDetailValues.offerCategory
												],
											value: offerDetailValues.offerCategory,
										}}
										onChange={({ value }) => {
											setValues((prev) => ({ ...prev, offerCategory: value }));
										}}
										className="offer-category-dropdown"
									/>
								</fieldset>
							) : null}
							{/* <fieldset
								disabled={disabled}
								className="dropdown catagory-tablet"
							>
								<legend>{PROGRAM_TYPE}</legend>
								<Select
									styles={getCustomStyles(theme)}
									isDisabled={disabled || programTypesLoading}
									options={programTypes.map((programType) => {
										return {
											label: programType.name,
											value: programType.code,
										};
									})}
									isLoading={programTypesLoading}
									value={
										offerDetailValues.programType
											? {
													label: programTypes.find(
														(programType) =>
															programType.code === offerDetailValues.programType
													)?.name,
													value: offerDetailValues.programType,
											  }
											: null
									}
									onChange={({ value }) => {
										setValues((prev) => ({ ...prev, programType: value }));
									}}
									className="offer-category-dropdown"
								/>
							</fieldset> */}

							<fieldset className="text-box" disabled={disabled}>
								<legend>{DESCRIPTION_LABEL}</legend>
								<textarea
									className="input-text-textarea"
									rows="3"
									cols="50"
									value={offerDetailValues?.offerDescription}
									maxLength="250"
									name="offerDescription"
									onChange={(e) =>
										valueHandler(e, offerDetailValues, setValues)
									}
								/>
							</fieldset>
						</div>
					</div>
				</section>
			</div>
		</>
	);
};

export default OfferDetails;
