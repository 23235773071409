import "./Cap.style.scss";
import { useEffect, useLayoutEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  handleEditBlur,
  getCustomStyles,
  handleEditClick,
  formatNumberCommaSeperated,
} from "../capComponent/CapUtil";
import {
  discountIntervalUnitOptions,
  discountIntervalAlias,
  discountIntervalAliasRev,
  earnIntervalUnitOptions,
  discountCapNewProperty,
  generateInitialDiscountCapProperty,
  capPeriodValueProperty,
  DISCOUNT_CAP_INFO_TITLE,
  DISCOUNT_CAP_INFO_DETAIL,
} from "../capComponent/CapConstants";
import ReactSelect from "../../utilsComponent/Dropdown/ReactSelect";
import {
  headers,
  OFFER_DETAILS_STATUS_DRAFT,
} from "../../constantComponent/Constants";
import {
  generateInitialJSON,
  generateRewardJSON,
} from "../discountComponent/DiscountUtil";
import { onKeyPressAction } from "../../utilsComponent/CommonUtil";
import Select from "react-select";
import { updateCapData } from "../../../tellurideExtRedux/offerDetails/OfferDetailsAction";
import { CAP } from "../discountComponent/DiscountConstant";
import { NumericFormat } from "react-number-format";
import { AiOutlineInfoCircle } from "react-icons/ai";

const DiscountOfferCap = ({ theme, disabled }) => {
  const offerData = useSelector((state) => state.offerDetailsData);
  const initialRewardData = useSelector(
    (state) => state.offerDetailsData.rewardData
  );
  // const initialCapData = useSelector(
  //   (state) => state.offerDetailsData.capData
  // );
  const [capData, setCapData] = useState("");
  const [awardDetailData, setAwardDetaildata] = useState(
    initialRewardData === undefined
      ? JSON.parse(JSON.stringify(generateInitialJSON()))
      : JSON.parse(JSON.stringify(initialRewardData))
  );
  const [showOfferStartDays, setShowOfferStartDays] = useState(false);
  const [memberDiscountCap, setMemberDiscountCap] = useState("0");
  const [daysFromOfferStart, setDaysFromOfferStart] = useState("");
  const [discountPeriod, setDiscountPeriod] = useState("PER_TRANSACTION");
  const [actionProp, setActionProp] = useState(
    awardDetailData &&
      awardDetailData?.action[0]?.actionProperties?.actionProperty?.map(
        (element) => {
          if (element?.propertyType && element.propertyType === CAP) {
            return true;
          }
        }
      )
  );
  const [showDiscountCap, setShowDiscountCap] = useState(
    actionProp.some((v) => v === true)
  );
  const [isCapExist, setCapExist] = useState(false);
  const dispatch = useDispatch();
  const [isSetDisable, setDisable] = useState(
    offerData?.offerStatus !== OFFER_DETAILS_STATUS_DRAFT
      ? true
      : false || disabled
  );
  useEffect(() => {
    setDisable(
      offerData?.offerStatus !== OFFER_DETAILS_STATUS_DRAFT
        ? true
        : false || disabled
    );
  }, [offerData?.offerStatus, disabled]);

  const [btnClicked, setBtnClicked] = useState(false);
  const securityData = useSelector((state) => state.securityData);
  const discountCapSection = useRef();
  const [showDiscCapInfo, setShowDiscCapInfo] = useState(false);
  const [editProcess, setEditProcess] = useState({
    status: false,
    name: null,
  });

  useEffect(() => {
    const rewardCapData =
      awardDetailData &&
      awardDetailData?.action[0]?.actionProperties?.actionProperty?.filter(
        ({ propertyType }) => propertyType === "CAP"
      );

    const existingCapData =
      rewardCapData && rewardCapData.length > 0
        ? rewardCapData[0]
        : JSON.parse(JSON.stringify(generateInitialDiscountCapProperty()));
    setCapData(existingCapData);
    existingCapData &&
      existingCapData?.property?.map((element) => {
        if (element.name === "MEMBER_TIMES_CAP") {
          setMemberDiscountCap(element.values.value[0]);
        } else if (element.name === "MEMBER_CAP_PERIOD") {
          setDiscountPeriod(element.values.value[0]);
        }
      });
  }, [awardDetailData]);

  useEffect(() => {
    let tmpCapData = capData;

    tmpCapData &&
      tmpCapData?.property?.map((element) => {
        if (element.name === "MEMBER_TIMES_CAP") {
          element.values.value[0] = memberDiscountCap ? memberDiscountCap : 0;
        } else if (element.name === "MEMBER_CAP_PERIOD") {
          element.values.value[0] = discountPeriod ? discountPeriod : 0;
        }
        setCapData((p) => {
          return {
            property: tmpCapData.property,
            propertyType: tmpCapData.propertyType,
          };
        });
      });
  }, [memberDiscountCap, discountPeriod]);

  useEffect(() => {
    if (capData) {
      capData.property.map((property) => {
        if (property.name === "MEMBER_TIMES_CAP") {
          if (property.values.value[0] !== '0') {
            dispatch(updateCapData(capData));
          } else {
            dispatch(updateCapData(""));
          }
        }
      });
    }
  }, [capData]);

  const showContent = (e) => {
    setShowDiscountCap(e.target.checked);
    setBtnClicked(true);
    e.target.checked ? setCapExist(true) : setCapExist(false);
  };

  function handleChangeSelect({ value }, { name }) {
    let e = { target: { name: name, value: value } };
    setDiscountPeriod(value);
    if (value === "DAYS_OFFER_START") {
      setShowOfferStartDays(true);
    }
    handleEditBlur(setEditProcess);
  }

  useLayoutEffect(() => {
    if (showDiscountCap && btnClicked === true) {
      discountCapSection.current.scrollIntoView({ behavior: "smooth" });
    }
    if (showDiscountCap === false && capData) {
      let tmpCapDataProp = capData.property;
      tmpCapDataProp = tmpCapDataProp.filter(
        (element) => element.name !== "MEMBER_TIMES_CAP"
      );
      tmpCapDataProp = tmpCapDataProp.filter(
        (element) => element.name !== "MEMBER_CAP_PERIOD"
      );
      capData.property = tmpCapDataProp;
    }
  }, [showDiscountCap, btnClicked]);

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
    }
  };
  return (
    <>
      <div className="section-top-title setup-page-section-title">
        What is the cap on discounts
      </div>
      <div className="cap-main-div">
        <div className="cap-section-inner">
          <div className="mb-16 cap-content-div">
            <div>
              <label className="content-label">Discount Cap</label>
              <label className="info_icon_display">
                <>
                  <div className="info-btn-reward">
                    <AiOutlineInfoCircle
                      className="info-icon"
                      onMouseEnter={() => {
                        setShowDiscCapInfo(true);
                      }}
                      onMouseLeave={() => {
                        setShowDiscCapInfo(false);
                      }}
                    />

                    {showDiscCapInfo && (
                      <>
                        <div
                          className="info-msg-point"
                          onMouseEnter={() => {
                            setShowDiscCapInfo(true);
                          }}
                          onMouseLeave={() => {
                            setShowDiscCapInfo(false);
                          }}
                        >
                          <div className="triangle"></div>
                          <p>{DISCOUNT_CAP_INFO_TITLE} </p>
                          {DISCOUNT_CAP_INFO_DETAIL}
                        </div>
                      </>
                    )}
                  </div>
                </>
              </label>
              <label className="switch">
                <input
                  type="checkbox"
                  onChange={showContent}
                  checked={
                    btnClicked ? isCapExist : actionProp.some((v) => v === true)
                  }
                  disabled={isSetDisable}
                />
                <span className="slider round"></span>
              </label>
            </div>
          </div>
          {showDiscountCap && (
            <>
              {
                <div className="caps-wrapper" ref={discountCapSection}>
                  <div className="valid-from-section">
                    <p className="valid-from">
                      Award upto
                      <div className="input-with-error">
                        {isSetDisable === false &&
                        (memberDiscountCap === "" ||
                          editProcess.name === "discount_count") ? (
                          <NumericFormat
                            className={`text-input-number ${
                              memberDiscountCap === "" ? "empty-error" : null
                            }`}
                            autoFocus="true"
                            value={memberDiscountCap}
                            thousandsGroupStyle="lakh"
                            thousandSeparator=","
                            displayType="input"
                            decimalScale={0}
                            renderText={(memberDiscountCap) => (
                              <b>{memberDiscountCap}</b>
                            )}
                            isAllowed={(values) => {
                              const { value } = values;
                              return value <= 9999999 || value === "";
                            }}
                            onValueChange={(values, sourceInfo) => {
                              const { value } = values;
                              if (value && value !== "") {
                                setMemberDiscountCap(value);
                              }
                            }}
                            onBlur={(e) => {
                              handleEditBlur(setEditProcess);
                            }}
                            onKeyPress={(e) => {
                              onKeyPressAction(e);
                            }}
                          />
                        ) : (
                          <span
                            data-name="discount_count"
                            onClick={(e) => {
                              handleEditClick(e, setEditProcess);
                            }}
                            className="editable"
                          >
                            {formatNumberCommaSeperated(memberDiscountCap)}
                          </span>
                        )}
                      </div>
                      time(s) per Member
                      {isSetDisable === false &&
                      (discountPeriod === "" ||
                        editProcess.name === "discount_duration_on") ? (
                        <fieldset className="point-fieldset dropdown">
                          <Select
                            name="Discount_Duration"
                            options={discountIntervalUnitOptions}
                            styles={getCustomStyles(theme)}
                            onChange={handleChangeSelect}
                            value={{
                              label: discountIntervalAlias[discountPeriod],
                              value: discountPeriod,
                            }}
                            onBlur={() => {
                              handleEditBlur(setEditProcess);
                            }}
                            defaultMenuIsOpen
                            autoFocus
                            menuPlacement="auto"
                            maxMenuHeight={150}
                          />
                        </fieldset>
                      ) : (
                        <span
                          data-name="discount_duration_on"
                          onClick={(e) => {
                            handleEditClick(e, setEditProcess);
                          }}
                          className="editable"
                        >
                          {discountIntervalAlias[discountPeriod]
                            ? discountIntervalAlias[discountPeriod]
                            : "Per Transaction"}
                        </span>
                      )}
                      {/*
                      isSetDisable === false &&
                      (discountPeriod === "" || editProcess.name === "discount_duration_on") &&                      
                      showOfferStartDays && (
                        <div className="input-with-error">
                        <input
                          autoFocus
                          className={`text-input-number ${
                            daysFromOfferStart === "" ? "empty-error" : null
                          }`}
                          type="text"
                          onChange={(e) => {
                            setValue(e.target.value);
                          }}
                          defaultValue={daysFromOfferStart}
                          onBlur={(e) => {
                            if (value) {
                              setDaysFromOfferStart(e.target.value);
                            }
                          }}
                          onKeyPress={(e)=>{
                            onKeyPressAction(e)
                          }}
                        ></input>
                      </div>                        
                        )*/}
                    </p>
                  </div>
                </div>
              }
            </>
          )}
        </div>
      </div>
    </>
  );
};
export default DiscountOfferCap;
