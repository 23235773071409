import { useEffect, useState } from "react";
import {
	ACTIVE,
	ASCENDING,
	ATTRIBUTE_MODAL_HEADER,
	ATTRIBUTE_TITLE,
	CODE_TITLE_ATTRIBUTE,
	DESCENDING,
	DESCRIPTION_TITLE,
	ENTITY_DEFAULT_VALUE,
	OPERATOR,
	OPERATOR_DEFAULT_VALUE,
	SEMI_ACTIVE,
	SORTED,
	VALUE,
	VALUE_DEFAULT_VALUE,
	ZERO,
	attribute_modal_columns,
	popupConfig,
} from "./GenericRulesConstants.js";
import {
	resetFilters,
	setAttributeName,
	setAttributeType,
	setAttributeValue,
	setAttrInputDropdownOptions,
} from "./GenericRulesUtil.js";
import { AiFillCloseCircle } from "react-icons/ai";
import { VscTriangleDown, VscTriangleUp } from "react-icons/vsc";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getAttributesDetails } from "../../../tellurideExtRedux/metaData/MetaDataAction.js";
import {
	ESLO,
	headers,
	OFRI,
	OFRP,
} from "../../constantComponent/Constants.js";
import { emptyData } from "../../constantComponent/DesignConstant.jsx";
import { loading } from "../../LoaderComponents/loadingDesignConstant.jsx";
import { motion } from "framer-motion/dist/framer-motion";
import { getRightsFromSession, skey } from "../CommonUtil.js";
import SmallGenericTable from "../SmallGenericTable/SmallGenericTable.jsx";
export function AttributeModal({
	attributeModal,
	setAttributeModal,
	setExpression,
}) {
	// attribute list will always be fetched on change on entity
	const metaData = useSelector((state) => state.metaData);
	const ATTRIBUTE_LIST = metaData?.attributesResponse?.attributes;
	const offerData = useSelector((state) => state.offerDetailsData);
	const attributeLoading = metaData?.attributeLoading;
	const [list, setList] = useState([]);
	const [searchedlist, setSearchedList] = useState([]);
	const [searchInput, setSearchInput] = useState("");
	const [isSorting, setIsSorting] = useState({
		status: false,
		order: null,
	});
	const toasterData = useSelector((state) => state.toasterData);
	const [theme, setTheme] = useState(toasterData.isDarkTheme);

	const dispatch = useDispatch();

	useEffect(() => {
		if (
			attributeModal.entityValue !== ENTITY_DEFAULT_VALUE &&
			attributeModal.entityValue !== VALUE_DEFAULT_VALUE
		) {
			headers.sessionId = skey();
			headers.requestAccess = getRightsFromSession();
			headers.organization = offerData?.authOrganizationName;
			const requestHeaders = {
				headers,
			};
			const requestBody = {
				messageVersion: process.env.REACT_APP_MESSAGE_VERSION_01,
				requestorID: OFRI,
				entity: attributeModal.entityValue,
			};
			dispatch(getAttributesDetails(requestHeaders, requestBody));
		}
	}, []);

	useEffect(() => {
		if (attributeLoading === false) {
			setList(ATTRIBUTE_LIST);
		}
	}, [ATTRIBUTE_LIST, attributeLoading]);
	function setAttributeModalData(row) {
		handleAttributeSelect(row.code, row.type, row.options, row?.name);
	}
	function handleAttributeSelect(value, type, options, name) {
		// reset operator value and attr input value
		resetFilters(setExpression, attributeModal, [
			{ filterType: OPERATOR, filterResetValue: OPERATOR_DEFAULT_VALUE },
			{ filterType: VALUE, filterResetValue: VALUE_DEFAULT_VALUE },
		]);
		//setting attribute value and type
		setAttributeValue(setExpression, attributeModal, value);
		setAttributeType(setExpression, attributeModal, type);
		setAttributeName(setExpression, attributeModal, name);

		setAttributeModal((prev) => {
			return {
				...prev,
				status: false,
			};
		});

		// if its dropdown,then setting its options
		setAttrInputDropdownOptions(setExpression, attributeModal, options);
		setSearchInput("");
	}
	function handleSearchChange(e) {
		if (list && list.length > ZERO) {
			let input = e.target.value;
			setSearchInput(input);
			if (searchInput.length > 0) {
				setSearchedList(() => {
					return [...ATTRIBUTE_LIST].filter((item) => {
						return (
							item?.name?.toLowerCase().includes(searchInput.toLowerCase()) ||
							item?.code?.toLowerCase().includes(searchInput.toLowerCase()) ||
							item?.description
								?.toLowerCase()
								.includes(searchInput.toLowerCase())
						);
					});
				});
			}
		} else {
			return;
		}
	}

	// function handleSort(order) {
	//   if (list && list.length > ZERO) {
	//     if (order === isSorting.order) {
	//       setList(() => [...ATTRIBUTE_LIST]);
	//       if (searchInput.length > ZERO) {
	//         setSearchedList(() => {
	//           return [...ATTRIBUTE_LIST].filter((item) => {
	//             return (
	//               item.name.toLowerCase().includes(searchInput.toLowerCase()) ||
	//               item.code.toLowerCase().includes(searchInput.toLowerCase())
	//             );
	//           });
	//         });
	//       }
	//       setIsSorting({
	//         order: null,
	//         status: null,
	//       });
	//     } else {
	//       if (order === ASCENDING) {
	//         console.log(list);
	//         setList((prevList) => {
	//           return prevList.sort(({ name: attr1 }, { name: attr2 }) =>
	//             attr1.localeCompare(attr2)
	//           );
	//         });
	//         if (searchInput.length > ZERO) {
	//           setSearchedList((prevList) => {
	//             return prevList.sort(({ name: attr1 }, { name: attr2 }) =>
	//               attr1.localeCompare(attr2)
	//             );
	//           });
	//         }
	//         setIsSorting({
	//           order: ASCENDING,
	//           status: SORTED,
	//         });
	//       } else if (order === DESCENDING) {
	//         setList((prevList) => {
	//           return prevList.sort(({ name: attr1 }, { name: attr2 }) =>
	//             attr2.localeCompare(attr1)
	//           );
	//         });
	//         if (searchInput.length > ZERO) {
	//           setSearchedList((prevList) => {
	//             return prevList.sort(({ name: attr1 }, { name: attr2 }) =>
	//               attr2.localeCompare(attr1)
	//             );
	//           });
	//         }
	//         setIsSorting({
	//           order: DESCENDING,
	//           status: SORTED,
	//         });
	//       }
	//     }
	//   } else {
	//     return;
	//   }
	// }
	return (
		<>
			<div className="modal-container">
				<div className="modal-backdrop"></div>
				<motion.div
					className="modal"
					initial={{ opacity: 0 }}
					animate={{ opacity: 1 }}
					exit={{ opacity: 0 }}
				>
					<AiFillCloseCircle
						className="attribute-modal-close"
						onClick={() => {
							setAttributeModal((prev) => {
								return {
									...prev,
									status: false,
								};
							});
						}}
					/>
					<p>{ATTRIBUTE_MODAL_HEADER}</p>
					<p>
						<>
							Entity : <b>{attributeModal.entityValue}</b>
						</>
					</p>
					<div className="search-bar-container">
						<input
							type="text"
							placeholder="Search..."
							className="attribute-search"
							value={searchInput}
							onChange={handleSearchChange}
						/>
					</div>
					<div className="attribute-list-container">
						<div className="attribute-list">
							{searchInput.length > ZERO ? (
								searchedlist && searchedlist.length > ZERO ? (
									<SmallGenericTable
										Columns={attribute_modal_columns}
										tableData={searchedlist}
										setTableData={setSearchedList}
										isDataLoading={attributeLoading}
										selectedData={attributeModal?.selectedAttr}
										isRowSelectable={true}
										rowClickHandler={setAttributeModalData}
										config={{
											...popupConfig,
											theme: theme,
											selectedDataKey: "groupName",
											border: false,
										}}
										isShowPagination={false}
									/>
								) : (
									emptyData()
								)
							) : attributeLoading === true ? (
								loading()
							) : list && list.length > ZERO ? (
								<SmallGenericTable
									Columns={attribute_modal_columns}
									tableData={list}
									setTableData={setList}
									isDataLoading={attributeLoading}
									selectedData={attributeModal?.selectedAttr}
									isRowSelectable={true}
									rowClickHandler={setAttributeModalData}
									config={{
										...popupConfig,
										theme: theme,
										selectedDataKey: "code",
										border: false,
									}}
									isShowPagination={false}
								/>
							) : (
								emptyData()
							)}
						</div>
					</div>
				</motion.div>
			</div>
		</>
	);
}
