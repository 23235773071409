export const OFFER = "/offer";
export const DETAILS = "/details";
export const CUSTOM = "/custom";
export const VIEW_OFFER = "/view";
export const DASHBOARD = "/dashboard";
export const SEGMENTS = "/segments";
export const VIEW_SEGMENT = "/view";
export const EDIT_SEGMENT = "/edit";
export const CONFIGURE = "/configure";
export const SUBCLUB = "/subclub";
export const ATTRIBUTE = "/attribute";
export const VIEW = "/view";
export const CAMPAIGN = "/campaign";
export const VIEW_CAMPAIGN = "/view";
export const CONTENT = "/content";
export const CONFIGURE_ATTRIBUTE_DETAILS = "/configure/attribute/details";
export const CONFIGURE_ATTRIBUTE_VIEW = "/configure/attribute/view";
