import React, { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import "./Loading.style.scss";
import { useSelector } from "react-redux";
import { DARK } from "../constantComponent/Constants";
const SkeletonTableLoading = ({ columns, layoutLoading }) => {
	//theme
	const toasterData = useSelector((state) => state.toasterData);
	const [theme, setTheme] = useState(toasterData.isDarkTheme);
	useEffect(() => {
		setTheme(toasterData.isDarkTheme);
	}, [toasterData]);
	//theme
	// baseColor="#202020" highlightColor="#444"
	return (
		<div className="card-skeleton">
			{columns.map((col, index) => {
				return (
					<div
						style={
							layoutLoading
								? {
										width: "100%",
								  }
								: { minWidth: col.minWidth - 8 }
						}
						key={index}
					>
						<Skeleton
							baseColor={theme === DARK ? "#202020" : ""}
							highlightColor={theme === DARK ? "#444" : ""}
						/>
					</div>
				);
			})}
		</div>
	);
};
export default SkeletonTableLoading;
