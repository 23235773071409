import {
  DARK,
  DROPDOWN_BGCOLOR_DARK_THEME,
  DROPDOWN_FOCUSED_BGCOLOR_DARK_THEME,
  DROPDOWN_FOCUSED_BGCOLOR_LIGHT_THEME,
  DROPDOWN_SELECTED_BGCOLOR_DARK_THEME,
  DROPDOWN_SELECTED_BGCOLOR_LIGHT_THEME,
  LIGHT,
} from "../../constantComponent/Constants";
import { formatDate } from "../../utilsComponent/CommonUtil";
import {
  CONTENT_END_DATE,
  CONTENT_START_DATE,
} from "./ContentDetailsConstants";

// remove error class from start date if input data is added.
export const removeErrorClass = () => {
  const inputStartDate = document.getElementById("input-start-date");
  if (inputStartDate?.value) {
    const startDate = document.getElementById("start-date");
    startDate.classList.remove("error-start-date");
  }
  return;
};
export const dateHandler = (name, value, contentDetailValues, setValues) => {
  setValues((prev) => {
    return {
      ...prev,
      [name]: value ? value : 0,
    };
  });
  if (name === CONTENT_START_DATE) {
    let isEndDateSmall =
      new Date(contentDetailValues?.contentEndDate) < new Date(value);
    if (isEndDateSmall) {
      setValues((prev) => {
        return {
          ...prev,
          [CONTENT_END_DATE]: value,
        };
      });
    }
  }
  return;
};

export const valueHandler = (event, contentDetailValues, setValues) => {
  removeErrorClass();
  setValues({
    ...contentDetailValues,
    [event.target.name]: event.target.value,
  });
};
export const validateDescription = (e) => {
  if (e.target.value !== "") {
    const validatedString = e.target.value.replace(
      /[*|\":<>/[\]{}`\\()';@&$]/g,
      ""
    );
    return validatedString;
  }
  return "";
};
export function convertUTCDateToLocalDate(date) {
  if (date) {
    date = new Date(date);
    var newDate = new Date(
      date.getTime() + date.getTimezoneOffset() * 60 * 1000
    );

    var offset = date.getTimezoneOffset() / 60;
    var hours = date.getHours();

    newDate.setHours(hours - offset);
    return newDate;
  }

  return date;
}
export const getCustomStyles = (theme) => {
  return {
    control: (provided, state) => ({
      border: "none",
      borderRadius: "4px",
      display: "flex",
      padding: "0px",
      outline: "none",
      height: "34px",
      position: "relative",
      top: "-8px",
      left: "-8px",
      margin: 0,
      // Add more custom styles as needed
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor:
        state.isSelected && theme === LIGHT
          ? DROPDOWN_SELECTED_BGCOLOR_LIGHT_THEME
          : state.isSelected && theme === DARK
          ? DROPDOWN_SELECTED_BGCOLOR_DARK_THEME
          : // ? DROPDOWN_FOCUSED_BGCOLOR_LIGHT_THEME
          state.isFocused && theme === DARK
          ? DROPDOWN_FOCUSED_BGCOLOR_DARK_THEME
          : state.isFocused && theme === LIGHT
          ? DROPDOWN_FOCUSED_BGCOLOR_LIGHT_THEME
          : theme === DARK
          ? DROPDOWN_BGCOLOR_DARK_THEME
          : "#fff",
      color: state.isSelected
        ? "#fff"
        : state.isFocused && theme === DARK
        ? "#fff"
        : state.isFocused && theme === LIGHT
        ? DROPDOWN_SELECTED_BGCOLOR_LIGHT_THEME
        : theme === LIGHT
        ? DROPDOWN_SELECTED_BGCOLOR_LIGHT_THEME
        : "#fff",
      padding: 8,
    }),
    // Add more style properties as needed
  };
};
