import { CONTENT_NAME } from "../../campaignSetupComponent/campaignBuilderComponent/CampaignBuilderConstants";
import {
  CREATE_OFFER_LINK,
  OFFER_DETAILS_STATUS_DRAFT,
  CREATE_SEGMENT_LINK,
  SEGMENT,
  OFFER_TITLE,
  CAMPAIGN_TITLE,
  CONTENT_TITLE,
} from "../../constantComponent/Constants";
import {
  CAMPAIGN,
  CONTENT,
  DETAILS,
  CUSTOM,
  OFFER,
  SEGMENTS,
} from "../../constantComponent/PathConstants";

export function handleMenuClick() {
  let sideBar = document.querySelector(".leftsidebar");
  let navLinkTexts = document.querySelectorAll(".nav-link-text");
  let navLinkCompleteIcons = document.querySelectorAll(".tick-icon");
  document.querySelector(".opener-container").classList.toggle("open");
  sideBar.ontransitionend = null;
  if (sideBar.classList.contains("open")) {
    navLinkTexts.forEach((elm) => {
      elm.classList.remove("open");
    });
    navLinkCompleteIcons.forEach((elm) => {
      elm.classList.remove("open");
    });
    sideBar.classList.remove("open");
  } else {
    sideBar.classList.add("open");
    sideBar.ontransitionend = () => {
      navLinkTexts.forEach((elm) => {
        elm.classList.add("open");
      });
      navLinkCompleteIcons.forEach((elm) => {
        elm.classList.add("open");
      });
    };
  }
}
export function showSetupCreationConfirmationPopup() {
  const backdrop = document.querySelector("#createOffer-confirmation-model");
  backdrop?.classList.add("show");
}
export function hideSetupPageConfirmationPopup() {
  const backdrop = document.querySelector("#createOffer-confirmation-model");
  backdrop?.classList.remove("show");
}
export function handleModelPopup(
  location,
  navigate,
  navlink,
  showOfferPopup,
  setShowOfferPopup,
  offerStatus,
  isOfferChanged,
  src,
  isSegmentEdited,
  isCampaignChanged,
  campaignStatus,
  customOfferChanged,
  customOfferStatus
) {
  switch (src) {
    case SEGMENT:
      if (
        location.pathname.includes(SEGMENTS + DETAILS) &&
        isSegmentEdited
      ) {
        showSetupCreationConfirmationPopup();
      } else {
        handleNavigation(navigate, navlink, showOfferPopup, setShowOfferPopup);
      }
      break;
    case OFFER_TITLE:
      if (
        (location.pathname.includes(OFFER + DETAILS) &&
          offerStatus === OFFER_DETAILS_STATUS_DRAFT &&
          isOfferChanged) ||
        (location.pathname.includes(OFFER + CUSTOM + DETAILS) &&
          customOfferStatus === OFFER_DETAILS_STATUS_DRAFT &&
          customOfferChanged)
      ) {
        showSetupCreationConfirmationPopup();
      } else {
        handleNavigation(navigate, navlink, showOfferPopup, setShowOfferPopup);
      }
      break;
    case CONTENT_TITLE:
      if (
        location.pathname.includes(CONTENT + DETAILS) &&
        offerStatus === OFFER_DETAILS_STATUS_DRAFT &&
        isOfferChanged
      ) {
        showSetupCreationConfirmationPopup();
      } else {
        handleNavigation(navigate, navlink, showOfferPopup, setShowOfferPopup);
      }
      break;
    case CAMPAIGN_TITLE:
      if (
        location.pathname.includes(CAMPAIGN + DETAILS) &&
        campaignStatus === OFFER_DETAILS_STATUS_DRAFT &&
        isCampaignChanged
      ) {
        showSetupCreationConfirmationPopup();
      } else {
        handleNavigation(navigate, navlink, showOfferPopup, setShowOfferPopup);
      }
      break;
    default:
      handleNavigation(navigate, navlink, showOfferPopup, setShowOfferPopup);
  }
}

export function handleNavigation(
  navigate,
  navlink,
  showOfferPopup,
  setShowOfferPopup
) {
  if (navlink.label === CREATE_OFFER_LINK) {
    if (showOfferPopup === false) {
      setShowOfferPopup(true);
    }
  } else if (navlink.label === CREATE_SEGMENT_LINK) {
    if (showOfferPopup === false) {
      setShowOfferPopup(true);
    }
  } else {
    navigate("/" + window.location.pathname.split("/")[1] + navlink.url);
  }
  hideSetupPageConfirmationPopup();
}
