import axios from "axios";
import {
  GET_CHART_CAMPAIGN_STATUS_FAILURE,
  GET_CHART_CAMPAIGN_STATUS_REQUEST,
  GET_CHART_CAMPAIGN_STATUS_SUCCESS,
  GET_CHART_CAMPAIGN_STATUS_SUCCESS_FAILURE,
  GET_CHART_ENGAGEMENT_STATUS_FAILURE,
  GET_CHART_ENGAGEMENT_STATUS_REQUEST,
  GET_CHART_ENGAGEMENT_STATUS_SUCCESS,
  GET_CHART_ENGAGEMENT_STATUS_SUCCESS_FAILURE,
  SET_BLANK_SESSION_ID_ERROR,
  SET_SESSION_TIMEOUT_POPUP,
  UPDATE_CAMPAIGN_STATUS_REQUEST,
  UPDATE_CAMPAIGN_STATUS_SUCCESS,
  UPDATE_CAMPAIGN_STATUS_FAILURE,
  UPDATE_CAMPAIGN_STATUS_SUCCESS_FAILURE,
  SET_CAMPAIGN_OFFER_CHANGE_STATUS,
  GET_CAMPAIGN_PERFORMANCE_LIST_REQUEST,
  GET_CAMPAIGN_PERFORMANCE_LIST_SUCCESS,
  GET_CAMPAIGN_PERFORMANCE_LIST_FAILURE,
  GET_CAMPAIGN_PERFORMANCE_LIST_SUCCESS_FAILURE,
  GET_VARIATION_STATS_SUCCESS,
  GET_VARIATION_STATS_SUCCESS_FAILURE,
  GET_VARIATION_STATS_FAILURE,
  GET_VARIATION_STATS_REQUEST,
  SAVE_WINNER_REQUEST,
  SAVE_WINNER_SUCCESS,
  SAVE_WINNER_SUCCESS_FAILURE,
  SAVE_WINNER_FAILURE,
  LAUNCH_CAMPAIGN_OFFER_REQUEST,
  LAUNCH_CAMPAIGN_OFFER_SUCCESS,
  LAUNCH_CAMPAIGN_OFFER_FAILURE,
  LAUNCH_CAMPAIGN_OFFER_SUCCESS_FAILURE,
  AB_TEST_START_REQUEST,
  AB_TEST_START_SUCCESS,
  AB_TEST_START_FAILURE,
  AB_TEST_START_SUCCESS_FAILURE,
} from "./CampaignDetailsType";
import { setErrorToaster, setSuccessToaster } from "../toaster/ToasterAction";
import {
  ERROR_MESSAGE,
  setBlankSession,
  setSessionExpire,
  setToaster,
} from "../reduxUtil";
import {
  OFFER_DETAILS_STATUS_ACTIVATED,
  OFFER_DETAILS_STATUS_DRAFT,
  OFRI,
  SUCCESS,
} from "../../tellurideExtComponents/constantComponent/Constants";
import {
  EDITABLE,
  LAUNCH_OFFER_SUCCESS,
  OFFER_SAVE,
  OFFER_STATUS_UPDATE_SUCCESS_MESSAGE,
  START_AB_TEST_SUCCESS,
  WINNER_VARIATION_SUCCESS,
} from "../../tellurideExtComponents/errorsComponent/ErrorConstant";
import {
  CAMPAIGN,
  DETAILS,
} from "../../tellurideExtComponents/constantComponent/PathConstants";
import _ from "lodash";
// import { setSessionExpirePopUp } from "../offerDetails/OfferDetailsAction";

export const fetchChartCampaignTotalStats = (requestHeaders, requestBody) => {
  return (dispatch) => {
    dispatch(fetchChartCampaignTotalStatsRequest());
    // const chartData = {
    // 	summary: [
    // 		{
    // 			date: "2023-8-29",
    // 			totalActive: 19,
    // 			completed: 6,
    // 			totalDraft: 20,
    // 		},
    // 		{
    // 			date: "2023-8-30",
    // 			totalActive: 20,
    // 			completed: 6,
    // 			totalDraft: 5,
    // 		},
    // 		{
    // 			date: "2023-8-31",
    // 			totalActive: 10,
    // 			completed: 10,
    // 			totalDraft: 5,
    // 		},
    // 		{
    // 			date: "2023-9-1",
    // 			totalActive: 10,
    // 			completed: 16,
    // 			totalDraft: 5,
    // 		},
    // 		{
    // 			date: "2023-9-2",
    // 			totalActive: 10,
    // 			completed: 15,
    // 			totalDraft: 5,
    // 		},
    // 		{
    // 			date: "2023-9-3",
    // 			totalActive: 20,
    // 			completed: 9,
    // 			totalDraft: 5,
    // 		},
    // 		{
    // 			date: "2023-9-4",
    // 			totalActive: 10,
    // 			completed: 10,
    // 			totalDraft: 5,
    // 		},
    // 	],
    // 	status: SUCCESS,
    // };
    // dispatch(fetchChartCampaignTotalStatsSuccess(chartData));

    axios
      .post(
        process.env.REACT_APP_URL_DOMAIN +
          process.env.REACT_APP_GET_OFFER_STATUS_STATS,
        requestBody,
        requestHeaders
      )
      .then((resp) => {
        if (resp.data.errors !== undefined && resp.data.errors.length !== 0) {
          dispatch(fetchChartCampaignTotalStatsSuccessFailure(resp.data));
          dispatch(setErrorToaster(resp.data.errors[0].message));
          setSessionExpire(resp, setSessionExpirePopUp, dispatch);
        } else {
          dispatch(fetchChartCampaignTotalStatsSuccess(resp.data));
        }
      })
      .catch((err) => {
        dispatch(fetchChartCampaignTotalStatsFailure(err.message));
        setToaster(err, ERROR_MESSAGE, dispatch);
        setSessionExpire(err, setSessionExpirePopUp, dispatch);
        setBlankSession(err, dispatch, requestHeaders, setBlankSessionIdError);
      });
  };
};

export const fetchChartCampaignTotalStatsRequest = () => {
  return {
    type: GET_CHART_CAMPAIGN_STATUS_REQUEST,
  };
};

export const fetchChartCampaignTotalStatsSuccess = (apiData) => {
  return {
    type: GET_CHART_CAMPAIGN_STATUS_SUCCESS,
    payload: apiData,
  };
};

export const fetchChartCampaignTotalStatsFailure = (error) => {
  return {
    type: GET_CHART_CAMPAIGN_STATUS_FAILURE,
    payload: error,
  };
};

export const fetchChartCampaignTotalStatsSuccessFailure = (error) => {
  return {
    type: GET_CHART_CAMPAIGN_STATUS_SUCCESS_FAILURE,
    payload: error,
  };
};

//Delivery chart

// engagement chart
export const fetchChartEngagementStatus = (requestHeaders, requestBody) => {
  return (dispatch) => {
    dispatch(fetchChartEngagementStatusRequest());
    const chartData = {
      summary: [
        {
          date: "2023-8-29",
          impressions: 1,
          clicks: 1,
        },
        {
          date: "2023-8-30",
          impressions: 2,
          clicks: 2.5,
        },
        {
          date: "2023-8-31",
          impressions: 3.5,
          clicks: 4.5,
        },
        {
          date: "2023-9-1",
          impressions: 2,
          clicks: 3,
        },
        {
          date: "2023-9-2",
          impressions: 2,
          clicks: 3,
        },
        {
          date: "2023-9-3",
          impressions: 2,
          clicks: 3,
        },
        {
          date: "2023-9-4",
          impressions: 2,
          clicks: 3,
        },
      ],
      status: SUCCESS,
    };
    dispatch(fetchChartEngagementStatusSuccess(chartData));
  };
};

export const fetchChartEngagementStatusRequest = () => {
  return {
    type: GET_CHART_ENGAGEMENT_STATUS_REQUEST,
  };
};

export const fetchChartEngagementStatusSuccess = (apiData) => {
  return {
    type: GET_CHART_ENGAGEMENT_STATUS_SUCCESS,
    payload: apiData,
  };
};

export const fetchChartEngagementStatusFailure = (error) => {
  return {
    type: GET_CHART_ENGAGEMENT_STATUS_FAILURE,
    payload: error,
  };
};

export const fetchChartEngagementStatusSuccessFailure = (error) => {
  return {
    type: GET_CHART_ENGAGEMENT_STATUS_SUCCESS_FAILURE,
    payload: error,
  };
};

// campaign details

//delete this later
export const callFetchWorkFlowDetails = (
  requestHeaders,
  dispatch,
  navigate,
  redirectionPath,
  offerNumber
) => {
  const offerRequestBody = {
    requestorID: OFRI,
    messageVersion: process.env.REACT_APP_MESSAGE_VERSION_03,
    offerNumber: offerNumber ? offerNumber : "",
  };
  // dispatch(
  //   fetchWorkFlowDetails(
  //     requestHeaders,
  //     offerRequestBody,
  //     navigate,
  //     redirectionPath
  //   )
  // );
};

export const updateCampaignStatusHandller = (
  statusValue,
  requestHeaders,
  dispatch,
  campaignData,
  navigate,
  userEmail
) => {
  const updateOfferStatusRequestBody = {
    requestorID: OFRI,
    messageVersion: process.env.REACT_APP_MESSAGE_VERSION_01,
    source: "TELL",
    modifiedBy: userEmail,
    // modifiedTS: "2023-06-27 20:44:29",
    offersInfo: {
      offerInfo: [
        {
          offerNumber: campaignData.offerNumber,
          offerStatus: statusValue,
        },
      ],
    },
    additionalAttributes: {
      additionalAttribute: [
        {
          name: "RealTimeFlag",
          values: {
            value: ["Y"],
          },
        },
      ],
    },
  };

  dispatch(
    updateCampaignStatus(
      requestHeaders,
      updateOfferStatusRequestBody,
      navigate,
      campaignData
    )
  );
};

export const updateCampaignStatus = (
  requestHeaders,
  requestBody,
  navigate,
  campaignData
) => {
  return async (dispatch) => {
    dispatch(updateCampaignStatusRequest());
    try {
      const resp = await axios.post(
        process.env.REACT_APP_URL_DOMAIN +
          process.env.REACT_APP_UPDATE_CAMPAIGN_STATUS,
        requestBody,
        requestHeaders
      );
      if (resp.data.errors !== undefined && resp.data.errors.length !== 0) {
        dispatch(updateCampaignStatusSuccessFailure(resp.data));
        dispatch(setErrorToaster(resp.data.errors[0].message));
        callFetchWorkFlowDetails(
          requestHeaders,
          dispatch,
          navigate,
          "/campaign/view",
          campaignData?.campaignNumber
        );
        setSessionExpire(resp, setSessionExpirePopUp, dispatch);
      } else {
        dispatch(updateCampaignStatusSuccess(resp.data));
        callFetchWorkFlowDetails(
          requestHeaders,
          dispatch,
          navigate,
          "/campaign/view",
          resp?.data?.offerInfoResponse[0]?.offerNumber
        );

        const successMessage =
          resp.data?.offerInfoResponse[0]?.offerStatus ===
          OFFER_DETAILS_STATUS_DRAFT
            ? EDITABLE
            : resp.data?.offerInfoResponse[0]?.offerStatus?.toLowerCase();
        dispatch(
          setSuccessToaster(
            resp.data?.offerInfoResponse[0]?.offerStatus ===
              OFFER_DETAILS_STATUS_DRAFT
              ? successMessage
              : OFFER_STATUS_UPDATE_SUCCESS_MESSAGE + successMessage
          )
        );
      }
    } catch (err) {
      dispatch(updateCampaignStatusFailure(err.message));
      setToaster(err, ERROR_MESSAGE, dispatch);
      callFetchWorkFlowDetails(
        requestHeaders,
        dispatch,
        navigate,
        "/campaign/view",
        campaignData?.campaignNumber
      );
      setSessionExpire(err, setSessionExpirePopUp, dispatch);
      setBlankSession(err, dispatch, requestHeaders, setBlankSessionIdError);
    }
  };
};

export const updateCampaignStatusRequest = () => {
  return {
    type: UPDATE_CAMPAIGN_STATUS_REQUEST,
  };
};

export const updateCampaignStatusSuccess = (apiData) => {
  return {
    type: UPDATE_CAMPAIGN_STATUS_SUCCESS,
    payload: apiData,
  };
};
export const updateCampaignStatusFailure = (apiData) => {
  return {
    type: UPDATE_CAMPAIGN_STATUS_FAILURE,
    payload: apiData,
  };
};
export const updateCampaignStatusSuccessFailure = (apiData) => {
  return {
    type: UPDATE_CAMPAIGN_STATUS_SUCCESS_FAILURE,
    payload: apiData,
  };
};

export const getCampaignPerformanceList = (
  requestHeaders,
  requestBody,
  apiurl,
  controller
) => {
  return async (dispatch) => {
    dispatch(getCampaignPerformanceListRequest());
    try {
      const resp = await axios.post(apiurl, requestBody, {
        ...requestHeaders,
        signal: controller.signal,
      });
      if (resp.data.errors !== undefined && resp.data.errors.length !== 0) {
        dispatch(getCampaignPerformanceListSuccessFailure(resp.data));
        dispatch(setErrorToaster(resp.data.errors[0].message));
        setSessionExpire(resp, setSessionExpirePopUp, dispatch);
      } else {
        dispatch(getCampaignPerformanceListSuccess(resp.data));
      }
    } catch (err) {
      dispatch(getCampaignPerformanceListFailure(err.message));
      setToaster(err, ERROR_MESSAGE, dispatch);
      setSessionExpire(err, setSessionExpirePopUp, dispatch);
      setBlankSession(err, dispatch, requestHeaders, setBlankSessionIdError);
    }
  };
};
export const getCampaignPerformanceListRequest = () => {
  return {
    type: GET_CAMPAIGN_PERFORMANCE_LIST_REQUEST,
  };
};

export const getCampaignPerformanceListSuccess = (apiData) => {
  return {
    type: GET_CAMPAIGN_PERFORMANCE_LIST_SUCCESS,
    payload: apiData,
  };
};
export const getCampaignPerformanceListFailure = (apiData) => {
  return {
    type: GET_CAMPAIGN_PERFORMANCE_LIST_FAILURE,
    payload: apiData,
  };
};
export const getCampaignPerformanceListSuccessFailure = (apiData) => {
  return {
    type: GET_CAMPAIGN_PERFORMANCE_LIST_SUCCESS_FAILURE,
    payload: apiData,
  };
};
export const setSessionExpirePopUp = () => {
  return {
    type: SET_SESSION_TIMEOUT_POPUP,
  };
};
export const setBlankSessionIdError = () => {
  return {
    type: SET_BLANK_SESSION_ID_ERROR,
  };
};
export const setCampaignOfferChangeStatus = (status) => {
  return {
    type: SET_CAMPAIGN_OFFER_CHANGE_STATUS,
    payload: status,
  };
};

// export const getVariationStats = (requestHeaders, requestBody) => {
//   return async (dispatch) => {
//     dispatch(getVariationStatsRequest());
//     try {
//       const resp = await axios.post(
//         process.env.REACT_APP_URL_DOMAIN +
//           process.env.REACT_APP_VARIATION_STATS,
//         requestBody,
//         requestHeaders
//       );
//       if (resp.data.errors !== undefined && resp.data.errors.length !== 0) {
//         dispatch(getVariationStatsSuccessFailure(resp.data));
//         dispatch(setErrorToaster(resp.data.errors[0].message));
//         setSessionExpire(resp, setSessionExpirePopUp, dispatch);
//       } else {
//         dispatch(getVariationStatsSuccess(resp.data));
//       }
//     } catch (err) {
//       dispatch(getVariationStatsFailure(err.message));
//       setToaster(err, ERROR_MESSAGE, dispatch);
//       setSessionExpire(err, setSessionExpirePopUp, dispatch);
//       setBlankSession(err, dispatch, requestHeaders, setBlankSessionIdError);
//     }
//   };
// };

// export const getVariationStatsRequest = () => {
//   return {
//     type: GET_VARIATION_STATS_REQUEST,
//   };
// };

// export const getVariationStatsSuccess = (apiData) => {
//   return {
//     type: GET_VARIATION_STATS_SUCCESS,
//     payload: apiData,
//   };
// };

// export const getVariationStatsSuccessFailure = (error) => {
//   return {
//     type: GET_VARIATION_STATS_SUCCESS_FAILURE,
//     payload: error,
//   };
// };

// export const getVariationStatsFailure = (error) => {
//   return {
//     type: GET_VARIATION_STATS_FAILURE,
//     payload: error,
//   };
// };

// export const saveVariationWinner = (
//   requestHeaders,
//   requestBody,
//   navigate,
//   offerNumber
// ) => {
//   return async (dispatch) => {
//     dispatch(saveVariationWinnerRequest());
//     try {
//       const resp = await axios.post(
//         process.env.REACT_APP_URL_DOMAIN +
//           process.env.REACT_APP_SAVE_VARIATION_WINNER,
//         requestBody,
//         requestHeaders
//       );
//       if (resp.data.errors !== undefined && resp.data.errors.length !== 0) {
//         dispatch(saveVariationWinnerSuccessFailure(resp.data));
//         dispatch(setErrorToaster(resp.data.errors[0].message));
//         setSessionExpire(resp, setSessionExpirePopUp, dispatch);
//       } else {
//         dispatch(setSuccessToaster(WINNER_VARIATION_SUCCESS));
//         dispatch(saveVariationWinnerSuccess(resp.data));
//         callFetchWorkFlowDetails(
//           requestHeaders,
//           dispatch,
//           navigate,
//           "/campaign/view",
//           offerNumber
//         );
//       }
//     } catch (err) {
//       dispatch(saveVariationWinnerFailure(err.message));
//       setToaster(err, ERROR_MESSAGE, dispatch);
//       setSessionExpire(err, setSessionExpirePopUp, dispatch);
//       setBlankSession(err, dispatch, requestHeaders, setBlankSessionIdError);
//     }
//   };
// };

// export const saveVariationWinnerRequest = () => {
//   return {
//     type: SAVE_WINNER_REQUEST,
//   };
// };

// export const saveVariationWinnerSuccess = (apiData) => {
//   return {
//     type: SAVE_WINNER_SUCCESS,
//     payload: apiData,
//   };
// };

// export const saveVariationWinnerSuccessFailure = (error) => {
//   return {
//     type: SAVE_WINNER_SUCCESS_FAILURE,
//     payload: error,
//   };
// };

// export const saveVariationWinnerFailure = (error) => {
//   return {
//     type: SAVE_WINNER_FAILURE,
//     payload: error,
//   };
// };

export const launchCampaignOffer = (
  requestHeaders,
  requestBody,
  navigate,
  isCallGetCampaignDetails
) => {
  return async (dispatch) => {
    dispatch(launchCampaignOfferRequest());
    try {
      const resp = await axios.post(
        process.env.REACT_APP_URL_DOMAIN +
          process.env.REACT_APP_LAUNCH_CAMPAIGN,
        requestBody,
        requestHeaders
      );
      if (resp.data.errors !== undefined && resp.data.errors.length !== 0) {
        dispatch(launchCampaignOfferSuccessFailure(resp.data));
        dispatch(setErrorToaster(resp.data.errors[0].message));
        setSessionExpire(resp, setSessionExpirePopUp, dispatch);
      } else {
        dispatch(setSuccessToaster(LAUNCH_OFFER_SUCCESS));
        dispatch(launchCampaignOfferSuccess(resp.data));
        if (isCallGetCampaignDetails) {
          callFetchWorkFlowDetails(
            requestHeaders,
            dispatch,
            navigate,
            "/campaign/view",
            resp?.data?.offerInfoResponse[0]?.offerNumber
          );
        }
      }
    } catch (err) {
      dispatch(launchCampaignOfferFailure(err.message));
      setToaster(err, ERROR_MESSAGE, dispatch);
      setSessionExpire(err, setSessionExpirePopUp, dispatch);
      setBlankSession(err, dispatch, requestHeaders, setBlankSessionIdError);
    }
  };
};

export const launchCampaignOfferRequest = () => {
  return {
    type: LAUNCH_CAMPAIGN_OFFER_REQUEST,
  };
};

export const launchCampaignOfferSuccess = (apiData) => {
  return {
    type: LAUNCH_CAMPAIGN_OFFER_SUCCESS,
    payload: apiData,
  };
};

export const launchCampaignOfferSuccessFailure = (error) => {
  return {
    type: LAUNCH_CAMPAIGN_OFFER_SUCCESS_FAILURE,
    payload: error,
  };
};

export const launchCampaignOfferFailure = (error) => {
  return {
    type: LAUNCH_CAMPAIGN_OFFER_FAILURE,
    payload: error,
  };
};

export const abTestStart = (requestHeaders, requestBody, navigate) => {
  return async (dispatch) => {
    dispatch(abTestStartRequest());
    try {
      const resp = await axios.post(
        process.env.REACT_APP_URL_DOMAIN + process.env.REACT_APP_AB_TEST_START,
        requestBody,
        requestHeaders
      );
      if (resp.data.errors !== undefined && resp.data.errors.length !== 0) {
        dispatch(abTestStartSuccessFailure(resp.data));
        dispatch(setErrorToaster(resp.data.errors[0].message));
        setSessionExpire(resp, setSessionExpirePopUp, dispatch);
      } else {
        dispatch(setSuccessToaster(START_AB_TEST_SUCCESS));
        dispatch(abTestStartSuccess(resp.data));
        callFetchWorkFlowDetails(
          requestHeaders,
          dispatch,
          navigate,
          "/campaign/view",
          resp?.data?.offerInfoResponse[0]?.offerNumber
        );
      }
    } catch (err) {
      dispatch(abTestStartFailure(err.message));
      setToaster(err, ERROR_MESSAGE, dispatch);
      setSessionExpire(err, setSessionExpirePopUp, dispatch);
      setBlankSession(err, dispatch, requestHeaders, setBlankSessionIdError);
    }
  };
};

export const abTestStartRequest = () => {
  return {
    type: AB_TEST_START_REQUEST,
  };
};

export const abTestStartSuccess = (apiData) => {
  return {
    type: AB_TEST_START_SUCCESS,
    payload: apiData,
  };
};

export const abTestStartSuccessFailure = (error) => {
  return {
    type: AB_TEST_START_SUCCESS_FAILURE,
    payload: error,
  };
};

export const abTestStartFailure = (error) => {
  return {
    type: AB_TEST_START_FAILURE,
    payload: error,
  };
};
