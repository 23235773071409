import {
  SET_LEAVE_SETUP_CREATION_PAGE,
  SET_SOURCE_CANCEL_BTN,
} from "./LeavingSetupCreationType";

const initialState = {
  leaveSetupPageState: false,
  sourceCancelBtnState: false,
  pathCancelBtn: "",
};

export const LeavingSetupCreationReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_LEAVE_SETUP_CREATION_PAGE:
      return {
        ...state,
        leaveSetupPageState: action.payload,
      };
    case SET_SOURCE_CANCEL_BTN:
      return {
        ...state,
        sourceCancelBtnState: action.payload.state,
        pathCancelBtn: action.payload.path,
      };
    default:
      return state;
  }
};
