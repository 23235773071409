import { React, useState } from "react";
import "./EventSelectionComponent.style.scss";
import { FaRegTimesCircle } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { miniloading } from "../../../../LoaderComponents/loadingDesignConstant";
import {
	EVENT_USE_TITLE,
	PROCEED,
	START,
	eventsLogo,
} from "../../CampaignBuilderConstants";
import { motion } from "framer-motion/dist/framer-motion";
import Skeleton from "react-loading-skeleton";
import { emptyData } from "../../../../constantComponent/DesignConstant";
import {
	CANCEL,
	DARK,
	OFRI,
	headers,
} from "../../../../constantComponent/Constants";
import {
	getRightsFromSession,
	skey,
} from "../../../../utilsComponent/CommonUtil";
import { getSegmentsList } from "../../../../../tellurideExtRedux";
import { updateNodeData } from "../../../../utilsComponent/GenericFlow/GenericFlowUtil";
import { getJourneyMetaData } from "../../../../../tellurideExtRedux/journey/JourneyAction";
import Loading from "../../../../LoaderComponents/Loading";
import { TRIGGER_EVENT_LOADER_SOURCE } from "../../../../LoaderComponents/LoadingConstant";
const EventSelectionComponent = ({
	theme,
	setPopupShowProcess,
	currentClickedNode,
	setNodeData,
	isDisabled,
}) => {
	// const campaignData = useSelector((state) => state.campaignDetailsData);
	const journeyDetailsData = useSelector((state) => state.journeyDetailsData);

	const offerDetailsPageData = useSelector((state) => state.offerDetailsData);
	const campaignEvents = journeyDetailsData?.journeyMetaDataTriggerEvents;
	const [selectEvent, setSelectEvent] = useState({
		triggerPoint: currentClickedNode?.data?.value?.action?.triggerPoint,
	});
	const [eventSelectionValue, setEventSelectionValue] = useState({});
	const securityData = useSelector((state) => state.securityData);
	const dispatch = useDispatch();
	useEffect(() => {
		headers.sessionId = skey();
		headers.requestAccess = getRightsFromSession();
		headers.organization = offerDetailsPageData?.authOrganizationName;
		const requestHeaders = {
			headers,
		};
		dispatch(getJourneyMetaData({}, requestHeaders));
	}, []);
	useEffect(() => {
		const eventNodeData = {
			displayData: {
				triggerPoint: selectEvent.triggerPoint,
			},
			value: {
				action: {
					triggerPoint: selectEvent.triggerPoint,
				},
			},
		};
		setEventSelectionValue(eventNodeData);
	}, [selectEvent]);
	return (
		<Loading source={TRIGGER_EVENT_LOADER_SOURCE}>
			<section>
				<div
					className="inner-modal-actions"
					style={{
						transform: "none !important",
					}}
				>
					<>
						<div className="segment-popup-wrapper">
							<div
								className="event-selection-body campaign-action-main-div"
								style={{
									width: "98% !important",
									padding: 10,
								}}
							>
								{campaignEvents &&
									campaignEvents.map((eve, index) => {
										return (
											<div
												key={index}
												className={`action-div fs-12 ${
													eve.eventName === selectEvent.triggerPoint
														? "selected"
														: ""
												} ${isDisabled ? "disabled" : ""}`}
												onClick={() => {
													if (isDisabled) {
														return;
													}
													setSelectEvent((prev) => {
														return {
															...prev,
															triggerPoint: eve.eventName,
														};
													});
												}}
											>
												<div className="campaign-event-logo">
													{eventsLogo[eve.eventName]}
												</div>
												<p>{eve.displayName}</p>
											</div>
										);
									})}
							</div>
							<footer className="campaign-modal-footer">
								{/* <button
                className="btn btn-secondary"
                onClick={() => {
                  setPopupShowProcess(false);
                }}
              >
                {CANCEL}
              </button> */}
								<button
									className="btn btn-primary continue-setup-btn"
									disabled={
										isDisabled || !selectEvent?.triggerPoint ? true :false
									}
									onClick={() => {
										if (isDisabled || !selectEvent?.triggerPoint ? true : false) {
											return;
										}
										updateNodeData(
											currentClickedNode,
											setNodeData,
											"START_TRIGGER",
											setPopupShowProcess,
											eventSelectionValue
										);
									}}
								>
									{PROCEED}
								</button>
							</footer>
						</div>
					</>
				</div>
			</section>
		</Loading>
	);
};

export default EventSelectionComponent;
