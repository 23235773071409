import React, { useCallback, useEffect, useMemo, useState } from "react";
import "./CampaignBuilder.style.scss";
import "reactflow/dist/style.css";

import "reactflow/dist/style.css";
import { stratify, tree } from "d3-hierarchy";

import ReactFlow, {
  MiniMap,
  Controls,
  Background,
  useNodesState,
  useEdgesState,
  addEdge,
  useReactFlow,
  MarkerType,
} from "reactflow";
import { Handle } from "reactflow";
import SegmentPopupComponent from "./campaignPopups/segmentPopupComponent/SegmentPopupComponent";
import { images } from "../../imagesComponent/Images";
import {
  ADD_NODE,
  CAMPAIGN_ACTION_TYPE,
  CAMPAIGN_BUILDER_HEADER,
  WAIT,
  EVENT,
  FIRST,
  LAST,
  MIDDLE,
  SEGMENT,
  SEND_EMAIL,
  SVG_ICONS,
  campaignNodeTypes,
  childNodesAllowed,
  initialEdges,
  initialNodes,
  nodeDataObj,
  START,
  MODIFY_NODE,
  MENU_NODE,
  DELETE_NODE,
  componentNameByType,
  actionProps,
  displayNameAlias,
} from "./CampaignBuilderConstants";
import { useSelector } from "react-redux";

import GenericFlow from "../../utilsComponent/GenericFlow/GenericFlow";
import { getDisplayData } from "./CampaignBuilderUtil";
import { getDisplayName } from "../../utilsComponent/GenericFlow/GenericFlowUtil";
import {
  checkRightsIsEditable,
  checkRightsIsVisible,
  getAllRights,
  getRightsKey,
} from "../../utilsComponent/CommonUtil";

const CampaignBuilder = ({ theme, setCampaignBuilderData, isDisabled }) => {
  // const campaignData = useSelector((state) => state.campaignDetailsData);
  const journeyDetailsData = useSelector((state) => state.journeyDetailsData);

  const [actionName] = useState(
    campaignNodeTypes[
      journeyDetailsData?.journeyDetailsResponse?.journeyData?.journeyType
    ]["actionName"]
  );
  const maxChildNodes =
    campaignNodeTypes[
      journeyDetailsData?.journeyDetailsResponse?.journeyData?.journeyType
    ]["nodesAllowed"];

  const [journeyFlow] = useState({
    nodes:
      journeyDetailsData?.journeyDetailsResponse?.journeyFlow?.nodes?.length > 0
        ? journeyDetailsData?.journeyDetailsResponse?.journeyFlow?.nodes.map(
            (node) => {
              return {
                ...node,
                data: {
                  ...node.data,
                  displayData: {
                    ...getDisplayData(
                      node.data.value,
                      node.data.type.toLowerCase(),
                      journeyDetailsData?.journeyDetailsResponse?.journeyData
                        ?.journeyStatus,
                      journeyDetailsData?.journeyDetailsResponse?.journeyData
                        ?.journeyType
                    ),
                  },
                  name: node?.data?.name
                    ? node?.data?.name
                    : getDisplayName(node.data.type, displayNameAlias),
                },
              };
            }
          )
        : initialNodes.map((node) => {
            node.data.type = actionName;
            node.data.displayData.displayName = actionName;
            node.data.name = getDisplayName(actionName, displayNameAlias);
            return node;
          }),
    edges:
      journeyDetailsData?.journeyDetailsResponse?.journeyFlow?.edges?.length > 0
        ? journeyDetailsData?.journeyDetailsResponse?.journeyFlow?.edges
        : initialEdges,
  });

  const campaignType =
    journeyDetailsData?.journeyDetailsResponse?.journeyData?.journeyType;

  function journeyFlowHandler(journeyFlow) {
    setCampaignBuilderData({
      journeyFlow: {
        nodes: journeyFlow.nodes,
        edges: journeyFlow.edges,
      },
    });
  }

  return (
    <>
      <section style={{ height: "calc(100% - 130px)" }}>
        <div className="section-top-title setup-page-section-title">
          {CAMPAIGN_BUILDER_HEADER}
        </div>
        <div className="generic-flow-builder-wrapper">
          <GenericFlow
            theme={theme}
            journeyFlow={journeyFlow}
            maxChildNodes={maxChildNodes}
            isDisabled={isDisabled}
            nodeDataObj={nodeDataObj}
            actionProps={actionProps.filter((prop) =>
              prop.showInCampaignType.includes(campaignType)
            )}
            componentNameByType={componentNameByType}
            journeyFlowHandler={journeyFlowHandler}
            displayNameJson={displayNameAlias}
            nodeNameisEditable={true}
            rights={{
              isEditable: {
                addNode: !checkRightsIsEditable(
                  getRightsKey(["campaign", "detail", "addnode"])
                ),
                editNode: !checkRightsIsEditable(
                  getRightsKey(["campaign", "detail", "editnode"])
                ),
                deletNode: !checkRightsIsEditable(
                  getRightsKey(["campaign", "detail", "deletenode"])
                ),
              },
              isVisible: {
                addNode: checkRightsIsVisible(
                  getRightsKey(["campaign", "detail", "addnode"])
                ),
                editNode: checkRightsIsVisible(
                  getRightsKey(["campaign", "detail", "editnode"])
                ),
                deletNode: checkRightsIsVisible(
                  getRightsKey(["campaign", "detail", "deletenode"])
                ),
              },
            }}
          />
        </div>
      </section>
    </>
  );
};

export default CampaignBuilder;
