export const COLUMNS = [
  {
    id:"attrName1",
    title: "Attribute Name",
    key: "name",
    type: "string",
    visible: true,
    filter: true,
    sort: true,
    minWidth: 120,
    initialWidth: 120,
    defaultWidth: 120,
    resizable: true,
    reqBodyKey: { attrName: "name", isAllowed: true },
  },
  {
    id:"attrDesc2",
    title: "Description",
    key: "description",
    type: "string",
    visible: true,
    filter: true,
    sort: true,
    advanceFilter: true,
    minWidth: 240,
    initialWidth: 240,
    defaultWidth: 240,
    resizable: true,
    reqBodyKey: { attrName: "description", isAllowed: true },
  },
  {
    id:"attrEntity3",
    title: "Entity",
    key: "entity",
    type: "select",
    visible: true,
    filter: true,
    sort: true,
    options: ["member", "product", "store", "tender", "transaction"],
    advanceFilter: false,
    multi: true,
    minWidth: 100,
    initialWidth: 100,
    defaultWidth: 100,
    resizable: true,
    reqBodyKey: { attrName: "entity", isAllowed: true },
  },
  {
    id:"attrType4",
    title: "Type",
    key: "type",
    type: "select",
    visible: true,
    filter: true,
    sort: true,
    advanceFilter: true,
    multi: true,
    options: ["String", "Date", "Number", "List", "Double"],
    minWidth: 85,
    initialWidth: 85,
    defaultWidth: 85,
    resizable: true,
    reqBodyKey: { attrName: "type", isAllowed: true },
    // rights: [
    //   {
    //     key: "String",
    //     rights: ["filter","configure","attribute","type","string"]
    //   },
    //   {
    //     key: "Date",
    //     rights: ["filter","configure","attribute","type","date"]
    //   },
    //   {
    //     key: "Number",
    //     rights: ["filter","configure","attribute","type","number"]
    //   },
    //   {
    //     key: "Double",
    //     rights: ["filter","configure","attribute","type","double"]
    //   },
    //   {
    //     key: "List",
    //     rights: ["filter","configure","attribute","type","list"]
    //   },
    // ],
  },
  {
    id:"attrCreatedBy5",
    title: "Created by",
    key: "createdBy",
    type: "string",
    visible: true,
    filter: true,
    sort: true,
    advanceFilter: true,
    minWidth: 85,
    initialWidth: 85,
    defaultWidth: 85,
    resizable: true,
    reqBodyKey: { attrName: "createdBy", isAllowed: true },
  },
  {
    id:"attrCreatedTime6",
    title: "Created time",
    key: "createdTs",
    type: "string",
    visible: true,
    filter: false,
    sort: true,
    advanceFilter: false,
    minWidth: 85,
    initialWidth: 85,
    defaultWidth: 85,
    resizable: true,
    reqBodyKey: { attrName: "", isAllowed: false },
  },
];
export const VIEW_ATTRIBUTE = "VIEW_ATTRIBUTE";
export const VIEW_ATTRIBUTE_PLACEHOLDER = "Search attributes";
export const ATTRIBUTE_REQ_BODY_KEY = "filter";
export const ATTRIBUTE_RES_BODY_KEY = "list";
