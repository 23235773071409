export const ATTRIBUTES_TYPES = [
  {
    label: "List",
    value: "List",
  },
  {
    label: "Text",
    value: "String",
  },
  {
    label: "Number",
    value: "Number",
  },
  {
    label: "Date",
    value: "Date",
  },
  {
    label: "Double",
    value: "Double",
  },
];
export const ATTRIBUTE_DETAILS_HEADER = "Details";
export const ATTRIBUTE_INPUT_ARRAY = ["name", "entity", "type", "description"];
export const ATTRIBUTE_INPUT_NAME = "name";
export const ATTRIBUTE_INPUT_DESCRIPTION = "description";
export const ATTRIBUTE_INPUT_TYPE = "type";
export const ATTRIBUTE_INPUT_ENTITY = "entity";
export const ATTRIBUTE_INPUT_LIST_INFO_TITLE = `When to choose "List" ?`;
export const ATTRIBUTE_INPUT_LIST_INFO_BODY = `Use type "List" when the attribute can only have a limited set of options. E.g - If the attribute 'VIP Tier' can only hold the values 'Silver', 'Gold' and 'Platinum', then select type as "List" and add these as options on the right.`;
