import "./CampaignDetails.style.scss";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";

import {
  changeDateDashFormat,
  copyToClipBoard,
  formatDate,
} from "../../utilsComponent/CommonUtil";
import "../../../tellurideExtAssets/styles/reactCalendar.style.scss";
import "../../../tellurideExtAssets/styles/reactDatePicker.style.scss";
import DatePicker from "react-date-picker";
import { images } from "../../imagesComponent/Images";
//import { OFFER_DETAILS_STATUS_DRAFT } from "../../constantComponent/Constants";
import moment from "moment";
import {
  DESCRIPTION_LABEL,
  END_DATE_INFO,
  END_DATE_LABEL,
  CAMPAIGN_CATEGORY_OPTIONS,
  CAMPAIGN_DETAILS_TITLE,
  CAMPAIGN_END_DATE,
  CAMPAIGN_ID_INFO,
  CAMPAIGN_ID_LABEL,
  CAMPAIGN_START_DATE,
  CAMPAIGN_TYPE_LABEL,
  START_DATE_INFO,
  START_DATE_LABEL,
  CONTENT_PREVIEW_AND_TEST,
  CAMPAIGN_SCHEDULE_TITLE,
  ALLOWED_PERIODS,
} from "./CampaignDetailsConstants";
import {
  convertUTCDateToLocalDate,
  dateHandler,
  getCustomStyles,
  validateDescription,
  valueHandler,
} from "./CampaignDetailsUtil";
//import { OFFER_CATEGORY } from "../rewardComponent/RewardConstant";
import Select from "react-select";
import { RxCopy } from "react-icons/rx";
import { CAMPAIGN_TRIGGER_TYPES } from "../createCampaingComponent/CreateCampaingConstant";
// import { updatejourneyDataDetails } from "../../../tellurideExtRedux";

import { Cron as ReactCron } from "react-js-cron";
import "react-js-cron/dist/styles.css";
import { resetIcon } from "../../utilsComponent/SidebarIcons";
import { campaignNodeTypes } from "../campaignBuilderComponent/CampaignBuilderConstants";
import { SUCCESS } from "../../constantComponent/Constants";

const CampaignDetails = ({
  campaignDetailsData,
  setCampaignDetailsData,
  isDisabled,
}) => {
  const dispatch = useDispatch();
  // const campaignDetailsPageData = useSelector(
  //   (state) => state.campaignDetailsData
  // );
  const journeyDetailsData = useSelector((state) => state.journeyDetailsData);
  const journeyData = journeyDetailsData?.journeyDetailsResponse?.journeyData;

  const [isSetDisable, setDisable] = useState(false);
  const [copyText, setCopyText] = useState(false);
  const toasterData = useSelector((state) => state.toasterData);
  const [theme, setTheme] = useState(toasterData.isDarkTheme);
  const [journeyType, setJourneyType] = useState("");
  const [state, setState] = useState({
    value: campaignDetailsData?.cronExpression || "",
  });
  const [value, setValue] = useState(
    campaignDetailsData?.cronExpression || "0 0 * * *"
  );

  const [showCustom, setShowCustom] = useState("Predefined");

  const HEADER = {
    MINUTES: "MINUTES",
    HOURLY: "HOURLY",
    DAILY: "DAILY",
    WEEKLY: "WEEKLY",
    MONTHLY: "MONTHLY",
    CUSTOM: "CUSTOM",
  };
  const cronOptions = {
    headers: [
      HEADER.HOURLY,
      HEADER.DAILY,
      // HEADER.MINUTES,
      HEADER.WEEKLY,
      HEADER.MONTHLY,
    ],
  };
  useEffect(() => {
    setDisable(isDisabled);
  }, [isDisabled]);

  useEffect(() => {
    if (
      journeyDetailsData?.journeyDetailsLoading === false &&
      journeyDetailsData?.journeyDetailsResponse &&
      journeyDetailsData?.journeyDetailsResponse?.status === SUCCESS
    ) {
      setState({
        value:
          journeyDetailsData?.journeyDetailsResponse.journeyFlow?.metaData
            ?.scheduleCronExpression,
      });
      setValue(
        journeyDetailsData?.journeyDetailsResponse.journeyFlow?.metaData
          ?.scheduleCronExpression || "0 0 * * *"
      );
      setJourneyType(
        journeyDetailsData?.journeyDetailsResponse?.journeyAttributes?.journeyAttribute?.some(
          (attribute) => attribute?.name === "DISPLAY_OFFER_TYPE"
        )
          ? journeyDetailsData?.journeyDetailsResponse?.journeyAttributes?.journeyAttribute?.find(
              (attribute) => attribute?.name === "DISPLAY_OFFER_TYPE"
            ).values.value[0]
          : journeyDetailsData?.journeyDetailsResponse?.journeyData?.journeyType
      );
    }
  }, [journeyDetailsData?.journeyDetailsResponse]);
  useEffect(() => {
    setTheme(toasterData.isDarkTheme);
  }, [toasterData]);
  // useEffect(() => {
  //   setDisable(
  //     campaignDetailsPageData?.campaignStatus !== OFFER_DETAILS_STATUS_DRAFT
  //       ? true
  //       : false
  //   );
  // }, [campaignDetailsPageData?.campaignStatus]);
  // useEffect(() => {
  //   dispatch(updatejourneyData(campaignDetailValues));
  // }, [campaignDetailValues]);
  useEffect(() => {
    setCampaignDetailsData((prev) => {
      return {
        ...prev,
        cronExpression: value,
      };
    });
  }, [value]);
  return (
    <>
      <div className="campaign-details-main-container">
        <div className="campaign-details-title setup-page-section-title">
          {CAMPAIGN_DETAILS_TITLE}
        </div>
        <section className="campaign-details-section">
          {/* {campaignDetailsPageData?.campaignStatus !== "draft" && (
            <div className="disable-backdrop"></div>
          )} */}
          <div className="form-div">
            <div className="campaign-code-wrapper">
              <div className="campaign-code">
                <div className="campaign-info-container">
                  <label className="campaign-info-label details-page-label">
                    {CAMPAIGN_ID_LABEL} :{" "}
                  </label>
                  <span className="campaign-info-bold">
                    {journeyData?.journeyNumber}
                  </span>
                  {copyText === false ? (
                    <RxCopy
                      className="copy-icon"
                      onClick={() => {
                        setCopyText(true);
                        copyToClipBoard(journeyData?.journeyNumber);
                        setTimeout(() => {
                          setCopyText(false);
                        }, 3000);
                      }}
                    />
                  ) : (
                    <p className="copy-icon-text">Copied!</p>
                  )}
                </div>
                <p>{CAMPAIGN_ID_INFO}</p>
              </div>
              <div className="campaign-code">
                <div className="campaign-info-container campaign-type-container">
                  <label className="campaign-info-label campaign-type-label details-page-label">
                    {CAMPAIGN_TYPE_LABEL} :{" "}
                  </label>
                  <span title={journeyType} className="campaign-info-bold">
                    {journeyType}
                  </span>
                  {/* <span className="campaign-info-bold">{"Points Earn"}</span> */}
                </div>
              </div>
            </div>

            {journeyData?.journeyType === "CMP-EVENT" && (
              <div className="date-wrapper">
                <div className="date-wrapper-inside">
                  <fieldset id="start-date" disabled={isDisabled}>
                    <legend>{START_DATE_LABEL}</legend>
                    <DatePicker
                      calendarIcon={
                        <img
                          src={images.calendarIcon}
                          className="calendar-icon"
                        />
                      }
                      clearIcon={null}
                      format="y-MM-dd"
                      value={moment(
                        campaignDetailsData?.startDate
                      ).toLocaleString("en-US")}
                      minDate={moment(
                        journeyData?.createdDate,
                        "YYYY-MM-DD"
                      ).toDate()}
                      onChange={(value) => {
                        setCampaignDetailsData((prev) => {
                          return {
                            ...prev,
                            startDate: new Date(value).toLocaleDateString(
                              "en-US"
                            ),
                          };
                        });
                      }}
                      disabled={isDisabled}
                    />
                  </fieldset>

                  <div className="info-div">
                    <p>{START_DATE_INFO}</p>
                  </div>
                </div>

                <div className="date-wrapper-inside" id="end-date">
                  <fieldset disabled={isDisabled}>
                    <legend>{END_DATE_LABEL}</legend>
                    <DatePicker
                      calendarIcon={
                        <img
                          src={images.calendarIcon}
                          className="calendar-icon"
                        />
                      }
                      clearIcon={null}
                      format="y-MM-dd"
                      minDate={moment(
                        changeDateDashFormat(campaignDetailsData?.startDate),
                        "YYYY-MM-DD"
                      ).toDate()}
                      onChange={(value) => {
                        setCampaignDetailsData((prev) => {
                          return {
                            ...prev,
                            endDate: new Date(value).toLocaleDateString(
                              "en-US"
                            ),
                          };
                        });
                      }}
                      value={moment(
                        campaignDetailsData?.endDate
                      ).toLocaleString("en-US")}
                      disabled={isDisabled}
                      className={"top-230"}
                    />
                  </fieldset>

                  <div className="info-div">
                    <p>{END_DATE_INFO}</p>
                  </div>
                </div>
              </div>
            )}

            <div className="textbox-wrapper">
              <fieldset className="text-box" disabled={isDisabled}>
                <legend>{DESCRIPTION_LABEL}</legend>
                <textarea
                  className="input-text-textarea"
                  rows="3"
                  cols="50"
                  value={campaignDetailsData?.description}
                  maxLength="250"
                  name="campaignDescription"
                  onChange={(e) => {
                    setCampaignDetailsData((prev) => {
                      return {
                        ...prev,
                        description: validateDescription(e),
                      };
                    });
                  }}
                />
              </fieldset>
            </div>
          </div>

          {/* <div className="campaign-details-title schedule details-page-label">
						{CAMPAIGN_SCHEDULE_TITLE} :
						<span
							onClick={() => {
								if (isSetDisable !== true) {
									setValue("0 0 * * *");
								}
							}}
							className={`reset-icon ${isSetDisable ? "disabled" : ""}`}
						>
							{resetIcon()} Clear
						</span>
					</div>
					<div className="campaign-cron-wrapper pr-22">
						<div className="campaign-cron-container">
							<ReactCron
								value={value}
								setValue={setValue}
								clearButton={false}
								allowedPeriods={ALLOWED_PERIODS}
								locale={{
									prefixMinutes: "",
								}}
								disabled={isSetDisable}
								allowEmpty="always"
							/>
							{process.env.REACT_APP_ENVIRONMENT === "dev" && (
								<span className="campaign-info-bold">{value}</span>
							)}
						</div>
					</div> */}
        </section>
      </div>
    </>
  );
};

export default CampaignDetails;
