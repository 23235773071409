import React from "react";
import {
  ACTIVE,
  ASCENDING,
  DESCENDING,
  SEMI_ACTIVE,
} from "../GenericRules/GenericRulesConstants";
import { VscTriangleDown, VscTriangleUp } from "react-icons/vsc";

function SmallGenericTableColumn({
  columns,
  isRowMultiSelectable,
  tableData,
  rowClickHandler,
  selectedData,
  config,
  handleSort,
  isSorting,
}) {
  return (
    <div className="small-tablelist-container">
      {isRowMultiSelectable && (
        <div className="checkbox-container">
          <input
            type="checkbox"
            checked={selectedData.length === tableData.length}
            onClick={(e) => {
              if (e.target.checked) {
                rowClickHandler(
                  tableData.map((item) => {
                    return {
                      code: item?.groupName,
                      name: item?.groupName,
                    };
                  })
                );
              } else {
                rowClickHandler([]);
              }
            }}
          />
        </div>
      )}
      {columns.map((header, index) => {
        if (header.visible === true) {
          return (
            <div className="column_header generic-table-col-name attribute-col" key={index}>
              <div>{header.title}</div>
              {header.sort && (
                <>
                  <VscTriangleUp
                    className={`sort-icon up ${
                      isSorting?.order === ASCENDING ? ACTIVE : SEMI_ACTIVE
                    }`}
                    onClick={() => {
                      handleSort(ASCENDING, header.sortKey);
                    }}
                  />
                  <VscTriangleDown
                    className={`sort-icon down ${
                      isSorting?.order === DESCENDING ? ACTIVE : SEMI_ACTIVE
                    }`}
                    onClick={() => {
                      handleSort(DESCENDING, header.sortKey);
                    }}
                  />
                </>
              )}
            </div>
          );
        }
      })}
    </div>
  );
}

export default React.memo(SmallGenericTableColumn);
