import React from "react";
import { VscTriangleDown, VscTriangleUp } from "react-icons/vsc";
import {
	ACTIVE,
	ASCENDING,
	DESCENDING,
	SEMI_ACTIVE,
} from "../GenericRules/GenericRulesConstants";
import {
	ColumnFilterPopover,
	filteredIcon,
	filterIcon,
} from "./GenericTableDesignConstant";
import { handleSort } from "./GenericTableUtil";

export function TableColumns({
	filter,
	sort,
	columns,
	columnFilter,
	setColumnFilter,
	setFetchList,
	setFilter,
	setSort,
	setReset,
	setTableSorted,
	mouseDown,
	activeIndex,
	layoutLoading,
}) {
	return (
		<div className="table-col-container">
			{columns.map((col, index) => {
				return (
					<React.Fragment key={col.key}>
						{col.visible === true && (
							<>
								<div
									className={`col`}
									style={
										layoutLoading
											? {
													width: "100%",
											  }
											: {
													maxWidth: col.minWidth,
													minWidth: col.minWidth,
											  }
									}
									ref={col.ref}
								>
									<div className="col-name">{col.title.toUpperCase()}</div>
									<ColumnFilterPopover
										columnFilter={columnFilter}
										setColumnFilter={setColumnFilter}
										col={col}
										shiftRight={index === 0 ? true : false}
										shiftLeft={
											[
												columns.length - 1,
												columns.length - 2,
												columns.length - 3,
											].includes(index)
												? true
												: false
										}
										filter={filter}
										setFetchList={setFetchList}
										setFilter={setFilter}
									/>

									<div className="col-icons-wrapper">
										<div className="priority-container">
											{sort?.find(({ name }) => name === col.key)
												? sort?.find(({ name }) => name === col.key)?.priority
												: null}
										</div>

										<div className="col-icons">
											{col.sort === true && (
												<div>
													<div className="sort-icons-container">
														<VscTriangleUp
															className={`sort-icon up ${
																sort?.find(
																	({ name, order }) =>
																		name === col.key && order === ASCENDING
																) && ACTIVE
															} ${
																sort?.find(
																	({ name, order }) =>
																		name === col.key && order === DESCENDING
																) && SEMI_ACTIVE
															}`}
															onClick={() => {
																handleSort(
																	col.key,
																	ASCENDING,
																	sort,
																	setSort,
																	setReset,
																	setTableSorted
																);
															}}
															size={15}
														/>

														<VscTriangleDown
															className={`sort-icon down ${
																sort?.find(
																	({ name, order }) =>
																		name === col.key && order === DESCENDING
																) && ACTIVE
															} ${
																sort?.find(
																	({ name, order }) =>
																		name === col.key && order === ASCENDING
																) && SEMI_ACTIVE
															}`}
															onClick={() => {
																handleSort(
																	col.key,
																	DESCENDING,
																	sort,
																	setSort,
																	setReset,
																	setTableSorted
																);
															}}
															size={15}
														/>
													</div>
												</div>
											)}
											{col.filter === true
												? filter?.find(
														({ name, status }) => name === col.key && status
												  )
													? filteredIcon(col, setColumnFilter)
													: filterIcon(col, setColumnFilter)
												: null}
										</div>
									</div>
									{col.resizable === true && (
										<div
											onMouseDown={() => mouseDown(index)}
											className={`resize-handle ${
												activeIndex === index ? "active" : "idle"
											}`}
										/>
									)}
								</div>
							</>
						)}
					</React.Fragment>
				);
			})}
		</div>
	);
}
