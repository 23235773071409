import { useEffect, useState } from "react";
import {
	ACTIVE,
	DESCRIPTION_TITLE,
	MEMBERGROUP,
	MEMBER_GROUP_HEADER,
	SEGMENT_TITLE,
	ZERO,
	segment_group_model_columns,
	popupConfig,
} from "./GenericRulesConstants.js";
import { setInputValue } from "./GenericRulesUtil.js";
import { AiFillCloseCircle } from "react-icons/ai";
import { VscTriangleDown, VscTriangleUp } from "react-icons/vsc";
import { useDispatch, useSelector } from "react-redux";
import { emptyData } from "../../constantComponent/DesignConstant.jsx";
import { loading } from "../../LoaderComponents/loadingDesignConstant.jsx";
import {
	DARK,
	OFRI,
	SAVE,
	headers,
} from "../../constantComponent/Constants.js";
import { motion } from "framer-motion/dist/framer-motion";
import { getSegmentsList } from "../../../tellurideExtRedux/index.js";
import { getRightsFromSession, skey } from "../CommonUtil.js";
import Skeleton from "react-loading-skeleton";
import { FiSearch } from "react-icons/fi";
import SmallGenericTable from "../SmallGenericTable/SmallGenericTable.jsx";

const SegmentGroupModal = ({
	options,
	id,
	setEditProcess,
	setExpression,
	isMulti,
	filter,
}) => {
	const metaData = useSelector((state) => state.metaData);
	const offerData = useSelector((state) => state.offerDetailsData);
	const segmentData = useSelector((state) => state.segmentDetailsData);
	const securityData = useSelector((state) => state.securityData);
	const dispatch = useDispatch();
	const OPTIONS_INPUTS = options || [];

	const attributeLoading = metaData?.attributeLoading;
	const [list, setList] = useState(OPTIONS_INPUTS);
	const [searchedlist, setSearchedList] = useState([]);
	const [searchInput, setSearchInput] = useState("");
	const [isSorting, setIsSorting] = useState({
		status: false,
		order: null,
	});
	const [multiSelectOptions, setMultiSelectOptions] = useState(
		filter.value && Array.isArray(filter.value) ? filter.value : []
	);
	const [isDataLoading, setIsDataLoading] = useState(true);
	const [fetchList, setFetchList] = useState(false);
	const selectedAttr = filter.attr;
	const controller = new AbortController();
	const tableLoading = segmentData.getSegmentListLoading;
	const tableResponse = segmentData.getSegmentListResponse;
	const [segmentList, setSegmentList] = useState([]);
	const toasterData = useSelector((state) => state.toasterData);
	const [theme, setTheme] = useState(toasterData.isDarkTheme);

	useEffect(() => {
		if (options && options.length > ZERO) {
			setList(options);
		}
	}, [options]);

	useEffect(() => {
		if (selectedAttr === MEMBERGROUP) {
			headers.sessionId = skey();
			headers.requestAccess = getRightsFromSession();
			headers.organization = offerData?.authOrganizationName;
			const requestHeaders = {
				headers,
			};
			let getGeneralListRequestBody = {
				requestorID: OFRI,
				messageVersion: process.env.REACT_APP_MESSAGE_VERSION_01,
				pagination: {
					recordPerPage: 10000,
					currentPage: 1,
				},
				sort: [],
				search: {
					description: "",
					lastModifiedBy: "",
					name: "",
					searchQuery: searchInput,
					type: "",
				},
			};
			let apiurl =
				process.env.REACT_APP_URL_DOMAIN +
				process.env.REACT_APP_GET_SEGMENTS_LIST;
			setIsDataLoading(true);
			dispatch(
				getSegmentsList(
					requestHeaders,
					getGeneralListRequestBody,
					apiurl,
					controller
				)
			);
		}
	}, [fetchList]);
	useEffect(() => {
		if (
			filter.attr === MEMBERGROUP &&
			tableLoading === false &&
			tableResponse
		) {
			if (tableResponse?.groupList) {
				setSegmentList(tableResponse.groupList);
				setIsDataLoading(false);
			}
			setIsDataLoading(false);
		}
	}, [tableLoading, tableResponse]);

	function handleInputDropdownSelect(code) {
		setInputValue(setExpression, id, code);
		setEditProcess({ id: null, status: false });
		setSearchInput("");
	}
	function handleInputDropdownMultiSelect(code, name) {
		setMultiSelectOptions((prev) => {
			if (prev.find((item) => item.code === code)) {
				return prev.filter((item) => item.code !== code);
			} else {
				return [...prev, { code: code, name: name }];
			}
		});
	}
	function handleInputDropdownMultiSelectALL() {
		setMultiSelectOptions(() => {
			if (searchInput.length > ZERO) {
				if (searchedlist.length === multiSelectOptions.length) {
					return [];
				} else {
					return searchedlist.map((item) => {
						return {
							code: item.code,
							name: item.name,
						};
					});
				}
			} else {
				if (list.length === multiSelectOptions.length) {
					return [];
				} else {
					return list.map((item) => {
						return {
							code: item.code,
							name: item.name,
						};
					});
				}
			}
		});
	}
	function handleMultiSelectSave() {
		if (multiSelectOptions.length !== ZERO) {
			setInputValue(setExpression, id, multiSelectOptions);
			setEditProcess({ id: null, status: false });
			setSearchInput("");
		}
	}

	function handleSearchChange(e) {
		if (list.length > ZERO) {
			let input = e.target.value;
			setSearchInput(input);
			if (searchInput.length > 0) {
				setSearchedList(() => {
					return [...OPTIONS_INPUTS].filter((item) => {
						return item.name.toLowerCase().includes(searchInput.toLowerCase());
					});
				});
			}
		} else {
			return;
		}
	}
	function handleSort(order) {
		if (list.length > ZERO) {
			if (order === isSorting.order) {
				setList(() => [...OPTIONS_INPUTS]);
				if (searchInput.length > ZERO) {
					setSearchedList(() => {
						return [...OPTIONS_INPUTS].filter((item) => {
							return (
								item.name.toLowerCase().includes(searchInput.toLowerCase()) ||
								item.code.toLowerCase().includes(searchInput.toLowerCase())
							);
						});
					});
				}
				setIsSorting({
					order: null,
					status: null,
				});
			} else {
				if (order === "ascending") {
					setList((prevList) => {
						return prevList.sort(({ name: attr1 }, { name: attr2 }) =>
							attr1.localeCompare(attr2)
						);
					});
					if (searchInput.length > ZERO) {
						setSearchedList((prevList) => {
							return prevList.sort(({ name: attr1 }, { name: attr2 }) =>
								attr1.localeCompare(attr2)
							);
						});
					}
					setIsSorting({
						order: "ascending",
						status: "sorted",
					});
				} else if (order === "descending") {
					setList((prevList) => {
						return prevList.sort(({ name: attr1 }, { name: attr2 }) =>
							attr2.localeCompare(attr1)
						);
					});
					if (searchInput.length > ZERO) {
						setSearchedList((prevList) => {
							return prevList.sort(({ name: attr1 }, { name: attr2 }) =>
								attr2.localeCompare(attr1)
							);
						});
					}
					setIsSorting({
						order: "descending",
						status: "sorted",
					});
				}
			}
		} else {
			return;
		}
	}
	function getSearchBarInput(selectedAttr) {
		switch (selectedAttr) {
			case MEMBERGROUP:
				return (
					<form
						onSubmit={(e) => {
							e.preventDefault();
							setFetchList((prev) => !Boolean(prev));
						}}
						className="attribute-form-search-member-group"
					>
						<input
							type="text"
							placeholder="Search..."
							className="attribute-search-member-group"
							value={searchInput}
							onChange={(e) => {
								setSearchInput(e.target.value);
							}}
						/>
						<FiSearch
							className={`search-icon`}
							onClick={() => setFetchList((prev) => !Boolean(prev))}
						/>
					</form>
				);
			default:
				return (
					<input
						type="text"
						placeholder="Search..."
						className="attribute-search-member-group"
						value={searchInput}
						onChange={handleSearchChange}
					/>
				);
		}
	}

	function getTable(selectedAttr) {
		switch (selectedAttr) {
			case MEMBERGROUP:
				return (
					<>
						{/* <div className="attribute-list-header-member-group membergroup">
              <>
                {isMulti && (
                  <div className="checkbox-container">
                    <input
                      type="checkbox"
                      checked={multiSelectOptions.length === segmentList.length}
                      onClick={(e) => {
                        if (e.target.checked) {
                          setMultiSelectOptions(
                            segmentList.map((item) => {
                              return {
                                code: item?.groupName,
                                name: item?.groupName,
                              };
                            })
                          );
                        } else {
                          setMultiSelectOptions([]);
                        }
                      }}
                      hidden={tableLoading || isDataLoading}
                    />
                  </div>
                )}
                <div className="attribute-col-member-group generic-table-col-name">
                  {"Segment"}
                </div>
                <div className="description-col generic-table-col-name">
                  {"Created by"}
                </div>
                <div className="description-col generic-table-col-name">
                  {"Member count"}
                </div>
              </>
            </div> */}
						<div className="attribute-list-wrapper">
							<div className="attribute-list-member-group membergroup">
								{isDataLoading === true || tableLoading === true ? (
									<div className="loading-container">
										{[...Array(15)].map((loading) => (
											<div className="loading-columns">
												{[...Array(3)].map((col) => (
													<div className="loading-column">
														<Skeleton
															baseColor={theme === DARK ? "#202020" : ""}
															highlightColor={theme === DARK ? "#444" : ""}
														/>
													</div>
												))}
											</div>
										))}
									</div>
								) : segmentList.length <= 0 ? (
									emptyData()
								) : (
									<SmallGenericTable
										Columns={segment_group_model_columns}
										tableData={segmentList}
										isDataLoading={tableLoading}
										selectedData={multiSelectOptions}
										allRowClickHandler={setMultiSelectOptions}
										isRowSelectable={true}
										isRowMultiSelectable={true}
										rowClickHandler={handleInputDropdownMultiSelect}
										config={{
											...popupConfig,
											theme: theme,
											selectedDataKey: "groupName",
											border: false,
										}}
										isShowPagination={false}
									/>
								)}
							</div>
						</div>
					</>
				);
			default:
				return (
					<>
						<div className="attribute-list-header-member-group">
							<div className="attribute-col-member-group">
								{isMulti && (
									<div className="checkbox-container">
										<input
											type="checkbox"
											checked={multiSelectOptions.length === list.length}
											onClick={handleInputDropdownMultiSelectALL}
											disabled={searchInput.length > ZERO ? true : false}
										/>
									</div>
								)}
								{SEGMENT_TITLE}
								<div>
									<VscTriangleUp
										className={`sort-icon up ${
											isSorting.order === "ascending" ? ACTIVE : "semi-active"
										}`}
										onClick={() => {
											handleSort("ascending");
										}}
									/>
									<VscTriangleDown
										className={`sort-icon down ${
											isSorting.order === "descending" ? ACTIVE : "semi-active"
										}`}
										onClick={() => {
											handleSort("descending");
										}}
									/>
								</div>
							</div>
							<div className="description-col">{DESCRIPTION_TITLE}</div>
						</div>
						<div className="attribute-list-wrapper">
							<div className="attribute-list-member-group">
								{searchInput.length > ZERO
									? searchedlist.length > ZERO
										? searchedlist.map(
												({ name, code, description, count }, index) => {
													return (
														<div
															className={`attribute-container-member-group ${
																!isMulti && code === filter.value
																	? "selected"
																	: multiSelectOptions.some(
																			({ code: selectedOptionCode }) =>
																				selectedOptionCode === code
																	  ) === true
																	? "selected"
																	: null
															}`}
															key={index}
															onClick={() => {
																if (isMulti) {
																	handleInputDropdownMultiSelect(code, name);
																} else {
																	handleInputDropdownSelect(code);
																}
															}}
														>
															{isMulti && (
																<div className="checkbox-container">
																	<input
																		type="checkbox"
																		checked={
																			multiSelectOptions.find(
																				(item) => item.code === code
																			)
																				? true
																				: false
																		}
																	/>
																</div>
															)}
															<div className="group-name">{code}</div>
															<div className="description-member-group">
																{description ? description : name}
															</div>
														</div>
													);
												}
										  )
										: emptyData()
									: attributeLoading === true
									? loading()
									: list.length > ZERO
									? list.map(({ code, name, description, count }, index) => {
											return (
												<div
													className={`attribute-container-member-group ${
														!isMulti && code === filter.value
															? "selected"
															: multiSelectOptions.some(
																	({ code: selectedOptionCode }) =>
																		selectedOptionCode === code
															  ) === true
															? "selected"
															: null
													}`}
													key={index}
													onClick={() => {
														if (isMulti) {
															handleInputDropdownMultiSelect(code, name);
														} else {
															handleInputDropdownSelect(code);
														}
													}}
												>
													{isMulti && (
														<div className="checkbox-container">
															<input
																type="checkbox"
																checked={
																	multiSelectOptions.find(
																		(item) => item.code === code
																	)
																		? true
																		: false
																}
															/>
														</div>
													)}
													<div className="group-name">{code}</div>
													<div className="description-member-group">
														{description ? description : name}
													</div>
												</div>
											);
									  })
									: emptyData()}
							</div>
						</div>
					</>
				);
		}
	}
	return (
		<div className={`modal-container-member-group ${isMulti ? "multi" : null}`}>
			<div className="modal-backdrop"></div>
			<motion.div
				className="modal-member-group"
				initial={{ opacity: 0 }}
				animate={{ opacity: 1 }}
				exit={{ opacity: 0 }}
			>
				{isMulti && (
					<button
						className={`btn btn-primary modal-save-btn ${
							multiSelectOptions.length === ZERO && "disabled"
						}`}
						onClick={handleMultiSelectSave}
						disabled={multiSelectOptions.length === ZERO ? true : false}
					>
						{"Proceed"}
					</button>
				)}
				<AiFillCloseCircle
					className="attribute-modal-close-member-group"
					onClick={() => {
						setEditProcess({ id: null, status: false });
					}}
				/>
				<p>{MEMBER_GROUP_HEADER}</p>
				<p>
					<div
						style={{
							display: "flex",
							gap: "10px",
						}}
					>
						<div>
							Entity : <b title={filter.entity}>{filter.entity}</b>
						</div>
						<>|</>
						<div
							style={{
								maxWidth: "300px",
								overflow: "hidden",
								textOverflow: "ellipsis",
								whiteSpace: "nowrap",
							}}
						>
							Attribute :{" "}
							<b title={filter.name ? filter.name : filter.attr}>
								{filter.name ? filter.name : filter.attr}
							</b>
						</div>
						<>|</>
						<div>
							Operator : <b title={filter.operator}>{filter.operator}</b>
						</div>
					</div>
				</p>
				<div className="search-bar-container-member-group">
					{getSearchBarInput(selectedAttr)}
				</div>
				<div className="attribute-list-container-member-group">
					{getTable(selectedAttr)}
				</div>
			</motion.div>
		</div>
	);
};

export default SegmentGroupModal;
